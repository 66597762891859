/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, July 2019 &
 * America Mendoza <america@startuplab.mx>, August 2019 &
 * Darien Miranda <darien@startuplab.mx, August 2019
 */

 // Regular imports
 import PropTypes from 'prop-types';
 import React, { Component } from 'react';
 import KomunidadApi from '../../api/api.js';
 import { transformToInt } from './../form/transforms.js';
 
 // Components
 import Swal from 'sweetalert2';
 import FormInput from './../form/FormInput';
 import FormTextArea from './../form/FormTextArea';
 import KMap from './../KMap';
 import Select from 'react-select';
 
 /**
  *  Display and edit the company information (Idea phase content).
  */
 
 
 class KTabIdeaContent extends Component{
   constructor(props) {
     super(props)
 
     this.state = {
       editMode:false,
       company:{},
       isFormEditing:false,
       formData: {
         name: '',
         quote: '',
         problemToSolve: '',
         proposed_solution: '',
         address_zip_code: '',
         address_state: '',
         address_municipality: '',
         address_neighborhood: '',
         address_street: '',
         address_number_ext: '',
         address_number_int: '',
         coordinates_latitude: '',
         coordinates_longitude: '',
       },
       stateLabel: {label:'Selecciona el estado', value: ''}
     }
 
     this.getAddressState              = this.getAddressState.bind(this);
     this.updateData                   = this.updateData.bind(this);
     this.cancelEdit                   = this.cancelEdit.bind(this);
     this.cancelForm                   = this.cancelForm.bind(this);
     this.onChangeForm                 = this.onChangeForm.bind(this);
     this.toggleEditMode               = this.toggleEditMode.bind(this);
     this.openCancelSwal               = this.openCancelSwal.bind(this);
     this.openLoadingSwal              = this.openLoadingSwal.bind(this);
     this.renderActionButton           = this.renderActionButton.bind(this);
     this.setInitialValues             = this.setInitialValues.bind(this);
     this.renderAddInfoButton          = this.renderAddInfoButton.bind(this);
     this.validateForm                 = this.validateForm.bind(this);
     this.formIsEditing                = this.formIsEditing.bind(this);
     this.renderAddressForm            = this.renderAddressForm.bind(this);
     this.getAddressFromMap            = this.getAddressFromMap.bind(this);
     this.setMarkerPosition            = this.setMarkerPosition.bind(this);
     this.setMarkerInitialPosition     = this.setMarkerInitialPosition.bind(this);
     this.onChangeState					      = this.onChangeState.bind(this);
   }
   componentDidMount(){
     this.setInitialValues(this.props.company);
   }
   setInitialValues(company) {
     this.setState({
       formData: {
         ...this.state.formData,
         name: company.name,
         quote: company.quote || "",
         problemToSolve: company.problemToSolve || "",
         proposed_solution: company.proposed_solution || "",
         address_zip_code: company.address_zip_code || '',
         address_state: company.address_state || '',
         address_municipality: company.address_municipality || '',
         address_neighborhood: company.address_neighborhood || '',
         address_street: company.address_street || '',
         address_number_ext: company.address_number_ext || '',
         address_number_int: company.address_number_int || '',
         coordinates_latitude: company.hasOwnProperty("coordinates") ? company.coordinates[1] : '',
         coordinates_longitude: company.hasOwnProperty("coordinates") ? company.coordinates[0] : '',
        },
        stateLabel: {
          label: company.address_state.length != 0 ? this.iso31662ToState(company.address_state) : 'Selecciona el estado', 
          value: company.address_state.length != 0 ? company.address_state : ''
        }
     })
   }
 
   formIsEditing(editing){
     if(!(this.state.isFormEditing && editing)){
       this.setState({isFormEditing:editing});
       this.props.isTabEditing(editing);
     }
   }
 
   cancelEdit () {
     this.cancelForm();
     this.formIsEditing(false);
     this.setState({
       editMode: false,
     })
   }
 
   toggleEditMode () {
     this.setState(prevState => ({
       editMode: !prevState.editMode
     }))
   }
 
   stateToISO31662(state){
     state = state.replace(/^[^A-Za-z]+/, '');
     let isoCodes = {
       "Aguascalientes":"MX-AGU",
       "Baja California":"MX-BCN",
       "Baja California Sur":"MX-BCS",
       "Campeche":"MX-CAM",
       "Coahuila de Zaragoza":"MX-COA",
       "Colima":	"MX-COL",
       "Chiapas":"MX-CHP",
       "Chihuahua":"MX-CHH",
       "Ciudad de México":"MX-CMX",
       "Durango":"MX-DUR",
       "Guanajuato":"MX-GUA",
       "Guerrero":"MX-GRO",
       "Hidalgo":"MX-HID",
       "Jalisco":"MX-JAL",
       "México":"MX-MEX",
       "Michoacán de Ocampo":"MX-MIC",
       "Morelos":"MX-MOR",
       "Nayarit":"MX-NAY",
       "Nuevo León":"MX-NLE",
       "Oaxaca":"MX-OAX",
       "Puebla":"MX-PUE",
       "Querétaro":"MX-QUE",
       "Quintana Roo":"MX-ROO",
       "San Luis Potosí":"MX-SLP",
       "Sinaloa":"MX-SIN",
       "Sonora":"MX-SON",
       "Tabasco":"MX-TAB",
       "Tamaulipas":"MX-TAM",
       "Tlaxcala":"MX-TLA",
       "Veracruz":"MX-VER",
       "Yucatán":"MX-YUC",
       "Zacatecas":"MX-ZAC"
     }
     return isoCodes[state];
   }
   iso31662ToState(state){
     let isoCodes = {
       "MX-AGU":"Aguascalientes",
       "MX-BCN":"Baja California",
       "MX-BCS":"Baja California Sur",
       "MX-CAM":"Campeche",
       "MX-COA":"Coahuila de Zaragoza",
       "MX-COL":"Colima",
       "MX-CHP":"Chiapas",
       "MX-CHH":"Chihuahua",
       "MX-CMX":"Ciudad de México",
       "MX-DUR":"Durango",
       "MX-GUA":"Guanajuato",
       "MX-GRO":"Guerrero",
       "MX-HID":"Hidalgo",
       "MX-JAL":"Jalisco",
       "MX-MEX":"México",
       "MX-MIC":"Michoacán de Ocampo",
       "MX-MOR":"Morelos",
       "MX-NAY":"Nayarit",
       "MX-NLE":"Nuevo León",
       "MX-OAX":"Oaxaca",
       "MX-PUE":"Puebla",
       "MX-QUE":"Querétaro",
       "MX-ROO":"Quintana Roo",
       "MX-SLP":"San Luis Potosí",
       "MX-SIN":"Sinaloa",
       "MX-SON":"Sonora",
       "MX-TAB":"Tabasco",
       "MX-TAM":"Tamaulipas",
       "MX-TLA":"Tlaxcala",
       "MX-VER":"Veracruz",
       "MX-YUC":"Yucatán",
       "MX-ZAC":"Zacatecas",
     }
     return isoCodes[state];
   }
   getAddressState(value) {
     return this.iso31662ToState(value);
   }
 
   getAddressFromMap(res) {
     let address = res.place_name;
     let addressArr = address.split(',');
     addressArr = addressArr.reverse();
     if(addressArr.length === 2) {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);                                                 
         formData.address_state = this.stateToISO31662(addressArr[1]);
         formData.address_municipality = '';
         formData.address_street = '';
         return { formData };                                                                                  
       }, () => {
         this.setState({
           stateLabel: {
             label: addressArr[1],
             value: this.stateToISO31662(addressArr[1]),
           }
         })
       });
     }
     else if(addressArr.length === 3) {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);                                                 
         formData.address_state = this.stateToISO31662(addressArr[1]);
         formData.address_municipality = addressArr[2];
         formData.address_street = '';
         return { formData };                                                                                  
       }, () => {
         this.setState({
           stateLabel: {
             label: addressArr[1],
             value: this.stateToISO31662(addressArr[1]),
           }
         })
       });
     } else if(addressArr.length === 4) {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);                                                 
         formData.address_state = this.stateToISO31662(addressArr[1]);
         formData.address_municipality = addressArr[2];
         formData.address_street = addressArr[3];
         return { formData };                                                                                  
       }, () => {
         this.setState({
           stateLabel: {
             label: addressArr[1],
             value: this.stateToISO31662(addressArr[1]),
           }
         })
       });
     } else if(addressArr.length === 5) {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);                                                 
         formData.address_state = this.stateToISO31662(addressArr[1]);
         formData.address_municipality = addressArr[2];
         formData.address_street = addressArr[3];
         return { formData };                                                                                  
       },() => {
         this.setState({
           stateLabel: {
             label: addressArr[1],
             value: this.stateToISO31662(addressArr[1]),
           }
         })
       });
     }
     this.setMarkerPosition(res)
   }
 
   onChangeState(state) {
     this.setState(prevState => {
       let formData = Object.assign({}, prevState.formData);
       formData.address_state = state.value;
       return { formData };
     }, () => {
       this.setState({
         stateLabel: state
       })
     });
   }
 
   renderSelectState() {
     const isoCodes = [
       {label:"Aguascalientes", value:"MX-AGU"},
       {label:"Baja California", value:"MX-BCN"},
       {label:"Baja California Sur", value:"MX-BCS"},
       {label:"Campeche", value:"MX-CAM"},
       {label:"Coahuila de Zaragoza", value:"MX-COA"},
       {label:"Colima", value:	"MX-COL"},
       {label:"Chiapas", value:"MX-CHP"},
       {label:"Chihuahua", value:"MX-CHH"},
       {label:"Ciudad de México", value:"MX-CMX"},
       {label:"Durango", value:"MX-DUR"},
       {label:"Guanajuato", value:"MX-GUA"},
       {label:"Guerrero", value:"MX-GRO"},
       {label:"Hidalgo", value:"MX-HID"},
       {label:"Jalisco", value:"MX-JAL"},
       {label:"México", value:"MX-MEX"},
       {label:"Michoacán de Ocampo", value:"MX-MIC"},
       {label:"Morelos", value:"MX-MOR"},
       {label:"Nayarit", value:"MX-NAY"},
       {label:"Nuevo León", value:"MX-NLE"},
       {label:"Oaxaca", value:"MX-OAX"},
       {label:"Puebla", value:"MX-PUE"},
       {label:"Querétaro", value:"MX-QUE"},
       {label:"Quintana Roo", value:"MX-ROO"},
       {label:"San Luis Potosí", value:"MX-SLP"},
       {label:"Sinaloa", value:"MX-SIN"},
       {label:"Sonora", value:"MX-SON"},
       {label:"Tabasco", value:"MX-TAB"},
       {label:"Tamaulipas", value:"MX-TAM"},
       {label:"Tlaxcala", value:"MX-TLA"},
       {label:"Veracruz de Ignacio de la Llave", value:"MX-VER"},
       {label:"Yucatán", value:"MX-YUC"},
       {label:"Zacatecas", value:"MX-ZAC"},
     ];
     let multipleOptions = isoCodes.map( s => ({value: s.value, label: s.label }));
     if(this.state.editMode){
       return(
         <div className='mb-4'>
           <label>
             <b>Estado
               {/* <span style={{color: '#ef5350'}} className="ml-2">*</span> */}
            </b>
          </label><br/>
           <Select
             value={this.state.stateLabel}
             onChange={(state) => this.onChangeState(state)}
             placeholder={"Selecciona el estado"}
             options={multipleOptions}
           />
           <small className="form-text text-muted">Estado donde se localiza tu empresa</small>
         </div>
       )
     } else {
       return(
         <FormInput
           id="Estado"
           ref={ref => this.fi_address_state = ref}
           label="Estado"
           model="address_state"
           showCounter={false}
           editable={false}
           editMode={false}
           value={this.getAddressState(this.state.formData.address_state) }
           initialValue={this.getAddressState(this.props.company.address_state) }
           onChange= {change => { this.onChangeForm(change) }}
           validationRules="required|string"
           help="Estado donde se localiza tu empresa"/>
       )
     }
   }
 
   renderAddressForm () {
     return (
       <div>
         <FormInput
           id="calle"
           ref={ref => this.fi_address_street = ref}
           label="Calle"
           model="address_street"
           showCounter={true}
           maxLength={100}
           value={ this.state.formData.address_street }
           initialValue={this.props.company.address_street}
           editMode={this.state.editMode}
           onChange= {change => { this.onChangeForm(change) }}
           validationRules="required|string"
           help="Calle dónde está el proyecto"/>

        <FormInput
          id="Número"
          ref={ref => this.fi_address_number_ext = ref}
          label="Número"
          model="address_number_ext"
          maxLength={10}
          showCounter={true}
          value={ this.state.formData.address_number_ext }
          initialValue={this.props.company.address_number_ext}
          editMode={this.state.editMode}
          onChange= {change => { this.onChangeForm(change) }}
          validationRules="required|string"
          help="Número"/>

          <FormInput
           id="Colonia"
           ref={ref => this.fi_address_neighborhood = ref}
           label="Colonia"
           model="address_neighborhood"
           showCounter={false}
           editMode={this.state.editMode}
           value={ this.state.formData.address_neighborhood}
           initialValue={this.props.company.address_neighborhood}
           onChange= {change => { this.onChangeForm(change) }}
           validationRules="required|string"
           help="Colonia donde se localiza"/>

         {/* <FormInput
           id="Estado"
           ref={ref => this.fi_address_state = ref}
           label="Estado"
           model="address_state"
           showCounter={false}
           editable={false}
           editMode={false}
           value={this.getAddressState(this.state.formData.address_state) }
           initialValue={this.getAddressState(this.props.company.address_state) }
           onChange= {change => { this.onChangeForm(change) }}
           validationRules="required|string"
           help="Estado donde se localiza tu empresa"/> */}
         
         <FormInput
           id="Municipio"
           ref={ref => this.fi_address_municipality = ref}
           label="Municipio"
           model="address_municipality"
           showCounter={false}
           editMode={this.state.editMode}
           value={ this.state.formData.address_municipality}
           initialValue={this.props.company.address_municipality}
           onChange= {change => { this.onChangeForm(change) }}
           validationRules="required|string"
           help="Municipio donde se localiza tu empresa"/>
 
          {this.renderSelectState()}

         <FormInput
           ref={ref => this.fi_address_zip_code = ref}
           id="código postal"
           model="address_zip_code"
           label="Código postal"
           cssClass="custom-class"
           editMode={this.state.editMode}
           value={this.state.formData.address_zip_code}
           initialValue={this.props.company.address_zip_code}
           help="Escribe el código postal de la zona donde se encuentra tu establecimiento (Mínimo 5 caracteres)."
           validationRules="isInteger|minLen:5|maxLen:5"
           maxLength={5}
           transformMethod={transformToInt}
           onChange= {change => {this.onChangeForm(change) }}
         />
 
       </div>
     )
   }
 
   renderMapBox(){
     const formData = this.state.formData;
     const latitude = formData.coordinates_latitude === '' ? '0' : formData.coordinates_latitude;
     const longitude = formData.coordinates_longitude === '' ? '0' : formData.coordinates_longitude;
 
     if(this.state.editMode){
       return(
         <div className='m-b-20'>
           Busca la calle para ubicar tu empresa, puedes arrastrar el pin ( <i className="mdi mdi-map-marker" style={{fontSize: '18px', color: 'red'}}></i> ) 
           dando clic y manteniendolo pulsado hasta ubicarlo donde elijas.
           <KMap marker={{ latitude:parseFloat( latitude ),
                           longitude:parseFloat(longitude) }}
                 onMarkerChange={(e) => this.setMarkerPosition(e)}
                 getAddress={(res) => this.getAddressFromMap(res)}
           />
         </div>
       )
     }else{
       return null
     }
   }
 
   renderAddInfoButton() {
     if(this.props.nonEditable){
       return (null);
     }
     if(this.props.company.is_disabled === false){
       if (this.state.editMode) {
         return (
           <div className="m-b-20">
             <button className="btn btn-success btn-save" onClick={ this.updateData }>
               <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                 Guardar
             </button>
           </div>
         )
       } else {
         return (
           <div className="m-b-20">
             <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
               <i className="mdi mdi-plus mr-1" style={{fontSize: '16px'}}></i> 
               Agregar información
             </button>
           </div>
         )
       }
     }else{
       return null
     }
   }
 
   renderActionButton () {
     if(this.props.nonEditable){
       return (null);
     }
     if(this.props.company.is_disabled === false){
       if (this.state.editMode) {
         return (
           <div>
              <button className="btn btn-success btn-save" onClick={ this.updateData }>
               <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                 Guardar
              </button>
 
              <button className="btn btn-danger btn-cancel" onClick={ this.openCancelSwal }>
               <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                 Cancelar
              </button>
           </div>
         )
       } else {
         return (
           <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
             <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
               Editar
           </button>
         )
       }
     }else{
       return null
     }
   }
 
   updateData () {
     if(this.validateForm()){
       this.openLoadingSwal();
 
       KomunidadApi.updateCompany(this.props.company._id, this.state.formData).then(res => {
         this.formIsEditing(false);
         Swal.close();
 
         if(!res.success){
           Swal.fire({
             type: 'error',
             title: 'No pudimos guardar tus datos',
             text: 'Hubo un error al guardar tus datos',
             footer: '',
             customClass: {
               confirmButton: 'swalBtnTeal',
               title: 'swal-custom-title',
             },
           });
         }else{
           this.props.refreshCompany();
           this.toggleEditMode();
         }
       })
     }
   }
 
   onChangeForm(data){
     let value = (data.value === null) ? '' : data.value; //sets null data to empty string
 
     this.formIsEditing(true);
 
     this.setState(prevState => {
       let formData = Object.assign({}, prevState.formData);
       formData[data.model] = value;
       return { formData };
     });
   }
 
   validateForm(){
     if(this.state.formData.address_zip_code !== ''){
       return this.fi_quote.validate() &&
             this.ft_problemToSolve.validate() &&
             this.ft_proposed_solution.validate() &&
             this.fi_address_zip_code.validate() &&
            //  this.fi_address_state.validate() &&
             this.fi_address_municipality.validate() &&
             this.fi_address_neighborhood.validate() &&
             this.fi_address_street.validate() &&
             this.fi_address_number_ext.validate() 
     } else {
       return ( this.fi_quote.validate() &&
               this.ft_problemToSolve.validate() &&
               this.ft_proposed_solution.validate());
     }
   }
 
   cancelForm(){
     //remove initial coordinates
     this.setMarkerInitialPosition();
     //remove address
     try{
      //  this.fi_address_state.cancel();
       this.fi_address_municipality.cancel();
       this.fi_address_neighborhood.cancel();
       this.fi_address_street.cancel();
       this.fi_address_number_ext.cancel();
       this.fi_address_zip_code.cancel();
     }catch(ex){
 
     }
     //remove values
     this.fi_quote.cancel();
     this.ft_problemToSolve.cancel();
     this.ft_proposed_solution.cancel();
   }
 
   setMarkerInitialPosition(){
     let company = this.props.company;
 
     this.setState(prevState => {
       let formData = Object.assign({}, prevState.formData);                                                 // creating copy of state variable marker
       formData.coordinates_latitude = company.hasOwnProperty("coordinates") ? company.coordinates[1] : '';  // update the name property, assign a new value
       formData.coordinates_longitude = company.hasOwnProperty("coordinates") ? company.coordinates[0] : ''; // update the name property, assign a new value
       return { formData };                                                                                  // return new object marker object
     });
   }
 
   setMarkerPosition(event){
     this.formIsEditing(true);
     if(event.hasOwnProperty('geometry')) {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);       // creating copy of state variable marker
         formData.coordinates_latitude = event.center[1].toString();                      // update the name property, assign a new value
         formData.coordinates_longitude = event.center[0].toString();                     // update the name property, assign a new value
         return { formData };                                      // return new object marker object
       })
     } else {
       this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);       // creating copy of state variable marker
         formData.coordinates_latitude = event.lngLat[1].toString();                      // update the name property, assign a new value
         formData.coordinates_longitude = event.lngLat[0].toString();                     // update the name property, assign a new value
         return { formData };                                      // return new object marker object
       })
     }
   }
 
   render(){
     let programName = "";
     let company_programs = this.props.company.programs;
     if(company_programs !== undefined){
       if(company_programs.length > 0){
         programName = company_programs.map(company => company.program.name).toString();
       }
     }
     return (
       <div className="card-body">
         <div className="row view-subtittle">
           <div className="col-md-9">
             {/* <p>En caso de estar inscrito en algún programa por favor ponte en contacto con el director para que puedas habilitar el area de servicios, avances y recursos. </p> */}
           </div>
         </div>
         <div className="form-horizontal">
           { this.renderAddInfoButton() }
           <FormInput
             id="nombre del programa"
             label="Programa"
             model="name"
             cssClass="custom-class"
             editMode={false}
             value={programName}
             initialValue={programName}
             help=""
             maxLength={140}
             required={false}
             validationRules=""
             onChange={ change => { this.onChangeForm(change) } }
             />
           <FormInput
             id="nombre del proyecto"
             label="Nombre"
             model="name"
             cssClass="custom-class"
             editMode={false}
             value={this.state.formData.name}
             initialValue={this.props.company.name}
             help="Escribe el nombre de tu empresa o idea de proyecto (Mínimo 3 caracteres)."
             maxLength={140}
             required={true}
             validationRules="required|minLen:3|maxLen:140"
             onChange={ change => { this.onChangeForm(change) } }
             />
 
           <FormInput
             ref={ref => this.fi_quote = ref}
             id="frase"
             label="Frase"
             model="quote"
             cssClass="custom-class"
             editMode={this.state.editMode}
             value={this.state.formData.quote}
             initialValue={this.props.company.quote}
             help="Desarrolla una frase que defina tu empresa y la gente pueda entender en menos de 140 letras. Ej. LinkedIn el Facebook de los proyectos (Mínimo 5 caracteres)."
             moreHelp="Destila la visión de tu empresa en una sola oración. Es la herramienta perfecta para los fans, usuarios e inversionistas que están ayudándote a promocionar tu nueva empresa. El truco es poder poner todo el proyecto detrás de una tarjeta de presentación. En otras palabras tienes que decir: “Somos el X para Y (Somos el Uber para Doctores)”. Debes de buscar utilizar algo conocido para que la gente lo entienda y pueda explicarlo de manera correcta."
             maxLength={90}
             validationRules="minLen:5|maxLen:90"
             onChange={ change => { this.onChangeForm(change) } }
             />
 
           <FormTextArea
             ref={ref => this.ft_problemToSolve = ref}
             id="problema"
             label="Problema"
             model="problemToSolve"
             cssClass="custom-class"
             editMode={this.state.editMode}
             value={this.state.formData.problemToSolve}
             initialValue={this.props.company.problemToSolve}
             help="Escribe ¿Qué problema resuelves? ¿Quién tiene el problema? ¿Por qué tiene el problema? Se especifico, define tu mercado, cuantifica tu mercado, define la solución actual y quita todas las palabras que sean “yo” o “nosotros” (Mínimo 50 caracteres)."
             moreHelp="Escribe primero una oración que defina cuál es el problema y quien lo tiene. Después pregúntate ¿Por qué esta población tiene este problema?. Ej. El 75% de los 60 millones de mexicanos que tienen sobre peso, intentan algún tipo de dieta sin éxito. Esto resulta en un gasto de $5,000 pesos anuales por persona que decide hacer una dieta sin éxito."
             maxLength={500}
             validationRules="minLen:50|maxLen:500"
             onChange={ change => { this.onChangeForm(change) } }
             />
 
           <FormTextArea
               ref={ref => this.ft_proposed_solution = ref}
               id="solución"
               label="Solución"
               model="proposed_solution"
               cssClass="custom-class"
               editMode={this.state.editMode}
               value={this.state.formData.proposed_solution}
               initialValue={this.props.company.proposed_solution}
               help="Describe tu producto o servicio y como este resuelve el problema para las personas que tienen dicho problema. También puedes mencionar porque tu solución es mejor que la competencia (Mínimo 20 caracteres)."
               moreHelp="Siempre debes de empezar primero por definir claramente el problema y a partir de ahi definir una solución te recordamos que no necesariamente esta solución debe de estar ya construida o lista para venderse. En esta primera etapa vamos a validar si tu idea realmente es la mejor opción para resolver el problema que te planteaste."
               maxLength={500}
               validationRules="minLen:20|maxLen:500"
               onChange={ change => { this.onChangeForm(change) } }
               />
           { this.renderMapBox() }
           { this.renderAddressForm() }
           { this.renderActionButton() }
         </div>
       </div>
     )
   }
 
   openLoadingSwal() {
     Swal.fire({
       title: 'Tus datos están siendo guardados',
       customClass: {
         title: 'swal-custom-title',
       },
       onBeforeOpen: () => {
         Swal.showLoading()
       }
     })
   }
 
   openCancelSwal() {
     Swal.fire({
       title: '¿Deseas cancelar?',
       text: "Los datos que no han sido guardados se perderán",
       type: 'warning',
       showCancelButton: true,
       confirmButtonText: 'SI',
       cancelButtonText: 'NO',
       customClass: {
         confirmButton: 'swalBtnRedOrange',
         cancelButton: 'swalBtnTeal',
         title: 'swal-custom-title',
       }
     }).then(res => {
       if (res.value) this.cancelEdit();
     })
   }
 
 }
 
 KTabIdeaContent.propTypes = {
   /** Company object */
   company        : PropTypes.object,
   /** True while the company is being edited */
   isTabEditing   : PropTypes.func,
   /** Callback to update the company data*/
   refreshCompany : PropTypes.func,
   /** True if the information can't be edited*/
   nonEditable    : PropTypes.bool,
 };
 
 KTabIdeaContent.defaultProps = {
   company        : {},
   isTabEditing   : () => {},
   refreshCompany : () => {},
   nonEditable    : false,
 };
 
 export default KTabIdeaContent;