/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, September 2019
 */

//Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import SkyLight from 'react-skylight';

//Style import
import '../../scss/pages/_landing.scss';

// Images


class Profile extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			isMouserHover: false,
			modalStyle: {},
		}

		this.onProfileImageMouseEnter = this.onProfileImageMouseEnter.bind(this);
		this.onProfileImageMouseLeave = this.onProfileImageMouseLeave.bind(this);
    this.executeBeforeModalOpen = this.executeBeforeModalOpen.bind(this);

	}

	onProfileImageMouseEnter() {
		this.setState({ isMouserHover: true});
	}

	onProfileImageMouseLeave() {
		this.setState({ isMouserHover: false});
	}

  executeBeforeModalOpen() {
    if(window.innerWidth <= 768){
      this.setState(prevState => {
        let modalStyle = Object.assign({}, prevState.modalStyle);
        modalStyle.width = '100%';
        modalStyle.left = 0;
        modalStyle.marginLeft = 0;
        modalStyle.maxHeight = '450px';
        modalStyle.overflow = 'auto'; 
        modalStyle.zIndex = 101; 
        return { modalStyle };
      })
    } else {
      this.setState(prevState => {
        let modalStyle = Object.assign({}, prevState.modalStyle);
        modalStyle.width = '50%';
        modalStyle.left = '50%';
        modalStyle.marginLeft = '-25%';
        modalStyle.maxHeight = undefined;
        modalStyle.overflow = undefined; 
        modalStyle.zIndex = 101;
        return { modalStyle };
      })
    }
  }

	renderBio() {
		return(
			<SkyLight
        dialogStyles={this.state.modalStyle}
        title={[
        	<div className="row mx-0 justify-content-md-center" key="tittlekey">
            <center>
	            <div className="pt-5">{this.props.name}</div>
            </center>
	        </div>
        ]}
        ref={ref => this.profileRef = ref}
        beforeOpen={this.executeBeforeModalOpen}
        hideOnOverlayClicked
      	>
      	<div className="row mx-0 p-30 pt-0" key="contentkey">
      		<div className="card-body">
		      	<p className="card-text">{this.props.bio}
		        </p>
	       	</div>
        </div>
      </SkyLight>
  	);
	}

	renderHover(){
  	if(this.state.isMouserHover){
	    return(
	      <div className="img-circle-overlay" style={{cursor: 'pointer',borderRadius: 0}} onClick={() => this.profileRef.show()}>
	        <div>
	          Más información
	        </div>
	      </div>
	    );
  	}
  }
	
	render () {
		return (
			<div className="col-lg-2 col-sm-3 card m-3 border-0 shadow-none">
        <center onMouseEnter={this.onProfileImageMouseEnter} onMouseLeave={this.onProfileImageMouseLeave}>
          {this.renderHover()}
          {this.renderBio()}
          <img className="card-img-top img-responsive img-circle" style={{borderRadius: 0}}src={this.props.image} alt={this.props.name}/>
        </center>
        <div className="card-body text-center m-3 p-30 p-0 pt-3">
          <h4 className="card-text">{this.props.name}</h4>
          <small>{this.props.job}</small>
        </div>
      </div>
		);
	}

}

Profile.propTypes = {
  image: PropTypes.any,
  bio: PropTypes.string,
  name: PropTypes.string,
  job: PropTypes.string,
};

Profile.defaultProps = {
  image: '',
  bio: '',
  name: '',
  job: '',
};

export default Profile;
