/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Israel@startuplab.mx>, January 2020
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';

//components
import KResourcesRow from './KResourcesRow';


class KResourcesList extends React.Component {

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al mostrar los recursos</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderEmptyList(){
    return(
      <div className="text-center m-t-30">
        <h3>Aún no hay recursos registrados en {this.props.programName}</h3>
        <i className="mdi mdi-folder-remove m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
      </div>
    )
  }

  renderSelectResource() {
    return(
      <div className="text-center m-t-30">
        <h3>Selecciona un programa para ver su material de apoyo</h3>
        <i className="mdi mdi-folder m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
      </div>
    )
  }

  render() {
    const resources = this.props.resources;
    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(resources.length > 0){
        return(
          <div className='row m-t-30'>
              {resources.map((item,index) =>
                (
                  <KResourcesRow key={index}
                                   resource={item}
                                   onClickResource={(resource) => this.props.onClickResource(resource)}
                  />
                )
              )}
          </div>
        )
      }
      else{
        return this.renderEmptyList()
      }
    }else if(this.props.requestStatus === 'default') {
        return this.renderSelectResource()
    }else{
      return this.renderFailed()
    }
  }
}

KResourcesList.propTypes = {
  resources             : PropTypes.array,
  requestStatus       : PropTypes.string, //idle,fetching,success,failed
  programName         : PropTypes.string
};

KResourcesList.defaultProps = {
  resources             : [],
  requestStatus       : 'idle',
  programName         : ''
};

export default KResourcesList;
