/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, August 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

//components
import KImage from '../components/KImage';
import { renderFullNameOrEmail } from '../utils/Utils.js';


/**
 *  Render the stats of all the instalation.
 */

class KNewRow extends React.Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
  }

  formatDate(date){
    if(date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }

  formatType(type) {
    if(type === 'partner') {
      return 'emprendedor';
    } else return 'aliado';
  }

  renderContent(el) {
    if(el.hasOwnProperty('type')) {
      if( el.type === 'partner' || el.type === 'mentor'){
        return this.renderUser()
      } else if( el.type === 'program' ) {
        return this.renderProgram()
      } else {
        return this.renderCompany()
      }
    } else return null;
  }

  renderUser() {
    const user = this.props.new;
    return(
      <div className="kuRow">
        <KImage src={user.profileUrlThumbnail} className={"kuImage m-l-5 m-r-5"} alt={"user_img"}/>
        <div className="kuWrapper">
          <div><h6 className="m-b-0">{renderFullNameOrEmail(user)}</h6></div>
            <div className="kuEmail text-muted">{this.formatDate(user.created_at)}</div>
            <span className="text-muted font-14">
              Se ha registrado como {this.formatType(user.type)}
            </span>
        </div>
      </div>
    )
  }

  renderProgram() {
    const program = this.props.new;
    return (
      <div className="kuRow">
        <i className="mdi mdi-book m-l-10 m-r-10" style={{fontSize: '40px'}}></i>
        <div className="kuWrapper">
          <div><h6 className="m-b-0">{program.name}</h6></div>
            <div className="kuEmail text-muted">{this.formatDate(program.created_at)}</div>
            <span className="text-muted font-14">
              Programa creado
            </span>
        </div>
      </div>
    )
  }

  renderCompany() {
    const company = this.props.new;
    return (
      <div className="kuRow">
        <KImage src={company.logoThumbnail} type='company' className='companyImage' alt="company_image"/>
        <div className="kuWrapper">
          <div><h6 className="m-b-0">{company.name}</h6></div>
            <div className="kuEmail text-muted">{this.formatDate(company.created_at)}</div>
            <span className="text-muted font-14">
              Empresa registrada
            </span>
        </div>
      </div>
    )
  }

  render(){
    return(
      <div className="manager-item">
        {this.renderContent(this.props.new)} 
      </div>
    )
  }
}

KNewRow.propTypes = {
  /** The new object with the fields:
      user, review, created_at*/
  new            : PropTypes.object,
};

KNewRow.defaultProps = {
  new             : {},
};

export default KNewRow;
