/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>, June 2021
 */

// Regular imports
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import KomunidadApi from '../api/api.js';

// Custom styling
import './../scss/pages/pages.scss';
import './../scss/pages/header.css';
// Images
import {getAppLogo} from "../utils/Vendor.js";


//Components
import KImage from '../components/KImage';

//const
const MySwal = withReactContent(Swal);

const Logo = getAppLogo();

class HeaderCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallLogo: true,
    };
    this.onClickBtn        = this.onClickBtn.bind(this);
    this.logout            = this.logout.bind(this);
    this.confirmLogout     = this.confirmLogout.bind(this);
    this.updateDimensions  = this.updateDimensions.bind(this);
    this.updateLogo        = this.updateLogo.bind(this);
  }
  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  updateDimensions(){
    var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    this.props.onResize(width);
    this.updateLogo();
  }
  logout(){
    KomunidadApi.logout().then((res) => {
      if(res.success){
        this.redirectHome();
      }else{
        alert('Hubo un problema al cerrar tu sesión.')
      }
    });
  }

  confirmLogout(){
    MySwal.fire({
      title: '¿Estás seguro(a) de cerrar sesión?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title'
      }
    }).then((result) => {
      if (result.value) {
        this.logout();
      }
    })
  }

  redirectHome(){
    this.props.history.push('/');
  }
  onClickBtn(){
    this.props.onClickBtn();
  }

  updateLogo(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        smallLogo: !this.state.smallLogo,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          smallLogo: true
        })
      } else {
        this.setState({
          smallLogo: false
        })
      }
    }
  }

  renderBtn(){
    if(!this.props.removeBtn){
      return(
        <Button variant="secondary" onClick={this.onClickBtn}>
          {this.props.titleBtn}
        </Button>
      )
    }else{
      return null;
    }
  }
  renderBurger(){
    if(this.props.hideBurger){
      return(
        <ul className="navbar-nav mr-auto">
        </ul>
      )
    }else{
      return(
        <ul className="navbar-nav mr-auto">
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 nav-toggler hidden-md-up waves-effect waves-dark"><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 sidebartoggler hidden-sm-down waves-effect waves-dark" ><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item hidden-sm-down" onClick={() => {this.props.onBurgerClicked(); this.updateLogo();}}></li>
        </ul>
      )
    }
  }

  renderUserLogged() {
    if(this.props.user.hasOwnProperty('_id')) {
      return(
        <li className="nav-item dropdown">
            <p className="nav-link dropdown-toggle waves-effect waves-dark m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <KImage src={this.props.user.profileUrlThumbnail}
                      className={"profile-pic"}
                      alt={"user_img"}
                      type={"user_"+this.props.user.gender}
              />
            </p>
            <div className="dropdown-menu dropdown-menu-right animated flipInY">
                <ul className="dropdown-user">
                    <li>
                        <div className="dw-user-box">
                            <div className="u-img">
                              <KImage src={this.props.user.profileUrlThumbnail}
                                      className={"u-img"}
                                      alt={"user_img"}
                                      type={"user_"+this.props.user.gender}
                              />
                            </div>
                            <div className="u-text">
                              <h4>{this.props.user.name}</h4>
                              <p className="text-muted" style={{fontSize:'x-small'}}>{this.props.user.email}</p>
                            </div>
                        </div>
                    </li>
                    <li><Link to={'/profile'}><i className="mdi mdi-account" style={{fontSize: '20px'}}></i> Mi perfil</Link></li>
                    <li><a href="https://komunidad.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-help-circle" style={{fontSize: '20px'}}></i> Ayuda</a></li>
                    <li><a className="logout_link" onClick={() => this.confirmLogout()}><i className="mdi mdi-power" style={{fontSize: '20px'}}></i> Salir</a></li>
                </ul>
            </div>
        </li>
      )
    } else {
      return (
        <div className="d-inline-flex">
          {/* <span className="m-auto"></span> */}
          <Link className="btn btn-link m-0" to={{ pathname: '/sign-up'}}>¿Quieres ser parte del directorio de Komunidad?</Link>
          {/* <Link className="btn btn-info px-2" to={{ pathname: '/'}}>Inicia sesión</Link> */}
        </div>
      );
    }
  }

  renderBurger(){
    if(this.props.hideBurger){
      return(
        <ul className="navbar-nav mr-auto">
        </ul>
      )
    }else{
      return(
        <ul className="navbar-nav mr-auto">
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 nav-toggler hidden-md-up waves-effect waves-dark"><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 sidebartoggler hidden-sm-down waves-effect waves-dark" ><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item hidden-sm-down" onClick={() => {this.props.onBurgerClicked(); this.updateLogo();}}></li>
        </ul>
      )
    }
  }

  render() {
    return (
      <header className="topbar">
          <nav className="navbar top-navbar navbar-expand-md navbar-light">
              {/* Logo */}
              <div className="navbar-header">
                  <Link className="navbar-brand" to="/companies">
                      {/* Logo text */}
                      <div className={this.state.smallLogo ? "header-bg-ksq":"header-bg-k"}>
                           {/* dark Logo text */}
                           <div className="dark-logo AppLogoHeader"></div>
                           {/* Light Logo text */}
                           <div className="light-logo AppLogoHeader"></div>
                        </div>
                  </Link>
              </div>
              {/* End Logo */}
              <div className="navbar-collapse">
                  {this.renderBurger()}
                  {/* User profile*/}
                  <ul className="navbar-nav my-lg-0">
                      {/* Profile */}
                      {this.renderUserLogged()}
                  </ul>
              </div>
          </nav>
      </header>
    );
  }
}

HeaderCatalog.propTypes = {
  titleBtn: PropTypes.string,
  removeBtn:PropTypes.bool,
  onClickBtn:PropTypes.func,
  onBurgerClicked:PropTypes.func,
  onResize:PropTypes.func,
  user: PropTypes.object,
  hideBurger: PropTypes.bool,
};

HeaderCatalog.defaultProps = {
  onClickBtn: ()=>{},
  titleBtn: 'Entrar',
  removeBtn:false,
  onBurgerClicked: () => {},
  onResize:(width) => {},
  hideBurger: false,
  user:{
      _id                     :null,
      name                    :null,
      email                   :null,
      created_at              :null,
      updatedAt               :null,
      source                  :null,
      uid                     :null,
      profileUrlThumbnail     :null,
      goverment_id_curp       :null,
      social_linkedin         :null,
      social_facebook         :null,
      social_twitter          :null,
      social_other            :null,
      goverment_id_rfc        :null,
      dateOfBirth             :null,
      phone                   :null,
      gender                  :null,
      roles                   :null,
  },
};
export default withRouter(HeaderCatalog);
