/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, May 2020
 */

// Regular imports
import React from 'react';
import PropTypes from 'prop-types';

//Style
import '../scss/components/_k_select_company_widget.scss';

//Components
import KImage from './KImage';


/**
*   Render a list of selected companies. The companies can be romoved.
*/

class KSelectCompanyWidget extends React.Component {
  constructor(props) {
    super(props);
    this.onClickRemoveItem           =  this.onClickRemoveItem.bind(this);
  }

  onClickRemoveItem(index){
    this.props.onClickRemoveItem(index);
  }

  renderRemoveBtn(index){
    if(this.props.showRemoveBtn){
      return(
        <i className="mdi mdi-close cursor-pointer" style={{fontSize: '20px', color: "#c2c2c2"}} onClick={() => this.onClickRemoveItem(index)}></i>
      )
    }else{
      return null;
    }
  }

  renderLabel(){
    const label = this.props.label;
    if(label !== '' && this.props.companies.length > 0){
      return(
        <label><b>{label}</b></label>
      )
    }else{
      return null
    }
  }

  render() {
    const companies = this.props.companies;
    return(
      <div className='company-widget-wrapper'>
        {this.renderLabel()}
        <div className={`company-widget ${this.props.classNameWrapper}` }>
          {companies.map((company,index) =>
            (
              <div className='c-w-element' key={index}>
                <KImage src={company.logoThumbnail} type='company' className='c-w-image' alt="company_image"/>
                <span className='p-l-5 p-r-10'>{company.name}</span>
                {this.renderRemoveBtn(index)}
              </div>
            )
          )}
        </div>
      </div>
    )

  }
}

KSelectCompanyWidget.propTypes = {
  /** List of companies*/
  companies             : PropTypes.array,

  /** Callback when the company is removed*/
  onClickRemoveItem     : PropTypes.func,

  /** ClassName of the wrapper :*/
  classNameWrapper      : PropTypes.string,

  /** When is false hide the remove btn:*/
  showRemoveBtn         : PropTypes.bool,

  /** Label:*/
  label                 : PropTypes.string,
};

KSelectCompanyWidget.defaultProps = {
  companies             : [],
  onClickRemoveItem     : () => {},
  classNameWrapper      : '',
  showRemoveBtn         : true,
  label                 : 'Empresas seleccionadas:'
};

export default KSelectCompanyWidget;
