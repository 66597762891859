/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx> September 2021
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';

// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/_mentors-view.scss';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KCoursesGrid from '../../components/KCoursesGrid';
import ProgramsCoursesPicker from '../../components/ProgramsCoursesPicker';
import { Tab, Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { isUserProfileComplete } from '../../utils/Utils.js';


class CoursesListViewFree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: true,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      me: {},
      preuser_email: '',
      mentorsLoadState: 'idle',
      multipleOptions: [],
      my_courses: [],
      uncompleted_courses: [],
      courses: [],
      completed_courses: [],
      activeKey: "courses_by_program",
      displayMode: 'grid', //state values grid and list
      sortMode: 'asc',
      selectedId: ['mine'],
      isMyCourse: true,
      isPartner: false,
    }
    this.handleResizeBar = this.handleResizeBar.bind(this);
    this.getPreUserCourses = this.getPreUserCourses.bind(this);
    this.handleHeaderResize = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile = this.redirectToEditProfile.bind(this);
    this.onProgramSelected = this.onProgramSelected.bind(this);
    this.hasPartnerRole = this.hasPartnerRole.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('email');
    let name = params.get('name');

    if (email !== null) {
      this.setState({
        preuser_email: email,
        name: name
      });
    }

    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile() {
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if (method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if (windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  getPreUserCourses(query) {
    KomunidadApi.getPublicCourses(query).then((res) => {
      if (res.success) {
        if (res.courses.length > 0) {
          let courses = this.sortCoursesByName(res.courses)
          this.setState({ courses: courses, isMyCourse: false });
        } else {
          this.setState({ courses: [] });
        }
      } else {
        this.setState({ courses: [] });
      }
      //TODO: swal error or msg in table with table_msg
    });
  }

  changeTab(key) {
    this.setState({ activeKey: key });
  }

  sortCoursesByName(courses) {
    courses.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return courses
  }

  onProgramSelected(id) {
    let programId = id[0];
    this.setState({
      selectedId: id
    })
    return this.getPreUserCourses(programId);
  }

  hasPartnerRole() {
    if (this.state.me.roles.includes('partner')) {
      this.setState({ isPartner: true })
    } else {
      this.setState({ isPartner: false });
    }
  }

  renderCoursesGrid() {
    return (
      <KCoursesGrid
        courses={this.state.courses}
        requestStatus={'success'}
        freeMode={true}
        programId={this.state.selectedId[0]}
        onClickCourse={(course) => this.openConfirmationModal(course)}
        isMyCourse={this.state.isMyCourse} />
    )
  }

  onClickCourse(course) {
    let email = this.state.preuser_email;

    if (email !== '') {
      this.props.history.push({
        pathname: '/resend_email/' + email,
        search: `?name=${this.state.name}`
      });
    } else {
      this.props.history.push('/sign-up');
    }
  }

  resendPrc(course) {
    let email = this.state.preuser_email;
    if (email !== '') {
      KomunidadApi.userPreRegister(this.state.preuser_email).then(res => {
        if (res.body.success) {
          this.swalSuccess(this.state.preuser_email);
        } else {
          this.swalError(this.state.preuser_email);
        }
      }).catch(err => { });
    } else {
      this.props.history.push('/sign-up');
    }
  }

  openConfirmationModal() {
    let email = this.state.preuser_email;

    if (email !== '') {
      Swal.fire({
        title: 'Confirma tu correo',
        html: `Para desbloquear todo el contenido de CREO MX revisa tu correo electrónico <b>${this.state.preuser_email}</b> (no olvides la carpeta de SPAM) y confirma tu cuenta.`,
        type: 'info',
        confirmButtonText: 'Reenviar correo',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {
        console.log(result)
        if (result.value) {
          this.resendPrc();
        }
      });
    } else {
      this.props.history.push('/sign-up');
    }
  }

  swalSuccess(email) {
    Swal.fire({
      title: 'Reenvío exitoso',
      html: `Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    })
  }

  swalError(email) {
    Swal.fire({
      title: 'Felicidades ya tienes cuenta en Komunidad',
      html: `El correo <b>${email}</b> ya se encuentra registrado, serás redireccionado al login para iniciar sesión`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    }).then(() => {
      this.props.history.push('/');
    })
  }

  renderContent() {
    return this.renderCoursesGrid();
  }

  renderMyCourses() {
    return null;
  }

  renderCoursesByProgram() {
    return this.renderCoursesGrid();
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className={this.state.courses.length <= 4 ? "height100" : null}>
            {/* Start Page Content */}
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
              freeMode={true}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
              freeMode={true}
              onClickSidebar={() => this.openConfirmationModal()}
            />
            <SideBar
              user={this.state.me}
              visible={this.state.sidebarStatus}
              hideSideBar={this.state.showTopbar}
              freeMode={true}
              onClickSidebar={() => this.openConfirmationModal()}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open' : '')}>
              <div className="container-fluid">

                <div className="row page-titles">
                  <div className="col-md-12 align-self-center">
                    <h3 className="text-themecolor">Academia</h3>
                  </div>
                </div>
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                      <Tab.Container
                        activeKey={this.state.activeKey}
                        onSelect={(k) => this.changeTab(k)}
                        defaultActiveKey="courses_by_program">

                        <Nav className="nav" bsPrefix='tabCustom' >
                          <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link" eventKey="courses_by_program" id='courses_by_program'>Cursos disponibles</Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey="courses_by_program">
                            <div className="card-body">
                              <div className="row mb-3">

                                <div className="col-md-9 align-self-center">
                                  <label className="font-weight-bold">
                                    Para inscribirte en los cursos especializados registra tu cuenta en komunidad
                                  </label>
                                </div>
                                {/* <div className="col-md-3 align-self-center">
                                        FOR BUTTONS
                                  </div> */}
                                <div className="col-md-6 align-self-center">
                                  <ProgramsCoursesPicker onSelect={this.onProgramSelected}
                                    showMyCourses={false}
                                    multipleProgramSelected={this.state.selectedId}
                                    freeMode={true}
                                  />
                                </div>
                              </div>
                              {this.renderCoursesByProgram()}
                            </div>
                          </Tab.Pane>

                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End PAge Content */}
              </div>
              <Footer />
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }
}
export default CoursesListViewFree;
