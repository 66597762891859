/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal,Button} from 'react-bootstrap';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import KUsersList from '../components/KUsersList';
import KImage from '../components/KImage';
import FormInput from '../components/form/FormInput';

import { renderFullNameOrEmail} from '../utils/Utils.js';

/**
 * Modal to add a partner.
 * Search or invite a user as a partner to a company.
 */

class KAddPartnersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageIsVisible:true,
      message:null,
      users:[],
      isUserSelected:false,
      userSelected:{},
      isButtonDisable:false,
      showBtnBack:false,
      isModalOpen: false,
      query:'',
    };
    this.setMsg                      =  this.setMsg.bind(this);
    this.onClickSend                 =  this.onClickSend.bind(this);
    this.validatorEmail              =  this.validatorEmail.bind(this);
    this.clearMessage                =  this.clearMessage.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
    this.onChange                    =  this.onChange.bind(this);
    this.onSelectUser                =  this.onSelectUser.bind(this);
    this.backNavigation              =  this.backNavigation.bind(this);
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleOpenModal             =  this.handleOpenModal.bind(this);
    this.clear                       =  this.clear.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isModalOpen) {
      this.handleOpenModal(true)
    }else{
      this.handleOpenModal(false)
    }
  }

  componentWillUnmount(){
    this.executeAfterModalClose();
  }

  handleOpenModal(show){
    this.setState({isModalOpen:show});
  }

  validatorEmail(email){
    let messages = {
      invalid_email: "El email es inválido",
      invalid_len: "El email no puede estar vacío",
    }
    let validatorResponse = {
      result:false,
      reason:null
    }

    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

    if(email.toString().trim() === ''){
      validatorResponse.reason = messages.invalid_len;
      validatorResponse.result = false;
    }else if( re.test(String(email).toLowerCase()) ){
      validatorResponse.reason = null;
      validatorResponse.result = true;
    }else{
      validatorResponse.reason = messages.invalid_email;
      validatorResponse.result = false;
    }
    return validatorResponse;
  }


  isUserRegistered(user){
    if(user.hasOwnProperty('_id')){
      return true;
    }else{
      return false;
    }
  }

  inviteUserRegistered(user,position){
    let messages ={
      "not_sent"  :"Hubo un error al enviar tus datos",
      'invalid_id':"Hubo un error al enviar tus datos",
      'sent'      :"Tu invitación ha sido enviada",
    }

    if(this.props.companyId !== null){
      KomunidadApi.addPartner(user._id,position,this.props.companyId).then((res) => {
        if(res.success){
          this.hideModal();
          this.props.onPartnersAdded();
        }else{
          Swal.fire({
						type: 'error',
						title: 'Error al enviar invitación:',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
						text: res.error_es,
						footer: ''
					});
        }
      });
    }else{
      this.setMsg(true,messages['invalid_id']);
    }
  }

  inviteUserNotRegistered(email,position){
    let messages ={
      "not_sent"  :"Hubo un error al enviar tus datos",
      'invalid_id':"Hubo un error al enviar tus datos",
      'sent'      :"Tu invitación ha sido enviada",
    }

    if(this.props.companyId !== null){
      //invite user not registered
      KomunidadApi.inviteUserNotRegistered(email,position,this.props.companyId).then((res) => {
        if(res.success){
          this.setMsg(true,messages['sent']);
          this.setState({isButtonDisable:true});
          setTimeout(this.clearMessage, 3000);
          setTimeout(this.hideModal, 2000);
          this.props.onPartnersAdded();
        }else{
          Swal.fire({
            type: 'error',
            title: 'Error al enviar invitación:',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            text: res.error_es,
            footer: ''
          });
          this.setMsg(true,messages['not_sent']);
          setTimeout(this.clearMessage, 3000);
        }
      });
    }else{
      this.setMsg(true,messages['invalid_id']);
      setTimeout(this.clearMessage, 3000);
    }
  }

  hideModal(){
    this.handleOpenModal(false);
  }

  onClickSend(){
      let position = 'Socio';

      //get the value of the user selected
      let userSelected = this.state.userSelected;
      let isUserRegistered = this.isUserRegistered(userSelected);

      //Check if the user is registered. If the user is not registered invite by email.
      if(isUserRegistered){
        //sent invitation to user registered
        this.inviteUserRegistered(userSelected,position);
      }else{
        //send invitation to user not registered
        this.inviteUserNotRegistered(userSelected.email,position);
      }
    // }
  }

  setMsg(messageIsVisible,msg,type){
    this.setState({
      messageIsVisible:messageIsVisible,
      message:msg
    });
  }

  clearMessage(){
    this.setMsg(true,'');
  }

  renderMessage(){
    if(this.state.messageIsVisible){
      return (this.state.message)
    }
  }


  executeAfterModalClose(){
    this.clear();
    this.clearMessage();
    this.props.closeModal();
  }
  onChange(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    let isValidEmail = this.validatorEmail(value).result;

    this.setState({query:value});

    if(value.trim() !== '' && !data.isTriggeredByOnBlur){
      KomunidadApi.findUsers(value).then((res) => {
        if(res.success){
          if(res.results.length >= 0){
            if( isValidEmail  && (res.results.length === 0)){
              const userNotFound = [{name:null,
                                     last_name_father:null,
                                     last_name_mother:null,
                                     email:value,
                                     profileUrlThumbnail:null,
                                     gender:null,
                                     not_found:true
                                   }];

              this.setState({users:userNotFound});
            }else{
              this.setState({users:res.results});
            }
          }
        }
      });
    }
  }

  onSelectUser(user){
    this.setState({showBtnBack:true,userSelected:user,isUserSelected:true});
  }

  renderModalContent(){
    if(this.state.isUserSelected){
      return(
        <div className='KAPMWrapper'>
          <div className='KAPMUser'>
            <h4 className="m-b-20">Estás por agregar a <b>{renderFullNameOrEmail(this.state.userSelected)}</b> como tu nuevo socio(a)</h4>
            <KImage src={this.state.userSelected.profileUrlThumbnail} className={"KAPMImage"} type={"user_"+this.state.userSelected.gender} alt={"user_img"}/>
            <div className="KAPMName">
              Tu socio(a) <b className='KAPMBold'>{renderFullNameOrEmail(this.state.userSelected)}</b> tendrá
              los mismos derechos que el creador de la empresa
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div className='KAPMWrapper'>
          <div className='row'>
            <div className="col form-horizontal">
              <FormInput
                ref={ref => this.kInputEmail = ref}
                editMode={true}
                id="nombre o email"
                label="Escribe el nombre o correo de la persona para buscarlo"
                placeholder={"Nombre o email"}
                cssClass="m-b-0"
                value={this.state.query}
                help="Escribe el nombre o correo de la persona para buscarlo."
                required={true}
                validationRules="required|string"
                onChange={ change => { this.onChange(change) }}
                showCounter={false}
                />
            </div>
          </div>
          <div className={'wrapper__kUser__list'}>
            <KUsersList users={this.state.users} onClickUser={this.onSelectUser}/>
          </div>
        </div>
      )
    }
  }

  renderBtnBack(){
    if(this.state.showBtnBack){
      return(
        <div className="modal__btn__back" onClick={this.backNavigation}>
           <i className="mdi mdi-chevron-left" style={{fontSize: '30px', color: "#c2c2c2"}}></i>
        </div>
      )
    }else{
      return null
    }
  }

  backNavigation(){
    this.clear();
  }

  renderInvitationBtn(){
    if(this.state.isUserSelected){
      return(
        <Button className="float-right btn btn-info btn-save"
          disabled={this.state.isButtonDisable}
          onClick={this.onClickSend}>
          Enviar invitación
        </Button>
      )
    }else{
      return null
    }
  }
  clear(){
    this.setState(
      {
        users:[],
        isUserSelected:false,
        userSelected:{},
        isButtonDisable:false,
        showBtnBack:false,
        query:''
      }
    );
  }
  onShow(){
    this.clear();
    this.kInputEmail.focus();
  }
  render() {
    return (
      <Modal  show={this.state.isModalOpen}
              onShow={() => this.onShow()}
              onHide={() => this.handleOpenModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
        <Modal.Header closeButton>
          {this.renderBtnBack()}
          <Modal.Title id="contained-modal-title-vcenter">
            Agregar socios
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'modal__body__height'}>
          {this.renderModalContent()}
          <div className="KAPMMessage">
            <b>{this.renderMessage()}</b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className={'btn btn-danger btn-cancel'} onClick={() => this.handleOpenModal(false)}>Cerrar</Button>
          {this.renderInvitationBtn()}
        </Modal.Footer>
      </Modal>
    );
  }
}

KAddPartnersModal.propTypes = {
  /** When true the modal will show itself. */
  isModalOpen      : PropTypes.bool,
  /** Company id. */
  companyId        : PropTypes.string,
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** Callback when the partner is added*/
  onPartnersAdded  : PropTypes.func,
};

KAddPartnersModal.defaultProps = {
  isModalOpen     : false,
  companyId       : null,
  closeModal      : () => {},
  onPartnersAdded : () => {},
};

export default KAddPartnersModal;
