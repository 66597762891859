/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';

//Components
import KPartnersRow from './KPartnersRow';

/**
*   Display a list of partners using the row [KPartnersRow](/#kpartnersrow).
*   Show the button to delete a partner.
*/

class KPartnersList extends React.Component {

  render() {
    const partners = this.props.partners;

    if(this.props.failedLoad){
      return (
        <Table responsive borderless>
          Hubo un problema al cargar a los socios
        </Table>
      )
    }

    if(partners.length > 0){
      return (
        <Table responsive className='tableBorderB tableVMiddle' >
          <tbody>
            {partners.map((item,index) =>
              (
                <KPartnersRow key={index}
                              partner={item}
                              onClickDeletePartner={this.props.onClickDeletePartner}
                              showEmail={this.props.showExtraContactButtons}
                              showWhatsapp={this.props.showExtraContactButtons}
                              isUserOwner={this.props.isUserOwner}
                />
              )
            )}
          </tbody>
        </Table>
      );
    }else{
      return null
    }
  }
}

KPartnersList.propTypes = {
  /** Partner list. */
  partners             : PropTypes.array,
  /** Callback on mentor deleted. */
  onClickDeletePartner : PropTypes.func,
  /** True when the data can't be loaded */
  failedLoad           : PropTypes.bool,
  /** True when the user is owner. */
  isUserOwner          : PropTypes.bool,
  /** Shows or hide extra contact buttons **/
  showExtraContactButtons : PropTypes.bool


};

KPartnersList.defaultProps = {
  partners                : [],
  onClickDeletePartner    : ()=>{},
  failedLoad              : false,
  isUserOwner             : false,
  showExtraContactButtons : false


};

export default KPartnersList;
