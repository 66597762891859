/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, August 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 *  Render the stats of all the instalation.
 */

class KStatsManager extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    return(
      <div>
        <div className="row">
          <div class="col-lg-3 col-md-6">
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Empresas</h5>
                      <div class="row mt-3">
                          <div class="col-5">
                              <div class="btn btn-circle btn-sm btn-manager-stats btn-success text-white" >
                                <i class="mdi mdi-lightbulb-on"></i>
                              </div>
                          </div>
                          <div class="col-6 ml-auto text-right">
                              <h2 class="font-weight-medium text-success mb-0">{this.props.data.companies.length}</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6">
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Programas</h5>
                      <div class="row mt-3">
                          <div class="col-5">
                              <div class="btn btn-circle btn-sm btn-manager-stats btn-primary text-white" >
                                <i class="mdi mdi-book"></i>
                              </div>
                          </div>
                          <div class="col-6 ml-auto text-right">
                              <h2 class="font-weight-medium text-primary mb-0">{this.props.data.programs.length}</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6">
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Aliados</h5>
                      <div class="row mt-3">
                          <div class="col-5">
                              <div class="btn btn-circle btn-sm btn-manager-stats btn-info text-white" >
                                <i class="mdi mdi-account-star-variant"></i>
                              </div>
                          </div>
                          <div class="col-6 ml-auto text-right">
                              <h2 class="font-weight-medium text-info mb-0">{this.props.data.mentors.length}</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6">
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Emprendedores</h5>
                      <div class="row mt-3">
                          <div class="col-5">
                              <div class="btn btn-circle btn-sm btn-manager-stats btn-danger text-white" >
                                <i class="mdi mdi-account-multiple"></i>
                              </div>
                          </div>
                          <div class="col-6 ml-auto text-right">
                              <h2 class="font-weight-medium text-danger mb-0">{this.props.data.partners.length}</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


        </div>
      </div>
    )
  }
}

KStatsManager.propTypes = {
  /** data object */
  data                : PropTypes.object,
}

KStatsManager.defaultProps = {
  data                : { programs:[], companies:[], partners:[], mentors:[]  },
}

export default KStatsManager;
