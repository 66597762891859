/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, February 2020
 */

import React from 'react';
import KomunidadApi from '../api/api.js';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { orderBy } from 'lodash';

/**
 *  Selects one of your active companies.
 *  The select is the component 'react-select'.
 */

class CompanyPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies:[],
      companiesSelected:[],
      options:[],
    }
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.handleSelect           = this.handleSelect.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getCompanies().then( (res) => {
      if(res.hasOwnProperty('companies')){
        let companies = res.companies.filter((company) => company.is_disabled === false );
        let sortedCompanies = this.sortCompaniesByDate(companies);
        this.setMultipleOptions(sortedCompanies);
      }
    });
  }

  setMultipleOptions(companies){
    let options = companies.map(company => ({ value: company._id, label: company.name,company:company}));

    this.setState({options:options});
  }


  handleSelect(selectedOptions){
    this.setState({companiesSelected:selectedOptions});
    this.props.onSelect(selectedOptions);
  }

  sortCompaniesByDate(companies){
    if(companies.length > 0){
      companies = orderBy(companies, ['created_at'], ['desc']);
      return companies;
    }else{
      return [];
    }
  }

  removeSelections(){
    this.setState({companiesSelected:[]});
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>Nombre de la empresa</label>
      )
    }else{
      return null;
    }
  }

  render(){
    const customStyles = {
      container: (provided) => ({
          ...provided,
          zIndex: 20,
        }),
    }
    return(
      <div className='form-group'>
        {this.renderLabel()}
        <Select
          value={this.state.companiesSelected}
          onChange={this.handleSelect}
          options={this.state.options}
          placeholder={this.props.placeholder}
          // styles={customStyles}
        />
      </div>
    )
  }
}

CompanyPicker.propTypes = {
  /** Returns the company id when a company is selected. */
  onSelect                : PropTypes.func,
  /** Default selection, array of ids. */
  companiesSelected       : PropTypes.array,
  /** Placeholder of the select */
  placeholder             : PropTypes.string,
  /** Show the label of the input select. */
  showLabel               : PropTypes.bool,
}

CompanyPicker.defaultProps = {
  onSelect                : (program) => {},
  companiesSelected       : [],
  placeholder             : 'Selecciona una empresa',
  showLabel               : true,
}

export default CompanyPicker;
