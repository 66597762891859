/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, June 2019
 */
var komunidadApi = {
  API_URL: process.env.REACT_APP_API_URL,

  /*
   * Gets current logged in user
   */
  getMe() {
    return fetch(komunidadApi.API_URL + "/users/me", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Creates a login request
   */
  login(email, password) {
    let params = {
      email: email,
      password: password,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Checks if a user is registered based on a email
   */
  userExists(email) {
    let params = {
      email: email,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/exists", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets a preregister based on an email
   */
  userPreRegister(email, name, last_name_father, last_name_mother, phone, programId) {
    let params = {
      email: email,
      name: name,
      last_name_father: last_name_father,
      last_name_mother: last_name_mother,
      phone: phone,
      programId: programId
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/preregister", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then(response => response.json().then(data => ({status: response.status, body: data})))
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets a preregister based on an email
   */
  userRegisterNew(email, name, last_name_father, last_name_mother, phone, password) {
    let params = {
      email: email,
      name: name,
      last_name_father: last_name_father,
      last_name_mother: last_name_mother,
      phone: phone,
      password: password
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/register_new", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
        .then(response => response.json().then(data => ({status: response.status, body: data})))
        .then((responseJson) => {
          return responseJson;
        })
        .catch((error) => {
          return error;
        });
  },
  /*
   * Invites an user via email with roles.
   */
  userPreRegisterMentor(email, roles, programs_ids) {
    let params = {
      email: email,
      roles: roles,
      programs_ids: programs_ids,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/preregister", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets a PRC
   */
  getPrc(prc) {
    return fetch(komunidadApi.API_URL + `/users/preregister?prc=${prc}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Creates a password based on a PRC (For creating an account)
   */
  createPassword(prc, password) {
    let params = {
      prc: prc,
      password: password,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Logouts the current user
   */
  logout() {
    return fetch(komunidadApi.API_URL + "/users/logout", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Updates the user profile image
   */
  uploadUserImage(file) {
    let formBody = new FormData();
    formBody.append("file", file);
    return fetch(komunidadApi.API_URL + "/users/me/update_photo", {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Updates the user profile image
   */
  managerUploadUserImage(file,user_id) {
    let formBody = new FormData();
    formBody.append("file", file);
    return fetch(  `${komunidadApi.API_URL}/users/${user_id}/update_photo`, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
        .then((response) => response.json())
        .then((responseJson) => {
          return responseJson;
        })
        .catch((error) => {
          return error;
        });
  },
  updateUser(params) {
    let url = `${komunidadApi.API_URL}/users/me/update`;
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    return fetch(url, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => responseJson);
  },
  managerUpdateUser(params,user_id) {
    let url = `${komunidadApi.API_URL}/users/${user_id}/update`;
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    return fetch(url, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
        .then((response) => response.json())
        .then((responseJson) => responseJson);
  },
  /*
   * Updates the user profile data
   */
  // updateUser(params){
  //   let formBody = [];
  //   for (let property in params) {
  //     let encodedKey = encodeURIComponent(property);
  //     let encodedValue = encodeURIComponent(params[property]);
  //     formBody.push(encodedKey + "=" + encodedValue);
  //   }
  //   formBody = formBody.join("&");
  //   return fetch(komunidadApi.API_URL+'/users/me/update', {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: formBody,
  //     credentials: 'include'
  //   })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     return responseJson;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
  // },
  /*
   * Returns a list of companies the user created or is part of
   */
  getCompanies() {
    return fetch(komunidadApi.API_URL + "/companies?type=partner", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getMentoredCompanies() {
    return fetch(komunidadApi.API_URL + "/companies?type=mentor", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },

  /*
   * Invites a not registered user to a company based on an email
   */
  inviteUserNotRegistered(email, position, id) {
    let params = {
      email: email,
      position: position,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      komunidadApi.API_URL + "/companies/" + id + "/invite_not_registered",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Invites a not registered user to a company based on an email
   */
  inviteMentorNotRegistered(email, position, id) {
    let params = {
      email: email,
      position: position,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      komunidadApi.API_URL +
        "/companies/" +
        id +
        "/mentors/invite_not_registered",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Requests a new password creation
   */
  requestPwdReset(email) {
    let params = {
      email: email,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/password_reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Obtains a PR code data
   */
  getPrCode(prCode) {
    return fetch(
      komunidadApi.API_URL + `/users/validate_pr_code?pr_code=${prCode}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of registed user based on a query string
   */
  findUsers(query_string) {
    let url = new URL(komunidadApi.API_URL + "/partners/lookup");
    url.searchParams.append("query_string", query_string);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of pre-registed user based on a query string
   */
  findPreUsers(query_string) {
    let url = new URL(komunidadApi.API_URL + "/users/preregistered_users");
    url.searchParams.append("query_string", query_string);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of registed mentors based on a query string
   */
  findMentors(query_string) {
    let url = new URL(
      komunidadApi.API_URL + "/partners/lookup?role=mentor|partner"
    );
    url.searchParams.append("query_string", query_string);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Creates a new password
   */
  createNewPwd(prCode, pwd) {
    let params = {
      pr_code: prCode,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/create_new_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Adds a new partner to a company based on the invited user id
   */
  addPartner(user_id, position, company_id) {
    let params = {
      user_id: user_id,
      position: position,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets a company data based on an id
   */
  getCompany(companyId) {
    let url = new URL(komunidadApi.API_URL + "/companies/" + companyId);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets a public company data based on an id
   */
  getPublicCompany(companyId) {
    let url = new URL(komunidadApi.API_URL + "/companies/public/" + companyId);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Removes a partner from a company
   */
  deletePartner(partner_id, company_id) {
    let params = {
      partner_id: partner_id,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Removes a partner from a company
   */
  deleteMentor(partner_id, company_id) {
    let params = {
      partner_id: partner_id,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/mentors/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Attempts to update the given company with the parameters
   * provided
   *
   * @params String company_id
   * @params Object params
   *
   * @returns mixed
   */
  updateCompany(company_id, params) {
    let url = `${komunidadApi.API_URL}/companies/${company_id}/update`;
    let formBody = new FormData();

    for (var key in params) {
      formBody.append(key, params[key]);
    }

    if(params.hasOwnProperty('social_networks')){
      formBody.set('social_networks',JSON.stringify(params['social_networks']))
    }

    return fetch(url, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => responseJson);
  },
  /*
   * Creates a new company
   */
  createCompany(params) {
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    return fetch(komunidadApi.API_URL + "/companies/create", {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Disable the company status
   */
  toggleCompanyIsPublic(company_id, is_public, password) {
    let params = {
      is_public: is_public,
      password: password,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/companies/${company_id}/is_public`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Accepts an invitation to a company
   */
  acceptInvitation(params) {
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/accept", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Accepts an invitation to a company
   */
  acceptMentorInvitation(params) {
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/mentors/accept", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  requestDemo(params) {
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/demo/request_demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of all mentors
   */
  getMentors(sortMode) {
    return fetch(komunidadApi.API_URL + `/mentors?order=${sortMode}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Invite mentors
   */
  inviteRegisteredMentor(user_id, company_id) {
    let params = {
      user_id: user_id,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/TEMP_invite_mentor", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Self Invite mentors
   */
  selfInviteMentor(program_id, company_id) {
    let params = {
      program_id: program_id,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/mentor_self_invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Manager Invite mentors
   */
  managerInviteMentor(user_id, program_id, company_id) {
    let params = {
      user_id: user_id,
      program_id: program_id,
      company_id: company_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/partners/mentor_manager_invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Add a rating of a company
   */
  createCompanyRating(rating, company_id, program_id) {
    let params = {
      rating: rating,
      program_id: program_id
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/companies/${company_id}/ratings/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Add a review of a company
   */
  createCompanyReview(review, company_id) {
    let params = {
      review: review,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/companies/${company_id}/reviews/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of reviews of a company
   */
  getReviews(company_id) {
    return fetch(`${komunidadApi.API_URL}/companies/${company_id}/reviews`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of all reviews
   */
  getAllReviews() {
    return fetch(`${komunidadApi.API_URL}/companies/all/reviews`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
  /*Creates a program
  */

  addProgram(name, start_date, end_date, is_public, message, file, iframe, iframe_2, schedule, partnership){
    let params = {
      'name': name,
      'message': message,
      'start_date': start_date,
      'end_date': end_date,
      'is_public': is_public,
      'iframe': iframe,
      'iframe_2': iframe_2,
      'schedule': schedule,
      'partnership': partnership,
    };
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    formBody.append("file", file);
    return fetch(komunidadApi.API_URL + "/programs/create", {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then(response => response.json().then(data => ({status: response.status, body: data})))
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },


  /*
  /* Updates a program
  */

  updateProgram(program_id,name, start_date, end_date,is_public, message, file, iframe, iframe_2, schedule, partnership){
    let params = {
      'name': name,
      'message': message,
      'start_date': start_date,
      'end_date': end_date,
      'is_public': is_public,
      'iframe': iframe,
      'iframe_2': iframe_2,
      'schedule': schedule,
      'partnership': partnership,
    };
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    formBody.append("file", file);
    return fetch(`${komunidadApi.API_URL}/programs/${program_id}/update`, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
  /* Updates a program
  */

  updateProgramAcceptServiceRequests(program_id,accepts_service_requests){
    let params = {
      'accepts_service_requests': accepts_service_requests,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/programs/${program_id}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
  /* Updates a program
  */

  updateProgramServiceRequestsEmailSettings(program_id,send_service_requests_to,
    service_requests_email_list){
    let params = {
      'send_service_requests_to': send_service_requests_to,
      'service_requests_email_list': service_requests_email_list
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/programs/${program_id}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of program
   */
  findPrograms(query) {
    let url = new URL(komunidadApi.API_URL + "/programs");

    if (query !== "" && query !== undefined && query !== null) {
      url.searchParams.append("q", query);
    }

    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Delete user. Just the manager can delete a user.
   */
  deleteUsers(users_ids, pwd) {
    let params = {
      users_ids: users_ids,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Delete preuser. Just the manager can delete a preuser.
   */
  deletePreusers(preusers_ids, pwd) {
    let params = {
      preusers_ids: preusers_ids,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/delete_preusers", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Delete preuser. Just the manager can delete a preuser.
   */
  invitePreusers(preusers_ids) {
    let params = {
      preusers_ids: preusers_ids
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/resend_invitations", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Delete resources.Just the manager and the facilitator can delete resources
   */
  deleteResources(program_id, resources_id, pwd) {
    let params = {
      resources_ids: resources_id,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/delete_resources`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
  *   Change companies status to public or not
  */
  makePublic(companies_ids, change_to_public) {
    let params = {
      change_to_public: change_to_public,
      companies_ids: companies_ids,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/companies/make_public`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Remove companies.Just the manager and the facilitator can remove companies
   */
  removeCompanies(program_id, companies_id, pwd) {
    let params = {
      companies_ids: companies_id,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/remove_companies`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Remove users without company.Just the manager and the facilitator can remove users
   */
  removeUsersNoCompany(program_id, users_ids, pwd) {
    let params = {
      users_ids: users_ids,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/remove_users_no_company`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Remove mentors. Just the manager and the facilitator can remove users
   */
  removeMentorsFromProgram(program_id, users_ids, pwd) {
    let params = {
      users_ids: users_ids,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/remove_mentors`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *  Add multiple users to a single program.Just the manager and the facilitator have permissions
   */
  addMultipleUsers(program_id, users_ids, pwd) {
    let params = {
      users_ids: users_ids,
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/add_multiple_users`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Disable the company status
   */
  changeStatus(company_id, pwd) {
    let params = {
      password: pwd,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/companies/${company_id}/disable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of registered users
   */
  getUsers() {
    let url = new URL(komunidadApi.API_URL + "/users/");
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of registered users
   */
  getUsersById(users_ids) {
    let params = {
      ids: users_ids,
    };
    return fetch(`${komunidadApi.API_URL}/users`, {
      method: "POST",
      body: params,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  downloadFile(users_ids) {
      let params = {
        ids: users_ids,
      };
      return fetch(`${komunidadApi.API_URL}/users?export=xlsx`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
        credentials: "include",
      })
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        // window.open(url, '_blank');
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = 'reporte-komunidad.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of pre-registered users
   */
  getPreUsers() {
    let url = new URL(komunidadApi.API_URL + "/users/preregistered_users");
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of all companies
   */
  findCompanies(query) {
    let url = new URL(komunidadApi.API_URL + "/companies/manager/index");

    if (query !== "") {
      url.searchParams.append("q", query);
    }

    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of all companies
   */
  findPublicCompanies(query) {
    let url = new URL(komunidadApi.API_URL + "/companies/company_catalog");

    if (query !== "") {
      url.searchParams.append("q", query);
    }

    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of all companies
   */
  getPublicCompanies(query,filters,sectors,programs,states) {
    let url = new URL(komunidadApi.API_URL + "/companies/public_companies");

    if (query !== "") {
      url.searchParams.append("q", query);
    }

    if (filters !== "") {
      url.searchParams.append("filters", filters);
    }

    if (sectors !== "") {
      url.searchParams.append("sectors", sectors);
    }

    if (programs !== "") {
      url.searchParams.append("programs", programs);
    }

    if (states !== "") {
      url.searchParams.append("states", states);
    }

    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  },
  /*
   *get the user profile information and programs.
   */
  getUser(_id) {
    return fetch(`${komunidadApi.API_URL}/users/manager/${_id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns if the file is valid
   */
  validateCSVFile(file) {
    let url = new URL(
      komunidadApi.API_URL + "/users/manager/massive/validate_csv"
    );
    let formBody = new FormData();

    formBody.append("csv_file", file);

    return fetch(url.href, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Invite users by email, using and array of objects
   */
  inviteUsersMassive(users, roles, programs_ids, eventBriteName = null) {
    let params = {
      users: users,
      roles: roles,
      programs_ids: programs_ids,
    };
    if (eventBriteName !== null) {
      params["eventBriteEventName"] = eventBriteName;
    }
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/manager/massive/invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getProgram(_id) {
    return fetch(`${komunidadApi.API_URL}/programs/${_id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of the disabled companies
   */
  getDisabledCompanies() {
    return fetch(komunidadApi.API_URL + "/companies/disabled", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Send message to the user, mentors or companies from a program
   */
  sendBroadcast(body, subject, program_id, companies_ids, users_id, cc_me,images  = []) {
    let params = {
      body: body,
      subject: subject,
      program_id: program_id,
      companies_ids: companies_ids,
      users_ids: users_id,
      cc_me: cc_me,
      images: images
    };
    // let formBody = [];
    // for (let property in params) {
    //   let encodedKey = encodeURIComponent(property);
    //   let encodedValue = encodeURIComponent(params[property]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }
    // formBody = formBody.join("&");

    return fetch(komunidadApi.API_URL + "/broadcasts/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Send message to the facilitator of a program
   */
  sendContactEmail(body, subject, program_id, cc_me) {
    let params = {
      body: body,
      subject: subject,
      program_id: program_id,
      cc_me: cc_me,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/send_contact_email", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Add program permissions
   */
  addProgramPermissions(facilitator_id, programs_ids) {
    let params = {
      programs_ids: programs_ids,
      facilitator_id: facilitator_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      komunidadApi.API_URL + "/facilitators/change_programs_permissionss",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Add program permission
   */
  addProgramPermission(facilitator_id, program_id) {
    let params = {
      program_id: program_id,
      facilitator_id: facilitator_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      komunidadApi.API_URL + "/facilitators/add_program_permission",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Invites a facilitator via email with programs(optional).
   */
  facilitatorPreRegister(email, programs_ids) {
    let params = {
      email: email,
      roles: "facilitator",
      programs_ids: programs_ids,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + "/users/preregister", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *Enable user.
   */
  enableUsers(user_id, password) {
    let params = {
      password: password,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + `/users/${user_id}/enable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *disabled user.
   */
  disableUsers(user_id, password) {
    let params = {
      password: password,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + `/users/${user_id}/disable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *get the user profile information.
   */
  getUserProfile(_id) {
    return fetch(`${komunidadApi.API_URL}/users/${_id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of my mentors
   */
  getMyMentors(sortMode) {
    return fetch(komunidadApi.API_URL + `/mentors/mines?order=${sortMode}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of the mentors of a program
   */
  getMentorsByProgram(programId, sortMode) {
    return fetch(
      `${komunidadApi.API_URL}/mentors/by_program/${programId}?order=${sortMode}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of the resources of a program
   */
  getResourcesByProgram(programId) {
    return fetch(
      `${komunidadApi.API_URL}/programs/${programId}/get_resources`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns an iframe of live view by program id
   */
  getLiveViewByProgram(programId) {
    return fetch(
      `${komunidadApi.API_URL}/programs/${programId}/get_live`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns an iframe of live view by program id
   */
  getLiveViewByFreeProgram(programId) {
    return fetch(
      `${komunidadApi.API_URL}/programs/${programId}/get_live_free`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of mentors ordered
   */
  findMentorsSorted(query, sortMode) {
    return fetch(
      komunidadApi.API_URL + `/mentors?q=${query}&order=${sortMode}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *Join user multiple programs
   */
  joinPrograms(userId, programIds, programId, join_as_mentor) {
    let params = {
      programs_ids: programIds,
      program_id: programId,
      join_as_mentor: join_as_mentor,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + `/users/${userId}/join_programs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then(response => response.json().then(data => ({status: response.status, body: data})))
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   *Join user multiple programs
   */
  joinProgramsAsMentors(userId, programIds) {
    let params = {
      programs_ids: programIds,
      join_as_mentor: "true",
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(komunidadApi.API_URL + `/users/${userId}/join_programs_as_mentor`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of my programs
   */
  getMyPrograms(query) {
    let url = new URL(komunidadApi.API_URL + "/programs/mines");
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Returns a list of mentored programs
   */
  getMyMentoredPrograms(status) {
    let url = new URL(komunidadApi.API_URL + `/programs/mentored?status=${status}`);
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Request a service
   */
  serviceRequest(subject, message, company_id, program_id, cc_me) {
    let params = {
      subject: subject,
      message: message,
      company_id: company_id,
      program_id:program_id,
      cc_me: cc_me,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/services/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Progress Report
   */
  progressReport(phase, start_date, end_date, describe_activities, describe_problems, next_phase_goals, company_id, program_id,file, cc_me) {
    let params = {
      'phase': phase,
      'start_date': start_date,
      'end_date': end_date,
      'describe_activities': describe_activities,
      'describe_problems': describe_problems,
      'next_phase_goals': next_phase_goals,
      'company_id': company_id,
      'program_id': program_id,
      'cc_me': cc_me,
    };
    let formBody = new FormData();
    for (var key in params) {
      formBody.append(key, params[key]);
    }
    formBody.append("file", file);
    return fetch(`${komunidadApi.API_URL}/progress/report`, {
      method: "POST",
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Send Whatsapp invitation
   */
  sendWhatsappGroupInvitation(group_name, group_url, users_ids, program_id) {
    let params = {
      group_name: group_name,
      group_url: group_url,
      users_ids: users_ids,
      program_id: program_id,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/broadcasts/invite_whatsapp_group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Create program resource
   */
  createResource(resource_name, resource_url, resource_description, program_id) {
    let params = {
      resource_name: resource_name,
      resource_url: resource_url,
      resource_description: resource_description,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/programs/${program_id}/add_resource`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Creates a mew template
   */
  createTemplate(programId, name, template, svg) {
    let params = {
      program_id: programId,
      name: name,
      template: template,
      svg: svg,
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${komunidadApi.API_URL}/certificates/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Get companies filtered to be displayed in the map.
   */
  getCompaniesWithLocation(params) {
    let url = `${komunidadApi.API_URL}/programs/export/data?`;
    let p = new URLSearchParams(params);
    url = `${url}${p.toString()}`;

    return fetch(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets the certificates saved in the system
   */
  getCertificates() {
    return fetch(komunidadApi.API_URL + "/certificates", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets the certificates saved in the system
   */
  getCertificatesByProgramId(program_id) {
    return fetch(
      `${komunidadApi.API_URL}/certificates?program_id=${program_id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Gets the certificates saved in the system
   */
  getCertificateById(certificate_id) {
    return fetch(`${komunidadApi.API_URL}/certificates/${certificate_id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
   * Sent certificates
   */
  sendCertificates(certificateId, program_id, users_ids) {
    let params = {
      certificateId: certificateId,
      program_id: program_id,
      users_ids: users_ids,
      sendMode: "email",
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(
      `${komunidadApi.API_URL}/certificates/${certificateId}/issue`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((error) => {
        return error;
      });
  },
 /*
  *  Assign company to a program.
  */
 assignCompany(program_id,companies_id,price_paid){
   let params = {
     'companies_id': companies_id,
     'price_paid': price_paid,
   };
   let formBody = [];
   for (let property in params) {
     let encodedKey = encodeURIComponent(property);
     let encodedValue = encodeURIComponent(params[property]);
     formBody.push(encodedKey + "=" + encodedValue);
   }
   formBody = formBody.join("&");
   return fetch(`${komunidadApi.API_URL}/programs/${program_id}/add_company`, {
     method: 'POST',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     body: formBody,
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
 },
 /*
   * Gets the courses where user can join
   */
  getUserCourses(program_id) {
    return fetch(
      `${komunidadApi.API_URL}/courses/user?programId=${program_id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
 /*
   * Gets the all the public courses
   */
  getPublicCourses() {
    return fetch(
      `${komunidadApi.API_URL}/courses/public_courses`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  /*
  /*Get all the courses
  */
 getAllCourses(){
  let url = new URL(komunidadApi.API_URL + "/courses/all");
   return fetch(url.href, {
     method: "GET",
     credentials: "include",
   })
     .then((response) => response.json())
     .then((response) => {
       return response;
     })
     .catch((error) => {
       return error;
     });
  },
  /*
  /*Get the courses filtered by query
  */
 getCoursesByProgram(query){
  let url = new URL(komunidadApi.API_URL + "/courses/all");
  if (query !== "") {
    url.searchParams.append("query", query);
  }
   return fetch(url.href, {
     method: "GET",
     credentials: "include",
   })
     .then((response) => response.json())
     .then((response) => {
       return response;
     })
     .catch((error) => {
       return error;
     });
  },
   /*
   /*Get user courses
   */
  getMyCourses(){
   let url = new URL(komunidadApi.API_URL + "/courses/my_courses");
    return fetch(url.href, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
   },
 /*
  * Returns a list of public programs
  */
 getPublicPrograms() {
   let url = new URL(komunidadApi.API_URL + "/programs/public");
   return fetch(url.href, {
     method: "GET",
     credentials: "include",
   })
     .then((response) => response.json())
     .then((response) => {
       return response;
     })
     .catch((error) => {
       return error;
     });
 },
  /*
  /*Creates a course
  */
 addCourse(name, phase, programs, is_public, chapters, file){
  let params = {
    'name': name,
    'phase': phase,
    'programs': programs,
    'is_public': is_public,
    'chapters': chapters,
  };
  let formBody = new FormData();
  for (var key in params) {
    formBody.append(key, params[key]);
  }
  formBody.append("file", file);
  return fetch(komunidadApi.API_URL + "/courses/create", {
    method: "POST",
    body: formBody,
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return error;
    });
  },
  /*
  /*Edit a course
  */
 editCourse(course_id, name, programs, is_public, accepts_inscriptions, chapters, file){
  let params = {
    'course_id': course_id,
    'name': name,
    'programs': programs,
    'is_public': is_public,
    'accepts_inscriptions': accepts_inscriptions,
    'chapters': chapters,
  };
  let formBody = new FormData();
  for (var key in params) {
    formBody.append(key, params[key]);
  }
  formBody.append("file", file);
  return fetch(komunidadApi.API_URL + "/courses/edit", {
    method: "POST",
    body: formBody,
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return error;
    });
  },
  /*
  /*Join in a curse
  */
 joinCourse(courseId){
  return fetch(`${komunidadApi.API_URL}/courses/${courseId}/join`, {
     method: 'POST',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },
  /*
  /*Join multiple courses
  */
 joinMultipleCourses(coursesIds){
  let params = {
    'coursesIds': coursesIds
  };
  let formBody = [];
  for (let property in params) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(`${komunidadApi.API_URL}/courses/join_multiple`, {
     method: 'POST',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     body: formBody,
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },
  /*
  /*Get course information
  */
  getCourse(courseId){
  return fetch(`${komunidadApi.API_URL}/courses/manager/${courseId}`, {
     method: 'GET',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },
  /*
  /*Get chapters course
  */
 getChapters(courseId){
  return fetch(`${komunidadApi.API_URL}/courses/${courseId}`, {
     method: 'GET',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },
  /*
  /*Join in a curse
  */
 updateProgress(courseId, chapterId){
   let params = {
    'course_id': courseId,
    'chapter_id': chapterId,
  };
  let formBody = [];
  for (let property in params) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(`${komunidadApi.API_URL}/courses/course_progress`, {
     method: 'POST',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     body: formBody,
     credentials: "include"
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },

  /*Get program basic data*/
  getProgramName(programId){
  return fetch(`${komunidadApi.API_URL}/programs/${programId}/join_program`, {
     method: 'GET',
     headers: {
       "Content-Type": "application/x-www-form-urlencoded",
     },
     credentials: 'include'
   })
   .then((response) => response.json())
   .then((responseJson) => {
     return responseJson;
   })
   .catch((error) => {
     return error;
   });
  },
  /*
    * Gets Creo Videos
    */
  getCreoVideos() {
    return fetch(komunidadApi.API_URL + "/creo-videos", {
      method: "GET",
      credentials: "include",
    })
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
  },
  getOpportunities() {
    return fetch(komunidadApi.API_URL + "/opportunities", {
      method: "GET",
      credentials: "include",
    })
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
  },
  saveOpportunities(opportunities) {
    return fetch(komunidadApi.API_URL + "/opportunities", {
      method: "POST",
      headers:{
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({opportunities:opportunities})
    })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  },
};

module.exports = komunidadApi;
