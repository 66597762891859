/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, Octubre 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import Header from '../components/Header';
import HeaderFree from '../components/HeaderFree';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';

import { isUserProfileComplete } from '../utils/Utils.js';

// Images
import {getCommingSoon, getCCMX, getCMN, getEDOMEX, getPartnersLogos, getAsem, getPosible, getConcamin,
        getEshow, getBrive, getMexicoPuede, getGentera, getApolo, getCoppel, 
        getStartuplab, getConsejo, getWadhwani, getUs, getADO, getCofece } from "../utils/Vendor.js";

// Images CREO MX SLP
import {getSedeco, getAyuntamiento, getCanacintra, getUASLP } from "../utils/Vendor.js";

const CommingSoon = getCommingSoon();
const CCMX = getCCMX();
const CMN = getCMN();
const EDOMX = getEDOMEX();
const partners = getPartnersLogos();
const Sedeco = getSedeco();
const Ayuntamiento = getAyuntamiento();
const Canacintra = getCanacintra();
const UASLP = getUASLP();
const Asem = getAsem();
const Posible = getPosible();
const Concamin = getConcamin();
const Eshow = getEshow();
const Brive = getBrive();
const MexicoPuede = getMexicoPuede();
const Gentera = getGentera();
const Apolo = getApolo();
const Coppel = getCoppel();
const Startuplab = getStartuplab();
const Consejo = getConsejo();
const Wadhwani = getWadhwani();
const Us = getUs();
const ADO = getADO();
const Cofece = getCofece();

class SponsorsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      resources:[],
      resourcesLoadState:'default',
      selectedId:[0],
      programName:[0],
      loading: false
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true,
            loading: true
          });
        }
      }else{
        //redirect profile
        // this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar();
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }


  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

    renderSponsors(){
        let agendaViewport = document.getElementById("agenda-viewport");
        if (agendaViewport){
            agendaViewport = agendaViewport.offsetHeight;
            console.log(agendaViewport);
            return (
                <div id="scheed-agenda">
                    <iframe
                    src="https://creomx.sched.com/directory/sponsors"
                    title="Creo MX 2020"
                    // m-top = 70, m-bottom = 60, ui = 82 ... 212px
                    height={agendaViewport-212+"px"}
                    width="100%"
                    frameBorder="0"
                    ></iframe>
                </div>
            );
        } else {
            return null
        }
    }

    renderCommingSoon(){
        return (
            <div className="text-center">
                <h1 className="mb-5">¡Próximamente!</h1>
                {/* <FontAwesomeIcon className="m-t-20 m-b-20" color="#c2c2c2" icon="user-tie" size='4x'/> */}
                <img src={ CommingSoon } alt="Home" width="100%" className="" />
            </div>
        )
    }

    renderSponsorsContent(){
            return (
                <div className="container px-xl-0 text-center">
                    <h1 className="mb-1">Organizadores</h1>
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-3 my-2">
                            <img src={ CMN } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ CCMX } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ EDOMX } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                    </div>
                    <h1 className="mb-1 mt-5">Aliados Estratégicos</h1>
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-12 my-2">
                          <img src={ partners } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        {/*<div className="col-xl-4 my-2">
                            <img src={ Canacintra } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-4 my-2">
                            <img src={ UASLP } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-4 my-2">
                            <img src={ Startuplab } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Sedeco } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Ayuntamiento } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Asem } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Posible } alt="" className="img-fluid radius10 img mx-auto d-block" />
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Coppel } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Gentera } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Concamin } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Eshow } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Brive } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ MexicoPuede } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Apolo } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Consejo } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Us } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ ADO } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Cofece } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>
                        <div className="col-xl-3 my-2">
                            <img src={ Wadhwani } alt="" className="img-fluid radius10 img mx-auto d-block"/>
                        </div>*/}
                    </div>
                </div>
            );
    }

  render(){
   if(this.state.authorized){
     return(
       <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
         <div id="main-wrapper" className={this.state.resources.length <= 4 ? "height100": null}>
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
           {/* Page wrapper  */}
           <div id="agenda-viewport" className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">

                <div className="row page-titles">
                    {/* <h1 className="mb-1">Aliados</h1> */}
                </div>
                {/* Start Page Content */}
                {this.renderSponsorsContent()}
                {/* End PAge Content */}
               </div>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(
      <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
        <div id="main-wrapper" className={this.state.resources.length <= 4 ? "height100": null}>
            <HeaderFree
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              // user={this.state.me}
              freeMode={true}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              // user={this.state.me}
              freeMode={true} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
          {/* Page wrapper  */}
          <div id="agenda-viewport" className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">

                <div className="row page-titles">
                    {/* <h1 className="mb-1">Aliados</h1> */}
                </div>
                {/* Start Page Content */}
                {this.renderSponsorsContent()}
                {/* End PAge Content */}
              </div>
          </div>
          {/* End Page wrapper  */}
        </div>

      </div>
     )
   }
  }
}
export default SponsorsView;
