/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx> &
 * Darien Miranda <darien@startuplab.mx> &
 * Israel Arellano <israel@startuplab.mx>
 * September 2019
 */

// Regular imports
import React from 'react'
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from "react-router-dom"

// Images & Logos
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// Style
import "./scss/icons/material-design-iconic-font/css/materialdesignicons.min.css";

// Pages
import LandingView from './views/landing/LandingView';
import EventView from './views/landing/EventView';
import ProfileView from './views/profile/ProfileView';
import PasswordView from './views/auth/PasswordView';
import ResendPasswordEmail from './views/auth/ResendPasswordEmail';
import LoginView from './views/auth/LoginView';
import SignUpView from './views/auth/SignUpView';
import CreateCompanyView from './views/company/CreateCompanyView';
import CompaniesView from './views/company/CompaniesView';
import CompanyView from './views/company/CompanyView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import Footer from './components/landing/Footer';
import EditProfileView from './views/profile/EditProfileView';
import MentorsView from './views/MentorsView';
import ManagerUserView from './views/manager/ManagerUserView';
import CreateProgramView from './views/manager/CreateProgramView';
import ManagerDataView from './views/manager/ManagerDataView';
import ManagerAcademy from './views/manager/ManagerAcademy';
import AcademyView from './views/manager/AcademyView';
import ManagerCompaniesView from './views/manager/ManagerCompaniesView';
import ProgramsView from './views/manager/ProgramsView';
import ManagerView from "./views/manager/ManagerView";
import ServicesView from './views/ServicesView';
import ProgressReport from './views/ProgressReport';
import ProgressReportViewOnly from './views/ProgressReportViewOnly';
import InviteWhatsappGroupView from './views/manager/InviteWhatsappGroupView';
import ResourcesView from './views/ResourcesView';
import CreateResourceView from './views/manager/CreateResourceView';
import EventbriteView from './views/manager/EventbriteView';
import CertificateEditor from './views/manager/CertificateEditor';
import CertificateView from './views/manager/CertificateView';
import ScheduleView from './views/ScheduleView';
import LiveFreeView from './views/LiveFreeView';
import ProgramsLiveView from './views/ProgramsLiveView';
import SpeakersView from './views/SpeakersView';
import FinancingView from './views/FinancingView';
import SponsorsView from './views/SponsorsView';
import CoursesListView from './views/courses/CoursesListView';
import CourseView from './views/courses/CourseView';
import CoursesListViewFree from './views/courses/CoursesListViewFree';
import SelectProgramView from './views/SelectProgramView';
import JoinProgramView from './views/JoinProgramView';
import PublicCompaniesListView from './views/PublicCompaniesListView';
import PublicCompanyView from './views/company/PublicCompanyView';

// Google analytics
import RouteChangeTracker from './RouteChangeTracker.js';
const TRACKING_ID = "UA-195346229-1";
ReactGA.initialize(TRACKING_ID);

library.add(
  fas,fab,far
)

function App() {
  return (
    <Router>
      <RouteChangeTracker />
      <Route
        exact
        path="/home"
        render={(props) => (
          <div>
            <LandingView />
            <Footer />
          </div>
        )}
      />
      {/* <Route exact path="/evento" component={EventView} /> */}
      {/* <Route exact path="/" component={LoginView} />  */}
      <Route exact path="/login" component={LoginView} />
      <Route exact path="/sign-up" component={SignUpView} />
      <Route exact path="/profile" component={ProfileView} />
      <Route exact path="/profile/edit" component={EditProfileView} />
      <Route exact path="/profile/:profile_id/edit" component={EditProfileView} />
      <Route path="/forgot-password" component={ForgotPasswordView} />
      <Route path="/reset-password/:pr_code" component={ResetPasswordView} />
      <Route exact path="/companies/:id" component={CompanyView} />
      <Route exact path="/create_company" component={CreateCompanyView} />
      <Route exact path="/companies" component={CompaniesView} />
      <Route exact path="/create_password/:prc" component={PasswordView} />
      <Route exact path="/mentors" component={MentorsView} />
      <Route exact path="/manager" component={ManagerView} />
      <Route exact path="/manager/users" component={ManagerUserView} />
      <Route exact path="/manager/programs" component={CreateProgramView} />
      <Route exact path="/manager/stats" component={ManagerDataView} />
      <Route exact path="/manager/academy" component={ManagerAcademy} />
      <Route exact path="/manager/academy/:id" component={AcademyView} />
      <Route exact path="/manager/companies" component={ManagerCompaniesView} />
      <Route exact path="/manager/programs/:id" component={ProgramsView} />
      {/* <Route exact path="/services" component={ServicesView} /> */}
      <Route
        exact
        path="/invite_whatsapp_group"
        component={InviteWhatsappGroupView}
      />
      {/* <Route exact path="/agenda" component={ScheduleView} /> */}
      {/* <Route exact path="/live" component={LiveView} /> */}
      <Route exact path="/" component={LiveFreeView} />
      <Route exact path="/login" component={LoginView} />
      <Route exact path="/live" component={ProgramsLiveView} />
      <Route exact path="/live_free" component={LiveFreeView} />
      <Route exact path="/speakers" component={SpeakersView} />
      <Route exact path="/sponsors" component={SponsorsView} />
      <Route exact path="/financing" component={FinancingView} />
      <Route exact path="/resources" component={ResourcesView} />
      <Route exact path="/select_program" component={SelectProgramView}/>
      <Route exact path="/join_program" component={SignUpView}/>
      {/* <Route exact path="/progress_report" component={ProgressReport} /> */}
      {/* <Route exact path="/reporte_avances" component={ProgressReportViewOnly} /> */}
      <Route exact path="/create_resource" component={CreateResourceView} />
      <Route exact path="/eventbrite" component={EventbriteView} />
      <Route exact path="/certificate_editor" component={CertificateEditor} />
      <Route exact path="/certificates" component={CertificateView} />
      <Route exact path="/courses" component={CoursesListView} />
      <Route exact path="/courses/:id" component={CourseView} />
      <Route exact path="/courses_free" component={CoursesListViewFree} />
      <Route exact path="/resend_email/:email" component={ResendPasswordEmail} />
      <Route exact path="/directorio" component={PublicCompaniesListView} />
      <Route exact path="/directorio/:id" component={PublicCompanyView} />
    </Router>
  );
}

export default App;
