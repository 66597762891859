/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, March 2020
 */
//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/components/_eb_components.scss';

//components
import KImage from '../KImage';
import EBCalendarDate from './EBCalendarDate';
/**
*  Show an Eventbrite event. Used by [EBListEvents](/#EBListEvents).
*/

class EBEvent extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEvent    = this.onClickEvent.bind(this);
    this.renderName      = this.renderName.bind(this);
    this.status = {
      "draft":"Borrador",
      "live":"Publicado",
      "started":"Iniciado",
      "ended":"Terminado",
      "completed":"Completado",
      "canceled":"Cancelado"
    }
  }

  onClickEvent(review){
    this.props.onClickEvent(review);
  }
  renderName(){
    let r = this.props.event;
    return JSON.stringify(r);
  }
  renderStatus(){
    let s = this.props.event.status;
    return this.status[s];
  }
  render() {
    const event = this.props.event;

    let logoUrl = null;

    if(event.hasOwnProperty("logo")){
      if(event.logo !== null){
        if(event.logo.hasOwnProperty("url")){
          logoUrl = event.logo.url
        }
      }
    }
    
    let start_date = new Date(this.props.event.start.local);

    return(
      <div className="eb-event-row m-b-20" onClick={()=> {this.props.onClickEvent(this.props.event)}}>
        <EBCalendarDate date={start_date}/>
        <div className="m-r-10">
          <KImage className="companyImage eb-event-image" src={logoUrl} type={"eventbrite"}/>
        </div>
        <div className="eb-event-description">
          <h5 class="m-b-5 font-medium">
            {event.name.text}
          </h5>
          <div className="">
            {event.summary}
          </div>
        </div>
        <div className="eb-event-status p-r-10">
          {this.renderStatus()}
        </div>
      </div>
    )
  }
}

EBEvent.propTypes = {
  /** The Event object:
      user, review, created_at*/
  event            : PropTypes.object,
  /** Callback on click event*/
  onClickEvent     : PropTypes.func,
};

EBEvent.defaultProps = {
  review            : {},
  onClickEvent      : ()=>{}
};

export default EBEvent;
