/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>, October 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal,Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import KImage from '../components/KImage';
import parse from 'html-react-parser';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';


/** TODO:CHECK THE NAME.
*   Modal to invite a selected mentor to a company (as mentor).
*   If the user is a partner in the selected company, can't be a mentor.
*/

class KSpeakerModal extends React.Component {
    constructor(props) {
        super(props);

        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

  handleCloseModal(value){
    this.props.closeModal(value)
  }

  getValueSafely(user,property){
    if(user !== null ){
      if(user.hasOwnProperty(property)){
        return user[property][this.props.speakerIndex];
      }else{
        return "-";
      }
    }
  }

  render() {

    let user = this.props.user;

    return (
      <Modal
        show={this.props.showModal}
        onHide={() => this.handleCloseModal(false)}
        onExit={() => this.handleCloseModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className="row w-100">
            <div className="col-md-auto">
              <KImage src={this.getValueSafely(user,"profileUrlThumbnail")}
                    className={"kuImage mr-2 mt-2"}
                    alt={"user_img"}/>
            </div>
            <div className="col">
              <div className="row">
                <Modal.Title>
                  {user.name[this.props.speakerIndex]}
                </Modal.Title>
              </div>
              <div className="row">
                <span className="text-muted font-14">{parse(user.speciality[this.props.speakerIndex])}</span>
              </div>
            </div>
          </div>
        </Modal.Header>
        { !user.hasOwnProperty('start') &&
          <Modal.Body>
            <div className="activity-item m-b-20">
              {parse(user.bio[this.props.speakerIndex])}
            </div>
          </Modal.Body>
        }
        </Modal>
    );
  }
}

KSpeakerModal.propTypes = {
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** The user to be invited as mentor*/ 
  user             : PropTypes.object,
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /*SpeakerIndex*/
  speakerIndex     : PropTypes.number
};

KSpeakerModal.defaultProps = {
  showModal       : false,
  user            : {},
  closeModal      : () => {},
  speakerIndex    : 0
};

export default KSpeakerModal;
