/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano, <israel@startuplab.mx> May 2021
 */

// Regular imports
import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import KomunidadApi from '../api/api.js';
// Custom styling
import '../scss/pages/_style.css';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';
import Background from "../assets/images/background/login-register.jpg";

//Components
import Header from '../components/Header';

//version
import pkg from '../../package.json'

import { validateEmail, validateStringLength, getProfileStatus, validatePhone, required } from '../utils/Utils.js';
import { getAppLogo } from "../utils/Vendor.js";
const Logo = getAppLogo();

class JoinProgramView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      me: {},
      program: null,
      joined_programs: null,
      renderContent: false,
      selectedForm: 'signup',
      isValidDate: true,
      isModalOpen: false,
      aceptedTerms: false,
      email: '',
      password: '',
      register_name: '',
      register_email: '',
      register_last_name_f: '',
      register_last_name_m: '',
      register_phone: '',
      register_error_message: '',
      emailExist: false,
    }
    this.joinProgram = this.joinProgram.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.hideErrorMessage = this.hideErrorMessage.bind(this);
    this.changeFormContent = this.changeFormContent.bind(this);
    this.aceptTerms = this.aceptTerms.bind(this);
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        this.setState({
          me: res.user,
          joined_programs: res.user.joined_programs,
          renderContent: true
        });
      }
      KomunidadApi.getProgramName(programIdQuery).then((res) => {
        this.setState({
          program: res.program,
          isValidDate: this.isValidDate(res.program)
        })
      });
    });
  }

  changeFormContent(form) {
    this.setState({
      selectedForm: form
    });
  }

  aceptTerms() {
    this.setState({
      aceptedTerms: !this.state.aceptedTerms
    });
  }

  updateStatus(status, user) {
    this.setState({
      renderContent: status,
      me: user,
      joined_programs: user.joined_programs,
    })
  }

  isValidDate(program) {
    let today = Date.now();
    let endDate = new Date(program.end_date);

    endDate.setDate(endDate.getDate());

    let status = (endDate.getTime() > today) ? true : false;

    return status;

  }

  formatLinkPrograms(idProgram, joined_programs) {
    let programs = joined_programs;
    let programsIds = programs.map(program => {
      return program.program._id;
    })

    if (!programsIds.includes(idProgram)) {
      programsIds.push(idProgram);
    }

    return programsIds.join("|");
  }

  joinProgram() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    let userId = this.state.me._id;
    let joinedPrograms = this.state.me.roles.includes("mentor") ? this.state.me.mentored_programs : this.state.me.joined_programs;
    let programsArray = this.formatLinkPrograms(programIdQuery, joinedPrograms)
    let join_as_mentor = this.state.me.roles.includes("mentor") ? "true" : "false";

    KomunidadApi.joinPrograms(userId, programsArray, programIdQuery, join_as_mentor).then((res) => {
      if (res.body.success) {
        Swal.fire({
          title: join_as_mentor === "true" ? 'Te has inscrito correctamante como aliado del programa' : 'Te has inscrito con éxito',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'success',
          timer: 1250,
          showConfirmButton: false
        }).then(() => {
          if (join_as_mentor === "true") {
            this.props.history.push({
              pathname: '/companies',
            })

          } else {
            // REDIRECT TO CREATE COMPANY ON PROGRAM
            // this.props.history.push({
            //   pathname: '/create_company',
            //   search: `?programid=${programIdQuery}`
            // })
            this.props.history.push({
              pathname: '/live',
            })
          }
        });
      } else {
        if (res.status === 403) {
          Swal.fire({
            title: 'Inscripciones cerradas',
            text: res.body.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          }).then(() => {
            this.redirectHome();
          });
        } else {
          Swal.fire({
            title: 'Ocurrió un error',
            text: "Inténtalo más tarde",
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          }).then(() => {
            this.redirectHome();
          });
        }
      }
    });
  }

  redirectHome() {
    this.props.history.push('/');
  }

  redirectLogin(program) {
    this.props.history.push({
      pathname: '/',
      search: `?programid=${program}`
    });
  }

  hideErrorMessage() {
    setTimeout(() => {
      this.setState({
        register_error_message: '',
        error_message: ''
      });
    }, 3000);
  }

  handleChanges(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ error_message: '' });

    if (this.validateForm()) {
      KomunidadApi.login(this.state.email, this.state.password).then(res => {
        if (!res.hasOwnProperty("success")) {
          throw Error("Network Error");
        }
        if (res.success) {
          this.failed_attempts = 0;
          KomunidadApi.getMe().then((res) => {
            if (res.valid_token) {
              getProfileStatus(res.user).then(statusObj => {
                this.updateStatus(true, res.user)
              }).catch(err => {
                this.setState({ error_message: 'Servicio no disponible. Intenta nuevamente más tarde.' });
              });
            }
          });
        } else {
          if (res.account_disabled) {
            return this.showSuspendedAccountSwal();
          }
          this.failed_attempts++;
          this.setState({ error_message: 'Error de usuario contraseña. Intenta nuevamente' });
        }
      }).catch(err => {
        this.setState({ error_message: 'Servicio no disponible. Intenta nuevamente más tarde.' });
      })
    }
  }

  registerSubmit(event) {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    event.preventDefault();
    this.setState({ register_error_message: '' })

    if (this.state.aceptedTerms) {
      if (required(this.state.register_name) && required(this.state.register_last_name_f) && required(this.state.register_last_name_m)) {
        if (validateEmail(this.state.register_email)) {
          if (validatePhone(this.state.register_phone)) {
            KomunidadApi.userPreRegister(this.state.register_email, this.state.register_name, this.state.register_last_name_f, this.state.register_last_name_m, this.state.register_phone, programIdQuery).then(res => {
              if (res.body.success) {
                this.swalSuccess(this.state.register_email, this.state.register_name);
                this.setState({
                  register_name: '',
                  register_email: '',
                  register_last_name_f: '',
                  register_last_name_m: '',
                  register_phone: '',
                });
                this.aceptTerms();
              } else {
                if(res.status === 400) {
                  this.changeFormContent('login');
                  this.setState({
                    email: this.state.register_email,
                    register_name: '',
                    register_email: '',
                    register_last_name_f: '',
                    register_last_name_m: '',
                    register_phone: '',
                    error_message: 'El correo ya se encuentra registrado, inicia sesión'
                  })
                } else {
                  this.setState({
                    register_name: '',
                    register_email: '',
                    register_last_name_f: '',
                    register_last_name_m: '',
                    register_phone: '',
                    register_error_message: res.body.message_es
                  });
                  this.hideErrorMessage();
                }
              }
            }).catch(err => {
              this.setState({ register_error_message: 'Servicio no disponible. Intente de nuevo más tarde.' })
              this.hideErrorMessage();
            });
          } else {
            this.setState({ register_error_message: 'El teléfono no es válido' })
          }
        } else {
          this.setState({ register_error_message: 'El correo electrónico no es válido' })
        }
      } else {
        this.setState({ register_error_message: 'Debes llenar correctamente tu nombre' })
      }
    } else {
      this.setState({ register_error_message: 'Debes aceptar términos y condiciones para registrarte.' })
      this.hideErrorMessage();
    }
  }

  swalSuccess(email,name) {
    Swal.fire({
      title: 'Registro exitoso en ' + this.state.program.name,
      html: `Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    }).then(() => {
      // redirect with email
      this.props.history.push({
        pathname: '/live_free',
        search: `?email=${email}&name=${name}`
      });
    })
  }

  validateForm() {
    if (!validateEmail(this.state.email)) {
      this.setState({ error_message: 'El correo electrónico no es válido' });
    } else if (!validateStringLength(this.state.password, 5)) {
      this.setState({ error_message: 'La contraseña tiene que tener más de 5 caracteres' });
    } else {
      return true;
    }

    return false;
  }

  redirectSignUp(program) {
    this.props.history.push({
      pathname: '/sign-up',
      search: `?programid=${program}`
    });
  }

  renderBg(bannerUrlThumbnail) {
    if (bannerUrlThumbnail !== null) {
      return (
        <section id="wrapper" className="login-register login-sidebar login-section" style={{
          backgroundImage: `url(${bannerUrlThumbnail})`
        }}>
          <div className="container about-container" style={{ position: 'absolute', bottom: 0 }}>
            <div className="text-center row justify-content-md-center">
              <div className="col col-lg-8" style={{ bottom: '12px', fontSize: '14px' }}>
                <p style={{ display: 'inline' }}>Komunidad IO © 2024 - v{pkg.version}</p>
              </div>
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section id="wrapper" className="login-register login-sidebar login-section" style={{
          backgroundImage: `url(${Background})`
        }}>
          <div className="container about-container" style={{ position: 'absolute', bottom: 0 }}>
            <div className="text-center row justify-content-md-center">
              <div className="col col-lg-8" style={{ bottom: '12px', fontSize: '14px' }}>
                <p style={{ display: 'inline' }}>Komunidad IO © 2024 - v{pkg.version}</p>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
  renderForm() {
    if (this.state.selectedForm === 'signup') {
      return this.renderSignup();
    }
    if (this.state.selectedForm === 'login') {
      return this.renderLogin();
    }
  }

  renderLogin() {
    let program = this.state.program;
    return (
      <div className="row justify-content-center">
        <div className="card col-md-8 col-lg-10 border-0">
          <div className="card-body">
            <form className="form-horizontal" id="loginform" onSubmit={this.handleSubmit}>
              {/*
                <div className='form-group row text-center db'>
                  <div className="col-xs-12">
                    <a className="text-center db" href="#">
                      <img src={ Logo } alt="Home" className="AppLogo"/>
                    </a>
                  </div>
                </div>
              */}
              <p><h2 className="mb-3"><span className="font-bold text-dark">{program.name}</span></h2>
                Inicia sesión para inscribirte al programa, si aún no tienes cuenta en Komunidad, regístrate gratis
                <div className="btn btn-link" style={{ padding: '.25rem 0px .5rem .25rem' }} onClick={() => this.changeFormContent('signup')}>aquí</div>
              </p>

              <div className="form-group row m-t-20">
                <div className="col-md-6 mb-3">
                  {/*<label>Correo electrónico</label>*/}
                  <input className="form-control" id="email" value={this.state.email} onChange={this.handleChanges} placeholder="tucorreo@tucorreo.com" type="email" />
                </div>
                <div className="col-md-6 mb-3">
                  {/*<label>Contraseña</label>*/}
                  <input className="form-control" id="password" value={this.state.password} onChange={this.handleChanges} placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" type="password" />
                </div>
              </div>

              <p className="text-error mt-0">{this.state.error_message}</p>

              <div className="form-group row mb-3">
                <div className="col-md-12">
                  <Link className="float-left" style={{ fontSize: '14px', paddingTop: '5px' }} to={{ pathname: '/forgot-password', params: { email: this.state.email } }}>
                    ¿Olvidaste tu contraseña?
                  </Link>
                  <div className="float-right">
                    <button className="btn btn-info btn-block btn-rounded" style={{ borderRadius: '.25rem' }} type="submit">Iniciar sesión</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="container text-muted text-center">
            <a href="https://komunidad.freshdesk.com/support/home" target="_blank"> Soporte</a> |
            <a href="https://komunidad.io/aviso.html" target="_blank"> Aviso de Privacidad</a>
          </div>
        </div>
      </div>
    )
  }

  renderSignup() {
    let program = this.state.program;
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12">
          <div className="card container border-0">
            <div className="card-body">
              <h2 className="mb-3"><span className="font-bold text-dark">{program.name}</span></h2>
              Para inscribirte en el programa es necesario tener una cuenta de Komunidad, si ya tienes una puedes iniciar sesión
              <div className="btn btn-link m-0" style={{ padding: '.25rem 0px .5rem .25rem' }} onClick={() => this.changeFormContent('login')}>aquí</div>,
              de lo contrario puedes registrarte gratis
              <form className="form-horizontal" id="loginform" onSubmit={this.registerSubmit}>

                <div className="form-group row mb-3">
                  <div className="col-md-4 mb-3">
                    {/* <label>Nombre</label> */}
                    <input className="form-control" id="register_name" value={this.state.register_name} onChange={this.handleChanges} placeholder="Nombre" type="text" />
                  </div>
                  <div className="col-md-4 mb-3">
                    {/* <label>Correo electrónico</label> */}
                    <input className="form-control" id="register_last_name_f" value={this.state.register_last_name_f} onChange={this.handleChanges} placeholder="Apellido paterno" type="text" />
                  </div>
                  <div className="col-md-4 mb-3">
                    {/*<label>Correo electrónico</label>*/}
                    <input className="form-control" id="register_last_name_m" value={this.state.register_last_name_m} onChange={this.handleChanges} placeholder="Apellido materno" type="text" />
                  </div>
                  <div className="col-md-8 mb-3">
                    {/* <label>Correo electrónico</label> */}
                    <input className="form-control" id="register_email" value={this.state.register_email} onChange={this.handleChanges} placeholder="tucorreo@tucorreo.com" type="email" />
                  </div>
                  <div className="col-md-4">
                    {/* <label>Correo electrónico</label> */}
                    <input className="form-control" id="register_phone" value={this.state.register_phone} onChange={this.handleChanges} placeholder="Teléfono" type="text" />
                  </div>
                </div>

                <p className="text-error my-1">{this.state.register_error_message}</p>

                {/*<div className="form-group m-0">
              <div className="col-xs-12">
                {this.renderAdBlockDetect()}
              </div>
            </div>*/}

                <div className="form-group">
                  <div className="my-2">
                    <input
                      type="checkbox"
                      id="md_checkbox"
                      className="filled-in chk-col-light-blue"
                      value={this.state.aceptedTerms}
                      onChange={this.aceptTerms}
                      checked={this.state.aceptedTerms}
                      name='terms_and_conditions' />
                    <label htmlFor="md_checkbox">
                      <label style={{ fontWeight: 'normal', fontSize: '14px' }}>
                        Estoy de acuerdo con los <a href="https://komunidad.io/aviso.html" rel="noopener noreferrer" target="_blank">términos de uso</a> y la
                        <a href="https://komunidad.io/aviso.html" rel="noopener noreferrer" target="_blank"> política de privacidad.</a>
                      </label>
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <div className="col-md-12">
                    <Link className="float-left" style={{ fontSize: '14px', paddingTop: '5px' }} to={{ pathname: '/forgot-password', params: { email: this.state.email } }}>
                      ¿Olvidaste tu contraseña?
                    </Link>
                    <div className="float-right">
                      <button className={`btn ${this.state.aceptedTerms ? 'btn-info' : 'btn-aux'} btn-block btn-rounded`} disabled={!this.state.aceptedTerms} style={{ borderRadius: '.25rem', padding: '5px' }} type="submit">Regístrate</button>
                    </div>
                  </div>
                </div>

              </form>
              <div className="container text-muted text-center">
                <a href="https://komunidad.freshdesk.com/support/home" target="_blank"> Soporte</a> |
                <a href="https://komunidad.io/aviso.html" target="_blank"> Aviso de Privacidad</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let program = this.state.program;
    if (this.state.renderContent && program !== null && this.state.isValidDate) {
      return (
        <section id="wrapper">
          <div className="d-flex no-block justify-content-center align-items-center" style={{ position: 'relative', minHeight: '100vh',backgroundSize:'cover', backgroundImage: `url(${program.bannerUrlThumbnail !== null ? program.bannerUrlThumbnail : Background})` }}>
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-7">
                <div className="card-container card border-0" style={{ minWidth: '375px'}}>
                  <div className="card-body mb-3">
                    {/*<div className='form-group row text-center db mb-4'>
                      <div className="col-xs-12">
                      <a className="text-center db" href="#">
                      <img src={ Logo } alt="Home" className="AppLogo"/>
                      
                      </a>
                      </div>
                    </div>*/}
                    <h2 className="mb-3">¡Bienvenido a <span className="font-bold text-dark">{program.name}</span>!</h2>

                    <p>{program.message}</p>

                    <div className="form-group row justify-content-start mb-3">
                      <div className="col-sm-6 col-md-4 col-lg-4">
                        <button className="btn btn-info btn-block btn-rounded" style={{ borderRadius: '.25rem', minWidth: '150px' }} onClick={this.joinProgram}>Inscríbirse</button>
                      </div>
                    </div>
                  </div>
                  <div className="container text-muted text-center mb-3">
                    <a href="https://komunidad.freshdesk.com/support/home" target="_blank"> Soporte</a> |
                    <a href="https://komunidad.io/aviso.html" target="_blank"> Aviso de Privacidad</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (program !== null && this.state.isValidDate) {
      return (
        <section id="wrapper">
          <div className="d-flex no-block justify-content-center align-items-center" style={{ position: 'relative', minHeight: '100vh', backgroundSize:'cover', backgroundImage: `url(${program.bannerUrlThumbnail !== null ? program.bannerUrlThumbnail : Background})` }}>
            {this.renderForm()}
          </div>
        </section>
      )
    } else if (program !== null) {
      return (
        <div className="landingView" >
          <div className="page-wrapper" style={{ minHeight: '584px', padding: 0 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card" style={{ margin: 0 }}>
                    <div className="card-body">
                      <div className="error-box">
                        <div className="error-body text-center">
                          <h3 className="text-uppercase error-subtitle">Las inscripciones al programa {program.name} han cerrado</h3>
                          <p className="mt-4 mb-4">Te invitamos a conocer otros programas disponibles
                            <Link className="mx-1" style={{ fontSize: '14px', paddingTop: '5px' }} to={'/'}>
                              Inicia sesión
                            </Link>
                          </p>
                          <div className="mb-4">¿No tienes cuenta?
                            <Link className="mx-1" style={{ fontSize: '14px', paddingTop: '5px' }} to={'/sign-up'}>
                              Registrate
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default JoinProgramView;
