/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, August 2021
 */

// Regular imports
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom';

// Custom styling
import './../scss/pages/pages.scss';
import './../scss/pages/footer.css';


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallLogo: true,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateLogo = this.updateLogo.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  updateDimensions() {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    this.props.onResize(width);
    this.updateLogo();
  }

  updateLogo(method) {
    const windowSize = window.innerWidth;
    if (method === 'byClick') {
      this.setState({
        smallLogo: !this.state.smallLogo,
      })
    } else {
      if (windowSize <= 994) {
        this.setState({
          smallLogo: true
        })
      } else {
        this.setState({
          smallLogo: false
        })
      }
    }
  }

  render() {
    return (
      <div>
        <footer className="pt-70 pb-60 bg-transparent footer_4">
          <div className="px-4">
            <div className="row align-items-center">
              <div className="col-xl-2 order-0">
                <a href="#" className="link img_link">
                  {/* Logo */}
                  <a className="navbar-brand" href="https://www.komunidad.io/">
                    {/* Logo text */}
                    <div style={{minHeight:"41px"}}className={this.state.smallLogo ? "header-bg-ksq" : "header-bg-k"}>
                      {/* dark Logo text */}
                      <div className="dark-logo AppLogoHeader"></div>
                      {/* Light Logo text */}
                      <div className="light-logo AppLogoHeader"></div>
                    </div>
                  </a>
                </a>
              </div>
              <div className="col-xl-4 col-lg-9 order-2 order-xl-0 ">
                © 2024 Komunidad. Todos los Derechos Reservados.
              </div>
              <div className="mt-10 mb-10 my-xl-0 col-xl-6 text-xl-right links order-1 order-xl-0 ">
                <a target="_blank" rel="noopener noreferrer" href="https://www.komunidad.io/" className="first-footer-link color-main">
                  Sitio Web
                </a> |
                <a target="_blank" rel="noopener noreferrer" href="https://komunidad.freshdesk.com/support/home" className="footer-link color-main">
                  Soporte
                </a> |
                <a target="_blank" rel="noopener noreferrer" href="https://creomx.com/edomex/Aviso.pdf" className="footer-link color-main">
                  Politica de Privacidad
                </a> |
                <a target="_blank" rel="noopener noreferrer" href="https://www.komunidad.io/terminosycondiciones" className="footer-link color-main">
                  Términos
                </a>
              </div>
            </div>
            <div className="footer-line">
            </div>
            <div className="row align-items-baseline">
              <div className="col-xl-10 col-md-9 ">
                <div className="color-heading text-adaptive">
                  Mide el impacto de tus programas de innovación y emprendimiento.
                </div>
              </div>
              <div className="mt-10 mt-md-0 col-xl-2 col-md-3 text-md-right socials">
                <a href="https://www.linkedin.com/showcase/komunidad/?originalSubdomain=mx" className="f-18 link color-main mr-15">
                  <i className="mdi mdi-linkedin m-r-10"
                    style={{ color: "#c2c2c2", fontSize: '24px', cursor: 'pointer' }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

Footer.propTypes = {
  onResize: PropTypes.func,
};

Footer.defaultProps = {
  onResize: (width) => { },
};
export default withRouter(Footer);
