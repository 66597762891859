/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, May 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import KomunidadApi from '../api/api.js';
import BootstrapTable from 'react-bootstrap-table-next';
import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import KCompanyList from '../components/KCompanyList';
import FormInput from '../components/form/FormInput';
import KSelectCompanyWidget from '../components/KSelectCompanyWidget';


/**
*   Modal to assign a company to a program.
*
*/

class KAssignCompanyToProgramModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companySelected:{},
      companies:[], //cache
      companiesTable:[], // companies that are shown in the table
      companiesSelected:[],
      companiesLoadState:'idle',
      step:'select', /** posible values: select,confirmation,assign,response*/
      query:'',
      response:'',
      responsesResults:[]
    };
    this.executeAfterModalClose           =  this.executeAfterModalClose.bind(this);
    this.onCompanySelected                =  this.onCompanySelected.bind(this);
    this.backNavigation                   =  this.backNavigation.bind(this);
    this.hideModal                        =  this.hideModal.bind(this);
    this.handleCloseModal                 =  this.handleCloseModal.bind(this);
    this.clear                            =  this.clear.bind(this);
    this.getCompanies                     =  this.getCompanies.bind(this);
    this.onChange                         =  this.onChange.bind(this);
    this.onClickAssign                    =  this.onClickAssign.bind(this);
    this.removeCompanyFromSelectedList    =  this.removeCompanyFromSelectedList.bind(this);
    this.onClickNext                      =  this.onClickNext.bind(this);
    this.filterSelectedCompaniesFromTable =  this.filterSelectedCompaniesFromTable.bind(this);
  }

  getCompanies(query){
    KomunidadApi.findCompanies(query).then((res) => {
       if(res.success){
         if(res.hasOwnProperty('companies')){
           this.setState({
             companies:res.companies,
             companiesLoadState:'success',
           }, () =>{
             this.filterSelectedCompaniesFromTable();
           });
         }
       }else{
         this.setState({
           companies:[],
           companiesTable:[],
           companiesLoadState:'failed'
         })
       }
    });
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.clear();
    this.props.closeModal(false);
  }


  //Add the selected company on the companiesSelected list
  onCompanySelected(company){
    let newCompanies = this.state.companiesSelected;
    let newCompaniesIds = newCompanies.map(c => c._id);

    //check if the company selected is alredy on the list
    if(!newCompaniesIds.includes(company._id)){
      newCompanies.push(company);
      this.setState({
        companiesSelected:newCompanies
      }, () =>{
        //remove the company selected from the company list
        this.filterSelectedCompaniesFromTable();
      });
    }
  }

  filterSelectedCompaniesFromTable(){
    let selectedCompaniesIds = this.state.companiesSelected.map( c => c._id);
    let newCompaniesTable = this.state.companies.filter(ct => !selectedCompaniesIds.includes(ct._id));
    this.setState({
      companiesTable: newCompaniesTable
    })
  }


  removeCompanyFromSelectedList(index){
    this.setState(state => {
      const companiesSelected = state.companiesSelected.filter((item, j) => index !== j);
      return {
        companiesSelected,
      };
    }, () =>{
      this.filterSelectedCompaniesFromTable();
    });
  }

  renderBtnBack(){
    if(this.state.step === 'confirmation'){
      return(
        <div className="modal__btn__back" onClick={() => this.backNavigation()}>
          <i className="mdi mdi-chevron-left" style={{fontSize: '30px', color: "#c2c2c2"}}></i>
        </div>
      )
    }else{
      return null
    }
  }

  backNavigation(){
    this.setState({step:'select'})
  }

  clear(){
    this.setState(
      {
        companies:[],
        query:'',
        companySelected:{},
        companiesSelected:[],
        step:'select',
        response:'',
        companiesLoadState:'idle'
      }
    );
  }

  onShow(){
    this.clear();
  }

  onClickAssign(){
    let program_id = this.props.program._id;
    let companies_ids = this.state.companiesSelected.map( c => c._id).join("|");
    // assignCompany(program_id,company_id,price_paid)
    KomunidadApi.assignCompany(program_id,companies_ids,'0').then((res) => {
      if(res.success){
        this.setState({
          step:'response',
          response:'success',
          responsesResults:res.results
        });
        this.props.onCompanyAssigned(this.props.program._id)
        // this.handleCloseModal(false);
      }else{
        this.setState({step:'response',response:'fail'});
      }

    });
  }

  onClickNext(){
    this.setState({step:'confirmation'});
  }

  renderAssignBtn(){
    if(this.state.step === 'confirmation'){
      return (
        <Button className="btn btn-primary btn-save" onClick={() => this.onClickAssign()}>Asignar</Button>
      )
    }else{
      return null
    }
  }
  renderNextBtn(){
    if( (this.state.step === 'select') && (this.state.companiesSelected.length > 0) ){
      return (
        <Button className="btn btn-primary btn-info" onClick={() => this.onClickNext()}>Siguiente</Button>
      )
    }else{
      return null
    }
  }

  onChange(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.setState({query:value});

    if(value.trim() !== '' && !data.isTriggeredByOnBlur){
      this.getCompanies(value);
    }
  }

  renderStepSelectCompanies(){
    return(
      <div className="card-body p-0">
        <FormInput
          ref={ref => this.kInputEmail = ref}
          editMode={true}
          id="nombre de la empresa"
          label="Escribe el nombre de la empresa"
          placeholder={"Nombre de la empresa"}
          cssClass="m-0"
          value={this.state.query}
          required={false}
          validationRules="required|string"
          onChange={ change => { this.onChange(change) }}
          showCounter={false}
          />

        <div className='wrapper__kAMCompanyList'>
          <KCompanyList companies={this.state.companiesTable}
                        requestStatus={this.state.companiesLoadState}
                        shortView={true}
                        hoverStyle={true}
                        stripedStyle={false}
                        borderlessStyle={false}
                        onClickCompany={this.onCompanySelected}
                        emptyListTitle={'No se encontraron empresas'}
                        showCreator={true}
                        style={{cursor:'pointer'}}
                        />
        </div>

        <div className='wrapper__kAMCompanyWidget'>
          <KSelectCompanyWidget companies={this.state.companiesSelected}
                                onClickRemoveItem={this.removeCompanyFromSelectedList}/>
        </div>
      </div>
    )
  }

  renderStepConfirmation(){
    let txt = `Estás por asignar la siguiente empresa al programa ${this.props.program.name}`;

    if(this.state.companiesSelected.length > 1){
      txt = `Estás por asignar las siguientes empresa al programa ${this.props.program.name}`;
    }

    return(
      <div className="card-body p-0">
        <KSelectCompanyWidget companies={this.state.companiesSelected}
                              label={txt}
                              showRemoveBtn={false}/>
      </div>
    )
  }

  renderStepResponse(){
    if(this.state.response === 'fail'){
      return(
        <div className="card-body p-0">
          <center>
            <h4 className="m-b-20 text-danger">
              <b>Hubo un error al tratar de agregar tus empresas.</b>
            </h4>
          </center>
        </div>
      )
    }else{
      const results = this.state.responsesResults;
      const columns = [
        {
          dataField: 'company_name',
          text: 'Empresa',
        },
        {
          dataField: 'result',
          text: 'Estado',
          formatter: (cellContent) => {
            const status = {
              service_na:'Error al asignar',
              joined:'Empresa asignada',
              already_joined:'Empresa ya asignada',
            }
            const className={
              service_na:'text-danger',
              joined:'text-success',
              already_joined:'text-warning',
            }

            return <span className={className[cellContent]}>{status[cellContent]}</span>
          }
        },

      ];
      return(
        <div className="card-body p-0">
          <BootstrapTable
            striped
            bordered={ false }
            keyField='company_id'
            data={ results }
            columns={ columns }
            wrapperClasses="table-responsive"
            classes="react-bootstrap-table"
          />
        </div>
      );
    }
  }

  renderContent(){
    switch (this.state.step) {
      case 'select':
        return this.renderStepSelectCompanies();
      case 'confirmation':
        return this.renderStepConfirmation();
      case 'response':
        return this.renderStepResponse();
      default:
        return null;
    }
  }
  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={() => this.clear()}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
        <Modal.Header closeButton>
          {this.renderBtnBack()}
          <Modal.Title>
            Asignar empresa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='wrapper__kAssignModalCompany'>
            {this.renderContent()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className={'btn btn-danger btn-cancel'} onClick={() => this.handleCloseModal(false)}>Cerrar</Button>
          {this.renderAssignBtn()}
          {this.renderNextBtn()}

        </Modal.Footer>
      </Modal>
    );
  }
}

KAssignCompanyToProgramModal.propTypes = {
  /** Close the modal. */
  closeModal          : PropTypes.func,
  /** Callback when the company or companies are invited.*/
  onCompanyAssigned   : PropTypes.func,
  /** When true the modal will show itself. */
  showModal           : PropTypes.bool,
  /** The program object. */
  program             : PropTypes.object,
};

KAssignCompanyToProgramModal.defaultProps = {
  closeModal        : () => {},
  onCompanyAssigned : () => {},
  showModal         : false,
  program           : {},
};

export default KAssignCompanyToProgramModal;
