/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez && Israel Arellano <juan@startuplab.mx> && <israel@startuplab.mx>, October 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';
import '../scss/pages/user-card.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import KSpeakerList from '../components/KSpeakerList';

import speakers from './speakers.json'

//Other imports
import { orderBy } from 'lodash';
import { isUserProfileComplete } from '../utils/Utils.js';

class SpeakersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      sidebarVisible: true,
      me: {},
    };
    this.handleSidebarVisible = this.handleSidebarVisible.bind(this);
    this.handleHeaderResize = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile = this.redirectToEditProfile.bind(this);
  }

  componentDidMount() {
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        if (!isUserProfileComplete(res.user)) {
          this.redirectToEditProfile();
        } else {
          this.setState({
            me: res.user,
            authorized: true,
          });
        }
      } else {
        //redirect profile
        this.props.history.push("/profile");
      }
    });
  }

  redirectToEditProfile() {
    this.props.history.push("/profile/edit");
  }

  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true,
    });
  }

  handleSidebarVisible() {
    this.setState({
      sidebarVisible: !this.state.sidebarVisible,
    });
  }

  renderContent() {

    let sortedSpeakers = speakers.speakers;

    sortedSpeakers = orderBy(sortedSpeakers, ['name']);

    return (
      <KSpeakerList speakers={sortedSpeakers}/>
    )
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className="fix-header card-no-border fix-sidebar height100">
          <Header
            user={this.state.me}
            onBurgerClicked={() => this.handleSidebarVisible()}
            onResize={(w) => this.handleHeaderResize(w)}
          />
          <SideBar user={this.state.me} visible={this.state.sidebarVisible} />
          {/* Page wrapper  */}
          <div
            id="agenda-viewport"
            className={
              this.state.sidebarVisible
                ? "page-wrapper color-gray sidebar-wrapper-open"
                : "page-wrapper color-gray"
            }
          >
            <div className="container-fluid">
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h3 className="text-themecolor">Ponentes CREO MX 2020</h3>
                </div>
              </div>
              {/* Start Page Content */}
              {this.renderContent()}
              {/* End PAge Content */}
            </div>
          </div>
          {/* End Page wrapper  */}
        </div>
      );
    } else {
      return null;
    }
  }
}
export default SpeakersView;
