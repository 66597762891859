/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, March 2020
 */
//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/components/_eb_components.scss';

//components
import KImage from '../KImage';
/**
*  Show an Eventbrite event. Used by [EBListEvents](/#EBListEvents).
*/

class EBOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEvent    = this.onClickEvent.bind(this);
  }

  onClickEvent(review){
    this.props.onClickEvent(review);
  }
  render() {
    const organization = this.props.organization;
    return(
      <div className="eb-event-row m-b-20" onClick={()=> {this.props.onClickOrganization(this.props.organization)}}>
        <div className="m-r-10">
          <KImage className="companyImage eb-event-image" src={null} type={"eventbrite"}/>
        </div>
        <div className="eb-event-description">
          <h5 class="m-b-5 font-medium">
            {organization.name}
          </h5>
        </div>
      </div>
    )
  }
}

EBOrganization.propTypes = {
  /** The organization object*/
  organization     : PropTypes.object,
  /** Callback on click event*/
  onClickOrganization : PropTypes.func,
};

EBOrganization.defaultProps = {
  organization      : {},
  onClickOrganization : ()=>{}
};

export default EBOrganization;
