/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx> February 2021
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';

// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/_mentors-view.scss';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KCoursesGrid from '../../components/KCoursesGrid';
import ProgramsCoursesPicker from '../../components/ProgramsCoursesPicker';
import { Tab, Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { isUserProfileComplete } from '../../utils/Utils.js';


class CoursesListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      mentorsLoadState:'idle',
      multipleOptions: [],
      my_courses: [],
      uncompleted_courses: [],
      filtered_courses: [],
      courses: [],
      completed_courses: [],
      activeKey: "courses_by_program",
      displayMode:'grid', //state values grid and list
      sortMode:'asc',
      selectedId:['mine'],
      isMyCourse: true,
      isPartner:false,
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.getUserCourses                = this.getUserCourses.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.onProgramSelected             = this.onProgramSelected.bind(this);
    this.hasPartnerRole                = this.hasPartnerRole.bind(this);
    this.changeTab                     = this.changeTab.bind(this);
  }

  componentDidMount(){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let tabQuery = params.get('tab');

    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true
          });
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
    this.getMyCourses();
    
    // redirect to tab
    if(tabQuery === 'completed_courses'){
      this.changeTab('completed_courses');
    }
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  getUserCourses(query){
    KomunidadApi.getUserCourses(query).then( (res) => {

      if(res.success){
         if(res.courses.length > 0){
           let courses = this.sortCoursesByName(res.courses)
           courses = this.filterJoinedCourses(courses)
            this.setState({courses: courses, isMyCourse: false});
          }else{
            this.setState({courses:[]});
          }
      }else{    
        this.setState({courses: []});
      }
      //TODO: swal error or msg in table with table_msg
    });
  }

  getMyCourses() {
    KomunidadApi.getMyCourses().then( (res) => {
      if(res.success){
         if(res.courses.length > 0){
          //  let sortedCourses = this.sortCoursesByName(res.courses);
            this.setState(
              {
                my_courses: res.courses,
                uncompleted_courses: res.courses.filter(c => c.progress !== c.chapters),
                completed_courses: this.sortCoursesByDate(res.courses.filter(c => c.progress === c.chapters))
              });
          }else{
            this.changeTab('courses_by_program');
            this.setState({my_courses:[]});
          }
      }else{    
        this.setState({my_courses: []});
      }
    });
  }

  joinAllCourses(){
    let courses_ids = this.state.filtered_courses.map(c => c._id).join("|");
    KomunidadApi.joinMultipleCourses(courses_ids).then( (res) => {
      if(res.success){
        Swal.fire({
          type: 'success',
          title: 'Cursos inscritos',
          text: 'Para ver el material de los cursos serás redirigido a "Mis cursos"',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then(result => {
          this.getMyCourses();
          this.changeTab('my_courses');
          this.onProgramSelected(this.state.selectedId)
        })
      } else {
        Swal.fire({
          type: 'info',
          title: 'Ya te encuentras inscrito en alguno de estos cursos',
          text: 'Puedes ver tu cursos inscritos en Mis cursos',
          customClass: {
            confirmButton: "swalBtnTeal",
            title: "swal-custom-title",
          },
        });
      }
    });
  }

  changeTab(key){
    this.setState({activeKey: key});
  }

  sortCoursesByName(courses){
    courses.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return courses
  }

  sortCoursesByDate(courses){
    courses.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    return courses
  }

  filterJoinedCourses(courses){
    if(this.state.my_courses.length > 0){
      let joinedCoursesIds = this.state.my_courses.map(c => c._id)
      let my_courses = this.state.my_courses;

      courses = courses.map(function (c) { 
        if(joinedCoursesIds.includes(c._id)){
          let progress = my_courses[my_courses.findIndex(x => x._id === c._id)].progress;
          let chapters = c.chapters.length;
          console.log(c.chapters.length)
          return {...c, is_mine:true, progress:progress, chapters:chapters}
        }else{
          return {...c, is_mine: false}
        }
      });
      
      // Filters joined courses
      let filtered_courses = courses.filter(c => !joinedCoursesIds.includes(c._id)) 
      this.setState({filtered_courses:filtered_courses})
    }
    return courses
  }

  onProgramSelected(id){
    let programId = id[0];
    this.setState({
      selectedId:id
    })
    if(programId === 'public'){
      return this.getUserCourses(programId);
    }else if(programId === 'mine'){
      return this.getMyCourses();
    }else{
      return this.getUserCourses(programId);
    }
  }

  hasPartnerRole(){
    if (this.state.me.roles.includes('partner')){
      this.setState({ isPartner: true })
    }else{
      this.setState({ isPartner: false });
    }
  }

  renderCoursesGrid() {
    return (
      <KCoursesGrid
        courses={this.state.courses}
        requestStatus={'success'}
        programId={this.state.selectedId[0]}
        isMyCourse={this.state.isMyCourse}/>
    )
  }
  renderMyCoursesGrid() {
    if (this.state.courses) {
      return (
        <KCoursesGrid
          courses={this.state.uncompleted_courses}
          requestStatus={'success'}
          programId={this.state.selectedId[0]}
          isMyCourse={true}/>
      )
    } else {
      return (
        <div className="text-center">
          <h3>Aún no tienes cursos inscritos</h3>
          <i className="mdi mdi-account-star-variant m-t-20 m-b-20" style={{fontSize: '50px', color:"#c2c2c2"}}></i>
        </div>
      )
    }
  }
  renderCompletedCourses(){
    if (this.state.completed_courses) {
      return (
        <KCoursesGrid
          courses={this.state.completed_courses}
          requestStatus={'success'}
          programId={this.state.selectedId[0]}
          isMyCourse={true}/>
      )
    }
  }

  renderJoinAllCourses(){
    if(this.state.filtered_courses.length > 0 ){
      return(
        <button className='btn btn-info text-white'
          style={{marginLeft: 0, marginRight: ".50rem"}}
          onClick={() => this.joinAllCourses()}>Inscribirse a todos los cursos
        </button>
      )
    }else{
      return null
    }
  }

  renderContent(){
    return this.renderCoursesGrid();
  }

  renderMyCourses(){
    return null;
  }
  
  renderCoursesByProgram(){
    return this.renderCoursesGrid();
  }

  renderCompletedCoursesTab(){
    if(this.state.completed_courses.length > 0){
      return(
        <Nav.Item className="nav-item">
          <Nav.Link className="nav-link" eventKey="completed_courses" id='completed_courses'>Cursos completados</Nav.Link>
        </Nav.Item>
      )
    }else{
      return null;
    }
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className={this.state.courses.length <= 4 ? "height100" : null}>
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">

                <div className="row page-titles">
                  <div className="col-md-12 align-self-center">
                    <h3 className="text-themecolor">Academia</h3>
                  </div>
                </div>
                {/* <div className="row view-subtittle">
                  <div className="col-md-9">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                  </div>
                </div> */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                        <Tab.Container 
                        activeKey={this.state.activeKey}
                        onSelect={(k) => this.changeTab(k)}
                        defaultActiveKey="courses_by_program">
                          <Nav className="nav" bsPrefix='tabCustom' >
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="courses_by_program" id='courses_by_program'>Cursos disponibles</Nav.Link>
                            </Nav.Item>

                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="my_courses" id='my_courses'>Mis cursos</Nav.Link>
                            </Nav.Item>
                            

                            {this.renderCompletedCoursesTab()}

                          </Nav>

                          <Tab.Content className="tab-content">

                            <Tab.Pane eventKey="my_courses">
                              <div className="card-body">
                                {this.renderMyCoursesGrid()}
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="courses_by_program">
                              <div className="card-body">
                                <div className="row mb-3">

                                  <div className="col-md-9 align-self-center">
                                    <label className="font-weight-bold">
                                      Para inscribirte en los cursos especializados selecciona 
                                      el programa en el cual estás participando
                                    </label>
                                  </div>
                                  <div className="col-md-3 align-self-center">
                                    {this.renderJoinAllCourses()}
                                  </div>
                                  <div className="col-md-6 align-self-center">
                                    <ProgramsCoursesPicker onSelect={this.onProgramSelected} 
                                                          showMyCourses={false}
                                                          multipleProgramSelected={this.state.selectedId}
                                                          />
                                  </div>
                                </div>
                                {this.renderCoursesByProgram()}
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="completed_courses">
                              <div className="card-body">
                                {this.renderCompletedCourses()}
                              </div>
                            </Tab.Pane>

                          </Tab.Content>
                        </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                
                {/* Start Page Content */}
                {/* {this.renderContent()} */}
                {/* End PAge Content */}
              </div>
              <Footer/>
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }
}
export default CoursesListView;
