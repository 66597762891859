/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx> & Juan Alberto Hernández <juan@startuplab.mx>,
 * America Mendoza <america@startuplab.mx> & Darien Miranda <darien@startuplab.mx>
 * December 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import KomunidadApi from '../api/api.js';

import beepSound from '../assets/sounds/broadcastsound3.wav'
import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';
import "react-tageditor/dist/style/default.css";

import FormInput from './form/FormInput';
import TagEditor from 'react-tageditor';
import Swal from 'sweetalert2';
import KImage from "./KImage";
import ImagePicker from "./ImagePicker";

var _= require('lodash');

/**
*  Send emails to partners, mentors, companies or all participants of a program.
*
*
*/
class KSendManagerEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject:'',
      toList:[],
      emailContent:RichTextEditor.createEmptyValue(),
      bodyIsEmpty:true,
      sendCopy:true,
      image:'',
      images:[]
    }
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
    this.onChangeForm                =  this.onChangeForm.bind(this);
    this.onChangeHtml                =  this.onChangeHtml.bind(this);
    this.onClickSendEmail            =  this.onClickSendEmail.bind(this);
    this.cleanStates                 =  this.cleanStates.bind(this);
    this.getUsersIdsFromCompanies    =  this.getUsersIdsFromCompanies.bind(this);
    this.getUsersIds                 =  this.getUsersIds.bind(this);
    this.getIds                      =  this.getIds.bind(this);
    this.getCompaniesIds             =  this.getCompaniesIds.bind(this);
    this.onChangeInput               =  this.onChangeInput.bind(this);
    this.getImage                    = this.getImage.bind(this);

    this.audio = new Audio(beepSound);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.usersList !== state.toList) {
      let usersList = props.usersList;
      let toList;
      if(props.type === 'user'){
        toList = usersList.map( user => user.email);
      }else if(props.type === 'company'){
        toList = usersList.map( company => company.name);
      }else if(props.type === 'program'){
        toList = [props.programName];
      }

      return {
        toList: toList
      };
    }
    // Return null if the state hasn't changed
    return null;
  }
  getImage(file) {
    let name = window.URL.createObjectURL(file);
    this.setState({
      image: name
    });
  }
  onChangeHtml(emailContent){
    this.setState({emailContent});
    if (this.props.onChange) {
      this.props.onChange(
        emailContent.toString('html')
      );
    }
    let bodyIsEmpty =  !emailContent.getEditorState().getCurrentContent().hasText();
    this.setState({
      bodyIsEmpty:bodyIsEmpty
    });
  }

  onChangeForm(data){
    this.setState({
      [data.model]:data.value
    });
  }

  handleCloseModal(show){
    this.clear();
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    //Clean the states
    this.cleanStates();
    //Close modal from the parent
    this.props.closeModal(false);
  }

  cleanStates(){
    this.setState({subject:'',toList:[],emailContent:RichTextEditor.createEmptyValue(),bodyIsEmpty:true,sendCopy:true});
  }

  onClickSendEmail(){
    // This line gets the ids, filter(null,undefined,'') and then join the elements by '|'
    let users_ids = this.getIds().filter((u) => u).join("|");
    let companies_ids = this.getCompaniesIds().filter((c) => c).join("|");

    let body = this.state.emailContent.toString('html');
    let subject = this.state.subject;
    let program_id = this.props.programId;
    let sendCopy = this.state.sendCopy ? 'true' : 'false';

    if(subject.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El asunto no puede estar vacío',
        footer: '',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.bodyIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El mensaje no puede estar vacío',
        footer: '',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    KomunidadApi.sendBroadcast(body,subject,program_id,companies_ids,users_ids,sendCopy,this.state.images).then(res => {
      if(res.success){
        //play sound an then dismiss
        this.audio.play();
        this.hideModal();
      }else{
        Swal.fire({
          type: 'error',
          title: 'Error al enviar tu mensaje',
          text: 'Hubo un error al enviar tu mensaje. Intenta de nuevo mas tarde.',
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
        this.clear();
      }
    });
  }
  clear(){
    this.setState({image:null,images:[]})
  }

  getIds(){
    switch (this.props.type) {
      case 'user':
        return this.getUsersIds();
      case 'program':
        return this.getUsersIds();
      case 'company':
        return this.getUsersIdsFromCompanies();
      default:
        return [];
    }
  }

  getUsersIds(){
    // let emails = this.tagEditor.output();
    let usersList = this.props.usersList;
    let ids = usersList.map( user => {
      if(user !== null) {
        return user._id
      }
    });

    return ids
  }

  getUsersIdsFromCompanies(){
    const usersList = this.props.usersList;

    let mentors = usersList
    .filter(company => company.mentors.length>0)
    .map(company => company.mentors.map(companyMentors => companyMentors.user));

    let partners = usersList
    .filter(company => company.partners.length>0)
    .map(company => company.partners.map(companyPartners => companyPartners.user));

    partners = [].concat.apply([],partners);
    mentors = [].concat.apply([],mentors);

    return _.uniq(partners.concat(mentors));
  }

  getCompaniesIds(){
    let ids = [];
    if(this.props.type === 'company'){
      let usersList = this.props.usersList;
      ids = usersList.map( company => company._id);
    }
    return ids
  }

  renderToText(){
    let length = this.state.toList.length;
    let msg;

    if(this.props.type === 'company'){
      msg = (length ===  1) ? `${length} empresa` : `${length} empresas`;
    }else if(this.props.type === 'program'){
      msg = 'el programa'
    }else{
      msg = (length ===  1) ? `${length} usuario` : `${length} usuarios`;
    }

    return(
      <small>( {msg} )</small>
    )
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }
  handleFile(e){
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      let t = this;
      reader.addEventListener("load", () =>{ // Read the file
        const images = [...this.state.images];
        images[0].Content = reader.result;
        this.setState({image:reader.result,images:images});
      });
      const file = e.target.files[0];
      this.setState({images:[{Name:file.name,ContentType:file.type,Content:''}]})
      reader.readAsDataURL(e.target.files[0]);
    }

  }
  openInput(){
    document.getElementById("image_input").click();
  }
  renderContent(){
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ],
      BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };

    return (
      <div className="card-body">
        <div className="form-group">
          <h4>Para {this.renderToText()}</h4>
          <TagEditor ref={ref => this.tagEditor = ref} tags={this.state.toList} delimiters={[]}  />
        </div>
        <div className="form-group">
          <h4>Asunto</h4>
          <FormInput
            id="asunto"
            model="subject"
            value={this.state.subject}
            maxLength={250}
            showCounter={false}
            validationRules="maxLen:250"
            onChange={ change => {this.onChangeForm(change) } }
            />
        </div>
        <div className="form-group">
        </div>
        <h4><i className="ti-link" /> Mensaje </h4>
        <div style={{minHeight:"200px"}}>
          <RichTextEditor
            value={this.state.emailContent}
            onChange={this.onChangeHtml}
            className="email-size"
            toolbarConfig={toolbarConfig}
          />
        </div>
        <div className="m-t-20">
          <h4><i className="ti-link" /> Imagen</h4>
          <input id="image_input" type={'file'} hidden={true}
                 accept="image/jpeg,image/jpg,image/png"
                 onChange={(ev) => this.handleFile(ev)}/>
          <KImage src={this.state.image}
                  type='email_image'
                  className={'rounded img-fluid selectLogo'}
                  alt="Adjuntar imagen"
                  onClick={() => this.openInput()}
          />
        </div>

        <label className="m-t-20">
         <input
           type="checkbox"
           id="sendCopy"
           className='filled-in chk-col-light-blue'
           name='sendCopy'
           value={this.state.sendCopy}
           checked={this.state.sendCopy}
           onChange={this.onChangeInput}/>
         <label htmlFor="sendCopy" className={this.state.sendCopy ? "": "text-muted"}>Enviarme copia del correo</label>
        </label>

        <br/>

        <button className="btn btn-info m-t-20 d-inline-flex align-items-center" onClick={() => this.onClickSendEmail()}>
          <i className="mdi mdi-email m-r-10" style={{fontSize: '18px', color:'#fffff'}}></i>
          Enviar
        </button>
        <button className="btn btn-danger m-t-20 d-inline-flex align-items-center" onClick={() => this.handleCloseModal(false)}>
          <i className="mdi mdi-close m-r-10" style={{fontSize: '18px', color: '#fffff'}}></i>
          Cancelar
        </button>
      </div>
    )
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Envia tu mensaje
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
      </Modal>
    );
  }
}

KSendManagerEmail.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,

  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** A list of users to send the email. */
  usersList        : PropTypes.array,
  /** The program id. */
  programId        : PropTypes.string,
  /** The program name. */
  programName      : PropTypes.string,
  /** If the type of user to send the email: user, company or program. */
  type             : PropTypes.string,
};

KSendManagerEmail.defaultProps = {
  closeModal       : () => {},
  showModal        : false,
  usersList        : [],
  programId        : '',
  programName      : '',
  type             : 'user', //posible values are user, company and program
};

export default KSendManagerEmail;
