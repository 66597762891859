/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, June 2019
 */
 // Regular imports
import React from "react";
import ReactCrop from "react-image-crop";
import PropTypes from 'prop-types';

import "react-image-crop/dist/ReactCrop.css";
import './ImagePicker.css';

var fixOrientation = require('fix-orientation');

/**
 * Select a image from your library.
 * And returns the blob of the image.
 * The image can be cropped.
 */

class ImagePicker extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      showImageSelected:false,
      src: null,
      crop: {
        unit:"px",
        width:this.props.minWidthProp,
        height:this.props.minHeightProp,
        aspect:(this.props.minWidthProp / this.props.minHeight),
      },
      imageHeight: 0
    };
    this.imageBlob        = null;
    this.removeFile       = this.removeFile.bind(this);
    this.sentCroppedImage = this.sentCroppedImage.bind(this);
    this.openPicker       = this.openPicker.bind(this);
    this.getCroppedImg    = this.getCroppedImg.bind(this);
  }


  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      let t = this;
      reader.addEventListener("load", () =>{ // Read the file
       //fix orientation if needed
       fixOrientation(reader.result, { image: true }, function (fixed, image) {
         t.setState({src:fixed,showImageSelected:true});
       });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    /*Limits crop position*/
    if(crop.x < 0 || crop.y < 0 || percentCrop.x >= 100 || percentCrop.y >= 100){
      crop.x = 0;
      crop.y = 0;
    }
    
    /*Blocks aspect ratio*/
    let ar = this.props.minHeightProp / this.props.minWidthProp
    crop.height = ar * crop.width

    /*Fix crop size*/
    if(crop.width <= this.props.minWidthProp){
      crop.width = this.props.minWidthProp;
    }
    if(crop.height <= this.props.minHeightProp){
      crop.height = this.props.minHeightProp;
    }

    if(crop.height <= this.state.imageHeight){
      this.setState({ crop });
    }

  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * this.props.zoom;
    canvas.height = crop.height * this.props.zoom;
    const ctx = canvas.getContext("2d");

    /*get natural height*/
    this.setState({ imageHeight: image.height})

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * this.props.zoom,
      crop.height * this.props.zoom
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        this.imageBlob = blob;
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/png");
    });
  }

  sentCroppedImage(){
    if(this.state.croppedImageUrl === null){
    }else{
      this.props.getCroppedImage(this.imageBlob);
      this.removeFile();
    }
  }

  renderReactCrop(){
    if(this.state.showImageSelected){
      return(
        <div className='imagePickerWrapper'>
          <div className='imagePickerCancelBtn' onClick={this.removeFile}>x</div>
          <ReactCrop
            ref={element => this.reactCrop = element}
            className={'reactCrop'}
            src={this.state.src}
            crop={this.state.crop}
            minHeight={this.props.minHeightProp}
            minWidth={this.props.minWidthProp}
            maxHeight={this.props.minHeightProp*6.8}
            maxWidth={this.props.minWidthProp*6.8}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
          <div className='imagePickerWrapperBtns'>
            <button type="button" onClick={this.sentCroppedImage} className="btn waves-effect waves-light btn-block btn-success">Guardar</button>
          </div>
        </div>
      )
    }else{
      return null
    }
  }

  removeFile(){
    this.fileInput.value = '';
    this.setState({src:null,showImageSelected:false});
  }
  openPicker(){
    this.fileInput.click();
  }
  render() {
    return (
      <div className='imagePickerComponent'>
        <div className='imagePickerWrapperInput'>
          <input type="file"
                 className={"imagePickerInput"}
                 accept="image/*"
                 onChange={this.onSelectFile}
                 ref={(input) => { this.fileInput = input; }}
          />

        </div>
        {this.renderReactCrop()}
      </div>
    );
  }
}
ImagePicker.propTypes = {
  /** Returns the blob of the cropped image. */
  getCroppedImage: PropTypes.func,
  /** Min width **/
  minWidthProp: PropTypes.number,
  /** Min width **/
  minHeightProp: PropTypes.number,
  /** Set resize image**/
  zoom: PropTypes.number
};

ImagePicker.defaultProps = {
  getCroppedImage: () => {},
  minWidthProp: 150,
  minHeightProp: 150,
  zoom: 1
};
export default ImagePicker;
