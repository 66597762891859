/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import UserMale from '../assets/images/avatar_male_2021.png';
import UserFemale from '../assets/images/avatar_female_2021.png';
import Company from '../assets/images/mistartup_komunidad_2021.png';
import Course from '../assets/images/card-course.png';
import Program from '../assets/images/ilustracion-komunidad-message.png';
import EventBriteImage from '../assets/images/logos/eventbrite.svg';
import EmailImage from '../assets/images/adjuntarImagen.png';

/**
*   Returns a default image if the image is null.
*   The default image is ../assets/images/avatar_male.png'.
*   The default className is k-img-circle.
*/

class KImage extends React.Component {
  constructor(props) {
    super(props);

    this.onClickImage      = this.onClickImage.bind(this);
  }

  onClickImage(){
    this.props.onClick();
  }

  getSrc(src){
    if((src === null) || (src === '') ){
      //set the default image
      if(this.props.type === 'user_male'){
        src = UserMale;
      }else if(this.props.type === 'user_female'){
        src = UserFemale;
      }else if(this.props.type === 'company'){
        src = Company;
      }else if(this.props.type === 'course'){
        src = Course;
      }else if(this.props.type === 'eventbrite'){
        src = EventBriteImage
      }else if(this.props.type === 'program'){
        src = Program
      }else if(this.props.type === 'email_image'){
        src = EmailImage
      }else{
        src = UserMale;
      }
    }
    return src;
  }

  render() {
    const src = this.getSrc(this.props.src);

    return (
      <img src={src} className={this.props.className} style={this.props.style} alt={this.props.alt} onClick={() => this.onClickImage()}/>
    );
  }
}

KImage.propTypes = {
  /** The url of the image. */
  src               : PropTypes.string,
  /** The class name of the image. */
  className         : PropTypes.string,
  /** The style tag of the image. */
  style             : PropTypes.object,
  /** The alt of the image. */
  alt               : PropTypes.string,
  /** If the image is a user or a company.
      To get the default image in case of
      the src is null or empty . */
  type              : PropTypes.string,
  /** Callback if the image is clicked.*/
  onClick           : PropTypes.func,
};

KImage.defaultProps = {
  src               : '',
  className         : 'k-img-circle',
  style             : {},
  alt               : '',
  type              : '', //user_male, user_female, company
  onClick           : () => {},
};

export default KImage;
