/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, June 2019
 * Darien Miranda <darien@startuplab.mx>, August 2019.
 * America Mendoza <america@startuplab.mx>, August 2019.
 * Major revision: Fri 23 August 2019 by Darien & America
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import Tooltip from 'react-tooltip-lite';
import * as validations from './validations.js';

// Custom SCSS
import '../../scss/components/_input.scss';

class FormTextArea extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      value: '',
      initialValue:'',
      has_error: false,
      error_message: ''
    };

    this.validate          = this.validate.bind(this);
    this.onChange          = this.onChange.bind(this);
    this.cancel            = this.cancel.bind(this);
    this.renderHelp        = this.renderHelp.bind(this);
    this.getCssClass       = this.getCssClass.bind(this);
    this.renderLabel       = this.renderLabel.bind(this);
    this.renderInput       = this.renderInput.bind(this);
    this.shouldValidate    = this.shouldValidate.bind(this);
    this.renderCharCounter = this.renderCharCounter.bind(this);
    this.onBlur            = this.onBlur.bind(this);

  }
  //Updates the state according to the props
  static getDerivedStateFromProps(props, current_state){
    return{
      value:props.value
    };
  }
  //Cancels the edition and returns the initial value
  cancel(){
    this.props.onChange({
      model: this.props.model,
      value: this.props.initialValue
    });
    this.setState({
      error_message:'',
      has_error:false
    })
  }
  shouldValidate() {
    return ((!isNil(this.state.value) && this.state.value.length > 0) || (this.props.required && this.props.validationRules))
  }

  validate () {
    let vm = this;
    if (vm.shouldValidate()) {
      //Reads the validation from the validationRules prop and parses it
      let BreakException = { method_failed: null, condition: null, field_id: null }
      let rules = vm.props.validationRules.split('|')
      try {
        rules.forEach(function (rule) {
          let validationFunction = rule.split(':');
          //convert nulls to empty strings
          let tx_value = vm.state.value  === null ? '' : vm.state.value;
          //calls the validators
          let is_valid = validationFunction.length > 1 ?
            validations[validationFunction[0]](tx_value, validationFunction[1]) :
            validations[validationFunction[0]](tx_value)
          //If the validation does not pass. throws the exception

          if (!is_valid) {
            BreakException = {
              field_id: vm.props.id,
              method_failed: validationFunction[0],
              condition: validationFunction.length > 1 ?
                validationFunction[1] : null
            }
            throw BreakException;
          }
        });
        //TODO: Validate when other type of exception happens
      } catch (exception) {
        vm.setState(prevState => ({
          has_error: true,
          error_message: validations.getMessage(exception)
        }));

        vm.props.onValidationFail(BreakException);

        return false;
      }
    }

    vm.setState({
      has_error: false,
      error_message: ''
    });

    let successObject = {
      field_id: this.props.id,
      value: this.props.value
    };

    vm.props.onValidationSuccess(successObject);

    return true;
  }

  onChange (event) {
    let new_value = event.target.value;

    if (this.props.maxLength) {
      new_value = new_value.substring(0, this.props.maxLength)
    }

    this.setState({ value: new_value }, () => {
      this.validate();
    });

    this.props.onChange({
      model: this.props.model,
      value: new_value
    });
  }

  onBlur () {
    let trimValue = (this.state.value ===  null) ? "" : this.state.value.trim();
    this.setState({ value: trimValue }, () => {
      this.validate();
    });

    this.props.onChange({
      model: this.props.model,
      value: trimValue
    });
  }

  getCssClass () {
    let state = this.state;
    let props = this.props;
    return {
      container_class: `form-group _textarea${state.has_error ? ' is-invalid' : ''}${props.maxLength ? ' has-counter' : ''}${props.help ? ' has-help' : ''}`,
      input_class: `form-input-component form-control${state.has_error ? ' is-invalid' : ''} ${props.cssClass}`
    };
  }

  renderLabel () {
    if (this.props.label && this.props.required) {
      return (
        <label htmlFor={this.props.id}>{this.props.label} <span>*</span></label>
      );
    } else if (this.props.label) {
      return (
        <label htmlFor={this.props.id}>{this.props.label}</label>
      );
    }
  }

  renderInput (custom_class) {
    if (this.props.editMode) {
      return (
        <textarea
          id={ this.props.model }
          value={ this.state.value }
          placeholder={ this.props.placeholder }
          onChange={ this.onChange }
          onBlur={ this.onBlur }
          className={ custom_class }>
          </textarea>
      );
    } else {
      return (
        <p className={custom_class}>{this.state.value}</p>
      );
    }
  }

  renderHelp() {
    if (this.props.help && this.props.editMode) {
      if (this.props.moreHelp) {
        return (
          <small id={`${this.props.id}Help`}
            className="form-text text-muted ">{this.props.help}
            <Tooltip content={<div className="more-help-tooltip">{this.props.moreHelp}</div>} useDefaultStyles className="tooltipText tooltipIcon">
              <i className="mdi mdi-help-circle"></i>
            </Tooltip>
          </small>
        );
      } else {
        return (
          <small id={`${this.props.id}Help`}
            className="form-text text-muted">{this.props.help}
          </small>
        );
      }
    }
  }

  renderCharCounter () {
    if (this.props.maxLength > 0 && this.props.showCounter && this.props.editMode) {
      let current_count = this.state.value ? this.state.value.toString().trim().length : 0;
      return (
        <small className='float-right mt-1' style={{fontSize: '.7em', color:'#67757c'}}>
          { current_count }/{this.props.maxLength}
        </small>
      );
    }
  }

  renderError () {
    if (this.state.has_error) {
      return (
        <small className="form-text text-danger">{ this.state.error_message }</small>
      );
    }
  }

  render () {
    let classObject = this.getCssClass();

    return (
      <div className={ classObject.container_class }>
        { this.renderLabel() }
        { this.renderError() }
        { this.renderInput(classObject.input_class) }
        { this.renderCharCounter() }
        { this.renderHelp() }
      </div>
    );
  }
}

FormTextArea.propTypes = {
  required: PropTypes.bool,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  showCounter:PropTypes.bool,
  id: PropTypes.string,
  help: PropTypes.string,
  model: PropTypes.string,
  value: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  cssClass: PropTypes.string,
  placeholder: PropTypes.string,
  validationRules: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onValidationFail: PropTypes.func,
  onValidationSuccess: PropTypes.func,
 }

FormTextArea.defaultProps = {
  required: false,
  editable: true,
  editMode: true,
  showCounter: true,
  id: '',
  help: '',
  model: '',
  value: '',
  initialValue: '',
  label: '',
  cssClass: '',
  placeholder: '',
  validationRules: '',
  maxLength: 255,
  onChange: () => {},
  onValidationFail: () => {},
  onValidationSuccess: () => {}
}

export default FormTextArea;
