/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Junuary 2020
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { renderFullNameOrEmail } from '../utils/Utils.js';
import {PopupText} from 'react-calendly';
//components
import KImage from '../components/KImage';
import KCompanyModal from './KCompanyModal';
import KMentorProfileModal from './KMentorProfileModal';


/**
*   Display the information of the mentor used by the component [KMentorsList](/#kmentorslist).
*   Invite a mentor to a company.
*   View the mentor profile.
*/

class KMentorsCompleteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      showProfileModal:false,
    };
    this.handleOpenModal       = this.handleOpenModal.bind(this);
    this.handleProfileModal    = this.handleProfileModal.bind(this);
  }

  handleOpenModal(value){
    this.setState({showModal: value});
  }

  handleProfileModal(value){
    this.setState({showProfileModal: value});
  }

  renderLinkein(mentor){
    if(mentor.social_linkedin !== null && mentor.social_linkedin !== ''){
      return(
        <a href={mentor.social_linkedin} target="_blank" rel="noopener noreferrer">
          <i className="mdi mdi-linkedin-box" style={{fontSize: '22px'}}></i>
        </a>
      )
    }else{
      return null;
    }
  }

  renderCVLink(user){
    let has_cv = false;
    if(user.hasOwnProperty("mentor_cv_file")){
      if(user.mentor_cv_file !== null){
        if(user.mentor_cv_file.trim() !== ""){
          has_cv = true;
        }
      }
    }
    if(has_cv){
      return(
        <a className="box-title m-b-0" href={this.props.mentor.mentor_cv_file}>Descargar CV</a>
      )
    }else{
      return(null)
    }
  }

  renderInvite(){
    if(!(this.props.mentor.is_self || this.props.isMyMentor)){
      return(
        <button className='btn btn-info btn-save text-white'
                style={{marginLeft: 0, marginRight: ".50rem"}}
                onClick={() => this.handleOpenModal(true)}>Invitar a empresa</button>
      )
    }else{
      return(null);
    }
  }

  renderCalendly(){
    let has_calendly = false;
    if(this.props.mentor.hasOwnProperty("calendly_username")){
      if(this.props.mentor.calendly_username !== null){
        if(this.props.mentor.calendly_username.trim() !== ""){
          has_calendly = true;
        }
      }
    }
    if(has_calendly){
      return(
        <div className='calendly-button btn btn-aux text-white '>
          <PopupText
             text="Agendar"
             textColor="#ffffff"
             url={"https://calendly.com/"+this.props.mentor.calendly_username}
           />
        </div>
      )
    }else{
      return(null)
    }
  }


  render() {
    let emptyUser = {
      name:'Usuario no encontrado',
      email:'Usuario no encontrado',
      gender:'',
      last_name_father:null,
      last_name_mother:null,
      social_linkedin:null,
    };

    const user   = this.props.mentor !== null ? this.props.mentor : emptyUser;
    return(
      <tr>
        <td style={{width:50, cursor: 'pointer'}}>
          <span className="round roundTransparent">
            <KImage src={user.profileUrlThumbnail} onClick={() => this.handleProfileModal(true)} className={"kuImage"} type={'user_'+user.gender} alt={"user_img"}/>
          </span>
        </td>
        <td>
          {renderFullNameOrEmail(user)}
        </td>
        <td>
          {user.email}
        </td>
        <td>
          {this.renderLinkein(user)}
        </td>
        <td>
          {this.renderCalendly()}
        </td>
        <td>
          {this.renderCVLink(user)}
        </td>
        <td>
          {this.renderInvite()}
        </td>
        <KCompanyModal showModal={this.state.showModal}
                       user={this.props.mentor}
                       programId={this.props.programId}
                       closeModal={this.handleOpenModal}>
        </KCompanyModal>
        <KMentorProfileModal showModal={this.state.showProfileModal}
                             user={this.props.mentor}
                             closeModal={this.handleProfileModal}>
        </KMentorProfileModal>
      </tr>
    )
  }
}

KMentorsCompleteRow.propTypes = {
  /** Mentor to be render */
  mentor               : PropTypes.object,
  programId            : PropTypes.string,
  isMyMentor           : PropTypes.bool
};

KMentorsCompleteRow.defaultProps = {
  mentor               : {},
  programId            : '',
  isMyMentor           : true
};

export default KMentorsCompleteRow;
