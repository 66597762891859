/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, February 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import RichTextEditor from 'react-rte';
import Swal from 'sweetalert2';

import { isUserProfileComplete } from '../utils/Utils.js';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import Footer from '../components/Footer';
import CompanyPicker from '../components/CompanyPicker';
import ProgramOfCompanyPicker from '../components/ProgramOfCompanyPicker';


import FormInput from '../components/form/FormInput';



class ServicesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      subject:'',
      company:null,
      program:null,
      msgIsEmpty:true,
      msg:RichTextEditor.createEmptyValue(),
      sendCopy:true
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.onCompanySelected             = this.onCompanySelected.bind(this);
    this.onClickSend                   = this.onClickSend.bind(this);
    this.onChangeInput                 = this.onChangeInput.bind(this);
    this.onChangeForm                  = this.onChangeForm.bind(this);
    this.onChangeHtml                  = this.onChangeHtml.bind(this);
    this.cleanState                    = this.cleanState.bind(this);
    this.onProgramSelected             = this.onProgramSelected.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true
          });
          //get programs
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  sendEmail(subject,msg,company_id,cc_me){
    KomunidadApi.serviceRequest(subject,msg,company_id,this.state.program._id,cc_me).then((res) => {
      if(res.success){
        this.cleanState();
        Swal.fire({
          type: 'success',
          title: 'Tu solicitud de servicio fue enviada',
          text: 'En breve serás contactado por correo electrónico',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });

      }else{
        Swal.fire({
          type: 'error',
          title: 'Error al enviar tu mensaje',
          text: 'Hubo un error al enviar tu mensaje. Intenta de nuevo más tarde.',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  onCompanySelected(companies){
    let company = companies.company;
    let program = null;

    if(company !== null){
      if(company.hasOwnProperty("programs")){
        if(company.programs.length === 1){
          //auto select the first program if there is only one
          program = company.programs[0].program;
        }
      }
    }
    this.setState({
      company:companies.company,
      program:program
    });
  }
  onProgramSelected(p){
    let program = p.program;
    this.setState({
      program:program
    });
  }
  onClickSend(){
    let msg = this.state.msg.toString('html');
    let subject = this.state.subject;
    let company = this.state.company;
    let sendCopy = this.state.sendCopy ? 'true' : 'false';

    if(company === null){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Selecciona una empresa',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
    if(subject.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El servicio no puede estar vacío',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(!this.state.msgIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El mensaje no puede estar vacío',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }

    return this.sendEmail(subject,msg,company._id,sendCopy);
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  onChangeForm(data){
    this.setState({
      [data.model]:data.value
    });
  }

  onChangeHtml(msg){
    this.setState({msg:msg});

    let msgIsEmpty =  !msg.getEditorState().getCurrentContent().hasText();
    this.setState({
      bodyIsEmpty:msgIsEmpty
    });
  }

  cleanState(){
    this.picker.removeSelections();

    this.setState({
      subject:'',
      company:null,
      msgIsEmpty:true,
      msg:RichTextEditor.createEmptyValue(),
      sendCopy:true
    });
  }
  renderProgram(){
    let programs = [];
    let showPicker = false;
    if(this.state.company !== null){
      if(this.state.company.hasOwnProperty("programs")){
        programs = this.state.company.programs.map( p=> p.program);
        if(this.state.company.programs.length > 1){
          showPicker = true;
        }
      }
    }
    if(showPicker){
      return(
        <ProgramOfCompanyPicker  ref={ref => this.programOfCompanyPicker = ref}
                        programs={programs}
                        onSelect={(company) => {this.onProgramSelected(company)}}
                        companySelected={this.state.company}
                        onProgramSelected={this.state.program}>

        </ProgramOfCompanyPicker>
      )
    }else{
      return(
        null
      )
    }

  }
  renderControls() {
    //possible states:
    // a)No company is selected, return null
    // a)The company is not in a program. Show error
    // b)The selected program does not allow service requests
    //
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };
    let companyHasPrograms = false;
    if (this.state.company !== null) {
      if (this.state.company.hasOwnProperty("programs")) {
        if (this.state.company.programs.length > 0) {
          companyHasPrograms = true;
        }
      }
    }
    if (this.state.company === null) {
      return (null);
    }
    if (!companyHasPrograms) {
      return (
        <div>La empresa seleccionada no tiene programas</div>
      )
    }

    //If the company has programs, but the selected program has finished
    if (this.state.program !== null) {
      var today = Date.now();
      var startDate = new Date(this.state.program.start_date);
      var endDate = new Date(this.state.program.end_date);
      endDate.setDate(endDate.getDate() + 1);
      if ((today >= startDate.getTime()) && (today <= endDate.getTime())) {
        //ok
      } else {
        return (
          <div>El programa {this.state.program.name} no acepta solicitudes porque ya ha finalizado.</div>
        )
      }
    }
    //}

    //If the company has programs, but the selected program does not allow service requests
    if (this.state.program !== null) {
      if (this.state.program.hasOwnProperty("accepts_service_requests")) {
        if (!this.state.program.accepts_service_requests) {
          return (
            <div>El programa {this.state.program.name} no acepta solicitudes de servicio.</div>
          )
        }
      } else {
        //ok
      }
    }
    //}
    if (this.state.program === null) { //If the company is selected but not the program
      return (
        null
      )
    }
    return (
      <div>
        <div className="form-group">
          <label>Servicio</label>
          <FormInput
            id="asunto"
            model="subject"
            value={this.state.subject}
            maxLength={250}
            showCounter={false}
            validationRules="maxLen:250"
            onChange={change => { this.onChangeForm(change) }}
          />
        </div>

        <div className="form-group m-b-0">
          <label>Mensaje</label>
        </div>
        <RichTextEditor
          value={this.state.msg}
          onChange={this.onChangeHtml}
          className="email-editor"
          toolbarConfig={toolbarConfig}
        />

        <label className="m-t-20">
          <input
            type="checkbox"
            id="sendCopy"
            className='filled-in chk-col-light-blue'
            name='sendCopy'
            value={this.state.sendCopy}
            checked={this.state.sendCopy}
            onChange={this.onChangeInput} />
          <label htmlFor="sendCopy" className={this.state.sendCopy ? "" : "text-muted"}>Enviarme copia del correo</label>
        </label>

        <br />

        <button className="btn btn-info btn-save m-t-20 d-inline-flex align-self-center" onClick={() => this.onClickSend()}>
           <i className="mdi mdi-email mr-2"></i>
          Enviar
        </button>
      </div>

    )
  }
  render() {
    if (this.state.authorized) {


      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">

                <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                    <h3 className="text-themecolor">Solicita servicios para tu empresa</h3>
                  </div>
                </div>
                <div className="row view-subtittle">
                  <div className="col-md-9">
                    <p>Dependiendo del programa en el cual estes inscrito este puede tener distintos servicios clave para tu empresa como: diseño de sitio web, registro de marca, asesoría fiscal entre otros. Para más información sobre que servicios tiene el programa en el que estas inscrito revisa el area de recursos, selecciona el programa y verifica la lista de servicios.</p>
                    <p>Para solicitar algún servicio, selecciona tu empresa participante y llena la información requerida.</p>
                  </div>
                </div>
                {/* Start Page Content */}
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="card">
                      <div className='card-body'>
                        <CompanyPicker ref={ref => this.picker = ref}
                          onSelect={(company) => { this.onCompanySelected(company) }}>

                        </CompanyPicker>
                        {this.renderProgram()}
                        {this.renderControls()}
                      </div>
                    </div>
                  </div>
                </div>
                {/* End PAge Content */}
              </div>
              <Footer/>
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }
}
export default ServicesView;
