/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, August 2021
 */

// Regular imports
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import KomunidadApi from '../api/api.js';

// Custom styling
import './../scss/pages/pages.scss';
import './../scss/pages/header.css';
// Images
import {getAppLogo} from "../utils/Vendor.js";


//Components
import KImage from '../components/KImage';

//const
const MySwal = withReactContent(Swal);

const Logo = getAppLogo();

class HeaderFree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallLogo: true,
    };
    this.onClickBtn        = this.onClickBtn.bind(this);
    this.logout            = this.logout.bind(this);
    this.confirmLogout     = this.confirmLogout.bind(this);
    this.updateDimensions  = this.updateDimensions.bind(this);
    this.updateLogo        = this.updateLogo.bind(this);
  }
  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  updateDimensions(){
    var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    this.props.onResize(width);
    this.updateLogo();
  }
  logout(){
    KomunidadApi.logout().then((res) => {
      if(res.success){
        localStorage.removeItem('welcomeModal');
        this.redirectHome();
      }else{
        alert('Hubo un problema al cerrar tu sesión.')
      }
    });
  }

  confirmLogout(){
    MySwal.fire({
      title: '¿Estás seguro(a) de cerrar sesión?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title'
      }
    }).then((result) => {
      if (result.value) {
        this.logout();
      }
    })
  }

  redirectHome(){
    this.props.history.push('/');
  }
  onClickBtn(){
    this.props.onClickBtn();
  }

  updateLogo(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        smallLogo: !this.state.smallLogo,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          smallLogo: true
        })
      } else {
        this.setState({
          smallLogo: false
        })
      }
    }
  }

  renderBtn(){
    if(!this.props.removeBtn){
      return(
        <Button variant="secondary" onClick={this.onClickBtn}>
          {this.props.titleBtn}
        </Button>
      )
    }else{
      return null;
    }
  }
  renderBurger(){
    if(this.props.hideBurguer){
      return(
        <ul className="navbar-nav mr-auto">
        </ul>
      )
    }else{
      return(
        <ul className="navbar-nav mr-auto">
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 nav-toggler hidden-md-up waves-effect waves-dark"><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item" onClick={() => {this.props.onBurgerClicked(); this.updateLogo('byClick');}}> <p className="nav-link m-0 sidebartoggler hidden-sm-down waves-effect waves-dark" ><i className="mdi mdi-menu" style={{fontSize: '20px'}}></i></p> </li>
            <li className="nav-item hidden-sm-down" onClick={() => {this.props.onBurgerClicked(); this.updateLogo();}}></li>
        </ul>
      )
    }
  }

  renderLogo(){
    let user = this.props.user;
    if(user.hasOwnProperty('roles')){
      if(user.roles.includes('manager') || user.roles.includes('facilitator')){
        return(
          <Link className="navbar-brand" to="/manager/programs">
              {/* Logo text */}
              <div className={this.state.smallLogo ? "header-bg-ksq":"header-bg-k"}>
                 {/* dark Logo text */}
                 <div className="dark-logo AppLogoHeader"></div>
                 {/* Light Logo text */}
                 <div className="light-logo AppLogoHeader"></div>
              </div>
          </Link>
          )
      }else{
        return(
          <Link className="navbar-brand" to="/live">
          {/* <Link className="navbar-brand" to="/companies"> */}
              {/* Logo text */}
              <div className={this.state.smallLogo ? "header-bg-ksq":"header-bg-k"}>
                 {/* dark Logo text */}
                 <div className="dark-logo AppLogoHeader"></div>
                 {/* Light Logo text */}
                 <div className="light-logo AppLogoHeader"></div>
              </div>
          </Link>
          )
      }
    }else{
      return(
        <Link className="navbar-brand" to="/companies">
            {/* Logo text */}
            <div className={this.state.smallLogo ? "header-bg-ksq":"header-bg-k"}>
                 {/* dark Logo text */}
                 <div className="dark-logo AppLogoHeader"></div>
                 {/* Light Logo text */}
                 <div className="light-logo AppLogoHeader"></div>
              </div>
        </Link>
        )
    }
  }

  render() {
    return (
      <header className="topbar">
          <nav className="navbar top-navbar navbar-expand-md navbar-light">
              {/* Logo */}
              <div className="navbar-header">
                  {this.renderLogo()}
              </div>
              {/* End Logo */}
              <div className="navbar-collapse">
                  {this.renderBurger()}
                  {/* User profile*/}
                  <ul className="navbar-nav my-lg-0">
                      {/* Profile */}
                      <li className="nav-item dropdown">
                        <div className="d-inline-flex">
                          {/* <Link className="btn btn-link m-0" to={{ pathname: '/join_program?programid=60ff000966793a7cfdce9352'}}>Conoce la plataforma CREO EDOMÉX</Link> */}
                          <Link className="btn btn-info px-2" to={{ pathname: '/login'}}>Inicia sesión</Link>
                        </div>
                      </li>
                  </ul>
              </div>
          </nav>
      </header>
    );
  }
}

HeaderFree.propTypes = {
  titleBtn: PropTypes.string,
  removeBtn:PropTypes.bool,
  onClickBtn:PropTypes.func,
  onBurgerClicked:PropTypes.func,
  onResize:PropTypes.func,
  user: PropTypes.object,
  hideBurguer: PropTypes.bool,
};

HeaderFree.defaultProps = {
  onClickBtn: ()=>{},
  titleBtn: 'Entrar',
  removeBtn:false,
  onBurgerClicked: () => {},
  onResize:(width) => {},
  hideBurguer: false,
  user:{
      _id                     :null,
      name                    :null,
      email                   :null,
      created_at              :null,
      updatedAt               :null,
      source                  :null,
      uid                     :null,
      profileUrlThumbnail     :null,
      goverment_id_curp       :null,
      social_linkedin         :null,
      social_facebook         :null,
      social_twitter          :null,
      social_other            :null,
      goverment_id_rfc        :null,
      dateOfBirth             :null,
      phone                   :null,
      gender                  :null,
      roles                   :null,
  },
};
export default withRouter(HeaderFree);
