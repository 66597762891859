/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano, <israel@startuplab.mx> March 2021
 */

// Regular imports
import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import KomunidadApi from '../api/api.js';
// Custom styling
import '../scss/pages/_style.css';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import Header from '../components/Header';
import ProgramsPicker from '../components/ProgramsPicker';

class SelectProgramView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      me:{},
      program: null,
      joined_programs: null
    }
    this.onChangeProgram  =  this.onChangeProgram.bind(this);
    this.joinProgram      =  this.joinProgram.bind(this);
  }

  componentDidMount(){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        this.setState({
          me: res.user,
          joined_programs: res.user.joined_programs
        });
      } else {
        this.redirectHome();
      }
    });
  }

  onChangeProgram(program) {
    let programId = null;
    if (program !== undefined) {
      programId = program._id;
    } else {
      programId = "";
    }
    this.setState({
      program: programId
    });
  }

  formatPrograms() {
    let programs = this.state.joined_programs;
    let programsIds = programs.map(program => {
      return program.program._id;
    })

    if(!programsIds.includes(this.state.program)) {
      programsIds.push(this.state.program);
    }

    return programsIds.join("|");

  }

  formatLinkPrograms(idProgram, joined_programs) {
    let programs = joined_programs;
    let programsIds = programs.map(program => {
      return program.program._id;
    })

    if(!programsIds.includes(idProgram)) {
      programsIds.push(idProgram);
    }

    return programsIds.join("|");
  }

  joinProgram() {
    let userId = this.state.me._id;
    if(this.state.program !== null) {
      let programsArray = this.formatPrograms()
      KomunidadApi.joinPrograms(userId, programsArray, '').then( (res) => {
        if(res.body.success) {
          Swal.fire({
            title: 'Te has inscrito con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'success',
          }).then(() => {
            this.props.history.push('/create_company')
          });
        } else {
          if(res.status === 403){
            Swal.fire({
              title: 'Inscripciones cerradas',
              text: res.body.error_es,
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
              type: 'error',
            }).then(() => {
              this.redirectHome();
            });
          }else{
            Swal.fire({
              title: 'Ocurrió un error',
              text: "Inténtalo más tarde",
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
              type: 'error',
            }).then(() => {
              this.redirectHome();
            });
          }
        }
      });
    } else {
      Swal.fire({
        title: 'Debes seleccionar al menos un programa',
        text: "Puedes dar clic en omitir si no deseas inscribirte en algún programa",
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
        type: 'error',
      });
    }
  }

  redirectHome(){
    this.props.history.push('/');
  }

  render(){
    return(
      <div className="fix-header single-column card-no-border fix-sidebar height100">
        <div id="main-wrapper" className="height100">
          <Header hideBurguer={true} user={this.state.me}/>
          {/* Page wrapper  */}
          <div className={"page-wrapper color-gray"}>
            {/* Container fluid  */}
            <div className="container-fluid">
              <div className="row page-titles justify-content-md-center">
                <div className="col-md-6 align-self-center">
                  <h3 className="text-themecolor">Selecciona el programa al que deseas inscribirte</h3>
                </div>
              </div>
              {/* Start Page Content */}
              <div className="row justify-content-md-center">
                <div className='col-6'>
                  <div className="card">
                    <div className="card-body">
                    {/*<p>Antes de continuar a Komunidad.io es necesario que ingreses los siguientes datos:</p>*/}
                      <div className='col-sm-12 col-xs-12'>
                        <ProgramsPicker
                          selectedProgram={this.state.program}
                          onChange={(program) => {this.onChangeProgram(program)}}
                          type={'public'}
                        ></ProgramsPicker>
                      </div>
                      <div className="form-group row mb-3">
                        <div className="col-sm-12 col-xs-12">
                          <Link className="float-left pl-3" style={{fontSize: '14px', paddingTop: '5px'}} to={{ pathname: '/companies'}}>
                            Omitir registro
                          </Link>
                          <div className="float-right pr-3">
                            <button className="btn btn-info btn-save btn-block btn-rounded" style={{borderRadius: '.25rem',padding: '5px'}} onClick={this.joinProgram}>Inscribirse</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End PAge Content */}
              </div>
              {/* End Container fluid  */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectProgramView;
