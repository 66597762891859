/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx> February 2021
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';

// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/_mentors-view.scss';
import '../../scss/pages/contact-app-page.css';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KCoursesGrid from '../../components/KCoursesGrid';
import Swal from 'sweetalert2';

import { isUserProfileComplete } from '../../utils/Utils.js';


class CourseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      course_id: null,
      chapter_number: 0,
      chapter_name: '',
      chapter_description: '',
      chapter_link: '',
      chapter_service: '',
      chapters: [],      
      isPartner:false,
      perProgress: 0,
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.getChapters                   = this.getChapters.bind(this)
    this.onSelectChapter               = this.onSelectChapter.bind(this);
    this.completeChapter               = this.completeChapter.bind(this);
    this.chargePercentage              = this.chargePercentage.bind(this);
  }

  componentDidMount(){
    let course_id = this.props.match.params.id
    this.setState({ course_id: course_id })

    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true
          });
          this.getChapters();
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  disbaledChapters(chapters) {
    let lastIndex = chapters.map(chap => chap.done).lastIndexOf(true)

    let chapVals = chapters.map(chap => {
      if(chap.done || (chap === chapters[lastIndex+1])){
        chap['disabled'] = false;
        return chap;
      }
      chap['disabled'] = true;
      return chap;
    })

    return chapVals;

  }

  getChapters() {
    KomunidadApi.getChapters(this.state.course_id).then((res) => {
      if(res.success){
        this.setState({
          /*chapters: this.disbaledChapters(res.chapters),*/
          chapters: res.chapters.sort(function (a, b) { return a.priority - b.priority;}),
          course_name: res.course_name,
          chapter_name: res.chapters[0].name,
          chapter_description: res.chapters[0].description,
          chapter_link: res.chapters[0].url,
          chapter_service: res.chapters[0].service,
          chapter_number: 0,
        })
      }
    }).then(res => {
      this.chargePercentage()
    });
  }

  onSelectChapter(chapter) {
    this.setState({
      chapter_name: chapter.name,
      chapter_description: chapter.description,
      chapter_link: chapter.url,
      chapter_service: chapter.service,
      chapter_number: chapter.priority
    })
  }

  completeChapter(chapter) {
    let i = this.state.chapters.findIndex(c => c._id === chapter._id);
    /*
    if(!this.state.chapters[i].done) {
      if(this.state.chapters[i].priority === 0) {
        KomunidadApi.updateProgress(this.state.course_id,chapter._id).then((res) => {
          if(res.success) {
            this.setState(prevState => {
              let myChapters = prevState.chapters;
              myChapters[i].done = true;
              if(myChapters[i+1] !== undefined) {
                myChapters[i+1].disabled = false;
              }
              return myChapters;
            })
          }
        }).then(res => {
          this.chargePercentage()
        })
      } else {
        let prevChapter = this.state.chapters.findIndex(c => c.priority === this.state.chapters[i].priority-1)
        if(this.state.chapters[prevChapter].done) {
          KomunidadApi.updateProgress(this.state.course_id,chapter._id).then((res) => {
            if(res.success) {
              this.setState(prevState => {
                let myChapters = prevState.chapters;
                myChapters[i].done = true;
                if(myChapters[i+1] !== undefined) {
                  myChapters[i+1].disabled = false;
                }
                return myChapters;
              })
            }
          }).then(res => {
            this.chargePercentage()
          })
        }
      }
    }*/
    KomunidadApi.updateProgress(this.state.course_id,chapter._id).then((res) => {
      if(res.success) {
        this.setState(prevState => {
          let myChapters = Object.assign({}, prevState.chapters);
          myChapters[i].done = true;
          return { myChapters };
        })
        this.setState({
          chapter_number: this.state.chapters[i].priority
        })
      }
    }).then(res => {
      this.changeChapter('next');
      this.chargePercentage();
    }).finally(() => {
      if(this.state.perProgress === 100) {
        Swal.fire({
          title: 'Felicidades, has completado el curso',
          subtitle: 'Puedes verlo de nuevo en cursos completados',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Ir a cursos completados',
          cancelButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'swalBtnTeal',
            cancelButton: 'swalBtnGray',
            title: 'swal-custom-title'
          }
        }).then((result) => {
          if (result.value) {
            // Redirect to completed courses
            this.props.history.push({
              pathname: '/courses',
              search: `?tab=completed_courses`
            })
          }
        })
      }
    })
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
      sidebarVisible:width <= 500 ? false : true
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  renderChapters() {
    let chapters = this.state.chapters;
    if(chapters) {
      return(
          chapters.map((chapter,index) =>
          (
            <li>
              <input
              type="checkbox"
              id={index}
              className="filled-in chk-col-light-blue"
              value={chapter.done}
              onClick={() => this.completeChapter(chapter)}
              checked={chapter.done}
              disabled={chapter.done}
              readOnly/>
              <label htmlFor={index}>
                <label style={{fontWeight: 'normal', fontSize: '14px', cursor: 'pointer'}} onClick={() => this.onSelectChapter(chapter)}>
                  {/*{chapter.priority + 1}.-*/}{chapter.name}
                </label>
              </label>
            </li>
          )
        )
      )
    } else {
      return (null)
    }
  }

  chargePercentage() {
    let chapCount = this.state.chapters.length;
    let chapDone = this.state.chapters.filter(chapDone => chapDone.done === true).length;
    let percentage = Math.ceil(((chapDone/chapCount)*100)/5)*5;

    this.setState({
      perProgress: percentage
    })

  }

  renderProgress(){
    let progress = this.state.perProgress;

    return (
      <div className="my-3">
        <div className="row h-100 justify-content-center align-items-center">
          <div data-label={progress+'%'} className={'css-bar css-bar-lg m-b-0 css-bar-success css-bar-'+progress} style={{zIndex: 0}}></div>
        </div>
      </div>
    )
  }

  renderIframe() {
    if(this.state.chapter_service === 'youtube.com'){
      return(
        <iframe 
          width="100%" 
          height="100%" 
          src={this.state.chapter_link}
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      )
    } else {
      return(
        <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="100%"
          src={this.state.chapter_link}
          frameBorder="0">
        </iframe>
      )
    }
  }

  renderChapterDescription(){
    if(this.state.chapter_description !== ''){
      return(
        <div className="d-flex">
          <div className="align-self-center">
            <p className="card-title m-t-10">{this.state.chapter_description}</p>
          </div>
        </div>
      )
    }else{
      return null
    }
  }

  changeChapter(status) {
    let change = this.state.chapter_number;

    if(status === 'next') {
      if(this.state.chapter_number + 1 < this.state.chapters.length) {
        change = this.state.chapter_number + 1;
      }
    } else {
      if(this.state.chapter_number > 0) {
        change = this.state.chapter_number - 1;
      }
    }

    let chapter = this.state.chapters.filter(chapter => chapter.priority === change);

    this.onSelectChapter(chapter[0]);

  }

  renderContent(){
    return (
      <div className="card">
        <div className="row">
          <div className="col-lg-4 col-md-12 bg-light-part course-info">
            <div className="row">
              <div className="col-lg-12 col-md-3 col-sm-12">
                {this.renderProgress()}
              </div>
              <div className="col-lg-12 col-md-9 col-sm-12">
                <div className="list-style-none mx-3">
                  <div className="box-label">
                    <a>
                      Módulos: {this.state.chapters.length}<br/>
                      <small className="text-muted">
                        Para visualizar cada modulo da click en el título, una vez terminado da check en el cuadro para guardar tu progreso
                      </small>
                    </a>
                  </div>
                  <li className="divider"></li>
                  <ul className="chapters-list overflow-auto p-0">
                    {this.renderChapters()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="right-page-header my-2">
              <div className="row">
                  <div className="col-8">
                    <h4 className="card-title m-t-10">{this.state.chapter_name}</h4>
                  </div>
                  <div className="col-4">
                    <div className="btn btn-info py-1 btn-aux float-right" onClick={() => this.changeChapter('next')}>
                      <i className="mdi mdi-chevron-right" style={{fontSize: '18px'}}></i>
                    </div>
                    <div className="btn btn-info py-1 btn-aux float-right mr-2" onClick={() => this.changeChapter('prev')}>
                      <i className="mdi mdi-chevron-left" style={{fontSize: '18px'}}></i>
                    </div>
                </div>
              </div>
              {this.renderChapterDescription()}
            </div>
            <div className="d-flex align-self-center justify-content-center pr-15" style={{height: '60vh', padding: '0 15px 15px 0'}}>
              {this.renderIframe()}
            </div>
          </div>
        </div>             
      </div>
    );
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header course-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className={this.state.chapters.length <= 4 ? "height100" : null}>
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">

                <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                    <h3 className="text-themecolor">{this.state.course_name}</h3>
                  </div>
                  <div className="col-md-7 align-self-center">
                    <div className="btn btn-info btn-aux float-right" onClick={() => this.props.history.push('/courses')}>
                      Regresar a mis cursos
                    </div>
                  </div>
                </div>
                {/* Start Page Content */}
                {this.renderContent()}
                {/* End PAge Content */}
              </div>
              <Footer/>
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }
}
export default CourseView;
