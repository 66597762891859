/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, November 2019
 */
import React from "react";

import '../../../scss/pages/_style.css';
import '../../../scss/_sidebar.scss';

const profileSteps = [
  {
    selector: '[data-tut="welcome"]',
    content: `Hola, este asistente te describirá como puedes interacturar con Komunidad.io.`
  },
  {
    selector: '[data-tut="edit-image"]',
    content: `Aquí puedes editar tu imagen de perfil.`
  },
  {
    selector: '[data-tut="edit-profile"]',
    content: `Con este boton puedes editar tu información.`
  },
  {
    selector: '[data-tut="add-project"]',
    content: () => (
      <div>
        <p>Esta barra te llevará al registro de startup.</p>
        <p>También puedes ver las startups que tienes registradas, así como los aliados disponibles.</p>
        <p>Para más información puedes revisar la documentación de Komunidad.io dando clic
          <a href="https://komunidad.freshdesk.com/support/home" rel="noopener noreferrer" target="_blank"><button type="button" className="btn btn-success btn-save m-l-5">Aquí</button></a>
        </p>
      </div>
    ),
  },
];

export default profileSteps;