/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx> && Juan Alberto Hernandez, September 2021
 */

//Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Images and css
import './PasswordView.css';
import Logo from "../../assets/images/komunidad.svg";

const MySwal = withReactContent(Swal);

class ResendPasswordEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preuser_email: '',
      preuser_name: '',
    };
  }

  componentDidMount() {
    var email = this.props.match.params.email;
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let preuser_name = params.get('name');

    this.setState({
      preuser_name: preuser_name,
      preuser_email: email,
    })
  }

  resendPrc() {
    KomunidadApi.userPreRegister(this.state.preuser_email).then(res => {
      if (res.body.success) {
        this.swalSuccess(this.state.preuser_email);
      } else {
        this.swalError(this.state.preuser_email);
      }
    }).catch(err => { });
  }

  swalSuccess(email) {
    Swal.fire({
      title: 'Reenvío exitoso',
      html: `Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    })
  }

  swalError(email) {
    Swal.fire({
      title: 'Felicidades ya tienes cuenta en Komunidad',
      html: `El correo <b>${email}</b> ya se encuentra registrado, serás redireccionado al login para iniciar sesión`,
      type: 'success',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then(() => {
      this.props.history.push('/');
    })
  }

  render() {
    return (
      <div className="landingView" >
        <div className="page-wrapper" style={{ minHeight: '584px', padding: 0 }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ margin: 0 }}>
                  <div className="card-body">
                    <div className="pv_content form-horizontal form-horizontal">
                      <p className="text-center">
                        <i className="mdi mdi-mailbox" style={{ fontSize: '50px' }}></i>
                      </p>
                      <h2 className="pv_subtitle">Verifica tu correo</h2>
                      <p className={'text-center m-t-20'} style={{ fontSize: '18px' }}>
                        Hola <b>{this.state.preuser_name}</b>, hemos enviado un correo a <b>{this.state.preuser_email}.</b><br />
                        Si deseas recibir un nuevo correo de verificación da clic en el botón
                      </p>
                      <p className={'text-center m-t-10 m-b-20'} style={{ fontSize: '18px' }}>
                      </p>
                      <div className="form-group text-center row justify-content-center">
                        <div className="col-xs-6">
                          <div className={'text-center m-b-20'} style={{ color: '#ef5350' }}>{this.state.errorMsg}</div>
                          <button className="btn btn-info btn-lg btn-block text-uppercase btn-rounded komunidad-color" onClick={() => this.resendPrc()}>Reenviar Correo</button>
                        </div>
                      </div>
                      <img className="komunidad-logo" src={Logo} alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResendPasswordEmail;
