/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, October 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import KomunidadApi from './../../api/api.js';

//Components
import KReviewList from './../KReviewList';
import KModalReviewList from './../KModalReviewList';

/**
 *  Display and edit the company information (Review content).
 */

class KTabReviewContent extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      reviews:[],
      showModal:false,
      requestStatusReviewList:'idle',
    }
    this.getReviews        = this.getReviews.bind(this);
    this.handleCloseModal  = this.handleCloseModal.bind(this);
  }

  componentDidMount(){
    this.getReviews();
  }

  getReviews(){
    KomunidadApi.getReviews(this.props.company._id).then((res) => {
      if(res.success){
        this.setState({reviews:res.reviews,requestStatusReviewList:'success'});
      }else{
        this.setState({requestStatusReviewList:'failed'});
      }
    });
  }

  handleCloseModal(value){
    this.setState({showModal:value});
  }

  renderShowReviewBtn(reviews){
    if(reviews.length >= 8){
        return(
          <div className="text-center m-b-30 ">
            <div className="btn waves-effect waves-light btn-outline-secondary"
                 onClick={() => this.handleCloseModal(true)}>Ver todo ({reviews.length})</div>
          </div>
        )
    }else{
      return null;
    }
  }

  render(){
    const reviews = this.state.reviews;
    return(
      <div className="card-body">
        <div className="activity-box">
          <KReviewList
              reviews={reviews.slice(0,8)}
              requestStatus={this.state.requestStatusReviewList}
              subtitleEmptyList={''}/>

          {this.renderShowReviewBtn(reviews)}
        </div>
        <KModalReviewList showModal={this.state.showModal}
                          reviews={this.state.reviews}
                          requestStatus={this.state.requestStatusReviewList}
                          closeModal={this.handleCloseModal}>
        </KModalReviewList>
      </div>
    )
  }
}

KTabReviewContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
};

KTabReviewContent.defaultProps = {
  company               : {},
};

export default KTabReviewContent;
