/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>,
 * November 2021
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import KomunidadApi from '../api/api.js';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';
import "react-tageditor/dist/style/default.css";

import FormInput from './form/FormInput';
import ProgramsPicker from './ProgramsPicker';
import TagEditor from 'react-tageditor';
import Swal from 'sweetalert2';

var _= require('lodash');

/**
*  Send emails to partners, mentors, companies or all participants of a program.
*
*
*/
class KSendContactEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject:'',
      toList:[],
      emailContent:RichTextEditor.createEmptyValue(),
      bodyIsEmpty:true,
      selectedProgram: '',
      sendCopy:true,
      showButtons: null
    }
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
    this.onChangeForm                =  this.onChangeForm.bind(this);
    this.onChangeHtml                =  this.onChangeHtml.bind(this);
    this.onChangeProgram             =  this.onChangeProgram.bind(this);
    this.onClickSendEmail            =  this.onClickSendEmail.bind(this);
    this.cleanStates                 =  this.cleanStates.bind(this);
    this.onChangeInput               =  this.onChangeInput.bind(this);
  }

  onChangeHtml(emailContent){
    this.setState({emailContent});
    if (this.props.onChange) {
      this.props.onChange(
        emailContent.toString('html')
      );
    }
    let bodyIsEmpty =  !emailContent.getEditorState().getCurrentContent().hasText();
    this.setState({
      bodyIsEmpty:bodyIsEmpty
    });
  }

  onChangeForm(data){
    this.setState({
      [data.model]:data.value
    });
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    //Clean the states
    this.cleanStates();
    //Close modal from the parent
    this.props.closeModal(false);
  }

  cleanStates(){
    this.setState({
      subject:'',
      toList:[],
      emailContent:RichTextEditor.createEmptyValue(),
      bodyIsEmpty:true,
      sendCopy:true,
      showButtons: false
    });
  }

  onClickSendEmail(){
    let body = this.state.emailContent.toString('html');
    let subject = this.state.subject;
    let program_id = this.state.selectedProgram._id;
    let sendCopy = this.state.sendCopy ? 'true' : 'false';

    if(subject.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El asunto no puede estar vacío',
        footer: '',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.bodyIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'El mensaje no puede estar vacío',
        footer: '',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    KomunidadApi.sendContactEmail(body,subject,program_id,sendCopy).then(res => {
      if(res.success){
        Swal.fire({
          type: 'success',
          title: 'Mensaje enviado',
          showConfirmButton: false,
          timer: 1500
        });
        this.hideModal();
      }else{
        Swal.fire({
          type: 'error',
          title: 'Error al enviar tu mensaje',
          text: 'Hubo un error al enviar tu mensaje. Intenta de nuevo mas tarde.',
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  onChangeProgram(program) {
		if (program !== undefined) {
      this.setState({ 
        'selectedProgram': program,
        'showButtons': true
      })
		} else {
			this.setState({ 
        'selectedProgram': '',
        'showButtons': false
      })
		}
	}

  renderActionButtons() {
    if (this.state.showButtons) {
      return(
        <div>
          <button className="btn btn-info m-t-20 d-inline-flex align-items-center" onClick={() => this.onClickSendEmail()}>
            <i className="mdi mdi-email m-r-10" style={{fontSize: '18px', color:'#fffff'}}></i>
            Enviar
          </button>
          <button className="btn btn-danger m-t-20 d-inline-flex align-items-center" onClick={() => this.handleCloseModal(false)}>
            <i className="mdi mdi-close m-r-10" style={{fontSize: '18px', color: '#fffff'}}></i>
            Cancelar
          </button>
        </div>
      )
    } else {
      return null;
    }
  }

  renderContent(){
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ],
      BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };

    return (
      <div className="card-body">
        <div className="form-group">
          <h4>Para director del programa:</h4>
          <ProgramsPicker
            selectedProgram={this.state.selectedProgram}
            onChange={(program) => {this.onChangeProgram(program)}}
            showLabel={false}
            type={'mine'}
            showWithFacilitator={true}
          ></ProgramsPicker>
        </div>
        <div className="form-group">
          <h4>Asunto</h4>
          <FormInput
            id="asunto"
            model="subject"
            value={this.state.subject}
            maxLength={250}
            showCounter={false}
            validationRules="maxLen:250"
            onChange={ change => {this.onChangeForm(change) } }
            />
        </div>
        <div className="form-group">
        </div>
        <h4><i className="ti-link" /> Mensaje </h4>
        <div style={{minHeight:"200px"}}>
          <RichTextEditor
            value={this.state.emailContent}
            onChange={this.onChangeHtml}
            className="email-size"
            toolbarConfig={toolbarConfig}
          />
        </div>

        <label className="m-t-20">
         <input
           type="checkbox"
           id="sendCopy"
           className='filled-in chk-col-light-blue'
           name='sendCopy'
           value={this.state.sendCopy}
           checked={this.state.sendCopy}
           onChange={this.onChangeInput}/>
         <label htmlFor="sendCopy" className={this.state.sendCopy ? "": "text-muted"}>Enviarme copia del correo</label>
        </label>

        <br/>

        {this.renderActionButtons()}
      </div>
    )
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Envia tu mensaje
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
      </Modal>
    );
  }
}

KSendContactEmail.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,

  /** When true the modal will show itself. */
  showModal        : PropTypes.bool
};

KSendContactEmail.defaultProps = {
  closeModal       : () => {},
  showModal        : false
};

export default KSendContactEmail;
