/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, November 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal,Button} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import BounceLoader from 'react-spinners/BounceLoader';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';
// users:[{email:'asd@asd.com'},{email:'asd2@asd.com'},{email:'asd3@asd.com'}],
// invalidUsers:[{email:'asd'},{email:'asd.com'}],
import ProgramsPicker from './ProgramsPicker';

class KCSVUploaderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      stepState: 'uploading', //posible values uploading,loading,response
      users:[],
      invalidUsers:[],
      selectedPrograms:[],
      file:'',
      isMentor:false,
      isPartner:false,
      isDisabled:true,
      showModal:false,
      results:[]
    }
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleModal                 =  this.handleModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
    this.uploadFile                  =  this.uploadFile.bind(this);
    this.onChangeFile                =  this.onChangeFile.bind(this);
    this.validateFormByFile          =  this.validateFormByFile.bind(this);
    this.onChangeCheckbox            =  this.onChangeCheckbox.bind(this);
    this.sendInvitations             =  this.sendInvitations.bind(this);
  }


  uploadFile(){
    if(this.state.file === ''){
      Swal.fire({
        title: 'Error',
        text: 'Debes de seleccionar un archivo',
        type: 'error',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {
      });
      return false;
    }
    if(!this.state.isMentor && !this.state.isPartner){
      Swal.fire({
        title: 'Error',
        html: 'Debes de seleccionar al menos un rol',
        type: 'warning',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {

      })
      return false;
    }

    let isValid = this.validateFormByFile();
    if(isValid){
      this.handleModal(true);
      KomunidadApi.validateCSVFile(this.state.file).then((res) => {
        if(res.hasOwnProperty('users')){
          let users = res.users.filter(user =>  user.valid);
          let iUsers = res.users.filter(user => !user.valid);

          this.setState({stepState:'loading',users:users,invalidUsers:iUsers});
        }else{
          Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al procesar el archivo.',
            type: 'error',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.hideModal();
          });
        }
      });
    }else{
      Swal.fire({
        title: 'Error',
        text: 'El archivo no es válido',
        type: 'error',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {
        this.hideModal();
      });
    }
  }

  handleModal(show){
    this.setState({showModal:show});
  }

  hideModal(){
    this.handleModal(false);
  }

  executeAfterModalClose(){
    this.hideModal();
    this.setState({
      stepState: 'uploading',
      users:[],
      invalidUsers:[],
      results:[]
    })
  }

  onChangeFile(data) {
    let file = data.target.files[0];
    this.setState({file:file}, () => this.enableForm());
  }

  onChangeCheckbox(data) {
    this.setState({[data.target.name]:data.target.checked}, () => this.enableForm());
  }

  sendInvitations(){
    this.setState({stepState:'uploading'});

    let users = {users: this.state.users}
    let roles = [];

    //Check if the users are invited as mentor or partners or both.
    if(this.state.isMentor){
      roles.push("mentor");
    }
    if(this.state.isPartner){
      roles.push("partner");
    }
    roles = roles.join("|");

    let programs_ids = this.state.selectedPrograms.join("|");
    KomunidadApi.inviteUsersMassive(JSON.stringify(users), roles, programs_ids).then((res) => {
      if(res.success){
        this.setState({
          results:res.results,
          isMentor: false,
          isPartner: false,
          stepState:'response'
        });
        this.fi_programs.cancel();
      }else{
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al enviar las invitaciones',
          type: 'error',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  enableForm(){
    let _v = this.validateFormByFile();
    if(_v){
      if((this.state.isMentor) || (this.state.isPartner)){
        this.setState({isDisabled:false});
      }else{
        this.setState({isDisabled:true});
      }
    }else{
      this.setState({isDisabled:true});
    }
  }

  validateFormByFile(){
    let _validate = this.state.file !== '' ? true : false;
    return _validate;
  }

  onChangeProgram(programs){
    this.setState({selectedPrograms:programs});
  }

  renderLoader(){
    return(
      <center>
        <div className='m-b-20'>Estamos procesando el archivo...</div>
        <BounceLoader
           sizeUnit={"px"}
           size={150}
           color={'#3cc'}
           loading={this.props.loading}
        />
      </center>
    )
  }

  renderTable(){
    const users = this.state.users;
    const columns = [
      {
        dataField: 'email',
        text: 'Email',
      },
    ];
    if(this.state.users.length === 0){
      return(
        <div className="card-body">
          <center>
            No se encontraron correos válidos en el archivo.<br></br>
          </center>
          <br></br>
          <br></br>
          {this.renderInvalidUsers()}
          <div className='float-right'>
            <Button className='btn btn-danger' onClick={() => this.handleModal(false)}>Finalizar</Button>
          </div>
        </div>
      )
    }else{
      return(
        <div className="card-body">
          <center>
            Se encontrarón <b>{this.state.users.length}</b> correos válidos en el archivo.<br></br>
            Da clic en 'Invitar usuarios' para confirmar.
          </center>
          <BootstrapTable
            striped
            bordered={ false }
            keyField='email'
            data={ users }
            columns={ columns }
            wrapperClasses="table-responsive"
            classes="react-bootstrap-table"
          />
          {this.renderInvalidUsers()}
          <div className='float-right'>
            <Button className='btn btn-danger' onClick={() => this.handleModal(false)}>Cancelar</Button>
            <Button className='btn btn-save' onClick={() => this.sendInvitations()}>Invitar usuarios</Button>
          </div>
        </div>
      )
    }
  }

  renderInvalidUsers(){
    const invalidUsers = this.state.invalidUsers;

    if(invalidUsers.length > 0){
      const invalids = invalidUsers.map(user => <li><b>{user.email}</b></li>);

      return(
        <div className='m-t-10 m-b-20'>
          Los siguientes correos no están en el formato correcto:
          <ul>{invalids}</ul>
        </div>
      )
    }else{
      return null
    }
  }

  renderResponse(){
    const results = this.state.results;
    const columns = [
      {
        dataField: 'email',
        text: 'Email',
      },
      {
        dataField: 'status',
        text: 'Estado',
        formatter: (cellContent) => {
          const status = {
            user_exists:'Usuario ya registrado',
            invitation_updated:'Invitación enviada',
            errored:'Error al registrar',
            invited:'Invitación enviada'
          }
          const className={
            user_exists:'text-danger',
            invitation_updated:'text-success',
            errored:'text-danger',
            invited:'text-success'
          }

          return <span className={className[cellContent]}>{status[cellContent]}</span>
        }
      },

    ];

    return(
      <div className="card-body">
        <BootstrapTable
          striped
          bordered={ false }
          keyField='email'
          data={ results }
          columns={ columns }
          wrapperClasses="table-responsive"
          classes="react-bootstrap-table"
        />

        <Button variant='secondary' className='float-right' onClick={() => this.handleModal(false)}>Finalizar</Button>
      </div>
    )
  }

  renderModalContent(){
    switch (this.state.stepState) {
      case 'uploading':
        return this.renderLoader();
      case 'loading':
        return this.renderTable();
      case 'response':
        return this.renderResponse();
      default:
        return (null)
    }
  }

  renderModal(){
    return (
      <Modal  show={this.state.showModal}
              onHide={() => this.handleModal(false)}
              onExit={this.executeAfterModalClose}
              >
        <Modal.Header closeButton>
          <Modal.Title>
            Invitar usuarios 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderModalContent()}
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div className="m-t-20">
        <div className="row">
         <div className="col-md-12">
          <h3 className='m-b-20'>Por Excel / CSV</h3>
           <div className='form-group'>
             <div className='text-muted m-b-20'>El archivo debe contener email</div>
             <input
               type={'file'}
               onChange={ this.onChangeFile }
               accept=".xlsx, .xls, .csv" />
             <label className="m-t-20">
               <input
                 type="checkbox"
                 id="isPartner"
                 className="filled-in chk-col-light-blue"
                 name='isPartner'
                 value={this.state.isPartner}
                 checked={this.state.isPartner}
                 onChange={this.onChangeCheckbox}/>
               <label htmlFor="isPartner" className={this.state.isPartner ? "": "text-muted"}>Emprendedor</label>
             </label>

             <label>
              <input
                type="checkbox"
                id="isMentor"
                className="filled-in chk-col-light-blue"
                name='isMentor'
                value={this.state.isMentor}
                checked={this.state.isMentor}
                onChange={this.onChangeCheckbox}/>
              <label htmlFor="isMentor" className={this.state.isMentor ? "": "text-muted"}>Aliado</label>
            </label>
           </div>
           <div className='form-group' style={{paddingTop: "8px"}}>
              <ProgramsPicker
                ref={ref => this.fi_programs = ref}
                onChange={(program) => {this.onChangeProgram(program)}}
                showInactivePrograms={true}
                isMultiple={true}
              ></ProgramsPicker>
           </div>
           <Button className='btn btn-save' onClick={this.uploadFile}>Subir archivo</Button>
         </div>

         {/* <div className="col-md-6 m-t-40">
         </div>
         <div className="col-md-6">
         </div> */}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

KCSVUploaderModal.propTypes = {
  closeModal       : PropTypes.func,
  showModal        : PropTypes.bool,
};

KCSVUploaderModal.defaultProps = {
  closeModal      : () => {},
  showModal       : false,
  file            : null
};

export default KCSVUploaderModal;
