/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, Frebruary 2021
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';

//components
import KCoursesGridRow from './KCoursesGridRow';


/**
*  Show a list of courses as a grid.
*  Render the mentor using the component [KCoursesGridRow](/#kcoursesGridRow)
*/

class KCoursesGrid extends React.Component {

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al mostrar los cursos</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderEmptyList(){
    if(this.props.programId === 'public'){
      return(
        <div className="text-center">
          <h3>Aún no hay cursos gratis registrados</h3>
          <i className="mdi mdi-school m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        </div>
      )
    } else {
      return(
        <div className="text-center">
          <h3>Aún no hay cursos registrados en el programa</h3>
          <i className="mdi mdi-school m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        </div>
      )
    } 
  }

  render() {
    const courses = this.props.courses;
    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(courses.length > 0){
        return(
          <div className='row'>
              {courses.map((item,index) =>
                (
                  <KCoursesGridRow key={index}
                                   course={item}
                                   freeMode={this.props.freeMode}
                                   programId={this.props.programId}
                                   onClickCourse={(course) => this.props.onClickCourse(course)}
                                   isMyCourse={this.props.isMyCourse}
                  />
                )
              )}
          </div>
        )
      }else{
        return this.renderEmptyList()
      }
    }else{
      return this.renderFailed()
    }
  }
}

KCoursesGrid.propTypes = {
  /** List of courses */
  courses             : PropTypes.array,
  /** Callback when the course is clicked */
  onClickCourse       : PropTypes.func,
  /** Status of the loading data: idle, fetching, success and failed*/
  requestStatus       : PropTypes.string, //idle,fetching,success,failed
  programId           : PropTypes.string,
  isMyCourse          : PropTypes.bool,
  freeMode            : PropTypes.bool,
};

KCoursesGrid.defaultProps = {
  courses             : [],
  onClickCourse       : ()=>{},
  requestStatus       : 'idle',
  programId           : '',
  isMyCourse          : true,
  freeMode            : false,
};

export default KCoursesGrid;
