/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, June 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/** Pick one or multiple sectors.
*   Returns an array of strings of the selected sectors
*/

class KSectorsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors:[
        {
          value:'all',
          label:'Todos',
        },
        {
          value:'Aeroespacial',
          label:'Aeroespacial'
        },
        {
          value:'Aeronáutico',
          label:'Aeronáutico'
        },
        {
          value:'Agroindustrial',
          label:'Agroindustrial'
        },
        {
          value:'Alimentos',
          label:'Alimentos'
        },
        {
          value:'Artesanías',
          label:'Artesanías',
        },
        {
          value:'Automotriz',
          label:'Automotriz',
        },
        {
          value: 'Biofarmaceutica',
          label: 'Biofarmaceutica'
        },
        {
          value: 'Desarrollo Web',
          label: 'Desarrollo Web'
        },
        {
          value: 'Diseño y Moda',
          label: 'Diseño y Moda'
        },
        {
          value: 'Dispositivos y Componentes',
          label: 'Dispositivos y Componentes'
        },
        {
          value: 'Educación',
          label: 'Educación'
        },
        {
          value: 'Electrodomésticos',
          label: 'Electrodomésticos'
        },
        {
          value: 'Electrónico',
          label: 'Electrónico'
        },
        {
          value: 'Energético',
          label: 'Energético'
        },
        {
          value: 'Energías Limpias',
          label: 'Energías Limpias'
        },
        {
          value: 'Energías Renovables',
          label: 'Energías Renovables'
        },
        {
          value: 'Equipo Médico',
          label: 'Equipo Médico'
        },
        {
          value: 'Fintech',
          label: 'Fintech'
        },
        {
          value: 'Gobierno',
          label: 'Gobierno'
        },
        {
          value: 'Industria Agroalimentaria',
          label: 'Industria Agroalimentaria'
        },
        {
          value: 'Industria del Reciclaje',
          label: 'Industria del Reciclaje'
        },
        {
          value: 'Industria Textil',
          label: 'Industria Textil'
        },
        {
          value: 'Internet de las Cosas',
          label: 'Internet de las Cosas'
        },
        {
          value: 'Logística',
          label: 'Logística'
        },
        {
          value: 'Maquinaria y Equipo',
          label: 'Maquinaria y Equipo'
        },
        {
          value: 'Materiales para la Construcción',
          label: 'Materiales para la Construcción'
        },
        {
          value: 'Metalmecánica',
          label: 'Metalmecánica'
        },
        {
          value: 'Minería',
          label: 'Minería'
        },
        {
          value: 'Nanotecnología',
          label: 'Nanotecnología'
        },
        {
          value: 'Plásticos',
          label: 'Plásticos'
        },
        {
          value: 'Petroquímica',
          label: 'Petroquímica'
        },
        {
          value: 'Productos Agropecuarios y Acuícolas',
          label: 'Productos Agropecuarios y Acuícolas'
        },
        {
          value: 'Productos Químicos',
          label: 'Productos Químicos'
        },
        {
          value: 'Servicios de Investigación y Desarrollo',
          label: 'Servicios de Investigación y Desarrollo'
        },
        {
          value: 'Servicios Medicos',
          label: 'Servicios Medicos'
        },
        {
          value: 'Tecnologías de la Información',
          label: 'Tecnologías de la Información'
        },
        {
          value: 'Turismo',
          label: 'Turismo'
        },
        {
          value: 'Turismo Médico',
          label: 'Turismo Médico'
        },
      ],
      selectedSectors:[]
    }
    this.sectors = [
        {
          value:'all',
          label:'Todos',
        },
        {
          value:'Aeroespacial',
          label:'Aeroespacial'
        },
        {
          value:'Aeronáutico',
          label:'Aeronáutico'
        },
        {
          value:'Agroindustrial',
          label:'Agroindustrial'
        },
        {
          value:'Alimentos',
          label:'Alimentos'
        },
        {
          value:'Artesanías',
          label:'Artesanías',
        },
        {
          value:'Automotriz',
          label:'Automotriz',
        },
        {
          value: 'Biofarmaceutica',
          label: 'Biofarmaceutica'
        },
        {
          value: 'Desarrollo Web',
          label: 'Desarrollo Web'
        },
        {
          value: 'Diseño y Moda',
          label: 'Diseño y Moda'
        },
        {
          value: 'Dispositivos y Componentes',
          label: 'Dispositivos y Componentes'
        },
        {
          value: 'Educación',
          label: 'Educación'
        },
        {
          value: 'Electrodomésticos',
          label: 'Electrodomésticos'
        },
        {
          value: 'Electrónico',
          label: 'Electrónico'
        },
        {
          value: 'Energético',
          label: 'Energético'
        },
        {
          value: 'Energías Limpias',
          label: 'Energías Limpias'
        },
        {
          value: 'Energías Renovables',
          label: 'Energías Renovables'
        },
        {
          value: 'Equipo Médico',
          label: 'Equipo Médico'
        },
        {
          value: 'Fintech',
          label: 'Fintech'
        },
        {
          value: 'Gobierno',
          label: 'Gobierno'
        },
        {
          value: 'Industria Agroalimentaria',
          label: 'Industria Agroalimentaria'
        },
        {
          value: 'Industria del Reciclaje',
          label: 'Industria del Reciclaje'
        },
        {
          value: 'Industria Textil',
          label: 'Industria Textil'
        },
        {
          value: 'Internet de las Cosas',
          label: 'Internet de las Cosas'
        },
        {
          value: 'Logística',
          label: 'Logística'
        },
        {
          value: 'Maquinaria y Equipo',
          label: 'Maquinaria y Equipo'
        },
        {
          value: 'Materiales para la ConstrucciónMateriales para la Construcción',
          label: 'Materiales para la ConstrucciónMateriales para la Construcción'
        },
        {
          value: 'Metalmecánica',
          label: 'Metalmecánica'
        },
        {
          value: 'Minería',
          label: 'Minería'
        },
        {
          value: 'Nanotecnología',
          label: 'Nanotecnología'
        },
        {
          value: 'Plásticos',
          label: 'Plásticos'
        },
        {
          value: 'Petroquímica',
          label: 'Petroquímica'
        },
        {
          value: 'Productos Agropecuarios y Acuícolas',
          label: 'Productos Agropecuarios y Acuícolas'
        },
        {
          value: 'Productos Químicos',
          label: 'Productos Químicos'
        },
        {
          value: 'Servicios de Investigación y Desarrollo',
          label: 'Servicios de Investigación y Desarrollo'
        },
        {
          value: 'Servicios Medicos',
          label: 'Servicios Medicos'
        },
        {
          value: 'Tecnologías de la Información',
          label: 'Tecnologías de la Información'
        },
        {
          value: 'Turismo',
          label: 'Turismo'
        },
        {
          value: 'Turismo Médico',
          label: 'Turismo Médico'
        },
        {
          value:'all',
          label:'Todos',
        }];
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
    this.cancel                 = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(props.selectedSectors !== ""){
      let selectedSectorsArray = props.selectedSectors.slice("|");
      let newSelectedSectors = state.sectors.filter( p => selectedSectorsArray.includes(p.value));
      return {
        selectedSectors: newSelectedSectors
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount(){
  }
  cancel(){
    this.setState({
      selectedSectors: [],
      sectors: this.sectors,
    })
  }
  handleMultipleSelect(selectedOptions){
    let sectors = this.sectors.slice();
    if(selectedOptions !== null){
      if(selectedOptions.length > 0){
        sectors = sectors.filter((p) => p.value !== 'all');
        if(selectedOptions[0].value === "all"){
          sectors = sectors.map((p) => {p.isDisabled = true; return p});
        }
      }
    }else{
      sectors = sectors.map((p) => {p.isDisabled = false; return p});
    }
    this.setState({selectedSectors:selectedOptions,sectors:sectors});
    this.props.onChange(selectedOptions);
  }
  renderSelect(){
      return(
        <Select
          options={this.state.sectors}
          value={this.state.selectedSectors}
          onChange={this.handleMultipleSelect}
          isMulti={true}
          placeholder={"Seleccionar"}
        />
      )
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>Sector</label>
      )
    }else{
      return null;
    }
  }

  render(){
    return(
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

KSectorsPicker.propTypes = {
  /** Callback on program selected, returns the id or an array of ids. */
  onChange               : PropTypes.func,
  /** When is true show the label 'Programas'. */
  showLabel              : PropTypes.bool,
  /** selected sectors */
  selectedSectors        : PropTypes.string,
}
KSectorsPicker.defaultProps = {
  onChange                : (sectors) => {},
  showLabel               : true,
  selectedSectors         : "",
}

export default KSectorsPicker;
