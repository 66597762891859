/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, March 2020
 */
var EventBriteAPI = {
  API_URL: "https://www.eventbriteapi.com/v3",
  /*
  * Get user's oranizations
  */
  getOrganizations(token) {
    let params = {
    };
    let formBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return fetch(`${EventBriteAPI.API_URL}/users/me/organizations/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return error;
    });
  },
  /*
  * Get organization's events
  */
  getEvents(organization_id,token,continuation) {
    var url = new URL(`${EventBriteAPI.API_URL}/organizations/${organization_id}/events/`);
    if(continuation !== null){
      url.searchParams.append("continuation",continuation);
    }
    return fetch(url.toString(), {
      method: 'GET',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return error;
    });
  },
  /*
  * Get organization's events
  */
  getAttendees(event_id,token,continuation) {
    var url = new URL(`${EventBriteAPI.API_URL}/events/${event_id}/attendees/`);
    if(continuation !== null){
      url.searchParams.append("continuation",continuation);
    }
    return fetch(url.toString(), {
      method: 'GET',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      return error;
    });
  }
}
module.exports = EventBriteAPI;
