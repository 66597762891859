import React from 'react';
import KomunidadApi from '../api/api.js';
import PropTypes from 'prop-types';
import Select from 'react-select';

/** Pick one or multiple programs.
*   Returns an array of ids or the id of the selected program(s).
*/


class ProgramsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programs:[],
      selectedProgram:'',
      multipleOptions:[],
      multipleProgramSelected:[]
    }
    this.onChange               = this.onChange.bind(this);
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
    this.cancel                 = this.cancel.bind(this);
    this.getPrograms            = this.getPrograms.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.multipleProgramSelected !== null){
      if ((state.multipleProgramSelected.length === 0) && (props.multipleProgramSelected !== state.multipleProgramSelected)){
        let _selected = [];
        if(state.multipleOptions.length > 0){

          //Filter the multiple option array to set the selected programs
          _selected = state.multipleOptions.filter(function(option){
            return props.multipleProgramSelected.indexOf(option.value) !== -1;
          });

          // console.log(_selected);
        }
        return {
          multipleProgramSelected: _selected
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount(){
    if(this.props.type === 'mine'){
      this.getMyPrograms();
    }else if(this.props.type === 'public'){
      this.getPublicPrograms();
    }else{
      this.getAllPrograms();
    }
  }

  getAllPrograms(){
    KomunidadApi.findPrograms().then( (res) => {
      if(res.success){
        this.setState({
          programs:res.programs,
          selectedProgram:this.props.selectedProgram
        });
        this.setMultipleOptions(res.programs);
      }
    });
  }

  getMyPrograms(){
    KomunidadApi.getMyPrograms().then( (res) => {
      if(res.success){
        this.setState({
          programs:res.programs,
          selectedProgram:this.props.selectedProgram
        });
        this.setMultipleOptions(res.programs);
      }
    });
  }
  statusHelper(program){
    let today = Date.now();
    let startDate = new Date(program.start_date);
    let endDate = new Date(program.end_date);
    endDate.setDate(endDate.getDate() + 1);
    let status = ((today >= startDate.getTime()) && (today <= endDate.getTime())) ?  "Activo" :  "Inactivo";
    return status;
  }
  getPublicPrograms(){
    KomunidadApi.getPublicPrograms().then( (res) => {
      if(res.success){
        this.setState({
          programs: res.programs.map((p) => {
            p.status = this.statusHelper(p);
            return p;
          }).filter((p) =>{
            return p.status === 'Activo' // Filter active programs only
          }),
          selectedProgram:this.props.selectedProgram
        });
        this.setMultipleOptions(res.programs);
      }
    });
  }
  cancel(){
    this.setState({
      selectedProgram: '',
      multipleProgramSelected: [],
    })
  }

  setMultipleOptions(programs){
    let p = this.getPrograms(programs);

    if(p.length > 0 && this.props.freeMode){
      this.setState({selectedProgram:programs[0]._id});
      this.props.onChange(programs[0]);
    }

    let options = p.map(program => ({ value: program._id, label: program.name }));
    this.setState({multipleOptions:options});
  }
  getPrograms(programs){
    if(this.props.showInactivePrograms){
      return programs;
    }
    var today = Date.now();
    programs = programs.filter(function (program) {
      var startDate = new Date(program.start_date);
      var endDate = new Date(program.end_date);
      endDate.setDate(endDate.getDate() + 1);
      return ((today >= startDate.getTime()) && (today <= endDate.getTime()));
    });
    if(this.props.showWithFacilitator) {
      programs = programs.filter(program => program.has_facilitator)
      return programs;
    }
    return programs;
  }
  onChange(p){
    let selectedProgramId = p.target.value;
    let program = this.state.programs.filter(p => p._id.toString() === selectedProgramId);
    this.setState({selectedProgram:program._id});
    this.props.onChange(program[0]);
  }

  handleMultipleSelect(selectedOption){
    this.setState({multipleProgramSelected:selectedOption});
    let sOption = selectedOption || [];
    let programsIds = sOption.length > 0 ? sOption.map( option => option.value ) : [];

    this.props.onChange(programsIds);
  }
  renderSelect(){
    if(this.props.isMultiple){
      if(!this.props.displayMode){
        return(
          <Select
            value={this.state.multipleProgramSelected}
            onChange={this.handleMultipleSelect}
            options={this.state.multipleOptions}
            menuPosition={'fixed'}
            isMulti={true}
            placeholder={"Selecciona uno o más programas"}
          />
        )
      }else{
        return(
          <div>{this.state.multipleProgramSelected.map((item,i) => <li key={i}>{item.label}</li>)}</div>
        )
      }
    }else{
      return(
        <div>
          <select className='form-control custom-select'
            name='industry_sector'
            value={this.state.selectedProgram}
            onChange={this.onChange}
          >
          {(!this.props.freeMode) && <option value={null}>{"Selecciona un programa..."}</option>}
          {
            this.getPrograms(this.state.programs).map((row, i) =>
              <option value={row._id} key={row._id}>{row.name}</option>
              )
          }
          </select>
          {this.props.showHelp && <small className="form-text text-muted">Si estás inscrito en algún programa no olvides seleccionarlo.</small>}
        </div>
      )
    }
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>{this.props.label}</label>
      )
    }else{
      return null;
    }
  }

  render(){
    return(
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

ProgramsPicker.propTypes = {
  /** Selected program. */
	selectedProgram        : PropTypes.string,
  /** Callback on program selected, returns the id or an array of ids. */
  onChange               : PropTypes.func,
  /** When is false only display the active programs. */
  showAllPrograms        : PropTypes.bool,
  /** When is true  display the inactive and active (all) programs.*/
  showInactivePrograms   : PropTypes.bool,
  /** When is true  display the programs that have facilitator.*/
  showWithFacilitator   : PropTypes.bool,
  /** When is true you can select multiple programs. */
  isMultiple             : PropTypes.bool,
  /** Selected programs separated by pipe '|'. */
  multipleProgramSelected: PropTypes.array,
  /** When is true shows the label 'Programas'. */
  showLabel              : PropTypes.bool,
  /** When is true shows just the label not editable'. */
  displayMode            : PropTypes.bool,
  /** When is true shows just the public programs used on free views'. */
  freeMode               : PropTypes.bool,
  /** When is true shows the help label */
  showHelp               : PropTypes.bool,
  /** Type of programs to be display: all, mine, public*/
  type                   : PropTypes.string,
  /** Label */
  label                  : PropTypes.string
}
ProgramsPicker.defaultProps = {
	selectedProgram         : '',
  onChange                : (program) => {},
  showAllPrograms         : false,
  showInactivePrograms    : false,
  showWithFacilitator     : false,
  isMultiple              : false,
  multipleProgramSelected : [],
  showLabel               : true,
  displayMode             : false,
  freeMode                : false,
  showHelp                : false,
  type                    : 'all',
  label                   : 'Programa'
}

export default ProgramsPicker;
