/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, March 2020
 */

//Map style
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL,{ NavigationControl } from 'react-map-gl';

//components
import KMarker from '../components/KMarker';
import Geocoder from 'react-map-gl-geocoder'
import DeckGL from "deck.gl";

//Your mapbox token:
const MAPBOX_TOKEN = 'pk.eyJ1IjoicGFuemVyIiwiYSI6ImNrN2RsZzcwMDIxMHAzZW9naWh1amhkejIifQ.2MkaNWU65XhWigJvQUm1_A';

/**
 *  Set adress position (latitude or longitude) using mapbox.
 */

class KMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
       latitude: parseFloat(process.env.REACT_APP_LATITUDE),
       longitude: parseFloat(process.env.REACT_APP_LONGITUDE),
       zoom: 12,
      },
    }
    this.setMarkerPosition = this.setMarkerPosition.bind(this);
  }
  
  mapRef = React.createRef();
  geocoderContainerRef = React.createRef();

  componentDidMount() {
    let marker = this.props.marker;
    if((this.props.marker.latitude !== null && this.props.marker.longitude !== null) && (this.props.marker.latitude !== 0 && this.props.marker.longitude !== 0) ) {
      this.setState(prevState => {
        let viewport = Object.assign({}, prevState.viewport);                                                 
        viewport.latitude = marker.latitude;
        viewport.longitude = marker.longitude;
        return { viewport };
      });
    }
  }

  setMarkerPosition(event){
    this.props.onMarkerChange(event);
  }

  getResult(res) {
    this.props.getAddress(res.result)
  }

  render() {
    const marker = this.props.marker;
    return(
      <div>
        <div
          ref={this.geocoderContainerRef}
          style={{
            height: 50,
            background: "none",
            display: "flex",
            alignItems: "center",
          }}
        />
        <ReactMapGL
          ref={this.mapRef}
          width="100%"
          height="400px"
          {...this.state.viewport}
          mapStyle="mapbox://styles/panzer/ck7e07mrw1nxj1iqvac5593qg"
          onViewportChange={viewport => this.setState({viewport})}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onClick={(e) => this.setMarkerPosition(e)}
        >
          <div style={{position: 'absolute', left: 10, top:10}}>
            <NavigationControl showCompass={false}/>
          </div>
          <KMarker latitude={marker.latitude}
                   longitude={marker.longitude}
                   draggable={true}
                   onDragEnd={(e) => this.setMarkerPosition(e) } />
          <Geocoder
            mapRef={this.mapRef}
            containerRef={this.geocoderContainerRef}
            onViewportChange={viewport => this.setState({viewport})}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            placeholder="Buscar"
            onResult={(res) => this.getResult(res)}
            countries="MX"
          >
            <DeckGL {...this.state.viewport}/>
          </Geocoder>
        </ReactMapGL>
      </div>
    )
  }
}

KMap.propTypes = {
  /** Marker object with the required fields:
      latitude, longitude */
  marker            : PropTypes.object,
  /** Callback on marker changed. */
  onMarkerChange     : PropTypes.func,
  /** Callback on map typed. */
  getAddress     : PropTypes.func,
};

KMap.defaultProps = {
  marker             : {latitude:null,longitude:null},
  onMarkerChange     : () => {},
  getAddress          : () => {}
};

export default KMap;
