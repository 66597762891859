/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, September 2019
 */

// Regular imports
import React from 'react';
import ReactModal from 'react-modal';
import SweetAlert from 'sweetalert2-react';
import KomunidadApi from '../../api/api.js';
import AliceCarousel from 'react-alice-carousel';
import { Link as RouteLink} from "react-router-dom";
import { validateEmail } from '../../utils/Utils.js';
import Navbar from '../../components/landing/Navbar.js';
import Profile from '../../components/landing/Profile.js';
import DemoModal from '../../components/landing/DemoModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element } from 'react-scroll';

// Custom styling
import '../../scss/pages/_landing.scss';
import '../../scss/pages/_landing-modal.scss';
import '../../scss/components/_navbar.scss';
import '../../scss/pages/_landing-carousel.scss';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import '../../scss/colors/icons.scss';

// Images
import Banner from "../../assets/images/ilustracion-komunidad.png";
import BannerBlue from "../../assets/images/ilustracion-komunidad-textura.png";
import BannerResponsive from "../../assets/images/k-ilustracion.png";
import Enterpreneur from "../../assets/images/enterpreneur-cropped.jpg";
import Ecosystem from "../../assets/images/entrepreneurial-ecosystem-cropped.jpg";
import Ujat from "../../assets/images/ujat.png";
import Uaslp from "../../assets/images/uaslp.png";
import Sezac from "../../assets/images/sezac.png";
import Orion from "../../assets/images/orion.png";
import Startuplab from "../../assets/images/startuplab.png";
import NodePort from "../../assets/images/nodeport.png"
import UjatGray from "../../assets/images/ujat-gray.png";
import UaslpGray from "../../assets/images/uaslp-gray.png";
import SezacGray from "../../assets/images/sezac-gray.png";
import OrionGray from "../../assets/images/orion-gray.png";
import StartuplabGray from "../../assets/images/startuplab-gray.png";
import NodePortGray from "../../assets/images/nodeport-gray.png"
import TeamPe from "../../assets/images/team-Pe.png";
import TeamAn from "../../assets/images/team-An.png";
import TeamEd from "../../assets/images/team-Ed.png"
import TeamIv from "../../assets/images/team-Iv.png";
import TeamDa from "../../assets/images/team-Da.png";
import TeamAm from "../../assets/images/team-Am.png";
import TeamIs from "../../assets/images/team-Is.png";
import TeamJu from "../../assets/images/team-Ju.png";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// ReactModal.setAppElement('#main-wrapper')

import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();

class landingView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      email: '',
      modal_open: false,
      error_message: '',
      show_success: false,
      show_error: false,
      isMenuVisible: false,
      openEnterpreneurs: false,
      openOrganization: false,
      openDemo: false,
      isBannerResponsive: false
    };

    this.responsiveObject = {
      360: {
        items: 1
      },
      720: {
        items: 3
      },
      1024: {
        items: 5
      }
    };

    this.toggleMenu         = this.toggleMenu.bind(this);
    this.renderSwal         = this.renderSwal.bind(this);
    this.toggleModal        = this.toggleModal.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
    this.handleChanges      = this.handleChanges.bind(this);
    this.openEnterpreneurs  = this.openEnterpreneurs.bind(this);
    this.closeEnterpreneurs = this.closeEnterpreneurs.bind(this);
    this.openOrganization   = this.openOrganization.bind(this);
    this.closeOrganization  = this.closeOrganization.bind(this);
    this.openDemo           = this.openDemo.bind(this);
    this.closeDemo          = this.closeDemo.bind(this);
  }

  componentDidMount() {
    if(window.innerWidth <= 420) {
      this.setState({ isBannerResponsive: true });
    } else {
      this.setState({ isBannerResponsive: false });
    }
  }

  toggleModal() {
    this.setState({
      modal_open: !this.state.modal_open,
      show_success: false
    });
  }

  handleChanges (event) {
    this.setState({ [event.target.id] : event.target.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.setState({ error_message : '' })

    if (validateEmail(this.state.email)) {
      KomunidadApi.userPreRegister(this.state.email).then(res => {
        if (res.success) {
          this.toggleModal();
          this.setState({
            email: '',
            show_success: true
          });
        } else {
          this.toggleModal();
          this.setState({
            email: '',
            show_error: true,
            error_message: res.message_es
          });
        }
      }).catch(err => {
        this.setState({ error_message: 'Servicio no disponible. Intente de nuevo más tarde.' })
      });
    }
  }

  toggleMenu(value){
    this.setState({isMenuVisible: value});
  }

  closeEnterpreneurs() {
    this.setState({openEnterpreneurs: false});
  }

  openEnterpreneurs() {
    this.setState({openEnterpreneurs: true});
  }

  closeOrganization() {
    this.setState({openOrganization: false});
  }

  openOrganization() {
    this.setState({openOrganization: true});
  }

  closeDemo() {
    this.setState({openDemo: false});
  }

  openDemo() {
    this.setState({openDemo: true});
  }

  renderSwal () {
    if (this.state.show_success) {
      return (
        <SweetAlert
          type="success"
          title="¡Genial!"
          show={this.state.show_success}
          text="Se ha enviado una invitación a tu correo con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)"
          onConfirm={() => this.setState({ show_success: false })} />
      )
    } else if (this.state.show_error) {
      return (
        <SweetAlert
          type="warning"
          title="¡Lo sentimos!"
          show={this.state.show_error}
          text={this.state.error_message}
          onConfirm={() => this.setState({ show_error: false })} />
      )
    }
  }

  renderInfoButton(){
    if(process.env.REACT_APP_DEMO_MODE === "false"){
      return(
        <div className="down-btn"> <button className="btn btn-info m-b-10" onClick={this.toggleModal}>Registrarse</button></div>
      )
    }else{
      return(
        <div className="down-btn"> <button className="btn btn-info m-b-10" onClick={this.openDemo}>Más información</button></div>
      )
    }
  }

  renderDemoButton() {
    if(process.env.REACT_APP_DEMO_MODE === "false"){
      return(null);
    }else{
      return(
        <div className="down-btn"> <button className="btn btn-info m-b-10" onClick={this.openDemo}>Clic aquí</button></div>
      )
    }
  }

  renderPrincipal() {
    return(
      <div className="fix-width">
        <div className="row banner-text">
          <div className="col-lg-5 m-t-20">
            <h1>Mide el Impacto de tu Ecosistema</h1>
            <p className="subtext"><span className="font-medium">Conoce el impacto económico y social de tus programas de innovación,
            emprendimiento y desarrollo tecnológico.  Komunidad te ayuda a administrar y gestionar proyectos, startups, aliados, emprendedores,
            retos y programas dentro de tu organización.</span></p>
            {this.renderInfoButton()}
            <DemoModal isModalOpen={this.state.openDemo} closeModal={this.closeDemo}/>
          </div>
          <div className="col-lg-7">
            <div className="hero-banner">
              <img src={ this.state.isBannerResponsive ? BannerResponsive : Banner } alt="Komunindad Banner" className="banner-img"/>
             </div>
          </div>
        </div>
      </div>
    )
  }

  renderBenefits() {
    return(
      <Element name="benefits">
        <div className="row justify-content-md-center light-blue py-5">
          <div className="fix-width">
            <h1 className="display-7 text-center">Beneficios de Komunidad</h1>
            <div className="row">
              <div className="col change-order">
                <div className="card-no-border">
                  <div className="card-body">
                    <div className="m-t-30">
                    <img className="card-img-top img-responsive img-size" style={{display:'block', height: '265px', width: 'auto', borderRadius: '10px'}} src={Enterpreneur} alt="Emprendedores"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col change-order">
                <div className="card-no-border">
                  <div className="card-body">
                    <h2 className="card-title m-t-20 mb-0">Emprendedores</h2>
                    <div className="mt-0">
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Programa de descuentos y precios preferenciales en servicios específicos para iniciar una proyecto como: campañas de marketing, sitios web, servicios notariales, contables, bancarios, fiscales, renta de oficinas, etc.
                      </p>
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Acceso a la red de inversionistas, aliados y asesores institucionales.
                      </p>
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Aplicación a programas públicos y privados de apoyo a emprendedores como incubadoras, aceleradoras, fondos de inversión, universidades, financiamiento y ángeles inversionistas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card-no-border">
                  <div className="card-body">
                    <h2 className="card-title m-t-20 mb-0">Ecosistema</h2>
                    <div className="mt-0">
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Proveer las herramientas y conocimiento necesario para implementar y mejorar su programas y estrategias de apoyo al emprendimiento y la innovación en MIPYMES.
                      </p>
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Empoderar a sus comunidades de emprendedores y vincularlos con aliados dentro y fuera de su red.
                      </p>
                      <p className="testimonial-text my-3" style={{width: '100%', fontSize: '14px', lineHeight: '28px'}}>
                        Generar información que permita medir el impacto de los distintos programas y cuantificar la derrama económica y beneficios que la comunidad recibe con números claros y con significado.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card-no-border">
                  <div className="card-body">
                    <div className="m-t-30">
                    <img className="card-img-top img-responsive img-size" style={{display:'block', height: '290px', width: 'auto', borderRadius: '10px'}} src={Ecosystem} alt="Ecosistema emprendedor"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    )
  }

  renderKomunidadGoodFor() {
    return(
      <Element name="komunidad-good-for">
        <div className="row white-space py-5">
          <div className="fix-width icon-section">
            <h1 className="display-7 text-center mb-5">¿Qué hace Komunidad?</h1>
            <p className="testimonial-text" style={{width: '100%'}}>
              Conoce el impacto económico y social de tus programas de innovación,
              emprendimiento y desarrollo tecnológico.  Komunidad te ayuda a administrar y gestionar proyectos, startups, aliados, emprendedores,
              retos y programas dentro de tu organización.
            </p>
              <div className="row m-t-40">
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="rocket"/>
                  <h4 className="font-500">Startups</h4>
                  <p>Identifica todas las empresas y startups dentro de tu (k)omunidad, conoce sus números y perspectivas de crecimiento.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="globe-americas" />
                  <h4 className="font-500">Ecosistema</h4>
                  <p>Visualiza el ecosistema emprendedor de tu (k)omunidad. Organismos, incubadoras, fondos, programas y aliados en un mapa geográfico.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="chalkboard-teacher" />
                  <h4 className="font-500">Capacitación</h4>
                  <p>Talleres en línea, base de datos de aliados y plataforma de asignación de asesorías por horario, etapa y programa.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="laptop-code" />
                  <h4 className="font-500">Eventos</h4>
                  <p>Plataforma para administración de retos y convocatorias (hackathones, demo days, etc.), da retroalimentación y califica desde un solo lugar.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="folder-open" />
                  <h4 className="font-500">One pagers</h4>
                  <p>Genera one pagers y fichas técnicas de cada startup o proyecto con un solo clic (PDF). Formatos predefinidos por tu organización.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="tasks" />
                  <h4 className="font-500">Seguimiento</h4>
                  <p>Sigue el avance de los proyectos o empresas conforme maduran y avanzan en distintos programas y fases de maduración.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="chart-bar" />
                  <h4 className="font-500">Tablero de análisis</h4>
                  <p>Todos los datos sobre tu (k)omunidad en un solo lugar. Toma decisiones sobre programas y estrategias para generar mejores startups.</p>
                </div>
                <div className="col-lg-3 col-md-6 icon-color">
                  <FontAwesomeIcon size="2x" icon="file-alt" />
                  <h4 className="font-500">Documentos clave</h4>
                  <p>Repositorio de documentos, contratos, plantillas legales y financieras, además de manuales de consulta sobre tramites para iniciar un negocio.</p>
                </div>
              </div>
          </div>
        </div>
      </Element>
    )
  }

  renderAllies() {
    return(
      <Element name="allies">
        <div className="row justify-content-md-center light-blue py-5">
          <div className="fix-width buttons-carousel">
            <center className="my-0">
              <div className="col-md-12" id="demos">
                <h1 className="display-7 mt-3 mb-5">Nuestros aliados</h1>
                <div className="card-deck">
                  <AliceCarousel
                    responsive = {this.responsiveObject}
                    autoPlay = {true}
                    autoPlayInterval = {2000}
                    buttonsDisabled = {true}
                    dotsDisabled = {true}
                    infinite
                    ref={(el) => (this.Carousel = el)}
                  >
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ UjatGray } alt="UJAT"
                        onMouseOver= {e => (e.currentTarget.src = Ujat)}
                        onMouseOut= {e => (e.currentTarget.src = UjatGray)}
                      />
                    </div>
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ UaslpGray } alt="UASLP"
                        onMouseOver= {e => (e.currentTarget.src = Uaslp)}
                        onMouseOut= {e => (e.currentTarget.src = UaslpGray)}
                      />
                    </div>
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ SezacGray } alt="Sezac"
                        onMouseOver= {e => (e.currentTarget.src = Sezac)}
                        onMouseOut= {e => (e.currentTarget.src = SezacGray)}
                      />
                    </div>
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ OrionGray } alt="Orion Startups"
                        onMouseOver= {e => (e.currentTarget.src = Orion)}
                        onMouseOut= {e => (e.currentTarget.src = OrionGray)}
                      />
                    </div>
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ StartuplabGray } alt="Startuplab"
                        onMouseOver= {e => (e.currentTarget.src = Startuplab)}
                        onMouseOut= {e => (e.currentTarget.src = StartuplabGray)}
                      />
                    </div>
                    <div className="card border-0" style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
                      <img className="card-img-top img-responsive" src={ NodePortGray } alt="Nodeport"
                        onMouseOver= {e => (e.currentTarget.src = NodePort)}
                        onMouseOut= {e => (e.currentTarget.src = NodePortGray)}
                      />
                    </div>
                  </AliceCarousel>
                </div>
              </div>
            </center>
            {/*Arrows*/}
            {/*<FontAwesomeIcon size="2x" icon="arrow-circle-left" className="buttons-carousel button-prev" onClick={() => this.Carousel.slidePrev()}/>
            <FontAwesomeIcon size="2x" icon="arrow-circle-right" className="buttons-carousel button-next" onClick={() => this.Carousel.slideNext()}/>*/}
          </div>
        </div>
      </Element>
    )
  }

  renderAboutUs() {
    return(
      <Element name="team">
        <div className="row white-space pt-5 pb-0 mx-2">
          <div className="fix-width buttons-carousel">
            <h1 className="display-7 text-center">El equipo</h1>
            <p className="testimonial-text" style={{width: '100%'}}>
              Somos un grupo de apasionados del emprendimiento y la innovación. Creemos que el verdadero desarrollo social y económico de una (k)omunidad esta en sus emprendedores y el impacto de sus nuevas empresas. Sabemos que el éxito de cualquier programa, iniciativa o política publica para fomentar el emprendimiento y la innovación será exitosa si esta puede ser medida.
            </p>
            <p className="testimonial-text" style={{width: '100%'}}>
              Apoyamos a organizaciones, cámaras empresariales, universidades, incubadoras, aceleradoras, fondos y gobiernos a generar un mejor entorno emprendedor dentro de sus (k)omunidades.
            </p>
            <div className="row">
              <Profile
                image={TeamPe}
                name="Pedro Martínez"
                bio="Actualmente dirige StartupLab MX, organización especializada en fomentar estrategias de emprendimiento e innovación con operaciones en San Luis Potosí, Tabasco y Zacatecas.
                Los programas de StartupLab han apoyado a más de 6,000 emprendedores y han acompañado a más de 900 empresas a generar empleos y desarrollo en las entidades donde operan, colaborando
                directamente con gobiernos estatales, municipales, instituciones de educación superior y centros de investigación."
                job="Operaciones"
              />
              <Profile
                image={TeamEd}
                name="Eduardo Calvillo"
                bio="Consultor en Innovación Digital y Estrategista de Productos Digitales en Berlin, Alemania, donde fundó y dirigé la consultoría MezcalDigital.
                Eduardo ha sido cofundador de varias empresas en México y Alemania. Ha trabajado para Gobiernos, ONGs y empresas de F500. En su vida pasada fue un académico responsable
                de desarrollar la curricula de varias ingenierías, y sus resultados de investigación han sido públicados en revistas y conferencias internacionales. "
                job="Director de desarrollo"
              />
              <Profile
                image={TeamAn}
                name="Ana Greta Ibañez"
                bio="Cofundadora de StartupLab Mx, apasionada del emprendimiento y la innovación; actualmente trabajando para mejorar el ecosistema emprendedor de San Luis Potosí,
                Tabasco y Zacatecas. Con experiencia en diversas empresas e instituciones tanto del sector público como de la iniciativa privada. Cuenta con una certificación en
                Incubación y Aceleración por la Universidad de Salamanca y se desempeña como Directora Administrativa."
                job="Administración"
              />
              <Profile
                image={TeamIv}
                name="Ivan Ponce"
                bio="Diseñador gráfico en la Facultad del Hábitat de la UASLP, se desarrolla como diseñador de Identidad e Ilustrador 2D. Seleccionado en exposiciones para
                Imágenes en Voz Alta, Alzheimer a través de la piel, Ideas por la paz, entre otras."
                job="Diseño"
              />
              <Profile
                image={TeamAm}
                name="América Mendoza"
                bio="Egresada del Centro de Investigación Cientifica de Ensenada (CICESE) como Maestra en Ciencias de la Computación. Cofundadora de Nodeport SA de CV, empresa especializada en desarrollo de servicios web y aplicaciones. Tiene amplia experiencia en desarrollo de interfaces gráficas utilizando diversas tecnologías. Desde hace algunos años se desempeña como docente en el Instituto Tecnológico de Ensenada en la modalidad de educación a distancia. "
                job="Desarrollo"
              />
              <Profile
                image={TeamDa}
                name="Darién Miranda"
                bio="Egresado del Centro de Investigación Cientifica de Ensenada (CICESE) como Maestro en Ciencias de la Computación. Cofundador de Nodeport SA de CV, empresa especializada en desarrollo de servicios web y aplicaciones. Ha liderado equipos de programación en la creación de soluciones de software para empresas mexicanas, estadunidenses y europeas en la modalidad de outsourcing cumpliendo con las expectativas de sus clientes. Tiene experiencia en el lanzamiento de múltiples servicios en línea para startups, garantizando su estabilidad y correcto funcionamiento."
                job="Desarrollo"
              />
              <Profile
                image={TeamIs}
                name="Israel Arellano"
                bio="Estudiante de Ingenieria en Tecnologías de la Información en la Universidad Politécnica de San Luis Potosí, apasionado por el desarrollo de aplicaciones web,
                siendo el front-end la práctica donde mejor se desenvuelve, posee conocimientos en frameworks como Vuejs y Reactjs, así como en lenguajes de hojas de estilo CSS y Sass.
                Actualmente se desarrolla como front-end developer en StartupLab Mx. "
                job="Desarrollo"
              />
              <Profile
                image={TeamJu}
                name="Juan Hernández"
                bio="Estudiante en la carrera de Ingeniería en Tecnologías de la Información en la Universidad Politécnica de San Luis Potosí. Anteriormente Becario en la rama
                Industrial Automotriz con funciones de implementación y desarrollo de Software. Cuenta con experiencia en el Desarrollo de Software en especifico en el apartado Web,
                se especializa en el desarrollo de Front-end. Actualmente se desarrolla como Front-end Developer en StartupLab Mx."
                job="Desarrollo"
              />
            </div>
          </div>
        </div>
      </Element>
    )
  }

  renderDemo() {
    if(process.env.REACT_APP_DEMO_MODE === "false"){
      return (null);
    } else {
      return(
        <Element name="demo">
          <div className="row" style={{backgroundImage: `url(${BannerBlue})`, backgroundPosition: 'center'}}>
            <div className="fix-width">
              <div className="row demo-container justify-content-md-center" style={{maxHeight: '320px'}}>
                <div className="col-5" style={{maxHeight: '320px', margin:'auto 0'}}>
                  <h1 className="demo-tittle">Solicita más información</h1>
                </div>
                <div className="col-3" style={{maxHeight: '320px'}}>
                  <div style={{maxHeight: '320px', height:'500px'}}>
                    <div className="demo-position">
                      {this.renderDemoButton()}
                    </div>
                    <DemoModal isModalOpen={this.state.openDemo} closeModal={this.closeDemo}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
      );

    }
  }

  render() {
    const isMenuVisible = this.state.isMenuVisible

    return (
      <div id="main-wrapper">
        {/* Landing page Header */}
        <Navbar toggleMenu={() =>  this.toggleMenu(!isMenuVisible)} toggleModal={this.toggleModal} isMenuVisible={this.state.isMenuVisible}/>

        {/* Main page wrapper */}
        <div className="page-wrapper landing_container py-0">
          <div className="container-fluid">
            {/* Hero unit */}
            {this.renderPrincipal()}
            {/*Komunidad benefits*/}
            {this.renderBenefits()}
            {/*Komunidad good for*/}
            {this.renderKomunidadGoodFor()}
            {/*Allies*/}
            {this.renderAllies()}
            {/*About us*/}
            {this.renderAboutUs()}
            {/*Demo*/}
            {this.renderDemo()}
          </div>
        </div>

        {/* Modal from pre-register */}
        <ReactModal
          ariaHideApp={false}
          contentLabel="¡Regístrate!"
          isOpen={this.state.modal_open}
          overlayClassName="register-modal-overlay"
          shouldCloseOnOverlayClick={true}
        >
          <div className="register__text">
            <FontAwesomeIcon size="2x" className="register__close" icon={faTimes} onClick={this.toggleModal} />

            <h1>Regístrate aquí</h1>
            <p>Ingresa tu correo electrónico y recibirás un correo con instrucciones para activar tu cuenta.</p>
          </div>

          <div className="register__form">
            <form className="form-horizontal form-horizontal" id="loginform" onSubmit={this.handleSubmit}>
              <RouteLink className="text-center db" to="/home">
                <img src={ Logo } alt="Home" className="AppLogo"/>
              </RouteLink>

              <p className="text-error">{this.state.error_message}</p>

              <div className="form-group m-t-40">
                <div className="col-xs-12">
                  <label>Correo electrónico</label>
                  <input className="form-control" id="email" value={this.state.email} onChange={this.handleChanges} type="email" />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-12">
                  <p>
                    ¿Ya tienes cuenta?
                    <RouteLink className="text-muted float-right" to="/">
                      ¡Inicia sesión!
                    </RouteLink>
                  </p>
                </div>
              </div>

              <div className="form-group text-center">
                <div className="col-xs-12">
                  <button className="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Regístrate</button>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>

        {/* SWAL */}
        { this.renderSwal() }
      </div>
    )
  }
}

export default landingView;
