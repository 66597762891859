/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, August 2019
 */

//Regular imports
import React from 'react';
import { Link as RouteLink } from "react-router-dom";
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-scroll';

//Style import
import '../../scss/pages/_landing.scss';
import '../../scss/components/_navbar.scss';

// Images
import KomunidadApi from '../../api/api.js';
import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();
class Navbar extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			scrollingLock: false,
			blueButtonText:"Iniciar Sesión",
			blueButtonLink:"/"
		}
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
		KomunidadApi.getMe().then( (res) => {
			if(res.valid_token){ //redirect to profile if user is logged in
				this.setState({
					blueButtonText:"Ir a mi cuenta",
					blueButtonLink:"/profile"
				});
			}else{
				this.setState({
					blueButtonText:"Iniciar Sesión",
					blueButtonLink:"/"
				});
			}
		});
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
    		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {

	  if (window.scrollY > 10) {
	    this.setState({
	      scrollingLock: true
	    });
	  } else if (window.scrollY < 10) {
	    this.setState({
	      scrollingLock: false
	    });
	  }

	}
	renderLoginButton(){
		if(process.env.REACT_APP_DEMO_MODE === "false"){
			return (
				<li className="nav-item px-2">
					<div className="nav-link py-0">
						<RouteLink className="btn btn-info font-13" to={this.state.blueButtonLink}>
							{this.state.blueButtonText}
						</RouteLink>
					</div>
				</li>
			);
		}else{
			return (
				<li className="nav-item px-2" style={{cursor: 'pointer'}}><Link activeClass="active" to="demo" spy={true} smooth={true} offset={-65} duration={500} className="nav-link">Contacto</Link></li>
			);
		}
	}

	render () {
		return (
			<header className={this.state.scrollingLock ? "topheader nav-header on": "topheader nav-header"}  id="top">
        <div className="fix-width">
          <nav className="navbar-complete navbar-expand-md navbar-light p-l-0 p-r-0">
            <button className="nav-toggler navbar-toggler-right" type="button" onClick={this.props.toggleMenu} aria-controls="navbarNavDropdown" aria-expanded={this.props.isMenuVisible}> <span className="navbar-toggler-icon"></span> </button>

            <RouteLink className="navbar-brand p-b-20" to="/home">
              <img src={ Logo } alt="Komunidad" className="AppLogo"/>
            </RouteLink>
            <Collapse in={this.props.isMenuVisible}>
              <div className={this.props.isMenuVisible ? "nav-col": "not-collapse"} id="navbarNavDropdown">
                <ul className="navbar-navigation navbar-nav ml-auto style-nav">
                  <li className="nav-item px-2" style={{cursor: 'pointer'}}><Link activeClass="active" to="benefits" spy={true} smooth={true} offset={-65} duration={500} className="nav-link">Beneficios</Link></li>
                  <li className="nav-item px-2" style={{cursor: 'pointer'}}><Link activeClass="active" to="komunidad-good-for" spy={true} smooth={true} offset={-65} duration={500} className="nav-link">¿Qué hacemos?</Link></li>
                  <li className="nav-item px-2" style={{cursor: 'pointer'}}><Link activeClass="active" to="allies" spy={true} smooth={true} offset={-65} duration={500} className="nav-link">Aliados</Link></li>
                  <li className="nav-item px-2" style={{cursor: 'pointer'}}><Link activeClass="active" to="team" spy={true} smooth={true} offset={-65} duration={500} className="nav-link">El equipo</Link></li>
									{this.renderLoginButton()}
                </ul>

              </div>
            </Collapse>
          </nav>
        </div>
      </header>
		);
	}

}

export default Navbar;
