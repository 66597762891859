/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, June 2019 &
 * Darien Miranda <darien@startuplab.mx>, August 2019.
 * America Mendoza <america@startuplab.mx>, August 2019.
 * Major revision: Fri 23 August 2019 by Darien & America
 * Added Masking support: Wed 18 Sep 2019 by Darien
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import Tooltip from 'react-tooltip-lite';
import * as validations from './validations.js';

// Custom SCSS
import '../../scss/components/_input.scss';

class FormInput extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      value: '',
      cleanValue:'',
      has_error: false,
      error_message: ''
    };

    this.validate          = this.validate.bind(this);
    this.onChange          = this.onChange.bind(this);
    this.cancel            = this.cancel.bind(this);
    this.renderHelp        = this.renderHelp.bind(this);
    this.getCssClass       = this.getCssClass.bind(this);
    this.renderLabel       = this.renderLabel.bind(this);
    this.renderInput       = this.renderInput.bind(this);
    this.shouldValidate    = this.shouldValidate.bind(this);
    this.renderCharCounter = this.renderCharCounter.bind(this);
    this.onBlur            = this.onBlur.bind(this);
    this.getCleanValue     = this.getCleanValue.bind(this);
    this.onClick           = this.onClick.bind(this);
    this.handleKeyDown     = this.handleKeyDown.bind(this);
  }
  //Updates the state according to the props
  static getDerivedStateFromProps(props, current_state){
    let v = props.value;
    let cv = v;
    if(current_state.input_changed){
      cv = current_state.cleanValue ||  "";
    }else{
      //format it when it's mounted
      v = props.transformMethod(v);
      if(v !== null){
        if(v.hasOwnProperty("formattedValue")){
          v = v.formattedValue;
        }
      }else{
          v = "";
      }
    }
    if(props.editMode){
      //format it when you open it
      v = props.transformMethod(v);
      if(v.hasOwnProperty("formattedValue")){
        v = v.formattedValue;
      }
    }
    return{
      value:v,
      cleanValue:cv
    };
  }
  //Cancels the edition and returns the initial value
  cancel(){
    this.props.onChange({
      model: this.props.model,
      value: this.props.initialValue,
      isTriggeredByOnBlur:false
    });
    this.setState({
      input_changed : false,
      error_message:'',
      has_error:false,
      cleanValue:'',
    })
  }
  clearError(){
    this.setState({
      error_message:'',
      has_error:false,
    })
  }
  shouldValidate() {
    return ((!isNil(this.state.value) && this.state.value.length > 0)
          || this.props.required)
      && this.props.validationRules
  }
  focus(){
    this.input.focus();
  }
  onClick(e){
    this.props.onClick(e);
  }

  validate () {
    let vm = this;
    if (this.shouldValidate()) {
      //Reads the validation from the validationRules prop and parses it
      let BreakException = { method_failed: null, condition: null, field_id: null }
      let rules = vm.props.validationRules.split('|')
      try {
        rules.forEach(function (rule) {
          let validationFunction = rule.split(':');
          //convert nulls to empty strings
          let tx_value = vm.state.cleanValue  === null ? '' : vm.state.cleanValue;
          //calls the validators
          let is_valid = validationFunction.length > 1 ?
            validations[validationFunction[0]](tx_value, validationFunction[1]) :
            validations[validationFunction[0]](tx_value)
          //If the validation does not pass. throws the exception
          if (!is_valid) {
            BreakException = {
              field_id: vm.props.id,
              method_failed: validationFunction[0],
              condition: validationFunction.length > 1 ?
                validationFunction[1] : null
            }
            throw BreakException;
          }
        });
        //TODO: Validate when other type of exception happens
      } catch (exception) {
        vm.setState(prevState => ({
          has_error: true,
          error_message: validations.getMessage(exception)
        }));

        vm.props.onValidationFail(BreakException);

        return false;
      }
    }
    vm.setState({
      has_error: false,
      error_message: ''
    });

    let successObject = {
      field_id: this.props.id,
      value: this.props.value
    };

    vm.props.onValidationSuccess(successObject);

    return true;
  }
  process( str ) {
      return str.replace( /^([^.]*\.)(.*)$/, function ( a, b, c ) {
          return b + c.replace( /\./g, '' );
      });
  }
  onChange (event) {
    let input_val   = this.props.transformMethod(event.target.value);
    let new_value   = input_val;
    let clean_value = input_val;
    if(input_val.hasOwnProperty("formattedValue")){
      new_value     = input_val.formattedValue;
      clean_value   = input_val.cleanValue
    }
    if (this.props.maxLength) {
      new_value = new_value.substring(0, this.props.maxLength);
    }

    this.setState({
        value : new_value,
        cleanValue : clean_value,
        input_changed : true
    }, () => {
        this.validate();
    });

    this.props.onChange({
      model: this.props.model,
      value: new_value,
      isTriggeredByOnBlur:false,
      cleanValue : clean_value
    });
  }

  onBlur () {
    let v = this.state.value.toString();
    let trimValue = (v ===  null) ? "" : v.trim();
    let input_val   = this.props.transformMethod(v);
    let new_value   = input_val;
    let clean_value = input_val;
    if(input_val.hasOwnProperty("formattedValue")){
      new_value     = input_val.formattedValue;
      clean_value   = input_val.cleanValue
    }
    this.setState({
        value: new_value,
        cleanValue:clean_value,
        input_changed : true

    }, () => {
        this.validate();
    });

    this.props.onChange({
      model: this.props.model,
      value: trimValue,
      isTriggeredByOnBlur:true
    });
  }
  getCleanValue(){
    return this.state.cleanValue;
  }
  getCssClass () {
    let state = this.state;
    let props = this.props;

    return {
      container_class: `form-group${state.has_error ? ' is-invalid' : ''}${props.maxLength ? ' has-counter' : ''}${props.help ? ' has-help' : ''}`,
      input_class: `form-input-component form-control${state.has_error ? ' is-invalid' : ''} ${props.cssClass}`
    };
  }

  renderLabel () {
    if (this.props.label && this.props.required) {
      return (
        <label htmlFor={this.props.id}>{this.props.label} <span>*</span></label>
      );
    } else if (this.props.label) {
      return (
        <label htmlFor={this.props.id}>{this.props.label}</label>
      );
    }
  }

  handleKeyDown(e){
    if (e.key === 'Enter') {
      this.props.onEnterPressed();
    }
  }

  renderInput (custom_class) {
    if (this.props.editMode) {
      if(this.props.inputType === 'textarea'){
        return (
            <textarea
                ref={ref => this.input = ref}
                id={ this.props.model }
                value={ this.state.value }
                type={ this.props.inputType }
                maxLength={ this.props.maxLength }
                placeholder={ this.props.placeholder }
                onChange={ this.onChange }
                onBlur={ this.onBlur }
                disabled={ this.editable }
                className={ custom_class }
                onClick={ this.onClick }
                onKeyDown={this.handleKeyDown}
            />
        );
      }else{
        return (
            <input
                ref={ref => this.input = ref}
                id={ this.props.model }
                value={ this.state.value }
                type={ this.props.inputType }
                maxLength={ this.props.maxLength }
                placeholder={ this.props.placeholder }
                onChange={ this.onChange }
                onBlur={ this.onBlur }
                disabled={ this.editable }
                className={ custom_class }
                onClick={ this.onClick }
                onKeyDown={this.handleKeyDown}
            />
        );
      }


    } else if((this.state.value === '')  &&  (this.props.emptyValueLabel !== '')){
      return (
        <div>
          {this.props.emptyValueLabel}
        </div>
      )
    }else{
      let displayValue = this.props.transformDisplayMethod(this.state.cleanValue);
      displayValue = (displayValue === null) ? "" : displayValue;
      if(displayValue.hasOwnProperty("formattedValue")){
        displayValue   = displayValue.formattedValue;
      }
      return (
        <div>
          {displayValue}
        </div>
      );
    }
  }

  renderHelp () {
    if (this.props.help && this.props.editMode) {
      if (this.props.moreHelp){
        return (
          <small id={`${this.props.id}Help`}
            className="form-text text-muted ">{this.props.help}
          <Tooltip content={<div className="more-help-tooltip">{this.props.moreHelp}</div>} useDefaultStyles className="tooltipText tooltipIcon">
            <i className="mdi mdi-help-circle"></i>
          </Tooltip>
          </small>
        );
      } else {
        return (
          <small id={`${this.props.id}Help`}
            className="form-text text-muted">{ this.props.help }
          </small>
        );
      }
    }
  }

  renderCharCounter () {
    if (this.props.maxLength > 0 && this.props.showCounter && this.props.editMode) {
      let current_count = this.state.value ? this.state.value.toString().trim().length : 0;
      return (
        <small className='float-right mt-1' style={{fontSize: '.7em', color:'#67757c'}}>
          { current_count }/{this.props.maxLength}
        </small>
      );
    }
  }

  renderError () {
    if (this.state.has_error) {
      return (
        <small className="form-text text-danger">{ this.state.error_message }</small>
      );
    }
  }

  render () {
    let classObject = this.getCssClass();
    if(this.props.errorPosition === "top"){
      return (
        <div className={ classObject.container_class }>
          { this.renderLabel() }
          { this.renderError() }
          { this.renderInput(classObject.input_class) }
          { this.renderCharCounter() }
          { this.renderHelp() }
        </div>
      );
    }else{
      return (
        <div className={ classObject.container_class }>
          { this.renderLabel() }
          { this.renderInput(classObject.input_class) }
          { this.renderCharCounter() }
          { this.renderHelp() }
          { this.renderError() }
        </div>
      );
    }

  }
}

FormInput.propTypes = {
  required:     PropTypes.bool,
  editable:     PropTypes.bool,
  editMode:     PropTypes.bool,
  showCounter:  PropTypes.bool,
  id:           PropTypes.string,
  help:         PropTypes.string,
  moreHelp:     PropTypes.string,
  model:        PropTypes.string,
  //TODO: Check append usefulness
  append:       PropTypes.string,
  value:        PropTypes.string,
  initialValue: PropTypes.string,
  label:        PropTypes.string,
  cssClass:     PropTypes.string,
  placeholder:  PropTypes.string,
  //TODO: Check inputType usefulness
  inputType:              PropTypes.string,
  validationRules:        PropTypes.string,
  maxLength:              PropTypes.number,
  onChange:               PropTypes.func,
  transformMethod:        PropTypes.func,
  transformDisplayMethod: PropTypes.func,
  onValidationFail:       PropTypes.func,
  onValidationSuccess:    PropTypes.func,
  emptyValueLabel:        PropTypes.string,
  onClick:                PropTypes.func,
  onEnterPressed:         PropTypes.func,
  errorPosition:          PropTypes.string,
}

FormInput.defaultProps = {
  required: false,
  editable: true,
  editMode: true,
  showCounter: true,
  id: '',
  help: '',
  moreHelp:'',
  model: '',
  append: '',
  value: '',
  initialValue: '',
  label: '',
  cssClass: '',
  placeholder: '',
  inputType: 'text',
  validationRules: '',
  maxLength: 255,
  onChange: () => {},
  transformMethod: (value) => {
    return value
  },
  transformDisplayMethod: (value) => {
    return (
      value
    )
  },
  onValidationFail: () => {},
  onValidationSuccess: () => {},
  emptyValueLabel:'',
  onClick: (e) => {},
  onEnterPressed: () => {},
  errorPosition:"top",
}

export default FormInput;
