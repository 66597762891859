/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, July 2019 &
 * America Mendoza <america@startuplab.mx>, August 2019 &
 * Darien Miranda <darien@startuplab.mx, August 2019
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KomunidadApi from './../../api/api.js';
import { transformToAnchor } from './../form/transforms.js';

// Components
import Swal from 'sweetalert2';
import FormInput from './../form/FormInput';


/**
 *  Display and edit the company information (Prototype phase content).
 */

class KTabPrototypeContent extends Component{
  constructor(props) {
    super(props)

    this.state = {
      editMode: false,
      isFormEditing: false,
      formData: {
        website: '',
        pdf_file: '',
        video_url: '',
        industry_sector: '',
      },
    }

    this.updateData           = this.updateData.bind(this);
    this.cancelEdit           = this.cancelEdit.bind(this);
    this.openCancelSwal       = this.openCancelSwal.bind(this);
    this.toggleEditMode       = this.toggleEditMode.bind(this);
    this.openLoadingSwal      = this.openLoadingSwal.bind(this);
    this.renderActionButtons  = this.renderActionButtons.bind(this);
    this.renderAddInfoButton  = this.renderAddInfoButton.bind(this);
    this.setInitialValues     = this.setInitialValues.bind(this);
    this.validateForm         = this.validateForm.bind(this);
    this.industryChange       = this.industryChange.bind(this);
    this.fileUpload           = this.fileUpload.bind(this);
    this.onChangeForm         = this.onChangeForm.bind(this);
    this.formIsEditing        = this.formIsEditing.bind(this);
    this.cancelForm           = this.cancelForm.bind(this);
    // MAXIMUM UPLOAD SIZE
    this.MAXIMUM_UPLOAD_SIZE = 5 * 1024 * 1024; //5MB
  }
  componentDidMount(){
    this.setInitialValues(this.props.company);
  }
  componentDidUpdate (prevProps) {
    if (this.props.company.pdf_file !== prevProps.company.pdf_file) {
      let company = this.props.company || {};
      this.setInitialValues(company);
    }
  }

  setInitialValues(company) {
    this.setState({
      formData: {
        website: company.website || '',
        pdf_file: company.pdf_file || '',
        video_url: company.video_url || '',
        industry_sector: company.industry_sector || '',
      }
    })
  }
  formIsEditing(editing){
    if(!(this.state.isFormEditing && editing)){
      this.setState({isFormEditing:editing});
      this.props.isTabEditing(editing);
    }
  }

  renderAddInfoButton() {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div className="m-b-20">
            <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
            </button>
          </div>
        )
      } else {
        return (
          <div className="m-b-20">
            <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
              <i className="mdi mdi-plus mr-1" style={{fontSize: '16px'}}></i> 
                Agregar información
            </button>
          </div>
        )
      }
    }else{
      return null
    }
  }

  renderActionButtons () {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div>
             <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
             </button>

             <button className="btn btn-danger btn-cancel" onClick={ this.openCancelSwal }>
              <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                Cancelar
             </button>
          </div>
        )
      } else {
        return (
          <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
            <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
              Editar
          </button>
        )
      }
    }else{
      return null
    }
  }

  cancelEdit () {
    this.cancelForm();
    this.formIsEditing(false);
    this.setState({
      editMode: false,
    });
  }
  cancelForm(){
    this.fi_website.cancel();
    this.fi_video_url.cancel();
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.industry_sector = this.props.company.industry_sector || '';
      formData.pdf_file = this.props.company.pdf_file || '';

      return { formData };
    });

  }
  toggleEditMode () {
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }))
  }

  updateData () {
    this.openLoadingSwal()

    KomunidadApi.updateCompany(this.props.company._id, this.state.formData).then(res => {
      this.formIsEditing(false);
      Swal.close()

      if (!res.success) {
        Swal.fire({
          type: 'error',
          title: 'No pudimos guardar tus datos',
          text: 'Hubo un error al guardar tus datos',
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      } else {
        this.props.refreshCompany();
        this.toggleEditMode();
      }
    })
  }

  validateForm(){
    return ( this.fi_website.validate() && this.fi_video_url.validate());
  }

  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.formIsEditing(true);
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }

  fileUpload(file) {
    if(file.target.files[0].size > this.MAXIMUM_UPLOAD_SIZE){
			Swal.fire({
				title: 'Archivo demasiado grande',
				text: "El archivo debe de ser menor a 5MB.",
				type: 'error',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
			});
			this.pdfInput.value = '';
		}else{
      this.formIsEditing(true);
      let pdf_file = file.target.files[0];
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.pdf_file = pdf_file;
        return { formData };
      });
    }
  }

  //setState prototype data
  industryChange(data) {
    this.formIsEditing(true);
    let dataValue = data.target.value;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.industry_sector = dataValue;
      return { formData };
    });

  }

  renderSmallLabelSelect(){
    if(this.state.editMode){
      return(
        <small className="form-text text-muted">Selecciona el sector más cercano en el que se encuentre tu empresa.</small>
      )
    }else{
      return null
    }
  }

  renderPdfLabel(){
    if(this.state.editMode){
      return(
        <div className='form-group'>
          <label>Hoja Técnica</label><br/>
          <input type="file" ref={ref=> this.pdfInput = ref} onChange={this.fileUpload} accept="application/pdf"/>
          <small className="form-text text-muted">En caso de requerirlo, puedes subir una hoja técnica que explique algún proceso dentro de tu empresa. (Máximo 5MB).</small>
        </div>
      )
    }else{
      let pdfName = ( this.state.formData.pdf_file === '' ) ? 'No has agregado el pdf técnico' : 'Descargar archivo';
      if(this.state.formData.pdf_file === ''){
        return(
          <div className='form-group'>
            <label>Hoja Técnica</label>
            <p>{pdfName}</p>
          </div>
        )
      }else{
        return(
          <div className='form-group'>
            <label>Hoja Técnica</label>
            <a href={this.state.formData.pdf_file} >{pdfName}</a>
          </div>
        )
      }

    }
  }

  render(){
    return (
      <div className="card-body">
        <div className="row view-subtittle">
          <div className="col-md-9">
            <p>Una vez que tengas definido y validado tu problema y solución deberás de empezar con la construcción de un prototipo este puede ser en formato digital o físico.</p>
          </div>
        </div>
        <div className="form-horizontal m-t-20">
          { this.renderAddInfoButton() }

          <FormInput
            ref={ref => this.fi_website = ref}
            id="dirección web"
            label="Sitio web"
            model="website"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.website}
            initialValue={this.props.company.website}
            help="Copia el URL del sitio web de tu empresa, puede ser también una red social como Facebook o LinkedIn."
            maxLength={300}
            validationRules="url"
            onChange={ change => { this.onChangeForm(change) } }
            transformDisplayMethod={ transformToAnchor }
            emptyValueLabel={'No has ingresado la dirección web'}/>


          <FormInput
            ref={ref => this.fi_video_url = ref}
            id="video"
            label="Video"
            model="video_url"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.video_url}
            initialValue={this.props.company.video_url}
            help="En caso de que tengas algún video que describa de mejor manera tu empresa puedes copiar el URL de Youtube o cualquier otra plataforma."
            maxLength={300}
            validationRules="url"
            onChange={ change => { this.onChangeForm(change) } }
            transformDisplayMethod={ transformToAnchor }
            emptyValueLabel={'No has ingresado el url del video'}
            />

          {this.renderPdfLabel()}

          <div className='form-group'>
            <label>Sector de tu empresa</label>
            <select className={this.state.editMode ? 'form-control custom-select' : 'select-non-editable'}
              name='industry_sector'
              value={this.state.formData.industry_sector}
              onChange={this.industryChange}
              disabled={!this.state.editMode}
            >
              <option value={''}>Selecciona un sector</option>
              <option value='Aeroespacial'>Aeroespacial</option>
              <option value='Aeronáutico'>Aeronáutico</option>
              <option value='Agroindustrial'>Agroindustrial</option>
              <option value='Alimentos'>Alimentos</option>
              <option value='Artesanías'>Artesanías</option>
              <option value='Automotriz'>Automotriz</option>
              <option value='Biofarmaceutica'>Biofarmaceutica</option>
              <option value='Desarrollo Web'>Desarrollo Web</option>
              <option value='Diseño y Moda'>Diseño y Moda</option>
              <option value='Dispositivos y Componentes'>Dispositivos y Componentes</option>
              <option value='Educación'>Educación</option>
              <option value='Electrodomésticos'>Electrodomésticos</option>
              <option value='Electrónico'>Electrónico</option>
              <option value='Energético'>Energético</option>
              <option value='Energías Limpias'>Energías Limpias</option>
              <option value='Energías Renovables'>Energías Renovables</option>
              <option value='Equipo Médico'>Equipo Médico</option>
              <option value='Fintech'>Fintech</option>
              <option value='Gobierno'>Gobierno</option>
              <option value='Industria Agroalimentaria'>Industria Agroalimentaria</option>
              <option value='Industria del Reciclaje'>Industria del Reciclaje</option>
              <option value='Industria Textil'>Industria Textil</option>
              <option value='Internet de las Cosas'>Internet de las Cosas</option>
              <option value='Logística'>Logística</option>
              <option value='Maquinaria y Equipo'>Maquinaria y Equipo</option>
              <option value='Materiales para la Construcción'>Materiales para la Construcción</option>
              <option value='Metalmecánica'>Metalmecánica</option>
              <option value='Minería'>Minería</option>
              <option value='Nanotecnología'>Nanotecnología</option>
              <option value='Plásticos'>Plásticos</option>
              <option value='Petroquímica'>Petroquímica</option>
              <option value='Productos Agropecuarios y Acuícolas'>Productos Agropecuarios y Acuícolas</option>
              <option value='Productos Químicos'>Productos Químicos</option>
              <option value='Salud'>Salud</option>
              <option value='Servicios de Investigación y Desarrollo'>Servicios de Investigación y Desarrollo</option>
              <option value='Servicios Medicos'>Servicios Medicos</option>
              <option value='Tecnologías de la Información'>Tecnologías de la Información</option>
              <option value='Turismo'>Turismo</option>
              <option value='Turismo Médico'>Turismo Médico</option>
            </select>
            {this.renderSmallLabelSelect()}
          </div>

          { this.renderActionButtons() }
        </div>
      </div>
    )
  }

  openLoadingSwal() {
    Swal.fire({
      title: 'Tus datos están siendo guardados',
      customClass: {
        title: 'swal-custom-title',
      },
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  openCancelSwal() {
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then(res => {
      if (res.value) this.cancelEdit()
    })
  }
}

KTabPrototypeContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
  /** True while the company is being edited */
  isTabEditing   : PropTypes.func,
  /** Callback to update the company data*/
  refreshCompany : PropTypes.func,
  /** True if the information can't be edited*/
  nonEditable    : PropTypes.bool,
};

KTabPrototypeContent.defaultProps = {
  company        : {},
  refreshCompany : () => {},
  isTabEditing   : () => {},
  nonEditable    : false,
};

export default KTabPrototypeContent;
