/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import {Nav, Spinner, Tab} from "react-bootstrap";

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';
import '../scss/pages/_mentors-view.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import Footer from '../components/Footer';
import KMentorsGrid from '../components/KMentorsGrid';
import ProgramsMentorPicker from '../components/ProgramsMentorPicker';
import KMentorsList from '../components/KMentorsList';
import KSearchInput from '../components/KSearchInput';

import {isUserProfileComplete, renderFullNameOrEmail} from '../utils/Utils.js';
import KImage from "../components/KImage";
import KTableSmartSelect from "../components/KTableSmartSelect";
import paginationFactory, {PaginationListStandalone, PaginationProvider} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import KProfileSidebar from "../components/KProfileSidebar";


class MentorsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      mentors:[],
      mentorsLoadState:'idle',
      displayMode:'grid', //state values grid and list
      sortMode:'asc',
      selectedId:['mine'],
      isMyMentor: true,
      isPartner:false,
      activeTab:'allies',
      entrepreneurs:[]
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.onProgramSelected             = this.onProgramSelected.bind(this);
    this.hasPartnerRole                = this.hasPartnerRole.bind(this);
    this.getAllMentors                 = this.getAllMentors.bind(this);
    this.getMyMentors                  = this.getMyMentors.bind(this);
    this.getMentorsByProgram           = this.getMentorsByProgram.bind(this);
    this.handleDisplayMode             = this.handleDisplayMode.bind(this);
    this.sort                          = this.sort.bind(this);
    this.getQuery                      = this.getQuery.bind(this);
    this.searchMentors                 = this.searchMentors.bind(this);
    this.handleSelectedTab             = this.handleSelectedTab.bind(this);
    this.loadEntrepreneurs             = this.loadEntrepreneurs.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true
          });
        }
        //get my mentors
        this.getMyMentors();
        this.hasPartnerRole();
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  getAllMentors() {
    KomunidadApi.getMentors(this.state.sortMode).then((res) => {
      if (res.success) {
        if (res.hasOwnProperty('results')) {
          this.setState({ mentors: res.results, mentorsLoadState: 'success' });
        }
      } else {
        this.setState({ mentors: [], mentorsLoadState: 'failed' })
      }
    });
  }

  getMyMentors() {
    KomunidadApi.getMyMentors(this.state.sortMode).then((res) => {
      if (res.success) {
        this.setState({ mentors: res.users, mentorsLoadState: 'success', isMyMentor: true });
      } else {
        this.setState({ mentors: [], mentorsLoadState: 'failed' })
      }
    });
  }

  getMentorsByProgram(programId) {
    KomunidadApi.getMentorsByProgram(programId, this.state.sortMode).then((res) => {
      if (res.success) {
        this.setState({ mentors: res.users, mentorsLoadState: 'success', isMyMentor: false });
      } else {
        this.setState({ mentors: [], mentorsLoadState: 'failed' })
      }
    });
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  onProgramSelected(id){
    let programId = id[0];
    this.setState({
      selectedId:id
    })
    if(programId === 'all'){
      return this.getAllMentors();
    }else if(programId === 'mine'){
      return this.getMyMentors();
    }else{
      return this.getMentorsByProgram(programId);
    }
  }

  hasPartnerRole(){
    if (this.state.me.roles.includes('partner')){
      this.setState({ isPartner: true })
    }else{
      this.setState({ isPartner: false });
    }
  }

  renderMentorsGrid() {
    if (this.state.mentors) {
      return (
        <KMentorsGrid
          mentors={this.state.mentors}
          requestStatus={this.state.mentorsLoadState}
          programId={this.state.selectedId[0]}
          isMyMentor={this.state.isMyMentor} />
      )
    } else {
      return (
        <div className="text-center">
          <h3>Aún no hay aliados registrados en el programa</h3>
          <i className="mdi mdi-account-star-variant m-t-20 m-b-20" style={{fontSize: '50px', color:"#c2c2c2"}}></i>
        </div>
      )
    }
  }

  renderMentorsList(){
    return(
      <KMentorsList
        mentors={this.state.mentors}
        requestStatus={'success'}
        showCompleteRow={true}
        stripedStyle={true}
        isMyMentor={this.state.isMyMentor}
      />
    )
  }

  renderContent(){
    if(this.state.displayMode === 'grid'){
      return this.renderMentorsGrid();
    }else if(this.state.displayMode === 'list'){
      return this.renderMentorsList();
    }else{
      return null
    }
  }

  handleDisplayMode(mode){
    this.setState({displayMode:mode});
  }

  sort(){
    let nextMode = this.state.sortMode === "asc" ? "desc" : "asc";
    this.setState({
      sortMode:nextMode
    }, () => {
      //reloads the previous query with the next sortMode
      this.onProgramSelected(this.state.selectedId);
    });
  }

  getQuery(value){
    this.setState({
      mentorsLoadState:'fetching'},
      () => {
        this.searchMentors(value);
      }
    );
  }

  searchMentors(value) {
    KomunidadApi.findMentorsSorted(value, this.state.sortMode).then((res) => {
      if (res.success) {
        this.setState({ mentors: res.results, mentorsLoadState: 'success' });
      } else {
        this.setState({ mentors: [], mentorsLoadState: 'failed' })
      }
    });
  }

  handleSelectedTab(key) {
    if(key === 'entrepreneurs'){
      this.loadEntrepreneurs();
    }
    this.setState({ activeTab: key });
  }
  renderEntrepreneurs(){
    if(this.state.isLoadingEntrepreneurs){
      return <Spinner animation="grow"  variant={"primary"}/>;
    }
    let partners = this.state.entrepreneurs;
    // const partners = this.state.program.partners;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
              <div style={{'display':'flex'}}>
                {rowIndex+1}
              </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(cellContent !== undefined && cellContent !== null){
            return(
                <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                  <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
                </div>
            )
          }else{
            return(
                <div  className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                  <KImage src={cellContent}  type='user' alt="user_image"/>
                </div>

            )
          }
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
                <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                  {name}
                </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },

      {
        dataField: 'dummy',
        text: 'Con empresa',
        isDummyField: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row,rowIndex,formatExtraData) =>{
          if(row === null){
            return (null);
          }
          let iconProfile   = !this.state.users_without_company_ids.includes(row._id) ? 'mdi-check' : 'mdi-close';
          let colorProfile  = !this.state.users_without_company_ids.includes(row._id) ? "#707cd2" : 'red';

          return(
              <div className="flex space-between" style={{width:"65px"}}>
                <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
              </div>
          )
        }
      },

    ];

    const pageButtonRenderer = ({
                                  page,
                                  active,
                                  disabled,
                                  title,
                                  onPageChange
                                }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({partnersPage: this.state.partnersPage + 1})
        } else if(page === '<') {
          this.setState({partnersPage: this.state.partnersPage - 1})
        } else {
          this.setState({partnersPage: page})
        }
      };
      return (
          <button
              className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
              onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: partners?.length || 0,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.partnersPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectionRenderer: ({ mode, ...rest }) => (
          <label className="custom-checkbox">
            <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
            <label></label>
          </label>
      )
    };

    return(
        <div className="">
          <div className=''
               style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse",justifyContent:'space-between'}}>
            <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectPartners = ref}
                onOptionSelected={(option) => this.onOptionSelectedPartners(option)}
                onCbClick={this.onCbClickPartners}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
              {this.renderActionButtonsPartners()}
            </div>
            {this.renderPartnersActionButtons()}
          </div>
          <PaginationProvider
              pagination={ paginationFactory(options) }
          >
            {
              ({
                 paginationProps,
                 paginationTableProps
               }) => (
                  <div>
                    <PaginationListStandalone
                        { ...paginationProps }
                    />
                    <BootstrapTable
                        bootstrap4
                        striped
                        caption={<TableCaption />}
                        keyField='_id'
                        data={ partners }
                        columns={ columns }
                        noDataIndication={'No se encontraron emprendedores para este programa.'}
                        wrapperClasses="table-responsive"
                        classes="react-bootstrap-table"
                        { ...paginationTableProps }
                    />
                    <PaginationListStandalone
                        { ...paginationProps }
                    />
                  </div>
              )
            }
          </PaginationProvider>
        </div>
    )
  }
  renderActionButtonsPartners()
  {
  }
  renderPartnersActionButtons(){

  }
  loadEntrepreneurs(){
    this.setState({isLoadingEntrepreneurs:true});
    KomunidadApi.getProgram("65eb9925fd56632e93076577").then((res) =>{
      this.setState({
        entrepreneurs:[...res.programs.partners,...res.programs.users_without_company],
        users_without_company_ids:res.programs.users_without_company.map(u => u._id),
      });
    }).catch((ex) =>{
    }).finally(() =>{
      this.setState({isLoadingEntrepreneurs:false});
    });
  }
  openUserProfile(id,activeKey){
    this.setState({userSelectedId:id,userProfileActiveKey:activeKey},() => this.handleSidebarProfile(true));
  }
  handleSidebarProfile(show){
    this.setState({isSidebarProfileOpen:show});
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className={this.state.mentors.length <= 4 ? "height100" : null}>
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            <KProfileSidebar showSidebar={this.state.isSidebarProfileOpen}
                             closeSidebar={(show) => this.handleSidebarProfile(show)}
                             user_id={this.state.userSelectedId}
                             activeKey={this.state.userProfileActiveKey}>
            </KProfileSidebar>
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">

                <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                    <h3 className="text-themecolor">Consultorio empresarial</h3>
                  </div>
                  <div className='col-md-7 align-self-center'>
                    <div className="mentors-actions">
                      <KSearchInput onEnter={(value) => this.getQuery(value)} />
                      <i className="mdi mdi-view-module m-l-10 m-r-10"
                        style={{color: this.state.displayMode === 'grid' ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
                        onClick={() => this.handleDisplayMode('grid')}
                      ></i>
                      <i className="mdi mdi-format-list-bulleted m-r-10"
                        style={{color: this.state.displayMode === 'list' ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
                        onClick={() => this.handleDisplayMode('list')}
                      ></i>
                      <i className="mdi mdi-sort-alphabetical m-r-10"
                        style={{color: '#007bff', fontSize: '24px'}}
                        onClick={this.sort}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="row view-subtittle">
                  <div className="col-md-9">
                    <p>Selecciona el programa en el cual estas inscrito para que puedas conocer el directorio de aliados, donde los podrás invitar a tu startup, generar citas de asesoría y visualizar su CV. Te recordamos que cada programa cuenta con su propio equipo de aliados.</p>
                  </div>
                </div>
                <div className="row page-titles">
                  <div className="col-md-6 align-self-center">
                    <ProgramsMentorPicker onSelect={this.onProgramSelected}
                      showInactivePrograms={false}
                      showMyMentors={this.state.isPartner}
                      multipleProgramSelected={this.state.selectedId} />
                  </div>
                </div>
                {/* Start Page Content */}
                <Tab.Container activeKey={this.state.activeTab} onSelect={this.handleSelectedTab}>
                  <Nav className="nav" bsPrefix="tabCustom">
                    <Nav.Item className="nav-item">
                      <Nav.Link
                          className="nav-link"
                          eventKey="allies"
                          id="allies"
                      >
                        Aliados
                      </Nav.Link>
                    </Nav.Item>
                    {
                      this.state.selectedId[0] === '65eb9925fd56632e93076577' ?
                          <Nav.Item className="nav-item">
                            <Nav.Link
                                className="nav-link"
                                eventKey="entrepreneurs"
                                id="entrepreneurs"
                            >
                              Emprendedores
                            </Nav.Link>
                          </Nav.Item> : null
                    }

                  </Nav>
                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="allies">
                      {this.renderContent()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="entrepreneurs">
                      {this.renderEntrepreneurs()}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

                {/* End Page Content */}
              </div>
              <Footer/>
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }

}
const isFilled = function(v){
  if(v === null || v === undefined){
    return false;
  }else{
    if( v.toString().trim() === ""){
      return false;
    }else{
      return true;
    }
  }
}
const TableCaption = () => <h5 className="display-table-caption" style={{ textAlign: 'center', color: '#398bf7', padding: '0.5em' }}> Desliza a la derecha para ver más<i className="ml-1 mdi mdi-chevron-right"/> </h5>;
export default MentorsView;
