/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, July 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal,Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { renderFullNameOrEmail} from '../utils/Utils.js';
import KomunidadApi from '../api/api.js';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import KUsersList from '../components/KUsersList';
import FormInput from '../components/form/FormInput';
import KImage from '../components/KImage';

var _= require('lodash');


/**
 *  Join a user to a company. (Used by a facilitator and manager).
 */


class KAssignUserToCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query:'',
      users:[],
      userSelected:{},
      step:'selectUser', //value selectUser, confirmation and response
      response:'', //value fail and success
      responseErrTxt: 'El usuario no pudo ser asignado a la startup',
    }
    this.hideModal                     =  this.hideModal.bind(this);
    this.handleCloseModal              =  this.handleCloseModal.bind(this);
    this.clear                         =  this.clear.bind(this);
    this.onChange                      =  this.onChange.bind(this);
    this.onClickAssign                 =  this.onClickAssign.bind(this);
    this.joinUserToCompany             =  this.joinUserToCompany.bind(this);
    this.onSelectUser                  =  this.onSelectUser.bind(this);
    this.backNavigation                =  this.backNavigation.bind(this);
  }



  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  clear(){
    this.setState({ users:[],query:'',userSelected:{},step:'selectUser',response:'' });
  }

  onChange(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.setState({query:value});

    if(value.trim() !== '' && !data.isTriggeredByOnBlur){
      KomunidadApi.findUsers(value).then((res) => {
        if(res.success){
          if(res.results.length >= 0){
            if( res.results.length === 0 ){
              this.setState({users:[]});
            }else{
              this.setState({users:res.results});
            }
          }
        }
      });
    }
  }

  onClickAssign(){
    let user = this.state.userSelected;
    let program_id = this.props.program._id;
    let company_id = this.props.company._id;


    if(program_id !== '' && company_id !== ''){
      return this.joinUserToCompany(user,program_id,company_id);
    }
  }

  joinUserToCompany(user,program_id,company_id){

    KomunidadApi.managerInviteMentor(user._id,program_id,company_id).then((res) => {
      if(res.success){
        this.setState({step:'response',response:'success'});
        this.props.onUserAssigned(this.props.program._id)
        this.handleCloseModal(false);
      }else{
        this.setState({step:'response',response:'fail',responseErrTxt:res.error_es});
      }
    });
  }



  renderContent(){
    switch (this.state.step) {
      case 'selectUser':
        return this.renderStepSelectUser();
      case 'confirmation':
        return this.renderStepConfirmation();
      case 'response':
        return this.renderStepResponse();
      default:
        return null;
    }
  }


  renderStepSelectUser(){
    return(
      <div className="card-body">
        <FormInput
          ref={ref => this.kInputEmail = ref}
          editMode={true}
          id="nombre o email"
          label="Escribe el nombre o correo del usuario"
          placeholder={"Nombre o email"}
          cssClass="m-b-0"
          value={this.state.query}
          required={true}
          validationRules="required|string"
          onChange={ change => { this.onChange(change) }}
          showCounter={false}
          />
        <KUsersList users={this.state.users}
                    onClickUser={this.onSelectUser}
                    showEmptyListLabel={this.state.query.trim() !== '' ? true : false}>
        </KUsersList>
      </div>
    )
  }

  onSelectUser(user){
    //On select user get the complete data and assign to userSelected.
    //Check if the user has joined programs,
    KomunidadApi.getUser(user._id).then((res) => {
      if(res.hasOwnProperty('user')){
        this.setState({userSelected:res.user,step:'confirmation'});
      }
    })
  }

  renderStepConfirmation(){
    return(
      <div className="card-body">
        <center>
          <h4 className="m-b-20">Estás por asignar a <b>{renderFullNameOrEmail(this.state.userSelected)}</b></h4>
          <KImage src={this.state.userSelected.profileUrlThumbnail} className={"KAPMImage"} type={"user_"+this.state.userSelected.gender} alt={"user_img"}/>
          <h4 className="m-t-20">a la startup <b>'{this.props.company.name}'</b> </h4>
        </center>
      </div>
    )
  }

  renderStepResponse(){
    if(this.state.response === 'fail'){
      return(
        <div className="card-body">
          <center>
            <h4 className="m-b-20 text-danger">
              <b>{this.state.responseErrTxt}</b>
            </h4>
          </center>
        </div>
      )
    }else{
      return null;
    }
  }

  renderAssignBtn(){
    if(this.state.step === 'confirmation'){
      return (
        <Button className="btn btn-primary btn-save" onClick={() => this.onClickAssign()}>Asignar</Button>
      )
    }else{
      return null
    }
  }

  renderBtnBack(){
    if(this.state.step === 'confirmation'){
      return(
        <div className="modal__btn__back" onClick={() => this.backNavigation()}>
          <i className="mdi mdi-chevron-left" style={{fontSize: '30px', color: "#c2c2c2"}}></i>
        </div>
      )
    }else{
      return null
    }
  }

  backNavigation(){
    this.clear();
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={() => {this.clear();}}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
        <Modal.Header closeButton>
          {this.renderBtnBack()}
          <Modal.Title>
            Agregar aliados a startup: {this.props.company.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button className={'btn btn-danger btn-cancel'} onClick={() => this.handleCloseModal(false)}>Cerrar</Button>
          {this.renderAssignBtn()}
        </Modal.Footer>
      </Modal>
    );
  }
}

KAssignUserToCompanyModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** The program object. */
  program          : PropTypes.object,
  /** The company object. */
  company          : PropTypes.object,
  /** Callback when the user is assigned. */
  onUserAssigned   : PropTypes.func,
};

KAssignUserToCompanyModal.defaultProps = {
  closeModal        : () => {},
  showModal         : false,
  program           : {},
  company           : {},
  onUserAssigned    : () => {},
};

export default KAssignUserToCompanyModal;
