/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import KomunidadApi from '../../api/api.js';

//Components
import KMentorsList from './../KMentorsList';

//const
const MySwal = withReactContent(Swal);


/**
 *  Display and edit the company information (Mentors content).
 */

class KTabMentorsContent extends React.Component{

  onClickDeleteMentor(mentor){
    let mentorId = mentor._id;
    let companyId = this.props.company._id;
    //Only the owner can delete partners
    //confirm if the btn is clicked
    this.confirmAlert(mentorId,companyId);
  }

  confirmAlert(partnerId,companyId){
    MySwal.fire({
      title: '¿Estás seguro(a)?',
      text: "Tu aliado(a) no podrá acceder a esta startup. Puedes volver a agregarlo después.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.deleteMentor(partnerId,companyId);
      }
    })
  }

  deleteMentor(mentorId,companyId){
    KomunidadApi.deleteMentor(mentorId,companyId).then((res) => {
      if(!res.success){
        Swal.fire({
          type: 'error',
          title: 'No pudimos remover a este usuario',
          text: res.error_es,
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
      this.props.onMentorsDeleted(companyId);
    });
  }

  renderAddMentorsBtn(){
    let is_disabled = false; //if the company doesn't have the property set as false.
    if(this.props.company.hasOwnProperty('is_disabled')){
      is_disabled = this.props.company.is_disabled;
    }
    if( this.props.nonEditable ){
     return null
   } else if(is_disabled === false){
      return(
        <div className="form-actions">
              <div className="row">
                  <div className="col-md-6">
                      <div className="row">
                          <div className="col-md-offset-3 col-md-9">
                              <button type="submit"
                                      className='btn btn-info'
                                      onClick={ () => this.props.onClickAddMentors(true) } >
                                      <i className="mdi mdi-account-star-variant" style={{fontSize: '16px'}}></i> Invitar aliados
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      )
    }
  }

  render(){
    return(
      <div className="card-body">
        {/* form-horizontal */}
        <div className="form-horizontal">
          <div className="row">
              <div className="col-md-12">
                <KMentorsList
                  mentors={this.props.company.mentors}
                  requestStatus={'success'}
                  isUserOwner={this.props.company.user_is_owner}
                  onClickDeleteMentor={(mentor) => this.onClickDeleteMentor(mentor)}
                />
              </div>
          </div>
        </div>
        {/* form-horizontal end*/}
      </div>
    )
  }
}

KTabMentorsContent.propTypes = {
  /** Company object */
  company               : PropTypes.object,
  /** Callback on mentor deleted */
  onMentorsDeleted      : PropTypes.func,
  /** True if the information can't be edited*/
  nonEditable           : PropTypes.bool,
};

KTabMentorsContent.defaultProps = {
  company               : {},
  onMentorsDeleted      : () => {},
  nonEditable           : false,
};

export default KTabMentorsContent;
