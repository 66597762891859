/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * America Mendoza <america@startuplab.mx>, May 2020
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import KomunidadApi from '../api/api.js';
import { Button } from 'react-bootstrap';

// Components
import Swal from 'sweetalert2';
import FormInput from '../components/form/FormInput';
import FormTextArea from '../components/form/FormTextArea';
import KDatePicker from '../components/KDatePicker';
import KFacilitatorsPicker from '../components/KFacilitatorsPicker';
import KImage from '../components/KImage';
import ImagePicker from '../components/ImagePicker.js';
const moment = require('moment');


/**
*   Create and edit program
*/


class KCreateAndEditProgram extends Component{
  constructor(props) {
    super(props);

    this.state = {
      programName:'',
      startDate: null,
      endDate: null,
      is_public:'false',
      selectedFacilitator:"",
      message: '',
      iframe:'',
      iframe_2:'',
      schedule:'',
      partnership:'',
      file:null,
      fileName:null,
    }

    this.clear                        = this.clear.bind(this);
    this.toggleEditMode               = this.toggleEditMode.bind(this);
    this.openCancelSwal               = this.openCancelSwal.bind(this);
    this.openLoadingSwal              = this.openLoadingSwal.bind(this);
    this.setInitialValues             = this.setInitialValues.bind(this);
    this.handleRadioBtn               = this.handleRadioBtn.bind(this);
    this.onChangeForm                 = this.onChangeForm.bind(this);
    this.onDateChange                 = this.onDateChange.bind(this);
    this.onChangeFacilitator          = this.onChangeFacilitator.bind(this);
    this.sendProgram                  = this.sendProgram.bind(this);
    this.getPublicValue               = this.getPublicValue.bind(this);
    this.dateToMask                   = this.dateToMask.bind(this);
    this.getImage                     = this.getImage.bind(this);
    this.onCourseImageMouseEnter      = this.onCourseImageMouseEnter.bind(this);
    this.onCourseImageMouseLeave      = this.onCourseImageMouseLeave.bind(this);
  }


  componentDidMount(){
    this.setInitialValues(this.props.program);
  }

  setInitialValues(program) {
    let programName = this.props.isCreateMode ? ''      : program.name;
    let iframe      = this.props.isCreateMode ? ''      : this.hasValue(program,'iframe');
    let iframe_2    = this.props.isCreateMode ? ''      : this.hasValue(program,'iframe_2');
    let schedule    = this.props.isCreateMode ? ''      : this.hasValue(program,'schedule');
    let partnership = this.props.isCreateMode ? ''      : this.hasValue(program,'partnership');
    let message     = this.props.isCreateMode ? ''      : program.message;
    let fileName    = this.props.isCreateMode ? null    : program.bannerUrlThumbnail;
    let startDate   = this.props.isCreateMode ? null    : new Date(program.start_date);
    let endDate     = this.props.isCreateMode ? null    : new Date(program.end_date);
    let is_public   = this.props.isCreateMode ? 'false' : this.getPublicValue(program);
    
    let selectedFacilitator = '';
    if(this.props.user.roles !== null){
      selectedFacilitator = this.props.user.roles.includes('facilitator') ? this.props.user.uid : '';
    }
    
    this.setState({
      programName: programName,
      iframe: iframe,
      iframe_2: iframe_2,
      schedule: schedule,
      partnership: partnership,
      fileName: fileName,
      message: message,
      startDate: startDate,
      endDate: endDate,
      is_public: is_public,
      selectedFacilitator: selectedFacilitator,
    });
  }

  getPublicValue(program){
    if(program.hasOwnProperty('is_public')){
      return program.is_public.toString();
    }else{
      return 'true'
    }
  }

  hasValue(program,prop){
    if(program.hasOwnProperty(prop)){
      return program[prop]
    }else{
      return ''
    }
  }

  openLoadingSwal() {
    Swal.fire({
      title: 'Tus datos están siendo guardados',
      customClass: {
        title: 'swal-custom-title',
      },
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  closeLoadingSwal(){
    Swal.close();
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.props.onEditingProgram(true);
    this.setState({[data.model]:value});
  }

  handleRadioBtn(e){
    this.props.onEditingProgram(true);
    this.setState({[e.target.name]:e.target.value})
  }

  validateProgramForm() {
    if(!this.validateDates()){
      Swal.fire({
        type: 'error',
        title: 'Revisa las fechas',
        text: 'Los campos de fecha son obligatorios',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.programName.trim().length === 0) {
      Swal.fire({
        type: 'error',
        title: 'Revisa el nombre del programa',
        text: 'El nombre del programa no pude estar vacio',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
    return true;
  }

  sendProgram() {
    this.openLoadingSwal();
    if(this.validateProgramForm()) {
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      KomunidadApi.addProgram(
        this.state.programName, startDate, endDate, this.state.is_public, this.state.message, this.state.file, this.state.iframe, this.state.iframe_2, this.state.schedule, this.state.partnership
      ).then((res) => {
        this.props.onEditingProgram(false);
        if(res.body.success) {
          this.closeLoadingSwal()
          let program = res.body.program;
          KomunidadApi.addProgramPermission(this.state.selectedFacilitator, res.body.program._id).then((res) => {
            Swal.fire({
              type: 'success',
              title: '¡Programa registrado!',
              html: `Comparte la liga de inscripción: <input value="https://${window.location.host}/join_program?programid=${program._id}" readonly></input>`,
              cancelButtonText: 'COPIAR',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
                cancelButton: 'swalBtnGray'
              },
            }).then((result) => {
              if (result.dismiss === 'cancel') {
                navigator.clipboard.writeText('https://' + window.location.host + `/join_program?programid=`)
              }
            })
            this.setState({
              programName: '',
              startDate:'',
              endDate: '',
              message: '',
              file:null,
              fileName:null,
              is_public:'false',
              iframe: '',
              iframe_2: '',
              schedule: '',
              partnership: '',
            });
            if(this.props.user.roles.includes('manager')){
              this.setState({
                selectedFacilitator: '',
              });
            }
            this.clear();
            this.props.onUpdateProgram('');
          });
        } else {
          if(res.status === 401){
            Swal.fire({
              type: 'error',
              title: 'Limite de programas básicos',
              text: res.body.error_es,
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }else{
            Swal.fire({
              type: 'error',
              title: 'No pudimos guardar tus datos',
              text: 'Hubo un error al guardar tus datos, intenta más tarde',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }
        }
      });
    }
  }
  updateProgram() {
    this.openLoadingSwal();
    if(this.validateProgramForm()) {
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      let image = null;

      if(this.state.file === null) {
        image = this.state.file;
      }

      KomunidadApi.updateProgram(
        this.props.program._id,this.state.programName, startDate, endDate, this.state.is_public, this.state.message, this.state.file, this.state.iframe, this.state.iframe_2, this.state.schedule, this.state.partnership
      ).then((res) => {
        this.closeLoadingSwal();
        if(res.success) {
          this.toggleEditMode(false); // close the inputs
          this.props.onUpdateProgram(''); // and update program props
          Swal.fire({
            type: 'success',
            title: '¡Programa actualizado!',
            text: 'Se guardaron los cambios correctamente',
            timer: 1500,
            showConfirmButton: false
          })
        } else {
          Swal.fire({
            type: 'error',
            title: 'No pudimos guardar tus datos',
            text: 'Hubo un error al guardar tus datos, intenta más tarde',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }
  //setState for DatePicker
  onDateChange(model,date) {
    this.props.onEditingProgram(true);
    let d = date;
    if(date !== null){
      d = date.date;
    }
    if(model === "startDate"){
      this.setState({
        startDate:d
      });
    }else{
      this.setState({
        endDate:d
      });
    }

  }

  onChangeFacilitator(facilitator){
    this.setState({selectedFacilitator:facilitator[0]});
  }

  getImage(file){
    if(file !== null){
      let name = window.URL.createObjectURL(file);
      this.setState({
        file: file,
        fileName: name
      });
    }
  }

  validateDates() {
    let datesAreValid = false;
    let startDate = this.state.startDate;
    let endDate   = this.state.endDate;
    if(startDate === null){
      return false;
    }
    if(endDate === null){
      return false;
    }
    if(startDate <= endDate){
      datesAreValid = true
    }
    return datesAreValid;
  }

  renderBtns(){
    if(this.props.isCreateMode){
      return(
        <Button className='btn btn-save' style={{marginTop:'32px'}} onClick={this.sendProgram}>Agregar programa</Button>
      )
    }else{
      return this.renderEditAndSaveBtns();
    }
  }

  renderEditAndSaveBtns(){
    if(this.props.editMode){
      return(
        <div style={{marginTop:'32px'}}>
          <Button className='btn btn-save'    onClick={() => this.updateProgram()}>Guardar</Button>
          <Button className='btn btn-danger'  onClick={() => {this.openCancelSwal()} }>Cancelar</Button>
        </div>
      )
    }else{
      return(
        <div style={{marginTop:'32px'}}>
          <Button className='btn btn-info btn-save'  onClick={() => this.toggleEditMode(true)}>Editar</Button>
        </div>
      )
    }
  }

  toggleEditMode(value) {
    this.props.toggleEditing(value);
  }
  renderPrivacyLabel(){
    if(this.state.is_public === "true"){
      return ("En los programas públicos los emprendedores pueden agregar su empresa al programa.");
    }else{
      return ("En los programas privados las empresas solamente pueden ser agregadas al programa por el director.");
    }
  }
  renderAddFacilitator(){
    if(this.props.isCreateMode && this.props.user.roles.includes('manager')){
      return(
        <div style={{"marginTop": "25px"}}>
          <KFacilitatorsPicker 
            ref={ref => this.facilitator_picker = ref}
            onChange={(facilitator) => {this.onChangeFacilitator(facilitator)}}>
          </KFacilitatorsPicker>
        </div>
      )
    }
  }
  openCancelSwal() {
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then(res => {
      if (res.value){
        this.toggleEditMode(false);
        this.clear();
      }
    })
  }

 renderUpdateImageArea(){
    if(this.state.showUpdateImageArea && this.props.editMode){
      return(
        <div className="img-program-overlay" onClick={() => this.imagePicker.openPicker()}>
          <div style={{fontSize: '42px'}}>
            +
          </div>
        </div>
      )
    }else{
      return null;
    }
  }

  onCourseImageMouseEnter(){
    this.setState({
      showUpdateImageArea:true
    })
  }

  onCourseImageMouseLeave(){
    this.setState({
      showUpdateImageArea:false
    })
  }

  clear () {
    this.fi_name.cancel();
    this.fi_message.cancel();
    this.dp_startDate.clear();
    this.dp_endDate.clear();
    if(this.props.isCreateMode){
      if(this.props.user.roles.includes('manager')){
        this.facilitator_picker.clear();
      }
    }else{
      //when creating
      this.setState({
        is_public: this.getPublicValue(this.props.program)
      })
    }
    //avoids showing the swal on leaving
    this.props.onEditingProgram(false);
  }

  dateToMask(date){
    return moment(date).format("DD-MM-YYYY");
  }
  renderProgramStartDateNotice(){
    let date = this.dateToMask(this.props.program.created_at);
    if(this.props.isCreateMode || !this.props.editMode){
      return null;
    }else{
      return (
        <small className="form-text text-muted" style={{marginTop:-28}}>
          La fecha mínima permitida es la fecha de creación ({date})
        </small>
      );
    }
  }
  render(){
    let created_at = new Date(this.props.program.created_at);
    let minDate = new Date();
    if(!this.props.isCreateMode){ // past for editing
      minDate = moment().diff(created_at) < 0 ? new Date() : created_at
    }

    return (
      <div className='row form-group form-horizontal'>
        <div className="col-lg-6 col-md-6" style={{borderRight: "1px solid rgba(0,0,0,.1)"}}>
           <FormInput
              ref={ref => this.fi_name = ref}
              editMode={this.props.editMode}
              id="nombre del programa"
              label="Nombre del programa"
              model="programName"
              showCounter={false}
              value={
                this.state.programName
              }
              initialValue={
                this.props.isCreateMode ? '' : this.props.program.name
              }
              required={true}
              validationRules="required"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre"/>
          <FormInput
              ref={ref => this.fi_iframe = ref}
              editMode={this.props.editMode}
              id="link de la transmisión"
              label="Link de la transmisión (Sala 1)"
              model="iframe"
              showCounter={false}
              maxLength={300}
              validationRules="url"
              value={
                this.state.iframe
              }
              initialValue={
                this.props.isCreateMode ? '' : this.props.program.iframe
              }
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Dirección Web (Sala 1)"/>
          <FormInput
              ref={ref => this.fi_iframe_2 = ref}
              editMode={this.props.editMode}
              id="link de la transmisión"
              label="Link de la transmisión (Sala 2)"
              model="iframe_2"
              showCounter={false}
              maxLength={300}
              validationRules="url"
              value={
                this.state.iframe_2
              }
              initialValue={
                this.props.isCreateMode ? '' : this.props.program.iframe_2
              }
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Dirección Web (Sala 2)"/>
          <FormInput
              ref={ref => this.fi_schedule = ref}
              editMode={this.props.editMode}
              id="link de la agenda"
              label="Link de la agenda"
              model="schedule"
              showCounter={false}
              maxLength={300}
              validationRules="url"
              value={
                this.state.schedule
              }
              initialValue={
                this.props.isCreateMode ? '' : this.props.program.schedule
              }
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Dirección Web"/>
          <FormInput
              ref={ref => this.fi_partnership = ref}
              editMode={this.props.editMode}
              id="link de aliados"
              label="Link de aliados"
              model="partnership"
              showCounter={false}
              maxLength={300}
              validationRules="url"
              value={
                this.state.partnership
              }
              initialValue={
                this.props.isCreateMode ? '' : this.props.program.partnership
              }
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Dirección Web"/>
          <FormTextArea
              ref={ref => this.fi_message = ref}
              id="instrucciones"
              label="Instrucciones del programa"
              model="message"
              editMode={this.props.editMode}
              value={this.state.message}
              initialValue={this.props.isCreateMode ? '' : this.props.program.message}
              help="Escribe un mensaje de bienvenida o instructivo dirigido a los participantes del programa."
              maxLength={500}
              required={true}
              validationRules="minLen:50|maxLen:500"
              onChange={ change => { this.onChangeForm(change) } }
              />
           <div className="widthInputCalendar wrapperDateCalendar">
             <KDatePicker
               id="Inicio del programa"
               ref={ref => this.dp_startDate = ref}
               editMode={this.props.editMode}
               initialValue={
                 this.props.isCreateMode ? '' : this.dateToMask(this.props.program.start_date)
               }
               value={
                 this.props.isCreateMode ? '' : this.dateToMask(this.props.program.start_date)
               }
               label="Inicio del programa"
               placeholder="Inicio del programa"
               required={true}
               validationRules="required"
               help={"Ingresa la fecha de inicio del programa (dd-mm-aaaa)"}
               onDateChanged={(date) => this.onDateChange('startDate',date)}
               minDate={
                 minDate
               }
             >
             </KDatePicker>
           </div>
           {this.renderProgramStartDateNotice()}

           <div className="widthInputCalendar wrapperDateCalendar">
             <KDatePicker
               id="Fin del programa"
               ref={ref => this.dp_endDate = ref}
               editMode={this.props.editMode}
               label="Fin del programa"
               placeholder="Fin del programa"
               required={true}
               validationRules="required"
               help={"Ingresa la fecha de fin del programa (dd-mm-aaaa)"}
               initialValue={
                 this.props.isCreateMode ? '' : this.dateToMask(this.props.program.end_date)
               }
               value={
                 this.props.isCreateMode ? '' : this.dateToMask(this.props.program.end_date)
               }
               onDateChanged={(date) => this.onDateChange('endDate',date)}
             >
             </KDatePicker>
         </div>

         <label>Tipo de programa:</label>
         <div>
           <input
              id="rb_is_public"
              onChange={(e) => this.handleRadioBtn(e)}
              disabled={!this.props.editMode}
              checked={this.state.is_public === 'true'}
              name="is_public"
              type="radio"
              value="true"
              className="with-gap radio-col-purple"
           />
           <label for="rb_is_public">
              Público
           </label>&nbsp;
           <input
              id="rb_is_private"
              checked={this.state.is_public === 'false'}
              onChange={(e) => this.handleRadioBtn(e)}
              disabled={!this.props.editMode}
              name="is_public"
              type="radio"
              value="false"
              className="with-gap radio-col-purple m-l-10"
           />
           <label for="rb_is_private">
              Privado
           </label>
           <div>{this.renderPrivacyLabel()}</div>

           {this.renderAddFacilitator()}
         </div>
         {this.renderBtns()}
       </div>
       <div className="col-lg-6 col-md-6">
        <label>Imagen del programa</label>
        <p>{this.props.editMode ? 'Esta es la imagen que los asistentes verán en la página de invitación a tu programa. Usa una imagen de calidad alta, minimo 1280 x 640 pixeles' : ''}</p>
        <div className="form-group">
            <ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage} minWidthProp={320} minHeightProp={180} zoom={4}/>
              <div style={{display: 'flex'}} className={'justify-content-center ' + (!this.props.editMode ?  'noselect' : 'cursor-pointer noselect')} onMouseEnter={this.onCourseImageMouseEnter} onMouseLeave={this.onCourseImageMouseLeave} data-tut="edit-image">
                  {this.renderUpdateImageArea()}
                  <KImage src={this.state.fileName} className="img-program" alt="course_img" type={"program"}/>
              </div>
          </div>
       </div>
      </div>
    )
  }
}

KCreateAndEditProgram.propTypes = {
  /** Program object. */
  program            : PropTypes.object,

  /** Callback when the program is being edited. */
  onEditingProgram   : PropTypes.func,

  /** Callback when program is created or updated. */
  onUpdateProgram    : PropTypes.func,
  
  /** When is true don´t allow to select program facilitator. */
  facilitatorView    : PropTypes.bool,

  /** When is true create a new program. */
  isCreateMode       : PropTypes.bool,

  /** True when the program is being edited. */
  editMode           : PropTypes.bool,

  /** Change the state editMode*/
  toggleEditing      : PropTypes.func,

  /** User object */
  user               : PropTypes.object,
};

KCreateAndEditProgram.defaultProps = {
  program:{
    name:'',
    iframe:'',
    iframe_2:'',
    schedule:'',
    partnership:'',
    message:'',
    fileName:null,
    start_date:null,
    end_date:null,
  },
  onEditingProgram   : () => {},
  onUpdateProgram    : () => {},
  facilitatorView    : false,
  isCreateMode       : false,
  editMode           : false,
  toggleEditing      : () => {},
  user:{
    _id                     :null,
    name                    :null,
    email                   :null,
    created_at              :null,
    updatedAt               :null,
    source                  :null,
    uid                     :null,
    profileUrlThumbnail     :null,
    goverment_id_curp       :null,
    social_linkedin         :null,
    social_facebook         :null,
    social_twitter          :null,
    social_other            :null,
    goverment_id_rfc        :null,
    dateOfBirth             :null,
    phone                   :null,
    gender                  :null,
    roles                   :null,
  },
};

export default KCreateAndEditProgram;
