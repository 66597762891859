/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Octuber 2019
 */
//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';

//components
import KImage from '../components/KImage';
import parse from 'html-react-parser';

/**
*  Show the review of the mentor. Used by [KReviewList](/#kreviewlist).
*/

class KReviewRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullReview:false,
    }
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.onClickReview               = this.onClickReview.bind(this);
    this.getValueSafely              = this.getValueSafely.bind(this);
    this.renderName                  = this.renderName.bind(this);
  }

  handleCloseModal(value){
    this.setState({showFullReview:value});
  }

  onClickReview(review){
    this.props.onClickReview(review);
  }
  renderName(){
    let r = this.props.review;
    try{
      let full_name = `${r.user.name} ${r.user.last_name_father} ${r.user.last_name_mother}`;
      return full_name;
    }catch(ex){
      return "Usuario no encontrado";
    }
  }
  getValueSafely(user,property){
    if(user){
      if(user.hasOwnProperty(property)){
        return user[property];
      }else{
        return "-";
      }
    }
  }

  renderModalReview(review,user,date) {
    if(this.state.showFullReview){
      return(
        <Modal show={this.state.showFullReview}
              onHide={() => this.handleCloseModal(false)}
              onExit={() => this.handleCloseModal(false)}
              size="lg"
              centered>
        <Modal.Header closeButton>
          <div className="row w-100">
            <div className="col-md-auto">
              <KImage src={this.getValueSafely(user,"profileUrlThumbnail")}
                    className={"kuImage mr-2 mt-2"}
                    alt={"user_img"}
                    type={"user_"+user.gender}/>
            </div>
            <div className="col">
              <div className="row">
                <Modal.Title>
                  {this.renderName()}
                </Modal.Title>
              </div>
              <div className="row">
                <span className="text-muted font-14">{date}</span>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body >
          <div className="activity-item m-b-20">
            {parse(review)}
          </div>
        </Modal.Body>
      </Modal>
      )
    } else {
      return null;
    }
  }

  renderTexReview(review){
    if(this.props.showAll) {
      return(
        <div className="container p-0">
          {parse(review)}
        </div>
      )
    } else {
      return(
        <div className="container p-0">
          <span className='text-info' style={{ cursor: "pointer" }} onClick={() => this.setState({showFullReview:true})}>Ver comentario</span>
        </div>
      )
    }
  }

  render() {
    let moment = require('moment');

    const review = this.props.review;
    const user   = this.props.review.user;
    const created_at = moment(review.created_at).format('DD MMMM YYYY');
    if (!this.props.managerViewReview){
      return(
        <div className="activity-item m-b-20">
          <div className="m-r-10">
            <KImage src={this.getValueSafely(user,"profileUrlThumbnail")}
                    className={"kuImage"}
                    alt={"user_img"}
                    type={"user_"+user.gender}/>
          </div>
          <div style={{width:'83%'}}>
            <h5 className="m-b-5 font-medium">
              {this.renderName()} <span className="text-muted font-14 m-l-10">&nbsp;<br/>{created_at}</span>
            </h5>
            {this.renderModalReview(review.review,user,created_at)}
            {this.renderTexReview(review.review)}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <span className='text-info' style={{ cursor: "pointer" }} onClick={() => this.setState({ showFullReview: true })}>
            <div className="activity-item m-b-20 row">
              <div className="col-md-3 mx-auto">
                <KImage src={this.getValueSafely(user, "profileUrlThumbnail")}
                  className={"kuImage d-block m-auto"}
                  alt={"user_img"}
                  type={"user_" + user?.gender} />
              </div>
              <div className="col-md p-r-0">
                <h5 className="m-b-5 font-medium general-text-font">
                  {this.renderName()}
                  <div className="text-muted font-14">comentó en la empresa <b>{review.company.name}</b></div>
                  <span className="text-muted font-14">{created_at}</span>
                </h5>
                {/* {this.renderTexReview(review.review)} */}
              </div>
            </div>
          </span>
                {this.renderModalReview(review.review, user, created_at)}
        </div>
      )
    }
  }
}

KReviewRow.propTypes = {
  /** The review object with the fields:
      user, review, created_at*/
  review            : PropTypes.object,
  /** Callback on click review*/
  onClickReview     : PropTypes.func,
  /** Show full review in Modal*/
  showFullReview    : PropTypes.bool,
  /** Show manager view review*/
  managerViewReview : PropTypes.bool,
  /*Show full review in text*/
  showAll           : PropTypes.bool
};

KReviewRow.defaultProps = {
  review             : {},
  onClickReview      : ()=>{},
  showFullReview     : false,
  managerViewReview  : false,
  showAll            : false
};

export default KReviewRow;
