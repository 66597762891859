import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const isoCodes = [
  {label:"Cualquier estado", value:'all'},
  {label:"Aguascalientes", value:"MX-AGU"},
  {label:"Baja California", value:"MX-BCN"},
  {label:"Baja California Sur", value:"MX-BCS"},
  {label:"Campeche", value:"MX-CAM"},
  {label:"Coahuila de Zaragoza", value:"MX-COA"},
  {label:"Colima", value:	"MX-COL"},
  {label:"Chiapas", value:"MX-CHP"},
  {label:"Chihuahua", value:"MX-CHH"},
  {label:"Ciudad de México", value:"MX-CMX"},
  {label:"Durango", value:"MX-DUR"},
  {label:"Guanajuato", value:"MX-GUA"},
  {label:"Guerrero", value:"MX-GRO"},
  {label:"Hidalgo", value:"MX-HID"},
  {label:"Jalisco", value:"MX-JAL"},
  {label:"México", value:"MX-MEX"},
  {label:"Michoacán de Ocampo", value:"MX-MIC"},
  {label:"Morelos", value:"MX-MOR"},
  {label:"Nayarit", value:"MX-NAY"},
  {label:"Nuevo León", value:"MX-NLE"},
  {label:"Oaxaca", value:"MX-OAX"},
  {label:"Puebla", value:"MX-PUE"},
  {label:"Querétaro", value:"MX-QUE"},
  {label:"Quintana Roo", value:"MX-ROO"},
  {label:"San Luis Potosí", value:"MX-SLP"},
  {label:"Sinaloa", value:"MX-SIN"},
  {label:"Sonora", value:"MX-SON"},
  {label:"Tabasco", value:"MX-TAB"},
  {label:"Tamaulipas", value:"MX-TAM"},
  {label:"Tlaxcala", value:"MX-TLA"},
  {label:"Veracruz de Ignacio de la Llave", value:"MX-VER"},
  {label:"Yucatán", value:"MX-YUC"},
  {label:"Zacatecas", value:"MX-ZAC"},
];

const multipleOptions = isoCodes.map( s => ({value: s.value, label: s.label }));

/**
*   Pick one or multiple states.
*   Returns an array of code's or the code of the selected state(s).
*/

class StatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleOptions:[],
      multipleStatesSelected:[],
      enableAll:true,
    }
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
    this.disabledOption         = this.disabledOption.bind(this);

  }

  static getDerivedStateFromProps(props, state) {
    if(props.selectedLocations !== "" && state.multipleStatesSelected !== null && state.multipleStatesSelected.length === 0){
      let selectedLocationsArray = props.selectedLocations.slice("|");
      let newSelectedLocations = isoCodes.filter( p => selectedLocationsArray.includes(p.value));
      return {
        multipleStatesSelected: newSelectedLocations
      };
    }

    // Return null if the state hasn't changed
    return null;
  }


  handleMultipleSelect(selectedOption,ActionType){
    let statesCodes = [];

    if( selectedOption !== null){
      statesCodes = selectedOption.length > 0 ? selectedOption.map( option => option.value ) : [];

      if(statesCodes.includes('all')){
        selectedOption = selectedOption.filter( option => option.value === 'all');

        this.setState({enableAll:false});
      }else if(!this.state.enableAll){
        this.setState({enableAll:true});
      }
    }else if(!this.state.enableAll){
      this.setState({enableAll:true});
    }

    this.setState({multipleStatesSelected:selectedOption});


    this.props.onChange(statesCodes);
  }

  disabledOption(option){
    if(!this.state.enableAll){
      return option.label !== 'Cualquier estado';
    }else{
      return option.label === '';
    }
  }

  renderSelect(){

    if(this.props.isMultiple){
      return(
        <Select
          value={this.state.multipleStatesSelected}
          onChange={this.handleMultipleSelect}
          options={multipleOptions}
          isMulti={true}
          placeholder={"Selecciona la entidad federativa"}
          isOptionDisabled={option => this.disabledOption(option)}
        />
      )
    }else{
      return(
        null
      )
    }
  }

  render(){
    return(
      <div className='form-group'>
        <label>{this.props.label}</label>
        {this.renderSelect()}
      </div>
    )
  }
}

StatePicker.propTypes = {
  /** Callback on state selected. */
  onChange          : PropTypes.func,
  /** When is true you can select multiple states. */
  isMultiple        : PropTypes.bool,
  label             : PropTypes.string,
  /**Selected locations */
  selectedLocations : PropTypes.string,
}
StatePicker.defaultProps = {
  onChange          : () => {},
  isMultiple        : false,
  label             : 'Estado',
  selectedLocations : '',
}

export default StatePicker;
