/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, November 2021
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/** Pick one or multiple programs.
*   Returns an array of strings of the selected programs
*/

class KProgramsPickerPublicCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      programs: [],
      selectedPrograms: []
    }
    this.programs = [];
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let propOptions = props.programs.map(p => ({ value: p._id, label: p.name, program: p }))
    
    if (state.selectedPrograms !== null || props.programs.length !== state.options.length - 1) {
      if (propOptions.length > 0) {
        propOptions.unshift({ value: 'all', label: 'Todos', program: {} });
        let newSelectedPrograms = [];
        if(props.selectedPrograms !== "" && state.selectedPrograms !== null && state.selectedPrograms.length === 0){
          let selectedProgramsArray = props.selectedPrograms.slice("|");
          newSelectedPrograms = state.programs.filter( p => selectedProgramsArray.includes(p.value));
          return {
            programSelected: [],
            programs: propOptions,
            options: propOptions,
            selectedPrograms: newSelectedPrograms,
          };
        }
        return {
          programSelected: [],
          programs: propOptions,
          options: propOptions,
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }

  cancel() {
    this.setState({
      selectedPrograms: [],
      programs: this.state.programs,
    })
  }

  handleMultipleSelect(selectedOptions) {
    let programs = this.state.programs.slice();
    if (selectedOptions !== null) {
      if (selectedOptions.length > 0) {
        programs = programs.filter((p) => p.value !== 'all');

        if (selectedOptions[0].value === "all") {
          programs = programs.map((p) => { p.isDisabled = true; return p });
        }
      }
    } else {
      programs = programs.map((p) => { p.isDisabled = false; return p });
    }
    this.setState({ selectedPrograms: selectedOptions, options: programs });
    this.props.onChange(selectedOptions);
  }
  renderSelect() {
    return (
      <Select
        options={this.state.options}
        value={this.state.selectedPrograms}
        onChange={this.handleMultipleSelect}
        isMulti={true}
        placeholder={"Seleccionar"}
      />
    )
  }

  renderLabel() {
    if (this.props.showLabel) {
      return (
        <label>Programa</label>
      )
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

KProgramsPickerPublicCompanies.propTypes = {
  /** Callback on program selected, returns the id or an array of ids. */
  onChange: PropTypes.func,
  /** When is true show the label 'Programas'. */
  showLabel: PropTypes.bool,
  /** When is true show the label 'Programas'. */
  programs: PropTypes.array,
  /** selected programs */
  selectedPrograms: PropTypes.string,
}
KProgramsPickerPublicCompanies.defaultProps = {
  onChange: (programs) => { },
  showLabel: true,
  programs: [],
  selectedPrograms: '',
}

export default KProgramsPickerPublicCompanies;
