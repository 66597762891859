/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila & América Mendoza <kevin@startuplab.mx & america@startuplab.mx>, July 2019
 * & Darien Miranda <darien@startuplab.mx>, August 2019
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import {Tab, Nav, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import { Prompt } from 'react-router-dom'
import Swal from 'sweetalert2';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KAddPartnersModal from '../../components/KAddPartnersModal';
import KTabPartnersContent from '../../components/ktabs/KTabPartnersContent';
import KTabIdeaContent from '../../components/ktabs/KTabIdeaContent';
import KTabPrototypeContent from '../../components/ktabs/KTabPrototypeContent';
import KTabStartupContent from '../../components/ktabs/KTabStartupContent';
import KTabPymeContent from '../../components/ktabs/KTabPymeContent';
import KTabScaleUpContent from '../../components/ktabs/KTabScaleUpContent';
import KTabMentorsContent from '../../components/ktabs/KTabMentorsContent';
import KTabReviewContent from '../../components/ktabs/KTabReviewContent';
import KTabCourseContent from '../../components/ktabs/KTabCourseContent';
import KTabSettingsContent from '../../components/ktabs/KTabSettingsContent';
import KImage from '../../components/KImage';
import KMentorCompanyView from '../../components/KMentorCompanyView';


import '../../scss/pages/_style.css';
import BounceLoader from 'react-spinners/BounceLoader';
var CompanyPhasesChecker = require("../../utils/CompanyPhasesChecker");

class CompanyView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      me: {},
      company: {},
      isModalPartnersOpen: false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      company_id: null,
      isInvitationAccepted:null,
      isTabEditing:false,
      confirmedNavigation: false,
      invitation:{
        invited_by:{

        }
      },
      companyPhases:{},
      loadingState:"idle", //States: idle, loading, failed
      isOnePagerAvailable:false,
      overrideMentorEdit:false
    }

    this.handleResizeBar              = this.handleResizeBar.bind(this);
    this.getCompany                   = this.getCompany.bind(this);
    this.openPartnersModal            = this.openPartnersModal.bind(this);
    this.closePartnersModal           = this.closePartnersModal.bind(this);
    this.refreshCompany               = this.refreshCompany.bind(this);
    this.handleHeaderResize           = this.handleHeaderResize.bind(this);
    this.renderJoin                   = this.renderJoin.bind(this);
    this.acceptInvitation             = this.acceptInvitation.bind(this);
    this.cancelInvitation             = this.cancelInvitation.bind(this);
    this.handleBlockedNavigation      = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);
    this.tabIsEditing                 = this.tabIsEditing.bind(this);
  }
  // componentWillMount is deprecated...
  componentDidMount () {
    let company_id = this.props.match.params.id
    this.setState({ company_id: company_id })
    // this.getCompany(true);
    KomunidadApi.getMe().then(res => {
      if (res.valid_token) {
        if (isUserProfileComplete(res.user)) {
          this.setState({ me: res.user })
          this.getCompany(true);
        } else {
          this.props.history.push('/profile/edit')
        }
      } else {
        this.props.history.push('/');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar();
  }

  componentDidUpdate(){
    if(this.state.isTabEditing) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }
  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }
  getCompany (showLoader) {

    if(showLoader){
      this.setState({loadingState:'loading'});
    }
    KomunidadApi.getCompany(this.state.company_id).then((res) => {
      if(res.success && res.hasOwnProperty('company')) {
        let companyPhases = CompanyPhasesChecker.check(res.company);
        this.setState({company:res.company,loadingState:'idle',isOnePagerAvailable:companyPhases.isIdea,companyPhases:companyPhases});
        this.isInvitationAccepted(this.state.me,res.company);
      }else if(!res.success){
        this.setState({loadingState:'failed'});

        this.props.history.push('/companies');
      }
    })
  }
  handleBlockedNavigation = (nextLocation) => {
    if(!this.state.confirmedNavigation){
      this.confirmLeave(nextLocation);
      return false;
    }
    return true
  }
  //Confirm leave
  confirmLeave(nextLocation){
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.handleConfirmNavigationClick(nextLocation);
      }
    })
  }
  //Navigate to the next location
  handleConfirmNavigationClick(nextLocation){
     this.setState({
        confirmedNavigation: true
     }, () => {
        // Navigate to the previous blocked location
        this.props.history.push(nextLocation.pathname);
     })
  }
  toggleCompanyIsPublic(is_public, password){
    KomunidadApi.toggleCompanyIsPublic(this.state.company._id, is_public, password).then(res => {
      if (res.success) {
        window.location.reload();
      } else {
        //TODO: SWAL ERROR
      }
    });
  }

  confirmSwal(is_public){
    let htmlTittle = '';
    if(is_public){
      htmlTittle = 'Mostrar tu empresa en el directorio publico';
    }else{
      htmlTittle = 'Dejar de mostrar tu empresa en el directorio publico';
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: htmlTittle,
          type: 'warning',
          html: 'Ingresa tu contraseña',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnTeal',
            cancelButton: 'swalBtnRedOrange',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.toggleCompanyIsPublic(is_public, password);
      }
    })()
  }

  tabIsEditing(editing){
    if(!this.state.company.is_disabled) {
      this.setState({isTabEditing:editing})
    }
  }
  acceptInvitation(){
    let params = {
      "company_id":this.state.company._id,
      "invitation_id":this.state.invitation._id
    }
    if(this.state.company.invitation_type === "partner"){
      KomunidadApi.acceptInvitation(params).then(res => {
        if (res.success) {
          window.location.reload();
        } else {
          //TODO: SWAL ERROR
        }
      });
    }else{
      KomunidadApi.acceptMentorInvitation(params).then(res => {
        if (res.success) {
          window.location.reload();
        } else {
          //TODO: SWAL ERROR
        }
      });
    }

  }
  cancelInvitation(){
    let companyId = this.state.company._id;
    let partner_id = this.state.invitation._id || null;
    if(this.state.company.invitation_type === "partner"){
      KomunidadApi.deletePartner(partner_id,companyId).then((res) => {
        if(res.success){
          this.props.history.push('/companies');
        }else{
          //TODO: SWAL ERROR
        }
      });
    }else{
      KomunidadApi.deleteMentor(partner_id,companyId).then((res) => {
        if(res.success){
          this.props.history.push('/companies');
        }else{
          //TODO: SWAL ERROR
        }
      });
    }
  }

  isInvitationAccepted(me,company){
    let partners = company.partners;

    if(company.invitation_type === "mentor"){
      partners = company.mentors;
    }
    let user = me;
    let isInvitationAccepted = false;
    if(user.uid === company.owner_uid){
      this.setState({isInvitationAccepted:true});
      return true;
    }
    function findMe(partner){
      if(partner.user !== null){
        return (partner.user._id === user._id);
      }else if(partner.status === "invited_by_email"){
        return (partner.invited_email === user.email);
      }
    }
    partners = partners.filter(findMe);
    partners = partners.filter(partner => (partner.status === "invited" || partner.status === "invited_by_email"));
    isInvitationAccepted = partners.length > 0 ? false : true;

    this.setState({
      isInvitationAccepted:isInvitationAccepted,
      invitation:partners[0]
    });
  }
  openPartnersModal(open){
    this.setState({isModalPartnersOpen:open});
  }
  closePartnersModal(){
    this.setState({isModalPartnersOpen:false});
  }


  refreshCompany(){
    //Refresh company
    this.getCompany(false);
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }
  renderJoin(){
    let name = "";
    if(this.state.invitation.hasOwnProperty("invited_by")){
      name = this.state.invitation.invited_by.name || "";
    }

    let profileUrl = "";
    let gender     = "";
    if(this.state.invitation.hasOwnProperty("invited_by")){
      profileUrl = this.state.invitation.invited_by.profileUrlThumbnail;
      gender = this.state.invitation.invited_by.gender;
    }

    return (
            <div>
              <div className="invitation">
                <div className="invitation_logos">
                  <KImage src={this.state.company.logoThumbnail}  alt="user_img" className="invitation-company-logo" type={"company"}/>
                  <KImage src={profileUrl} className="invitation-company-logo" alt="user_img" type={"user_"+gender}/>
                </div>
                <div className="invitation_company_name">{name} te invitó a unirte a {this.state.company.name}</div>
                <div className="members-list">
                </div>
                <p className="m-b-20 mx-auto">Puedes ver el one pager de la empresa
                  <a className="m-l-5" rel="noopener noreferrer" href={KomunidadApi.API_URL+'/companies/'+this.state.company_id+'/pdf'} target={'_blank'}>
                   aquí
                  </a>
                </p>
                <button type="button" className="btn btn-success btn-save" onClick={this.acceptInvitation}>
                  Aceptar Invitación
                </button>
                <div onClick={() => this.cancelInvitation()} className="invitation-link">
                  Rechazar
                </div>
              </div>
            </div>)
  }
  renderPhaseTabsContent(){
    if(this.state.loadingState === "loading"){
      return(
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="idea">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="prototype">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="startup">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="pyme">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="scale-up">
            {this.renderLoader()}
          </Tab.Pane>
        </Tab.Content>
      )
    }else{
      return(
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="idea">
            <KTabIdeaContent company={this.state.company}
                             refreshCompany={this.refreshCompany}
                             isTabEditing={this.tabIsEditing}/>
          </Tab.Pane>
          <Tab.Pane eventKey="prototype">
            <KTabPrototypeContent company={this.state.company}
                                  refreshCompany={this.refreshCompany}
                                  isTabEditing={this.tabIsEditing}/>
          </Tab.Pane>
          <Tab.Pane eventKey="startup">
            <KTabStartupContent company={this.state.company}
                                refreshCompany={this.refreshCompany}
                                isTabEditing={this.tabIsEditing}/>
          </Tab.Pane>
          <Tab.Pane eventKey="pyme">
            <KTabPymeContent  company={this.state.company}
                              refreshCompany={this.refreshCompany}
                              isTabEditing={this.tabIsEditing}/>
          </Tab.Pane>
          <Tab.Pane eventKey="scale-up">
            <KTabScaleUpContent company={this.state.company}
                                refreshCompany={this.refreshCompany}
                                isTabEditing={this.tabIsEditing}/>
          </Tab.Pane>
        </Tab.Content>
      )

    }
  }
  renderTabsContent(){
    if(this.state.loadingState === "loading"){
      return(
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="partners">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="mentors">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="reviews">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="courses">
            {this.renderLoader()}
          </Tab.Pane>
          <Tab.Pane eventKey="settings">
            {this.renderLoader()}
          </Tab.Pane>
        </Tab.Content>
      )
    }else{
      return(
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="partners">
            <KTabPartnersContent company={this.state.company}
                                 onClickAddPartners={this.openPartnersModal}
                                 onPartnersDeleted={this.refreshCompany}
                                 className={"btn-info btn-save"}
                                 />

          </Tab.Pane>
          <Tab.Pane eventKey="mentors">
            <KTabMentorsContent company={this.state.company}
                                onMentorsDeleted={this.refreshCompany}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="reviews">
            <KTabReviewContent company={this.state.company}/>
          </Tab.Pane>
          <Tab.Pane eventKey="courses">
            <KTabCourseContent company={this.state.company}/>
          </Tab.Pane>
          <Tab.Pane eventKey="settings">
            <KTabSettingsContent company={this.state.company} refreshCompany={this.refreshCompany}/>
          </Tab.Pane>
        </Tab.Content>
      )

    }
  }
  renderTabs(){
    return (
      <div className="row">
        <div className="col-lg-6 col-xlg-8">
          <div className="card">
            <Tab.Container defaultActiveKey="idea">
              <Nav className="nav" bsPrefix='tabCustom' >
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="idea" id="idea">Idea</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="prototype" id='prototype'>Prototipo</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="startup" id='startup' >Startup</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="pyme" id='pyme'>PyME</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="scale-up" id='scale-up'>Scale up</Nav.Link>
                </Nav.Item>
              </Nav>
              {this.renderPhaseTabsContent()}
            </Tab.Container>
          </div>
        </div>
        <div className="col-lg-6 col-xlg-4">
          <div className="card">
            <Tab.Container defaultActiveKey="partners">
              <Nav className="nav" bsPrefix='tabCustom' >
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="partners" id='partners'>Socios</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="mentors" id='mentors'>Aliados</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="reviews" id='reviews'>Retroalimentación</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="courses" id='courses'>Cursos</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="settings" id='settings'>
                    <i className="mdi mdi-settings"></i>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {this.renderTabsContent()}
            </Tab.Container>
          </div>
        </div>
      </div>
    )
  }

  renderCompanyContent(){
    let me = this.state.me;

    //Check if the user is the manager. If it is the view is non editable
    if(me.roles.includes('manager') || me.roles.includes('facilitator')){
      return ( <KMentorCompanyView company={this.state.company}
                                   user={me}
                                   allowReviews={false}
                                   subtitleEmptyList={''}/>
      )
    }else if(this.state.company.hasOwnProperty('invitation_type')){
      //if the user is mentor show the mentor view
      if(this.state.company.invitation_type === 'mentor'){
        if(this.state.loadingState === "loading"){
          return this.renderLoader();
        }else{
          return this.state.overrideMentorEdit ?
              <>
                {this.renderOverrideMentorEdit()}
                {this.renderTabs()}
              </>
              :
              <>
                {this.renderOverrideMentorEdit()}
                <KMentorCompanyView company={this.state.company} user={me}/>

              </>
        }
      }else{
        return this.renderTabs();
      }
    }else{
      return this.renderTabs();
    }
  }
  renderOverrideMentorEdit(){
    return <Button onClick={() => this.setState({overrideMentorEdit: !this.state.overrideMentorEdit})} className={'m-2'}>
      {!this.state.overrideMentorEdit  ? 'Editar' : 'Dejar de editar'}
    </Button>
  }
  renderContent(){
    switch (this.state.isInvitationAccepted) {
      case null:
        return (null)
      case true:
        return this.renderCompanyContent();
      case false:
        return this.renderJoin();
      default:
        return (null)
    }
  }
  renderOnePagerButton(){
    let tooltipText = !this.state.isOnePagerAvailable ?
      "El one pager no está diponible hasta que rellenes los datos de la fase idea." :
      "Generar One pager";
    if(this.state.isOnePagerAvailable){
      return(
        <OverlayTrigger key={'tooltip-cert'}
                        placement={'top'}
                        overlay={
                                    <Tooltip id={'tooltip-cert'}>
                                      {tooltipText}
                                    </Tooltip>
                                  }>
          <a href={KomunidadApi.API_URL+'/companies/'+this.state.company_id+'/pdf'} rel="noopener noreferrer" target={'_blank'}>
            <div className={"btn btn-success btn-save float-right"}>One pager</div>
          </a>
        </OverlayTrigger>
      );
    }else{
      return(
        <OverlayTrigger key={'tooltip-cert'}
                        placement={'top'}
                        overlay={
                                    <Tooltip id={'tooltip-cert'}>
                                      {tooltipText}
                                    </Tooltip>
                                  }>
            <div className={"btn btn-success btn-save float-right"}>One pager</div>
        </OverlayTrigger>
      );
    }
  }
  renderPublishButton(){
    if(this.state.me._id === this.state.company.owner_uid){
      if(this.state.company.is_public){
        return(
          <div className={"btn btn-danger float-right"} onClick={() => this.confirmSwal(false)}>Remover del directorio público</div>
        )
      }else{
        if(this.state.companyPhases.isStartupComplete){
          return(
            <div className={"btn btn-success float-right"} onClick={() => this.confirmSwal(true)}>Agregar al directorio público</div>
          )
        }
      }
    }
  }
  renderBtnOnePager(){
    if(this.state.isInvitationAccepted){
      return(
        <div className="company-actions">
          <div className="btn btn-info btn-aux py-1" onClick={() => this.getCompany(true)}>
            <i className="mdi mdi-refresh" style={{fontSize: '18px'}}></i>
          </div>
          {this.renderOnePagerButton()}
          {this.renderPublishButton()}
        </div>
      )
    }else{
      return null
    }
  }
  renderLoader(){
    return(
        <div className="loader_wrapper">
          <BounceLoader
                   sizeUnit={"px"}
                   size={150}
                   color={'#3cc'}
                   loading={true}
                 />
        </div>
    )
  }
  render() {
    const isTabEditing = this.state.isTabEditing

    return(
      <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
        <Prompt
            when={isTabEditing} // <- function that returns boolean
            message={this.handleBlockedNavigation}
        />
        <div id="main-wrapper" className="height100">
          <Header
            user={this.state.me}
            onBurgerClicked={() => this.handleResizeBar('byClick')}
            onResize={(w) => this.handleHeaderResize(w)}
            hideBurguer={this.state.showTopbar}
          />
          <TopSideBar
            user={this.state.me}
            hideTopBar={!this.state.showTopbar}
          />
          <SideBar 
            user={this.state.me} 
            visible={this.state.sidebarStatus} 
            hideSideBar={this.state.showTopbar}
          />
            {/* Page wrapper  */}
          <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
            <div className="container-fluid">
              <div className="row page-titles">
               <div className="col-md-5 align-self-center">
                  <h3 className="text-themecolor">{this.state.company.name}</h3>
                </div>
                <div className="col-md-7 align-self-center">
                  {this.renderBtnOnePager()}
                </div>
              </div>
              {this.renderContent()}
            </div>
            <KAddPartnersModal isModalOpen={this.state.isModalPartnersOpen}
              companyId={this.state.company_id}
              closeModal={this.closePartnersModal}
              onPartnersAdded={this.refreshCompany}></KAddPartnersModal>
          </div>
          <Footer/>
        </div>
      </div>
    )
  }
}

export default CompanyView;
