/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, August 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

/*Components*/
import KNewRow from './KNewRow';

//Other imports
import { orderBy } from 'lodash';

/**
 *  Render the stats of all the instalation.
 */

class KNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [] 
    }
  }

  static getDerivedStateFromProps(props, state) {

    let programs = props.data.programs;
    let companies = props.data.companies;
    let partners = props.data.partners;
    let mentors = props.data.mentors;
    let Data1 = []
    let fullData = []

    programs  = KNews.addType(programs, 'program')
    companies = KNews.addType(companies, 'company')
    partners  = KNews.addType(partners, 'partner')
    mentors   = KNews.addType(mentors, 'mentor')

    Data1 = programs.concat(companies, partners)
    fullData = Data1.concat(mentors, fullData)
    fullData = orderBy(fullData, ['created_at'], ['desc']);

    return{ data: fullData };

  }

  static addType(array, type) {
    array.forEach(function (el) {
      el.type = type;
    })

    return array;

  }

  render(){
    let data = this.state.data;
    return(
      <div className="col-lg-6 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex">
              <h4 className="card-title">
                <span className="lstick" />
                Actividad reciente
              </h4>
            </div>
            <div className="activity-box">
              {data.map((item,index) =>
                (
                  <KNewRow  key={index}
                            new={item}
                  />
                )
              )}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

KNews.propTypes = {
  /** data object */
  data                : PropTypes.object,
}

KNews.defaultProps = {
  data                : { programs:[], companies:[], partners:[], mentors:[]  },
}

export default KNews;
