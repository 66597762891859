/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, December 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import ProgramsPicker from '../components/ProgramsPicker';
import FormInput from '../components/form/FormInput';

/**
 * Modal to add a facilitator to a program.
 */

class KAddFacilitatorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:'',
      selectedPrograms:[],
    }
    this.hideModal                     =  this.hideModal.bind(this);
    this.handleCloseModal              =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose        =  this.executeAfterModalClose.bind(this);
    this.onChangeProgram               =  this.onChangeProgram.bind(this);
    this.onChangeForm                  =  this.onChangeForm.bind(this);
    this.cleanStates                   =  this.cleanStates.bind(this);
    this.addProgramPermissions         =  this.addProgramPermissions.bind(this);
    this.onClickAddFacilitator         =  this.onClickAddFacilitator.bind(this);
    this.confirmSwal                   =  this.confirmSwal.bind(this);
    this.validateFormByEmail           =  this.validateFormByEmail.bind(this);
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.props.closeModal(false);
  }

  cleanStates(){
    this.setStates({selectedPrograms:[]});
  }

  onChangeProgram(programs){
    this.setState({selectedPrograms:programs});
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.setState({[data.model]:value});
  }

  validateFormByEmail(){
    return this.fi_email.validate();
  }

  onClickAddFacilitator(){
    let isValid = this.validateFormByEmail();

    if(isValid){
      let email = this.state.email;
      let selectedPrograms = this.state.selectedPrograms;

      if(selectedPrograms.length > 0){
        this.inviteFacilitator(email,selectedPrograms);
      }else{
        this.confirmSwal(email,selectedPrograms);
      }
    }else{
      Swal.fire({
        title: 'Error',
        html: 'Revisa que la dirección de correo electrónico sea válida.',
        type: 'warning',
        customClass: {
          title: 'swal-custom-title',
          confirmButton: 'swalBtnTeal',
        }
      });
    }
  }

  inviteFacilitator(email,selectedPrograms){
    let programs_ids = selectedPrograms.join('|');

    KomunidadApi.facilitatorPreRegister(email,programs_ids).then((res) => {
      if(res.success){
        Swal.fire({
          title: 'Invitación enviada',
          text: `El usuario ${this.state.email} ha sido invitado como director`,
          type: 'success',
          customClass: {
            title: 'swal-custom-title',
            confirmButton: 'swalBtnTeal',
          },
          onClose: () => {
            this.handleCloseModal(false);
          }
        });
      }else{
        if(res.hasOwnProperty('message_es')){
          Swal.fire({
            title: 'Error',
            text: res.message_es,
            type: 'error',
            customClass: {
              title: 'swal-custom-title',
              confirmButton: 'swalBtnTeal',
            },
          });
        }
      }
    })
  }

  confirmSwal(email,selectedPrograms){
    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: "El director solo podrá acceder a sus programas básicos.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then((result) => {
      if (result.value) {
        this.inviteFacilitator(email,selectedPrograms);
      }
    })
  }

  addProgramPermissions(user_id,selectedPrograms){
    KomunidadApi.addProgramPermissions(user_id,selectedPrograms).then((res) => {
      if(res.success){
        Swal.fire({
          title: 'Programa asignado',
          text: "El programa ha sido asignado.",
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'success',
          onClose: () => {
            this.handleCloseModal(false);
            this.props.onAddProgram();
          }
        });
      }else{
        Swal.fire({
          title: 'Error',
          text: "El programa no pudo ser asignado.",
          type: 'error',
          customClass: {
            title: 'swal-custom-title',
            confirmButton: 'swalBtnTeal',
          },
        });
      }
    });
  }


  renderContent(){
    return(
      <div className="card-body">
        <div className='form-group'>
          <FormInput
            ref={ref => this.fi_email = ref}
            id="email"
            label="Email"
            model="email"
            showCounter={false}
            value={this.state.email}
            initialValue={''}
            required={true}
            validationRules="email|required"
            onChange={ change => { this.onChangeForm(change) } }
            placeholder="email"
            errorPosition="bottom"
            />
        </div>

        <ProgramsPicker showInactivePrograms={true}
                        onChange={(program) => {this.onChangeProgram(program)}}
                        isMultiple={true}>
        </ProgramsPicker>
      </div>
    )
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              >
        <Modal.Header closeButton>
          <Modal.Title>
            Invitar director
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          <div className="btn btn-primary btn-save" onClick={() => this.onClickAddFacilitator()}>Invitar</div>
        </Modal.Footer>
      </Modal>
    );
  }
}

KAddFacilitatorModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
};

KAddFacilitatorModal.defaultProps = {
  closeModal        : () => {},
  showModal         : false,
};

export default KAddFacilitatorModal;
