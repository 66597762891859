/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import KomunidadApi from '../api/api.js';
import Tooltip from 'react-tooltip-lite';
import { renderFullNameOrEmail} from '../utils/Utils.js';
import CompanyPhasesChecker from '../utils/CompanyPhasesChecker'

//componentes
import KImage from './KImage';
import ReactStars from "react-rating-stars-component";

/** Render a the row of a list company, using the component [KCompanyList](/#kcompanylist).
*/

class KCompanyRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClickCompany = this.onClickCompany.bind(this);
    this.onRatingSelected = this.onRatingSelected.bind(this);
  }

  onClickCompany(){
    this.props.onClickCompany(this.props.company);
  }

  onRatingSelected(newRating){
    let rating = {
      rating: newRating,
      companyId: this.props.company._id
    }
    this.props.onRatingSelected(rating)
  }

  renderSharedIcon(){
    const company = this.props.company;
    let user = this.props.user;
    if(user.hasOwnProperty("_id")){
      if(company.owner_uid !== user._id.toString()){
        return (
          <div className="tooltipWrapper">
            <Tooltip content="Compartido conmigo" useDefaultStyles className="tooltipText">
              <i className="mdi mdi-account-multiple" ref={ref => this.fai = ref } style={{fontSize: '18px', color:'#939393'}}></i>
            </Tooltip>
          </div>

        );
      } else {
        return (
          <div className="tooltipWrapper">
            <Tooltip content="Eres el fundador de la empresa" useDefaultStyles className="tooltipText">
              <i className="mdi mdi-account" ref={ref => this.fai = ref } style={{fontSize: '18px', color:'#939393'}}></i>
            </Tooltip>
          </div>
        )
      }
    }else{
      return (null);
    }
  }

  isInvitationAccepted(){
    const company = this.props.company;

    if(!company.hasOwnProperty('invitation_status')){
      return true;
    }else{
      return (company.invitation_status === "accepted" ? true : false);
    }
  }

  renderCompanyPhase(company){
    return CompanyPhasesChecker.getCurrentPhaseLabel(company)
  }

  renderLink(){
    const company = this.props.company;
    let viewLabel = this.props.managerView ? 'Ver empresa' : this.props.viewLabel;
    if(this.props.isMentoringSuggestion){
      if((!this.props.shortView || this.props.managerView) && !this.props.ratingsEnabled){
        return(
          <td onClick={() => this.props.onSuggestionSelected(company)} >
            {/* <Link to={'/companies/'+company._id}> */}
              <div className="btn btn-info btn-aux text-white">{viewLabel}</div>
            {/* </Link> */}
          </td>
        )
      }else{
        return null
      }
    }else{
      if((!this.props.shortView || this.props.managerView) && !this.props.ratingsEnabled){
        return(
          <td>
            <Link to={'/companies/'+company._id}>
              <div className="btn btn-info btn-aux text-white">{viewLabel}</div>
            </Link>
          </td>
        )
      }else{
        return null
      }
    }

  }

  renderRating(){
    const company = this.props.company;
    if((!this.props.shortView || this.props.managerView) && this.props.ratingsEnabled ){
      return(
        <td>
          <ReactStars
              value={company.my_rating}
              count={5}
              onChange={this.onRatingSelected}
              size={24}
              color2={"#ffd700"}
            />
        </td>
      )
    }else{
      return null
    }
  }


  renderOnePagerBtn(){
    const company = this.props.company;

    if(!this.props.shortView){
      return(
          <td>
            <a href={KomunidadApi.API_URL+'/companies/'+company._id+'/pdf'} target={'_blank'}>
              <div className="btn btn-success btn-save">One pager</div>
            </a>
          </td>
      )
    }else{
      return null;
    }
  }

  renderReviewNumber(){
    let numberOfReview = this.props.company.unread_reviews;
    if(numberOfReview > 0 && (!this.props.shortView || this.props.managerView )){
      let txt_label = `${numberOfReview} nuevos comentarios`;
      if(numberOfReview === 1){
        txt_label = `${numberOfReview} nuevo comentario`;
      }
      return(
        <td>
          <span className="label label-light-info">{txt_label}</span>
        </td>
      )
    }else{
      return(
        <td>
        </td>
      )
    }
  }

  renderCompanyRow(company){
    return (
      <tr onClick={this.onClickCompany}>
        <td style={{width:70}}>
          <span className="d-flex mr-3">
            <KImage src={company.logoThumbnail} type='company' className='companyImage' alt="company_image"/>
          </span>
        </td>
        <td style={{maxWidth:414}}>
          <h6>{company.name}</h6>
          {this.renderSharedIcon()}&nbsp;<small className="text-muted">{this.renderCompanyPhase(company)}</small>
        </td>

        {this.renderCreator()}
        {this.renderDate(company.created_at)}
        {this.renderLink()}
        {this.renderRating()}
        {this.renderOnePagerBtn()}
      </tr>
    )
  }

  renderCompanyRowDashboard(company) {
    return (
      <tr onClick={this.onClickCompany}>
        <td style={{ width: 70 }}>
          <span className="d-flex mr-3">
            <KImage src={company.logoThumbnail} type='company' className='companyImage' alt="company_image" />
          </span>
        </td>
        <td style={{ maxWidth: 414 }}>
          <h6>{company.name}</h6>
          {this.renderSharedIcon()}&nbsp;<small className="text-muted">{this.renderCompanyPhase(company)}</small>
        </td>

        {this.renderCreator()}
        {this.renderDate(company.created_at)}
      </tr>
    )
  }


  renderInvitationCompanyRow(company){
    return (
      <tr>
        <td style={{width:70}}>
          <span className="d-flex mr-3">
            <KImage src={company.logoThumbnail} type='company' className='companyImage' alt="company_image"/>
          </span>
        </td>
        <td style={{maxWidth:414}}>
          <h6>{company.name}</h6>
          {this.renderSharedIcon()}&nbsp;<small className="text-muted">{this.renderCompanyPhase(company)}</small>
        </td>
        <td></td>
        <td>
          <Link to={'/companies/'+company._id}>
              <div className="btn btn-info btn-save">Ver Invitación</div>
          </Link>
        </td>
        <td></td>
      </tr>
    )
  }

  renderProgramName(company){
    if(company.hasOwnProperty('programs')){
      if(company.programs.length > 0){
        let program = company.programs[0].program;
        return (
          <h6>{program.name}</h6>
        )
      }else {
        return (
          <h6 style={{color: 'red'}}>Empresa sin programa</h6>
        )
      }
    }else{
      return (
        <h6 style={{color: 'red'}}>Empresa sin programa</h6>
      )
    }
  }

  renderDate(date){
    if(this.props.showDate && !this.props.shortView) {
      let moment = require('moment');
      let _date = new Date(date.replace('Z',''));

      _date = moment(_date).format('DD-MM-YYYY');

      return(
        <td>
          <h6>{_date}</h6>
        </td>
      )
    } else return null;
  }

  renderInvitationMode(company){
    return (
      <tr onClick={this.onClickCompany}>
        <td style={{width:70}}>
          <span className="d-flex mr-3">
            <KImage src={company.logoThumbnail} type='company' className='companyImage' alt="company_image"/>
          </span>
        </td>
        <td style={{maxWidth:414}}>
          <h6>{company.name}</h6>
          {this.renderSharedIcon()}&nbsp;<small className="text-muted">{this.renderCompanyPhase(company)}</small>
        </td>
        <td>
          {this.renderProgramName(company)}
        </td>
          {this.renderDate(company.created_at)}
        <td>
          <Link to={'/companies/'+company._id}>
              <div className="btn btn-info btn-save">Ver Invitación</div>
          </Link>
        </td>
      </tr>
    )
  }

  renderCreator(){
    const company = this.props.company;
    const owner = company.hasOwnProperty('owner_id') ? company.owner_id : undefined;

    if(this.props.showCreator){
      return(
          <td>
            <small>
              <span className="text-muted">Fundador:</span>
              <br/>
              {renderFullNameOrEmail(owner)}
            </small>
          </td>
      )
    }else{
      return null;
    }
  }




  render() {
    const company = this.props.company;
    const invitationMode = this.props.invitationMode;

    if(!invitationMode){
      if(this.isInvitationAccepted() || (this.props.managerView)){
          // Render if the user is Manager and is in the dashboard view
        if (this.props.managerDashboardView){
          return this.renderCompanyRowDashboard(company);
        } else {
          //Render the company information if the invitation is accepted just if the invitation mode is off
          return this.renderCompanyRow(company);
        }
      }else{
        //Render the invitation to a company
        return this.renderInvitationCompanyRow(company);
      }
    }else{
      //Render the invitacion information
      return this.renderInvitationMode(company);
    }
  }
}

KCompanyRow.propTypes = {
  /** Company object /*/
  company            : PropTypes.object,

  /** User object*/
  user               : PropTypes.object,

  /** When is true, the row only displays the main information of a company.
  Image, name and link */
  shortView          : PropTypes.bool,

  /** Callback when the company is clicked*/
  onClickCompany     : PropTypes.func,

  /** Callback when the ratig is selected*/
  onRatingSelected   : PropTypes.func,

  /** Label of the link*/
  viewLabel          : PropTypes.string,

  /** A list view if the user is a manager (display the button 'Comentar'),
  TO DO:Check if this can be changed by the prop shortView*/
  mentoringStatus    : PropTypes.bool,

  /**Row view if the user is a manager,
  TO DO:Check if this can be changed by the prop shortView*/
  managerView        : PropTypes.bool,

  /**Row view if the user is a manager and is the dashboard view*/
  managerDashboardView: PropTypes.bool,

  /** When is true display the rating option */
  ratingsEnabled     : PropTypes.bool,

  /** Show if the company has invitations (display the button 'Ver invitacion')*/
  invitationMode     : PropTypes.bool,
  
  /** Display the list of suggestions to be a mentor of the program.*/
  isMentoringSuggestion : PropTypes.bool,

  /** Callback when the suggestion is selected*/
  onSuggestionSelected   : PropTypes.func,

  /** Show created at date*/
  showDate           : PropTypes.bool,
};

KCompanyRow.defaultProps = {
  company               : {},
  user                  : {},
  shortView             : false,
  onClickCompany        : () => {},
  onRatingSelected      : () => {},
  viewLabel             : "Ver / Editar",
  mentoringStatus       : false,
  managerView           : false,
  managerDashboardView  : false,
  ratingsEnabled        : false,
  invitationMode        : false,
  isMentoringSuggestion : false,
  onSuggestionSelected  : () => {},
  showDate              : true,
  showCreator           : false,

};

export default KCompanyRow;
