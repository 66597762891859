/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, March 2020
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Marker } from 'react-map-gl';

//components


/**
 *  Render a marker for map box. The marker has an offset top:-32 and left:-12.
 */


class KMarker extends React.Component {
  constructor(props) {
    super(props);
    this.onDragEnd    = this.onDragEnd.bind(this);
  }

  onDragEnd(e){
    this.props.onDragEnd(e);
  }

  renderMarkerLabel(){
    if(this.props.label !== ''){
      return <span className='marker-label'>{this.props.label}</span>
    }else{
      return null
    }
  }

  render() {
    return(
      <Marker latitude={this.props.latitude}
              longitude={this.props.longitude}
              offsetTop={-32}
              offsetLeft={-12}
              draggable={this.props.draggable}
              onDragEnd={(e) => this.props.onDragEnd(e) }>
              <div className='marker-wrapper'>
                <FontAwesomeIcon icon={this.props.icon}
                                 color={this.props.color}
                                 size={this.props.size}
                                 onClick={() => this.props.onClickMarker()}>
                </FontAwesomeIcon>
                {this.renderMarkerLabel()}
              </div>
      </Marker>
    )
  }
}

KMarker.propTypes = {
  /** Latitude (position of the marker)*/
  latitude         : PropTypes.number,
  /** Longitude (position of the marker)*/
  longitude        : PropTypes.number,
  /** Allow draggable */
  draggable        : PropTypes.bool,
  /** Callback on drag end*/
  onDragEnd        : PropTypes.func,
  /** Callback on click marker*/
  onClickMarker    : PropTypes.func,
  /** Name of the icon (font awesome icon)*/
  icon             : PropTypes.string,
  /** Color of the icon (font awesome icon)*/
  color            : PropTypes.string,
  /** Size of the icon (font awesome icon)*/
  size             : PropTypes.string,
  /** Marker label */
  label            : PropTypes.string,
};

KMarker.defaultProps = {
  latitude         : null,
  longitude        : null,
  draggable        : false,
  onDragEnd        : () => {},
  onClickMarker    : () => {},
  icon             : 'map-marker-alt',
  color            : 'red',
  size             : '2x',
  label            : '',
};

export default KMarker;
