import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/** Pick one or multiple phases.
*   Returns an array of strings of the selected phases
*/


class KPhasesPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phases:[
        {
          value:'all',
          label:'Todas',
        },
        {
          value:'idea',
          label:'Idea'
        },
        {
          value:'prototype',
          label:'Prototipo'
        },
        {
          value:'startup',
          label:'Startup'
        },
        {
          value:'pyme',
          label:'PyME'
        },
        {
          value:'scaleup',
          label:'Scaleup',
        }
      ],
      selectedPhases:[]
    }
    this.phases = [
        {
          value:'all',
          label:'Todas',
        },
        {
          value:'idea',
          label:'Idea'
        },
        {
          value:'prototype',
          label:'Prototipo'
        },
        {
          value:'startup',
          label:'Startup'
        },
        {
          value:'pyme',
          label:'PyME'
        },
        {
          value:'scaleup',
          label:'Scaleup',
        },
      ];
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
    this.handleSingleSelect     = this.handleSingleSelect.bind(this);
    this.cancel                 = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(props.selectedPhases !== "" && state.selectedPhases !== null && state.selectedPhases.length === 0){
      let selectedPhasesArray = props.selectedPhases.slice("|");
      let newSelectedPhases = state.phases.filter( p => selectedPhasesArray.includes(p.value));
      return {
        selectedPhases: newSelectedPhases
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount(){
  }

  handleMultipleSelect(selectedOptions){
    let phases = this.phases.slice();
    if(selectedOptions !== null){
      if(selectedOptions.length > 0){
        phases = phases.filter((p) => p.value !== 'all');
        if(selectedOptions[0].value === "all"){
          phases = phases.map((p) => {p.isDisabled = true; return p});
        }
      }
    }else{
      phases = phases.map((p) => {p.isDisabled = false; return p});
    }
    this.setState({selectedPhases:selectedOptions,phases:phases});
    this.props.onChange(selectedOptions);
  }

  handleSingleSelect(selectedOptions){
    let phases = this.phases.slice();
    let selected = [];
    selected.push(selectedOptions);
    this.setState({selectedPhases: selected, phases: phases});
    this.props.onChange(selected);
  }

  cancel(){
    this.setState({
      selectedPhases : [],
    })
  }

  renderSelect(){
    if(this.props.isMultiple){
      return(
        <Select
          options={this.state.phases}
          value={this.state.selectedPhases}
          onChange={this.handleMultipleSelect}
          menuPosition={'fixed'}
          isMulti={true}
          placeholder={"Seleccionar"}
        />
      )
    } else {
      return(
        <Select
          options={this.state.phases}
          value={this.state.selectedPhases}
          onChange={this.handleSingleSelect}
          menuPosition={'fixed'}
          isMulti={false}
          placeholder={"Seleccionar"}
        />
      )
    }
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>Etapa</label>
      )
    }else{
      return null;
    }
  }

  render(){
    return(
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

KPhasesPicker.propTypes = {
  /** Callback on program selected, returns the id or an array of ids. */
  onChange               : PropTypes.func,
  /** When is true show the label 'Programas'. */
  showLabel              : PropTypes.bool,
  /** When is true allow to select multiple programs */
  isMultiple             : PropTypes.bool,
  /**Selected phases */
  selectedPhases         : PropTypes.string,
}
KPhasesPicker.defaultProps = {
  onChange                : (phases) => {},
  showLabel               : true,
  isMultiple              : true,
  selectedPhases          : '',
}

export default KPhasesPicker;
