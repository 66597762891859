/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
//components
import KImage from '../components/KImage';

/**
*   Display the information of the partner used by the component [KPartnersList](/#kpartnerslist).
*   Also display the button to delete a partner.
*/

class KPartnersRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClickDeletePartner = this.onClickDeletePartner.bind(this)
  }

  onClickDeletePartner(partner){
    this.props.onClickDeletePartner(partner);
  }

  renderNameOrEmail(partner){
    if(partner.name === null){
      return partner.email;
    }else{
      let fullName = partner.name;

      if(partner.last_name_father !== null ){
        fullName = fullName + ' ' + partner.last_name_father;
      }
      if(partner.last_name_mother !== null){
        fullName = fullName + ' ' + partner.last_name_mother;
      }
      return fullName;
    }
  }

  renderLinkein(partner){
    if(partner.social_linkedin !== null && partner.social_linkedin !== '' && partner.social_linkedin !== undefined){
      return(
        <a href={partner.social_linkedin} target="_blank" rel="noopener noreferrer">
          <div className='btn btn-secondary'>
            <i className="mdi mdi-linkedin-box" style={{fontSize: '18px'}}></i>
          </div>
        </a>
      )
    }else{
      return null
    }
  }
  renderEmail(partner){
    if(!this.props.showEmail){
      return null
    }else if(partner.email !== null && partner.email !== '' && partner.email !== undefined ){
      return(
        <a href={`mailto:${partner.email}`} target="_blank" rel="noopener noreferrer">
          <div className='btn btn-secondary'>
            <i className="mdi mdi-email" style={{fontSize: '18px'}}></i>
          </div>
        </a>
      )
    }else{
      return null
    }
  }
  renderWhatsapp(partner){
    const WHATSAPP_LINK = `https://web.whatsapp.com/send?phone=+521${partner.phone}`;
    if(!this.props.showWhatsapp){
      return null
    }else if(partner.phone !== null && partner.phone !== '' && partner.phone !== undefined){
      return(
        <a href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer">
          <div className='btn btn-secondary'>
            <i className="mdi mdi-whatsapp" style={{fontSize: '18px'}}></i>
          </div>
        </a>
      )
    }else{
      return null
    }
  }

  renderInvitationStatus(status){
    switch (status) {
      case "invited_by_email":
        return " (Invitado, pendiende de crear cuenta)";
      case "invited":
        return " (Invitado, pendiente de confirmación)";
      default:
      return ""
    }
  }

  renderDeleteButton(partner){
    if(this.props.isUserOwner){
      if(partner.is_owner){
        return (
          <div></div>
        );
      }else{
        return (
          <div className="btn btn-danger" onClick={() => this.onClickDeletePartner(partner)}>Borrar socio</div>
        );
      }
    }else{
      return (
        <div></div>
      );
    }
  }

  render() {
    let emptyUser = {
      name:'Usuario no encontrado',
      email:'Usuario no encontrado',
      gender:'',
      last_name_father:null,
      last_name_mother:null,
      social_linkedin:null,
    };
    const partner = this.props.partner;
    if(this.props.partner.status === "invited_by_email"){
      emptyUser.name = this.props.partner.invited_email;
    }
    const user    = this.props.partner.user !== null ? this.props.partner.user : emptyUser;

    return(
      <tr>
          <td style={{width:50}}>
            <span className="round roundTransparent">
              <KImage src={user.profileUrlThumbnail} className={"kuImage"} type={'user_'+user.gender} alt={"user_img"}/>
            </span>
          </td>
          <td>
            <h6>{this.renderNameOrEmail(user)}</h6>
            <small className="text-muted">{partner.position} {this.renderInvitationStatus(partner.status)}</small>
          </td>
          <td className="contact_links">
            {this.renderLinkein(user)}
            {this.renderEmail(user)}
            {this.renderWhatsapp(user)}

          </td>
          <td>
            {this.renderDeleteButton(partner)}
          </td>
      </tr>
    )
  }
}

KPartnersRow.propTypes = {
  /** Partner to be render */
  partner              : PropTypes.object,
  /** Callback on click the button delete partner*/
  onClickDeletePartner : PropTypes.func,
  /** When is true the user is owner*/
  isUserOwner          : PropTypes.bool,
  showEmail            : false,
  showWhatsapp         : false

};

KPartnersRow.defaultProps = {
  partner              : {},
  onClickDeletePartner : ()=>{},
  isUserOwner          : false,
  showEmail            : true,
  showWhatsapp         : true
};

export default KPartnersRow;
