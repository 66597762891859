/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>,March 2020
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete} from '../../utils/Utils.js';

//Style
import '../../scss/pages/_style.css';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';

//certificates
import Cert1 from '../../assets/images/certificate/diploma1.png';
import Cert2 from '../../assets/images/certificate/diploma2.png';
import Cert3 from '../../assets/images/certificate/diploma3.png';
import Cert4 from '../../assets/images/certificate/diploma4.png';

//Data
import TCert1 from '../../data/cert1.json';
import TCert2 from '../../data/cert2.json';
import TCert3 from '../../data/cert3.json';
import TCert4 from '../../data/cert4.json';
import Swal from 'sweetalert2';



class CertifacateView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      companies:[],
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      loading:false,
      savedCertificates:[]
    }
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.isAuthorized                    = this.isAuthorized.bind(this);
    this.navigateToEditor                = this.navigateToEditor.bind(this);
    this.openSavedTemplate               = this.openSavedTemplate.bind(this);
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
  }

  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator") 
    )
  }
  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
              loading:true
            });
          }else{
            this.redirectToProfile();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    KomunidadApi.getCertificates().then( (res) => {
      if(res.success){
        this.setState({
          savedCertificates:res.certificates
        })
      }else{
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToProfile(){
    this.props.history.push('/profile');
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  navigateToEditor(cert){
    this.props.history.push({
             pathname:"/certificate_editor",
             state:{
                 template:cert
              }
            });
  }
  openSavedTemplate(template){
    KomunidadApi.getCertificateById(template._id).then( (res) => {
      let t = JSON.parse(res.certificate.template);
      if(res.success){
        this.props.history.push({
                 pathname:"/certificate_editor",
                 state:{
                     template:t
                  }
                });
      }else{
        Swal.fire({
          title: 'Error',
          html: 'Hubo un error al abrir el certificado',
          type: 'warning',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });


  }

  renderTemplates(){
    return(
      <div className='card-body'>
        <h5>Selecciona la plantilla que deseas crear</h5>

        <div className='row m-t-20'>
          <div className='col-lg-3 col-md-6 m-b-20'>
            <img className='img-responsive img-template'
                 src={Cert1}
                 onClick={() => this.navigateToEditor(TCert1)}
                 alt='template'/>
          </div>
          <div className='col-lg-3 col-md-6 m-b-20'>
            <img className='img-responsive img-template'
                 src={Cert2}
                 onClick={() => this.navigateToEditor(TCert2)}
                 alt='template'/>
          </div>
          <div className='col-lg-3 col-md-6 m-b-20'>
            <img className='img-responsive img-template'
                 src={Cert3}
                 onClick={() => this.navigateToEditor(TCert3)}
                 alt='template'/>
          </div>
          <div className='col-lg-3 col-md-6 m-b-20'>
            <img className='img-responsive img-template'
                 onClick={() => this.navigateToEditor(TCert4)}
                 src={Cert4}
                 alt='template'/>
          </div>
        </div>
      </div>
    )
  }
  renderSavedCertificates(){
    return (
      <div className='card-body'>
        <h5>Instrucciones para emitir certificado con plantillas creadas</h5>

        {/* <div className='row m-t-20'>
          {
            this.state.savedCertificates.map( cert => {
                return(
                  <div className='col-lg-3 col-md-6 m-b-20'>
                    <img
                      className='img-responsive img-template'
                      src={cert.thumbnail}
                      alt='template'
                      onClick={() => this.openSavedTemplate(cert)}
                      />
                      <div>{cert.name}</div>
                  </div>
                )
            })
          }
        </div> */}

        <div className='m-t-20'>
          1. Dirígete a la sección de Programas<br/>
          2. Selecciona el programa al cual quieres emitir o enviar el certificado<br/>
          3. Selecciona el ícono de certificado para ver las opciones<br/>
          4. Puedes descargarlos o enviarlos por correo a los participantes del programa<br/>
        </div>

      </div>
    )
  }


  render () {
    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Certificados</h3>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Columns */}
                  <div className="col-12 col-lg-12 col-xlg-12">
                    <div className='card'>
                      {this.renderTemplates()}
                    </div>
                  </div>
                  {/* End columns */}
                </div>
                {/* Row */}
                <div className="row">
                  {/* Columns */}
                  <div className="col-12 col-lg-12 col-xlg-12">
                    <div className='card'>
                      {this.renderSavedCertificates()}
                    </div>
                  </div>
                  {/* End columns */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default CertifacateView;
