/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, March 2021
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/** Pick one chapter.
*   Returns an object of the selected chapter
*/


class KChaptersPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chapters:[],
      multipleOptions:[],
      selectedChapter:[]
    }
    this.handleSingleSelect     = this.handleSingleSelect.bind(this);
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.cancel                 = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.multipleOptions !== null){
      if ((state.multipleOptions.length === 0)){
        return {
          chapters: props.chapters
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }
  componentDidUpdate(prevProps){
      if(prevProps.chapters !== this.state.chapters){
          this.setMultipleOptions(this.state.chapters)
      }
  }
  componentDidMount(){
  }

  setMultipleOptions(chapters){
    let options = chapters.map((chapter, index) => ({ value: index, id: chapter._id, label: chapter.name, description: chapter.description, url: chapter.url}));
    this.setState({multipleOptions:options});
  }

  handleSingleSelect(selectedOptions){
    this.setState({selectedChapter: selectedOptions});
    this.props.onChange(selectedOptions);
  }

  cancel(){
    this.setState({
      selectedChapter : [],
    })
  }

  renderSelect(){
      return(
        <Select
          options={this.state.multipleOptions}
          value={this.state.selectedChapter}
          onChange={this.handleSingleSelect}
          menuPosition={'fixed'}
          isMulti={false}
          placeholder={"Selecciona el módulo para editar"}
        />
      )
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>Editar módulo</label>
      )
    }else{
      return null;
    }
  }

  render(){
    return(
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

KChaptersPicker.propTypes = {
  /** Callback on program selected, returns the id or an array of ids. */
  onChange               : PropTypes.func,
  /** When is true show the label 'Programas'. */
  showLabel              : PropTypes.bool,
  /** Array of chapters to show in the select */
  chapters               : PropTypes.array,
}
KChaptersPicker.defaultProps = {
  onChange                : (chapters) => {},
  showLabel               : true,
  chapters                : [],
}

export default KChaptersPicker;
