/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, August 2019
 * Updated on 05-March-2020 by Darien Miranda <darien@startuplab.mx>
 * Major review 06-April-2020 by Darien Miranda <darien@startuplab.mx>
 * and America Mendoza <america@startuplab.mx>
 */

var isFilled = function(v){
  if(v === null || v === undefined){
    return false;
  }else{
    if( v.toString().trim() === ""){
      return false;
    }else{
      return true;
    }
  }
}
var CompanyPhasesChecker ={

  getCurrentPhaseLabel(company){
    let _c = CompanyPhasesChecker.check(company);
    /* Checks the phase a company is at.
     * @returns a string Scaleup, PyMe, Startup, Prototipo or Idea.
     */
    if(_c.isIdeaComplete && _c.isPrototypeComplete && _c.isStartupComplete && _c.isPymeComplete){
      return "Scaleup";
    }
    else if(_c.isIdeaComplete && _c.isPrototypeComplete && _c.isStartupComplete){
      return "PyME";
    }
    else if(_c.isIdeaComplete && _c.isPrototypeComplete){
      return "Startup";
    }
    else if(_c.isIdeaComplete){
      return "Prototipo";
    }
    else{
      return "Idea";
    }
  },
  /* Checks the phase a company is at.
   * @returns object {isIdea:boolean,isPrototype:boolean,isStartup:boolean,isPyme:boolean
   *                  isScaleUp:boolean}
   */
  check(company){
    return {
      isIdea:true, //always returns true
      isIdeaComplete:CompanyPhasesChecker.isIdeaComplete(company), //returns true when all the fields @idea are complete
      isPrototype:CompanyPhasesChecker.isIdeaComplete(company), //returns true when all the fields @idea are complete
      isPrototypeComplete:CompanyPhasesChecker.isPrototypeComplete(company), //returns true when all the fields @prototype are complete
      isStartup:CompanyPhasesChecker.isPrototypeComplete(company),//returns true when all the fields @prototype are complete
      isStartupComplete:CompanyPhasesChecker.isStartupComplete(company),//returns true when all the fields @startup are complete
      isPyme:CompanyPhasesChecker.isStartupComplete(company),//returns true when all the fields @startup are complete
      isPymeComplete:CompanyPhasesChecker.isPymeComplete(company),//returns true when all the fields @Pyme are complete
      isScaleUp:CompanyPhasesChecker.isPymeComplete(company),//returns true when all the fields @Pyme are complete
      isScaleUpComplete:CompanyPhasesChecker.isScaleUpComplete(company) //returns true when all the fields @scaleup are complete
    }
  },
  isIdeaComplete(company){
    //when all the data from idea is filled, the proyect is now a prototype
    return ( isFilled(company.name)  && isFilled(company.quote) &&
            isFilled(company.problemToSolve) &&
            isFilled(company.coordinates)
    )
  },
  isPrototypeComplete(company){
    //when all the data from prototype is filled, the proyect is now a startup
    return (isFilled(company.website) && isFilled(company.video_url) &&
            isFilled(company.pdf_file) && isFilled(company.industry_sector)
    )
  },
  isStartupComplete(company){
    //when all the data from startup is filled, the proyect is now a PyME
    return (
            isFilled(company.logo) && isFilled(company.dateOfEstablishment) &&
            isFilled(company.market) &&
            isFilled(company.business_model) &&
            isFilled(company.achievements) &&
            isFilled(company.pitch_file)
          )
  },
  isPymeComplete(company){
    //when all the data from PyME is filled, the proyect is now a Scaleup
    return (
            isFilled(company.goverment_id_rfc) &&
            isFilled(company.goverment_legal_name) &&
            isFilled(company.phone) &&
            isFilled(company.stats_sales) &&
            isFilled(company.gathered_capital) &&
            isFilled(company.net_spending)
    );
  },
  isScaleUpComplete(company){
    //In this phase, the proyect is still Scaleup but all the data in this phase
    //is complete
    return (
      isFilled(company.pre_capital_value) &&
      isFilled(company.seeked_capital)
    )
  },
  /* Checks the phase a company is at from an object of each phase.
   * @returns a string Scaleup, PyMe, Startup, Prototipo or Idea.
   */
  getPhaseLabelFromObject(phase){
    if(phase.isIdeaComplete && phase.isPrototypeComplete && phase.isStartupComplete && phase.isPymeComplete){
      return "Scaleup";
    }
    else if(phase.isIdeaComplete && phase.isPrototypeComplete && phase.isStartupComplete){
      return "PyME";
    }
    else if(phase.isIdeaComplete && phase.isPrototypeComplete ){
      return "Startup";
    }
    else if(phase.isIdeaComplete ){
      return "Prototipo";
    }
    else{
      return "Idea";
    }
  },

}

module.exports = CompanyPhasesChecker;
