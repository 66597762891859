/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Junuary 2020
 */

//imports
import React from 'react';
import PropTypes from 'prop-types';
import SlidingPanel from 'react-sliding-side-panel';

import { renderFullNameOrEmail} from '../utils/Utils.js';

//style
import '../scss/components/_profile_sidebar.scss';

//components
import KImage from '../components/KImage';
import FormInput from "./form/FormInput";
const validUrl = require('valid-url');
/**
*   Display the partner/mentor profile information and his companies.
*   The component call the api to get the user info and companies.
*/

class KProfileSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size:30,
      phases:[
        {
          label:'Idea',
          value:'idea'
        },
        {
          label:'Prototipo',
          value:'prototype'
        },
        {
          label:'Startup',
          value:'startup'
        },
        {
          label:'MiPyME',
          value:'mipyme'
        },
      ],
    }
    this.handleCloseSidebar          =  this.handleCloseSidebar.bind(this);
    this.executeAfterSidebarClose    =  this.executeAfterSidebarClose.bind(this);
    this.updateDimensions            =  this.updateDimensions.bind(this);

  }

  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  updateDimensions(){
    var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    if(width <= 576){
      this.setState({size:90});
    }else if(width >= 576 && width <= 768){
      this.setState({size:50});
    }else{
      this.setState({size:30});
    }
  }


  handleCloseSidebar(show){
    this.props.closeSidebar(show);
  }

  executeAfterSidebarClose(){
    this.setState({user:{},companies:[],loadState:'loading'});
  }

  renderSpeciality(mentor){
    let fullMentorSpecialitity = '';
    if(mentor === undefined || mentor === null){
      return null;
    }
    if(mentor.hasOwnProperty('mentor_speciality_sector')){
      if(mentor.mentor_speciality_sector !== null){
        for (const [key] in mentor.mentor_speciality_sector){
          fullMentorSpecialitity += mentor.mentor_speciality_sector[key] + ", ";
        }
      }if (mentor.mentor_speciality_sector_other !== null){
          if (mentor.mentor_speciality_sector_other !== "") {
            fullMentorSpecialitity += mentor.mentor_speciality_sector_other + ", ";
          }
      }if(fullMentorSpecialitity === ''){
        fullMentorSpecialitity = '—';
      }else{
        fullMentorSpecialitity = fullMentorSpecialitity.replace(/..$/,".");
      }

      return fullMentorSpecialitity;
    }else{
      return null
    }
  }

  renderBio(mentor){
    if(mentor === null || mentor === undefined){
      return null;
    }
    if(mentor.hasOwnProperty('mentor_biography')){
      if(mentor.mentor_biography !== null ){
        return (
          <div className="card-body p-t-0">
            <div><hr/></div>
            {mentor.mentor_biography}
          </div>
        )
      }else{
        return null;
      }
    }else{
      return null
    }
  }

  renderWhatsApp() {
    if(this.props.mentor === null || this.props.mentor === undefined){
      return null;
    }
    if(this.props.mentor.hasOwnProperty("phone") && this.props.isMyMentor){
      if((this.props.mentor.phone !== '') && (this.props.mentor.phone !== null) ){
        //whatsapp variable
        const WHATSAPP_LINK = "https://web.whatsapp.com/send?phone=521"+ this.props.mentor.phone;
        return (
          <a className='btn-link p-0' target="_blank" rel="noopener noreferrer" href={WHATSAPP_LINK} style={{float: 'inline-end'}}>
            Enviar whatsapp
          </a>
        )
      }
    }else{
      return null
    }
  }
  renderPromotedPrograms(user){
      const isUrl1Valid = validUrl.isUri(user?.mentorPromotedProgramURL1);
      const isUrl2Valid = validUrl.isUri(user?.mentorPromotedProgramURL2);
      return (
          <div className='card-body'>
            <h5 className="card-title font-bold"><span className="lstick"></span>Programas disponibles</h5>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Nombre"
                  editMode={false}
                  value={user?.mentorPromotedProgramName1}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Descripción"
                  editMode={false}
                  value={user?.mentorPromotedProgramDescription1}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
                {
                    isUrl1Valid ?
                        <>
                            <div><b>Dirección URL</b></div>
                            <a href={user?.mentorPromotedProgramURL1}>{user?.mentorPromotedProgramURL1}</a>
                        </> :
                        <FormInput
                            label="Dirección URL"
                            editMode={false}
                            value={user?.mentorPromotedProgramURL1}
                            emptyValueLabel={'—'}
                        />
                }
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Nombre"
                  editMode={false}
                  value={user?.mentorPromotedProgramName2}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Descripción"
                  editMode={false}
                  value={user?.mentorPromotedProgramDescription2}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
                <div className="form-group form-horizontal">
                    {
                        isUrl2Valid ?
                            <>
                                <div><b>Dirección URL</b></div>
                                <a href={user?.mentorPromotedProgramURL2}>{user?.mentorPromotedProgramURL2}</a>
                            </>:
                            <FormInput
                                label="Dirección URL"
                                editMode={false}
                                value={user?.mentorPromotedProgramURL2}
                                emptyValueLabel={'—'}
                            />
                    }
                </div>
            </div>
          </div>
      );
  }
  renderUserMentorIsCompany(user) {
    if (user?.mentorIsCompany) {
      return (
          <div className='card-body'>
            <h5 className="card-title font-bold"><span className="lstick"></span>Información de la empresa</h5>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Nombre"
                  editMode={false}
                  value={user?.mentorCompanyName}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Sitio web"
                  editMode={false}
                  value={user?.mentorCompanyWebsite}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Dirección"
                  editMode={false}
                  value={user?.mentorCompanyAddress}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="Nombre de contacto"
                  editMode={false}
                  value={user?.mentorCompanyContactName}
                  emptyValueLabel={'—'}
              />
            </div>

            <div className="form-group form-horizontal">
              <FormInput
                  label="Teléfono"
                  editMode={false}
                  value={user?.mentorCompanyPhone}
                  emptyValueLabel={'—'}
              />
            </div>
            <div className="form-group form-horizontal">
              <FormInput
                  label="E-mail de la empresa"
                  editMode={false}
                  value={user?.mentorCompanyEmail}
                  emptyValueLabel={'—'}
              />
            </div>
            <div><b>Programas para el 2024</b></div>
            <div className="form-group form-horizontal">
              {
                user.mentorCompanyPrograms.map((program) =>{
                  return <li key={program}>{program}</li>
                })
              }
            </div>
            <div><b>Le interesan proyectos que se encuentran en las siguientes etapas:</b></div>
            <div className="form-group form-horizontal">
              {
                user.mentorCompanyPhases.map((p) => this.state.phases.find((_p) => p === _p.value)).map((phase) =>{
                  return <li key={phase.key}>{phase.label}</li>
                })
              }
            </div>

          </div>
      )
    }
  }
  renderContent(){
    const user = this.props.mentor;
    let gender = null;
    let profileUrlThumbnail = null;
    let email = '';
    let phone = '';
    if(user !== null && user !== undefined){
      if(user.hasOwnProperty("gender")){
        gender = `user_${user.gender}`;
      }
      if(user.hasOwnProperty("profileUrlThumbnail")){
        profileUrlThumbnail = user.profileUrlThumbnail;
      }
      if(user.hasOwnProperty("email")){
        email = user.email;
      }
      if(user.hasOwnProperty("phone")){
        phone = user.phone;
      }
    }
    return(
      <div className="card">
        <div className='close-btn' onClick={() => this.handleCloseSidebar(false)} style={{cursor: 'pointer'}}>
          <i className="mdi mdi-close" style={{fontSize: '20px', color: '#67757c'}}></i>
        </div>

        <div className="card-body p-b-0">
          <center>
            <KImage src={profileUrlThumbnail} className="img-circle" alt="user_img" type={gender}/>
            <h4 className="card-title m-t-10 font-bold">{renderFullNameOrEmail(user)}</h4>
            <h6 className="card-subtitle">{this.renderSpeciality(user)}</h6>
          </center>
          <small className="text-muted">Email</small>
          <h6>{email}</h6>
          { this.renderUserMentorIsCompany(user)}
          { this.renderPromotedPrograms(user)}

        </div>
        { this.renderBio(user) }
      </div>
    )
  }

  render() {
    return (
      <SlidingPanel
        type={'right'}
        isOpen={this.props.showSidebar}
        size={this.state.size}
        backdropClicked={() => this.handleCloseSidebar(false)}
        onClose={() => this.executeAfterSidebarClose()}>
        <div className='panel-container'>
          {this.renderContent()}
        </div>
      </SlidingPanel>
    );
  }
}

KProfileSidebar.propTypes = {
  /** Close the sidebar. */
  closeSidebar       : PropTypes.func,

  /** When true the sidebar will show itself. */
  showSidebar        : PropTypes.bool,

  /** The active key of the tab. */
  activeKey          : PropTypes.string,

  /** User object (mentor) to be display. */
  mentor             : PropTypes.object,

  /** True when the user is watching his mentors */
  isMyMentor         : PropTypes.bool
};

KProfileSidebar.defaultProps = {
  closeSidebar      : () => {},
  showSidebar       : false,
  activeKey         : 'profile',
  mentor            : {},
  isMyMentor        : true
};

export default KProfileSidebar;
