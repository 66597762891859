/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';

//components
import KMentorsGridRow from './KMentorsGridRow';


/**
*  Show a list of mentors as a grid.
*  Render the mentor using the component [KMentorsGridRow](/#kmentorsgridrow)
*/

class KMentorsGrid extends React.Component {

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al mostrar los aliados</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderEmptyList(){
    return(
      <div className="text-center">
        <h3>Aún no hay aliados registrados</h3>
        <i className="mdi mdi-account-star-variant m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
      </div>
    )
  }

  render() {
    const mentors = this.props.mentors;
    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(mentors.length > 0){
        return(
          <div className='row'>
              {mentors.map((item,index) =>
                (
                  <KMentorsGridRow key={index}
                                   mentor={item}
                                   programId={this.props.programId}
                                   onClickMentor={(mentor) => this.props.onClickMentor(mentor)}
                                   isMyMentor={this.props.isMyMentor}
                  />
                )
              )}
          </div>
        )
      }else{
        return this.renderEmptyList()
      }
    }else{
      return this.renderFailed()
    }
  }
}

KMentorsGrid.propTypes = {
  /** List of mentors */
  mentors             : PropTypes.array,

  /** Status of the loading data: idle, fetching, success and failed*/
  requestStatus       : PropTypes.string, //idle,fetching,success,failed
  programId           : PropTypes.string,
  isMyMentor          : PropTypes.bool
};

KMentorsGrid.defaultProps = {
  mentors             : [],
  requestStatus       : 'idle',
  programId           : '',
  isMyMentor          : true
};

export default KMentorsGrid;
