/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx>, August 2019
 */

//Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
//TODO:Delete validator and use forminput instead
import Validator from '../../validations/resetpwd.js'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//Images and css
import './PasswordView.css';
import Logo from "../../assets/images/komunidad.svg";

const MySwal = withReactContent(Swal);

class PasswordView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			pwdConfirm:'',
			renderContent: null, //POSSIBLE VALUES: null, create_password_view wrong_prc_view
			data: null,
			hasInvitation:false,
			invited_by:{
				name:null,
				email:null
			},
			disabled:true,
			errorMsg:'',
			pwdEquals:false
		};

		this.passwordCheck = {
      isNumber: false,
      isLetter: false,
      isUpperLetter: false,
      sixCharacters: false,
      isEqual: false
    }

		this.componentDidMount 			= this.componentDidMount.bind(this);
		this.formData								= this.formData.bind(this);
		this.getPasswordCode 				= this.getPasswordCode.bind(this);
		this.comparePrc 						= this.comparePrc.bind(this);
		this.createPassword 				= this.createPassword.bind(this);
		this.validatePassword 			= this.validatePassword.bind(this);
		this.checkPassword 				  = this.checkPassword.bind(this);
		this.clearErrorMsg 				  = this.clearErrorMsg.bind(this);
		this.pwdConfirmRef					= React.createRef();
	}

	componentDidMount() {
		this.getPasswordCode();
	}

	formData(event) {
		this.setState({
			[event.target.id]:event.target.value,
			errorMsg:""
		}, () => {
			this.isPasswordEqual();
		});
	}

	getPasswordCode() {
		var prc = this.props.match.params.prc;
		this.comparePrc(prc);
	}

	comparePrc(prc) {
		KomunidadApi.getPrc(prc).then( (res) => {
			if(res.hasOwnProperty("pre_register_code")) { //TODO: change this later
				// alert(JSON.stringify(res));
				this.setState({
					data:res,
					renderContent:"create_password_view"
				});
				//Focus password input
				this.password.focus();
			}else{
				this.setState({
					renderContent:"wrong_prc_view"
				})
			}
			if(res.hasOwnProperty("invitation")){
				this.setState({hasInvitation:true,invited_by:res.invitation.invited_by});
			}
    });
	}

	renderWelcomeMsg(){
		if(this.state.hasInvitation){
			const invited_by = this.state.invited_by;

			return(
				<p className={'pvJustifyText'}>
                    Hola, bienvenido a CREO MX.
					Tú socio <b>{invited_by.name}</b> ({invited_by.email}) te ha invitado a ser parte de CREO MX<br/>
					Con esta plataforma podrás acceder a los contenidos virtuales de CREO MX. Mientras tanto te invitamos a que llenes tu perfil y subas la información de tu empresa o empresa para poder recibir asesoría y retroalimentación de aliados sin costo.<br/>
				</p>
			)
		}else{
			return(
				<p className={'pvJustifyText'}>
					Hola, bienvenido a CREO MX.
					Con esta plataforma podrás acceder a los contenidos virtuales de CREO MX. Mientras tanto te invitamos a que llenes tu perfil y subas la información de tu empresa o empresa para poder recibir asesoría y retroalimentación de aliados sin costo
				</p>
			)
		}
	}

	renderTitle(){
		if(this.state.hasInvitation){
			return 'Bienvenido a CREO MX';
		}else{
			return 'Gracias por validar tu correo';
		}
	}

	renderIsNumberHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"30px"
		}
		let iconStyle = {
			color:"#3ccccc",
			fontSize:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isNumber){
			return (
				<div style={divStyle}>
					<i className="mdi mdi-check" style={iconStyle}></i>
					<div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
						Al menos un número
					</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
								Al menos un número
							</div>
				</div>
			);
		}
	}
	renderIsLetterHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"30px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isLetter){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra minúscula
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra minúscula
							</div>
				</div>
			);
		}
	}
	renderIsUpperLetterHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"30px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isUpperLetter){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra mayúscula
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra mayúscula
							</div>
				</div>
			);
		}
	}
	renderSixCharsHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"30px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.sixCharacters){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
								Al menos 6 caracteres
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
								Al menos 6 caracteres
							</div>
				</div>
			);
		}
	}
	renderPwdEqualsHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"30px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.state.pwdEquals){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.state.pwdEquals ? "": "text-muted"} style={textStyle}>
								Contraseñas coinciden
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.state.pwdEquals ? "": "text-muted"} style={textStyle}>
								Contraseñas coinciden
							</div>
				</div>
			);
		}
	}
	renderPasswordForm(){
		return(
			<div className="pv_content form-horizontal form-horizontal">
				<h1 className="pv_title"> {this.renderTitle()} </h1>
				<h2 className="pv_subtitle m-t-20 m-b-20"> {this.state.data.email} </h2>
				{this.renderWelcomeMsg()}
				<div className="pv_wrapper">
          <div className="card-body">
						<div className="pv_subtitle">
							Crea una contraseña
						</div>
						<div className="form-group">
	            <div className="col-xs-12">
								<input type="password"
											 id="password"
											 ref={(input) => { this.password = input; }}
											 placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
											 value={this.state.password}
											 onChange={ change => {this.formData(change); this.setPassword(change)}}
											 className="form-control"
											 onKeyPress={
				                 event => {
				                   if (event.key === "Enter") {
														 this.pwdConfirmRef.current.focus();
				                   }
				                 }
				               }
											 onKeyDown={
												 event => {
													 if (event.key === "Tab") {

													 }
												 }
											 }
								 />
								 <input type="password"
								 				id="pwdConfirm"
												ref={this.pwdConfirmRef}
								 				placeholder="Confirma tu contraseña"
												value={this.state.pwdConfirm}
												onChange={change => {this.formData(change);}}
												className="form-control m-t-20"
												onKeyPress={
													event => {
														if (event.key === "Enter") {
															this.checkConfirmPwd()
														}
													}
												}
												onKeyDown={
													event => {
														if (event.key === "Tab") {
															this.checkConfirmPwd()
														}
													}
												}
									/>
						<label className="font-weight-bold m-t-30">Tu contraseña debe contener:</label>
							{this.renderIsNumberHint()}
							{this.renderIsLetterHint()}
							{this.renderIsUpperLetterHint()}
							{this.renderSixCharsHint()}
							{this.renderPwdEqualsHint()}
							</div>
						</div>
						<div className="form-group text-center m-t-20">
	            <div className="col-xs-12">
								<div className={'text-center m-b-20'} style={{color:'#ef5350'}}>{this.state.errorMsg}</div>
								<button className="btn btn-info btn-lg btn-block text-uppercase btn-rounded komunidad-color" onClick={this.createPassword}>Crear</button>
							</div>
						</div>
					</div>
				</div>
				<img className="komunidad-logo" src={ Logo } alt="logo" />
			</div>
		);
	}

	renderWrongPRC(){
		return(
			<div className="landingContent">
				<p>
					El código para creación de contraseña no es válido. Intente crear de nuevo su cuenta.
				</p>
			</div>
		);
	}

	renderContent(){
		let rc = this.state.renderContent;
		if(rc === null){
			return (null);
		}else if(rc === "create_password_view"){
			return this.renderPasswordForm();
		}else if(rc === "wrong_prc_view"){
			return this.renderWrongPRC();
		}
	}

	setPassword(password) {
    if(password.target.value.match(/\d+/)) {
      this.passwordCheck.isNumber = true;
    } else {
      this.passwordCheck.isNumber = false;
    }
    if(password.target.value.match(/[A-Z]+/)) {
      this.passwordCheck.isUpperLetter = true;
    } else {
      this.passwordCheck.isUpperLetter = false;
    }
    if(password.target.value.match(/[a-z]+/)) {
      this.passwordCheck.isLetter = true;
    } else {
      this.passwordCheck.isLetter = false;
    }
    if(password.target.value.match(/\w{6,12}/)) {
      this.passwordCheck.sixCharacters = true;
    } else {
      this.passwordCheck.sixCharacters = false;
    }
  }

  isPasswordEqual() {
		let pwd_eq = (
				(this.state.password === this.state.pwdConfirm )
			 	&& (this.state.password.length >= 6 && this.state.pwdConfirm.length >= 6)
		 );
	  this.passwordCheck.isEqual = pwd_eq;
		this.setState({"pwdEquals":pwd_eq});
  }

	checkPassword(){

		let response = Validator.validatePwd(this.state.password);
		if(response.result){
			this.setState({disabled:false});
			this.pwdConfirm.current.focus();
		}else{
			this.handleErrorMsg(response.reason);
		}
	}

	checkConfirmPwd(){
		let response = Validator.validatePwd(this.state.pwdConfirm);
		if(response.result){
			this.createPassword();
		}else{
			this.handleErrorMsg(response.reason);
		}
	}

	handleErrorMsg(msg){
		//Set error msg
		this.setState({errorMsg:msg});
	}

	createPassword() {

		let registerFlag = true;

		for(const el in this.passwordCheck) {
      if(!this.passwordCheck[el]) {
        registerFlag = false;
      }
    }

		if(this.state.pwdConfirm === this.state.password && registerFlag === true) {
			let search = this.props.location.search;
			let params = new URLSearchParams(search);
			let programIdQuery = params.get('program_id');
			KomunidadApi.createPassword(this.state.data.pre_register_code,this.state.password).then((res) => {
				if(!res.success){
					if(res.hasOwnProperty("error_es")){
						this.handleErrorMsg(res.error_es);
					}else{
						this.handleErrorMsg("Hubo un problema creando tu password. Intenta nuevamente después");
					}
				}else{
					MySwal.fire({
			      title: 'Tu cuenta ha sido creada',
						text:'Ahora serás redireccionado(a) a Komunidad.',
			      type: 'success',
			      		customClass: {
			      		  confirmButton: 'swalBtnTeal',
			      		  title: 'swal-custom-title',
			      		},
					}).then((result) => {
						if(programIdQuery !== null && programIdQuery !== 'undefined' && programIdQuery !== 'null') {
							// redirect to join program
							this.props.history.push({
								pathname: '/join_program',
								search: `?programid=${programIdQuery}`
							});
						}
	          else {
							// redirect to companies
							this.props.history.push(`/live`)
						}
			    });
				}

	    });
    }
    else{
			this.handleErrorMsg('Las contraseñas no coinciden');

    }
	}


	clearErrorMsg(){
		this.passwordCheck.isLetter = false;
    this.passwordCheck.isUpperLetter = false;
    this.passwordCheck.isNumber = false;
    this.passwordCheck.sixCharacters = false;
    this.passwordCheck.isEqual = false;
		this.setState({
			errorMsg:'',
			password: '',
			pwdConfirm: '',
		});
	}

	validatePassword(password) {
    var re = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,12})\S$/
    return re.test(String(password));
  }

	render() {
		return (

			<div className="landingView" >
				<div className="page-wrapper" style={{minHeight: '584px', padding: 0}}>
	        <div className="container-fluid">
	          <div className="row">
	            <div className="col-12">
	              <div className="card" style={{margin: 0}}>
	                <div className="card-body">
										{this.renderContent()}
									</div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
  		</div>
		);
	}
}

export default PasswordView;
