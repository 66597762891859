/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, July 2019
 */
 //TODO: TO BE DELETED
var resetPwd = {

	validateEmail(email) {

    let messages = {
      invalid_email: 'Verifica que la estructura de tu correo sea correcta'
    }

    let validatorResponse = {
      result:false,
      reason:null
    }

    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    if(re.test(String(email).toLowerCase())) {
      validatorResponse.reason = null;
      validatorResponse.result = true;
    }
    else {
      validatorResponse.reason = messages.invalid_email;
      validatorResponse.result = false;
    }

    return validatorResponse;

  },
	validatePwd(pwd){
		let messages = {
			invalid_pwd: 'La contraseña no es válida.',
			invalid_empty: 'La contraseña no puede ser vacía.',
			invalid_len_min: 'La contraseña debe tener al menos 6 caracteres.',
			invalid_len_max: 'La contraseña debe tener máximo 12 caracteres.',
		}

		let validatorResponse = {
			result:false,
			reason:null
		}

		let re = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{5,12})\S$/;

		if(pwd.trim() === ''){
			validatorResponse.reason = messages.invalid_empty;
      validatorResponse.result = false;
		}else if(pwd.length < 6){
			validatorResponse.reason = messages.invalid_len_min;
			validatorResponse.result = false;
		}else if(pwd.length > 12){
			validatorResponse.reason = messages.invalid_len_max;
			validatorResponse.result = false;
		}else if(	re.test(String(pwd)) ){
			validatorResponse.reason = null;
			validatorResponse.result = true;
		}else{
			validatorResponse.reason = messages.invalid_pwd;
			validatorResponse.result = false;
		}
		return validatorResponse;
	},
}

module.exports = resetPwd;
