/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, April 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';



import KReviewList from '../components/KReviewList';


/**
*  Render the complete list of reviews.
*/

class KModalReviewList extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.props.closeModal(false);
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Comentarios
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div class="activity-box">
            <KReviewList
                reviews={this.props.reviews}
                showFullReview={true}
                requestStatus={this.props.requestStatus}
                subtitleEmptyList={''}
                showAll={true}/>
          </div>


        </Modal.Body>
      </Modal>
    );
  }
}

KModalReviewList.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,

  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,

  /** Review list of the company. */
  reviews          : PropTypes.array,

  /** Status of the loading data: idle, fetching, success and failed. */
  requestStatus        : PropTypes.string,


};

KModalReviewList.defaultProps = {
  closeModal           : () => {},
  showModal            : false,
  reviews              : [],
  requestStatus        : 'idle'
};

export default KModalReviewList;
