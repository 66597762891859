/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>,April 2021
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../api/api.js';
import { isUserProfileComplete, renderFullNameOrEmail} from '../utils/Utils.js';
import CompanyPhasesChecker from '../utils/CompanyPhasesChecker.js';

import { Tab, Nav, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import '../scss/pages/_style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Link } from "react-router-dom";

// Components
import HeaderCatalog from '../components/HeaderCatalog';
import Footer from '../components/Footer';
import SideBarCatalog from '../components/SideBarCatalog';
import FormInput from '../components/form/FormInput';
import KImage from '../components/KImage';
import KTableSmartSelect from '../components/KTableSmartSelect';
import StatePicker from '../components/StatePicker';
import KSectorsPicker from '../components/KSectorsPicker';
import KPhasesPicker from '../components/KPhasesPicker';
import KProgramsPickerPublicCompanies from '../components/KProgramsPickerPublicCompanies';

//Other imports
import { orderBy } from 'lodash';

// Custom styling
import '../scss/pages/login-register-lock.scss';
import '../scss/colors/default-dark.scss';
import '../scss/pages/pages.scss';
import '../scss/pages/_login.scss';
import '../scss/pages/_create-company.scss';
import '../scss/pages/_landing-modal.scss';

import ReactMapGL,{ NavigationControl, Popup} from 'react-map-gl';
import KMarker from '../components/KMarker';
const MAPBOX_TOKEN = 'pk.eyJ1IjoicGFuemVyIiwiYSI6ImNrN2RsZzcwMDIxMHAzZW9naWh1amhkejIifQ.2MkaNWU65XhWigJvQUm1_A';

class PublicCompaniesListView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me:{},
      authorized:false,
      companies:[],
      sidebarVisible:true,
      sidebarStatus: true,
      query:'',
      table_msg:'Ingresa el nombre de la startup para buscar.',
      shareMessage:'Genera link de búsqueda',
      loading:false,
      embed:false,
      companiesPage:1,
      galleryPage: 1,
      search_msg:'',
      cb_state:'none',
      selectedRows:[],
      programs:[],
      stateIsoCode:'',
      q_stateIsoCode:'',
      selectedPrograms:'',
      q_selectedPrograms:'',
      filters: '',
      q_filters: '',
      sector: '',
      q_sector: '',
      selectedView:'gallery',
      resultCompanies:0,
      viewport: {
        latitude: Number(process.env.REACT_APP_LATITUDE),
        longitude: Number(process.env.REACT_APP_LONGITUDE),
        zoom: 3.9,
      },
      installationCoordinates:{
        latitude:0,
        longitude:0
      },
      selectedRows:[],
      order: true,
    }

    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.toggleView                      = this.toggleView.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.onChangeState                   = this.onChangeState.bind(this);
    this.onClickGetUrl                   = this.onClickGetUrl.bind(this);
    this.onClickFindCompany              = this.onClickFindCompany.bind(this);
    this.onClickFindAllCompanies         = this.onClickFindAllCompanies.bind(this);
    this.extractQueryParams              = this.extractQueryParams.bind(this);
    this.getCompanies                    = this.getCompanies.bind(this);
    this.setPhase                        = this.setPhase.bind(this);
    this.onProgramSelected               = this.onProgramSelected.bind(this);
    this.onOptionSelected                = this.onOptionSelected.bind(this);
    this.handleOnSelect                  = this.handleOnSelect.bind(this);
    this.handleProgramsChange            = this.handleProgramsChange.bind(this);
    this.handleSectorsChange             = this.handleSectorsChange.bind(this);
    this.changePage                      = this.changePage.bind(this);
    this.sortCompanies                   = this.sortCompanies.bind(this);
    this.columns = [
      // {
      //   dataField: 'logoThumbnail',
      //   classes: 'border-top border-bottom border-left',
      //   text: 'Startup',
      //   formatter: (cellContent, row) => {
      //     return(
      //       <Link to={`/directorio/${row._id}`}>
      //         <KImage src={cellContent} type='company' className='companyImage' alt="company_image"/>
      //       </Link>
      //     )
      //   },
      //   headerStyle: {fontSize: '14px'}
      // },
      {
        dataField: 'name',
        text:'Startup',
        classes: 'align-middle border-top border-right border-bottom',
        headerStyle: { minWidth: '245px' },
        style: {fontSize: '12px'},
        formatter: (cellContent, row) => {
          return(
            <Link to={`/directorio/${row._id}`+ (this.state.embed? "?embed=true":"")} className="btn btn-link">
              {row.name}
            </Link>
          )
        },
        sort: true
      },
      /*{
        dataField: 'phases',
        classes: 'align-middle border',
        text: 'Etapa',
        style: {fontSize: '12px'},
        sort: true,
        formatter:(cellContent) => {
          return CompanyPhasesChecker.getPhaseLabelFromObject(cellContent);
        }
      },*/
      /*{
        dataField: 'programs',
        classes: 'align-middle border',
        text: 'Programa(s)',
        style: {fontSize: '12px'},
        sort: true,
        formatter:(cellContent) => {
          return this.getProgramLabelFromObject(cellContent);
        }
      },*/
      /*{
        dataField: 'industry_sector',
        text: 'Sector',
        sort: true,
      },*/
      /*{
        dataField: 'owner_id',
        text: 'Fundador',
        sort: true,
        formatter: (cellContent) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== null){
             name = renderFullNameOrEmail(cellContent);
          }
          return name;
        }
      },*/
      {
        dataField: 'quote',
        text: 'Descripción',
        classes: 'align-middle border',
        style: {fontSize: '12px'},
        headerStyle: {fontSize: '14px'}
      },
      {
        dataField: 'industry_sector',
        text: 'Sector',
        classes: 'align-middle border',
        style: {fontSize: '12px'},
        headerStyle: {fontSize: '14px'}
      },
      
      // {
      //   dataField: 'programs',
      //   text: 'Programas',
      //   headerStyle: { minWidth: '108px' },
      //   classes: 'align-middle border',
      //   style: {
      //     verticalAlign:'middle'
      //   },
      //   formatter: (programs) => {
      //     // const programs = cellContent;
          
      //     return(
      //       <span>{ programs.map((program) =>
      //         <span>{program.program.name}<br></br></span>
      //         )}
      //       </span>
      //     )
      //   }
      // },
      {
        dataField: 'address_municipality',
        text: 'Ubicación',
        classes: 'align-middle border',
        style: {fontSize: '12px', width: '200px'},
        headerStyle: {fontSize: '14px'},
        formatter: (cellContent, row) => {
          return this.renderAddressListView(row)
        }
        /*text: 'Fundador',
        sort: true,*/
      },
      {
        dataField: 'published_at',
        text: 'Publicación',
        classes: 'align-middle border',
        style: {fontSize: '12px'},
        headerStyle: {fontSize: '14px'},
        formatter: (date) => {
          return(
            this.formatDate(date)
          )
        },
        headerAlign: 'left',
        align:'left',
        sort: true
      },
      /*{
        dataField: 'is_disabled',
        text: 'Activo',
        formatter: this.disableFormatter,
        formatExtraData: {
          true: 'times',
          false: 'check'
        },
        headerAlign: 'left',
        align:'left',
      },*/
      /*{
        dataField: '_id',
        text: '',
        formatter: (cellContent) => {
          return(
            <Link to={`/companies/${cellContent}`}> Ver startup </Link>
          )
        }
      }*/
    ];
    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({partnersPage: this.state.partnersPage + 1})
        } else if(page === '<') {
          this.setState({partnersPage: this.state.partnersPage - 1})
        } else {
          this.setState({partnersPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };
  }
  

  getProgramLabelFromObject(company){

    if(company.length > 0) {
      let company_programs = company.map(company => company.program.name);
      return company_programs.toString();
    } else {
      return "Startup sin programa"
    }

  }

  isAuthorized(user){
    return (
      user.roles.length > 0
    )
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
              userIsLoaded:true
            });
          }else{
            this.redirectToProfile();
          }
        }
      }
    });
    this.extractQueryParams(this.getCompanies);
    /*window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar();*/
  }

  extractQueryParams(cb){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let query             = params.get('query') !== null ? params.get('query'): "";
    let filters           = params.get('filters') !== null ? params.get('filters'): "";
    let sector            = params.get('sector') !== null ? params.get('sector'): "";
    let selectedPrograms  = params.get('selectedPrograms') !== null ? params.get('selectedPrograms'): "";
    let stateIsoCode      = params.get('stateIsoCode') !== null ? params.get('stateIsoCode'): "";
    let selectedView      = params.get('selectedView') !== null ? params.get('selectedView'): "gallery";
    let embed             = params.get('embed') !== null && params.get('embed') === 'true' ? true : false;
    this.setState({
      query: query,
      filters: filters,
      q_filters: filters,
      sector: sector,
      q_sector: sector,
      selectedPrograms: selectedPrograms,
      q_selectedPrograms: selectedPrograms,
      stateIsoCode: stateIsoCode,
      q_stateIsoCode: stateIsoCode,
      selectedView: selectedView,
      embed: embed,
    }, () =>{
      cb();
    });
  }

  iso31662ToState(state){
    let isoCodes = {
      "MX-AGU":"Aguascalientes",
      "MX-BCN":"Baja California",
      "MX-BCS":"Baja California Sur",
      "MX-CAM":"Campeche",
      "MX-COA":"Coahuila de Zaragoza",
      "MX-COL":"Colima",
      "MX-CHP":"Chiapas",
      "MX-CHH":"Chihuahua",
      "MX-CMX":"Ciudad de México",
      "MX-DUR":"Durango",
      "MX-GUA":"Guanajuato",
      "MX-GRO":"Guerrero",
      "MX-HID":"Hidalgo",
      "MX-JAL":"Jalisco",
      "MX-MEX":"México",
      "MX-MIC":"Michoacán de Ocampo",
      "MX-MOR":"Morelos",
      "MX-NAY":"Nayarit",
      "MX-NLE":"Nuevo León",
      "MX-OAX":"Oaxaca",
      "MX-PUE":"Puebla",
      "MX-QUE":"Querétaro",
      "MX-ROO":"Quintana Roo",
      "MX-SLP":"San Luis Potosí",
      "MX-SIN":"Sinaloa",
      "MX-SON":"Sonora",
      "MX-TAB":"Tabasco",
      "MX-TAM":"Tamaulipas",
      "MX-TLA":"Tlaxcala",
      "MX-VER":"Veracruz de Ignacio de la Llave",
      "MX-YUC":"Yucatán",
      "MX-ZAC":"Zacatecas",
    }
    return isoCodes[state];
  }

  onOptionSelected(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.companies.map(c => c._id);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    if(option.actionName === "active"){
      selectedRows = this.state.companies.filter(c=> !c.is_disabled).map(c => c._id);
    }
    if(option.actionName === "disabled"){
      selectedRows = this.state.companies.filter(c=> c.is_disabled).map(c => c._id);
    }
    this.setState({
      cb_state:option.actionType,
      selectedRows:selectedRows
    },() => {
      this.convertCompaniesToIds();
    });
  }
  convertCompaniesToIds(){
    let url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx`;
    let ids = this.state.selectedRows.join("|");
    if(this.state.selectedRows.length === this.state.companies.length){
        //do nothing
    }else{
      url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrl:url
    })
  }
  getCompanies(){
    this.setState({loading:true});
    KomunidadApi.getPublicCompanies(this.state.query,this.state.filters,this.state.sector,this.state.selectedPrograms,this.state.stateIsoCode).then((res) => {
      if(res.success){
        let _length = res.companies.length;
        let _msg = '';

        //set msg for search results if the query is not empty
        if(this.state.query !== ''){
          let _r = (_length === 1) ? 'resultado' : 'resultados';
          _msg = `${_length} ${_r} para "${this.state.query}" `;
        }

        if(_length > 0){
          /*this.formatCompaniesNoProgram(res.companies)*/
          this.setState({companies: this.formatCompaniesNoProgram(res.companies),programs:res.companiesPrograms,loading:false,search_msg:_msg,selectedRows:[]});
        }else{
          this.setState({companies:[],table_msg:'No hay negocios relacionados con tu búsqueda',programs:[],loading:false,search_msg:_msg,selectedRows:[]});
        }

        this.setState({
          resultCompanies:res.companies.length,
          companiesWithLocation:res.companiesWithLocation.length,
          viewport:{
            latitude: res.installationCoordinates[0],
            longitude: res.installationCoordinates[1],
            zoom: 3.9,
          },
          installationCoordinates:{
            latitude: res.installationCoordinates[0],
            longitude: res.installationCoordinates[1],
          }
        });

      }
    });
  }

  setPhase(phases){
    /*this.setState({filters: phases},() => {
      this.getCompanies();
    });*/

    if(phases !== null){
      phases = phases.map((p) => p.value).join("|");
    }
    if(phases === "all" || phases === null){
      phases = '';
    }

    this.setState({filters: phases});
  }

  formatCompaniesNoProgram(companies) {
    companies.forEach(function (element) {
      if(!element.hasOwnProperty('programs')) {
        element.programs = [];
      }
    });

    return companies

  }  

  handleProgramsChange(programs){
    if(programs !== null){
      programs = programs.map((p) => p.value).join("|");
    }
    if(programs === "all" || programs === null){
      programs = '';
    }
    this.setState({selectedPrograms:programs})
  }

  handleSectorsChange(sectors){
    if(sectors !== null){
      sectors = sectors.map((p) => p.value).join("|");
    }
    if(sectors === "all" || sectors === null){
      sectors = '';
    }
    this.setState({sector:sectors})
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    // this.formIsHalfFilledOut(true);
    this.setState({[data.model]:value});
  }

  onChangeState(isoCodeArray){
    let isoCode = '';

    if(isoCodeArray.length > 0){
      if(!isoCodeArray.includes('all')){
        isoCode = isoCodeArray.join('|');
      }
    }

    this.setState({stateIsoCode:isoCode});
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  onClickFindCompany(){
    if(this.state.sector !== null){
      let params = {
        "filter_sectors": this.state.sector
      };
    }

    if(this.fi_query.validate()){
      this.getCompanies();
    }
  }

  onClickGetUrl(){
    let url = new URL('https://' + window.location.host + "/directorio");    
    if (this.state.query !== "") {
      url.searchParams.append("query", this.state.query);
    }
    if (this.state.filters !== "") {
      url.searchParams.append("filters", this.state.filters);
    }
    if (this.state.sector !== "") {
      url.searchParams.append("sector", this.state.sector);
    }
    if (this.state.selectedPrograms !== "") {
      url.searchParams.append("selectedPrograms", this.state.selectedPrograms);
    }
    if (this.state.stateIsoCode !== "") {
      url.searchParams.append("stateIsoCode", this.state.stateIsoCode);
    }
    if (this.state.selectedView !== "") {
      url.searchParams.append("selectedView", this.state.selectedView);
    }
    
    navigator.clipboard.writeText(url.href)
    this.renderShareMessage('Link Copiado')
  }

  renderShareMessage(message) {
    this.setState({
      shareMessage: message
    }, () => {
      setTimeout( () => {
        this.setState({
          shareMessage: 'Genera link de búsqueda'
        });
      }, 2000);
    });
  }


  onProgramSelected(id) {
    let programId = id[0];
    this.setState({
      selectedId: id
    })
    return this.getPreUserCourses(programId);
  }

  getPreUserCourses(query) {
    KomunidadApi.getPublicCourses(query).then((res) => {
      if (res.success) {
        if (res.courses.length > 0) {
          // let courses = this.sortCoursesByName(res.courses)
          // this.setState({ courses: courses, isMyCourse: false });
        } else {
          this.setState({ courses: [] });
        }
      } else {
        this.setState({ courses: [] });
      }
      //TODO: swal error or msg in table with table_msg
    });
  }

  onClickFindAllCompanies(){
    // Clear components filters
    this.fi_query.cancel();
    this.fi_programs.cancel();
    this.fi_sectors.cancel();
    this.fi_phases.cancel();
    // Clear state filters
    this.setState({
      sector:'',
      selectedPrograms:'',
      stateIsoCode:'',
      query:'',
      filters:'',
    },() => this.getCompanies());
  }

  toggleView(view){
    this.setState({ selectedView: view })
  }

  disableFormatter(cell,row,rowIndex,formatExtraData){
    let color = cell ? '#ff5050' : '#707cd2';
    if(cell !== undefined){
      return(
        <FontAwesomeIcon icon={formatExtraData[cell]} color={color}></FontAwesomeIcon>
      )
    }
  }
  handleOnSelect(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedRows = [...state.selectedRows, row._id];
        return {
          selectedRows,
        };
      }, () => {
        this.convertCompaniesToIds();
        if(this.state.selectedRows.length === this.state.companies.length){
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("all");
          }

        }else{
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("mixed");
          }
        }
      });
    } else{
      this.setState(() => ({
        selectedRows: this.state.selectedRows.filter(x => x !== row._id),
      }), () => {
        this.convertCompaniesToIds();
        if(this.state.selectedRows.length === 0){
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("none");
          }

        }else{
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("mixed");
          }

        }
      });
    }
  }
  renderActionButtons(){
    if(this.state.selectedRows.length === 0){
      return (null);
    }else{
      return(
        <a className="m-l-10" href={this.state.exportUrl} rel="noopener noreferrer" target={'_blank'}>
          <div className='btn btn-warning'>Exportar a Excel</div>
        </a>
      )
    }
  }
  renderSearchBar(){
    return(
      <div className="card-body">
        <div className='row justify-content-md-start'>
          <div className="col-md-6">
            <FormInput
            ref={ref => this.fi_query = ref}
            id="buscar"
            label="Buscar "
            model="query"
            value={this.state.query}
            showCounter={false}
            initialValue={''}
            onChange={ change => { this.onChangeForm(change) } }
            placeholder="Nombre"
            onEnterPressed={() => this.onClickFindCompany()}
            />
          </div>
          <div className="col-md-3">
            <StatePicker
              onChange={(state) => {this.onChangeState(state)}}
              label={"Ubicación"}
              selectedLocations={this.state.q_stateIsoCode}
              isMultiple={true}>
            </StatePicker>
          </div>
          <div className="form-group col-md-3 mb-0">
            <KPhasesPicker 
            ref={ref => this.fi_phases = ref}
            selectedPhases={this.state.q_filters}
            onChange={(phases) => this.setPhase(phases)}></KPhasesPicker>
          </div>
        </div>

        <div className='row justify-content-md-start'>
          
          <div className="form-group col-md-8 mb-0">  
            <div className='row justify-content-md-start'>
              <div className="form-group col-md-6 mb-0">
                <KProgramsPickerPublicCompanies 
                ref={ref => this.fi_programs = ref}
                programs={this.state.programs}
                selectedPrograms={this.state.q_selectedPrograms}
                onChange={(programs) => this.handleProgramsChange(programs)}></KProgramsPickerPublicCompanies>
              </div>

              <div className="form-group col-md-6 mb-0">
                <KSectorsPicker 
                ref={ref => this.fi_sectors = ref}
                selectedSectors={this.state.q_sector}
                onChange={(sectors) => this.handleSectorsChange(sectors)}></KSectorsPicker>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="d-inline-flex" style={{marginTop:'30px'}}>
              <Button className="btn btn-save" onClick={this.onClickFindCompany}>Filtrar</Button>
              <Button className="btn btn-danger" onClick={this.onClickFindAllCompanies}>Todas</Button>
              <OverlayTrigger 
                key={'tooltip-share'}
                placement={'top'}
                overlay={
                  <Tooltip id={'tooltip-share'}>
                    {this.state.shareMessage}
                  </Tooltip>
                }>
              
                  {/* <button className="btn btn-info"><i className="mdi mdi-share-variant"
                    style={{color: '#ffffff', fontSize: '20px', cursor: 'pointer'}}
                    onClick={()  => this.onClickGetUrl()}
                  ></i></button> */}

                  <i className="btn-link mdi mdi-share-variant cursor-pointer mt-1 mx-2" style={{fontSize: '18px'}}
                  onClick={this.onClickGetUrl}>
                  </i>
                
              </OverlayTrigger>
              <i className="mdi mdi-view-grid mr-2 cursor-pointer"
                style={{color: this.state.selectedView === 'gallery' ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
                onClick={() => this.toggleView('gallery')}
              ></i>
              <i className="mdi mdi-format-list-bulleted mr-2 cursor-pointer"
                style={{color: this.state.selectedView === 'list' ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
                onClick={() => this.toggleView('list')}
              ></i>
              <i className="mdi mdi-map cursor-pointer"
                style={{color: this.state.selectedView === 'map' ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
                onClick={() => this.toggleView('map')}
              ></i>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_msg}</b>
          </div>
        </div>
      </div>
    )
  }

  renderCompanyInfo(company){
    return(
      <div className='popup-map  m-t-5'>
        <div className='popup-row'>
          <KImage src={company.logoThumbnail} type='company' className='popup-image' alt="company_image"/>

          <div className='popup-column m-l-5'>
            <div className='font-bold'>{company.name}</div>
            <div className='text-muted'>{CompanyPhasesChecker.getCurrentPhaseLabel(company)}</div>
          </div>
        </div>
        { this.renderAddress(company) }
      </div>
    )
  }

  renderAddress(company){
    let state = ''
    if( company.address_state !== '' && company.address_state !== null){
      state = this.iso31662ToState(company.address_state);
      return(
        <div className=''>
          {this.sanitizeString(company.address_street) + ' ' + this.sanitizeString(company.address_number_ext) + ' ' + this.sanitizeString(company.address_number_int) }
          <br/>
          {this.sanitizeString(company.address_neighborhood)}
          <br/>
          {this.sanitizeString(company.address_zip_code) + ' ' + this.sanitizeString(company.address_municipality)+ ', '+ this.sanitizeString(state) }
        </div>
      )
    }else{
      return null
    }
  }
  renderAddressListView(company){
    let state = ''
    if( company.address_state !== '' && company.address_state !== null){
      state = this.iso31662ToState(company.address_state);
      return(
        <div className=''>
          {this.sanitizeString(company.address_municipality)+ ', '+ this.sanitizeString(state) }
        </div>
      )
    }else{
      return null
    }
  }

  sanitizeString(str){
    if (str !== null && str !== undefined) return str
    return ''
  }

  renderCompanies(){
    switch (this.state.selectedView) {
      case 'gallery': return this.renderGallery();
      case 'list': return this.renderCompaniesList();
      case 'map': return this.renderMapBox();
      default: return null;
    }
  }

  renderUbication(address_municipality, address_state) {
    if(address_municipality !== null && address_municipality.length !== 0 && address_state !== null && address_state.lenght !== 0) {
      return(
        <div className="d-flex no-block align-items-center mb-2">
          <span className="card-subtitle mb-2 text-muted d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin mr-2 feather-sm"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>{address_municipality}, {this.iso31662ToState(address_state)}</span>
        </div>
      )
    } else return null;
  }

  renderQuote(quote){
    if(quote !== null && quote.length !== 0) { 
      return(
        <div className="mb-3">
          <p className="mb-0 mt-2 card-text">{quote}</p>
        </div>
      )
    } else return null;
  }
  renderSector(sector){
    if(sector !== null && sector.length !== 0) { 
      return(
        <div className="mb-1">
          <p className="mb-0 mt-2 card-text"><b>{sector}</b></p>
        </div>
      )
    } else return null;
  }
  renderPublishedAt(date){
    if(date !== undefined && date !== null && date.length !== 0) { 
      let displayDate = this.formatDate(date);
      return(
        <small className="mb-0 mt-1 float-right card-text">Publicación: <b>{displayDate}</b></small>
      )
    } else return null;
  }
  formatDate(date){
    if(date !== null && date !== undefined && date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }

  renderPrograms(programs) {
    if(programs.length !== 0) {   
      return(
        <div className="resource-text">
          {programs.map((item,index) =>
            (
              <span className="mb-1 badge font-weight-bold bg-light-primary text-primary mr-2">
                {item.program.name}
              </span>
            )
          )}
        </div>
      )
    } else return null;
  }

  changePage(event) {
    if(event.target.id === '>') {
      this.setState({galleryPage: this.state.galleryPage+1})
    } else if(event.target.id === '<') {
      this.setState({galleryPage: this.state.galleryPage-1})
    } else {
      this.setState({galleryPage: event.target.id})
    }
  }

  // true asc, else desc
  sortCompanies(order) {
    if(order) {
      this.setState({
        companies: orderBy(this.state.companies, ['name'], ['asc']),
        order: !this.state.order
      });
    } else {
      this.setState({
        companies: orderBy(this.state.companies, ['name'], ['desc']),
        order: !this.state.order
      });
    }
  }

  renderGallery() {
    if (this.state.companies.length > 0){
    let companies = this.state.companies
    let perPage = 24;

    // Logic for displaying all the companies
    const indexOfLastCompanies = this.state.galleryPage * perPage;
    const indexOfFirstCompanies = indexOfLastCompanies - perPage;
    const currentCompanies = companies.slice(indexOfFirstCompanies, indexOfLastCompanies);

    const renderCompanies = currentCompanies.map((company, item) => {
      return(
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="card shadow-none">
            <div className="card-body my-2" style={{height: '240px'}}>
              <h5 className="card-title mb-3 font-weight-bold one-line-text">
                <Link to={`/directorio/${company._id}`+ (this.state.embed? "?embed=true":"")}>{company.name}</Link>
              </h5>
              {this.renderUbication(company.address_municipality, company.address_state)}
              {this.renderSector(company.industry_sector)}
              {this.renderQuote(company.quote)}
            </div>
            <div className="card-footer text-muted bg-white border-0">
              <span className="mb-1 badge font-weight-bold bg-light-primary text-primary mr-2">{CompanyPhasesChecker.getCurrentPhaseLabel(company)}</span>
              {this.renderPublishedAt(company.published_at)}
            </div>
          </div>
        </div>
      )
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(companies.length / perPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <button className={`my-3 mr-1 btn ${this.state.galleryPage == number ? "btn-save" : "btn-secondary"}`}
                key={number}
                id={number}
                onClick={this.changePage}
        >
          {number}
        </button>
      );
    });

    if(this.state.selectedView === 'gallery') {
      return(
        <div className="card-body">
          <div className="d-inline-flex">
            <ul class="pagination react-bootstrap-table-page-btns-ul" id="page-numbers">
              {renderPageNumbers}
            </ul>
            <i className="mdi mdi-sort-alphabetical mx-2 my-3 cursor-pointer"
              style={{color: this.state.order === true ? '#007bff' : "#c2c2c2", fontSize: '24px'}}
              onClick={() => this.sortCompanies(!this.state.order)}
            ></i>
          </div>
          <div className="row">
            {renderCompanies}
          </div>
        </div>
      )
    }
    }else {
      return (
        <div className="text-center mt-4 mb-4">
          <h3>No hay negocios relacionados con tu búsqueda</h3>
          <i className="mdi mdi-store m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        </div>
      )
    }
  }

  renderCompaniesList(){
    if(this.state.selectedView === 'list'){
      
      const pageButtonRenderer = ({
        page,
        active,
        disabled,
        title,
        onPageChange
      }) => {
        const handleClick = (e) => {
          e.preventDefault();
          /*onPageChange(page);*/
          if(page === '>') {
            this.setState({companiesPage: this.state.companiesPage + 1})
          } else if(page === '<') {
            this.setState({companiesPage: this.state.companiesPage - 1})
          } else {
            this.setState({companiesPage: page})
          }
        };
        return (
            <button 
              className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
              onClick={ handleClick }
            >
              { page }
            </button>
        );
      };

      const options = {
        custom: true,
        totalSize: this.state.companies.length,
        pageButtonRenderer,
        sizePerPage: 25,
        page: this.state.companiesPage,
        withFirstAndLast: false
      };

      return(
        <div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    keyField='_id'
                    loading={this.state.loading}
                    data={this.state.companies}
                    columns={this.columns}
                    noDataIndication={this.state.table_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
        </div>
      )
    }else{
      return null
    }
  }

  renderMapBox(){
    const {showPopup} = this.state;
    const companies = this.state.companies.filter(c => c.coordinates !== undefined && c.coordinates[0] !== 0 && c.coordinates[1] !== 0);
    const {company} = this.state;
    let companyCount = this.state.resultCompanies - this.state.companiesWithLocation;

    let labelTxt = `${companyCount} startups sin ubicación`;
    if(companyCount === 1){
      labelTxt = `${companyCount} startup sin ubicación`;
    }
    
    return(
      <div>
        <ReactMapGL
          {...this.state.viewport}
          width="100%"
          height="530px"
          mapStyle="mapbox://styles/panzer/ck7e07mrw1nxj1iqvac5593qg"
          onViewportChange={viewport => this.setState({viewport})}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <div style={{position: 'absolute', right: 0}}>
            <NavigationControl showCompass={false}/>
          </div>

          { companies.map((company,item) => (
            <KMarker latitude={company.coordinates[1]}
                     longitude={company.coordinates[0]}
                     draggable={false}
                     onClickMarker={() => {this.setState({showPopup: true,company:company})}}>
             </KMarker>
            )
          )}
          { showPopup && <Popup
            latitude={company.coordinates[1]}
            longitude={company.coordinates[0]}
            closeButton={true}
            closeOnClick={false}
            onClose={() => this.setState({showPopup: false})}
            offsetTop={-32}
            anchor="left" >
            { this.renderCompanyInfo(company) }
          </Popup>}
          { companyCount !== 0 &&
            <KMarker latitude={this.state.installationCoordinates.latitude}
                    longitude={this.state.installationCoordinates.longitude}
                    draggable={false}
                    color={'#33cccc'}
                    icon={'building'}
                    label={labelTxt}>
            </KMarker>}
        </ReactMapGL>
        <div className="stats-bar">
          <div className="row text-center">
            <div className="col-lg-6 col-md-6">
              <div className="m-t-20">
                <h6 className="m-b-0">Startups encontradas</h6>
                <h3 className="m-b-0">{this.state.resultCompanies}</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="m-t-20">
                <h6 className="m-b-0">Startups con ubicación</h6>
                <h3 className="m-b-0">{this.state.companiesWithLocation}</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
  render () {
    return(
      <div className="fix-header fix-sidebar height100">
        <div id="main-wrapper" className="height100">
          {this.state.embed === false && <HeaderCatalog
            user={this.state.me}
            onResize={(w) => this.handleHeaderResize(w)}
            hideBurger={true}
          />}

          {/*<SideBarCatalog user={this.state.me} visible={this.state.sidebarStatus} searchBy={(phase) => this.setPhase(phase)}/>*/}
          {/* Page wrapper  */}
          <div className={"page-wrapper color-gray " + (this.state.embed === true ? "pt-0":"")}>
            {/* Container fluid  */}
            <div className="container-fluid">
              {/* Bread crumb*/}
              <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                      {/* <h3 className="text-themecolor">Directorio público</h3> */}
                  </div>
              </div>
              {/* End bread crumb*/}

              {/* Row */}
              <div className="row">
                {/* Column */}
                <div className="col-lg-12">
                  <div className="card border-0 shadow-none">
                    <div className="card-body">
                      {this.renderSearchBar()}
                      {this.renderCompanies()}
                    </div>
                  </div>
                </div>
                {/* End column */}
              </div>
              {/* End row */}

            </div>
            {/* End container fluid  */}
            { this.state.embed === false && <Footer/> }
          </div>
          {/* End page wrapper  */}

        </div>
      </div>
    )
  }
}

export default PublicCompaniesListView;
