/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, June 2019
 */

//Regular imports
import React from 'react'

//Style import
import '../../scss/components/_footer.scss'

class Footer extends React.Component {

  render () {
    return (
      <footer className="footer row" style={{margin: 0}}>
        <div className="fix-width">
          <div className="row">
            <div className="col-md-12 sub-footer m-0">
              <span className="ml-2">Komunidad.iO 2019</span>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}

export default Footer
