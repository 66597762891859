/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>,November 2019
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete, renderFullNameOrEmail} from '../../utils/Utils.js';
import CompanyPhasesChecker from '../../utils/CompanyPhasesChecker.js';

import { Tab, Nav, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../scss/pages/_style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Link } from "react-router-dom";

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import KImage from '../../components/KImage';
import KTableSmartSelect from '../../components/KTableSmartSelect';



class ManagerCompaniesView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      companies:[],
      authorized:false,
      userIsLoaded:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      query:'',
      table_msg:'Ingresa el nombre de la empresa para buscar.',
      loading:false,
      search_msg:'',
      cb_state:'none',
      selectedRows:[],
      exportUrl:''
    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.onClickFindCompany              = this.onClickFindCompany.bind(this);
    this.getCompanies                    = this.getCompanies.bind(this);
    this.onOptionSelected                = this.onOptionSelected.bind(this);
    this.onCbClick                       = this.onCbClick.bind(this);
    this.handleOnSelect                  = this.handleOnSelect.bind(this);
    this.isAuthorized                    = this.isAuthorized.bind(this);
    this.smartSelectOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
      {
        label         : "Activos",
        actionType    : "mixed",
        actionName    : "active",
      },
      {
        label         : "No Activos",
        actionType    : "mixed",
        actionName    : "disabled",
      }
    ];
    this.columns = [
      {
        dataField: 'logoThumbnail',
        text: 'Logo',
        formatter: (cellContent) => {
          return(
            <KImage src={cellContent} type='company' className='companyImage' alt="company_image"/>
          )
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true
      },
      {
        dataField: 'programs',
        text: 'Programa',
        sort: true,
        formatter:(cellContent) => {
          return this.getProgramLabelFromObject(cellContent);
        }
      },
      {
        dataField: 'phases',
        text: 'Etapa',
        sort: true,
        formatter:(cellContent) => {
          return CompanyPhasesChecker.getPhaseLabelFromObject(cellContent);
        }
      },
      {
        dataField: 'industry_sector',
        text: 'Sector',
        sort: true,
      },
      {
        dataField: 'owner_id',
        text: 'Fundador',
        sort: true,
        formatter: (cellContent) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== null){
             name = renderFullNameOrEmail(cellContent);
          }
          return name;
        }
      },
      {
        dataField: 'is_disabled',
        text: 'Activo',
        formatter: this.disableFormatter,
        formatExtraData: {
          true: 'times',
          false: 'check'
        },
        headerAlign: 'center',
        align:'center',
      },
      {
        dataField: '_id',
        text: '',
        formatter: (cellContent) => {
          return(
            <Link to={`/companies/${cellContent}`}> Ver empresa </Link>
          )
        }
      }
    ];
  }

  getProgramLabelFromObject(company){

    if(company.length > 0) {
      let company_programs = company.map(company => company.program.name);
      return company_programs.toString();
    } else {
      return "Empresa sin programa"
    }

  }

  isAuthorized(user){
    return (
      user.roles.includes("manager") && user.facilitator_programs_permissions.length === 0
    )
  }
  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
              userIsLoaded:true,
              loading:true
            });
            this.getCompanies('');
          }else{
            this.redirectToProfile();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }
  redirectToProfile(){
    this.props.history.push('/profile');
  }
  onOptionSelected(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.companies.map(c => c._id);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    if(option.actionName === "active"){
      selectedRows = this.state.companies.filter(c=> !c.is_disabled).map(c => c._id);
    }
    if(option.actionName === "disabled"){
      selectedRows = this.state.companies.filter(c=> c.is_disabled).map(c => c._id);
    }
    this.setState({
      cb_state:option.actionType,
      selectedRows:selectedRows
    },() => {
      this.convertCompaniesToIds();
    });
  }
  onCbClick(state){
    if(state === "all"){
      this.onOptionSelected(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelected(this.smartSelectOptions[1]);
    }
  }
  convertCompaniesToIds(){
    let url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx`;
    let ids = this.state.selectedRows.join("|");
    if(this.state.selectedRows.length === this.state.companies.length){
        //do nothing
    }else{
      url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrl:url
    })
  }
  getCompanies(query){
    KomunidadApi.findCompanies(query).then((res) => {
      if(res.success){
        let _length = res.companies.length;
        let _msg = '';

        //set msg for search results if the query is not empty
        if(query !== ''){
          let _r = (_length === 1) ? 'resultado' : 'resultados';

          _msg = `${_length} ${_r} para "${query}" `;
        }

        if(_length > 0){
          /*this.formatCompaniesNoProgram(res.companies)*/
          this.setState({companies: this.formatCompaniesNoProgram(res.companies),loading:false,search_msg:_msg,selectedRows:[]});
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("none");
          }
        }else{
          this.setState({companies:[],table_msg:'No hay empresas registradas.',loading:false,search_msg:_msg,selectedRows:[]});
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("none");
          }


        }
      }
    });
  }

  formatCompaniesNoProgram(companies) {
    companies.forEach(function (element) {
      if(!element.hasOwnProperty('programs')) {
        element.programs = [];
      }
    });

    return companies

  }  

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    // this.formIsHalfFilledOut(true);
    this.setState({[data.model]:value});
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  onClickFindCompany(){
    let query = this.state.query;
    if(this.fi_query.validate()){
      this.getCompanies(query);
    }
  }

  disableFormatter(cell,row,rowIndex,formatExtraData){
    let color = cell ? '#ff5050' : '#707cd2';
    if(cell !== undefined){
      return(
        <FontAwesomeIcon icon={formatExtraData[cell]} color={color}></FontAwesomeIcon>
      )
    }
  }
  handleOnSelect(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedRows = [...state.selectedRows, row._id];
        return {
          selectedRows,
        };
      }, () => {
        this.convertCompaniesToIds();
        if(this.state.selectedRows.length === this.state.companies.length){
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("all");
          }

        }else{
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("mixed");
          }
        }
      });
    } else{
      this.setState(() => ({
        selectedRows: this.state.selectedRows.filter(x => x !== row._id),
      }), () => {
        this.convertCompaniesToIds();
        if(this.state.selectedRows.length === 0){
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("none");
          }

        }else{
          if(this.kTableSmartSelect !== null){
            this.kTableSmartSelect.changeCbState("mixed");
          }

        }
      });
    }
  }
  renderActionButtons(){
    if(this.state.selectedRows.length === 0){
      return (null);
    }else{
      return(
        <a className="m-l-10" href={this.state.exportUrl} rel="noopener noreferrer" target={'_blank'}>
          <div className='btn btn-warning'>Exportar a Excel</div>
        </a>
      )
    }
  }
  renderCompanyList(){
    const companies = this.state.companies;

    let selectRow = {
      mode: 'checkbox',
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div></div>
      ),
      onSelect:this.handleOnSelect,
      selected:this.state.selectedRows,
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };
    return(
        <div className="card-body">
          <div className='row'>
            <div className="col-md-6">
              <FormInput
              ref={ref => this.fi_query = ref}
              id="buscar"
              label="Buscar "
              model="query"
              value={this.state.query}
              showCounter={false}
              initialValue={''}
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Escribe el nombre de la empresa"
              onEnterPressed={() => this.onClickFindCompany()}
              />
            </div>
            <div className="col-md-2">
              <Button style={{marginTop:'32px'}} className="btn btn-save" onClick={this.onClickFindCompany}>Buscar</Button>
            </div>
            <div className="col-md-4 col-xs-6 col-pull-right wrapper-btns-manager">

            </div>
          </div>

          <div className='row'>
            <div className="col-md-6">
              <b className='m-t-10 m-b-10'>{this.state.search_msg}</b>
            </div>
          </div>

          <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
            <div style={{marginLeft:"20px"}}>
              <KTableSmartSelect
                ref={ref => this.kTableSmartSelect = ref}
                onOptionSelected={(option) => this.onOptionSelected(option)}
                onCbClick={this.onCbClick}
                options={this.smartSelectOptions}>
              </KTableSmartSelect>
            </div>
            {this.renderActionButtons()}
          </div>
          <BootstrapTable
            bootstrap4
            striped
            keyField={'_id'}
            loading={this.state.loading}
            data={ companies }
            columns={ this.columns }
            selectRow={ selectRow }
            noDataIndication={this.state.table_msg}
            wrapperClasses="m-t-20 table-responsive"
            classes="react-bootstrap-table"
            overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(203, 255, 255, 0.5)'}) } })}
          />
        </div>
    )
  }

  render () {
    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Empresas</h3>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="list_companies">
                          <Nav className="nav" bsPrefix='tabCustom' >

                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="list_companies" id='list_companies'>Lista de empresas</Nav.Link>
                            </Nav.Item>

                          </Nav>

                          <Tab.Content className="tab-content">

                            <Tab.Pane eventKey="list_companies">
                              {this.renderCompanyList()}
                            </Tab.Pane>

                          </Tab.Content>
                        </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default ManagerCompaniesView;
