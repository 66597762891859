/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, February 2019
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import Footer from '../components/Footer';
import ProgramsResourcesPicker from '../components/ProgramsResourcesPicker';
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import KResourcesList from '../components/KResourcesList';
import KResourcesRow from '../components/KResourcesRow';
import { Link } from "react-router-dom";
import KImage from '../components/KImage';

import { isUserProfileComplete } from '../utils/Utils.js';
// Images
import {getSIVILogo} from "../utils/Vendor.js";
const SiviLogo = getSIVILogo();

class ResourcesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
			showTopbar: true,
      me:{},
      resources:[],
      resourcesLoadState:'default',
      selectedId:[0],
      programName:[0],
      loading: false
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.onProgramSelected             = this.onProgramSelected.bind(this);
    this.getResourcesByProgram         = this.getResourcesByProgram.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true,
            loading: true
          });
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  getResourcesByProgram(programId){
    KomunidadApi.getResourcesByProgram(programId).then((res) => {
       if(res.success){
         this.setState({resources:res.resources,resourcesLoadState:'success', loading: false});
       }else{
         this.setState({resources:[],resourcesLoadState:'failed', loading: false})
       }
    });
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  onProgramSelected(id,name){
    let programId = id[0];
    let programName = name;
    this.setState({
      selectedId: id,
      programName: programName
    })
    return this.getResourcesByProgram(programId);
  }

  renderDate(date){
    let moment = require('moment');
    let _date = new Date(date.replace('Z',''));

    _date = moment(_date).format('LL');

    return _date;
  }

  renderContent(){
    if(this.state.selectedId[0] === "6165b4afd7024e217389f618"){
      return this.renderSIVI()
    }else{
      return this.renderResourcesList()
    }
  }

  renderSIVI(){
    return(
      <div className="col-md-12 col-lg-12 col-xlg-12">
          <div className="card card-body mentor-card">
              <div className="row">
                  <div className="col-md-5 col-lg-5 text-center">
                    <KImage src={SiviLogo}
                            alt="sivi"
                            className="img-responsive"
                            onClick={() => this.handleProfileModal(true)}/>
                  </div>
                  <div className="col-md-7 col-lg-7">
                    <h3><p className="box-title m-b-0"style={{fontSize: '21px'}}>SIVI</p></h3>
                    <small className="text-muted">Sistema de Vinvulación - CCMX</small>
                    <div className='m-t-20 mb-2' style={{fontSize:'14px'}}>
                      <div>
                        <div className="">
                          El Sistema SIVI tiene la finalidad de que las MIPYMES beneficiadas en los programas del Centro de Competitividad en México (CCMX), sean visibles para los compradores de las Grandes Empresas o MIPYMES que también pasaron por el fortalecimiento empresarial y que requieran productos o servicios.<br/><br/>
                          Por tal motivo te invitamos a que participes en nuestros programas.<br/><br/>
                          De ser empresa registrada te invitamos a que des seguimiento a tu registro y a las solicitudes de las grandes empresas.
                        </div>
                      </div>
                      <br/>
                      {/* {this.renderLinkedIn()} */}
                      {/* <a className="box-title mb-1" href={`mailto:${mentor.email}`}>{mentor.email}</a> */}
                    </div>
                    <a className='btn btn-info btn-save text-white'
                      style={{marginLeft: 0, marginRight: ".50rem"}}
                      href="http://www.dat-mexico.com/CCMX/Default.aspx" target="_blank"
                      >Ingresar</a>
                  </div>
              </div>
          </div>
      </div>

    )
  }

  renderResourcesList(){
    const resources = this.state.resources || [];
    const columns = [
      {
        dataField: 'name',
        text: 'Nombre',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          return(
            <a href={row.url} target="_blank">{cellContent}</a>
          )
        }
      },
      {
        dataField: 'description',
        text: 'Descripción',
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'created_at',
        text: 'Fecha',
        style: {
          verticalAlign:'middle'
        },
        formatter:this.renderDate
      },
    ];

    return(
      <BootstrapTable
        bootstrap4
        striped
        keyField='_id'
        data={ resources }
        columns={ columns }
        noDataIndication={'No se encontraron recursos para este programa.'}
        wrapperClasses="table-responsive"
        classes="react-bootstrap-table"
      />
    )
  }

  render(){
   if(this.state.authorized){
     return(
       <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">

                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                     <h3 className="text-themecolor">Recursos y materiales de apoyo</h3>
                    </div>
                </div>
                <div className="row page-titles">
                  <div className="col-md-6 align-self-center">
                   <ProgramsResourcesPicker onSelect={this.onProgramSelected}
                                            multipleProgramSelected={this.state.selectedId}/>
                  </div>
                </div>
                {/* Start Page Content */}
                {this.renderContent()}
                {/* End PAge Content */}
               </div>
               <Footer/>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default ResourcesView;
