/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juan Hernandez <juan@startuplab.mx>, July 2020
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import KomunidadApi from '../api/api.js';
import { Button } from 'react-bootstrap';

// Components
import Swal from 'sweetalert2';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

/**
*   Enable/disable the program service requests.
*   Saves the service request email list.
*/

class KProgramMailToComponent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      accepts_service_requests:false,
      send_service_requests_to:'default', //default, email_list
      service_requests_email_list: [],
      values_changed:false,
      program_loaded:false,
    }
    this.handleRadioBtn       = this.handleRadioBtn.bind(this);
    this.cancel               = this.cancel.bind(this);
  }
  //----------------------------------------------------------------------
  static getDerivedStateFromProps(props, current_state){
    let program = props.program;
    let accepts_service_requests = true;
    let send_service_requests_to = 'default';
    let service_requests_email_list = [];
    if(program.hasOwnProperty("accepts_service_requests")){
      accepts_service_requests = program.accepts_service_requests;
    }
    if(program.hasOwnProperty("send_service_requests_to")){
      send_service_requests_to = program.send_service_requests_to;
    }
    if(program.hasOwnProperty("service_requests_email_list")){
      service_requests_email_list = program.service_requests_email_list;
    }

    if(!current_state.program_loaded){
      return{
        accepts_service_requests    : accepts_service_requests,
        send_service_requests_to    : send_service_requests_to,
        service_requests_email_list : service_requests_email_list,
        program_loaded              : true
      };
    }


  }

  handleRadioBtn(e){
    this.setState({[e.target.name]:e.target.value,values_changed:true})
  }

  renderControls(){
    return(
      <div className='form-group' style={{marginLeft:15}}>
        <div>Los correos referentes al programa como las solicitudes de servicio y los avances llegan a:</div>
        <div style={{display:"flex",flexDirection: "column",marginLeft:30,marginTop:10}}>
          <input
             id="rb_default"
             onChange={(e) => {this.handleRadioBtn(e)}}
             disabled={!this.props.editMode}
             checked={this.state.send_service_requests_to === 'default'}
             name="send_service_requests_to"
             type="radio"
             value="default"
             className="with-gap radio-col-purple"
          />
          <label for="rb_default">
             Directores de este programa
          </label>&nbsp;
          <input
             id="rb_email_list"
             checked={this.state.send_service_requests_to === 'email_list'}
             onChange={(e) => this.handleRadioBtn(e)}
             disabled={!this.props.editMode}
             name="send_service_requests_to"
             type="radio"
             value="email_list"
             className="with-gap radio-col-purple m-l-10"
          />
          <label for="rb_email_list">
             A la siguiente lista de correos:
          </label>
          <ReactMultiEmail
            placeholder="Escribe correos separados por coma"
            disabled={true}
            emails={this.state.service_requests_email_list}
            onChange={(_emails: string[]) => {
              this.setState({
                service_requests_email_list: _emails,
                values_changed:true
              });
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(
              email: string,
              index: number,
              removeEmail: (index: number) => void,
            ) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>

                  </span>
                </div>
             );
           }}/>

        </div>

      </div>
    )

  }
  render(){
    return (
      <div className='form-group'>
        <div className="switch">
          <div className='form-group'>
            {this.renderControls()}
          </div>
          {this.renderEditAndSaveBtns()}
        </div>
      </div>
    )
  }

  renderEditAndSaveBtns(){
    if(this.state.values_changed){
      return(
        <div style={{marginTop:'32px'}}>
          <Button className='btn btn-save'    onClick={() => this.save()}>Guardar</Button>
          <Button className='btn btn-danger'  onClick={() => this.cancel()}>Cancelar</Button>
        </div>
      )
    }else{
      return(
        <div style={{marginTop:'32px'}}>

        </div>
      )
    }
  }
  toggleEditMode(value) {
    this.props.toggleEditing(value);
  }
  cancel(){
    let send_service_requests_to = 'default';
    if(this.props.program.hasOwnProperty("send_service_requests_to")){
      send_service_requests_to = this.props.program.send_service_requests_to;
    }
    let service_requests_email_list = [];
    if(this.props.program.hasOwnProperty("service_requests_email_list")){
      service_requests_email_list = this.props.program.service_requests_email_list;
    }
    this.setState({
      send_service_requests_to:send_service_requests_to,
      service_requests_email_list:service_requests_email_list,
      values_changed:false
    });
  }
  save(){
    let emails = this.state.service_requests_email_list.join("|");
    KomunidadApi.updateProgramServiceRequestsEmailSettings(
      this.props.program._id,this.state.send_service_requests_to,emails
    ).then((res) => {
      if(res.success){
        this.setState({
          values_changed : false
        }, () =>{
          this.props.onUpdateProgram();
          Swal.fire({
            type: 'success',
            title: 'Destinatarios de correo actualizados',
            text: 'Se cambiaron las direcciones de los destinatarios de correo',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });

        });
      }else{
        Swal.fire({
          type: 'error',
          title: 'Hubo un problema al tratar de guardar el programa',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

}

KProgramMailToComponent.propTypes = {
  /** Program object. */
  program            : PropTypes.object,

  /** True when the program is being edited. */
  editMode           : true,

  /** Change the state editMode*/
  toggleEditing      : PropTypes.func,

  /** Callback when program is updated. */
  onUpdateProgram    : PropTypes.func
};

KProgramMailToComponent.defaultProps = {
  program            : {},
  editMode           : true,
  toggleEditing      : () => {},
  onUpdateProgram    : () => {}
};

export default KProgramMailToComponent;
