/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx>, August 2019
 */

// Regular imports
import React from 'react';
import { Link } from "react-router-dom";
import KomunidadApi from '../../api/api.js';
import { validateEmail, validateStringLength } from '../../utils/Utils.js';
import Swal from 'sweetalert2';
import AdBlockComponent from '../../components/adblock/AdBlockComponent';
import { getProfileStatus } from '../../utils/Utils.js';

//version
import pkg from '../../../package.json'
// Custom styling
import '../../scss/pages/login-register-lock.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/pages.scss';
import '../../scss/pages/_login.scss';
import '../../scss/pages/_landing-modal.scss';

// Images
import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();


class LoginView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      modal_open: false,
      error_message: '',
    }

    this.failed_attempts = 0;
    this.handleSubmit          = this.handleSubmit.bind(this);
    this.handleChanges         = this.handleChanges.bind(this);
    this.hideErrorMessage      = this.hideErrorMessage.bind(this);
  }

  componentDidMount(){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('email');

    if(email !== undefined && email !== '' && email !== null) {
      this.setState({email: email});
    }

    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        getProfileStatus(res.user).then( statusObj => {
          this.props.history.push(statusObj.redirectPath);
        }).catch( err => {
          this.setState({ error_message : 'Servicio no disponible. Intenta nuevamente más tarde.' });
        });
      }
    });
  }

  handleChanges (event) {
    this.setState({ [event.target.id] : event.target.value });
  }

  toggleModal() {
    this.setState({
      modal_open: !this.state.modal_open
    });
  }

  hideErrorMessage() {
    setTimeout( () => {
      this.setState({
        register_error_message: '',
        error_message: ''
      });
    }, 3000);
  }

  aceptTerms() {
    this.setState({
      aceptedTerms: !this.state.aceptedTerms
    });
  }

  swalSuccess(email){
    Swal.fire({
      title: 'Registro exitoso',
      html:`Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    })
  }

  registerSubmit (event) {
    event.preventDefault();
    this.setState({ register_error_message : '' })

    if(this.state.aceptedTerms) {
      if (validateEmail(this.state.register_email)) {
        KomunidadApi.userPreRegister(this.state.register_email).then(res => {
          if (res.body.success) {
            this.toggleModal();
            this.swalSuccess(this.state.register_email);
            this.setState({
              register_email: '',
            });
            this.aceptTerms();
          } else {
            this.setState({
              register_email: '',
              register_error_message: res.body.message_es
            });
            this.hideErrorMessage();
          }
        }).catch(err => {
          this.setState({ register_error_message: 'Servicio no disponible. Intente de nuevo más tarde.' })
          this.hideErrorMessage();
        });
      }
    } else {
      this.setState({ register_error_message: 'Debes aceptar términos y condiciones para registrarte.' })
      this.hideErrorMessage();
    }
  }

  renderAdBlockDetect() {
    return(
      <AdBlockComponent/>
    );
  }
  showSuspendedAccountSwal(){
    Swal.fire({
      title: 'Cuenta suspendida',
      html:`Su cuenta ha sido suspendida. Por favor contacte al gestor de esta instalación`,
      type: 'error',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    })
  }
  handleSubmit (event) {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    event.preventDefault();
    this.setState({ error_message : '' });

    if (this.validateForm()) {
      KomunidadApi.login(this.state.email, this.state.password).then(res => {
        if(!res.hasOwnProperty("success")){
            throw Error("Network Error");
        }
        if (res.success) {
          this.failed_attempts = 0;
          KomunidadApi.getMe().then( (res) => {
            if(res.valid_token){
              getProfileStatus(res.user).then( statusObj => {
                if(programIdQuery !== null) {
                  this.props.history.push({
                    pathname: '/join_program',
                    search: `?programid=${programIdQuery}`
                  });
                } else {
                  this.props.history.push(statusObj.redirectPath);
                }
              }).catch( err => {
                this.setState({ error_message : 'Servicio no disponible. Intenta nuevamente más tarde.' });
              });
            }
          });
        } else {
          if(res.account_disabled){
            return this.showSuspendedAccountSwal();
          }
          this.failed_attempts++;
          this.setState({error_message : 'Error de usuario contraseña. Intenta nuevamente'});

          if (this.failed_attempts === 5) {
            this.props.history.push('/forgot-password');
          }
        }
      }).catch(err => {
        this.setState({ error_message : 'Servicio no disponible. Intenta nuevamente más tarde.' });
      })
    }
  }

  validateForm() {
    if (!validateEmail(this.state.email)) {
      this.setState({ error_message : 'El correo electrónico no es válido' });
    } else if (!validateStringLength(this.state.password, 5)) {
      this.setState({ error_message : 'La contraseña tiene que tener más de 5 caracteres' });
    } else {
      return true;
    }

    return false;
  }

  renderLinkButton() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');

    if(programIdQuery !== null) {
      return(
        <div>¿No tienes cuenta? <Link to={{ pathname: '/sign-up', search: `?programid=${programIdQuery}`}}>Registrate</Link></div>
      )
    } else {
      return(
        <div>¿No tienes cuenta? <Link to={{ pathname: '/sign-up'}}>Registrate</Link></div>
      )
    }  

  }

  render() {
    return (
      <section id="wrapper">
        <div className="d-flex no-block justify-content-center align-items-center" style={{ backgroundColor: '#F2F4F5', position: 'relative', minHeight: '100vh'}}>
          <div style={{position: 'absolute', bottom: 0, width:'100%'}}>
            <div className="text-center row justify-content-md-center">
              <div className="col col-lg-8" style={{bottom: '12px', fontSize: '14px'}}>
                <a rel="noopener noreferrer" href="https://www.komunidad.io" target="_blank">Nosotros</a> |
                <a rel="noopener noreferrer" href="https://komunidad.freshdesk.com/support/home" target="_blank"> Soporte</a> |
                <a rel="noopener noreferrer" href="https://komunidad.io/aviso.html" target="_blank"> Aviso de Privacidad</a> |
                <a rel="noopener noreferrer" href="https://komunidad.io/aviso.html" target="_blank"> Términos y Condiciones</a>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className="col-12 mb-5">
              <a className="text-center db" href="https://www.komunidad.io/">
                <img src={ Logo } alt="Home" className="AppLogo" />
              </a>
            </div>
            <div className="col-md-12">
              <div className="card-container card border-0">
                <div className="card-body">
                  <h2 className="m-0"><b>¡Bienvenido a CREO MX!</b></h2>
                  <div className="form-group row my-3">
                    <div className="col-md-8">
                      <div className="float-left" style={{color: '#67757c',fontSize: '14px'}}>
                        {this.renderLinkButton()}
                      </div>
                    </div>
                  </div>
                  <form className="form-horizontal" id="loginform" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <div className="col-xs-12">
                        {/*<label>Correo electrónico</label>*/}
                        <input className="form-control" id="email" value={this.state.email} onChange={this.handleChanges} placeholder="tucorreo@tucorreo.com" type="email"/>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <div className="col-xs-12">
                        {/*<label>Contraseña</label>*/}
                        <input className="form-control" id="password" value={this.state.password} onChange={this.handleChanges} placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" type="password"/>
                      </div>
                    </div>

                    <p className="text-error my-1">{this.state.error_message}</p>

                    {/*<div className="form-group m-0">
                      <div className="col-xs-12">
                        {this.renderAdBlockDetect()}
                      </div>
                    </div>*/}

                    <div className="form-group row mb-3">
                      <div className="col-md-12">
                        <Link className="float-left" style={{fontSize: '14px', paddingTop: '5px'}} to={{ pathname: '/forgot-password', params: {email: this.state.email} }}>
                          ¿Olvidaste tu contraseña?
                        </Link>
                        <div className="float-right">
                          <button className="btn btn-info btn-block btn-rounded" style={{borderRadius: '.25rem',padding: '5px'}} type="submit">Iniciar sesión</button>
                        </div>
                      </div>
                    </div>

                  </form>
                  <div className="container text-muted text-center" style={{fontSize: '14px'}}>
                    <p style={{color: '#67757c', display: 'inline'}}>Komunidad IO © 2024 - v{pkg.version}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LoginView;
