/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>, November 2019
 */

// Regular imports
import React from 'react'

// external components
import Alert from 'react-bootstrap/Alert'
import AdBlockDetect from 'react-ad-block-detect';

class AdBlockComponent extends React.Component {

  render () {
    return (
      <AdBlockDetect>
        <Alert variant={'warning'} style={{fontSize: "14px"}}>
          ¡Adblocker detectado! <p>Algunos bloqueadores de anuncios afectan a Komunidad. Si tienes problemas iniciando sesión, por favor desactivalo.</p>
        </Alert>
      </AdBlockDetect>
    );
  }

}


export default AdBlockComponent;
