/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, June 2019
 */

import React from 'react';
import './KEditableInput.css';
import PropTypes from 'prop-types';

class KEditableInput extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      value: '',
      originalValue:'',
      chartCount:0,
    };
    this.editDone       = this.editDone.bind(this);
    this.editMode       = this.editMode.bind(this);
    this.onChange       = this.onChange.bind(this);
    this.cancel         = this.cancel.bind(this);
    this.getValue       = this.getValue.bind(this);
    this.onDoubleClick  = this.onDoubleClick.bind(this);
    this.onPostEditDone = this.onPostEditDone.bind(this);

  }

  componentWillMount() {
    this.setState({
      editMode: this.props.editMode,
      value: this.props.value,
      originalValue: this.props.value
    })
  }

  // componentDidUpdate (prevProps, prevState) {
  //   if ((this.props.value && !this.state.value) && prevState.value != this.props.value) {
  //     this.setState({
  //       editMode: this.props.editMode,
  //       value: this.props.value,
  //       originalValue: this.props.value
  //     })
  //
  //     this.props.onChange(this.props.value)
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editMode !== this.state.editMode) {
      this.setState({ editMode: nextProps.editMode});
    }
    if((nextProps.value  !== this.state.value) && (!this.state.editMode)){
      this.setState({value:nextProps.value});
    }
  }

  getValue(){
    return this.state.value;
  }
  editMode(value){
    this.setState({
      editMode:value
    });
  }

  editDone(){
      let validatorResult = this.props.validator(this.state.value);
      if(validatorResult.result){
        this.onPostEditDone(this.state.value);
        this.props.onEditDone(this.state.value);
        this.props.onValidateSuccess(this.state.value);
      }else{
        this.props.onValidateFail(validatorResult.reason);

      }

  }
  onPostEditDone(value){
    let postValue = this.props.onPostEditDone(value);
    this.setState({
      value:postValue
    })

  }

  onChange(event){
    let value = event.target.value;

    value = this.props.capitalize ? value.toUpperCase() : value;
    if(this.state.maxLength !== null){
      this.setState({chartCount: value.length});
    }
    this.setState({
      value:value
    });
    let validatorResult = this.props.validator(value);
    if(validatorResult.result){
      this.props.onValidateSuccess(value);
    }
    this.props.onChange(value);
  }
  cancel(){
    this.setState({value:this.state.originalValue})
  }
  renderLabel(){
    if(!this.props.labelAlwaysVisible){
      return null;
    }
    if(this.props.labelAlwaysVisible || this.state.editMode){
      return (
        <div className='KEI_label'>{this.props.label}</div>
      )
    }else{
      return null
    }
  }
  isLink(){
    var re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return re.test(this.state.value)
  }
  onDoubleClick(){
    if(!this.isLink()){
      this.props.onDoubleClick()
    }
  }
  renderRequired(){
    if(this.props.isRequired){
      return (<div className="KEI_required">*</div>);
    }else{
      return (null);
    }
  }
  renderContent(){
    if(this.isLink()){
      return (<a href={this.state.value} rel="noopener noreferrer" target="_blank">{this.state.value}</a>)
    }else{
      return (this.state.value);
    }
  }
  isValid(){
    return this.props.validator(this.state.value).result;
  }
  validate(){
    return this.props.validator(this.state.value);
  }
  renderElement(){
    if(this.props.type === 'text-area'){
      return(
          <textarea   type='text'
                      className='KEI_textArea form-control'
                      required
                      maxlength={this.props.maxLength}
                      value={this.state.value}
                      onChange={this.onChange}
                      placeholder={this.props.placeholder}
                      onBlur={
                        () => this.editDone()
                      }
                      onKeyPress={
                        event => {
                          if (event.key === "Enter"){
                            this.editDone()
                          }

                        }
                      }
                      onKeyDown={
                        event => {
                          if (event.key === "Tab"){
                            this.editDone()
                          }
                          if(event.keyCode === 27){
                            this.cancel();
                          }
                        }
                      }
          >
          </textarea>
      )
    }else{
      return(
        <input
          className='KEI_input form-control form-control-line'
          required
          value={this.state.value}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
          type={this.props.inputType}
          onBlur={
            () => this.editDone()
          }
          onKeyPress={
            event => {
              if (event.key === "Enter"){
                this.editDone()
              }

            }
          }
          onKeyDown={
            event => {
              if (event.key === "Tab"){
                this.editDone()
              }
              if(event.keyCode === 27){
                this.cancel();
              }
            }
          }
        />
      )
    }
  }

  renderCounter(){
    if(this.props.maxLength !== null){
      return(
        <span className="KEICounter">
          {this.state.chartCount}/{this.props.maxLength}
        </span>
      )
    }else{
      return null
    }
  }

  render(){
    if(this.state.editMode){
      return(
        <div className='KEI_wrapper'>
          {this.renderLabel()}
          {this.renderElement()}
          {this.renderCounter()}
          {this.renderRequired()}
        </div>
      )
    }else{
      return(
        <div className='KEI_wrapper'>
          {this.renderLabel()}
          <div className='profileHoverLabel' onDoubleClick={this.onDoubleClick}>
            {this.renderContent()}
          </div>
        </div>
      )
    }
  }

}


KEditableInput.propTypes = {
  editable            : PropTypes.bool,
  editMode            : PropTypes.bool,
  value               : PropTypes.string,
  onEditDone          : PropTypes.func,
  placeholder         : PropTypes.string,
  validator           : PropTypes.func,
  onSubmit            : PropTypes.func,
  onValidateFail      : PropTypes.func,
  label               : PropTypes.string,
  isRequired          : PropTypes.bool,
  onDoubleClick       : PropTypes.func,
  labelAlwaysVisible  : PropTypes.bool,
  capitalize          : PropTypes.bool,
  onPostEditDone      : PropTypes.func,
  maxLength           : PropTypes.number,
  type                : PropTypes.string,
  inputType           : PropTypes.string,
  onChange            : PropTypes.func,
};

KEditableInput.defaultProps = {
  editable            : true,
  editMode            : true,
  value               : '',
  onEditDone          : ()=>{},
  placeholder         : '',
  validator           : (val) => {
    let validatorResponse = {
      result:true,
      reason:null
    }
    return validatorResponse
  },
  onValidateFail      : (reason) => {},
  onValidateSuccess   : (val) => {},
  onDoubleClick       : () => {},
  onSubmit            : (val) => {},
  string              : '',
  isRequired          : false,
  labelAlwaysVisible  : false,
  capitalize          : false,
  onPostEditDone      : (value) => {return value},
  maxLength           : null,
  type                : 'input',
  inputType           : 'text',
  onChange            : (value) => {}
};

export default KEditableInput;
