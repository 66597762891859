/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, July 2019 &
 * America Mendoza <america@startuplab.mx>, August 2019 &
 * Darien Miranda <darien@startuplab.mx, August 2019
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KomunidadApi from './../../api/api.js';
import {
  transformToMoneyFormat, transformToUpperCase, editTransformToPhoneNumber,
  transformToPhone, transformToMoney
} from './../form/transforms.js';

// Components
import Swal from 'sweetalert2';
import FormInput from './../form/FormInput';



/**
 *  Display and edit the company information (Pyme phase content).
 */

class KTabPymeContent extends Component{
  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      company:{},
      isFormEditing:false,
      formData: {
      },
      neighborhoodCollection:[]
    }
    this.updateData                   = this.updateData.bind(this);
    this.cancelEdit                   = this.cancelEdit.bind(this);
    this.toggleEditMode               = this.toggleEditMode.bind(this);
    this.openCancelSwal               = this.openCancelSwal.bind(this);
    this.openLoadingSwal              = this.openLoadingSwal.bind(this);
    this.setInitialValues             = this.setInitialValues.bind(this);
    this.renderAddInfoButton          = this.renderAddInfoButton.bind(this);
    this.validateForm                 = this.validateForm.bind(this);
    this.renderActionButtons          = this.renderActionButtons.bind(this);
  }

  componentDidMount(){
    this.setInitialValues(this.props.company);
  }

  setInitialValues(company) {
    this.setState({
      name: company.name,
      formData: {
        ...this.state.formData,
        goverment_id_rfc: company.goverment_id_rfc || '',
        goverment_legal_name: company.goverment_legal_name || '',
        phone: company.phone || '',
        stats_sales: company.stats_sales || '',
        gathered_capital: company.gathered_capital || '',
        net_spending: company.net_spending || '',
      },
      neighborhoodCollection:[]
    })
  }

  formIsEditing(editing){
    if(!(this.state.isFormEditing && editing)){
      this.setState({isFormEditing:editing});
      this.props.isTabEditing(editing);
    }
  }
  cancelEdit () {
    this.cancelForm();
    this.formIsEditing(false);

    this.setState({
      editMode: false,
    })
  }

  toggleEditMode () {
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }))
  }
  
  renderAddInfoButton() {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div className="m-b-20">
            <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
            </button>
          </div>
        )
      } else {
        return (
          <div className="m-b-20">
            <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
              <i className="mdi mdi-plus mr-1" style={{fontSize: '16px'}}></i> 
              Agregar información
            </button>
          </div>
        )
      }
    }else{
      return null
    }
  }

  renderActionButtons () {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div>
             <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
             </button>

             <button className="btn btn-danger btn-cancel" onClick={ this.openCancelSwal }>
               <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                Cancelar
             </button>
          </div>
        )
      } else {
        return (
          <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
            <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
              Editar
          </button>
        )
      }
    }else{
      return null
    }
  }

  updateData () {
    if(this.validateForm()){
      this.openLoadingSwal();
      let formData                = this.state.formData;
      formData.stats_sales        = this.fi_stats_sales.getCleanValue();
      formData.gathered_capital   = this.fi_gathered_capital.getCleanValue();
      formData.net_spending       = this.fi_net_spending.getCleanValue();
      formData.phone              = this.fi_phone.getCleanValue();
      KomunidadApi.updateCompany(this.props.company._id, formData).then(res => {
        this.formIsEditing(false);
        Swal.close();

        if(!res.success){
          Swal.fire({
						type: 'error',
						title: 'No pudimos guardar tus datos',
						text: 'Hubo un error al guardar tus datos',
						footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
					});
        }else{
          this.props.refreshCompany();
          this.toggleEditMode();
        }
      })
    }
  }

  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.formIsEditing(true);

    this.setState({
      formData: {
        ...this.state.formData,
        [data.model]: value
      }
    });
  }

  validateForm(){
    return this.fi_goverment_id_rfc.validate() &&
            this.fi_goverment_legal_name.validate() &&
            this.fi_phone.validate() &&
            this.fi_stats_sales.validate() &&
            this.fi_gathered_capital.validate() &&
            this.fi_net_spending.validate();
  }

  cancelForm(){
    //remove values
    this.fi_goverment_id_rfc.cancel();
    this.fi_goverment_legal_name.cancel();
    this.fi_phone.cancel();
    this.fi_stats_sales.cancel();
    this.fi_gathered_capital.cancel();
    this.fi_net_spending.cancel();
  }

  render(){
    return (
      <div className="card-body">
        <div className="row view-subtittle">
          <div className="col-md-9">
            <p>En esta etapa inicias con las primeras ventas por lo que es importante estar dado de alta ante hacienda. </p>
          </div>
        </div>
        <div className="form-horizontal">
          { this.renderAddInfoButton() }
          <h3><span className="lstick m-r-20"></span>Información de empresa</h3>
          <div className='m-l-20'>
            <FormInput
              ref={ref => this.fi_goverment_id_rfc = ref}
              id="rfc"
              model="goverment_id_rfc"
              label="Registro Federal de Contribuyentes"
              cssClass="custom-class"
              editMode={this.state.editMode}
              showCounter={true}
              value={this.state.formData.goverment_id_rfc}
              initialValue={this.props.company.goverment_id_rfc}
              help="Escribe el RFC de tu empresa sin guiones o espacios."
              maxLength={13}
              validationRules="rfc"
              transformMethod={ transformToUpperCase }
              onChange= {change => { this.onChangeForm(change) }}
              />
            <FormInput
              ref={ref => this.fi_goverment_legal_name = ref}
              id="razón social"
              model="goverment_legal_name"
              label="Razón social"
              cssClass="custom-class"
              editMode={this.state.editMode}
              showCounter={true}
              value={this.state.formData.goverment_legal_name}
              initialValue={this.props.company.goverment_legal_name}
              help="Puedes estar dado de alta como persona moral o física. En caso de que estes dado de alta como persona física debes de volver a ingresar tu nombre (Mínimo 3 caracteres)."
              maxLength={140}
              validationRules="minLen:3|maxLen:140"
              onChange= {change => { this.onChangeForm(change) }}
              />
            <FormInput
              ref={ref => this.fi_phone = ref}
              id="teléfono"
              model="phone"
              label="Teléfono"
              cssClass="custom-class"
              editMode={this.state.editMode}
              value={this.state.formData.phone}
              initialValue={this.props.company.phone}
              help="Escribe el teléfono de contacto de tu empresa (10 caracteres obligatorio)."
              maxLength={14}
              transformDisplayMethod={transformToPhone}
              transformMethod={editTransformToPhoneNumber}
              validationRules="phone|minLen:10|maxLen:10"
              onChange= {change => { this.onChangeForm(change) }}
              />
          </div>

          <h3><span className="lstick m-r-20"></span>Datos financieros</h3>
          <div className='m-l-20'>
            <FormInput
              ref={ref => this.fi_stats_sales = ref}
              id="número de ventas anuales"
              model="stats_sales"
              label="Ventas anuales"
              cssClass="custom-class"
              editMode={this.state.editMode}
              value={this.state.formData.stats_sales}
              initialValue={this.props.company.stats_sales}
              help="Escribe sin espacios o comas tus ventas anuales del año pasado (aproximadamente) en pesos MXN."
              maxLength={20}
              transformMethod={transformToMoney}
              transformDisplayMethod={ transformToMoneyFormat }
              onChange= {change => { this.onChangeForm(change) }}
              />
            <FormInput
              ref={ref => this.fi_gathered_capital = ref}
              id="capital recaudado"
              label="Capital recaudado"
              model="gathered_capital"
              cssClass="custom-class"
              editMode={this.state.editMode}
              value={this.state.formData.gathered_capital}
              initialValue={this.props.company.gathered_capital}
              help="En caso de haber recibido inversión ingresa el monto en pesos MXN. Si no has recibido inversión escribe 0 (cero)."
              maxLength={20}
              validationRules="isNumber"
              transformMethod={transformToMoney}
              transformDisplayMethod={ transformToMoneyFormat }
              onChange={ change => { this.onChangeForm(change) } }
              />
            <FormInput
              ref={ref => this.fi_net_spending = ref}
              id="gasto neto mensual"
              label="Gasto de operación mensual"
              model="net_spending"
              cssClass="custom-class"
              editMode={this.state.editMode}
              value={this.state.formData.net_spending}
              initialValue={this.props.company.net_spending}
              help="Costo mensual de operar tu empresa(pesos)."
              maxLength={20}
              validationRules="isNumber"
              transformMethod={transformToMoney}
              transformDisplayMethod={ transformToMoneyFormat }
              onChange={ change => { this.onChangeForm(change) } }
              />
          </div>

          { this.renderActionButtons() }
        </div>
      </div>
    )
  }

  openLoadingSwal() {
    Swal.fire({
      title: 'Tus datos están siendo guardados',
      customClass: {
        title: 'swal-custom-title',
      },
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  openCancelSwal() {
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then(res => {
      if (res.value) this.cancelEdit();
    })
  }

}

KTabPymeContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
  /** True while the company is being edited */
  isTabEditing   : PropTypes.func,
  /** Callback to update the company data*/
  refreshCompany : PropTypes.func,
  /** True if the information can't be edited*/
  nonEditable    : PropTypes.bool,
};

KTabPymeContent.defaultProps = {
  company        : {},
  isTabEditing   : () => {},
  refreshCompany : () => {},
  nonEditable    : false,
};

export default KTabPymeContent;
