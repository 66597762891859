/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, March 2020
 */
//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/components/_eb_components.scss';


/**
*  Show an Eventbrite event. Used by [EBListEvents](/#EBListEvents).
*/

class EBCalendarDate extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEvent    = this.onClickEvent.bind(this);
    this.months = ["ENE","FEB","MAR","ABR","MAY","JUN","JUL","AGO","SEP","OCT","NOV","DIC"];
  }
  calcDate(){
    let date  = this.props.date;
    let month = this.months[date.getMonth()];
    return month;
  }
  calcDay(){
    return this.props.date.getDay().toString().padStart(2,"0");
  }
  onClickEvent(review){
    this.props.onClickEvent(review);
  }
  render() {
    return(
      <div className="eb-date-container">
        <div className="eb-date-month">
          {this.calcDate()}
        </div>
        <div className="eb-date-day">
          {this.calcDay()}
        </div>
      </div>
    )
  }
}

EBCalendarDate.propTypes = {
  /** The date */
  date              : PropTypes.instanceOf(Date),
  /** Callback on click review*/
  onClickEvent      : PropTypes.func,
};

EBCalendarDate.defaultProps = {
  review            : {},
  onClickEvent      : ()=>{}
};

export default EBCalendarDate;
