/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, October 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import {Tab,Nav} from 'react-bootstrap';
import Swal from 'sweetalert2';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import HeaderFree from '../components/HeaderFree';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import ProgramsPicker from '../components/ProgramsPicker';

// Validations
import { isUserProfileComplete } from '../utils/Utils.js';

class LiveFreeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized:true,
            sidebarVisible: true,
            sidebarStatus: false,
			      showTopbar: true,
            preuser_email: '',
            me:{},
            resources:[],
            resourcesLoadState:'default',
            selectedId:[0],
            programName:[0],
            showWelcomeModal:true,
            loading: false
        }
        this.handleResizeBar               = this.handleResizeBar.bind(this);
        this.handleHeaderResize            = this.handleHeaderResize.bind(this);
        this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
        this.onProgramSelected             = this.onProgramSelected.bind(this);
        this.handleSelectedTab             = this.handleSelectedTab.bind(this);
        this.getLiveViewByProgram          = this.getLiveViewByProgram.bind(this);
        this.openConfirmationModal         = this.openConfirmationModal.bind(this);
    }

    componentDidMount(){
        KomunidadApi.getMe().then( (res) => {
        if(res.valid_token){
            if(!isUserProfileComplete(res.user)){
                this.redirectToEditProfile();
            }else{
              this.props.history.push('/live');
            }
        }else{
          let search = this.props.location.search;
          let params = new URLSearchParams(search);
          let email = params.get('email');
          let name = params.get('name');
    
          if (email !== null) {
            this.setState({
              preuser_email: email,
              name: name
            });
          }
            //redirect profile
            // this.props.history.push('/profile');
        }
        });
        window.addEventListener("resize", this.handleResizeBar);
        this.handleResizeBar()
    }

    redirectToEditProfile(){
        this.props.history.push('/profile/edit');
    }


    handleHeaderResize(width){
        this.setState({
            sidebarVisible:width <= 500 ? false : true
        });
    }

    handleResizeBar(method) {
        const windowSize = window.innerWidth;
        if(method === 'byClick') {
          this.setState({
            sidebarStatus: !this.state.sidebarStatus,
          })
        } else {
          if(windowSize <= 994) {
            this.setState({
              sidebarStatus: true,
              showTopbar: false
            })
          } else {
            this.setState({
              sidebarStatus: false,
              showTopbar: true
            })
          }
        }
      }

    onExitModal(){
        localStorage.setItem('welcomeModal', 'showed');
    }
    handleSelectedTab(tab){
      // if(tab === "event_tab"){
      //   window.location.reload();
      // }
      this.setState({
        keyEventTabs: tab
      });
    }
    renderSelectedTab(tab){
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card mb-0">
                <div className="card-body">
                  {
                    {
                      'event_tab': this.renderLiveIframe(),
                      'event_tab_2': this.renderLiveIframe_2(),
                      'schedule_tab': this.renderScheduleIframe(),
                      'partnership_tab': this.renderPartnershipIframe()
                    }[tab]
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

  renderLiveIframe(){
    const iframe = this.state.iframe;
    if(this.state.loading === false && iframe !== ""){
        return(
          <div className="container text-center pt-3">
                <div className="live-frame">
                  <iframe src={iframe} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
          </div>
        )
    }else{
      return this.renderNotAvailable()
    }
  }
  renderLiveIframe_2(){
    const iframe_2 = this.state.iframe_2;
    if(this.state.loading === false && iframe_2 !== ""){
        return(
          <div className="container text-center pt-3">
                <div className="live-frame">
                  <iframe src={iframe_2} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
          </div>
        )
    }else{
      return this.renderNotAvailable()
    }
  }
  renderScheduleIframe(){
    const schedule = this.state.schedule;
    if(this.state.loading === false && schedule !== ""){
        if(this.state.selectedId[0] === "6165b4afd7024e217389f618"){
          return(
            <div className="container text-center pt-3">
                  <div className="container" style={{height:"4000px"}}>
                    <iframe src={schedule} className="" style={{height:"100%", width:"100%"}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
            </div>
          )
        }else{
          return(
            <div className="container text-center pt-3">
                  <div className="live-frame">
                    <iframe src={schedule} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
            </div>
          )
        }
    }else{
        return this.renderNotAvailable()
    }
  }
  renderPartnershipIframe(){
      const partnership = this.state.partnership;
      if(this.state.loading === false && partnership !== ""){
          return(
              <div className="container text-center pt-3">
                   <div className="live-frame">
                      <iframe src={partnership} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                   </div>
              </div>
          )
      }else{
          return this.renderNotAvailable()
      }
  }

  renderNotAvailable(){
    return(
      <div className="text-center m-t-30">
        <h3>Este evento aún no tiene transmisión en vivo</h3>
        <i className="mdi mdi-play m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
      </div>
    )
}
    

  openConfirmationModal() {
    let email = this.state.preuser_email;

    if (email !== '') {
      Swal.fire({
        title: 'Confirma tu correo',
        html: `Para desbloquear todo el contenido de CREO MX revisa tu correo electrónico <b>${this.state.preuser_email}</b> (no olvides la carpeta de SPAM) y confirma tu cuenta.`,
        type: 'info',
        confirmButtonText: 'Reenviar correo',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {
        if (result.value) {
          this.resendPrc();
        }
      }); 
    } else {
      this.props.history.push('/sign-up');
    }
  }

  resendPrc() {
    let email = this.state.preuser_email;
    if (email !== '') {
      KomunidadApi.userPreRegister(this.state.preuser_email).then(res => {
        if (res.success) {
          this.swalSuccess(this.state.preuser_email);
        } else {
          this.swalError(this.state.preuser_email);
        }
      }).catch(err => { });
    } else {
      this.props.history.push('/sign-up');
    }
  }

  swalSuccess(email) {
    Swal.fire({
      title: 'Reenvío exitoso',
      html: `Hemos enviado un correo a <b>${email}</b> con instrucciones para activar tu cuenta. (Recuerda revisar tu bandeja de correo no deseado)`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    })
  }

  swalError(email) {
    Swal.fire({
      title: 'Felicidades ya tienes cuenta en Komunidad',
      html: `El correo <b>${email}</b> ya se encuentra registrado, serás redireccionado al login para iniciar sesión`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => { Swal.hideLoading() }, 5000)
      },
    }).then(() => {
      this.props.history.push('/');
    })
  }
    onProgramSelected(program){
      let programId = program._id;
      let programName = program.name;
      this.setState({
        selectedId: [programId],
        programName: programName
      })
      // this.handleSelectedTab('schedule_tab')
      return this.getLiveViewByProgram(programId);
  }

  getLiveViewByProgram(programId){
    KomunidadApi.getLiveViewByFreeProgram(programId).then((res) => {
       if(res.success){
         this.setState({iframe:res.iframe,iframe_2:res.iframe_2,schedule:res.schedule,partnership:res.partnership,liveViewLoadState:'success', loading: false});
       }else{
         this.setState({iframe:"",iframe_2:"",schedule:"",partnership:"",liveViewLoadState:'failed', loading: false})
       }
    });
  }


  render(){
   if(this.state.authorized){
     return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
         <div id="main-wrapper" className="height100">
            <HeaderFree
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              // user={this.state.me}
              freeMode={true}
              hideTopBar={!this.state.showTopbar}
              onClickSidebar={() => this.openConfirmationModal()}
              />
            <SideBar 
              // user={this.state.me}
              freeMode={true} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
              onClickSidebar={() => this.openConfirmationModal()}
            />
           {/* Page wrapper  */}
           <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">
                {/* Start Page Content */}
                <div className="row page-titles">
                    <div className="col-md-6 align-self-center">
                      <ProgramsPicker
                        selectedProgram={this.state.selectedId}
                        onChange={(program) => {this.onProgramSelected(program)}}
                        showInactivePrograms={true}
                        freeMode={true}
                        type={'public'}
                      ></ProgramsPicker>
                  </div>
                </div>

                <div className="card">
                    <Tab.Container unmountOnExit={true} id="tab_event_view" defaultActiveKey="event_tab" activeKey={this.state.keyEventTabs} onSelect={(tab) => this.handleSelectedTab(tab)}>
                        <Nav className="nav" bsPrefix="tabCustom">
                        {this.state.iframe && <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link"
                            eventKey="event_tab"
                            id="event_tab"
                            >
                            En vivo (Sala 1)
                            </Nav.Link>
                        </Nav.Item>}
                        {this.state.iframe_2 && <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link"
                            eventKey="event_tab_2"
                            id="event_tab_2"
                            >
                            En vivo (Sala 2)
                            </Nav.Link>
                        </Nav.Item>}
                        {this.state.schedule && <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link"
                            eventKey="schedule_tab"
                            id="schedule_tab"
                            >
                            Agenda
                            </Nav.Link>
                        </Nav.Item>}
                        {this.state.partnership && <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link"
                            eventKey="partnership_tab"
                            id="partnership_tab"
                            >
                            Aliados - Patrocinadores
                            </Nav.Link>
                        </Nav.Item>}
                        </Nav>
                        <Tab.Content className="tab-content">
                        <Tab.Pane eventKey="event_tab">
                            {this.renderSelectedTab('event_tab')}
                        </Tab.Pane>
                        <Tab.Pane eventKey="event_tab_2">
                            {this.renderSelectedTab('event_tab_2')}
                        </Tab.Pane>
                        <Tab.Pane eventKey="schedule_tab">
                            {this.renderSelectedTab('schedule_tab')}
                        </Tab.Pane>
                        <Tab.Pane eventKey="partnership_tab">
                            {this.renderSelectedTab('partnership_tab')}
                        </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                {/* End PAge Content */}
               </div>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default LiveFreeView;
