/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano & Darién Miranda & América Mendoza <Isra_darrell@startuplab.mx , darien@startuplab.mx, america@startuplab.mx> June 2019
 */

//Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import {  animateScroll as  scroller } from 'react-scroll'
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import Swal from 'sweetalert2';
import { Prompt } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tour from 'reactour';
import Select from 'react-select';

//Styles
import 'react-day-picker/lib/style.css';
import '../../scss/pages/_create-company.scss';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput'
import ImagePicker from '../../components/ImagePicker.js';
import FormTextArea from '../../components/form/FormTextArea';
import KAddPartnersModal from '../../components/KAddPartnersModal';
import KTabPartnersContent from '../../components/ktabs/KTabPartnersContent';
import ProgramsPicker from '../../components/ProgramsPicker';
import KFileField from "../../components/KFileField";
import KMap from '../../components/KMap';
import KSocialNetworksField from "../../components/KSocialNetworksField";

//Other imports
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import debounce from 'lodash/debounce';
import {transformToMoney, transformToUpperCase,
		transformToInt,editDateMask,
		editTransformToPhoneNumber,transformToPhone} from '../../components/form/transforms.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import steps from './tour/CompanySteps';



class CreateCompanyView extends React.Component {
	constructor(props) {
		super(props);
		//State attributes
		this.state = {
			me:{
				_id: null,
				name: "",
				email: "",
				created_at: null,
				updatedAt: null,
				source: null,
				uid: null,
				profileUrlThumbnail: null,
				goverment_id_curp: null,
				social_linkedin: null,
				social_facebook: null,
				social_twitter: null,
				social_other: null,
				goverment_id_rfc: null,
			},
			formData: {
				idea: {
					name: '',
					quote: '',
					size: 1,
					problemToSolve: '',
					proposed_solution: '',
					address_zip_code: '',
					address_state: '',
					address_state_human: '',
					address_municipality: '',
					address_neighborhood: '',
					address_street: '',
					address_number_ext: '',
					address_number_int: '',
					coordinates_latitude:0,
					coordinates_longitude:0,
					program:''
				},
				prototype: {
					website: '',
					video_url: '',
					pdf_file: '',
					industry_sector: ''
				},
				startup: {
					logo: null,
					dateOfEstablishment: '',
					market: '',
					business_model: '',
					stats_is_seeking_funding: false,
					stats_funding_amount_seeked: '',
					stats_funding_seeking_reason: '',
					stats_is_seeking_mentoring: false,
					stats_is_seeking_team: false,
					stats_team_seeking_reason: '',
					achievements: '',
					currency: 'MXN',
					social_networks: [],
					stats_is_seeking_mentoring_marketing:false,
					stats_is_seeking_mentoring_sales:false,
					stats_is_seeking_mentoring_legal:false,
					stats_is_seeking_mentoring_fiscal:false,
					stats_is_seeking_mentoring_financing:false,
					stats_is_seeking_mentoring_accounting:false,
					stats_is_seeking_mentoring_hr:false,
					stats_is_seeking_mentoring_business_plan:false,
					stats_is_seeking_mentoring_trademark:false,
					stats_is_seeking_mentoring_patent:false,
					stats_is_seeking_mentoring_institutional_image:false,
					stats_is_seeking_mentoring_processes:false,
					pitch_file:'',
					pitch_link:''
				},
				pyme: {
					goverment_id_rfc: '',
					goverment_legal_name: '',
					phone: '',
					stats_sales: '',
					gathered_capital: '',
					net_spending: ''
				},
				scaleup: {
					pre_capital_value: '',
					seeked_capital: '',
					has_participated_program:false,
					participated_program_name:''
				}
			},
			neighborhoodCollection: [],
			companyId: null,
			logoName: null,
			isNameEditable: true,
			registerStep: 'idea',
			showHover: false,
			sidebarVisible: true,
			sidebarStatus: false,
			showTopbar: true,
			company:{},
			dateOfEstablishment: null,
			formIsHalfFilledOut: false,
			confirmedNavigation: false,
			isCalendarOpen:false,
			programs:[],
			isTourOpen: false,
			selectedPitchFile:null,
			programName: 'Selecciona un programa...',
			stateLabel: {label:'Selecciona el estado', value: ''}
		};

		//Methods that modify the status of attributes
		this.handleResizeBar      					= this.handleResizeBar.bind(this);
		this.handleHeaderResize   					= this.handleHeaderResize.bind(this);
		this.onDateChange 	 								= this.onDateChange.bind(this);
		this.onDateSelected       					= this.onDateSelected.bind(this);
		this.startupChange									= this.startupChange.bind(this);
		this.prototypeChange								= this.prototypeChange.bind(this);
		this.searchOfStatus									= this.searchOfStatus.bind(this);
		this.getImage												= this.getImage.bind(this);
		this.fileUpload											= this.fileUpload.bind(this);
		this.hoverLogo											= this.hoverLogo.bind(this);
		this.leaveLogo											= this.leaveLogo.bind(this);
		this.sendData												= this.sendData.bind(this);
		this.sendAndClose										= this.sendAndClose.bind(this);
		this.openPartnersModal    					= this.openPartnersModal.bind(this);
		this.closePartnersModal   					= this.closePartnersModal.bind(this);
		this.onPartnersAdded      					= this.onPartnersAdded.bind(this);
		this.onPartnersDeleted    					= this.onPartnersDeleted.bind(this);
		this.top		         								= this.top.bind(this);
		this.onChangeForm										= this.onChangeForm.bind(this);
		this.handleBlockedNavigation 				= this.handleBlockedNavigation.bind(this);
		this.handleConfirmNavigationClick		= this.handleConfirmNavigationClick.bind(this);
		this.onChangeProgram      					= this.onChangeProgram.bind(this);
		this.renderAddressForm            	= this.renderAddressForm.bind(this);
		this.getAddressFromMap            	= this.getAddressFromMap.bind(this);
		this.setMarkerPosition            	= this.setMarkerPosition.bind(this);
		this.onChangeState					= this.onChangeState.bind(this);
		//pages names
		this.pages = ['idea', 'prototype', 'startup', 'pyme', 'scaleup'];
		// MAXIMUM UPLOAD SIZE
		this.MAXIMUM_UPLOAD_SIZE = 5 * 1024 * 1024; //5MB
		this.onFileSelected       = this.onFileSelected.bind(this);

	}
	redirectToEditProfile() {
		this.props.history.push('/profile/edit');
	}
	componentDidMount() {
		let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
		KomunidadApi.getMe().then((res) => {
			if (res.valid_token) {
				if (!isUserProfileComplete(res.user)) {
					this.redirectToEditProfile();
				}
				this.setState({
					me: res.user
				})
			} else {
				this.redirectHome();
			}
		});
		if(programIdQuery !== null) {
			KomunidadApi.getProgramName(programIdQuery).then((res) => {
				this.setState({
					programName: res.program.name
				})
				Swal.fire({
	        type: 'info',
	        title: 'Completa la información de tu empresa',
	        text: 'Para obtener ayuda de mentores llena los datos de tu empresa',
	        customClass: {
	          confirmButton: "swalBtnTeal",
	          title: "swal-custom-title",
	        },
	      })
			})
			let program = {
				'_id': programIdQuery
			}
			this.onChangeProgram(program)
		}
		window.addEventListener("keyup", this.keyHandling);
		window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
	}

	componentDidUpdate() {
		if (this.state.formIsHalfFilledOut) {
			window.onbeforeunload = () => true
		} else {
			window.onbeforeunload = undefined;
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = undefined;
		window.removeEventListener("keyup", this.keyHandling);
	}

	disableBody = target => disableBodyScroll(target);
	enableBody = target => enableBodyScroll(target);

	closeTour = () => {
		this.setState({ isTourOpen: false });
	};

	openTour = () => {
		this.setState({ isTourOpen: true });
	};

	handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
		if(windowSize <= 994) {
			this.setState({
			  sidebarStatus: true,
			  showTopbar: false
			})
		} else {
			this.setState({
			  sidebarStatus: false,
			  showTopbar: true
			})
		}
    }
  }

	handleHeaderResize(width) {
		this.setState({
			sidebarVisible: width <= 500 ? false : true
		});
	}

	redirectHome() {
		this.props.history.push('/');
	}

	top() {
		scroller.scrollTo('page_wrapper', {
			duration: 200,
			delay: 100,
			smooth: true,
			containerId: 'page_wrapper',
		})
	}

	//setState DatePicker
	onDateChange(date) {
		let data = { model: 'dateOfEstablishment', value: date };

		//Save the date on the formData object.
		this.onChangeForm(data);
	}

	onChangeSocialNetworks(data){
    let value = (data.value === null) ? [] : data.value; //sets null data to empty string
    
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.startup[data.model] = value;
      return { formData };
    });
  }

	//setState for DatePicker
	onDateSelected(date) {
		this.formIsHalfFilledOut(true);

		let d = this.returnDateFormatToFormInput(date);

		this.setState({dateOfEstablishment:d.day + d.month + d.year})
	}

	onChangeWrapperDate(data){
		if(this.state.isCalendarOpen){
			this.dp_dateOfEstablishment.setOpen(false);
		}

		if( data.value.length === 10 ){
			let day = data.value.substring(0,2);
			let month = parseInt(data.value.substring(3,5));
			let year = data.value.substring(6,10);
			let date = new Date(year,month-1,day);
			this.dp_dateOfEstablishment.selected = date;
			this.onDateChange(date);
		}

		let value = (data.value === null) ? '' : data.value; //sets null data to empty string

		this.formIsHalfFilledOut(true);

		this.setState({dateOfEstablishment:value})
	}

	returnDateFormatToFormInput(date){
		let day = date.getDate().toString();
		let month = date.getMonth() + 1;
				month = month.toString();
		let year = date.getFullYear().toString();

		if(day.length === 1){
			day = '0'+day;
		}
		if(month.length === 1){
			month = '0'+month;
		}

		return {day:day,month:month,year:year}
	}

	//Set the state of start edit the forma
	formIsHalfFilledOut(value) {
		if (!(this.state.formIsHalfFilledOut && value)) {
			this.setState({ formIsHalfFilledOut: value });
		}
	}
	onChangeProgram(program) {
		let programId = null;
		if (program !== undefined) {
			programId = program._id;
		} else {
			programId = "";
		}
		this.setState(prevState => {
			let formData = Object.assign({}, prevState.formData);
			formData.idea.program = programId;
			return { formData };
		}, () => {
			if(program !== undefined) {
				this.setState({
					programName: program.name
				})
			} else {
				this.setState({
					programName: 'Selecciona un programa...'
				})
			}
		});
	}

	//Save the data of the forminput and formtextarea
	onChangeForm(data) {
		let registerStep = this.state.registerStep;

		this.formIsHalfFilledOut(true);

		this.setState({
			formData: {
				...this.state.formData,
				[registerStep]: {
					...this.state.formData[registerStep],
					[data.model]: data.value
				}
			}
		});
	}

	//setState startup data
	startupChange(data) {
		this.setState({
			formData: {
				...this.state.formData,
				startup: {
					...this.state.formData.startup,
					[data.target.name]: data.target.value
				}
			}
		});
	}

	//setState prototype data
	prototypeChange(data) {
		this.setState({
			formData: {
				...this.state.formData,
				prototype: {
					...this.state.formData.prototype,
					[data.target.name]: data.target.value
				}
			}
		});
	}

	//setState 'search of' data
	searchOfStatus(data) {
		this.setState({
			formData: {
				...this.state.formData,
				startup: {
					...this.state.formData.startup,
					[data.target.name]: !this.state.formData.startup[data.target.name]
				}
			}
		})
	}

	//setState for company logo
	getImage(file) {
		let name = window.URL.createObjectURL(file);
		this.setState({
			formData: {
				...this.state.formData,
				startup: {
					...this.state.formData.startup,
					logo: file,
				}
			},
			logoName: name
		});
	}

	//setState for file upload
	fileUpload(file) {
		if (file.target.files[0].size > this.MAXIMUM_UPLOAD_SIZE) {
			Swal.fire({
				title: 'Archivo demasiado grande',
				text: "El archivo debe de ser menor a 5MB.",
				type: 'error',
				customClass: {
					confirmButton: 'swalBtnTeal',
					title: 'swal-custom-title',
				},
			});
			this.pdfInput.value = '';
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					prototype: {
						...this.state.formData.prototype,
						pdf_file: file.target.files[0],
					}
				}
			});
		}

	}

	//Show hover on logo div
	hoverLogo() {
		this.setState({
			showHover: true
		});
	}

	//Hide hover on logo div
	leaveLogo() {
		this.setState({
			showHover: false
		});
	}

	//Method to company registration
	sendData(saveAndClose, send_data_cb) {
		let params = {};
		params = this.state.formData[this.state.registerStep];
		if (this.state.registerStep === "startup") {
			params.stats_funding_amount_seeked = this.fi_stats_funding_amount_seeked.getCleanValue();
			params.pitch_file = this.state.selectedPitchFile;
			this.setState({ selectedPitchFile: null });
		}
		if (this.state.registerStep === "pyme") {
			params.stats_sales = this.fi_stats_sales.getCleanValue();
			params.gathered_capital = this.fi_gathered_capital.getCleanValue();
			params.net_spending = this.fi_net_spending.getCleanValue();
			params.phone = this.fi_phone.getCleanValue();
		}
		if (this.state.registerStep === "scaleup") {
			params.pre_capital_value = this.fi_pre_capital_value.getCleanValue();
			params.seeked_capital = this.fi_seeked_capital.getCleanValue();
		}
		if (this.state.isNameEditable) {
			KomunidadApi.createCompany(params).then((res) => {
				if (res.success) {
					if (res.hasOwnProperty('company')) {
						this.setState({ isNameEditable: false, companyId: res.company._id });
						if (saveAndClose) {
							this.companyCreated();
						}
					}
				} else {
				}
				send_data_cb(res);
			});
		} else {
			KomunidadApi.updateCompany(this.state.companyId, params).then((res) => {
				if (res.success) {
					if (saveAndClose) {
						this.companyCreated()
					}
				} else {
				}
				send_data_cb(res);
			});
		}
	}

	//Method to send data and close the register
	sendAndClose() {
		let t = this;
		if (this.currentFormIsValid()) {
			/*--------Open loading swal------------------------------------------- */
			this.openLoadingSwal();
			/*----------------------------------------------------------------------*/
			function sendDataCb(res) {
				/*--------Close loading swal------------------------------------------- */
				t.closeLoadingSwal();
				/*----------------------------------------------------------------------*/

				if (!res.success) {
					Swal.fire({
						type: 'error',
						title: 'No pudimos guardar tus datos',
						text: 'Hubo un error al guardar tus datos',
						footer: '',
						customClass: {
							confirmButton: 'swalBtnTeal',
							title: 'swal-custom-title',
						},
					});
				}
			}
			let saveAndClose = true;
			this.sendData(saveAndClose, sendDataCb)
		} else {
			//TODO: Hint
		}
	}

	//Sweet alert company created
	companyCreated() {
		this.formIsHalfFilledOut(false);

		Swal.fire({
			type: 'success',
			title: '¡Startup registrada!',
			customClass: {
				confirmButton: 'swalBtnTeal',
				title: 'swal-custom-title',
			},
		}).then((result) => {
			this.props.history.push('/companies/' + this.state.companyId)
		})
	}

	validateIdea() {
		if(this.state.formData.idea.address_zip_code !== ''){
			return this.fi_name.validate() &&
					this.fi_quote.validate() &&
					this.ft_problemToSolve.validate() &&
					this.ft_proposed_solution.validate() &&
					this.fi_address_zip_code.validate() &&
					// this.fi_address_state.validate() &&
					this.fi_address_municipality.validate() &&
					this.fi_address_neighborhood.validate() &&
					this.fi_address_street.validate() &&
					this.fi_address_number_ext.validate();
			} else {
				return this.fi_name.validate() &&
					this.fi_quote.validate() &&
					this.ft_problemToSolve.validate() &&
					this.ft_proposed_solution.validate();
			}
	}

	validatePrototype() {
		return this.fi_website.validate() && this.fi_video_url.validate();
	}
	validateStartup() {
		return this.fi_market.validate() && this.fi_business_model.validate() &&
			this.fi_stats_funding_amount_seeked.validate() &&
			this.fi_stats_funding_seeking_reason.validate() &&
			this.fi_stats_team_seeking_reason.validate() &&
			this.fi_achievements.validate() && this.fi_pitch_link.validate()
	}

	validatePyme(){
		return	this.fi_goverment_id_rfc.validate() && 
			this.fi_goverment_legal_name.validate() &&
			this.fi_phone.validate() &&
			this.fi_stats_sales.validate() &&
			this.fi_gathered_capital.validate() &&
			this.fi_net_spending.validate();
	}

	validateScaleUp() {
		if (this.state.formData.scaleup.has_participated_program) {
			return (
				this.fi_pre_capital_value.validate() &&
				this.fi_seeked_capital.validate() &&
				this.ft_participated_program_name.validate()
			);
		} else {
			return (
				this.fi_pre_capital_value.validate() &&
				this.fi_seeked_capital.validate()
			);
		}
	}
	currentFormIsValid() {
		switch (this.state.registerStep) {
			case "idea":
				return this.validateIdea();
			case "prototype":
				return this.validatePrototype();
			case "startup":
				return this.validateStartup();
			case "pyme":
				return this.validatePyme();
			case "scaleup":
				return this.validateScaleUp();
			default:
				return false;
		}
	}
	/*
		Methods to change the view with buttons
	*/
	nextPage = () => {
		let current_index = this.pages.indexOf(this.state.registerStep);//0-4
		let next_index = current_index + 1;
		let t = this;
		//if this is not the last step, save & continue.
		if (this.currentFormIsValid()) {
			/*--------Open loading swal------------------------------------------- */
			this.openLoadingSwal();
			/*----------------------------------------------------------------------*/
			function sendDataCb(res) {
				/*--------Close loading swal------------------------------------------- */
				t.closeLoadingSwal();
				/*----------------------------------------------------------------------*/
				if (res.success) {
					if (res.hasOwnProperty('company')) {
						t.setState({
							registerStep: t.pages[next_index],
						}, () => {
							t.top();
						});
					}
				} else {
					Swal.fire({
						type: 'error',
						title: 'No pudimos guardar tus datos',
						text: 'Hubo un error al guardar tus datos',
						footer: '',
						customClass: {
							confirmButton: 'swalBtnTeal',
							title: 'swal-custom-title',
						},
					});
				}
			}
			let saveAndClose = (this.state.registerStep === "scaleup") ? true : false;
			this.sendData(saveAndClose, sendDataCb)
		} else {
			//TODO: Hint
		}
	};

	prevPage = () => {
		let current_index = this.pages.indexOf(this.state.registerStep);//0-4
		let t = this;
		let cancelLocation = {
			pathname: "/profile"
		}
		if (current_index === 0) {
			//if the company was created call the cancel function
			if (this.state.companyId !== null) {
				this.props.history.push("/profile");
			} else {
				this.confirmLeave(cancelLocation)
			}
		} else {
			this.setState({
				registerStep: this.pages[current_index - 1],
			}, () => {
				t.top();
			});
		}
	};

	openLoadingSwal() {
		Swal.fire({
			title: 'Tus datos están siendo guardados',
			allowOutsideClick: false,
			customClass: {
				title: 'swal-custom-title',
			},
			onBeforeOpen: () => {
				Swal.showLoading()
			}
		});
	}

	closeLoadingSwal() {
		Swal.close();
	}

	//Confirm leave
	confirmLeave(nextLocation) {
		Swal.fire({
			title: '¿Deseas cancelar?',
			text: "Los datos que ingresaste se perderán",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			customClass: {
				confirmButton: 'swalBtnRedOrange',
				cancelButton: 'swalBtnTeal',
				title: 'swal-custom-title',
			}
		}).then((result) => {
			if (result.value) {
				this.handleConfirmNavigationClick(nextLocation);
			}
		})
	}

	//Navigate to the next location
	handleConfirmNavigationClick(nextLocation) {
		this.setState({
			confirmedNavigation: true
		}, () => {
			// Navigate to the previous blocked location
			this.props.history.push(nextLocation.pathname);
		})
	}

	cancel() {
		this.props.history.push("/profile");
	}

	//Change the tittle of the component
	renderTitle(state) {
		return this.state.titles[this.pages.indexOf(this.state.registerStep) + state]
	}

	openPartnersModal(open) {
		this.setState({ isModalPartnersOpen: open });
	}

	closePartnersModal() {
		this.setState({ isModalPartnersOpen: false });
	}

	//Callback when the partners are added. Refresh the company view
	onPartnersAdded() {
		KomunidadApi.getCompany(this.state.companyId).then((res) => {
			if (res.success && res.hasOwnProperty('company')) {
				this.setState({ company: res.company });
			}
		})
	}

	//Callback when the partners are deleted. Refresh the company view
	onPartnersDeleted() {
		KomunidadApi.getCompany(this.state.companyId).then((res) => {
			if (res.success && res.hasOwnProperty('company')) {
				this.setState({ company: res.company });
			}
		})
	}

	//Render the text in the logo container
	renderLogoMessage() {
		if (!this.state.formData.startup.logo) {
			return (
				<div className='image-text'>
					Actualizar Logo
				</div>
			);
		} else if (this.state.showHover) {
			return (
				<div className='image-text'>
					Actualizar Logo
				</div>
			);
		} else {
			return null;
		}
	}

	//TODO: Move it somewhere else
	stateToISO31662(state) {
		state = state.replace(/^[^A-Za-z]+/, '');
		let isoCodes = {
			"Aguascalientes": "MX-AGU",
			"Baja California": "MX-BCN",
			"Baja California Sur": "MX-BCS",
			"Campeche": "MX-CAM",
			"Coahuila de Zaragoza": "MX-COA",
			"Colima": "MX-COL",
			"Chiapas": "MX-CHP",
			"Chihuahua": "MX-CHH",
			"Ciudad de México": "MX-CMX",
			"Durango": "MX-DUR",
			"Guanajuato": "MX-GUA",
			"Guerrero": "MX-GRO",
			"Hidalgo": "MX-HID",
			"Jalisco": "MX-JAL",
			"México": "MX-MEX",
			"Michoacán de Ocampo": "MX-MIC",
			"Morelos": "MX-MOR",
			"Nayarit": "MX-NAY",
			"Nuevo León": "MX-NLE",
			"Oaxaca": "MX-OAX",
			"Puebla": "MX-PUE",
			"Querétaro": "MX-QUE",
			"Quintana Roo": "MX-ROO",
			"San Luis Potosí": "MX-SLP",
			"Sinaloa": "MX-SIN",
			"Sonora": "MX-SON",
			"Tabasco": "MX-TAB",
			"Tamaulipas": "MX-TAM",
			"Tlaxcala": "MX-TLA",
			"Veracruz": "MX-VER",
			"Yucatán": "MX-YUC",
			"Zacatecas": "MX-ZAC"
		}

		return isoCodes[state];
	}
	iso31662ToState(state) {
		let isoCodes = {
			"MX-AGU": "Aguascalientes",
			"MX-BCN": "Baja California",
			"MX-BCS": "Baja California Sur",
			"MX-CAM": "Campeche",
			"MX-COA": "Coahuila de Zaragoza",
			"MX-COL": "Colima",
			"MX-CHP": "Chiapas",
			"MX-CHH": "Chihuahua",
			"MX-CMX": "Ciudad de México",
			"MX-DUR": "Durango",
			"MX-GUA": "Guanajuato",
			"MX-GRO": "Guerrero",
			"MX-HID": "Hidalgo",
			"MX-JAL": "Jalisco",
			"MX-MEX": "México",
			"MX-MIC": "Michoacán de Ocampo",
			"MX-MOR": "Morelos",
			"MX-NAY": "Nayarit",
			"MX-NLE": "Nuevo León",
			"MX-OAX": "Oaxaca",
			"MX-PUE": "Puebla",
			"MX-QUE": "Querétaro",
			"MX-ROO": "Quintana Roo",
			"MX-SLP": "San Luis Potosí",
			"MX-SIN": "Sinaloa",
			"MX-SON": "Sonora",
			"MX-TAB": "Tabasco",
			"MX-TAM": "Tamaulipas",
			"MX-TLA": "Tlaxcala",
			"MX-VER": "Veracruz",
			"MX-YUC": "Yucatán",
			"MX-ZAC": "Zacatecas",
		}
		return isoCodes[state];
	}
	getAddressState(value) {
		return this.iso31662ToState(value);
	}

	renderHelp() {
		if (this.state.registerStep === "idea") {
			return (
				<div className="col-md-7 align-self-center">
					<div className="company-actions">
						<div className="m-b-5 btn btn-success btn-save float-right" onClick={() => this.openTour()}>Ayuda</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderIdea() {
		if(this.state.registerStep === "idea"){
			return (
				<div className='row'>
					<div className='col-sm-12 col-xs-12'>
						<div data-tut="program-step">
							<ProgramsPicker
								selectedProgram={this.state.formData.idea.program}
								onChange={(program) => {this.onChangeProgram(program)}}
								type={'mine'}
								showHelp={true}
							></ProgramsPicker>
						</div>
						<div className='form-group' data-tut="project-name">
							<FormInput
								ref={ref => this.fi_name = ref}
								id="nombre de la empresa"
								label="Nombre de la empresa"
								model="name"
								cssClass="custom-class"
								editMode={this.state.isNameEditable}
								value={this.state.formData.idea.name}
								help="Escribe el nombre de tu empresa o idea de proyecto (Mínimo 3 caracteres)."
								maxLength={30}
								required={true}
								validationRules="required|minLen:3|maxLen:30"
								onChange={ change => { this.onChangeForm(change) }}
							/>
						</div>
					<div data-tut="continue">
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_quote = ref}
								id="frase"
								label="Frase"
								model="quote"
								cssClass="custom-class"
								value={this.state.formData.idea.quote}
								help="Desarrolla una frase que defina tu empresa y la gente pueda entender. Ej. LinkedIn el Facebook de los proyectos (Mínimo 5 caracteres)."
								moreHelp="Destila la visión de tu empresa en una sola oración. Es la herramienta perfecta para los fans, usuarios e inversionistas que están ayudándote a promocionar tu nueva empresa. El truco es poder poner todo el proyecto detrás de una tarjeta de presentación. En otras palabras tienes que decir: “Somos el X para Y (Somos el Uber para Doctores)”. Debes de buscar utilizar algo conocido para que la gente lo entienda y pueda explicarlo de manera correcta. "
								maxLength={90}
								validationRules="minLen:5|maxLen:90"
								onChange={ change => { this.onChangeForm(change) } }
							/>
						</div>
						<div className='form-group'>
							<FormTextArea
								ref={ref => this.ft_problemToSolve = ref}
								id="problema"
								label="Problema"
								model="problemToSolve"
								cssClass="custom-class"
								value={this.state.formData.idea.problemToSolve}
								help="Escribe ¿Qué problema resuelves? ¿Quién tiene el problema? ¿Por qué tiene el problema? Se especifico, define tu mercado, cuantifica tu mercado, define la solución actual y quita todas las palabras que sean “yo” o “nosotros” (Mínimo 50 caracteres)."
								moreHelp="Escribe primero una oración que defina cuál es el problema y quien lo tiene. Después pregúntate ¿Por qué esta población tiene este problema?. Ej. El 75% de los 60 millones de mexicanos que tienen sobre peso, intentan algún tipo de dieta sin éxito. Esto resulta en un gasto de $5,000 pesos anuales por persona que decide hacer una dieta sin éxito."
								maxLength={500}
								validationRules="minLen:50|maxLen:500"
								onChange={ change => { this.onChangeForm(change) } }
							/>
						</div>
						<div className='form-group'>
							<FormTextArea
								ref={ref => this.ft_proposed_solution = ref}
								id="solución"
								label="Solución"
								model="proposed_solution"
								cssClass="custom-class"
								value={this.state.formData.idea.proposed_solution}
								help="Describe tu producto o servicio y como este resuelve el problema para las personas que tienen dicho problema. También puedes mencionar porque tu solución es mejor que la competencia (Mínimo 20 caracteres)."
								moreHelp="Siempre debes de empezar primero por definir claramente el problema y a partir de ahi definir una solución te recordamos que no necesariamente esta solución debe de estar ya construida o lista para venderse. En esta primera etapa vamos a validar si tu idea realmente es la mejor opción para resolver el problema que te planteaste."
								maxLength={500}
								validationRules="minLen:20|maxLen:500"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>

						{this.renderMapBox()}
						{this.renderAddressForm()}

					</div>
				</div>
			</div>
			);
		}else{
			return null;
		}
	}
	//Render Project View
	renderPrototype() {
		if(this.state.registerStep === "prototype"){
			return (
				<div className='row'>
					<div className='col-sm-12 col-xs-12'>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_website = ref}
								id="dirección web"
								label="Dirección web"
								model="website"
								cssClass="custom-class"
								value={this.state.formData.prototype.website}
								help="Copia el URL del sitio web de tu empresa, puede ser también una red social como Facebook o LinkedIn."
								maxLength={300}
								validationRules="url"
								onChange={ change => { this.onChangeForm(change) } }
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_video_url = ref}
								id="video"
								label="Video"
								model="video_url"
								cssClass="custom-class"
								value={this.state.formData.prototype.video_url}
								help="En caso de que tengas algún video que describa de mejor manera tu empresa puedes copiar el URL de Youtube o cualquier otra plataforma."
								maxLength={300}
								validationRules="url"
								onChange={ change => { this.onChangeForm(change) } }
							/>
						</div>
						<div className='form-group'>
							<label>Hoja técnica</label><br/>
							<input type="file" ref={ref=> this.pdfInput = ref} onChange={this.fileUpload} accept="application/pdf"/>
							<small className="form-text text-muted">En caso de requerirlo, puedes subir una hoja técnica que explique algún proceso dentro de tu empresa. (Máximo 5MB).</small>
						</div>
						<div className='form-group'>
							<label>Sector</label>
							<select className='form-control custom-select'
								name='industry_sector'
								value={this.state.formData.prototype.industry_sector}
								onChange={this.prototypeChange}
							>
								<option value={''}>Selecciona un sector</option>
								<option value='Aeroespacial'>Aeroespacial</option>
								<option value='Aeronáutico'>Aeronáutico</option>
								<option value='Agroindustrial'>Agroindustrial</option>
								<option value='Alimentos'>Alimentos</option>
								<option value='Artesanías'>Artesanías</option>
								<option value='Automotriz'>Automotriz</option>
								<option value='Biofarmaceutica'>Biofarmaceutica</option>
								<option value='Desarrollo Web'>Desarrollo Web</option>
								<option value='Diseño y Moda'>Diseño y Moda</option>
								<option value='Dispositivos y Componentes'>Dispositivos y Componentes</option>
								<option value='Educación'>Educación</option>
								<option value='Electrodomésticos'>Electrodomésticos</option>
								<option value='Electrónico'>Electrónico</option>
								<option value='Energético'>Energético</option>
								<option value='Energías Limpias'>Energías Limpias</option>
								<option value='Energías Renovables'>Energías Renovables</option>
								<option value='Equipo Médico'>Equipo Médico</option>
								<option value='Fintech'>Fintech</option>
								<option value='Gobierno'>Gobierno</option>
								<option value='Industria Agroalimentaria'>Industria Agroalimentaria</option>
								<option value='Industria del Reciclaje'>Industria del Reciclaje</option>
								<option value='Industria Textil'>Industria Textil</option>
								<option value='Internet de las Cosas'>Internet de las Cosas</option>
								<option value='Logística'>Logística</option>
								<option value='Maquinaria y Equipo'>Maquinaria y Equipo</option>
								<option value='Materiales para la Construcción'>Materiales para la Construcción</option>
								<option value='Metalmecánica'>Metalmecánica</option>
								<option value='Minería'>Minería</option>
								<option value='Nanotecnología'>Nanotecnología</option>
								<option value='Plásticos'>Plásticos</option>
								<option value='Petroquímica'>Petroquímica</option>
								<option value='Productos Agropecuarios y Acuícolas'>Productos Agropecuarios y Acuícolas</option>
								<option value='Productos Químicos'>Productos Químicos</option>
								<option value='Salud'>Salud</option>
								<option value='Servicios de Investigación y Desarrollo'>Servicios de Investigación y Desarrollo</option>
								<option value='Servicios Medicos'>Servicios Medicos</option>
								<option value='Tecnologías de la Información'>Tecnologías de la Información</option>
								<option value='Turismo'>Turismo</option>
								<option value='Turismo Médico'>Turismo Médico</option>
							</select>
							<small className="form-text text-muted">Selecciona el sector más cercano en el que se encuentre tu empresa.</small>
						</div>
						<div className='form-group'>
							<label>Socios</label><br/>
						</div>
						<div className='col-sm-12 col-xs-12 m-b-40'>
							<KTabPartnersContent className='btn-secondary' company={this.state.company} onClickAddPartners={this.openPartnersModal} onPartnersDeleted={this.onPartnersDeleted}/>
						</div>
					</div>

				</div>
			);
		}else{
			return null;
		}
	}

	//Render Pyme view
	renderStartup() {
		var bgColor = 'bgImage';
		var bgHover;
		if (this.state.formData.startup.logo) {
			bgColor = ''
		}

		if (this.state.showHover) {
			bgHover = 'bgHover'
		} else {
			bgHover = ''
		}
		if (this.state.registerStep === "startup") {
			//
			const CustomDateInput = ({ value, onClick, onChange, ...props }) => (
				<button className={'btnCalendar'} onClick={onClick}>
					<i className="mdi mdi-calendar" style={{fontSize: '20px', color: '#67757c'}}></i>
				</button>
			);

			return (
				<div className='row'>
					<div className='col-sm-12 col-xs-12'>
						<div className='form-group'>
							<label>Logotipo</label>
							<ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage} />
							<div className='form-group' onClick={() => this.imagePicker.openPicker()}>
								<img className={'rounded img-fluid selectLogo ' + bgColor + ' ' + bgHover}
									onMouseEnter={this.hoverLogo}
									onMouseLeave={this.leaveLogo}
									alt=""
									src={this.state.logoName}
								/>
								{this.renderLogoMessage()}
								<small className="form-text text-muted">Te recomendamos que este sea de al menos 500px por 500px (cuadrado).</small>
							</div>
						</div>
						<div className='form-group form-horizontal wrapperDateCalendar m-b-0p' style={{ overflow: 'visible' }}>
							<div className="widthInputCalendar">
								<FormInput
									ref={ref => this.fi_date = ref}
									id="Inicio de operaciones"
									label="Inicio de operaciones"
									model="dateOfEstablishment"
									showCounter={false}
									value={this.state.dateOfEstablishment}
									help="Pon la fecha aproximada de cuándo hiciste la primera venta. En caso de que no hayas tenido ventas todavía, puedes poner la fecha de hoy (dd-mm-aaaa)."
									transformMethod={editDateMask}
									transformDisplayMethod={editDateMask}
									onChange={change => { this.onChangeWrapperDate(change) }}
									onClick={() => { this.dp_dateOfEstablishment.setOpen(true); this.setState({ isCalendarOpen: true }) }}
									placeholder="Inicio de operaciones"
								/>
							</div>
							<DatePicker
								ref={ref => this.dp_dateOfEstablishment = ref}
								selected={this.state.formData.startup.dateOfEstablishment}
								onSelect={this.onDateSelected}
								onChange={this.onDateChange}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								locale="es"
								dateFormat="dd MMMM yyyy"
								className='form-control form-control-line'
								maxDate={addDays(new Date(), 0)}
								placeholderText="Selecciona la fecha de inicio de operaciones"
								customInput={<CustomDateInput />}
								popperModifiers={{
									offset: {
										enabled: true,
										offset: "-25px, -10px"
									},
								}}
							/>
						</div>
						<div className='form-group'>
							<FormTextArea
								ref={ref => this.fi_market = ref}
								id="mercado"
								label="Mercado meta"
								model="market"
								cssClass="custom-class"
								value={this.state.formData.startup.market}
								help="Identifica quién es tu mercado meta (usuarios y clientes), su definición puede incluir edad, ubicación, sexo, estudios, hobbies, estado civil, ingresos, etc (Mínimo 5 caracteres)."
								moreHelp="Puedes analizar a tu competencia y buscar ellos como definen su mercado, debes de ser lo más especifico posible. El poner “personas adultas de clase socioeconómica b” no sirve de nada, ademas de que las campañas de marketing te costarán una fortuna. En tus entrevistas de validación identifica prospectos que te comenten cosas como: “esto es perfecto, me encanta, ¿donde dices que lo venden?”"
								maxLength={500}
								validationRules="minLen:5|maxLen:500"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormTextArea
								ref={ref => this.fi_business_model = ref}
								id="modelo"
								label="Modelo de negocios"
								model="business_model"
								cssClass="custom-class"
								value={this.state.formData.startup.business_model}
								help="Un modelo de negocios define tres cosas 1) ¿Qué vendes?, 2) ¿A quién se lo vendes? Y 3) ¿Cómo y cuánto le cobras?, lo cual debes de explicar utilizando cifras de ser posible (Mínimo 3 caracteres)."
								moreHelp="Igualmente puedes explicar el proceso de validación que utilizaste para llegar a este modelo de negocio, ¿cómo hiciste las entrevistas?, ¿a quién se las hiciste?, ¿qué encontraste y cómo adaptaste tu producto?. Lo más importante es recolectar evidencia, es lo único que bajará el riesgo al iniciar un proyecto."
								maxLength={500}
								validationRules="minLen:3|maxLen:500"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group' style={{ margin: '0 0 10px 0' }}>
							<label>Necesidades de tu empresa</label>
							<small className="form-text text-muted">Selecciona la casilla si estas en busca de financiamiento, mentores o personas, igualmente te pedimos llenes los campos que se requieren. </small>
						</div>
						<div className="form-group" data-toggle="buttons" style={{ margin: '0 0 15px 0' }}>
							<label>
								<input
									type="checkbox"
									id="md_checkbox_21"
									className="filled-in chk-col-red"
									name='stats_is_seeking_funding'
									value={this.state.formData.startup.stats_is_seeking_funding}
									onChange={this.searchOfStatus} />
								<label htmlFor="md_checkbox_21" className={this.state.formData.startup.stats_is_seeking_funding ? "" : "text-muted"}>Financiamiento</label>
							</label>
							<div className={this.state.formData.startup.stats_is_seeking_funding ? "form-group m-l-40" : "showButton"}>
								<FormInput
									ref={ref => this.fi_stats_funding_amount_seeked = ref}
									id="financiamiento"
									label="Monto requerido"
									model="stats_funding_amount_seeked"
									cssClass="custom-class"
									value={this.state.formData.startup.stats_funding_amount_seeked}
									help="Escribe la cantidad de capital que necesitas en pesos MXN (sin comas o decimales)."
									maxLength={20}
									validationRules="isNumber"
									transformMethod={transformToMoney}
									onChange={change => { this.onChangeForm(change) }}
								/>
							</div>
							<div className={this.state.formData.startup.stats_is_seeking_funding ? "form-group m-l-40" : "showButton"}>
								<FormInput
									ref={ref => this.fi_stats_funding_seeking_reason = ref}
									id="razón de financiamiento"
									label="Uso del recurso"
									model="stats_funding_seeking_reason"
									cssClass="custom-class"
									value={this.state.formData.startup.stats_funding_seeking_reason}
									help="Detalla el uso que le darás a este recurso (capital de trabajo, marketing, contratación de personal, etc). Se lo más especifico que puedas."
									maxLength={300}
									validationRules="minLen:5|maxLen:300"
									onChange={change => { this.onChangeForm(change) }}
								/>
							</div>
						</div>
						<div className="form-group" data-toggle="buttons" style={{ margin: '0 0 15px 0' }}>
							<label>
								<input
									type="checkbox"
									id="md_checkbox_22"
									className="filled-in chk-col-red"
									name='stats_is_seeking_mentoring'
									value={this.state.formData.startup.stats_is_seeking_mentoring}
									onChange={this.searchOfStatus} />
								<label htmlFor="md_checkbox_22" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Consultoría (capacitación)</label>
							</label>
							<div className={this.state.formData.startup.stats_is_seeking_mentoring ? "form-group m-0 pl-5 pt-3" : "showButton"}>
								<small className="form-text text-muted">De acuerdo a tus necesidades en que areas tienes requerimiento de consultoría o capacitación. </small>
								<div className="demo-radio-button">
									<input
										type="checkbox"
										id="cb_marketing"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_marketing'
										value={this.state.formData.startup.stats_is_seeking_mentoring_marketing}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_marketing" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Marketing</label>
									<input
										type="checkbox"
										id="cb_sales"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_sales'
										value={this.state.formData.startup.stats_is_seeking_mentoring_sales}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_sales" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Ventas</label>
									<input
										type="checkbox"
										id="cb_legal"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_legal'
										value={this.state.formData.startup.stats_is_seeking_mentoring_legal}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_legal" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Legal</label>
									<input
										type="checkbox"
										id="cb_fiscal"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_fiscal'
										value={this.state.formData.startup.stats_is_seeking_mentoring_fiscal}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_fiscal" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Fiscal</label>
									<input
										type="checkbox"
										id="cb_financing"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_financing'
										value={this.state.formData.startup.stats_is_seeking_mentoring_financing}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_financing" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Financiamiento</label>
									<input
										type="checkbox"
										id="cb_accounting"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_accounting'
										value={this.state.formData.startup.stats_is_seeking_mentoring_accounting}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_accounting" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Contabilidad</label>
									<input
										type="checkbox"
										id="cb_hr"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_hr'
										value={this.state.formData.startup.stats_is_seeking_mentoring_hr}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_hr" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Recursos Humanos</label>
									<input
										type="checkbox"
										id="cb_business_plan"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_business_plan'
										value={this.state.formData.startup.stats_is_seeking_mentoring_business_plan}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_business_plan" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Plan de negocios</label>
									<input
										type="checkbox"
										id="cb_trademark"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_trademark'
										value={this.state.formData.startup.stats_is_seeking_mentoring_trademark}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_trademark" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Registro de Marca</label>
									<input
										type="checkbox"
										id="cb_patent"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_patent'
										value={this.state.formData.startup.stats_is_seeking_mentoring_patent}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_patent" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Patente</label>
									<input
										type="checkbox"
										id="cb_institutional_image"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_institutional_image'
										value={this.state.formData.startup.stats_is_seeking_mentoring_institutional_image}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_institutional_image" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Imagen Institucional</label>
									<input
										type="checkbox"
										id="cb_processes"
										className="filled-in chk-col-red"
										name='stats_is_seeking_mentoring_processes'
										value={this.state.formData.startup.stats_is_seeking_mentoring_processes}
										onChange={this.searchOfStatus}
									/>
									<label htmlFor="cb_processes" className={this.state.formData.startup.stats_is_seeking_mentoring ? "" : "text-muted"}>Procesos</label>
								</div>
							</div>
						</div>
						<div className="form-group" data-toggle="buttons" style={{ margin: '0 0 15px 0' }}>
							<label>
								<input
									type="checkbox"
									id="md_checkbox_23"
									className="filled-in chk-col-red"
									name='stats_is_seeking_team'
									value={this.state.formData.startup.stats_is_seeking_team}
									onChange={this.searchOfStatus} />
								<label htmlFor="md_checkbox_23" className={this.state.formData.startup.stats_is_seeking_team ? "" : "text-muted"}>Equipo (socios, colaboradores, etc)</label>
							</label>
							<div className={this.state.formData.startup.stats_is_seeking_team ? "form-group m-l-40" : "showButton"}>
								<FormInput
									ref={ref => this.fi_stats_team_seeking_reason = ref}
									id="equipo"
									label="Equipo (RH)"
									model="stats_team_seeking_reason"
									cssClass="custom-class"
									value={this.state.formData.startup.stats_team_seeking_reason}
									help="Describe si estas buscando socios, desarrolladores, vendedores, etc. Se lo más especifico que puedas."
									maxLength={300}
									validationRules="minLen:5|maxLen:300"
									onChange={change => { this.onChangeForm(change) }}
								/>
							</div>
						</div>
						<div className='form-group'>
							<FormTextArea
								ref={ref => this.fi_achievements = ref}
								id="logros"
								label="Historia de tu empresa"
								model="achievements"
								cssClass="custom-class"
								value={this.state.formData.startup.achievements}
								help="Para los jueces e inversionistas es muy importante entender el contexto de cómo inicio tu empresa. De igual manera puedes mencionar algún logro que tu y tu equipo hayan tenido trabajando en este proyecto (Mínimo 5 caracteres)."
								maxLength={300}
								validationRules="minLen:5|maxLen:300"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<KFileField
							editMode={true}
							title={"Presentación de tu empresa (Pitch deck)"}
							subtitle={"Sube un archivo PDF con el pitch de tu empresa"}
							moreHelp={"Te recomendamos que esta presentación no sea mayor a 12 slides. Teniendo el siguiente orden: 1) Portada, 2) Misión y visión de la empresa, 3) Equipo, 4) Problema, 5) Solución, 6) Ventaja competitiva, 7) Mercado meta, 8) Ventas, 9) Competencia, 10) Logros, 11) Conclusión, 12) Necesidades de financiamiento. En caso de que tu presentación sea mayor a 5mb, puedes agregar una url (Dropbox, Google Drive, One Drive, etc). No olvides dar los permisos necesarios para que tu mentor o juez pueda visualizarla correctamente. "}
							fileNotUploadedTitle={"No has subido un pitch de tu empresa"}
							downloadFileTitle={"Descargar archivo"}
							uploadedFileUrl={''}
							onFileSelected={this.onFileSelected}
						>
						</KFileField>
						<FormTextArea
							ref={ref => this.fi_pitch_link = ref}
							id="Url de tu Pitch deck"
							label="O agrega la URL de tu pitch"
							model="pitch_link"
							cssClass="custom-class"
							editMode={true}
							value={this.state.formData.startup.pitch_link}
							help="También puedes agregar una URL a tu pitch aquí"
							maxLength={300}
							validationRules="url"
							onChange={change => { this.onChangeForm(change) }}
						/>
						<KSocialNetworksField
							editMode={true}
							model="social_networks"
							title={"Redes sociales y tienda en línea de tu empresa"}
							subtitle={"Sube los links de las redes sociales y tienda en línea de tu empresa"}
							moreHelp={"Si aun no cuentas con las redes sociales de tu empresa te recomendamos que las generes para darle presencia a tu empresa."}
							socialNetworks={this.state.formData.startup.social_networks}
							onChange={ change => { this.onChangeSocialNetworks(change) } }
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	onFileSelected(selectedFile){
		let pitch_file = selectedFile.file.target.files[0];
		this.setState({selectedPitchFile:pitch_file});
	}

	getAddressFromMap(res) {
		let address = res.place_name;
		let addressArr = address.split(',');
		addressArr = addressArr.reverse();
		if (addressArr.length === 2) {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);
				formData.idea.address_state = this.stateToISO31662(addressArr[1]);
				formData.idea.address_municipality = '';
				formData.idea.address_street = '';
				return { formData };
			}, () => {
				this.setState({
					stateLabel: {
						label: addressArr[1],
						value: this.stateToISO31662(addressArr[1]),
					}
				})
			});
		}
		else if (addressArr.length === 3) {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);
				formData.idea.address_state = this.stateToISO31662(addressArr[1]);
				formData.idea.address_municipality = addressArr[2];
				formData.idea.address_street = '';
				return { formData };
			}, () => {
				this.setState({
					stateLabel: {
						label: addressArr[1],
						value: this.stateToISO31662(addressArr[1]),
					}
				})
			});
		} else if (addressArr.length === 4) {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);
				formData.idea.address_state = this.stateToISO31662(addressArr[1]);
				formData.idea.address_municipality = addressArr[2];
				formData.idea.address_street = addressArr[3];
				return { formData };
			}, () => {
				this.setState({
					stateLabel: {
						label: addressArr[1],
						value: this.stateToISO31662(addressArr[1])
					}
				})
			});
		} else if (addressArr.length === 5) {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);
				formData.idea.address_state = this.stateToISO31662(addressArr[1]);
				formData.idea.address_municipality = addressArr[2];
				formData.idea.address_street = addressArr[3];
				return { formData };
			}, () => {
				this.setState({
					stateLabel: {
						label: addressArr[1],
						value: this.stateToISO31662(addressArr[1])
					}
				})
			});
		}
		this.setMarkerPosition(res)
	}

	setMarkerPosition(event) {
		if (event.hasOwnProperty('geometry')) {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);       // creating copy of state variable marker
				formData.idea.coordinates_latitude = event.center[1].toString();                      // update the name property, assign a new value
				formData.idea.coordinates_longitude = event.center[0].toString();                     // update the name property, assign a new value
				return { formData };                                      // return new object marker object
			})
		} else {
			this.setState(prevState => {
				let formData = Object.assign({}, prevState.formData);              // creating copy of state variable marker
				formData.idea.coordinates_latitude = event.lngLat[1].toString();   // update the name property, assign a new value
				formData.idea.coordinates_longitude = event.lngLat[0].toString();  // update the name property, assign a new value
				return { formData };                                               // return new object marker object
			})
		}
	}

	renderMapBox(){
		const formData = this.state.formData;
		const latitude = formData.idea.coordinates_latitude === '' ? '0' : formData.idea.coordinates_latitude;
		const longitude = formData.idea.coordinates_longitude === '' ? '0' : formData.idea.coordinates_longitude;

		return(
			<div className='m-b-30'>
				<label><b>Dirección</b></label><br/>
				Da clic izquierdo en el mapa para que aparezca el marcador ( <FontAwesomeIcon icon={'map-marker-alt'} color={'red'}></FontAwesomeIcon> ), 
        puedes arrastrarlo con clic derecho para ubicar tu empresa.<br/><br/>
				<KMap marker={{ latitude:parseFloat( latitude ),
								longitude:parseFloat( longitude ) }}
							onMarkerChange={(e) => this.setMarkerPosition(e)}
							getAddress={(res) => this.getAddressFromMap(res)}/>
			</div>
		)
	}

	onChangeState(state) {
		this.setState(prevState => {
			let formData = Object.assign({}, prevState.formData);
			formData.idea.address_state = state.value;
			return { formData };
		}, () => {
			this.setState({
				stateLabel: state
			})
		});
	}

	renderAddressForm () {
		const isoCodes = [
			{label:"Aguascalientes", value:"MX-AGU"},
			{label:"Baja California", value:"MX-BCN"},
			{label:"Baja California Sur", value:"MX-BCS"},
			{label:"Campeche", value:"MX-CAM"},
			{label:"Coahuila de Zaragoza", value:"MX-COA"},
			{label:"Colima", value:	"MX-COL"},
			{label:"Chiapas", value:"MX-CHP"},
			{label:"Chihuahua", value:"MX-CHH"},
			{label:"Ciudad de México", value:"MX-CMX"},
			{label:"Durango", value:"MX-DUR"},
			{label:"Guanajuato", value:"MX-GUA"},
			{label:"Guerrero", value:"MX-GRO"},
			{label:"Hidalgo", value:"MX-HID"},
			{label:"Jalisco", value:"MX-JAL"},
			{label:"México", value:"MX-MEX"},
			{label:"Michoacán de Ocampo", value:"MX-MIC"},
			{label:"Morelos", value:"MX-MOR"},
			{label:"Nayarit", value:"MX-NAY"},
			{label:"Nuevo León", value:"MX-NLE"},
			{label:"Oaxaca", value:"MX-OAX"},
			{label:"Puebla", value:"MX-PUE"},
			{label:"Querétaro", value:"MX-QUE"},
			{label:"Quintana Roo", value:"MX-ROO"},
			{label:"San Luis Potosí", value:"MX-SLP"},
			{label:"Sinaloa", value:"MX-SIN"},
			{label:"Sonora", value:"MX-SON"},
			{label:"Tabasco", value:"MX-TAB"},
			{label:"Tamaulipas", value:"MX-TAM"},
			{label:"Tlaxcala", value:"MX-TLA"},
			{label:"Veracruz de Ignacio de la Llave", value:"MX-VER"},
			{label:"Yucatán", value:"MX-YUC"},
			{label:"Zacatecas", value:"MX-ZAC"},
		];
		let multipleOptions = isoCodes.map( s => ({value: s.value, label: s.label }));
	    return (
	      <div>
			{/* <FormInput
				id="Estado"
				ref={ref => this.fi_address_state = ref}
				label="Estado"
				model="address_state"
				showCounter={false}
				editable={false}
				editMode={false}
				value={this.getAddressState(this.state.formData.idea.address_state) }
				onChange= {change => { this.onChangeForm(change) }}
				validationRules="required|string"
				help="Estado donde se localiza tu empresa"
			/> */}
			<FormInput
	          id="calle"
	          ref={ref => this.fi_address_street = ref}
	          label="Calle"
	          model="address_street"
	          showCounter={true}
	          maxLength={100}
	          value={ this.state.formData.idea.address_street }
	          editMode={this.state.editMode}
	          onChange= {change => { this.onChangeForm(change) }}
	          validationRules="required|string"
	          help="Calle dónde está el proyecto"/>

			<FormInput
	          id="Número"
	          ref={ref => this.fi_address_number_ext = ref}
	          label="Número"
	          model="address_number_ext"
	          maxLength={10}
	          showCounter={true}
	          value={ this.state.formData.idea.address_number_ext }
	          editMode={this.state.editMode}
	          onChange= {change => { this.onChangeForm(change) }}
	          validationRules="required|string"
	          help="Número del domicilio"/>

			<FormInput
	          id="Colonia"
	          ref={ref => this.fi_address_neighborhood = ref}
	          label="Colonia"
	          model="address_neighborhood"
	          showCounter={false}
	          editMode={this.state.editMode}
	          value={ this.state.formData.idea.address_neighborhood}
	          onChange= {change => { this.onChangeForm(change) }}
	          validationRules="required|string"
	          help="Colonia donde se localiza tu empresa"/>

			<FormInput
	          id="Municipio"
	          ref={ref => this.fi_address_municipality = ref}
	          label="Municipio"
	          model="address_municipality"
	          showCounter={false}
	          editMode={this.state.editMode}
	          value={ this.state.formData.idea.address_municipality}
	          onChange= {change => { this.onChangeForm(change) }}
	          validationRules="required|string"
	          help="Municipio donde se localiza tu empresa"/>

			<div className='mb-4'>
				<label>
					<b>Estado
						{/* <span style={{color: '#ef5350'}} className="ml-2">*</span> */}
					</b>
				</label><br/>
				<Select
					value={this.state.stateLabel}
					onChange={(state) => this.onChangeState(state)}
					placeholder={"Selecciona el estado"}
					options={multipleOptions}
				/>
				<small className="form-text text-muted">Estado donde se localiza tu empresa</small>
			</div>

	        <FormInput
	          id="código postal"
	          ref={ref => this.fi_address_zip_code = ref}
	          label="Código postal"
	          model="address_zip_code"
	          maxLength={5}
	          cssClass="custom-class"
	          value={ this.state.formData.idea.address_zip_code }
	          editMode={this.state.editMode}
	          onChange= {change => {this.onChangeForm(change) }}
	          validationRules="isInteger|minLen:5|maxLen:5"
	          transformMethod={transformToInt}
	          help="Escribe el código postal de la zona donde se encuentra tu establecimiento (Mínimo 5 caracteres)."
	        />

	      </div>
    	)
  }

	renderPyme() {
		if (this.state.registerStep === "pyme") {
			return (
				<div className='row'>
					<div className='col-sm-12 col-xs-12'>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_goverment_id_rfc = ref}
								id="rfc"
								model="goverment_id_rfc"
								label="Registro Federal de Contribuyentes"
								cssClass="custom-class"
								value={this.state.formData.pyme.goverment_id_rfc}
								help="Escribe el RFC de tu empresa sin guiones o espacios."
								maxLength={13}
								validationRules="rfc"
								transformMethod={transformToUpperCase}
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_goverment_legal_name = ref}
								id="razón social"
								model="goverment_legal_name"
								label="Razón social"
								cssClass="custom-class"
								value={this.state.formData.pyme.goverment_legal_name}
								help="Puedes estar dado de alta como persona moral o física. En caso de que estes dado de alta como persona física debes de volver a ingresar tu nombre."
								maxLength={140}
								validationRules="minLen:3|maxLen:140"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_phone = ref}
								id="teléfono"
								model="phone"
								label="Teléfono"
								cssClass="custom-class"
								showCounter={false}
								value={this.state.formData.pyme.phone}
								help="Escribe el teléfono de tu empresa, puedes ser el mismo con el que te diste de alta."
								maxLength={14}
								transformDisplayMethod={transformToPhone}
								transformMethod={editTransformToPhoneNumber}
								validationRules="phone|minLen:3|maxLen:10"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_stats_sales = ref}
								id="número de ventas anuales"
								model="stats_sales"
								label="Ventas anuales"
								cssClass="custom-class"
								showCounter={false}
								value={this.state.formData.pyme.stats_sales}
								help="Escribe sin espacios o comas tus ventas anuales del año pasado (aproximadamente) en pesos MXN."
								maxLength={20}
								transformMethod={transformToMoney}
								validationRules="isNumber"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_gathered_capital = ref}
								id="capital recaudado"
								label="Capital recaudado"
								model="gathered_capital"
								cssClass="custom-class"
								value={this.state.formData.pyme.gathered_capital}
								help="En caso de haber recibido inversión ingresa el monto en pesos MXN. Si no has recibido inversión escribe 0 (cero)."
								maxLength={20}
								transformMethod={transformToMoney}
								validationRules="isNumber"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_net_spending = ref}
								id="gasto neto mensual"
								label="Gasto de operación mensual"
								model="net_spending"
								cssClass="custom-class"
								value={this.state.formData.pyme.net_spending}
								help="Escribe sin espacios o comas el costo promedio de operar tu empresa de forma mensual en pesos MXN."
								maxLength={20}
								transformMethod={transformToMoney}
								validationRules="isNumber"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderScaleUp() {
		if (this.state.registerStep === "scaleup") {
			return (
				<div className='row'>
					<div className='col-sm-12 col-xs-12'>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_pre_capital_value = ref}
								id="valor pre-capital"
								model="pre_capital_value"
								label="Valuación esperada de tu empresa"
								cssClass="custom-class"
								showCounter={false}
								value={this.state.formData.scaleup.pre_capital_value}
								help="En caso de estar buscando capital ingresa tu valuación estimada de tu empresa (pre-capital) en pesos MXN."
								maxLength={20}
								transformMethod={transformToMoney}
								validationRules="isNumber"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group'>
							<FormInput
								ref={ref => this.fi_seeked_capital = ref}
								id="capital buscado"
								model="seeked_capital"
								label="Capital esperado"
								cssClass="custom-class"
								showCounter={false}
								value={this.state.formData.scaleup.seeked_capital}
								help="Ingresa el monto sin espacios o comas del capital esperado que requieres para tu empresa en pesos MXN."
								maxLength={20}
								transformMethod={transformToMoney}
								validationRules="isNumber"
								onChange={change => { this.onChangeForm(change) }}
							/>
						</div>
						<div className='form-group' >
							<label>Programas de incubación o aceleración realizados</label>
							<small className="form-text text-muted">Marca la casilla si alguno de los socios de tu empresa han participado en algún programa de incubación o aceleración previamente. Escribe el año y nombre del programa en el cual participaron.</small>
						</div>
						<div className='form-group' >
							<label>
								<input
									type="checkbox"
									id="cb_has_participated_program"
									className="filled-in chk-col-red"
									name='has_participated_program'
									value={this.state.formData.scaleup.has_participated_program}
									onChange={(e) => { this.handleCheckbox("scaleup", e) }}
								/>
								<label htmlFor="cb_has_participated_program">Si. He participado en algún programa.</label>
							</label>
							<div className='form-group m-l-40'>
								{this.renderHasParticipatedProgram()}
							</div>

						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	handleCheckbox(phase,data){
		this.setState({
			formData: {
				...this.state.formData,
				[phase]: {
					...this.state.formData[phase],
					[data.target.name]: !this.state.formData[phase][data.target.name]
				}
			}
		});
	}
	renderHasParticipatedProgram(){
		if(this.state.formData.scaleup.has_participated_program){
			return (
				<FormTextArea
					ref={ref =>this.ft_participated_program_name = ref}
					id="programas en lo que has participado"
					label="¿En Cuáles?"
					model="participated_program_name"
					cssClass="custom-class"
					value={this.state.formData.scaleup.participated_program_name}
					help="Escribe en cuales programas has participado (Mínimo 5 caracteres)."
					maxLength={300}
					validationRules="minLen:5|maxLen:300"
					onChange={ change => { this.onChangeForm(change) } }
					/>
			)
		}else{
			return (null);
		}
	}
	//The view will change according to the register step
	renderContent() {

		return(
			<div>
				{this.renderIdea()}
				{this.renderPrototype()}
				{this.renderStartup()}
				{this.renderPyme()}
				{this.renderScaleUp()}
			</div>
		)

	}

	handleBlockedNavigation = (nextLocation) => {
		if(!this.state.confirmedNavigation){
			this.confirmLeave(nextLocation);
			return false;
		}

		return true
	}
	renderProjectName(){
		if(this.state.registerStep === "idea"){
			return(
				<div>
					<h2 className="card-title text-center projectName">{this.state.formData.idea.name}</h2>
				</div>

			);
		}else{
			return(
				<div>
					<h5 className="card-title text-center text-muted">{this.state.formData.idea.name}</h5>
				</div>
			);
		}
	}
	render() {
		/*
			Indicate if the user can leave the page
		*/
		const formIsHalfFilledOut = this.state.formIsHalfFilledOut
		/*
			Variables for nextstep and back
		*/
		let backText='Atras',nextText='Siguiente y guardar';
		let showButton='btnOrder', backButton= '';

		if(this.state.registerStep === 'idea') {
			if(this.state.companyId !== null){
				backText='Cerrar';
			}else{
				backText='Cancelar';
				backButton=' btn-danger'
			}
		} else if(this.state.registerStep === 'scaleup') {
			nextText='Finalizar y guardar'
			showButton += ' showButton'
		}
		/*
			Interface titles
		*/
		const titles = [ '', 'Prototipo', 'Startup', 'Pyme', 'Scale up']

		return (
			<div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
			<Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.closeTour}
        onAfterOpen={this.disableBody}
        onBeforeClose={this.enableBody}
        />
			<Prompt
					when={formIsHalfFilledOut} // <- function that returns boolean
					message={this.handleBlockedNavigation}
				/>
				<div id="main-wrapper">
				<Header
					user={this.state.me}
					onBurgerClicked={() => this.handleResizeBar('byClick')}
					onResize={(w) => this.handleHeaderResize(w)}
					hideBurguer={this.state.showTopbar}
				/>
				<TopSideBar
					user={this.state.me}
					hideTopBar={!this.state.showTopbar}
				/>
				<SideBar 
					user={this.state.me} 
					visible={this.state.sidebarStatus} 
					hideSideBar={this.state.showTopbar}
				/>
					{/* Page wrapper  */}
					<div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
		        <div className="container-fluid company-view">
		          <div className="row">
		            <div className="col-md-5 align-self-center">
		              <h3 className="text-themecolor">Registra tu empresa</h3>
		            </div>
		            {this.renderHelp()}
		          </div>
		          <div className="row">
		            <div className="col-12">
		              <div className="card">
		                <div className="card-body">
		                	{/*<p>Para continuar con tu inscripción al programa: <span className="font-bold mr-1">{this.state.programName}</span>
		                	da de alta tu empresa, startup o empresa.</p>*/}
											<h2 className="card-title text-center">{titles[this.pages.indexOf(this.state.registerStep)]}</h2>
											{this.renderProjectName()}
		                  <div className="form-horizontal m-t-30">
		                  	{this.renderContent()}
		                  </div>
		                  <div className="button-group">
								        <button type="button" data-tut="cancel-button" className={"btn waves-effect waves-light btn-info btnOrder " + backButton} onClick={this.prevPage}>{backText}</button>
								        <button type="button" data-tut="save-and-continue-button" className="btn waves-effect waves-light btn-info btn-save btnOrder " onClick={this.nextPage}>{nextText}</button>
								        <button type="button" data-tut="save-and-close-button" className={"btn waves-effect waves-light btn-info " + showButton} onClick={this.sendAndClose}>Guardar y cerrar</button>
								      </div>
		                </div>
		              </div>
		            </div>
		          </div>
							<Footer/>
		        </div>
						<KAddPartnersModal isModalOpen={this.state.isModalPartnersOpen}
							companyId={this.state.companyId}
							closeModal={this.closePartnersModal}
							onPartnersAdded={this.onPartnersAdded}></KAddPartnersModal>
		      </div>
		    </div>
      </div>
		);
	}

}

/*<div className='form-group'>
	<label>Tipo de moneda</label>
	<select className='form-control custom-select'
  	name='currency'
  	value={this.state.formData.startup.currency}
    onChange={this.startupChange}
  >
  	<option value='MXN'>MXN</option>
  	<option value='USD'>USD</option>
  	<option value='EUR'>EUR</option>
  </select>
  <small className="form-text text-muted">Seleccione el tipo de moneda que desea manejar.</small>
</div>*/

export default CreateCompanyView;