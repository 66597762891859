/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, July 2019
 */
var CurrencyFormatter ={
  getCurrencySymbol(currency){
    switch (currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "CAD":
        return "$";
      case "MXN":
        return "$";
      case "JPY":
        return "¥";
      case "INR":
        return "₹";
      default:
        return "$";
    }
  },
  numberWithCommas(x,currency) {
    x = x === null ? 0 : parseFloat(x);
    x = x.toFixed(2);
    if(currency === "EUR"){
      x = x.toString().replace(".",",");
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else{
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  numberWithCommas4Decimals(x,currency) {
    let originalValue = x;
    let y = "";
    if(x === ""){
      return "";
    }
    x = x.toString().replace(/,/g,"");
    let splitted_val = x.split(".");
    x = splitted_val[0];
    if(splitted_val.length > 0){
      y = splitted_val[1];
      if(y !== undefined){
        if(y.toString().length > 2){
          y = y.substring(0,4);
        }
      }
    }
    if(x.length > 3){
      x = parseFloat(x);
      x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if(originalValue.includes(".")){
      return `${x}.${y}`
    }else{
      return x;
    }
  },
  format(amount,currency){
    return CurrencyFormatter.getCurrencySymbol(currency)+
            CurrencyFormatter.numberWithCommas(amount,currency);
  }
}
module.exports = CurrencyFormatter;
