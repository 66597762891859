/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, June 2019 &
 * Darien Miranda <darien@startuplab.mx>, August 2019.
 */

import * as _ from 'lodash'

const messages = {
  required: 'El campo :field es requerido.',
  string: 'El campo :field tiene que ser texto.',
  number: 'El campo :field tiene que ser numérico.',
  isInteger: 'El campo :field tiene que ser un número entero',
  isFloat: 'El campo :field tiene que ser un número entero o decimal',
  object: 'El campo :field no tiene el formato apropiado.',
  array: 'El campo :field no tiene el formato apropiado.',
  boolean: 'El campo :field tiene que ser si o no.',
  min: 'El campo :field tiene que tener al menos :condition :unit.',
  max: 'El campo :field tiene que tener máximo :condition :unit.',
  maxLen: 'El campo :field tiene que tener máximo :condition :unit.',
  minLen: 'El campo :field tiene que tener al menos :condition :unit.',
  isNumber: 'El campo :field tiene que ser igual o mayor a 0.',
  email: 'El campo :field tiene que ser un correo electrónico válido.',
  rfc: 'El campo :field tiene que ser un RFC válido.',
  phone: 'El campo :field tiene que ser un número telefónico válido de 10 dígitos.',
  curp: 'El campo :field tiene que ser un CURP válido.',
  url: 'El campo :field tiene que ser una URL válida.',
  isApartmentNumber: 'El campo :field no es un número de apartamento válido.',
  isLinkedIn: 'El campo :field no es una URL de LinkedIn válida.',
  isValidDate: 'El campo :field no es una fecha válida',
  isWhatsappGroupLink: 'El campo :field no es una URL de grupo de Whatsapp válido'
}

/*
 * Checks if the given value is not null, [],
 * {}, undefined or ''
 *
 * @params mixed value
 * @returns bool
 */
export function required (value) {
  return !_.isNil(value) && !_.isNull(value) && !_.isEmpty(value)
}

/*
 * Checks if the given value is a string
 *
 * @params mixed value
 * @returns bool
 */
export function string (value) {
  return _.isString(value)
}

/*
 * Checks if the given value is a number
 *
 * @params mixed value
 * @returns bool
 */
export function number (value) {
  return _.isNumber(parseFloat(value.replace(/,/g, '')))
}

/*
 * Checks if the given value is an integer number
 *
 * @params mixed value
 * @returns bool
 */
export function isInteger (value) {
  let re = /^\d*$/;
  return re.test(value);
}
/*
 * Checks if the given value is a valid apartment
 * @params mixed value
 * @returns bool
 */
export function isApartmentNumber(value){
  let r = /^\w*-*\w$/;
  return r.test(value);
}
/*
 * Checks if the given value is a float number
 *
 * @params mixed value
 * @returns bool
 */
export function isFloat (value) {
  let re =  /^[+-]?\d+(\.\d+)?$/
  if(re.test(String(value))) {
    return true
  }
  return false
}

/*
 * Checks if the given value is an object
 *
 * @params mixed value
 * @returns bool
 */
export function object (value) {
  return _.isObject(value)
}

/*
 * Checks if the given value is an array
 *
 * @params mixed value
 * @returns bool
 */
export function array (value) {
  return _.isArray(value)
}

/*
 * Checks if the given value is boolean
 *
 * @params mixed value
 * @returns bool
 */
export function boolean (value) {
  return _.isBoolean(value)
}

/*
 * Checks a given value depending if it's a number, string, array or object.
 * If it's a number the min is compared to the value.
 * If it's a string, array or object, the min is compared against the value length.
 *
 * @params mixed value
 * @params int min
 * @returns bool
 */
export function min (value, min) {
  if (number(value)) {
    return number >= min
  } else if (string(value)) {
    return value.trim().length >= min
  }

  return false
}

/*
 * Checks a given value depending if it's a number, string, array or object.
 * If it's a number the max is compared to the value.
 * If it's a string, array or object, the max is compared against the value length.
 *
 * @params mixed value
 * @params int max
 * @returns bool
 */
export function max (value, max) {
  if (number(value)) {
    return number <= max
  } else if (string(value)) {
    return value.length <= max
  }
  return false
}
/*
 * Checks if a string's length is smaller than a max.
 *
 * @params mixed value
 * @params int max
 * @returns bool
 */
export function maxLen (value, max) {
  return value.length <= max;
}
/*
 * Checks if a string's length is bigger than a min.
 *
 * @params mixed value
 * @params int max
 * @returns bool
 */
export function minLen (value, max) {
  return value.length >= max;
}
/*
 * Checks if a string's is a valid positive number.
 *
 * @params mixed value
 * @params int max
 * @returns bool
 */
export function isNumber(value){
  return parseFloat(value.match(/^-?\d*(\.\d+)?$/))>=0;
}

/*
 * Checks if the given string has a valid email format
 *
 * @params string value
 * @returns bool
 */
export function email (value) {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
  return string(value) && re.test(value)
}

/*
 * Checks if the given string is a valid rfc format
 *
 * @params string value
 * @returns bool
 */
export function rfc (value) {
  let re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
  return string(value) && re.test(value)
}
/*
 * Checks if the given string is a valid curp format
 *
 * @params string value
 * @returns bool
 */
export function curp (value) {
  let re   = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
  return re.test(value);
}

/*
 * Checks if the given string is a valid LinkedIn link
 *
 * @params string value
 * @returns bool
 */
export function isLinkedIn(link){
  let reW = /^[a-z]{2,3}\.linkedin\.com\/.*$/;
  if(reW.test(link)){
    link = "https://" + link;
  }
  let reH = /^http:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  if(reH.test(link)){
    link = link.replace("http:","https:");
  }
  let re = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;

  return re.test(link);
}
/*
 * Checks if the given string has a valid phone format
 *
 * @params string value
 * @returns bool
 */
export function phone (value) {
  let re = /^\d{10}$/
  return re.test(value)
}

/*
 * Checks if the given string has a valid url format
 *
 * @params string value
 * @returns bool
 */
export function url (value) {
  let re =  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return re.test(value)
}

/*
 * Gets the actual error message
 *
 * @params object exception
 * @returns string
 */
export function getMessage (exception) {
  if (exception.condition) {
    return messages[exception.method_failed].replace(':field', exception.field_id)
      .replace(':condition', _.toString(exception.condition))
      .replace(':unit', getUnit(exception.method_failed))
  }

  return messages[exception.method_failed].replace(':field', exception.field_id)
}

/*
 * Gets the unit for error message
 *
 * @params string method
 * @returns string
 */
export function getUnit (method) {
  return 'string' ? 'caracteres' : ''
}
/*
 * Validates a whatsapp https group link
 *
 */
export function isWhatsappGroupLink(wlink){
  let re = /https:\/\/chat.whatsapp.com\/([A-Z])\w+/;
  return re.test(wlink);

}
