/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, May 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/**
 *  Selects one of your active companies.
 *  The select is the component 'react-select'.
 */

class ProgramOfCompanyPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companySelected:[],
      programSelected:[],
      options:[],
    }
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.handleSelect           = this.handleSelect.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let propOptions = props.programs.map(p => ({ value: p._id, label: p.name,program:p}))
    if(state.companySelected !== null){
      if(props.onProgramSelected === null) {
        return {
          programSelected: [],
          options: propOptions
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }
  componentDidMount() {
    console.log(this.state.options)
    this.setState({companySelected: this.props.companySelected})
    this.setMultipleOptions()
  }
  setMultipleOptions(){
    this.setState({options: this.props.programs.map(p => ({ value: p._id, label: p.name,program:p})) })
  }
  handleSelect(selectedOptions){
    this.setState({programSelected:selectedOptions});
    this.props.onSelect(selectedOptions);
  }
  removeSelections(){
    this.setState({programSelected:[]});
  }

  renderLabel(){
    if(this.props.showLabel){
      return(
        <label>Programa</label>
      )
    }else{
      return null;
    }
  }

  render(){
    const customStyles = {
      container: (provided) => ({
          ...provided,
          zIndex: 10,
        }),
    }
    return(
      <div className='form-group'>
        {this.renderLabel()}
        <Select
          value={this.state.programSelected}
          onChange={this.handleSelect}
          options={this.state.options}
          placeholder={this.props.placeholder}
          // styles={customStyles}
        />
      </div>
    )
  }
}

ProgramOfCompanyPicker.propTypes = {
  /** Returns the company id when a company is selected. */
  onSelect                : PropTypes.func,
  /** Default selection, array of ids. */
  programSelected       : PropTypes.array,
  /** Placeholder of the select */
  placeholder             : PropTypes.string,
  /** Show the label of the input select. */
  showLabel               : PropTypes.bool,
  /** Programs to be displayed           */
  programs       : PropTypes.array,
  /*Returns selected company*/
  companySelected         : PropTypes.object,
  /*Returns selected program*/
}

ProgramOfCompanyPicker.defaultProps = {
  onSelect                : (program) => {},
  programSelected         : [],
  placeholder             : 'Selecciona un programa',
  showLabel               : true,
  programs                : [],
  companySelected         : {}
}

export default ProgramOfCompanyPicker;
