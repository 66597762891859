/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

// Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

//Components
import KCompanyRow from './KCompanyRow';


/**
*   Render a list of companies, using the component [KCompanyRow](/#kcompanyrow)
*/

class KCompanyList extends React.Component {

  renderTable(companies){
    return(
      <Table  responsive
              borderless={this.props.borderlessStyle}
              striped={this.props.stripedStyle}
              hover={this.props.hoverStyle}
              className='tableVMiddle'
              style={this.props.style}>
        <thead>
          <tr>
            {this.props.titles.map(title => (
                <th>{title}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {companies.map((item,index) =>
            (
              <KCompanyRow  key={index}
                            company={item}
                            user={this.props.user}
                            shortView={this.props.shortView}
                            viewLabel={this.props.viewLabel}
                            onClickCompany={(company) => this.props.onClickCompany(company)}
                            onRatingSelected={(rating) => this.props.onRatingSelected(rating)}
                            mentoringStatus={this.props.isMentoring}
                            isMentoringSuggestion={this.props.isMentoringSuggestion}
                            onSuggestionSelected={(company) => this.props.onSuggestionSelected(company)}
                            ratingsEnabled={this.props.ratingsEnabled}
                            managerView={this.props.managerView}
                            managerDashboardView={this.props.managerDashboardView}
                            invitationMode={this.props.invitationMode}
                            showDate={this.props.showDate}
                            showCreator={this.props.showCreator}
              />
            )
          )}
        </tbody>
      </Table>
    )
  }

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al cargar las empresas</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderSupportLink(){
    if(this.props.supportLink !== ''){
      return(
        <a href={this.props.supportLink}>Ayuda</a>
      )
    }else{
      return null;
    }
  }

  renderButton(){
    if(this.props.buttonLink !== ''){
      return(
        <a href={this.props.buttonLink}>{this.props.buttonText}</a>
      )
    }else{
      return null;
    }
  }

  renderEmptyList(){
    return(
      <div className="text-center">
        <h3>{this.props.emptyListTitle}</h3>
          <i className="mdi mdi-lightbulb-on m-t-20 m-b-20" style={{fontSize: '50px', color:"#c2c2c2"}}></i>
        <h5>
          {this.renderButton()}
          {this.props.emptyListSubtitle}
        </h5>
        <h5>
          {this.renderSupportLink()}
        </h5>
      </div>
    )
  }
  render() {
    const companies = this.props.companies;
    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(companies.length > 0){
        return this.renderTable(companies)
      }else{
        return this.renderEmptyList()
      }
    }else{
      return this.renderFailed()
    }
  }
}

KCompanyList.propTypes = {
  /** List of companies*/
  companies             : PropTypes.array,

  /** User object*/
  user                  : PropTypes.object,

  /** Status of the loading data: idle, fetching, success and failed*/
  requestStatus         : PropTypes.string, //idle,fetching,success,failed

  /** When is true, the row only displays the main information of a company.
  Image, name and link */
  shortView             : PropTypes.bool,

  /** When is true the style list is striped */
  stripedStyle          : PropTypes.bool,

  /** When is true the hover is set */
  hoverStyle            : PropTypes.bool,

  /** When is true the list is borderless */
  borderlessStyle       : PropTypes.bool,

  /** Callback when the company is clicked*/
  onClickCompany        : PropTypes.func,

  /** Callback when the ratig is selected*/
  onRatingSelected   : PropTypes.func,

  /** Label of the link*/
  viewLabel             : PropTypes.string,

  /** Display the list as a mentor of the company.
  TO DO:check if this is usefull*/
  isMentoring           : PropTypes.bool,
  
  /** Display the list of suggestions to be a mentor of the program.*/
  isMentoringSuggestion : PropTypes.bool,

  /** Callback when the suggestion is selected*/
  onSuggestionSelected   : PropTypes.func,

  /** When is true display the rating option */
  ratingsEnabled        : PropTypes.bool,

  /** A list view if the user is a manager,
  TO DO:Check if this can be changed by the prop shortView*/
  managerView           : PropTypes.bool,

  /** A list view if the user is a manager and is the dashboard view*/
  managerDashboardView  : PropTypes.bool,

  /** When the list is empty the text is display:*/
  emptyListTitle        : PropTypes.string,

  /** When the list is empty the text is display:*/
  emptyListSubtitle     : PropTypes.string,

  /** Display a support link if the list is empty: */
  supportLink           : PropTypes.string,

  /** Display a button link if the list is empty: */
  buttonLink            : PropTypes.string,

  /** Display a button text if the list is empty: */
  buttonText            : PropTypes.string,

  /** Show if the company has invitations (the whole list of invitation)*/
  invitationMode        : PropTypes.bool,

  /** Show created at date*/
  showDate              : PropTypes.bool,

  /** Show creator name*/
  showCreator           : PropTypes.bool,

  /** Table style inline*/
  style                 : PropTypes.object,

  /*Headers of tables*/
  titles                : PropTypes.array
};

KCompanyList.defaultProps = {
  companies             : [],
  user                  : {},
  requestStatus         : 'idle',
  shortView             : false,
  stripedStyle          : true,
  hoverStyle            : false,
  borderlessStyle       : true,
  ratingsEnabled        : false,
  onClickCompany        : () => {},
  onRatingSelected      : () => {},
  viewLabel             : "Ver / Editar",
  KTabSettingsContent   : false,
  managerView           : false,
  managerDashboardView  : false,
  emptyListTitle        : 'No cuentas con empresas registradas',
  emptyListSubtitle     : '',
  supportLink           : '',
  buttonLink            : '',
  buttonText            : '',
  isMentoringSuggestion : false,
  onSuggestionSelected  : () => {},
  invitationMode        : false,
  showDate              : true,
  showCreator           : false,
  style                 : {},
  titles                : []
};

export default KCompanyList;
