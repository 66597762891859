/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, October 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import KomunidadApi from '../api/api.js';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import Swal from 'sweetalert2';


/**
*  Send a certificate
*/

class KSendCertificatesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savedCertificates:[],
      certificateSelected:{},
      certificateId:null,
      programId:'',
      users_ids:'',
      pdfMode:true,
      emailMode:false,
      cb_partner:true,
      cb_mentor:true
    }
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
    this.onCertificateSelected       =  this.onCertificateSelected.bind(this);
    this.onProgramSelected           =  this.onProgramSelected.bind(this);
    this.getMentorsIds               =  this.getMentorsIds.bind(this);
    this.getPartnersIds              =  this.getPartnersIds.bind(this);
    this.sendCertificates            =  this.sendCertificates.bind(this);
    this.onChangeInput               =  this.onChangeInput.bind(this);
    this.onDeliveryChange            =  this.onDeliveryChange.bind(this);
    this.onNavigateToCert            =  this.onNavigateToCert.bind(this);
    this.fetchCertificates           =  this.fetchCertificates.bind(this);
  }

  componentDidMount(){
  }
  fetchCertificates(){
    KomunidadApi.getCertificatesByProgramId(this.props.program_id).then( (res) => {
      if(res.success){
            this.setState({
              savedCertificates:res.certificates
            })
      }
    });
  }
  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.props.closeModal(false);
  }

  onCertificateSelected(id){
    this.setState({certificateId:id});
  }

  onProgramSelected(programs){
    // this.setState({programId:programs._id});
  }

  getMentorsIds(){
    let mentors = this.props.mentors;
    let mentors_ids = '';

    if( mentors.length > 0){
      let m = mentors.map( mentor => mentor._id);
      mentors_ids = m.join("|")
    }
    return mentors_ids;
  }

  getPartnersIds(){
    let partners = this.props.partners;
    let partners_ids = '';

    if( partners.length > 0){
      let m = partners.map( partner => partner._id);
      partners_ids = m.join("|")
    }
    return partners_ids;
  }
  validate(){
    return(
      (this.state.cb_partner || this.state.cb_mentor) &&
      this.state.certificateId !== null
    )
  }
  sendCertificates(){
    if(this.state.pdfMode){
      this.form.submit();
    }else{
      let users_ids = [];
      if(this.state.cb_partner){
        users_ids = this.props.partners.map( p => p._id);
      }
      if(this.state.cb_mentor){
        users_ids = users_ids.concat(this.props.mentors.map( p => p._id));
      }
      users_ids = users_ids.join("|");
      KomunidadApi.sendCertificates(this.state.certificateId,this.props.program_id,users_ids).then( (res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Certificados enviados',
            footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },

          });
        }else{
          Swal.fire({
            type: 'error',
            title: 'Error al enviar los certificados:',
            text: res.error_es,
            footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  onDeliveryChange(e){
    let id = e.target.id;
    let checked = e.target.checked;
    if(id.includes("radio_pdf")){
      this.setState({
        pdfMode: checked,
        emailMode: !checked
      })
    }else{
      this.setState({
        emailMode: checked,
        pdfMode: !checked
      })
    }
  }
  isSelectedStyle(cert){
    if(cert._id === this.state.certificateId){
      return {
        backgroundColor: "#E1F7FA",
      }
    }else{
      return {};
    }
  }

  onNavigateToCert(){
    this.props.closeModal(false);
    this.props.onClickNavigation();
  }

  renderSavedCertificates(){
    const savedCertificates = this.state.savedCertificates;

    if(savedCertificates.length > 0){
      return savedCertificates.map( cert => {
        let isSelectedStyle = this.isSelectedStyle(cert);
         return(
           <Col xs={4} md={4} style={isSelectedStyle} >
             <img
               className='img-responsive m-b-5 m-t-10'
               src={cert.thumbnail}
               alt='template'
               onClick={() => this.onCertificateSelected(cert._id)}
               />
             <div className='text-center font-bold m-b-10' style={{fontSize:'12px'}}>{cert.name}</div>
           </Col>
         )
      })
    }else{
      return (
        <Col xs={12} md={12}>
          <center>
            <h5 className='text-muted'>Este programa no cuenta con certificados</h5>
            <button className='btn btn-link' onClick={() => this.onNavigateToCert()}>Crear certificado</button>
          </center>
        </Col>
      )
    }
  }
  renderContent(){
    return(
      <div className="card-body">
        <h4 className="card-title"><span className="lstick"></span>Selecciona un certificado</h4>
        <Container>
         <Row className="show-grid certificates-row">
          {this.renderSavedCertificates()}
         </Row>
        </Container>
      </div>
    )
  }

  renderDeliveryType(){
    return(
      <div className="card-body">
      <h4 className="card-title"><span className="lstick"></span>Selecciona un modo para emitir los certificados</h4>
        <div>
          <input id="radio_pdf" onClick={this.onDeliveryChange} checked={this.state.pdfMode} name="group1" type="radio" className="with-gap radio-col-purple"/>
          <label for="radio_pdf" id="radio_pdf_lb" onClick={this.onDeliveryChange}  >Descargar como PDF</label>
          <input id="radio_email" onClick={this.onDeliveryChange} checked={this.state.emailMode}  name="group1" type="radio" className="with-gap radio-col-purple"/>
          <label for="radio_email" id="radio_email_lb" onClick={this.onDeliveryChange} >Enviar por correo</label>
        </div>
      </div>
    )
  }

  renderUserType(){
    return(
      <div className="card-body">
        <h4 className="card-title"><span className="lstick"></span>Selecciona a quienes enviar el certificado</h4>
        <label>
          <input type="checkbox"
                 id="cb_partner"
                 className="filled-in chk-col-purple"
                 name='cb_partner'
                 value={this.state.cb_partner}
                 checked={this.state.cb_partner}
                 onChange={this.onChangeInput}/>
          <label for="cb_partner">Emprendedores</label>
        </label>
        <label className="m-l-20">
          <input type="checkbox"
                 id="cb_mentor"
                 className="filled-in chk-col-purple"
                 name='cb_mentor'
                 value={this.state.cb_mentor}
                 checked={this.state.cb_mentor}
                 onChange={this.onChangeInput}/>
          <label for="cb_mentor">Aliados</label>
        </label>

      </div>
    )
  }
  getUserIds(){
    let users_ids = [];
    if(this.state.cb_partner){
      users_ids = this.props.partners.map( p => p._id);
    }
    if(this.state.cb_mentor){
      users_ids = users_ids.concat(this.props.mentors.map( p => p._id));
    }
    users_ids = users_ids.join("|");
    return users_ids;
  }
  render() {
    return (
      <Modal  show={this.props.showModal}
              onShow={this.fetchCertificates}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Emitir certificados
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
          {this.renderUserType()}
          {this.renderDeliveryType()}
          <form ref={ (ref) => { this.form = ref; } }
                method="post"
                style={{display:'none'}}
                action={`${KomunidadApi.API_URL}/certificates/${this.state.certificateId}/issue`}>
                <input type="text" value={this.props.program_id} name="program_id"/>
                <input type="text" value={this.getUserIds()} name="users_ids"/>
                <input type="submit" id="send-certificate"/>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={this.validate() ? "info":"btn btn-link"} disabled={!this.validate()} onClick={() => this.sendCertificates()}>
            {this.state.pdfMode ? 'Descargar' : 'Enviar'}
          </Button>
          <Button variant="danger" onClick={() => this.handleCloseModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

KSendCertificatesModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,

  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,

  /** Certificate object. */
  certificate         : PropTypes.object,

  /** Partners list. */
  partners            : PropTypes.array,

  /** Mentors list. */
  mentors             : PropTypes.array,

  /** Program id */
  program_id          : PropTypes.string,

  /** Callback on send certificate */
  onClickNavigation   : PropTypes.func,
};

KSendCertificatesModal.defaultProps = {
  closeModal          : () => {},
  showModal           : false,
  certificate         : {},
  partners            : [],
  mentors             : [],
  program_id          : '',
  onClickNavigation   : () => {},
};

export default KSendCertificatesModal;
