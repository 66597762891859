/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, November 2019
 */

// Regular imports
import React,  { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete, renderFullNameOrEmail} from '../../utils/Utils.js';
import {Button, Tab, Nav, OverlayTrigger, Tooltip, Spinner} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../scss/pages/_style.css';
import CompanyPhasesChecker from '../../utils/CompanyPhasesChecker.js';
import { Link } from "react-router-dom";
import BounceLoader from 'react-spinners/BounceLoader';
import Swal from 'sweetalert2';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KImage from '../../components/KImage';
import KTableSmartSelect from '../../components/KTableSmartSelect';
import KSendManagerEmail from '../../components/KSendManagerEmail';
import KProfileSidebar from '../../components/KProfileSidebar';
import KAssignUserToProgramModal from '../../components/KAssignUserToProgramModal';
import KAssignUserToCompanyModal from '../../components/KAssignUserToCompanyModal';
import KStatsProgram from '../../components/KStatsProgram';
import KSendCertificatesModal from '../../components/KSendCertificatesModal';
import KAssignCompanyToProgramModal from '../../components/KAssignCompanyToProgramModal';
import KCreateAndEditProgram from '../../components/KCreateAndEditProgram';
import KProgramServicesComponent from '../../components/KProgramServicesComponent';
import KProgramMailToComponent from '../../components/KProgramMailToComponent';



var isFilled = function(v){
  if(v === null || v === undefined){
    return false;
  }else{
    if( v.toString().trim() === ""){
      return false;
    }else{
      return true;
    }
  }
}

const TableCaption = () => <h5 className="display-table-caption" style={{ textAlign: 'center', color: '#398bf7', padding: '0.5em' }}> Desliza a la derecha para ver más<i className="ml-1 mdi mdi-chevron-right"/> </h5>;
class ProgramsView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      users:[],
      users_without_company_ids:[],
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      partnersPage: 1,
      projectsPage: 1,
      mentorsPage: 1,
      program:{
        _id:'',
        name:'',
        start_date:'',
        end_date:'',
        iframe:'',
        schedule:'',
        partnership:'',
        message:'',
        companies_count:'',
        registered_companies:[],
        companies_mentored:[],
        mentors_participated:[],
        partners_signed:[]
      },
      resources:[],
      shareMessage: 'Generar link de invitación',
      companies:[],
      exportUrl:'',
      exportCompaniesUrl:`${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=companies&export=xlsx`,
      exportMentorsUrl:`${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=mentors&export=xlsx`,
      exportPartnersUrl:`${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=partners&export=xlsx`,
      exportUsersWithoutCompanyUrl:`${KomunidadApi.API_URL}/users?export=xlsx`,
      mentors:[],
      selectedCompanies:[],
      selectedMentors:[],
      selectedPartners:[],
      selectedUsers:[],
      selectedResources:[],
      selectedUsersWithoutCompany:[],
      cbStateCompanies:'none',
      cbStateMentors:'none',
      cbStatePartners:'none',
      cbStateUsers:'none',
      cbStateResources:'none',
      emailModal: false,
      usersList:[],//the users list or company list is for the modal send email.
      type:'user', //option user,company,program
      isSidebarProfileOpen:false,
      userSelectedId:'',
      userProfileActiveKey:'profile',
      assignModal:false,
      assignUserModal:false,
      isLoading:true,
      keyTab:'brief',
      step: 'instructions',
      certificatesModal:false,
      assignCompanyModal:false,
      isEditing:false,
      isEditingKSPC:false
    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.getProgram                      = this.getProgram.bind(this);
    this.getResources                    = this.getResources.bind(this);
    this.getCompanies                    = this.getCompanies.bind(this);
    this.openEmailModal                  = this.openEmailModal.bind(this);
    this.handleOnSelectCompanies         = this.handleOnSelectCompanies.bind(this);
    this.onOptionSelectedCompanies       = this.onOptionSelectedCompanies.bind(this);
    this.onCbClickCompanies              = this.onCbClickCompanies.bind(this);
    this.handleOnSelectMentors           = this.handleOnSelectMentors.bind(this);
    this.onOptionSelectedMentors         = this.onOptionSelectedMentors.bind(this);
    this.onCbClickMentors                = this.onCbClickMentors.bind(this);
    this.handleOnSelectPartners          = this.handleOnSelectPartners.bind(this);
    this.onOptionSelectedPartners        = this.onOptionSelectedPartners.bind(this);
    this.onCbClickPartners               = this.onCbClickPartners.bind(this);
    this.handleOnSelectUsersWithoutCompany             = this.handleOnSelectUsersWithoutCompany.bind(this);
    this.onOptionSelectedUsersWithoutCompany           = this.onOptionSelectedUsersWithoutCompany.bind(this);
    this.onCbClickUsers                  = this.onCbClickUsers.bind(this);
    this.handleOnSelectResources         = this.handleOnSelectResources.bind(this);
    this.onOptionSelectedResources       = this.onOptionSelectedResources.bind(this);
    this.onCbClickResources              = this.onCbClickResources.bind(this);
    this.onClickDeleteResources          = this.onClickDeleteResources.bind(this);
    this.deleteConfirmationSwal          = this.deleteConfirmationSwal.bind(this);
    this.onClickSendEmailToPartners      = this.onClickSendEmailToPartners.bind(this);
    this.onClickSendEmailToMentors       = this.onClickSendEmailToMentors.bind(this);
    this.onClickSendEmailToCompanies     = this.onClickSendEmailToCompanies.bind(this);
    this.onClickSendEmailToAll           = this.onClickSendEmailToAll.bind(this);
    this.isAuthorized                    = this.isAuthorized.bind(this);
    this.handleSidebarProfile            = this.handleSidebarProfile.bind(this);
    this.handleAssignModal               = this.handleAssignModal.bind(this);
    this.handleAssignUserModal           = this.handleAssignUserModal.bind(this);
    this.onClickWhatsapp                 = this.onClickWhatsapp.bind(this);
    this.onClickAddResource              = this.onClickAddResource.bind(this);
    this.renderPartnersActionButtons     = this.renderPartnersActionButtons.bind(this);
    this.handleCertificatesModal         = this.handleCertificatesModal.bind(this);
    this.onClickExportReviews            = this.onClickExportReviews.bind(this);
    this.handleAssignCompanyModal        = this.handleAssignCompanyModal.bind(this);
    this.handleIsEditing                 = this.handleIsEditing.bind(this);
    this.renderShareMessage              = this.renderShareMessage.bind(this);

    this.smartSelectOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
    ];
  }
  isAuthorized(user){
    let program_id = this.props.match.params.id;
    let facilitator_programs_permissions = user.facilitator_programs_permissions || [];
    let programs = facilitator_programs_permissions.map(p => p.program.toString());
    if(user.roles.includes("manager")){
      return true;
    }
    else if(user.roles.includes("facilitator") && programs.includes(program_id)){
      return true;
    }else{
      return false;
    }
  }
  componentDidMount(){
    let program_id = this.props.match.params.id;

    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
            });
          }else{
            this.redirectToProfile();
          }

          this.getProgram(program_id);
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }
  redirectToProfile(){
    this.props.history.push('/profile');
  }
  getProgram(_id,showLoader = true){
    if(showLoader){
      //set the Loader
      this.setState({isLoading:true});
    }
    KomunidadApi.getProgram(_id).then((res) => {
      if(res.success){
        this.setState({
          program:res.programs,
          companies: this.formatCompaniesPhase(res.programs.registered_companies),
          mentors:res.programs.mentors,
          users:res.programs.users_without_company,
          users_without_company_ids:res.programs.users_without_company.map(u => u._id),
          isLoading:false
        });
      }else{
      }
      this.getResources(_id);
    });
  }

  formatCompaniesPhase(companies) {
    let formatedCompanies = companies.map(company => {
      let phaseNumber = Object.values(company.phases).filter((v) => v).length;
      company.phases.phaseNumber = phaseNumber;
      return {
        ...company
      };
    })
    return formatedCompanies;
  }

  getCompanies(_id){
    KomunidadApi.getProgram(_id).then((res) => {
      if(res.success){
        this.setState({
          companies: this.formatCompaniesPhase(res.programs.registered_companies),
        });
      }else{}
    });
  }

  getResources(_id){
    KomunidadApi.getResourcesByProgram(_id).then((res) => {
      if(res.success){
        this.setState({
          resources:res.resources
        });
      }else{}
    });
  }

  getUsersWihtoutCompany(_id){
    KomunidadApi.getProgram(_id).then((res) => {
      if(res.success){
        this.setState({
          users: res.programs.users_without_company,
        });
      }else{}
    });
  }

  openEmailModal(value) {
    this.setState({emailModal: value});
  }

  handleSidebarProfile(show){
    this.setState({isSidebarProfileOpen:show});
  }

  handleAssignModal(show){
    this.setState({assignModal:show});
  }
  
  handleAssignUserModal(show,company){
    this.setState({
      assignUserModal:show,
      selectedAssignCompany:company
    });
  }

  handleCertificatesModal(show){
    this.setState({certificatesModal:show});
  }

  handleAssignCompanyModal(show){
    this.setState({assignCompanyModal:show});
  }

  onOptionSelectedCompanies(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.companies;
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cbStateCompanies:option.actionType,
      selectedCompanies:selectedRows
    },() =>{
      ////this.convertUsersToIds();
    });
  }

  onCbClickCompanies(state){
    if(state === "all"){
      this.onOptionSelectedCompanies(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelectedCompanies(this.smartSelectOptions[1]);
    }
  }

  onOptionSelectedMentors(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      let mentors = this.state.mentors;
      selectedRows = mentors.filter(mentor => mentor !== null);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cbStateMentors:option.actionType,
      selectedMentors:selectedRows
    },() =>{
      ////this.convertUsersToIds();
    });
  }

  onCbClickMentors(state){
    if(state === "all"){
      this.onOptionSelectedMentors(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelectedMentors(this.smartSelectOptions[1]);
    }
  }

  onOptionSelectedPartners(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      let partners = this.state.program.partners_signed;
      selectedRows = partners.filter(partner => partner !== null);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cbStatePartners:option.actionType,
      selectedPartners:selectedRows
    },() =>{
      //this.convertUsersToIds();
    });
  }

  onOptionSelectedUsersWithoutCompany(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      let users = this.state.users;
      selectedRows = users.filter(user => user !== null);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cbStateUsers:option.actionType,
      selectedUsersWithoutCompany:selectedRows
    },() =>{
      //this.convertUsersToIds();
    });
  }

  onCbClickUsers(state){
    if(state === "all"){
      this.onOptionSelectedUsersWithoutCompany(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelectedUsersWithoutCompany(this.smartSelectOptions[1]);
    }
  }

  onOptionSelectedResources(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      let resources = this.state.program.resources;
      selectedRows = resources.filter(resources => resources !== null);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cbStateResources:option.actionType,
      selectedResources:selectedRows
    },() =>{
      //this.convertUsersToIds();
    });
  }

  onCbClickResources(state){
    if(state === "all"){
      this.onOptionSelectedResources(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelectedResources(this.smartSelectOptions[1]);
    }
  }

  onCbClickPartners(state){
    if(state === "all"){
      this.onOptionSelectedPartners(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelectedPartners(this.smartSelectOptions[1]);
    }
  }

  convertUsersToIds(){
    let url = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedUsers.map(c => c._id).join("|");
    if(this.state.selectedUsers.length === this.state.users.length){
        //do nothing
    }else{
      url = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrl:url
    })
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  formatDate(date){
    if(date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }
  renderPrivacity(){
    if(this.state.program.hasOwnProperty('is_public')){
      return this.state.program.is_public ? 'Público' : 'Privado';
    }else{
      return 'Público';
    }
  }

  // renderServicesEnabledString(){
  //   let accepts_service_requests = true;
  //   if(this.state.program.hasOwnProperty("accepts_service_requests")){
  //     accepts_service_requests = this.state.program.accepts_service_requests;
  //   }
  //   return accepts_service_requests ? "Habilitado" : "Deshabilitado";
  // }

  renderProgramTypeString(){
    let is_free = false;
    if(this.state.program.hasOwnProperty("is_free")){
      is_free = this.state.program.is_free;
    }
    return is_free ? "Básico" : "Premium";
  }

  generateLink(program_id) {
    navigator.clipboard.writeText('https://' + window.location.host + `/join_program?programid=${program_id}`)

    this.renderShareMessage('Link Copiado')

  }

  renderShareMessage(message) {
    this.setState({
      shareMessage: message
    }, () => {
      setTimeout( () => {
        this.setState({
          shareMessage: 'Generar link de invitación'
        });
      }, 2000);
    });
  }

  renderBrief(){
    let program_id = this.props.match.params.id;
    const start_date = this.formatDate(this.state.program.start_date);
    const end_date = this.formatDate(this.state.program.end_date);

    return(
        <div style={{background:'#f4f6f9'}}>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body row">
                  <div className="col-md m-0 ">
                    <div className="row m-1 my-1 d-block">
                      <i className="mdi mdi-calendar-check mr-2" style={{color: '#06d79c', fontSize: '20px'}}></i>
                      <b>Fecha de inicio:</b> &nbsp;{start_date}
                    </div>
                    <div className="row m-1 my-2 d-block">
                      <i className="mdi mdi-calendar-remove mr-2" style={{color: '#ef5350', fontSize: '20px'}}></i>
                      <b>Fecha de finalización:</b> &nbsp;{end_date}
                    </div>
                    <div className="row m-1 my-2 d-block">
                      <i className="mdi mdi-account-key mr-2" style={{color: '#ffb22b', fontSize: '20px'}}></i>
                      <b>Privacidad:</b> &nbsp;{this.renderPrivacity()}
                    </div>
                    <div className="row m-1 my-2 d-block">
                      <i className="mdi mdi-account-star mr-2" style={{color: '#745af2', fontSize: '20px'}}></i>
                      <b>Tipo:</b> &nbsp; {this.renderProgramTypeString()}
                    </div>
                  </div>
                  <div className="col-md m-0  flex space-between flex-column">
                    <div>
                    <OverlayTrigger key={'tooltip-envelope'}
                                    placement={'top'}
                                    overlay={
                                                <Tooltip id={'tooltip-envelope'}>
                                                  Contactar a todos los participantes del programa.
                                                </Tooltip>
                                              }>
                      <Button className="btn btnMarginClean waves-effect waves-light btn-info pull-right float-right"
                              onClick={() => this.onClickSendEmailToAll()}>
                              <i className="mdi mdi-email" style={{fontSize: '18px'}}></i>
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger key={'tooltip-whats'}
                                    placement={'top'}
                                    overlay={
                                                <Tooltip id={'tooltip-whats'}>
                                                  Invitar a todos a grupo de Whatsapp.
                                                </Tooltip>
                                              }>
                      <Button className={"btn btnMarginClean waves-effect waves-light pull-right float-right "}
                              variant={"success"}
                              onClick={() => this.onClickWhatsapp('all')}
                              disabled={false}>
                              <i className="mdi mdi-whatsapp" style={{fontSize: '18px'}}></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger key={'tooltip-cert'}
                                    placement={'top'}
                                    overlay={
                                                <Tooltip id={'tooltip-cert'}>
                                                  Emitir Certificado
                                                </Tooltip>
                                              }>
                      <Button className={"btn btnMarginClean btn-save waves-effect waves-light pull-right float-right "}

                              onClick={() => this.handleCertificatesModal(true)}>
                              <i className="mdi mdi-certificate" style={{fontSize: '18px'}}></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger key={'tooltip-share'}
                                    placement={'top'}
                                    overlay={
                                                <Tooltip id={'tooltip-share'}>
                                                  {this.state.shareMessage}
                                                </Tooltip>
                                              }>
                      <Button className={"btn btnMarginClean waves-effect waves-light pull-right float-right"}
                              variant={"link"}
                              onClick={()  => this.generateLink(program_id)}>
                              <i className="mdi mdi-share-variant" style={{fontSize: '18px'}}></i>
                      </Button>
                    </OverlayTrigger>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderStats()}
        </div>
    )
  }
  onClickExportReviews(){
    let link = `${KomunidadApi.API_URL}/programs/${this.state.program._id}/reviews_report`;
    window.open(link,"_blank");
  }
  onClickSendEmailToAll(){
    this.setState({usersList:this.state.program.partners_signed.concat(this.state.program.mentors),type:'program'}, () => {
      this.openEmailModal(true);
    });
  }
  onClickWhatsapp(filter){
    let partners_ids = [];
    let mentors_ids = [];
    let users_without_company = [];
    if(filter === "all"){
      partners_ids = this.state.program.partners.filter(u => u !== null).map( u => u._id);
      mentors_ids = this.state.program.mentors.filter(u => u !== null).map( u => u._id);
      users_without_company = this.state.users.filter(u => u !== null).map( u => u._id);
    }else if(filter === "mentors"){
      mentors_ids = this.state.program.mentors.filter(u => u !== null).map( u => u._id);
    }else if(filter === "partners"){
      partners_ids = this.state.program.partners.filter(u => u !== null).map( u => u._id);
      users_without_company = this.state.users.filter(u => u !== null).map( u => u._id);
    }
    let users_ids = partners_ids.concat(mentors_ids).concat(users_without_company);
    if(users_ids.length > 0){
      this.props.history.push({
               pathname:"/invite_whatsapp_group",
               state:{
                   users_ids:users_ids,
                   program_id:this.state.program._id
                }
              });
    }

  }
  onClickAddResource(){
    this.props.history.push({
             pathname:"/create_resource",
             state:{
                program_id:this.state.program._id
              }
            });
  }
  renderStats(){
    return(
      <KStatsProgram program={this.state.program}></KStatsProgram>
    )
  }
  generateCompaniesExportLink(){
    let exportCompaniesUrl = `${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=companies&export=xlsx`;
    let ids = this.state.selectedCompanies.map(c => c._id).join("|");
    if(this.state.selectedCompanies.length === this.state.companies.length){
        //do nothing
    }else{
      exportCompaniesUrl = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportCompaniesUrl:exportCompaniesUrl
    });
  }
  generateMentorsExportLink(){
    let exportMentorsUrl = `${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=mentors&export=xlsx`;
    let ids = this.state.selectedMentors.map(c => c._id).join("|");
    if(this.state.selectedMentors.length === this.state.mentors.length){
        //do nothing
    }else{
      exportMentorsUrl = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportMentorsUrl:exportMentorsUrl
    });
  }
  generatePartnersExportLink(){
    let exportPartnersUrl = `${KomunidadApi.API_URL}/programs/${this.props.match.params.id}/?expType=partners&export=xlsx`;
    let ids = this.state.selectedPartners.map(c => c._id).join("|");
    if(this.state.selectedPartners.length === this.state.program.partners_signed.length){
        //do nothing
    }else{
      exportPartnersUrl = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportPartnersUrl:exportPartnersUrl
    });
  }
  generateUsersWihtoutCompanyExportLink(){
    //check the url
    let exportUsersWithoutCompanyUrl = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedUsersWithoutCompany.map(c => c._id).join("|");
    exportUsersWithoutCompanyUrl = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    this.setState({
      exportUsersWithoutCompanyUrl:exportUsersWithoutCompanyUrl
    });
  }
  handleOnSelectCompanies(row, isSelect){
    if(row === null){
      return false
    }
    if (isSelect) {
      this.setState(state => {
        const selectedCompanies = [...state.selectedCompanies, row];

        return {
          selectedCompanies,
        };
      }, () => {
        this.generateCompaniesExportLink();
        if(this.state.selectedCompanies.length === this.state.companies.length){
          this.setState({ cbStateCompanies: "all" });
          this.kTableSmartSelectCompanies.changeCbState("all");
        }else{
          this.setState({ cbStateCompanies: "mixed" });
          this.kTableSmartSelectCompanies.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedCompanies: this.state.selectedCompanies.filter(x => x !== row),
      }), () => {
        this.generateCompaniesExportLink();
        if(this.state.selectedCompanies.length === 0){
          this.setState({ cbStateCompanies: "none" });
          this.kTableSmartSelectCompanies.changeCbState("none");
        }else{
          this.setState({ cbStateCompanies: "mixed" });
          this.kTableSmartSelectCompanies.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectMentors(row, isSelect){
    if(row === null){
      return false
    }
    if (isSelect) {
      this.setState(state => {
        const selectedMentors = [...state.selectedMentors, row];

        return {
          selectedMentors,
        };
      }, () => {
        this.generateMentorsExportLink();
        if(this.state.selectedMentors.length === this.state.mentors.length){
          this.setState({ cbStateMentors: "all" });
          this.kTableSmartSelectMentors.changeCbState("all");
        }else{
          this.setState({ cbStateMentors: "mixed" });
          this.kTableSmartSelectMentors.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedMentors: this.state.selectedMentors.filter(x => x !== row),
      }), () => {
        this.generateMentorsExportLink();
        if(this.state.selectedMentors.length === 0){
          this.setState({ cbStateMentors: "none" });
          this.kTableSmartSelectMentors.changeCbState("none");
        }else{
          this.setState({ cbStateMentors: "mixed" });
          this.kTableSmartSelectMentors.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectPartners(row, isSelect){
    if(row === null){
      return false;
    }
    if (isSelect) {
      this.setState(state => {
        const selectedPartners = [...state.selectedPartners, row];

        return {
          selectedPartners,
        };
      }, () => {
        this.generatePartnersExportLink();
        if(this.state.selectedPartners.length === this.state.program.partners_signed.length){
          this.setState({ cbStatePartners: "all" });
          this.kTableSmartSelectPartners.changeCbState("all");
        }else{
          this.setState({ cbStatePartners: "mixed" });
          this.kTableSmartSelectPartners.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedPartners: this.state.selectedPartners.filter(x => x !== row),
      }), () => {
        this.generatePartnersExportLink();
        if(this.state.selectedPartners.length === 0){
          this.setState({ cbStatePartners: "none" });
          this.kTableSmartSelectPartners.changeCbState("none");
        }else{
          this.setState({ cbStatePartners: "mixed" });
          this.kTableSmartSelectPartners.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectUsersWithoutCompany(row, isSelect){
    if(row === null){
      return false
    }
    if (isSelect) {
      this.setState(state => {
        const selectedUsersWithoutCompany = [...state.selectedUsersWithoutCompany, row];

        return {
          selectedUsersWithoutCompany,
        };
      }, () => {
        this.generateUsersWihtoutCompanyExportLink();
        if(this.state.selectedUsersWithoutCompany.length === this.state.users.length){
          this.setState({ cbStateUsers: "all" });
          this.kTableSmartSelectUsers.changeCbState("all");
        }else{
          this.setState({ cbStateUsers: "mixed" });
          this.kTableSmartSelectUsers.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedUsersWithoutCompany: this.state.selectedUsersWithoutCompany.filter(x => x !== row),
      }), () => {
        this.generateUsersWihtoutCompanyExportLink();
        if(this.state.selectedUsersWithoutCompany.length === 0){
          this.setState({ cbStateUsers: "none" });
          this.kTableSmartSelectUsers.changeCbState("none");
        }else{
          this.setState({ cbStateUsers: "mixed" });
          this.kTableSmartSelectUsers.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectResources(row, isSelect){
    if(row === null){
      return false
    }
    if (isSelect) {
      this.setState(state => {
        const selectedResources = [...state.selectedResources, row];

        return {
          selectedResources,
        };
      }, () => {
        this.generatePartnersExportLink();
        if(this.state.selectedResources.length === this.state.users.length){
          this.setState({ cbStateResources: "all" });
          this.kTableSmartSelectResources.changeCbState("all");
        }else{
          this.setState({ cbStateResources: "mixed" });
          this.kTableSmartSelectResources.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedResources: this.state.selectedResources.filter(x => x !== row),
      }), () => {
        this.generatePartnersExportLink();
        if(this.state.selectedResources.length === 0){
          this.setState({ cbStateResources: "none" });
          this.kTableSmartSelectResources.changeCbState("none");
        }else{
          this.setState({ cbStateResources: "mixed" });
          this.kTableSmartSelectResources.changeCbState("mixed");
        }
      });
    }
  }
  downloadAsExcel = async(selection) =>{
    if(!this.state.isDownloading){
      const _ids = selection.map((p) => p._id);
      this.setState({isDownloading:true});
      KomunidadApi.downloadFile(_ids).then((res) =>{
        this.setState({isDownloading:false});
      }).catch((ex) =>{
        this.setState({isDownloading:false});
      })
    }
  }
  renderActionButtonsCompanies(){
    if(this.state.selectedCompanies.length === 0){
      return null;
    }else{
      return(
        <div className='m-r-20'>
          <button className="btn btnMarginClean waves-effect waves-light btn-danger pull-right float-right"
                  onClick={() => this.onClickRemoveCompanies()}>Remover del programa</button>
          <a className='m-r-10' href={this.state.exportCompaniesUrl} rel="noopener noreferrer" target={'_blank'}>
            <div className='btn btn-info btn-save'>Exportar a Excel</div>
          </a>
          <button className="btn btnMarginClean waves-effect waves-light btn-info pull-right float-right"
                  onClick={() => this.onClickSendEmailToCompanies()}>Enviar correo</button>
        </div>
      )
    }
  }

  renderAssignCompanyBtn(){
    if(this.state.selectedCompanies.length === 0){
        return(
          <div>
            <OverlayTrigger key={'tooltip-add-program'}
                            placement={'top'}
                            overlay={
                                        <Tooltip id={'tooltip-add-program'}>
                                          Agregar empresa al programa
                                        </Tooltip>
                                      }>
              <Button className={"btn btn-success btn-save waves-effect waves-light"}
                      onClick={() => this.handleAssignCompanyModal(true)}>
                      <i className="mdi mdi-plus" style={{fontSize: '18px'}}></i>
              </Button>
            </OverlayTrigger>
          </div>
        )
    }else{
      return null;
    }
  }

  onClickRemoveCompanies(){
    // if users are selected proceed to delete them
    if(this.state.selectedCompanies.length > 0){
      this.removeConfirmationSwal();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona las empresas que deseas remover',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  /*THIS SECTION WILL BE UNCOMMENT*/

    onClickRemovePartners() {
      let selectedUsers = this.state.selectedPartners;
      // if users are selected proceed to delete them
      if(selectedUsers.length > 0){
        this.removeUsersConfirmationSwal(selectedUsers, 'emprendedor');
      }else{
        //send error msg
        Swal.fire({
          type: 'error',
          title: 'Selecciona los emprendedores que deseas remover',
        });
      }
    }

  onClickRemoveMentors() {
      let selectedUsers = this.state.selectedMentors;
      // if users are selected proceed to delete them
      if(selectedUsers.length > 0){
        this.removeUsersConfirmationSwal(selectedUsers, 'mentor');
      }else{
        //send error msg
        Swal.fire({
          type: 'error',
          title: 'Selecciona los aliados que deseas remover',
        });
      }
    }

  onClickRemoveUsersNoCompany() {
    let selectedUsers = this.state.selectedUsersWithoutCompany;
    // if users are selected proceed to delete them
    if(selectedUsers.length > 0){
      this.removeUsersConfirmationSwal(selectedUsers, 'usuario');
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los emprendedores que deseas remover',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  removeConfirmationSwal(){
    let selectedCompanies = this.state.selectedCompanies;
    let htmlTxt = '';

    if(selectedCompanies.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Borrar la empresa <b>${selectedCompanies[0].name}</b> del programa<div>`;
    }else{
      htmlTxt = `Borrar las siguientes empresas del programa: `;
      for (var i = 0; i < selectedCompanies.length; i++) {
        let template = `<div><b>${selectedCompanies[i].name}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Remover',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.removeCompanies(password);
      }
    })()

  }

  removeUsersConfirmationSwal(selectedUsers, type) {
    let htmlTxt = '';
    let passedUsers = [...selectedUsers];

    if(selectedUsers.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Borrar el ${type} <b>${selectedUsers[0].name}</b> del programa<div>`;
    }else{
      if(type === 'usuario') {
        htmlTxt = `Borrar los siguientes ${type}s del programa: `;
      } else {
        htmlTxt = `Borrar los siguientes ${type}es del programa: `;
      }

      for (var i = 0; i < selectedUsers.length; i++) {
        let mentors_participated = this.state.program.mentors_participated;
        for(var j = 0; j < mentors_participated.length; j++) {
          if(selectedUsers[i]._id === mentors_participated[j].user._id) {
            const index = passedUsers.indexOf(selectedUsers[i]);
            passedUsers.splice(index, 1);
          }
        }
      }
      for(var i = 0; i < passedUsers.length; i++) {
        let template = `<div><b>${passedUsers[i].name}</b></div>`;
        htmlTxt += template;
      }
      if(type === 'mentor'){
        htmlTxt += `<div><b style='text-transform:uppercase;'>Importante</b>: Solo podrán ser eliminados los aliados si no han participado en el programa</div>`
      }
    }

    if(passedUsers.length === 0) {
      Swal.fire({
        type: 'warning',
        title: 'Importante',
        text: 'Solo podrán ser eliminados los aliados si no han participado en el programa',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      })
    } else {
      (async () => {
        const { value: password } = await Swal.fire({
            title: '¿Estás seguro(a)?',
            html: htmlTxt,
            type: 'warning',
            input:'password',
            inputPlaceholder:'Ingresa tu contraseña',
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value.trim() === '') {
                  resolve('La contraseña no puede ser vacía');
                } else {
                  resolve();
                }
              })
            },
            showCancelButton: true,
            confirmButtonText: 'Remover',
            cancelButtonText: 'No',
            customClass: {
              content: 'swal-content-class',
              confirmButton: 'swalBtnRedOrange',
              cancelButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
        })

        if (password) {
          this.removeUsers(passedUsers, password, type)
        }
      })()
    }
  }

  removeCompanies(password){
    let selectedCompanies = this.state.selectedCompanies;
    let program_id = this.props.match.params.id;
    if(selectedCompanies.length > 0){
      let companies_id = selectedCompanies.map(r => r._id).join('|');
      KomunidadApi.removeCompanies(program_id,companies_id,password).then((res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Empresa(s) removidos con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.getCompanies(this.state.program._id);
            this.setState({
              selectedCompanies:[]
            });
          })
        }else{
          Swal.fire({
            type: 'error',
            title: res.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }

  removeUsers(selectedUsers, password, type) {
    let program_id = this.props.match.params.id;
    if(selectedUsers.length > 0){
      let users_ids = selectedUsers.map(r => r._id).join('|');
      if(type === "usuario" || type === "emprendedor") {
        KomunidadApi.removeUsersNoCompany(program_id,users_ids,password).then((res) => {
          if(res.success){
            Swal.fire({
              type: 'success',
              title: 'Emprendedor(s) removidos con éxito',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            }).then((result) => {
              KomunidadApi.getProgram(this.state.program._id).then((res) => {
                if(res.success){
                  this.setState({
                    program:res.programs,
                    mentors:res.programs.mentors,
                  });
                }
              });
              this.setState({
                selectedUsersWithoutCompany:[],
                selectedUsers:[]
              });
            })
          }else{
            Swal.fire({
              type: 'error',
              title: res.error_es,
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }
        });
      } else if(type === "mentor") {
        KomunidadApi.removeMentorsFromProgram(program_id,users_ids,password).then((res) => {
          if(res.success){
            Swal.fire({
              type: 'success',
              title: 'Aliado(s) removidos con éxito',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            }).then((result) => {
              KomunidadApi.getProgram(this.state.program._id).then((res) => {
                if(res.success){
                  this.setState({
                    program:res.programs,
                    mentors:res.programs.mentors,
                  });
                }
              });
              this.setState({
                selectedMentors:[]
              });
            })
          }else{
            Swal.fire({
              type: 'error',
              title: res.error_es,
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }
        });
      }
    }
  }

  onClickSendEmailToCompanies(){
    this.setState({usersList:this.state.selectedCompanies,type:'company'}, () =>{
      this.openEmailModal(true);
    });
  }

  renderActionButtonsMentors(){
    if(this.state.selectedMentors.length === 0){
      return null;
    }else{
      return(
        <div className='m-r-20'>
          <button className="btn waves-effect waves-light btn-danger pull-right float-right"
                  onClick={() => this.onClickRemoveMentors()}>Remover del programa</button>
          <a className="m-r-10" onClick={() => this.downloadAsExcel(this.state.selectedMentors)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'} size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
          <button className="btn waves-effect waves-light btn-info pull-right float-right m-r-10"
                  onClick={() => this.onClickSendEmailToMentors()}>Enviar correo</button>
        </div>
      )
    }
  }

  renderAssignModalBtn(){
    if(this.state.selectedMentors.length === 0){
        return(
          <div style={{display:"flex",alignItems:"flex-start",flexDirection:"row-reverse"}}>
            <OverlayTrigger key={'tooltip-reviews'}
                                    placement={'top'}
                                    overlay={
                                                <Tooltip id={'tooltip-reviews'}>
                                                  Exportar retroalimentación a Excel
                                                </Tooltip>
                                              }>
                      <Button className={"btn btn-warning btnMarginClean waves-effect waves-light pull-right float-right "}
                              variant={"success"}
                              onClick={() => this.onClickExportReviews()}
                              disabled={false}>
                              <i className="mdi mdi-file-excel" style={{fontSize: '18px'}}></i>
                      </Button>
                    </OverlayTrigger>
            <OverlayTrigger key={'tooltip-assign-mentor'}
                            placement={'top'}
                            overlay={
                                        <Tooltip id={'tooltip-assign-mentor'}>
                                          Agregar aliados al programa
                                        </Tooltip>
                                      }>
              <div className="m-r-10">
                <button className="btn btn-success btn-save waves-effect waves-light"
                        onClick={() => this.handleAssignModal(true)}>
                        <i className="mdi mdi-plus" style={{fontSize: '18px'}}></i>
                </button>
              </div>
            </OverlayTrigger>
            <OverlayTrigger key={'tooltip-w-mentor'}
                            placement={'top'}
                            overlay={
                                        <Tooltip id={'tooltip-w-mentor'}>
                                          Crear grupo general de programa en Whatsapp
                                        </Tooltip>
                                      }>
              <div className="m-r-10">
                <Button className={"btn waves-effect waves-light pull-right float-right"}
                        variant={"success"}
                        onClick={() => this.onClickWhatsapp('mentors')}
                        disabled={false}>
                        <i className="mdi mdi-whatsapp" style={{fontSize: '18px'}}></i>
                </Button>
              </div>
            </OverlayTrigger>
          </div>
        )
    }
  }

  renderCreateResourceBtn(){
    if(this.state.selectedResources.length === 0){
      return(
        <div>
          <OverlayTrigger key={'tooltip-create_resource'}
                          placement={'top'}
                          overlay={
                                      <Tooltip id={'tooltip-create_resource'}>
                                        Crear recurso
                                      </Tooltip>
                                    }>
              <Button className={"btn btn-info btn-save waves-effect waves-light pull-right float-right"}
                      variant={"primary"}
                      onClick={() => this.onClickAddResource()}
                      disabled={false}>
                      <i className="mdi mdi-plus" style={{fontSize: '18px'}}></i>
              </Button>
          </OverlayTrigger>

        </div>
      )
    }
  }

  renderPartnersActionButtons(){
    if(this.state.selectedPartners.length === 0){
        return(
          <div>
            <OverlayTrigger key={'tooltip-w-partners'}
                            placement={'top'}
                            overlay={
                                        <Tooltip id={'tooltip-w-partners'}>
                                          Invitar emprendedores a grupo de Whatsapp
                                        </Tooltip>
                                      }>
              <Button className={"btn waves-effect waves-light pull-right float-right"}
                      variant={"success"}
                      onClick={() => this.onClickWhatsapp('partners')}
                      disabled={false}>
                      <i className="mdi mdi-whatsapp" style={{fontSize: '18px'}}></i>
              </Button>
            </OverlayTrigger>
          </div>
        )
    }
  }
  onClickSendEmailToMentors(){
    this.setState({usersList:this.state.selectedMentors,type:'user'}, () => {
      this.openEmailModal(true);
    });
  }



  renderActionButtonsPartners(){
    if(this.state.selectedPartners.length === 0){
      return (null);
    }else{
      return(
        <div className='m-r-20'>
          {/* <button className="btn waves-effect waves-light btn-danger pull-right float-right mx-1"
                  onClick={() => this.onClickRemovePartners()}>Remover del programa</button> */}
          <a className="m-r-10" onClick={() => this.downloadAsExcel(this.state.selectedPartners)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'} size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
          <button className="btn waves-effect waves-light btn-info pull-right float-right"
                  onClick={() => this.onClickSendEmailToPartners()}>Enviar correo</button>
        </div>
      )
    }
  }

  renderActionButtonsUsersWithoutCompany(){
    if(this.state.selectedUsersWithoutCompany.length === 0){
      return (null);
    }else{
      return(
        <div sName='m-r-20'>
          <button className="btn waves-effect waves-light btn-danger pull-right float-right"
                  onClick={() => this.onClickRemoveUsersNoCompany()}>Remover del programa</button>
          <a className="m-r-10" onClick={() => this.downloadAsExcel(this.state.exportUsersWithoutCompanyUrl)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'} size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
          <button className="btn waves-effect waves-light btn-info pull-right float-right m-r-10"
                  onClick={() => this.onClickSendEmailToUsers()}>Enviar correo</button>
        </div>
      )
    }
  }
  renderActionButtonsResources(){
    if(this.state.selectedResources.length === 0){
      return (null);
    }else{
      return(
        <div>
          <Button className={"btn btn-danger"}
                  onClick={this.onClickDeleteResources}
                  disabled={this.state.isDeleteBtnDisibled}>
                  Borrar recursos
          </Button>
        </div>
      )
    }
  }

  onClickDeleteResources(){
    // if users are selected proceed to delete them
    if(this.state.selectedResources.length > 0){
      this.deleteConfirmationSwal();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los recursos que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  deleteConfirmationSwal(){
    let selectedResources = this.state.selectedResources;
    let htmlTxt = '';

    if(selectedResources.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Borrar el recurso <b>${selectedResources[0].name}</b> del programa<div>`;
    }else{
      htmlTxt = `Borrar los siguientes recursos del programa: `;
      for (var i = 0; i < selectedResources.length; i++) {
        let template = `<div><b>${selectedResources[i].name}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Borrar',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.deleteResources(password);
      }
    })()

  }

  deleteResources(password){
    let selectedResources = this.state.selectedResources;
    let program_id = this.props.match.params.id;
    if(selectedResources.length > 0){
      let resources_ids = selectedResources.map(r => r._id).join('|');
      KomunidadApi.deleteResources(program_id,resources_ids,password).then((res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Recurso(s) eliminados con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.setState ({
              resources: res.resources
            })
          })
        }else{
          Swal.fire({
            type: 'error',
            title: res.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }

  onClickSendEmailToPartners(){
    this.setState({usersList:this.state.selectedPartners,type:'user'}, () => {
      this.openEmailModal(true);
    });
  }

  onClickSendEmailToUsers(){
    this.setState({usersList:this.state.selectedUsersWithoutCompany,type:'user'}, () => {
      this.openEmailModal(true);
    });
  }

  renderCompanyList(){
    const companies = this.state.companies;

    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'logoThumbnail',
        text: 'Logo',
        style: {
          verticalAlign:'middle'
        },
        headerStyle: {padding: '16px 12px' },
        formatter: (cellContent,row) => {
          return(
            <Link to={`/companies/${row._id}`}>
              <KImage src={cellContent} type='company' className='companyImage' alt="company_image"/>
            </Link>
          )
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          return(
            <Link to={`/companies/${row._id}`}>{cellContent}</Link>
          )
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: 'Fecha de inscripción',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          let program = row.programs.find((program) => program.program === this.state.program._id);

          return this.formatDate(program.joined_date);
        },
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          let programA = rowA.programs.find((program) => program.program === this.state.program._id);
          let programB = rowB.programs.find((program) => program.program === this.state.program._id);

          if (order === 'desc') {
            return new Date(programB.joined_date) - new Date(programA.joined_date);
          }
          return new Date(programA.joined_date) - new  Date(programB.joined_date); // asc
        }
      },
      {
        dataField: 'phases',
        text: 'Etapa',
        style: {
          verticalAlign:'middle'
        },
        headerStyle: {padding: '16px 12px' },
        formatter:(cellContent) => {
          return CompanyPhasesChecker.getPhaseLabelFromObject(cellContent);
        },
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === 'desc') {
            if (a.phaseNumber === b.phaseNumber){
              return a.phaseNumber - b.phaseNumber;
            }
            return a.phaseNumber - b.phaseNumber;
          }
          if (a.phaseNumber === b.phaseNumber){
            return b.phaseNumber - a.phaseNumber; // asc
          }
          return b.phaseNumber - a.phaseNumber; // asc
        },
      },
      {
        dataField: 'ratings',
        text: 'Calificación',
        style: {
          verticalAlign:'middle'
        },
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === 'asc') {
            if (a.average === b.average){
              return a.ratings_count - b.ratings_count;
            }
            return a.average - b.average;
          }
          if (a.average === b.average){
            return b.ratings_count - a.ratings_count; // desc
          }
          return b.average - a.average; // desc
        },
        formatter: (cellContent) =>{
          if(cellContent !== undefined){
            // console.log(cellContent)
            return(
              <div className="d-flex space-between align-items-center" style={{width:"65px"}}>
                <i className="mdi mdi-star" style={{color: "#ffd700", fontSize: '22px'}}></i>
                <div className="pl-2 pr-3">{parseFloat(cellContent.average).toFixed(1)} </div>
                <div className="px-2">({cellContent.ratings_count})</div>
              </div>
            )
          }else{
            return null
          }
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return (<div className="btn btn-link" onClick={() => this.handleAssignUserModal(true,row)}>Asignar aliados</div>)
        }
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({projectsPage: this.state.projectsPage + 1})
        } else if(page === '<') {
          this.setState({projectsPage: this.state.projectsPage - 1})
        } else {
          this.setState({projectsPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: companies.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.projectsPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectCompanies,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelectCompanies = ref}
                onOptionSelected={(option) => this.onOptionSelectedCompanies(option)}
                onCbClick={this.onCbClickCompanies}
                cbState={this.state.cbStateCompanies}
                options={this.smartSelectOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedCompanies.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };
    return(
        <div className="card-body">
          <div className='m-b-20'
               style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse",justifyContent:'space-between'}}>
            <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectCompanies = ref}
                onOptionSelected={(option) => this.onOptionSelectedCompanies(option)}
                onCbClick={this.onCbClickCompanies}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
              {this.renderActionButtonsCompanies()}
            </div>
            {this.renderAssignCompanyBtn()}
          </div>
          <PaginationProvider
            pagination={ paginationFactory(options) }
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                  <div>
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField='_id'
                    data={ companies }
                    columns={ columns }
                    selectRow={ selectRow }
                    noDataIndication={'No se encontraron empresas para este programa.'}
                    wrapperClasses="table-responsive"
                    classes="react-bootstrap-table"
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
        </div>
    )
  }

  openUserProfile(id,activeKey){
    this.setState({userSelectedId:id,userProfileActiveKey:activeKey},() => this.handleSidebarProfile(true));
  }

  renderMentorsList(){
    const mentors = this.state.mentors;

    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          return(
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
            </div>
          )
        }
      },

      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'social_linkedin',
        text: 'Datos',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row,rowIndex,formatExtraData) =>{
          let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
          let colorProfile   = row.isProfileComplete ? "#707cd2" : 'red';

          let iconLi = "mdi-linkedin-box";
          let colorLi = '#007bff';
          let opacityLi = isFilled(row.social_linkedin) ? 1.0 : 0.0;

          let iconId = "mdi-account-card-details";
          let colorId = '#6c757d';
          let opacityId = isFilled(row.id_scan) ? 1.0 : 0.0;


            return(
              <div className="flex space-between" style={{width:"65px"}}>
                <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
                <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
                <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
              </div>
            )
        }
      },
      {
        dataField: 'mentor_speciality_sector',
        text: 'Especialidad',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) =>{
          if(cellContent !== undefined){
            if(cellContent.length >= 1){
              return(
                <span>{ cellContent.map((speciality) =>
                  <span key={speciality}>{speciality}<br></br></span>
                  )}
                </span>
              )
            }
          }else{
            return null
          }
        }
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({mentorsPage: this.state.mentorsPage + 1})
        } else if(page === '<') {
          this.setState({mentorsPage: this.state.mentorsPage - 1})
        } else {
          this.setState({mentorsPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: mentors.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.mentorsPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectMentors,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelectMentors = ref}
                onOptionSelected={(option) => this.onOptionSelectedMentors(option)}
                onCbClick={this.onCbClickMentors}
                cbState={this.state.cbStateMentors}
                options={this.smartSelectOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedMentors.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
        <div className="card-body">
          <div className='m-b-20'
               style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse",justifyContent:'space-between'}}>
            <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectMentors = ref}
                onOptionSelected={(option) => this.onOptionSelectedMentors(option)}
                onCbClick={this.onCbClickMentors}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
              {this.renderActionButtonsMentors()}
            </div>
            {this.renderAssignModalBtn()}
          </div>

          <PaginationProvider
            pagination={ paginationFactory(options) }
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                  <div>
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField='_id'
                    data={ mentors }
                    columns={ columns }
                    selectRow={ selectRow }
                    noDataIndication={'No se encontraron aliados para este programa.'}
                    wrapperClasses="table-responsive"
                    classes="react-bootstrap-table"
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
            }
          </PaginationProvider>
        </div>
    )
  }

  renderPartnersList(){
    let partners = this.state.program.partners_signed;
    // const partners = this.state.program.partners;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(cellContent !== undefined && cellContent !== null){
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
              </div>
            )
          }else{
            return(
                <div  className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                  <KImage src={cellContent} type='user' alt="user_image"/>
                </div>

            )
          }
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'social_linkedin',
        text: 'Datos',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row,rowIndex,formatExtraData) =>{
          if(row === null){
            return (null);
          }
          let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
          let colorProfile   = row.isProfileComplete ? "#707cd2" : 'red';

          let iconLi      = "mdi-linkedin-box";
          let colorLi     = '#007bff';
          let opacityLi   = isFilled(row.social_linkedin) ? 1.0 : 0.0;

          let iconId      = "mdi-account-card-details";
          let colorId     = '#6c757d';
          let opacityId   = isFilled(row.id_scan) ? 1.0 : 0.0;


            return(
              <div className="flex space-between" style={{width:"65px"}}>
                <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
                <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
                <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
              </div>
            )
        }
      },
      {
        dataField: 'dummy',
        text: 'Con empresa',
        isDummyField: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row,rowIndex,formatExtraData) =>{
          if(row === null){
            return (null);
          }
          let iconProfile   = !this.state.users_without_company_ids.includes(row._id) ? 'mdi-check' : 'mdi-close';
          let colorProfile  = !this.state.users_without_company_ids.includes(row._id) ? "#707cd2" : 'red';

            return(
              <div className="flex space-between" style={{width:"65px"}}>
                <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
              </div>
            )
        }
      },
      {
        dataField: '_id',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter:(cell,row) =>{
          if(row !== null){
            return (<div className="btn btn-link" onClick={() => this.openUserProfile(cell,'companies')}>Ver empresas</div>)
          }else{
            return null;
          }
        },
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({partnersPage: this.state.partnersPage + 1})
        } else if(page === '<') {
          this.setState({partnersPage: this.state.partnersPage - 1})
        } else {
          this.setState({partnersPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: partners?.length || 0,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.partnersPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectPartners,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelectPartners = ref}
                onOptionSelected={(option) => this.onOptionSelectedPartners(option)}
                onCbClick={this.onCbClickPartners}
                cbState={this.state.cbStatePartners}
                options={this.smartSelectOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedPartners.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
      <div className="card-body">
        <div className='m-b-20'
             style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse",justifyContent:'space-between'}}>
            <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectPartners = ref}
                onOptionSelected={(option) => this.onOptionSelectedPartners(option)}
                onCbClick={this.onCbClickPartners}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
              {this.renderActionButtonsPartners()}
            </div>
            {this.renderPartnersActionButtons()}
          </div>
          <PaginationProvider
              pagination={ paginationFactory(options) }
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                    <div>
                      <PaginationListStandalone
                        { ...paginationProps }
                      />
                    <BootstrapTable
                      bootstrap4
                      striped
                      caption={<TableCaption />}
                      keyField='_id'
                      data={ partners }
                      columns={ columns }
                      selectRow={ selectRow }
                      noDataIndication={'No se encontraron emprendedores para este programa.'}
                      wrapperClasses="table-responsive"
                      classes="react-bootstrap-table"
                      { ...paginationTableProps }
                      />
                      <PaginationListStandalone
                        { ...paginationProps }
                      />
                    </div>
                    )
              }
            </PaginationProvider>
        </div>
    )
  }

  renderUsersList(){
    const users = this.state.users;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(cellContent !==null && cellContent !== undefined){
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
              </div>
            )
          }else{
            return(
              <KImage src={cellContent} alt="user_image"/>
            )
          }
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'social_linkedin',
        text: 'Datos',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row,rowIndex,formatExtraData) =>{
          let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
          let colorProfile   = row.isProfileComplete ? "#707cd2" : 'red';

          let iconLi      = "mdi-linkedin-box";
          let colorLi     = '#007bff';
          let opacityLi   = isFilled(row.social_linkedin) ? 1.0 : 0.0;

          let iconId      = "mdi-account-card-details";
          let colorId     = '#6c757d';
          let opacityId   = isFilled(row.id_scan) ? 1.0 : 0.0;


            return(
              <div className="flex space-between" style={{width:"65px"}}>
                <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
                <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
                <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
              </div>
            )
        }
      },
      {
        dataField: '_id',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter:(cell) =>{

          return (<div className="btn btn-link" onClick={() => this.openUserProfile(cell,'companies')}>Ver empresas</div>)
        },
      },
    ];
    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectUsersWithoutCompany,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelectUsers = ref}
                onOptionSelected={(option) => this.onOptionSelectedUsersWithoutCompany(option)}
                onCbClick={this.onCbClickUsers}
                cbState={this.state.cbStateUsers}
                options={this.smartSelectOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedUsersWithoutCompany.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
        <div className="card-body">
          <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
            <div style={{marginLeft:"20px"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectUsers = ref}
                onOptionSelected={(option) => this.onOptionSelectedUsersWithoutCompany(option)}
                onCbClick={this.onCbClickUsers}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
            </div>
            {this.renderActionButtonsUsersWithoutCompany()}
          </div>
          <BootstrapTable
            bootstrap4
            striped
            caption={<TableCaption />}
            keyField='_id'
            data={ users }
            columns={ columns }
            selectRow={ selectRow }
            noDataIndication={'No se encontraron emprendedores ni aliados sin startup.'}
            wrapperClasses="table-responsive"
            classes="react-bootstrap-table"
          />
        </div>
    )
  }

  renderResourcesList(){
    const resources = this.state.resources || [];
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'url',
        text: 'Url',
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'description',
        text: 'Descripción',
        style: {
          verticalAlign:'middle'
        },
      },
    ];

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectResources,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelectResources = ref}
                onOptionSelected={(option) => this.onOptionSelectedResources(option)}
                onCbClick={this.onCbClickResources}
                cbState={this.state.cbStateResources}
                options={this.smartSelectOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedResources.map( r => r._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
        <div className="card-body">
          <div  className='m-b-20'
                style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse",justifyContent:'space-between'}}>
            <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row-reverse"}}>
            <div style={{marginLeft:"20px"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelectResources = ref}
                onOptionSelected={(option) => this.onOptionSelectedResources(option)}
                onCbClick={this.onCbClickResources}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
            </div>
              {this.renderActionButtonsResources()}
            </div>
            {this.renderCreateResourceBtn()}
          </div>

          <BootstrapTable
            bootstrap4
            striped
            caption={<TableCaption />}
            keyField='_id'
            data={ resources }
            columns={ columns }
            selectRow={ selectRow }
            noDataIndication={'No se encontraron recursos para este programa.'}
            wrapperClasses="table-responsive"
            classes="react-bootstrap-table"
          />
        </div>
    )
  }

  renderSettings(){
    return (
      <div className="card-body">
        <h3 className="card-title m-b-5 font-bold">
          <span className="lstick"></span>Editar programa
        </h3>
        <div className="card-body">
          <KCreateAndEditProgram
            program={this.state.program}
            editMode={this.state.isEditing}
            toggleEditing={this.handleIsEditing}
            onUpdateProgram={() => { this.getProgram(this.state.program._id, false) }}
          />
        </div>
        {/* <div style={{ borderTop: "2px solid #e9ebee", marginBottom: "20px" }}/>
        <h3 className="card-title m-b-5 font-bold">
          <span className="lstick"></span>Servicios
        </h3>
        <div className="card-body">
          <KProgramServicesComponent
            program={this.state.program}
            onUpdateProgram={() => {
              this.getProgram(this.state.program._id, false);
            }}
          ></KProgramServicesComponent>
        </div> */}
        <div style={{ borderTop: "2px solid #e9ebee", marginBottom: "20px" }}/>
        <h3 className="card-title m-b-5 font-bold">
          <span className="lstick"></span>Destinatarios de correos
        </h3>
        <div className="card-body">
          <KProgramMailToComponent
            program={this.state.program}
            onUpdateProgram={() => {
              this.getProgram(this.state.program._id, false);
            }}
          ></KProgramMailToComponent>
        </div>
      </div>

    );
  }

  handleIsEditing(value){
    this.setState({isEditing:value})
  }

  renderLoader(){
    return(
        <div className="card loader_wrapper">
          <BounceLoader
                  sizeUnit={"px"}
                  size={150}
                  color={'#3cc'}
                  loading={true}
                />
        </div>
    )
  }

  renderTabs(){
    return(
      <div className="card">
          <Tab.Container
            mountOnEnter={true}
            unmountOnExit={true}
            defaultActiveKey='brief'>
            <Nav className="nav" bsPrefix='tabCustom'>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="brief" id="brief">Resumen</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="companies" id='companies'>Empresas</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="mentors" id='mentors'>Aliados</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="partners" id='partners'>Emprendedores</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="users" id='users'>Emprendedores sin empresa</Nav.Link>
              </Nav.Item> */}
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="resources" id='resources'>Recursos</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="settings" id='settings'>
                  <i className="mdi mdi-settings"></i>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="brief">
                {this.renderBrief()}
              </Tab.Pane>
              <Tab.Pane eventKey="companies">
                {this.renderCompanyList()}
              </Tab.Pane>
              <Tab.Pane eventKey="mentors">
                {this.renderMentorsList()}
              </Tab.Pane>
              <Tab.Pane eventKey="partners">
                {this.renderPartnersList()}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="users">
                {this.renderUsersList()}
              </Tab.Pane> */}
              <Tab.Pane eventKey="resources">
                {this.renderResourcesList()}
              </Tab.Pane>
              <Tab.Pane eventKey="settings">
                {this.renderSettings()}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
      </div>
    )
  }

  renderContent(){
    if(this.state.isLoading){
      return this.renderLoader();
    }else{
      return this.renderTabs();
    }
  }


  render () {
    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Programa "{this.state.program.name}"</h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                      <button className="btn btn-success btn-save float-right"
                              onClick={() => {this.getProgram(this.state.program._id)}}>
                              <i className="mdi mdi-refresh" style={{fontSize: '18px'}}></i>
                      </button>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    {this.renderContent()}
                    </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}
            <KSendManagerEmail showModal={this.state.emailModal}
                               closeModal={this.openEmailModal}
                               usersList={this.state.usersList} //the list can be a user list or a list of companies
                               programId={this.state.program._id}
                               programName={this.state.program.name}
                               type={this.state.type}>
                               </KSendManagerEmail>
           <KProfileSidebar showSidebar={this.state.isSidebarProfileOpen}
                            closeSidebar={(show) => this.handleSidebarProfile(show)}
                            user_id={this.state.userSelectedId}
                            activeKey={this.state.userProfileActiveKey}>
          </KProfileSidebar>
          <KAssignUserToProgramModal showModal={this.state.assignModal}
                                     closeModal={this.handleAssignModal}
                                     program={this.state.program}
                                     onUserAssigned={(_id) => {this.getProgram(_id,false)}}>
          </KAssignUserToProgramModal>
          <KAssignUserToCompanyModal showModal={this.state.assignUserModal}
                                     closeModal={() => this.handleAssignUserModal(false,{})}
                                     program={this.state.program}
                                     company={this.state.selectedAssignCompany}
                                     onUserAssigned={(_id) => {this.getProgram(_id,false)}}>
          </KAssignUserToCompanyModal>
          <KSendCertificatesModal showModal={this.state.certificatesModal}
                                  closeModal={this.handleCertificatesModal}
                                  program_id={this.state.program._id}
                                  mentors={this.state.mentors}
                                  partners={this.state.program.partners}
                                  onClickNavigation={() => this.props.history.push('/certificate_editor')}>
          </KSendCertificatesModal>
          <KAssignCompanyToProgramModal showModal={this.state.assignCompanyModal}
                                        closeModal={this.handleAssignCompanyModal}
                                        program={this.state.program}
                                        onCompanyAssigned={(_id) => {this.getProgram(_id,false)} }>
          </KAssignCompanyToProgramModal>
          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default ProgramsView;
