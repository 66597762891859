/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darién Miranda & América Mendoza, darien@startuplab.mx, america@startuplab.mx> June 2019
 */

// Regular imports
import React from 'react';
import { Prompt } from 'react-router-dom'
import KomunidadApi from '../../api/api.js';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { addDays } from 'date-fns';
import Swal from 'sweetalert2';
// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';
import "react-datepicker/dist/react-datepicker.css";

//Components
import Header from '../../components/Header';
import ImagePicker from '../../components/ImagePicker.js';
import KImage from '../../components/KImage';
import FormInput from '../../components/form/FormInput';
import FormTextArea from '../../components/form/FormTextArea';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {components} from 'react-select';

//FB pixel
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga';

import {  transformToPhone,
          editTransformToPhoneNumber,
          editDateMask,
          transformAddHttps,
          transformToAnchor,
          transformToUpperCase
       } from '../../components/form/transforms.js';
import { getProfileStatus } from '../../utils/Utils.js';
import {Button, FormCheck} from "react-bootstrap";
import ProgramsPicker from "../../components/ProgramsPicker";

registerLocale('es', es);
const moment = require('moment');
const options = [
  { value:'Branding', label:'Branding'},
  { value:'Comunicación', label:'Comunicación'},
  { value:'Contabilidad', label:'Contabilidad'},
  { value:'Desarrollo de Software', label:'Desarrollo de Software'},
  { value:'Descubrimiento de Clientes', label:'Descubrimiento de Clientes'},
  { value:'Diseño Gráfico', label:'Diseño Gráfico'},
  { value:'Distribución', label:'Distribución'},
  { value:'Financiamiento', label:'Financiamiento'},
  { value:'Finanzas', label:'Finanzas'},
  { value:'Innovación', label:'Innovación'},
  { value:'Legal', label:'Legal'},
  { value:'Marketing Digital', label:'Marketing Digital'},
  { value:'Modelo de Negocio', label:'Modelo de Negocio'},
  { value:'Pitch', label:'Pitch'},
  { value:'Productividad', label:'Productividad'},
  { value:'Recursos Humanos', label:'Recursos Humanos'},
  { value:'Sustentabilidad', label:'Sustentabilidad'},
  { value:'UX / UI', label:'UX / UI'},
  { value:'Validación de Mercado', label:'Validación de Mercado'},
  { value:'Valuación', label:'Valuación'},
  { value:'Ventas', label:'Ventas'},
];
class EditProfileView extends React.Component {
  handleChange = selectedOption => {
      let selectedSpecialities = selectedOption || [];
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData["mentor_speciality_sector"] = selectedSpecialities;
        return { formData };
      });
      this.setState({specialitiesChanged:true});
  };
  constructor(props) {
    super(props);
    this.state = {
      formIsOpen:true,
      authorized:true,
      isMentor:false,
      me:{
        _id:null,
        name:"",
        email:"",
        created_at:null,
        updatedAt:null,
        source:null,
        uid:null,
        profileUrlThumbnail:null,
        // goverment_id_curp:null,
        social_linkedin:null,
        social_facebook:null,
        social_twitter:null,
        social_other:null,
        goverment_id_rfc:null,
        dateOfBirth:null,
        phone:null,
        gender:null,
        roles:[],
      },
      formData: {
        name:"",
        last_name_father:"",
        last_name_mother:"",
        email:"",
        dateOfBirth:null,
        gender:null,
        phone:null,
        mentor_biography:"",
        mentor_speciality_sector: [],
        mentor_speciality_sector_other:"",
        goverment_id_curp:'',
        bdaypicker:'',
        pdf_file:'',
        calendly_username: '',
        id_scan: ''
      },
      isUploadingProfileImage:false,
      dateOfBirthInitialValue:null,
      selectedGender: null,
      formIsHalfFilledOut: false,
      confirmedNavigation: false,
      isCalendarOpen:false,
      specialitiesChanged:false,
      mentorIsCompany:true,
      phases:[
        {
          label:'Idea',
          value:'idea'
        },
        {
          label:'Prototipo',
          value:'prototype'
        },
        {
          label:'Startup',
          value:'startup'
        },
        {
          label:'MiPyME',
          value:'mipyme'
        },
      ],
      programs:[],
      creatableSelectText:'',
      isManagerEditing:false
    }
    this.user_id = null;
    this.isFormValid                     = this.isFormValid.bind(this);
    this.saveForm                        = this.saveForm.bind(this);
    this.onProfileImageMouseEnter        = this.onProfileImageMouseEnter.bind(this);
    this.onProfileImageMouseLeave        = this.onProfileImageMouseLeave.bind(this);
    this.getImage                        = this.getImage.bind(this);
    this.onDateChange                    = this.onDateChange.bind(this);
    this.onChangeFormCalendly            = this.onChangeFormCalendly.bind(this);
    this.handleOptionChange              = this.handleOptionChange.bind(this);
    this.onDateSelected                  = this.onDateSelected.bind(this);
    this.getGender                       = this.getGender.bind(this);
    this.getBday                         = this.getBday.bind(this);
    this.formIsHalfFilledOut             = this.formIsHalfFilledOut.bind(this);
    this.handleBlockedNavigation         = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick    = this.handleConfirmNavigationClick.bind(this);
    this.mentor_speciality_sector_change = this.mentor_speciality_sector_change.bind(this);
    this.fileUpload                      = this.fileUpload.bind(this);
    this.idUpload                        = this.idUpload.bind(this);
    this.handlePhaseChange               = this.handlePhaseChange.bind(this);
    this.handlePhaseSelectorChange       = this.handlePhaseSelectorChange.bind(this);
    this.onChangeProgram                 = this.onChangeProgram.bind(this);
    this.handleMultipleSelect            = this.handleMultipleSelect.bind(this);
    this.loadUser                        = this.loadUser.bind(this);
  }
  specialitiesListToObj(specialities){
    let obj = [];
    for (var i = 0; i < specialities.length; i++) {
      let o = {}
      o["value"] = specialities[i];
      o["label"] = specialities[i];

      obj.push(o);
    }
    return obj;
  }
  async getMe(){
    try{

    }catch(ex){

    }
    // res = await KomunidadApi.getMe();
  }
  async loadUser(){
    this.user_id = this.props.match.params.profile_id;
    let res = {};
    if(!this.user_id){
      res = await KomunidadApi.getMe();
      if(res.valid_token){

      }else{
        this.redirectHome();
      }

    }else{
      // user is something else
      res = await KomunidadApi.getUser(this.user_id);
      this.setState({isManagerEditing:true});
    }
    if(res?.success){
      // setState
      this.setState({
        me:res.user,
        authorized:true,
        formIsOpen:true,
        formData:{
          name: res.user.name,
          last_name_father: res.user.last_name_father,
          last_name_mother: res.user.last_name_mother,
          email: res.user.email,
          dayOfBirth:null,
          gender: res.user.gender,
          phone:  res.user.phone,
          goverment_id_curp: res.user.goverment_id_curp || '',
          mentor_biography: res.user.mentor_biography || '',
          mentor_speciality_sector: this.specialitiesListToObj(res.user.mentor_speciality_sector),
          mentor_speciality_sector_other: res.user.mentor_speciality_sector_other || '',
          calendly_username: res.user.calendly_username || '',
          social_linkedin: res.user.social_linkedin || '',
          pdf_file:res.user.mentor_cv_file || '',
          mentorIsCompany: res.user.mentorIsCompany || false,
          mentorCompanyName: res.user.mentorCompanyName || '',
          mentorCompanyWebsite: res.user.mentorCompanyWebsite || '',
          mentorCompanyAddress: res.user.mentorCompanyAddress || '',
          mentorCompanyContactName: res.user.mentorCompanyContactName || '',
          mentorCompanyPhone: res.user.mentorCompanyPhone || '',
          mentorCompanyEmail: res.user.mentorCompanyEmail || '',
          mentorCompanyPhase: res.user.mentorCompanyPhase || '',
          mentorCompanyPrograms: res.user.mentorCompanyPrograms || [],
          mentorCompanyPhases: (res.user.mentorCompanyPhases || []).map((v) => this.state.phases.find((p) => p.value === v)),
          mentorPromotedProgramName1:  res.user.mentorPromotedProgramName1 ||'',
          mentorPromotedProgramDescription1: res.user.mentorPromotedProgramDescription1 ||'',
          mentorPromotedProgramURL1: res.user.mentorPromotedProgramURL1 ||'',
          mentorPromotedProgramName2: res.user.mentorPromotedProgramName2 ||'',
          mentorPromotedProgramDescription2: res.user.mentorPromotedProgramDescription2 ||'',
          mentorPromotedProgramURL2: res.user.mentorPromotedProgramURL2 ||''
        },
      });
      this.getGender(res.user);
      this.getBday(res.user);
    }

  }
  componentDidMount(){
    const advancedMatching = { em: 'israel.arellano.gdz@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactGA.initialize('AW-529438945');
    ReactPixel.init('838333836903118', advancedMatching, options);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.pageView();
    this.loadUser();
  }

  componentDidUpdate(){
    if(this.state.formIsHalfFilledOut) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  handleBlockedNavigation = (nextLocation) => {
    if(!this.state.confirmedNavigation){
      this.confirmLeave(nextLocation);
      return false;
    }

    return true
  }

  //Confirm leave
  confirmLeave(nextLocation){
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que ingresaste se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.handleConfirmNavigationClick(nextLocation);
      }
    })
  }

  //Navigate to the next location
  handleConfirmNavigationClick(nextLocation){
     this.setState({
        confirmedNavigation: true
     }, () => {
        // Navigate to the previous blocked location
        this.props.history.push(nextLocation.pathname);
     })
  }

  getGender(user){
    let gender = '';
    if(user.hasOwnProperty('gender')){
      gender = user.gender;
    }
    this.setState({
      formData:{
        ...this.state.formData,
        gender: gender
      }
    });
  }

  getBday(user){
    if(user.hasOwnProperty('dateOfBirth')){
      if(user.dateOfBirth === null){
        return "";
      }
      let d = user.dateOfBirth;
      let date = new Date(d.replace('Z',''));
      let formInputdate = this.returnDateFormatToFormInput(date);

      this.setState({dateOfBirthInitialValue: formInputdate.day + formInputdate.month + formInputdate.year});

      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.dateOfBirth = date;
        formData.bdaypicker = formInputdate.day + formInputdate.month + formInputdate.year;
        return { formData };
      });
    }
  }

  redirectHome(){
    this.props.history.push('/');
  }

  //Set the state of start edit the forma
  formIsHalfFilledOut(value){
    if(!(this.state.formIsHalfFilledOut && value)){
      this.setState({formIsHalfFilledOut:value});
    }
  }

  renderUpdateImageArea(){
    if(this.state.isUploadingProfileImage){
      return(
        <div className="img-circle-overlay-left" >
          <div className="spinner-border" role="status">
            <span className="sr-only">Subiendo...</span>
          </div>
        </div>
      )
    }else if(this.state.showUpdateImageArea){
      return(
        <div className="img-circle-overlay-left" onClick={() => this.imagePicker.openPicker()}>
          <div>
            Actualizar
          </div>
        </div>
      )
    }else{
      return null;
    }
  }
  onProfileImageMouseEnter(){
    this.setState({
      showUpdateImageArea:true
    })
  }
  onProfileImageMouseLeave(){
    this.setState({
      showUpdateImageArea:false
    })
  }
  getImage(file){
    if(file != null){
      if(this.user_id){
        this.setState({isUploadingProfileImage:true});
        KomunidadApi.managerUploadUserImage(file,this.user_id).then((res) => {
          this.setState({isUploadingProfileImage:false});
          if(res.success){
            if(res.hasOwnProperty('user')){
              let user = res.user;
              if(user.hasOwnProperty('profileUrlThumbnail')){
                this.setState(prevState => {
                  let me  = Object.assign({}, prevState.me);    // creating copy of state variable jasper
                  me.profileUrlThumbnail = user.profileUrlThumbnail;           // update the name property, assign a new value
                  return { me };                                // return new object jasper object
                })
              }
            }
          }else{
            // TODO: DO SOMETHING
          }
        });
      }else{
        this.setState({isUploadingProfileImage:true});
        KomunidadApi.uploadUserImage(file).then((res) => {
          this.setState({isUploadingProfileImage:false});
          if(res.success){
            if(res.hasOwnProperty('user')){
              let user = res.user;
              if(user.hasOwnProperty('profileUrlThumbnail')){
                this.setState(prevState => {
                  let me  = Object.assign({}, prevState.me);    // creating copy of state variable jasper
                  me.profileUrlThumbnail = user.profileUrlThumbnail;           // update the name property, assign a new value
                  return { me };                                // return new object jasper object
                })
              }
            }
          }else{
            // TODO: DO SOMETHING
          }
        });
      }


    }else{
      // TODO: DO SOMETHING
    }
  }

  //setState for DatePicker
  onDateChange(date) {
    this.formIsHalfFilledOut(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.dateOfBirth = date;
      return { formData };
    });
  }

  //setState for DatePicker
  onDateSelected(date) {
    this.formIsHalfFilledOut(true);

    let d = this.returnDateFormatToFormInput(date);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.bdaypicker = d.day + d.month + d.year;
      return { formData };
    });
  }

  handleOptionChange(event) {
    this.formIsHalfFilledOut(true);

    this.setState({
      formData:{
        ...this.state.formData,
        gender: event.target.value
      }
    });
  }
  handlePhaseChange(event) {
    this.formIsHalfFilledOut(true);

    this.setState({
      formData:{
        ...this.state.formData,
        mentorCompanyPhase: event.target.value
      }
    });
  }
  returnDateFormatToFormInput(date){
    let day = date.getDate().toString();
    let month = date.getMonth() + 1;
        month = month.toString();
    let year = date.getFullYear().toString();

    if(day.length === 1){
      day = '0'+day;
    }
    if(month.length === 1){
      month = '0'+month;
    }

    return {day:day,month:month,year:year}
  }

  mentor_speciality_sector_change(data){
      let dataValue = data.target.value;
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.mentor_speciality_sector = dataValue;
        return { formData };
      });
  }
  fileUpload(file) {
    let pdf_file = file.target.files[0];
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.pdf_file = pdf_file;
      return { formData };
    });
  }
  renderPdfLabel(){
      return(
        <div className='form-group'>
          <label>Tu CV (Máximo 5MB)</label><br/>
          <input type="file" onChange={this.fileUpload} accept="application/pdf"/>
          <small className="form-text text-muted">Sube tu Curriculum Vitae en formato PDF.</small>
        </div>
      )
  }
  idUpload(file) {
    let id_scan = file.target.files[0];
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.id_scan = id_scan;
      return { formData };
    });
  }
  renderIdField(){
    let roles = this.state.me.roles
    if(process.env.REACT_APP_ID_SCAN === "true"){
      if( roles.includes("mentor") || roles.includes("partner") ) {
        return(
          <div className='form-group'>
            <label>Subir copia de tu identificación</label><br/>
            <input type="file" accept="application/pdf" onChange={this.idUpload} />
            <small className="form-text text-muted">Sube tu identificación en formato PDF.</small>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  renderIdLabel(){
    let roles = this.state.me.roles
    if(process.env.REACT_APP_ID_SCAN === "true"){
      if( roles.includes("mentor") || roles.includes("partner") ) {
        return(
          <div>
            <small className="form-text text-muted">En caso de que te encuentres inscrito en el algún programa apoyado o subsidiado por alguna organización publica o privada.
            Tu director te pedirá que subas una copia de tu identificación y el número de tu CURP.
            Esta documentación no será utilizada para otros fines que no sean los acordados por la beca o subsidio.<br/></small>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  renderSpecialityAreaError(){
    if(this.state.formData.mentor_speciality_sector.length === 0 && this.state.specialitiesChanged){
      return (<div style={{color:'red',fontSize:"13px"}}>Selecciona al menos un área de especialidad</div>)
    }
  }
  renderMentorFields(){
    const isValidNewOption = (inputValue, selectValue) =>
      inputValue.length > 0 && selectValue.length < 10;
      const Menu = props => {
        const optionSelectedLength = props.getValue().length || 0;
        return (
          <components.Menu {...props}>
            {optionSelectedLength < 10 ? (
              props.children
            ) : (
              <div>Has alcanzado el límite máximo de especialidades</div>
            )}
          </components.Menu>
        );
      };
    let roles = this.state.me.roles || [];
    if(roles.includes("mentor")){
      if(!this.state.isMentor) {
        this.setState({isMentor: true});
      }
      return (
        <div>
          <div className='form-group form-horizontal'>
            <FormInput
              ref={ref => this.fi_calendly_username = ref}
              id="Calendly"
              label="Calendly"
              model="calendly_username"
              editMode={this.state.formIsOpen}
              value={this.state.formData.calendly_username}
              initialValue={this.state.me.calendly_username}
              help="Ingresa tu nombre de usuario de Calendly"
              validationRules="maxLen:50"
              maxLength={50}
              onChange={ change => { this.onChangeFormCalendly(change) } }
              placeholder="Calendly"/>
          </div>
          <div className='form-group form-horizontal'>
            <FormTextArea
              ref={ref => this.fi_mentor_biography = ref}
              id="Biografía"
              label="Biografía"
              model="mentor_biography"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentor_biography}
              help="Escribe tu biografía."
              maxLength={500}
              required={true}
              validationRules="minLen:50|maxLen:500|required"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Biografía"/>
          </div>
          <div className='form-group'>
            <label>Áreas de Especialidad  <span>*</span></label>
              <Select
                components={{ Menu }}
                value={this.state.formData.mentor_speciality_sector}
                onChange={this.handleChange}
                options={options}
                isMulti={true}
                isValidNewOption={isValidNewOption}
                closeMenuOnSelect={false}
                placeholder={"Selecciona una o más especialidades"}
              />
              <small className="form-text text-muted">Seleciona una o mas especialidades.</small>
              {this.renderSpecialityAreaError()}
          </div>
          <div className='form-group form-horizontal'>
            <FormInput
              ref={ref => this.mentor_speciality_sector_other = ref}
              id="Otra Área de Especialidad"
              label="Otra Área de Especialidad"
              model="mentor_speciality_sector_other"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentor_speciality_sector_other}
              help="Si tu área no se encuentra en la lista, escríbela aquí."
              maxLength={50}
              validationRules="minLen:3|maxLen:50|required"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Otra Área de Especialidad"/>
          </div>
          {this.renderPdfLabel()}


        </div>
      )
    }else{
      return (null);
    }
  }

  onChangeWrapperDate(data){
    if(this.state.isCalendarOpen){
      this.dp_bday.setOpen(false);
    }

    if( data.value.length === 10 ){
      let day = data.value.substring(0,2);
      let month = parseInt(data.value.substring(3,5));
      let year = data.value.substring(6,10);
      let date = new Date(year,month-1,day);
      this.dp_bday.selected = date;
      this.onDateChange(date);
    }

    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.formIsHalfFilledOut(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }
  onChangeProgram(programs){
    const formData = {...this.state.formData};
    formData.mentorCompanyPrograms = programs;
    this.setState({formData:formData});
  }

  handleMultipleSelect(val){
    const safeVal = val || [];
    // Add appended options to state
    const formData = {...this.state.formData};
    formData.mentorCompanyPrograms = safeVal;
    this.setState({
      formData: formData,
    })
  }
  onInputChange(val){
    this.setState({
      mentorCompanyProgramWriting:val
    })
  }
  renderPromotedPrograms(){
    if(!this.state.me.roles.includes('mentor')){
      return null;
    }
    return (
        <div id={'promoted_programs'}>
          <hr></hr>
          <b>A cotinuación puedes listar programas para ser promocionados en tu perfil de komunidad. Puedes promocionar
            hasta 2 programas.</b>
          <br/>
          <br/>

          <div className="form-group form-horizontal">
            <div>
              <FormInput
                  ref={ref => this.mentorPromotedProgramName1 = ref}
                  id="Nombre del programa"
                  label="Nombre del programa"
                  model="mentorPromotedProgramName1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramName1}
                  initialValue={this.state.me.mentorPromotedProgramName1}
                  help="Nombre del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Nombre del programa"/>
              <FormInput
                  ref={ref => this.mentorPromotedProgramDescription1 = ref}
                  id="Descripción del programa"
                  label="Descripción del programa"
                  model="mentorPromotedProgramDescription1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramDescription1}
                  initialValue={this.state.me.mentorPromotedProgramDescription1}
                  help="Descripción del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Descripción del programa"/>
              <FormInput
                  ref={ref => this.mentorPromotedProgramURL1 = ref}
                  id="Link del registro del programa"
                  label="Link del registro del programa"
                  model="mentorPromotedProgramURL1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramURL1}
                  initialValue={this.state.me.mentorPromotedProgramURL1}
                  help="Link del registro del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Link del registro del programa"/>
            </div>
            <FormInput
                ref={ref => this.mentorPromotedProgramName2 = ref}
                id="Nombre del programa (2)"
                label="Nombre del programa (2)"
                model="mentorPromotedProgramName2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramName2}
                initialValue={this.state.me.mentorPromotedProgramName2}
                help="Nombre del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Nombre del programa (2)"/>
            <FormInput
                ref={ref => this.mentorPromotedProgramDescription2 = ref}
                id="Descripción del programa (2)"
                label="Descripción del programa (2)"
                model="mentorPromotedProgramDescription2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramDescription2}
                initialValue={this.state.me.mentorPromotedProgramDescription2}
                help="Descripción del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Descripción del programa"/>
            <FormInput
                ref={ref => this.mentorPromotedProgramURL2 = ref}
                id="Link del registro del programa (2)"
                label="Link del registro del programa (2)"
                model="mentorPromotedProgramURL2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramURL2}
                initialValue={this.state.me.mentorPromotedProgramURL2}
                help="Link del registro del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Link del registro del programa (2)"/>
          </div>
        </div>
    )
  }
  renderCompanyForm(){
    return (
        <div className='form-group form-horizontal'>
          <FormInput
              ref={ref => this.mentorCompanyName = ref}
              id="Nombre de la empresa"
              label="Nombre de la empresa"
              model="mentorCompanyName"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyName}
              help="Ingresa el nombre de la empresa (Mínimo 2 caracteres)"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de la empresa"/>
          <FormInput
              ref={ref => this.mentorCompanyWebsite = ref}
              id="Página web de la empresa"
              label="Página web de la empresa"
              model="mentorCompanyWebsite"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyWebsite}
              help="Ingresa la página web de la empresa"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de la página web de la empresa"/>
          <FormInput
              ref={ref => this.mentorCompanyAddress = ref}
              id="Ubicación o Dirección"
              label="Ubicación o Dirección"
              model="mentorCompanyAddress"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyAddress}
              help="Ubicación o Dirección"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Ubicación o Dirección"/>
          <FormInput
              ref={ref => this.mentorCompanyContactName = ref}
              id="Nombre de contacto"
              label="Nombre de contacto"
              model="mentorCompanyContactName"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyContactName}
              help="Nombre de contacto"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de contacto"/>
          <FormInput
              ref={ref => this.mentorCompanyPhone = ref}
              id="Teléfono"
              label="Teléfono"
              model="mentorCompanyPhone"
              showCounter={false}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyPhone}
              initialValue={this.state.me.mentorCompanyPhone}
              help="Ingresa tu número de teléfono a 10 dígitos (Mínimo 10 caracteres)"
              maxLength={14}
              required={true}
              transformDisplayMethod={transformToPhone}
              transformMethod={editTransformToPhoneNumber}
              validationRules="minLen:10|maxLen:10|phone|required"
              onChange={change => {
                this.onChangeForm(change)
              }}
              placeholder="Teléfono"/>
          <div className='form-group form-horizontal'>
            <label>Tus programas para 2024</label>
            <CreatableSelect
                isClearable
                isMulti
                onChange={(program) => {this.handleMultipleSelect(program)}}
                onInputChange={((v) => this.setState({creatableSelectText:v}))}
                noOptionsMessage={() => 'Escribe tu programa'}
                formatCreateLabel={() => {
                  return `Agregar '${this.state.creatableSelectText}'`;
                }}
                value={this.generateOptions()}
            />
          </div>
          <FormInput
              ref={ref => this.mentorCompanyEmail = ref}
              id="Correo electrónico"
              label="Correo electrónico"
              model="mentorCompanyEmail"
              showCounter={true}
              editMode={this.state.formIsOpen}
              value={this.state.formData.mentorCompanyEmail}
              help="Correo electrónico"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Correo electrónico"/>
          <div className="form-group form-horizontal">
            <label>Me interesan proyectos que se encuentran en las siguientes etapas:</label>
            <div>
              {this.renderPhasesSelector()}
            </div>
          </div>

        </div>
    )
  }
  handlePhaseSelectorChange(phases){
    const formData = {...this.state.formData};
    formData.mentorCompanyPhases = phases;
    this.setState({
      formData:formData
    })
  }
  renderPhasesSelector(){
    return(

        <Select
            value={this.state.formData.mentorCompanyPhases}
            onChange={this.handlePhaseSelectorChange}
            options={this.state.phases}
            menuPosition={'fixed'}
            isMulti={true}
            placeholder={"Selecciona una o más etapas de proyecto que te interesen"}
        />
    )
  }
  render(){
    /*
      Indicate if the user can leave the page
    */
    const formIsHalfFilledOut = this.state.formIsHalfFilledOut
    /*
      Custom btn for the date picker
    */
    const CustomDateInput = ({value, onClick, onChange,  ...props }) => (
      <button className={this.state.formIsOpen ? 'btnCalendar' : 'date-picker-non-display'} onClick={onClick}>
        <i className="mdi mdi-calendar" style={{fontSize: '20px', color: '#67757c'}}></i>
      </button>
    );
    return(
      <div className="fix-header single-column card-no-border fix-sidebar height100">
        <Prompt
            when={formIsHalfFilledOut} // <- function that returns boolean
            message={this.handleBlockedNavigation}
        />
        <div id="main-wrapper" className="height100">
          <ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage}/>

            <Header hideBurguer={true} user={this.state.me} hideProfile={this.state.isManagerEditing}/>


          {/* Page wrapper  */}
          <div className={"page-wrapper color-gray"}>
            {/* Container fluid  */}
            <div className="container-fluid container-edit-profile">
              <div className="row page-titles">
                  <div className="col-md d-inline-flex justify-content-left">
                      <h3 className="text-themecolor">{
                        this.state.isMentor && this.state.isManagerModifying ?
                        'Para poder aceptar la invitación como aliado es necesario que actualices tu perfil, incluyendo tu biografía, áreas de especialidad y cv'
                        : 'Agrega tus datos'}</h3>
                  </div>
              </div>
              {/* Start Page Content */}
              <div className="row">
                <div className='col-12'>
                  <div className="card">
                    <div className="card-body">
                    {/*<p>Antes de continuar a Komunidad.io es necesario que ingreses los siguientes datos:</p>*/}
                    <div className='col-sm-12 col-xs-12'>
                      <div className="form-group">
                        <label className="col-md-12">{this.state.formData.mentorIsCompany ? 'Logo de la empresa' : 'Imagen de perfil'}</label>
                        <div className="cursor-pointer noselect" onMouseEnter={this.onProfileImageMouseEnter} onMouseLeave={this.onProfileImageMouseLeave}>
                            {this.renderUpdateImageArea()}
                            <KImage src={this.state.me.profileUrlThumbnail} className="img-circle" alt="user_img" type={"user_"+this.state.me.gender}/>
                        </div>


                      </div>
                      {
                        this.state.me.roles.includes('mentor') ?
                            <div className='form-group'>
                              <div className="switch">
                                <label style={{fontSize:"1rem",fontWeight:'bold'}}>Soy empresa
                                  <input type="checkbox"
                                         name="accepts_service_requests"
                                         onChange={(e) => {this.onCbChange(e)}}
                                         checked={this.state.formData.mentorIsCompany}/>
                                  <span className="lever"></span>
                                </label>
                                <p>Activa esta casilla si eres una empresa</p>
                              </div>
                            </div>
                            : null
                      }

                      {/* Mentor company form ends*/}
                      {
                        !this.state.formData.mentorIsCompany ?
                            <div>
                              <div className='form-group form-horizontal'>

                                <FormInput
                                    ref={ref => this.fi_name = ref}
                                    id="Nombre(s)"
                                    label="Nombre(s)"
                                    model="name"
                                    showCounter={true}
                                    editMode={this.state.formIsOpen}
                                    value={this.state.formData.name}
                                    help="Ingresa tu nombre (Mínimo 2 caracteres)"
                                    maxLength={140}
                                    required={true}
                                    validationRules="minLen:2|maxLen:140|required:1"
                                    onChange={ change => { this.onChangeForm(change) } }
                                    placeholder="Nombre(s)"/>
                              </div>
                              <div className='form-group form-horizontal'>
                                <FormInput
                                    ref={ref => this.fi_last_name_father = ref}
                                    id="Apellido Materno"
                                    label="Apellido Paterno"
                                    model="last_name_father"
                                    showCounter={true}
                                    editMode={this.state.formIsOpen}
                                    value={this.state.formData.last_name_father}
                                    help="Ingresa tu apellido paterno (Mínimo 3 caracteres)"
                                    maxLength={140}
                                    required={true}
                                    validationRules="minLen:3|maxLen:140|required"
                                    onChange={ change => { this.onChangeForm(change) } }
                                    placeholder="Apellido Paterno"/>
                              </div>
                              <div className='form-group form-horizontal'>
                                <FormInput
                                    ref={ref => this.fi_last_name_mother = ref}
                                    id="Apellido Materno"
                                    label="Apellido Materno"
                                    model="last_name_mother"
                                    showCounter={true}
                                    editMode={this.state.formIsOpen}
                                    value={this.state.formData.last_name_mother}
                                    help="Ingresa tu apellido materno (Mínimo 3 caracteres)"
                                    maxLength={140}
                                    required={true}
                                    validationRules="minLen:3|maxLen:140|required"
                                    onChange={ change => { this.onChangeForm(change) } }
                                    placeholder="Apellido Materno"/>
                              </div>
                              <div className="form-group form-horizontal wrapperDateCalendar m-b-0" style={{overflow: 'visible'}}>
                                <div className="widthInputCalendar">
                                  <FormInput
                                      ref={ref => this.fi_bday = ref}
                                      id="Fecha de Nacimiento"
                                      label="Fecha de Nacimiento"
                                      model="bdaypicker"
                                      showCounter={false}
                                      editMode={this.state.formIsOpen}
                                      value={this.state.formData.bdaypicker}
                                      initialValue={this.state.dateOfBirthInitialValue}
                                      help="Ingresa tu fecha de nacimiento (dd-mm-aaaa)"
                                      transformMethod={editDateMask}
                                      transformDisplayMethod={editDateMask}
                                      onChange={ change => { this.onChangeWrapperDate(change)} }
                                      onClick={() => {this.dp_bday.setOpen(true); this.setState({isCalendarOpen:true})}}
                                      placeholder="Fecha de Nacimiento"
                                  />
                                </div>
                                <DatePicker
                                    ref={ref => this.dp_bday = ref}
                                    selected={this.state.formData.dateOfBirth}
                                    onSelect={this.onDateSelected}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    locale="es"
                                    onChange={this.onDateChange}
                                    dateFormat="dd MMMM yyyy"
                                    disabled={!this.state.formIsOpen}
                                    className={this.state.formIsOpen ? 'form-control form-control-line' : 'date-picker-non-editable'}
                                    maxDate={addDays(new Date(), 0)}
                                    placeholderText="Selecciona tu fecha"
                                    customInput={<CustomDateInput />}
                                    popperModifiers={{
                                      offset: {
                                        enabled: true,
                                        offset: "-25px, -10px"
                                      },
                                    }}
                                />
                              </div>
                              <div className="form-group form-horizontal">
                                <label>Sexo</label>
                                <div>
                                  <select className={this.state.formIsOpen ? 'form-control custom-select' : 'select-non-editable'}
                                          value={this.state.formData.gender}
                                          onChange={this.handleOptionChange}
                                          disabled={!this.state.formIsOpen}
                                          placeholder="Selecciona tu sexo">
                                    <option value={'no_answer'}>Sexo</option>
                                    <option name="female" value="female">Femenino</option>
                                    <option name="male" value="male">Masculino</option>
                                    <option name="no_answer" value="no_answer">Prefiero no decir</option>
                                  </select>
                                </div>
                              </div>
                              <div className='form-group form-horizontal'>
                                <FormInput
                                    ref={ref => this.fi_phone = ref}
                                    id="Teléfono"
                                    label="Teléfono"
                                    model="phone"
                                    showCounter={false}
                                    editMode={this.state.formIsOpen}
                                    value={this.state.formData.phone}
                                    help="Ingresa tu número de teléfono a 10 dígitos (Mínimo 10 caracteres)"
                                    maxLength={14}
                                    required={true}
                                    transformDisplayMethod={transformToPhone}
                                    transformMethod={editTransformToPhoneNumber}
                                    validationRules="minLen:10|maxLen:10|phone|required"
                                    onChange={ change => { this.onChangeForm(change) } }
                                    placeholder="Teléfono"/>
                              </div>
                              {this.renderMentorFields()}
                            </div>
                            :
                            this.renderCompanyForm()
                      }
                      {/* {this.renderIdLabel()} */}
                      {/* <div className='form-group form-horizontal'>
                        <FormInput
                          ref={ref => this.fi_goverment_id_curp = ref}
                          id="CURP"
                          label="CURP"
                          model="goverment_id_curp"
                          editMode={this.state.editMode}
                          value={this.state.formData.goverment_id_curp}
                          help="Ingresa tu CURP de 18 dígitos (Mínimo 18 caracteres)"
                          transformMethod={ transformToUpperCase }
                          maxLength={18}
                          validationRules="minLen:18|maxLen:18|curp"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="CURP"/>
                      </div> */}
                      {/* {this.renderIdField()} */}
                     </div>
                      {this.renderPromotedPrograms()}
                      {this.renderFormButtons()}
                    </div>
                  </div>
                </div>
              </div>
              {/* End PAge Content */}
            </div>
            {/* End Container fluid  */}
          </div>
        </div>
      </div>
    )
  }
  renderFormButtons(){
    return(
      <div className="form-actions">
        <button type="submit" className="btn btn-success btn-save float-right" onClick={() => this.onSaveClick()}>
          <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar y Continuar
        </button>
      </div>
      )
  }
  isFormValid(){
    //call all validations
    let roles = this.state.me.roles || [];
    if(roles.includes("mentor")){
      if(this.state.formData.mentorIsCompany){
        return (
            this.mentorCompanyName.validate() &&
            this.mentorCompanyWebsite.validate() &&
            this.mentorCompanyAddress.validate() &&
            this.mentorCompanyContactName.validate() &&
            this.mentorCompanyPhone.validate() &&
            this.mentorCompanyEmail.validate()
        );
      }else{
        return (
            this.fi_name.validate() &&
            this.fi_last_name_father.validate() &&
            this.fi_last_name_father.validate() &&
            this.fi_phone.validate() &&
            this.state.formData.dateOfBirth !== null &&
            this.state.formData.gender !== null &&
            this.fi_mentor_biography.validate() &&
            (
                this.state.formData.mentor_speciality_sector.length > 0 ||
                (
                    this.state.formData.mentor_speciality_sector_other !== '' &&
                    this.mentor_speciality_sector_other.validate()
                )
            )
        );
      }

    }else{
      return (
        this.fi_name.validate() &&
        this.fi_last_name_father.validate() &&
        this.fi_last_name_father.validate() &&
        this.fi_phone.validate() &&
        this.state.formData.dateOfBirth !== null &&
        this.state.formData.gender !== null
      );
    }
  }
  //Save the data of the forminput and formtextarea
	onChangeForm(data){
      this.formIsHalfFilledOut(true);

          this.setState({
          formData: {
              ...this.state.formData,
              [data.model]: data.value
          }
      });
	}
  onChangeProgram(data,index){
    this.formIsHalfFilledOut(true);
    const mentorCompanyPrograms = [...this.state.formData.mentorCompanyPrograms];
    mentorCompanyPrograms[index] = data.value;
    this.setState({
      formData: {
        ...this.state.formData,
        mentorCompanyPrograms:mentorCompanyPrograms
      }
    });
  }
  //Save the data of the forminput on calendly field
	onChangeFormCalendly(data){
    this.formIsHalfFilledOut(true);
    let userName = this.extractUsername(data.value);
    if(userName !== null){
      data.value = userName
    }

		this.setState({
    	formData: {
    		...this.state.formData,
    		[data.model]: data.value
    	}
    });
	}

  extractUsername(url) {
    var hostname;
    //remove protocol get hostname
    console.log(url.split('/'))
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //remove port
    hostname = hostname.split(':')[0];
    //remove "?"
    hostname = hostname.split('?')[0];

    let calendly = ["calendly.com","www.calendly.com"];
    if(calendly.includes(hostname)){
      return url.split('/')[3];
    }

    return null;
  }
  openLoadingSwal(){
		Swal.fire({
				 title: 'Tus datos están siendo guardados',
         customClass: {
           title: 'swal-custom-title',
         },
				 onBeforeOpen: () => {
						Swal.showLoading();
				 }
		});
	}
  closeLoadingSwal(){
		Swal.close();
	}
  onSaveClick(){
    if (this.state.formData.mentor_speciality_sector_other === null){
      this.setState(prevState => {
        let speciality_other = Object.assign({},prevState.formData);
        speciality_other.mentor_speciality_sector_other = '';
        return {speciality_other};
      });
    }
    if(this.state.formData.mentor_speciality_sector.length === 0 || this.state.formData.mentor_speciality_sector_other.trim() === '' ){
      this.setState({specialitiesChanged:true}); //this will trigger the specialities error
    }
    if(this.isFormValid()){
      //update all
      this.saveForm();
    }
  }
  getFormatDate(date){
    let dateFormat = moment(date).format('MM-DD-YYYY');
    return dateFormat;
  }
  formatLinkPrograms(idProgram, joined_programs) {
    let programs = joined_programs;
    let programsIds = programs.map(program => {
      return program.program;
    })

    if (!programsIds.includes(idProgram)) {
      programsIds.push(idProgram);
    }

    return programsIds.join("|");
  }
  saveForm(){
    let search = this.props.location.search;
    // let urlParams = new URLSearchParams(search);
    // let programIdQuery = urlParams.get('programid');

    // creo yucatan programid
    let programIdQuery = "61454bbc6af2c01b6192d56c";

    this.openLoadingSwal();
    let params = this.state.formData;
    let mentor_specialities = params.mentor_speciality_sector || [];
    try{
      params.phone = this.fi_phone.getCleanValue();
      params.mentor_speciality_sector = mentor_specialities.map(function(ss){return ss["value"]}).join("|");
    }catch(ex){

    }
    try {
      params.mentorCompanyPhone = this.mentorCompanyPhone.getCleanValue();
    }catch(ex){

    }
    if(this.state.formData.mentorIsCompany){
      this.state.formData.name = this.state.formData.mentorCompanyName;
      params.mentorCompanyPhases = this.state.formData.mentorCompanyPhases.map((v) => v.value);
      params.mentorCompanyPrograms = this.state.formData.mentorCompanyPrograms.map((v) => v.value);

    }
    let t = this;
    if(this.user_id){
      KomunidadApi.managerUpdateUser(params,this.user_id).then((res) =>{
        console.log(res);
        t.closeLoadingSwal();
      }).catch((ex) =>{
        Swal.fire({
          type: 'error',
          title: 'No pudimos guardar tus datos',
          text: 'Hubo un error al guardar tus datos',
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      });
    }else{
      KomunidadApi.updateUser(params).then((res) => {
        t.closeLoadingSwal();

        if(res.success){
          this.formIsHalfFilledOut(false);

          if(res.hasOwnProperty('user')){
            let user = res.user;
            this.setState({
              me:user
            });
            this.setState(prevState => {
              let formData = Object.assign({}, prevState.formData);
              formData["mentor_speciality_sector"] = this.specialitiesListToObj(res.user.mentor_speciality_sector);
              formData["pdf_file"] = res.user.mentor_cv_file;
              return { formData };
            });
            this.getGender(res.user);
            this.getBday(res.user);
            getProfileStatus(res.user).then( statusObj => {
              if(res.user.roles.includes('facilitator')) {
                this.props.history.push('/manager');
              }
                  // else if(res.user.hasOwnProperty('joined_programs') && res.user.joined_programs.length === 0){
                  //   if(programIdQuery !== null) {
                  //     this.props.history.push({
                  //       pathname: '/join_program',
                  //       search: `?programid=${programIdQuery}`
                  //     });
                  //   } else {
                  //     this.props.history.push('/select_program');
                  //   }
              // }
              else {
                let joinedPrograms = user.roles.includes("mentor") ? user.mentored_programs : user.joined_programs;
                let programsArray = this.formatLinkPrograms(programIdQuery, joinedPrograms);
                let join_as_mentor = user.roles.includes("mentor") ? "true":"false";

                KomunidadApi.joinPrograms(user._id, programsArray, programIdQuery, join_as_mentor).then( (res) => {
                  if(res.body.success) {
                    ReactGA.initialize('AW-529438945');
                    ReactGA.event({
                      category: 'conversion',
                      action: 'Se registro una cuenta'
                    });
                  }
                  this.props.history.push('/companies');
                });
              }
            }).catch( err => {
              //TODO: Do something
            });
          }
        }else{
          Swal.fire({
            type: 'error',
            title: 'No pudimos guardar tus datos',
            text: 'Hubo un error al guardar tus datos',
            footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }

  }

  onCbChange(e) {
    let checked = e.target.checked;
    const newValue = !this.state.formData.mentorIsCompany;
    const newFormData = {
      ...this.state.formData,
      mentorIsCompany: newValue
    }
    this.setState({
      formData:newFormData
    })
  }

  addCompany() {
    this.formIsHalfFilledOut(true);
    let mentorCompanyPrograms = [...this.state.formData.mentorCompanyPrograms];
    mentorCompanyPrograms = [...mentorCompanyPrograms, ''];
    this.setState({
      formData: {
        ...this.state.formData,
        mentorCompanyPrograms:mentorCompanyPrograms
      }
    });
  }

  generateOptions() {
    return this.state.formData.mentorCompanyPrograms.map((p) => {
      if(typeof(p) === 'string'){
        return {
          value:p,
          label:p
        }
      }else{
        return p;
      }
    })
  }
}

export default EditProfileView;
