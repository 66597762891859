/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import {PopupText} from 'react-calendly';
//components
import KImage from '../components/KImage';
import KCompanyModal from './KCompanyModal';
import KMentorSidebar from './KMentorSidebar';
import { renderFullNameOrEmail } from '../utils/Utils.js';

/**
*   Display the information of the mentor used by the component [KMentorsGrid](/#kmentorsgrid).
*   Invite a mentor to a company.
*   View the mentor profile.
*/

class KMentorsGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      showProfileModal:false,
    };
    this.handleOpenModal       = this.handleOpenModal.bind(this);
    this.handleProfileModal    = this.handleProfileModal.bind(this);
  }

  handleOpenModal(value){
    this.setState({showModal: value});
  }

  handleProfileModal(value){
    this.setState({showProfileModal: value});
  }

  renderSpeciality(mentor){
    let fullMentorSpeciality = '';
    if(mentor.mentor_speciality_sector !== null){
      for (const [key] in mentor.mentor_speciality_sector){
        fullMentorSpeciality += mentor.mentor_speciality_sector[key] + ", ";
      }
    }if (mentor.mentor_speciality_sector_other !== null){
      if (mentor.mentor_speciality_sector_other !== "") {
        fullMentorSpeciality += mentor.mentor_speciality_sector_other + ", ";
      }
    }if(fullMentorSpeciality === ''){
      fullMentorSpeciality = 'No especificado ';
    }
    fullMentorSpeciality = fullMentorSpeciality.replace(/..$/,".");
    return fullMentorSpeciality;
  }

  renderBio(mentor){
    if(mentor.mentor_biography !== null ){
      return(
        <div>
          <div className="resource-text">
            { mentor.mentor_biography } 
          </div>
          {this.renderCVLink()}
        </div>
      )
    }else{
      return '';
    }
  }
  renderInvite(){
    if(!(this.props.mentor.is_self || this.props.isMyMentor)){
      return(
        <button className='btn btn-info btn-save text-white'
                style={{marginLeft: 0, marginRight: ".50rem"}}
                onClick={() => this.handleOpenModal(true)}>Invitar a empresa</button>
      )
    }else{
      return(null);
    }
  }
  renderCalendly(user){
    let has_calendly = false;
    if(this.props.mentor.hasOwnProperty("calendly_username")){
      if(this.props.mentor.calendly_username !== null){
        if(this.props.mentor.calendly_username.trim() !== ""){
          has_calendly = true;
        }
      }
    }
    if(has_calendly){
      return(
        <div className='calendly-button btn btn-aux'>
          <PopupText
             text="Agendar"
             textColor="#ffffff"
             url={"https://calendly.com/"+this.props.mentor.calendly_username}
           />
        </div>
      )
    }else{
      return(null)
    }
  }
  renderCVLink(){
    let has_cv = false;
    if(this.props.mentor.hasOwnProperty("mentor_cv_file")){
      if(this.props.mentor.mentor_cv_file !== null){
        if(this.props.mentor.mentor_cv_file.trim() !== ""){
          has_cv = true;
        }
      }
    }
    if(has_cv){
      return(
        <a className="box-title m-b-0" href={this.props.mentor.mentor_cv_file}>Descargar CV</a>
      )
    }else{
      return(null)
    }
  }
  renderLinkedIn(){
    if(this.props.mentor.hasOwnProperty("social_linkedin")){
      if((this.props.mentor.social_linkedin !== '') && (this.props.mentor.social_linkedin !== null) ){
        return (
          <a target="_blank" rel="noopener noreferrer" href={this.props.mentor.social_linkedin}>
            <div className="btn p-0 mr-2 mb-1 btn-link">
              <i className="mdi mdi-linkedin-box" style={{fontSize: '22px'}}></i>
            </div>
          </a>
        )
      }
    }else{
      return null
    }
  }

  render() {
    const mentor = this.props.mentor;
    return(
      <div className="col-md-6 col-lg-6 col-xlg-4">
          <div className="card card-body mentor-card">
              <div className="row">
                  <div className="col-xs-2 col-md-4 col-lg-3 text-center mb-3">
                    <KImage src={mentor.profileUrl}
                            alt="user"
                            className="img-circle-br100 img-responsive cursor-pointer"
                            style={{maxWidth: '275px'}}
                            onClick={() => this.handleProfileModal(true)}/>
                  </div>
                  <div className="col-xs-10 col-md-8 col-lg-9">
                    <h3><p className="box-title m-b-0"
                      style={{fontSize: '21px'}}
                      onClick={() => this.handleProfileModal(true)}>{renderFullNameOrEmail(mentor)}</p></h3>
                    <small className="text-muted resource-text" style={{'fontSize':'smaller'}}>{this.renderSpeciality(mentor)}</small>
                    <div className='m-t-20 mb-2' style={{fontSize:'14px'}}>
                      {this.renderBio(mentor)}
                      <br/>
                      {this.renderLinkedIn()}
                      <a className="box-title mb-1" href={`mailto:${mentor.email}`}>{mentor.email}</a>
                    </div>
                    {this.renderInvite()}
                    {this.renderCalendly()}
                  </div>
              </div>
          </div>
          <KCompanyModal showModal={this.state.showModal}
                         user={this.props.mentor}
                         programId={this.props.programId}
                         closeModal={this.handleOpenModal}>
          </KCompanyModal>
          <KMentorSidebar
             showSidebar={this.state.showProfileModal}
             closeSidebar={this.handleProfileModal}
             isMyMentor={this.props.isMyMentor}
             mentor={this.props.mentor}
             >
          </KMentorSidebar>
      </div>
    )
  }
}

KMentorsGridRow.propTypes = {
  /** Mentor to be render. */
  mentor            : PropTypes.object,
  /** Callback when the mentor is clicked */
  onClickMentor     : PropTypes.func,
  programId         : PropTypes.string,
  isMyMentor        : PropTypes.bool
};

KMentorsGridRow.defaultProps = {
  mentor             : {},
  onClickMentor      : ()=>{},
  programId          : '',
  isMyMentor         : true
};

export default KMentorsGridRow;
