/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, October 2019
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete, renderFullNameOrEmail} from '../../utils/Utils.js';
import {Tab, Nav, Button, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import '../../scss/pages/_style.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import CompanyPhasesChecker from '../../utils/CompanyPhasesChecker.js';
import { Link } from "react-router-dom";

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import KImage from '../../components/KImage';
import KTableSmartSelect from '../../components/KTableSmartSelect';
import KCSVUploaderModal from '../../components/KCSVUploaderModal';
import KAssignProgramModal from '../../components/KAssignProgramModal';
import KAssignMultipleProgramModal from '../../components/KAssignMultipleProgramModal';
import KAddFacilitatorModal from '../../components/KAddFacilitatorModal';
import KProfileSidebar from '../../components/KProfileSidebar';
import ProgramsPicker from '../../components/ProgramsPicker';

//Other imports
import { orderBy } from 'lodash';

import EventBriteLogo from '../../assets/images/logos/eventbrite.png';
var isFilled = function(v){
  if(v === null || v === undefined){
    return false;
  }else{
    if( v.toString().trim() === ""){
      return false;
    }else{
      return true;
    }
  }
}
const TableCaption = () => <h5 className="display-table-caption" style={{ textAlign: 'center', color: '#398bf7', padding: '0.5em' }}> Desliza a la derecha para ver más<i className="ml-1 mdi mdi-chevron-right"/> </h5>;
class ManagerUserView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      users:[],
      partners:[],
      mentors:[],
      facilitators:[],
      managers:[],
      companies:[],
      catalog_companies: [],
      preUsers: [],
      authorized:false,
      userIsLoaded:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      email:'',
      is_mentor:false,
      is_partner:false,
      query:'',
      partnersPage: 1,
      mentorsPage: 1,
      facilitatorsPage: 1,
      projectsPage: 1,
      catalogPage: 1,
      preUsersPage: 1,
      partnersQuery:'',
      mentorsQuery:'',
      managersQuery:'',
      companyQuery:'',
      catalogQuery:'',
      preUserQuery: '',
      fQuery: '', //facilitators query
      table_msg:'Ingresa el nombre o correo del usuario a buscar.',
      table_pre_msg:'Ingresa el correo del pre-usuario a buscar.',
      table_fa_msg:'Ingresa el nombre o correo del director a buscar.',
      table_cmpy_msg: 'Ingresa el nombre o correo de la empresa a buscar.',
      selectedUsers:[],
      selectedPartners: [],
      selectedMentors: [],
      selectedManagers: [],
      selectedFacilitators:[],
      selectedPreusers:[],
      selectedCompanies:[],
      selectedPrograms:[],
      selectedCatalog: [],
      assignUsers:[],
      isDeleteBtnDisibled:true,
      loading:false,
      showMultipleProgramModal:false,
      showProgramModal:false,
      showFacilitatorModal:false,
      userSelected:{},
      search_msg:'',
      search_partners_msg: '',
      search_mentors_msg: '',
      search_managers_msg: '',
      search_pre_msg: '',
      search_facilitators_msg: '',
      cb_state:'none',
      cb_state_partners:'none',
      cb_state_mentors: 'none',
      cb_state_managers: 'none',
      cb_state_facilitators:'none',
      cb_state_companies: 'none',
      cb_state_catalog_companies: 'none',
      cb_state_preusers:'none',
      exportUrl:'',
      exportUrlPartners: '',
      exportUrlMentors: '',
      exportUrlManagers: '',
      exportUrlCompanies: '',
      exportUrlCatalog: '',
      programsSelected:[],
      isSidebarProfileOpen:false,
      userSelectedId:'',
      userProfileActiveKey:'profile',
      userType:'',//is using in the assign program modal
      isDownloading:false
    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.onChangeInput                   = this.onChangeInput.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.onChangeProgram                 = this.onChangeProgram.bind(this);
    this.addUserByEmail                  = this.addUserByEmail.bind(this);
    this.onAssignedMultiplePrograms      = this.onAssignedMultiplePrograms.bind(this);
    this.onClickFindUser                 = this.onClickFindUser.bind(this);
    this.onClickFindPartner              = this.onClickFindPartner.bind(this);
    this.onClickFindMentor               = this.onClickFindMentor.bind(this);
    this.onClickFindManager              = this.onClickFindManager.bind(this);
    this.onClickFindPreUser              = this.onClickFindPreUser.bind(this);
    this.onClickFindFacilitators         = this.onClickFindFacilitators.bind(this);
    this.onClickFindCompany              = this.onClickFindCompany.bind(this);
    this.onClickFindCatalog              = this.onClickFindCatalog.bind(this);
    this.onClickDelete                   = this.onClickDelete.bind(this);
    this.onClickDeletePartners           = this.onClickDeletePartners.bind(this);
    this.onClickDeleteMentors            = this.onClickDeleteMentors.bind(this);
    this.onClickDeletePreusers           = this.onClickDeletePreusers.bind(this);
    this.onClickInvitePreusers           = this.onClickInvitePreusers.bind(this);
    this.handleOnSelectUsers             = this.handleOnSelectUsers.bind(this);
    this.handleOnSelectPartners          = this.handleOnSelectPartners.bind(this);
    this.handleOnSelectMentors           = this.handleOnSelectMentors.bind(this);
    this.handleOnSelectManagers          = this.handleOnSelectManagers.bind(this);
    this.handleOnSelectFacilitators      = this.handleOnSelectFacilitators.bind(this);
    this.handleOnSelectCompanies         = this.handleOnSelectCompanies.bind(this);
    this.handleOnSelectCatalog           = this.handleOnSelectCatalog.bind(this);
    this.handleOnSelectPreusers          = this.handleOnSelectPreusers.bind(this);
    this.deleteConfirmationSwal          = this.deleteConfirmationSwal.bind(this);
    this.deleteConfirmationSwalPreusers  = this.deleteConfirmationSwalPreusers.bind(this);
    this.deleteUsers                     = this.deleteUsers.bind(this);
    this.getUsers                        = this.getUsers.bind(this);
    this.getPreUsers                     = this.getPreUsers.bind(this);
    this.getCompanies                    = this.getCompanies.bind(this);
    this.getCatalog                      = this.getCatalog.bind(this);
    this.openProgramModal                = this.openProgramModal.bind(this);
    this.handleProgramModal              = this.handleProgramModal.bind(this);
    // this.openMultipleProgramModal        = this.openMultipleProgramModal.bind(this);
    this.handleMultipleProgramModal      = this.handleMultipleProgramModal.bind(this);
    this.handleFacilitatorModal          = this.handleFacilitatorModal.bind(this);
    this.onOptionSelected                = this.onOptionSelected.bind(this);
    this.onOptionSelectedFacilitators    = this.onOptionSelectedFacilitators.bind(this);
    this.onOptionSelectedCompanies       = this.onOptionSelectedCompanies.bind(this);
    this.onOptionSelectedCatalog         = this.onOptionSelectedCatalog.bind(this);
    this.onOptionSelectedPreusers        = this.onOptionSelectedPreusers.bind(this);
    this.onCbClick                       = this.onCbClick.bind(this);
    this.onCbPartnersClick               = this.onCbPartnersClick.bind(this);
    this.onCbMentorsClick                = this.onCbMentorsClick.bind(this);
    this.onCbManagersClick               = this.onCbManagersClick.bind(this);
    this.onCbFacilitatorsClick           = this.onCbFacilitatorsClick.bind(this);
    this.onCbCompaniesClick              = this.onCbCompaniesClick.bind(this);
    this.onCbCatalogClick                = this.onCbCatalogClick.bind(this);
    this.onCbPreusersClick               = this.onCbPreusersClick.bind(this);
    this.isAuthorized                    = this.isAuthorized.bind(this);
    this.getFacilitators                 = this.getFacilitators.bind(this);
    this.getPartners                     = this.getPartners.bind(this);
    this.getMentors                      = this.getMentors.bind(this);
    this.getManagers                     = this.getManagers.bind(this);
    this.onClickDisable                  = this.onClickDisable.bind(this);
    this.onClickEnable                   = this.onClickEnable.bind(this);
    this.onClickMakePublic               = this.onClickMakePublic.bind(this);
    this.disableConfirmationSwal         = this.disableConfirmationSwal.bind(this);
    this.enableConfirmationSwal          = this.enableConfirmationSwal.bind(this);
    this.disableUsers                    = this.disableUsers.bind(this);
    this.enableUsers                     = this.enableUsers.bind(this);
    this.handleSidebarProfile            = this.handleSidebarProfile.bind(this);
    this.onEventbriteAuthClick           = this.onEventbriteAuthClick.bind(this);
    this.clearSelect                     = this.clearSelect.bind(this);

    this.smartSelectOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
      {
        label         : "Emprendedores",
        actionType    : "mixed",
        actionName    : "partners",
      },
      {
        label         : "Aliados",
        actionType    : "mixed",
        actionName    : "mentors",
      },
      {
        label         : "Emprendedores + Aliados",
        actionType    : "mixed",
        actionName    : "partners+mentors",
      },
      {
        label         : "Gestores",
        actionType    : "mixed",
        actionName    : "managers",
      },
      {
        label         : "Usuarios con perfil completo",
        actionType    : "mixed",
        actionName    : "profile_complete",
      },
      {
        label         : "Usuarios con perfil incompleto",
        actionType    : "mixed",
        actionName    : "profile_incomplete",
      },
      {
        label         : "Usuarios con identificación",
        actionType    : "mixed",
        actionName    : "profile_with_id",
      },
      {
        label         : "Usuarios sin identificación",
        actionType    : "mixed",
        actionName    : "profile_without_id",
      }
    ];
    this.smartSelectPartnersOptions = [
      {
        label: "Todos",
        actionType: "all",
        actionName: "all",
      },
      {
        label: "Ninguno",
        actionType: "none",
        actionName: "none",
      },
      {
        label: "Usuarios con perfil completo",
        actionType: "mixed",
        actionName: "profile_complete",
      },
      {
        label: "Usuarios con perfil incompleto",
        actionType: "mixed",
        actionName: "profile_incomplete",
      },
      {
        label: "Usuarios con identificación",
        actionType: "mixed",
        actionName: "profile_with_id",
      },
      {
        label: "Usuarios sin identificación",
        actionType: "mixed",
        actionName: "profile_without_id",
      }
    ];
    this.smartSelectMentorsOptions = [
      {
        label: "Todos",
        actionType: "all",
        actionName: "all",
      },
      {
        label: "Ninguno",
        actionType: "none",
        actionName: "none",
      },
      {
        label: "Usuarios con perfil completo",
        actionType: "mixed",
        actionName: "profile_complete",
      },
      {
        label: "Usuarios con perfil incompleto",
        actionType: "mixed",
        actionName: "profile_incomplete",
      },
      {
        label: "Usuarios con identificación",
        actionType: "mixed",
        actionName: "profile_with_id",
      },
      {
        label: "Usuarios sin identificación",
        actionType: "mixed",
        actionName: "profile_without_id",
      }
    ];
    this.smartSelectFacilitatorsOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
    ];
    this.smartSelectManagersOptions = [
      {
        label: "Todos",
        actionType: "all",
        actionName: "all",
      },
      {
        label: "Ninguno",
        actionType: "none",
        actionName: "none",
      },
    ];
    this.smartSelectCompaniesOptions = [
      {
        label: "Todos",
        actionType: "all",
        actionName: "all",
      },
      {
        label: "Ninguno",
        actionType: "none",
        actionName: "none",
      },
      {
        label: "Activos",
        actionType: "mixed",
        actionName: "active",
      },
      {
        label: "No Activos",
        actionType: "mixed",
        actionName: "disabled",
      }
    ];
    this.smartSelectCatalogOptions = [
      {
        label: "Todos",
        actionType: "all",
        actionName: "all",
      },
      {
        label: "Ninguno",
        actionType: "none",
        actionName: "none",
      }
    ];
    this.smartSelectPreusersOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
    ];
    this.columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'logoThumbnail',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          return (
            <div className={'cursor-pointer'} onClick={() => this.redirectToCompany(row._id)}>
              <KImage src={cellContent} type='company' className='companyImage' alt="company_image" />
            </div>
          )
        }
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '142px'},
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          return(
            <Link to={`/companies/${row._id}`}>{cellContent}</Link>
          )
        }
      },
      {
        dataField: 'programs',
        text: 'Programa',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          return this.getProgramLabelFromObject(cellContent);
        }
      },
      {
        dataField: 'phases',
        text: 'Etapa',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          return CompanyPhasesChecker.getPhaseLabelFromObject(cellContent);
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === 'desc') {
            if (a.phaseNumber === b.phaseNumber){
              return a.phaseNumber - b.phaseNumber;
            }
            return a.phaseNumber - b.phaseNumber;
          }
          if (a.phaseNumber === b.phaseNumber){
            return b.phaseNumber - a.phaseNumber; // asc
          }
          return b.phaseNumber - a.phaseNumber; // asc
        },
      },
      {
        dataField: 'industry_sector',
        text: 'Sector',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'owner_id',
        text: 'Fundador',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          let name = 'Usuario no encontrado';
          if (cellContent !== null) {
            name = renderFullNameOrEmail(cellContent);
          }
          return name;
        }
      },
      /*{
        dataField: 'is_disabled',
        text: 'Activo',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: this.disableFormatter,
        formatExtraData: {
          true: 'times',
          false: 'check'
        },
        headerAlign: 'center',
        align: 'center',
      }*/
    ];
  }

  redirectToCompany(id) {
    this.props.history.push('/companies/' + id);
  }

  isAuthorized(user){
    return (user.roles.includes("manager") || user.roles.includes("facilitator"));
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {

      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }
        if(isAuthorized){
          this.setState({
            authorized: isAuthorized,
            me:res.user,
            userIsLoaded:true,
            loading:true,
          });
          this.getUsers();
          this.getCompanies('');
          this.getCatalog('');
          if (res.user.roles.includes("manager")){
            this.getPreUsers();
          }
        }else{
          this.redirectToProfile();
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  getCompanies(query) {
    KomunidadApi.findCompanies(query).then((res) => {
      if (res.success) {
        let _length = res.companies.length;
        let _msg = '';

        //set msg for search results if the query is not empty
        if (query !== '') {
          let _r = (_length === 1) ? 'resultado' : 'resultados';

          _msg = `${_length} ${_r} para "${query}" `;
        }

        if (_length > 0) {
          /*this.formatCompaniesNoProgram(res.companies)*/
          this.setState({ companies: this.formatCompaniesPhase(this.formatCompaniesNoProgram(res.companies)), loading: false, search_cmpy_msg: _msg, selectedRows: [] });
          if (this.kTableSmartSelectCompanies !== null && query !== '') {
            this.setState({ cb_state_companies: "none" });
            this.kTableSmartSelectCompanies.changeCbState("none");
          }
        } else {
          this.setState({ companies: [], table_cmpy_msg: 'No hay empresas registradas.', loading: false, search_cmpy_msg: _msg, selectedRows: [] });
          if (this.kTableSmartSelectCompanies !== null && query !== '') {
            this.setState({ cb_state_companies: "none" });
            this.kTableSmartSelectCompanies.changeCbState("none");
          }


        }
      }
    });
  }

  getCatalog(query){
    KomunidadApi.getPublicCompanies(query,'','','','').then((res) => {
      if(res.success){
        let _length = res.companies.length;
        let _msg = '';

        //set msg for search results if the query is not empty
        if(this.state.query !== ''){
          let _r = (_length === 1) ? 'resultado' : 'resultados';
          _msg = `${_length} ${_r} para "${this.state.query}" `;
        }

       if (_length > 0) {
          /*this.formatCompaniesNoProgram(res.companies)*/
          this.setState({ catalog_companies: this.formatCompaniesPhase(this.formatCompaniesNoProgram(res.companies)), loading: false, search_cmpy_msg: _msg, selectedRows: [] });
          if (this.kTableSmartSelectCatalog !== null && query !== '') {
            this.setState({ cb_state_catalog_companies: "none" });
            this.kTableSmartSelectCatalog.changeCbState("none");
          }
        } else {
          this.setState({ catalog_companies: [], table_cmpy_msg: 'No hay empresas registradas.', loading: false, search_cmpy_msg: _msg, selectedRows: [] });
          if (this.kTableSmartSelectCatalog !== null && query !== '') {
            this.setState({ cb_state_catalog_companies: "none" });
            this.kTableSmartSelectCatalog.changeCbState("none");
          }

        }
      }
    });
  }

  formatCompaniesPhase(companies) {
    let formatedCompanies = companies.map(company => {
      let phaseNumber = Object.values(company.phases).filter((v) => v).length;
      company.phases.phaseNumber = phaseNumber;
      return {
        ...company
      };
    })
    return formatedCompanies;
  }

  formatCompaniesNoProgram(companies) {
    companies.forEach(function (element) {
      if (!element.hasOwnProperty('programs')) {
        element.programs = [];
      }
    });

    companies = orderBy(companies, ['created_at'], ['desc']);

    return companies
  }

  getProgramLabelFromObject(company) {

    if (company.length > 0) {
      let company_programs = company.map(company => company.program.name);
      return company_programs.toString();
    } else {
      return (
        <p style={{color: 'red'}}>Empresa sin programa</p>
      )
    }

  }

  onCbClick(state) {
    if (state === "all") {
      this.onOptionSelected(this.smartSelectOptions[0]);
    } else {
      this.onOptionSelected(this.smartSelectOptions[1]);
    }
  }

  handleSidebarProfile(show){
    this.setState({isSidebarProfileOpen:show});
  }

  onOptionSelected(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.users;
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    if(option.actionName === "partners"){
      selectedRows = this.state.users.filter( user => {
        if(user.roles.includes("partner")){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "mentors"){
      selectedRows = this.state.users.filter( user => {
        if(user.roles.includes("mentor")){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "partners+mentors"){
      selectedRows = this.state.users.filter( user => {
        if(user.roles.includes("mentor") && user.roles.includes("partner") && user.roles.length === 2){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "managers"){
      selectedRows = this.state.users.filter( user => {
        if(user.roles.includes("manager")){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "profile_complete"){
      selectedRows = this.state.users.filter( user => {
        if(user.isProfileComplete){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "profile_incomplete"){
      selectedRows = this.state.users.filter( user => {
        if(!user.isProfileComplete){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "profile_with_id"){
      selectedRows = this.state.users.filter( user => {
        if(isFilled(user.id_scan)){
          return true;
        }else{
          return false;
        }
      });
    }
    if(option.actionName === "profile_without_id"){
      selectedRows = this.state.users.filter( user => {
        if(!isFilled(user.id_scan)){
          return true;
        }else{
          return false;
        }
      });
    }
    // this.convert();
    this.setState({
      cb_state:option.actionType,
      selectedUsers:selectedRows
    },() =>{
      this.convertUsersToIds();
    });
  }

  onCbPartnersClick(state) {
    if (state === "all") {
      this.onOptionSelectedPartners(this.smartSelectPartnersOptions[0]);
    } else {
      this.onOptionSelectedPartners(this.smartSelectPartnersOptions[1]);
    }
  }

  onOptionSelectedPartners(option) {
    let selectedRows = [];
    if (option.actionType === "all") {
      selectedRows = this.state.partners;
    }
    if (option.actionType === "none") {
      selectedRows = [];
    }
    if (option.actionName === "profile_complete") {
      selectedRows = this.state.partners.filter(user => {
        if (user.isProfileComplete) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_incomplete") {
      selectedRows = this.state.partners.filter(user => {
        if (!user.isProfileComplete) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_with_id") {
      selectedRows = this.state.partners.filter(user => {
        if (isFilled(user.id_scan)) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_without_id") {
      selectedRows = this.state.partners.filter(user => {
        if (!isFilled(user.id_scan)) {
          return true;
        } else {
          return false;
        }
      });
    }
    // this.convert();
    this.setState({
      cb_state_partners: option.actionType,
      selectedPartners: selectedRows
    }, () => {
      this.convertPartnersToIds();
    });
  }

  onCbMentorsClick(state) {
    if (state === "all") {
      this.onOptionSelectedMentors(this.smartSelectMentorsOptions[0]);
    } else {
      this.onOptionSelectedMentors(this.smartSelectMentorsOptions[1]);
    }
  }

  onOptionSelectedMentors(option) {
    let selectedRows = [];
    if (option.actionType === "all") {
      selectedRows = this.state.mentors;
    }
    if (option.actionType === "none") {
      selectedRows = [];
    }
    if (option.actionName === "profile_complete") {
      selectedRows = this.state.mentors.filter(user => {
        if (user.isProfileComplete) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_incomplete") {
      selectedRows = this.state.mentors.filter(user => {
        if (!user.isProfileComplete) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_with_id") {
      selectedRows = this.state.mentors.filter(user => {
        if (isFilled(user.id_scan)) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (option.actionName === "profile_without_id") {
      selectedRows = this.state.mentors.filter(user => {
        if (!isFilled(user.id_scan)) {
          return true;
        } else {
          return false;
        }
      });
    }
    // this.convert();
    this.setState({
      cb_state_mentors: option.actionType,
      selectedMentors: selectedRows
    }, () => {
      this.convertMentorsToIds();
    });
  }

  onCbManagersClick(state) {
    if (state === "all") {
      this.onOptionSelectedManagers(this.smartSelectManagersOptions[0]);
    } else {
      this.onOptionSelectedManagers(this.smartSelectManagersOptions[1]);
    }
  }

  onOptionSelectedManagers(option) {
    let selectedRows = [];
    if (option.actionType === "all") {
      selectedRows = this.state.managers;
    }
    if (option.actionType === "none") {
      selectedRows = [];
    }
    this.setState({
      cb_state_managers: option.actionType,
      selectedManagers: selectedRows
    }, () => {
      this.convertManagersToIds();
    });
  }

  onOptionSelectedFacilitators(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.facilitators;
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cb_state_facilitators:option.actionType,
      selectedFacilitators:selectedRows
    });
  }

  onOptionSelectedCompanies(option) {
    let selectedRows = [];
    if (option.actionType === "all") {
      selectedRows = this.state.companies.map(c => c._id);
    }
    if (option.actionType === "none") {
      selectedRows = [];
    }
    if (option.actionName === "active") {
      selectedRows = this.state.companies.filter(c => !c.is_disabled).map(c => c._id);
    }
    if (option.actionName === "disabled") {
      selectedRows = this.state.companies.filter(c => c.is_disabled).map(c => c._id);
    }
    this.setState({
      cb_state_companies: option.actionType,
      selectedCompanies: selectedRows
    }, () => {
      this.convertCompaniesToIds();
    });
  }

  onOptionSelectedCatalog(option) {
    let selectedRows = [];
    if (option.actionType === "all") {
      selectedRows = this.state.catalog_companies.map(c => c._id);
    }
    if (option.actionType === "none") {
      selectedRows = [];
    }
    this.setState({
      cb_state_catalog_companies: option.actionType,
      selectedCatalog: selectedRows
    }, () => {
      this.convertCatalogToIds();
    });
  }

  onOptionSelectedPreusers(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.preUsers;
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cb_state_preusers:option.actionType,
      selectedPreusers:selectedRows
    });
  }

  onCbFacilitatorsClick(state){
    if(state === "all"){
      this.onOptionSelectedFacilitators(this.smartSelectFacilitatorsOptions[0]);
    }else{
      this.onOptionSelectedFacilitators(this.smartSelectFacilitatorsOptions[1]);
    }
  }

  onCbCompaniesClick(state){
    if (state === "all") {
      this.onOptionSelectedCompanies(this.smartSelectCompaniesOptions[0]);
    } else {
      this.onOptionSelectedCompanies(this.smartSelectCompaniesOptions[1]);
    }
  }

  onCbCatalogClick(state){
    if (state === "all") {
      this.onOptionSelectedCatalog(this.smartSelectCatalogOptions[0]);
    } else {
      this.onOptionSelectedCatalog(this.smartSelectCatalogOptions[1]);
    }
  }

  onCbPreusersClick(state){
    if(state === "all"){
      this.onOptionSelectedPreusers(this.smartSelectPreusersOptions[0]);
    }else{
      this.onOptionSelectedPreusers(this.smartSelectPreusersOptions[1]);
    }
  }

  convertUsersToIds(){
    let url = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedUsers.map(c => c._id).join("|");
    if(this.state.selectedUsers.length === this.state.users.length){
        //do nothing
    }else{
      url = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrl:url
    })
  }

  convertPartnersToIds() {
    let url = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedPartners.map(c => c._id).join("|");
    url = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    this.setState({
      exportUrlPartners: url
    })
  }

  convertMentorsToIds() {
    let url = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedMentors.map(c => c._id).join("|");
    url = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    this.setState({
      exportUrlMentors: url
    })
  }

  convertManagersToIds() {
    let url = `${KomunidadApi.API_URL}/users?export=xlsx`;
    let ids = this.state.selectedManagers.map(c => c._id).join("|");
    url = `${KomunidadApi.API_URL}/users?export=xlsx&ids=${ids}`;
    this.setState({
      exportUrlManagers: url
    })
  }

  convertCompaniesToIds() {
    let url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx`;
    let ids = this.state.selectedCompanies.join("|");
    if (this.state.selectedCompanies.length === this.state.companies.length && this.state.companyQuery === '') {
      //do nothing
    } else {
      url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrlCompanies: url
    })
  }

  convertCatalogToIds() {
    let ids = this.state.selectedCatalog.join("|");
    let url = `${KomunidadApi.API_URL}/companies/manager/index?export=xlsx&ids=${ids}`;
    this.setState({
      exportUrlCatalog: url
    })
  }

  getUsers(){
    KomunidadApi.getUsers().then((res) => {
      if(res.success){
        if(res.users.length > 0){
          this.setState({
            users:res.users,
            isDeleteBtnDisibled:false,
            loading:false,
            search_msg:'',
            search_partners_msg: '',
            search_mentors_msg: '',
            search_facilitators_msg: '',
            search_managers_msg: '',
          });
          this.getPartners(res.users);
          this.getMentors(res.users);
          this.getFacilitators(res.users);
          this.getManagers(res.users);
        }else{
          this.setState({users:[],table_msg:'No hay usuarios registrados.',loading:false,search_msg:''});
        }
      }
    });
  }

  getPreUsers() {
    KomunidadApi.getPreUsers().then((res) => {
      if(res.success){
        if(res.users.length > 0){
          this.setState({preUsers:this.sortPreUsers(res.users),loading:false, search_pre_msg:''});
        }else{
          this.setState({preUsers:[],table_pre_msg:'No hay usuarios pre-registrados' ,loading:false, search_pre_msg:''});
        }
      }
    });
  }

  sortPreUsers(preUsers) {

    preUsers = orderBy(preUsers, ['created_at'], ['desc']);

    /*preUsers = preUsers.slice(0,54)*/

    return preUsers;
  }

  getPartners(users) {
    let partners = users.filter(user => user.roles.includes('partner'));

    partners = orderBy(partners, ['created_at'], ['desc']);
    /*partners = partners.slice(0,300)*/
    this.setState({ partners: partners, search_partners_msg: '' });
  }

  getMentors(users) {
    let mentors = users.filter(user => user.roles.includes('mentor'));

    mentors = orderBy(mentors, ['created_at'], ['desc']);

    this.setState({ mentors: mentors, search_mentors_msg: '' });
  }

  getFacilitators(users) {
    let facilitators = users.filter( user => user.roles.includes('facilitator'));

    facilitators = orderBy(facilitators, ['created_at'], ['desc']);

    this.setState({facilitators:facilitators, search_facilitators_msg: '' });
  }

  getManagers(users) {
    let managers = users.filter(user => user.roles.includes('manager'));

    managers = orderBy(managers, ['created_at'], ['desc']);

    this.setState({ managers: managers, search_managers_msg:'' });
  }

  findUsers(query){
    this.setState({loading:true});
    KomunidadApi.findUsers(query).then((res) => {
      if(res.success){
        let _length = res.results.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if(_length > 0){
          this.setState({ users:res.results,loading:false,search_msg:_msg,selectedUsers:[]});
          this.kTableSmartSelect.changeCbState("none");
          this.setState({isDeleteBtnDisibled:false});
        }else{
          this.setState({users:[],table_msg:'No se encontró el usuario.',loading:false,search_msg:_msg,selectedUsers:[]});
          this.kTableSmartSelect.changeCbState("none");
        }
      }
    });
  }

  findPreUsers(query) {
    this.setState({loading:true});
    KomunidadApi.findPreUsers(query).then((res) => {
      if(res.success){
        let _length = res.users.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if(_length > 0){
          this.setState({ preUsers:res.users,loading:false,search_pre_msg:_msg});
          this.setState({ cb_state_preusers: "none" });
          this.kTableSmartSelectPreusers.changeCbState("none");
        }else{
          this.setState({preUsers:[],table_pre_msg:'No se encontró el usuario.',loading:false,search_pre_msg:_msg});
          this.setState({ cb_state_preusers: "none" });
          this.kTableSmartSelectPreusers.changeCbState("none");
        }
      }
    });
  }

  findFacilitators(query){
    this.setState({loading:true});
    KomunidadApi.findUsers(query).then((res) => {
      if(res.success){
        let users = res.results;
        let facilitators = users.filter( user => user.roles.includes('facilitator'));

        let _length = facilitators.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if(_length > 0){
          this.setState({facilitators:facilitators, loading:false,search_facilitators_msg:_msg,selectedFacilitators:[]});
          this.setState({ cb_state_facilitators: "none" });
          this.kTableSmartSelectFacilitators.changeCbState("none");
        }else{
          this.setState({facilitators:[],table_fa_msg:'No se encontró el director.',loading:false,search_facilitators_msg:_msg,selectedFacilitators:[]});
          this.setState({ cb_state_facilitators: "none" });
          this.kTableSmartSelectFacilitators.changeCbState("none");
        }
      }
    });
  }

  findPartners(query) {
    this.setState({ loading: true });
    KomunidadApi.findUsers(query).then((res) => {
      if (res.success) {
        console.log(res.results)
        let users = res.results;
        let partners = users.filter(user => user.roles.includes('partner'));

        let _length = partners.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if (_length > 0) {
          this.setState({ partners: partners, loading: false, search_partners_msg: _msg, selectedPartners: [] });
          this.setState({ cb_state_partners: "none" });
          this.kTableSmartSelectPartners.changeCbState("none");
        } else {
          this.setState({ partners: [], table_msg: 'No se encontró el usuario.', loading: false, search_partners_msg: _msg, selectedPartners: [] });
          this.setState({ cb_state_partners: "none" });
          this.kTableSmartSelectPartners.changeCbState("none");
        }
      }
    });
  }

  findMentors(query) {
    this.setState({ loading: true });
    KomunidadApi.findUsers(query).then((res) => {
      if (res.success) {
        let users = res.results;
        let mentors = users.filter(user => user.roles.includes('mentor'));

        let _length = mentors.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if (_length > 0) {
          this.setState({ mentors: mentors, loading: false, search_mentors_msg: _msg, selectedMentors: [] });
          this.setState({ cb_state_mentors: "none" });
          this.kTableSmartSelectMentors.changeCbState("none");
        } else {
          this.setState({ mentors: [], table_msg: 'No se encontró el usuario.', loading: false, search_mentors_msg: _msg, selectedMentors: [] });
          this.setState({ cb_state_mentors: "none" });
          this.kTableSmartSelectMentors.changeCbState("none");
        }
      }
    });
  }

  findManagers(query) {
    this.setState({ loading: true });
    KomunidadApi.findUsers(query).then((res) => {
      if (res.success) {
        let users = res.results;
        let managers = users.filter(user => user.roles.includes('manager'));

        let _length = managers.length;
        let _r = (_length === 1) ? 'resultado' : 'resultados';
        let _msg = `${_length} ${_r} para  "${query}" `;

        if (_length > 0) {
          this.setState({ managers: managers, loading: false, search_managers_msg: _msg, selectedManagers: [] });
          this.setState({ cb_state_managers: "none" });
          this.kTableSmartSelectManagers.changeCbState("none");
        } else {
          this.setState({ managers: [], table_msg: 'No se encontró el usuario.', loading: false, search_managers_msg: _msg, selectedManagers: [] });
          this.setState({ cb_state_managers: "none" });
          this.kTableSmartSelectManagers.changeCbState("none");
        }
      }
    });
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToProfile(){
    this.props.history.push('/profile');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    // this.formIsHalfFilledOut(true);
    this.setState({[data.model]:value});
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  addUserByEmail(){
    let isValid = this.validateFormByEmail();

    if(!isValid){
      Swal.fire({
        title: 'Error',
        html: 'Revisa que la dirección de correo electrónico sea válida.',
        type: 'warning',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      }).then((result) => {

      })
    }else{
      if(!this.state.is_mentor && !this.state.is_partner){
        Swal.fire({
          title: 'Error',
          html: 'Debes de seleccionar al menos un rol',
          type: 'warning',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then((result) => {

        })
      }else{
        this.sendEmailInvitation();
      }
    }
  }

  sendEmailInvitation(){
    let roles = [];
    if(this.state.is_mentor){
      roles.push("mentor");
    }
    if(this.state.is_partner){
      roles.push("partner");
    }

    roles = roles.join("|");
    let programs_ids = this.state.selectedPrograms.join("|");
    KomunidadApi.userPreRegisterMentor(this.state.email,roles, programs_ids).then(res => {
      if (res.success) {
        Swal.fire({
          title: 'Usuario invitado',
          html: 'El usuario ha sido invitado.',
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then((result) => {
          this.fi_email.cancel();
          this.fi_programs.cancel();
          this.setState({ 
            selectedPrograms : [],
            is_mentor:false,
            is_partner:false,
          });
        })
      } else {
        let error = 'No pudimos agregar al usuario. Intenta de nuevo mas tarde.';
        if(res.hasOwnProperty("message_es")){
          error = res.message_es;
        }
        Swal.fire({
          title: 'Error',
          html: error,
          type: 'warning',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then((result) => {

        })
      }
    });
  }

  validateFormByEmail(){
    return this.fi_email.validate();
  }

  onAssignedMultiplePrograms(){
    this.setState({
      selectedPartners:[],
      selectedMentors:[]
    })
    this.getUsers();
  }

  onClickFindUser(){
    this.setState({
      selectedUsers:[]
    })
    this.kTableSmartSelect.changeCbState("none");
    let query = this.state.query;
    if(query !== ''){
      this.findUsers(query);
    }else{
      this.getUsers();
    }
  }

  onClickFindPartner() {
    this.setState({
      selectedPartners: []
    })
    this.setState({ cb_state_partners: "none" });
    this.kTableSmartSelectPartners.changeCbState("none");
    let query = this.state.partnersQuery;
    if (query !== '') {
      this.findPartners(query);
    } else {
      this.getUsers();
    }
  }

  onClickFindMentor() {
    this.setState({
      selectedMentors: []
    })
    this.setState({ cb_state_mentors: "none" });
    this.kTableSmartSelectMentors.changeCbState("none");
    let query = this.state.mentorsQuery;
    if (query !== '') {
      this.findMentors(query);
    } else {
      this.getUsers();
    }
  }

  onClickFindManager() {
    this.setState({
      selectedManagers: []
    })
    this.setState({ cb_state_managers: "none" });
    this.kTableSmartSelectManagers.changeCbState("none");
    let query = this.state.managersQuery;
    if (query !== '') {
      this.findManagers(query);
    } else {
      this.getUsers();
    }
  }

  onClickFindCompany() {
    this.setState({
      selectedCompanies: []
    })
    this.setState({ cb_state_companies: "none" });
    this.kTableSmartSelectCompanies.changeCbState("none");
    let query = this.state.companyQuery;
    if (query !== '') {
      this.getCompanies(query);
    } else {
      this.getCompanies('');
    }
  }

  onClickFindCatalog() {
    this.setState({
      selectedCatalog: []
    })
    this.setState({ cb_state_catalog_companies: "none" });
    this.kTableSmartSelectCatalog.changeCbState("none");
    let query = this.state.catalogQuery;
    if (query !== '') {
      this.getCatalog(query);
    } else {
      this.getCatalog('');
    }
  }

  onClickFindPreUser() {
    this.setState({
      selectedPreusers:[]
    })
    this.setState({ cb_state_preusers: "none" });
    this.kTableSmartSelectPreusers.changeCbState("none");
    let query = this.state.preUserQuery;
    if(query !== ''){
      this.findPreUsers(query);
    }else{
      this.getPreUsers();
    }
  }

  onClickFindFacilitators(){
    this.setState({
      selectedFacilitators:[]
    })
    this.setState({ cb_state_facilitators: "none" });
    this.kTableSmartSelectFacilitators.changeCbState("none");
    let query = this.state.fQuery;
    if(query !== ''){
      this.findFacilitators(query);
    }else{
        this.getUsers();
    }
  }

  onClickDelete(){
    // if users are selected proceed to delete them
    if(this.state.selectedUsers.length > 0){
      this.deleteConfirmationSwal();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los usuarios que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  onClickPartners() {
    // if users are selected proceed to delete them
    if (this.state.selectedPartners.length > 0) {
      this.deleteConfirmationSwalPartners();
    } else {
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los emprendedores que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }


  onClickDeletePartners() {
    // if users are selected proceed to delete them
    if (this.state.selectedPartners.length > 0) {
      this.deleteConfirmationSwalPartners();
    } else {
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los emprendedores que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  onClickDeleteMentors() {
    // if users are selected proceed to delete them
    if (this.state.selectedMentors.length > 0) {
      this.deleteConfirmationSwalMentors();
    } else {
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los aliados que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }
  onClickDeletePreusers(){
    // if users are selected proceed to delete them
    if(this.state.selectedPreusers.length > 0){
      this.deleteConfirmationSwalPreusers();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los usuarios que deseas borrar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  onClickInvitePreusers(){
    // if users are selected proceed to invite them
    if(this.state.selectedPreusers.length > 0){
      this.invitePreusers();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los usuarios a los que deseas reenviar la invitación',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }


  deleteConfirmationSwal(){
    let selectedUsers = this.state.selectedUsers;
    let htmlTxt = '';

    if(selectedUsers.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Borrar al usuario <b>${renderFullNameOrEmail(selectedUsers[0])}</b> del sistema<div>`;
    }else{
      htmlTxt = `Borrar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedUsers.length; i++) {
        let template = `<div><b>${renderFullNameOrEmail(selectedUsers[i])}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Borrar',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.deleteUsers(password);
      }
    })()

  }

  deleteConfirmationSwalMentors() {
    let selectedMentors = this.state.selectedMentors;
    let htmlTxt = '';

    if (selectedMentors.length === 1) {
      htmlTxt = `<div class="margin-top:50px;">Borrar al usuario <b>${selectedMentors[0].email}</b> del sistema<div>`;
    } else {
      htmlTxt = `Borrar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedMentors.length; i++) {
        let template = `<div><b>${selectedMentors[i].email}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
        title: '¿Estás seguro(a)?',
        html: htmlTxt,
        type: 'warning',
        input: 'password',
        inputPlaceholder: 'Ingresa tu contraseña',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.trim() === '') {
              resolve('La contraseña no puede ser vacía');
            } else {
              resolve();
            }
          })
        },
        showCancelButton: true,
        confirmButtonText: 'Borrar',
        cancelButtonText: 'No',
        customClass: {
          content: 'swal-content-class',
          confirmButton: 'swalBtnRedOrange',
          cancelButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      })

      if (password) {
        this.deleteMentors(password);
      }
    })()

  }

  deleteConfirmationSwalPartners() {
    let selectedPartners = this.state.selectedPartners;
    let htmlTxt = '';

    if (selectedPartners.length === 1) {
      htmlTxt = `<div class="margin-top:50px;">Borrar al usuario <b>${selectedPartners[0].email}</b> del sistema<div>`;
    } else {
      htmlTxt = `Borrar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedPartners.length; i++) {
        let template = `<div><b>${selectedPartners[i].email}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
        title: '¿Estás seguro(a)?',
        html: htmlTxt,
        type: 'warning',
        input: 'password',
        inputPlaceholder: 'Ingresa tu contraseña',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.trim() === '') {
              resolve('La contraseña no puede ser vacía');
            } else {
              resolve();
            }
          })
        },
        showCancelButton: true,
        confirmButtonText: 'Borrar',
        cancelButtonText: 'No',
        customClass: {
          content: 'swal-content-class',
          confirmButton: 'swalBtnRedOrange',
          cancelButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      })

      if (password) {
        this.deletePartners(password);
      }
    })()

  }

  deleteConfirmationSwalPreusers(){
    let selectedPreusers = this.state.selectedPreusers;
    let htmlTxt = '';

    if(selectedPreusers.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Borrar al usuario <b>${selectedPreusers[0].email}</b> del sistema<div>`;
    }else{
      htmlTxt = `Borrar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedPreusers.length; i++) {
        let template = `<div><b>${selectedPreusers[i].email}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Borrar',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.deletePreusers(password);
      }
    })()

  }

  invitePreusers(){
    let selectedPreusers = this.state.selectedPreusers;
    if(selectedPreusers.length > 0){
      let preusers_ids = selectedPreusers.map(u => u._id).join('|');
      KomunidadApi.invitePreusers(preusers_ids).then((res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Invitaciones reenviadas',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.onClickFindPreUser();
          })
        }else{
         Swal.fire({
           type: 'error',
           title: res.error_es,
           customClass: {
             confirmButton: 'swalBtnTeal',
             title: 'swal-custom-title',
           },
         });
        }
      });
    }
  }

  deleteUsers(password){
    let selectedUsers = this.state.selectedUsers;
    if(selectedUsers.length > 0){
      let users_ids = selectedUsers.map(u => u._id).join('|');
      KomunidadApi.deleteUsers(users_ids,password).then((res) => {
       if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Usuario(s) eliminados con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.onClickFindUser();
          })
       }else{
         Swal.fire({
           type: 'error',
           title: res.error_es,
           customClass: {
             confirmButton: 'swalBtnTeal',
             title: 'swal-custom-title',
           },
         });
       }
      });
    }
  }

  deletePartners(password) {
    let selectedPartners = this.state.selectedPartners;
    if (selectedPartners.length > 0) {
      let partners_ids = selectedPartners.map(u => u._id).join('|');
      KomunidadApi.deleteUsers(partners_ids, password).then((res) => {
        if (res.success) {
          Swal.fire({
            type: 'success',
            title: 'Usuario(s) eliminados con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.onClickFindPartner();
          })
        } else {
          Swal.fire({
            type: 'error',
            title: res.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }

  deleteMentors(password) {
    let selectedMentors = this.state.selectedMentors;
    if (selectedMentors.length > 0) {
      let mentors_ids = selectedMentors.map(u => u._id).join('|');
      KomunidadApi.deleteUsers(mentors_ids, password).then((res) => {
        if (res.success) {
          Swal.fire({
            type: 'success',
            title: 'Usuario(s) eliminados con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.onClickFindMentor();
          })
        } else {
          Swal.fire({
            type: 'error',
            title: res.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }
  }

  deletePreusers(password){
    let selectedPreusers = this.state.selectedPreusers;
    if(selectedPreusers.length > 0){
      let preusers_ids = selectedPreusers.map(u => u._id).join('|');
      KomunidadApi.deletePreusers(preusers_ids,password).then((res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: 'Usuario(s) eliminados con éxito',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.onClickFindPreUser();
          })
        }else{
         Swal.fire({
           type: 'error',
           title: res.error_es,
           customClass: {
             confirmButton: 'swalBtnTeal',
             title: 'swal-custom-title',
           },
         });
        }
      });
    }
  }

  onChangeProgram(programs){
    this.setState({selectedPrograms:programs});
  }

  renderAddUserForm(){
    return(
      <div className="card-body">
        <div className="row">
         <div className="col-md-6 m-t-20">
           <h3 className='m-b-20'>Por email</h3>
           <div className='form-group'>
             <FormInput
               ref={ref => this.fi_email = ref}
               id="email"
               label="Email"
               model="email"
               showCounter={false}
               value={this.state.email}
               initialValue={''}
               required={true}
               validationRules="email|required"
               onChange={ change => { this.onChangeForm(change) } }
               placeholder="email"
               errorPosition="bottom"
               />
             <label>
               <input
                 type="checkbox"
                 id="is_partner"
                 className="filled-in chk-col-light-blue"
                 name='is_partner'
                 value={this.state.is_partner}
                 checked={this.state.is_partner}
                 onChange={this.onChangeInput}/>
               <label htmlFor="is_partner" className={this.state.is_partner ? "": "text-muted"}>Emprendedor</label>
             </label>
             <label>
              <input
                type="checkbox"
                id="is_mentor"
                className="filled-in chk-col-light-blue"
                name='is_mentor'
                value={this.state.is_mentor}
                checked={this.state.is_mentor}
                onChange={this.onChangeInput}/>
              <label htmlFor="is_mentor" className={this.state.is_mentor ? "": "text-muted"}>Aliado</label>
            </label>
           </div>
            <div className='form-group'>
                <ProgramsPicker
                  ref={ref => this.fi_programs = ref}
                  onChange={(program) => {this.onChangeProgram(program)}}
                  showInactivePrograms={true}
                  isMultiple={true}
                ></ProgramsPicker>
            </div>
           <Button className='btn btn-save' onClick={this.addUserByEmail}>Agregar</Button>
         </div>
         <div className="col-md-6" style={{borderLeft: "1px solid rgba(0,0,0,.1)"}}>
          <KCSVUploaderModal></KCSVUploaderModal>
         </div>
        </div>
        <hr></hr>
        <div className='m-t-20'>
          <h3 className='m-b-20'>Importar usuarios desde Eventbrite</h3>
          <div className='text-muted m-b-20'>Para importar usuarios de un evento de Eventbrite, necesitas asociar tu cuenta de Eventbrite con Komunidad.</div>
          <Button className='btn btn-save' onClick={this.onEventbriteAuthClick}><img src={EventBriteLogo} width={20} alt='EventBriteLogo'></img> Asociar con Eventbrite</Button>
        </div>
      </div>
    )
  }

  onEventbriteAuthClick(){
    let eventbriteurl = process.env.REACT_APP_EVENTBRITE_OAUTH_LINK;
    window.open(eventbriteurl,"_self")
  }

  handleOnSelectUsers(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedUsers = [...state.selectedUsers, row];

        return {
          selectedUsers,
        };
      }, () => {
        this.convertUsersToIds();
        if(this.state.selectedUsers.length === this.state.users.length){
          this.kTableSmartSelect.changeCbState("all");
        }else{
          this.kTableSmartSelect.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedUsers: this.state.selectedUsers.filter(x => x !== row),
      }), () => {
        this.convertUsersToIds();
        if(this.state.selectedUsers.length === 0){
          this.kTableSmartSelect.changeCbState("none");
        }else{
          this.kTableSmartSelect.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectPartners(row, isSelect) {
    if (isSelect) {
      this.setState(state => {
        const selectedPartners = [...state.selectedPartners, row];

        return {
          selectedPartners,
        };
      }, () => {
        this.convertPartnersToIds();
        if (this.state.selectedPartners.length === this.state.partners.length) {
          this.setState({cb_state_partners: "all"});
          this.kTableSmartSelectPartners.changeCbState("all");
        } else {
          this.setState({cb_state_partners: "mixed"});
          this.kTableSmartSelectPartners.changeCbState("mixed");
        }
      });
    } else {
      this.setState(() => ({
        selectedPartners: this.state.selectedPartners.filter(x => x !== row),
      }), () => {
        this.convertPartnersToIds();
        if (this.state.selectedPartners.length === 0) {
          this.setState({cb_state_partners: "none"});
          this.kTableSmartSelectPartners.changeCbState("none");
        } else {
          this.setState({cb_state_partners: "mixed"});
          this.kTableSmartSelectPartners.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectMentors(row, isSelect) {
    if (isSelect) {
      this.setState(state => {
        const selectedMentors = [...state.selectedMentors, row];

        return {
          selectedMentors,
        };
      }, () => {
        this.convertMentorsToIds();
        if (this.state.selectedMentors.length === this.state.mentors.length) {
          this.setState({ cb_state_mentors: "all" });
          this.kTableSmartSelectMentors.changeCbState("all");
        } else {
          this.setState({ cb_state_mentors: "mixed" });
          this.kTableSmartSelectMentors.changeCbState("mixed");
        }
      });
    } else {
      this.setState(() => ({
        selectedMentors: this.state.selectedMentors.filter(x => x !== row),
      }), () => {
        this.convertMentorsToIds();
        if (this.state.selectedMentors.length === 0) {
          this.setState({ cb_state_mentors: "none" });
          this.kTableSmartSelectMentors.changeCbState("none");
        } else {
          this.setState({ cb_state_mentors: "mixed" });
          this.kTableSmartSelectMentors.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectManagers(row, isSelect) {
    if (isSelect) {
      this.setState(state => {
        const selectedManagers = [...state.selectedManagers, row];

        return {
          selectedManagers,
        };
      }, () => {
        this.convertManagersToIds();
        if (this.state.selectedManagers.length === this.state.managers.length) {
          this.setState({ cb_state_managers: "all" });
          this.kTableSmartSelectManagers.changeCbState("all");
        } else {
          this.setState({ cb_state_managers: "mixed" });
          this.kTableSmartSelectManagers.changeCbState("mixed");
        }
      });
    } else {
      this.setState(() => ({
        selectedManagers: this.state.selectedManagers.filter(x => x !== row),
      }), () => {
        this.convertManagersToIds();
        if (this.state.selectedManagers.length === 0) {
          this.setState({ cb_state_managers: "none" });
          this.kTableSmartSelectManagers.changeCbState("none");
        } else {
          this.setState({ cb_state_managers: "mixed" });
          this.kTableSmartSelectManagers.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectFacilitators(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedFacilitators = [...state.selectedFacilitators, row];

        return {
          selectedFacilitators,
        };
      }, () => {
        // this.convertUsersToIds();
        if(this.state.selectedFacilitators.length === this.state.facilitators.length){
          this.setState({ cb_state_facilitators: "all" });
          this.kTableSmartSelectFacilitators.changeCbState("all");
        }else{
          this.setState({ cb_state_facilitators: "mixed" });
          this.kTableSmartSelectFacilitators.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedFacilitators: this.state.selectedFacilitators.filter(x => x !== row),
      }), () => {
        // this.convertUsersToIds();
        if(this.state.selectedFacilitators.length === 0){
          this.setState({ cb_state_facilitators: "none" });
          this.kTableSmartSelectFacilitators.changeCbState("none");
        }else{
          this.setState({ cb_state_facilitators: "mixed" });
          this.kTableSmartSelectFacilitators.changeCbState("mixed");
        }
      });
    }
  }

  handleOnSelectCompanies(row, isSelect) {
    if (isSelect) {
      this.setState(state => {
        const selectedCompanies = [...state.selectedCompanies, row._id];
        return {
          selectedCompanies,
        };
      }, () => {
        this.convertCompaniesToIds();
        if (this.state.selectedCompanies.length === this.state.companies.length) {
          if (this.kTableSmartSelectCompanies !== null) {
            this.setState({ cb_state_companies: "all" });
            this.kTableSmartSelectCompanies.changeCbState("all");
          }

        } else {
          if (this.kTableSmartSelectCompanies !== null) {
            this.setState({ cb_state_companies: "mixed" });
            this.kTableSmartSelectCompanies.changeCbState("mixed");
          }
        }
      });
    } else {
      this.setState(() => ({
        selectedCompanies: this.state.selectedCompanies.filter(x => x !== row._id),
      }), () => {
        this.convertCompaniesToIds();
        if (this.state.selectedCompanies.length === 0) {
          if (this.kTableSmartSelectCompanies !== null) {
            this.setState({ cb_state_companies: "none" });
            this.kTableSmartSelectCompanies.changeCbState("none");
          }

        } else {
          if (this.kTableSmartSelectCompanies !== null) {
            this.setState({ cb_state_companies: "mixed" });
            this.kTableSmartSelectCompanies.changeCbState("mixed");
          }

        }
      });
    }
  }

  handleOnSelectCatalog(row, isSelect) {
    if (isSelect) {
      this.setState(state => {
        const selectedCatalog = [...state.selectedCatalog, row._id];
        return {
          selectedCatalog,
        };
      }, () => {
        this.convertCatalogToIds();
        if (this.state.selectedCatalog.length === this.state.catalog_companies.length) {
          if (this.kTableSmartSelectCatalog !== null) {
            this.setState({ cb_state_catalog_companies: "all" });
            this.kTableSmartSelectCatalog.changeCbState("all");
          }

        } else {
          if (this.kTableSmartSelectCompanies !== null) {
            this.setState({ cb_state_catalog_companies: "mixed" });
            this.kTableSmartSelectCatalog.changeCbState("mixed");
          }
        }
      });
    } else {
      this.setState(() => ({
        selectedCatalog: this.state.selectedCatalog.filter(x => x !== row._id),
      }), () => {
        this.convertCatalogToIds();
        if (this.state.selectedCatalog.length === 0) {
          if (this.kTableSmartSelectCatalog !== null) {
            this.setState({ cb_state_catalog_companies: "none" });
            this.kTableSmartSelectCatalog.changeCbState("none");
          }

        } else {
          if (this.kTableSmartSelectCatalog !== null) {
            this.setState({ cb_state_catalog_companies: "mixed" });
            this.kTableSmartSelectCatalog.changeCbState("mixed");
          }

        }
      });
    }
  }

  handleOnSelectPreusers(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedPreusers = [...state.selectedPreusers, row];

        return {
          selectedPreusers,
        };
      }, () => {
        this.convertUsersToIds();
        if(this.state.selectedPreusers.length === this.state.facilitators.length){
          this.setState({ cb_state_preusers: "all" });
          this.kTableSmartSelectPreusers.changeCbState("all");
        }else{
          this.setState({ cb_state_preusers: "mixed" });
          this.kTableSmartSelectPreusers.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedPreusers: this.state.selectedPreusers.filter(x => x !== row),
      }), () => {
        this.convertUsersToIds();
        if(this.state.selectedPreusers.length === 0){
          this.setState({ cb_state_preusers: "none" });
          this.kTableSmartSelectPreusers.changeCbState("none");
        }else{
          this.setState({ cb_state_preusers: "mixed" });
          this.kTableSmartSelectPreusers.changeCbState("mixed");
        }
      });
    }
  }

  renderActionButtons(){
    if(this.state.selectedUsers.length === 0){
      return (null);
    }else{
      return(
        <div>
          <Button className={"btn btn-danger"}
                  onClick={this.onClickDelete}
                  disabled={this.state.isDeleteBtnDisibled}>
                  Borrar usuarios
          </Button>
          <a className="m-l-10" onClick={() => this.downloadAsExcel(this.state.selectedUsers)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'} size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
        </div>
      )
    }
  }

  renderActionButtonsPartners() {
    if (this.state.selectedPartners.length === 0) {
      return (null);
    } else {
      return (
        <div>
          { this.state.me.roles.includes('manager') && <Button className={"btn btn-danger"}
            onClick={this.onClickDeletePartners}
            disabled={this.state.isDeleteBtnDisibled}>
            Borrar usuarios
          </Button> }
          <Button className={"btn btn-info"}
            onClick={() => this.onClickAssignMultipleUsers('partners')}
            disabled={this.state.isDeleteBtnDisibled}>
            Asignar a programa
          </Button>
          <a className="m-l-10" onClick={() => this.downloadAsExcel(this.state.selectedPartners)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'}  size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
        </div>
      )
    }
  }
  downloadAsExcel = async(selection) =>{
    if(!this.state.isDownloading){
      const _ids = selection.map((p) => p._id);
      this.setState({isDownloading:true});
      KomunidadApi.downloadFile(_ids).then((res) =>{
        this.setState({isDownloading:false});
      }).catch((ex) =>{
        this.setState({isDownloading:false});
      })
    }
  }

  renderActionButtonsMentors() {
    if (this.state.selectedMentors.length === 0) {
      return (null);
    } else {
      return (
        <div>
          { this.state.me.roles.includes('manager') && <Button className={"btn btn-danger"}
            onClick={this.onClickDeleteMentors}
            disabled={this.state.isDeleteBtnDisibled}>
            Borrar usuarios
          </Button> }
          <Button className={"btn btn-info"}
            onClick={() => this.onClickAssignMultipleUsers('mentors')}
            disabled={this.state.isDeleteBtnDisibled}>
            Asignar a programa
          </Button>
          <a className="m-l-10" onClick={() => this.downloadAsExcel(this.state.selectedMentors)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'}  size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
        </div>
      )
    }
  }

  renderActionButtonsManagers() {
    if (this.state.selectedManagers.length === 0) {
      return (null);
    } else {
      return (
        <div>
          <a className="m-l-10" onClick={() => this.downloadAsExcel(this.state.selectedManagers)}>
            {
              this.state.isDownloading ?
                  <Spinner variant={'primary'} animation={'border'}  size={'sm'}>
                  </Spinner> :
                  <div className='btn btn-info btn-save'>Exportar a Excel</div>
            }
          </a>
        </div>
      )
    }
  }

  renderActionButtonsFacilitators(){
    if(this.state.selectedFacilitators.length === 1){
      let user = this.state.selectedFacilitators[0];

      if(user.is_disabled){
        return(
          <div>
            <Button className={"btn btn-info"} onClick={() => this.onClickEnable()}>
                    Habilitar
            </Button>
          </div>
        )
      }else{
        return(
          <div>
            <Button className={"btn btn-danger"} onClick={() => this.onClickDisable()}>
                    Deshabilitar
            </Button>
          </div>
        )
      }
    }else{
      return (null);
    }
  }

  renderActionButtonsCompanies() {
    if (this.state.selectedCompanies.length === 0) {
      return (null);
    } else {
      return (
        <div>
          <a className="m-l-10" href={this.state.exportUrlCompanies} rel="noopener noreferrer" target={'_blank'}>
            <div className='btn btn-info btn-save'>Exportar a Excel</div>
          </a>
          <Button className={"m-l-10 btn btn-aux"}
              onClick={() => this.onClickMakePublic(true)}>
              Agregar al directorio
            </Button>
        </div>
      )
    }
  }

  renderActionButtonsCatalog() {
    if (this.state.selectedCatalog.length === 0) {
      return (null);
    } else {
      return (
        <div>
          <a className="m-l-10" href={this.state.exportUrlCatalog} rel="noopener noreferrer" target={'_blank'}>
            <div className='btn btn-info btn-save'>Exportar a Excel</div>
          </a>
          <Button className={"m-l-10 btn btn-aux"}
              onClick={() => this.onClickMakePublic(false)}>
              Quitar del directorio
            </Button>
        </div>
      )
    }
  }

  renderActionButtonsPreusers(){
    if(this.state.selectedPreusers.length === 0){
      return (null);
    }else{
      return(
        <div>
          <Button className={"btn btn-danger"}
                  onClick={this.onClickDeletePreusers}
                  disabled={this.state.isDeleteBtnDisibled}>
                  Borrar usuarios no confirmados
          </Button>
          <Button className={"btn btn-info"}
                  onClick={this.onClickInvitePreusers}
                  disabled={this.state.isDeleteBtnDisibled}>
                  Reenviar invitación
          </Button>
        </div>
      )
    }
  }

  onClickDisable(){
    // if users are selected proceed to delete them
    if(this.state.selectedFacilitators.length > 0){
      this.disableConfirmationSwal();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los usuarios que deseas deshabilitar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  onClickEnable(){
    // if users are selected proceed to delete them
    if(this.state.selectedFacilitators.length > 0){
      this.enableConfirmationSwal();
    }else{
      //send error msg
      Swal.fire({
        type: 'error',
        title: 'Selecciona los usuarios que deseas habilitar',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    }
  }

  disableConfirmationSwal(){
    let selectedUsers = this.state.selectedFacilitators;
    let htmlTxt = '';

    if(selectedUsers.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Deshabilitar al usuario <b>${renderFullNameOrEmail(selectedUsers[0])}</b> del sistema<div>`;
    }else{
      htmlTxt = `Deshabilitar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedUsers.length; i++) {
        let template = `<div><b>${renderFullNameOrEmail(selectedUsers[i])}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Deshabilitar',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.disableUsers(password);
      }
    })()
  }

  enableConfirmationSwal(){
    let selectedUsers = this.state.selectedFacilitators;
    let htmlTxt = '';

    if(selectedUsers.length === 1){
      htmlTxt = `<div class="margin-top:50px;">Habilitar al usuario <b>${renderFullNameOrEmail(selectedUsers[0])}</b> del sistema<div>`;
    }else{
      htmlTxt = `Habilitar los siguientes usuarios del sistema: `;
      for (var i = 0; i < selectedUsers.length; i++) {
        let template = `<div><b>${renderFullNameOrEmail(selectedUsers[i])}</b></div>`;
        htmlTxt += template;
      }
    }

    (async () => {
      const { value: password } = await Swal.fire({
          title: '¿Estás seguro(a)?',
          html: htmlTxt,
          type: 'warning',
          input:'password',
          inputPlaceholder:'Ingresa tu contraseña',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Habilitar',
          cancelButtonText: 'No',
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnTeal',
            cancelButton: 'swalBtnRedOrange',
            title: 'swal-custom-title',
          },
      })

      if (password) {
        this.enableUsers(password);
      }
    })()
  }

  disableUsers(password){
    let selectedFacilitators = this.state.selectedFacilitators;
    if(selectedFacilitators.length === 1){
      let users_ids = selectedFacilitators.map(u => u._id).join('|');
      KomunidadApi.disableUsers(users_ids,password).then((res) => {
       if(res.success){
         this.getUsers();
         this.setState({selectedFacilitators:[]});
       }else{
         Swal.fire({
           type: 'error',
           title: res.error_es,
           customClass: {
             confirmButton: 'swalBtnTeal',
             title: 'swal-custom-title',
           },
         });
       }
      });
    }
  }

  enableUsers(password){
    let selectedFacilitators = this.state.selectedFacilitators;
    if(selectedFacilitators.length === 1){
      let users_ids = selectedFacilitators.map(u => u._id).join('|');
      KomunidadApi.enableUsers(users_ids,password).then((res) => {
       if(res.success){
         this.getUsers();
         this.setState({selectedFacilitators:[]});

       }else{
         Swal.fire({
           type: 'error',
           title: res.error_es,
           customClass: {
             confirmButton: 'swalBtnTeal',
             title: 'swal-custom-title',
           },
         });
       }
      });
    }
  }

  handleProgramModal(value){
    this.setState({showProgramModal:value});
  }

  handleMultipleProgramModal(value){
      this.setState({showMultipleProgramModal:value});
  }
  onClickAssignMultipleUsers(type){
      if (type === "partners"){
          this.setState({assignUsers: this.state.selectedPartners});
      } else if (type === "mentors") {
          this.setState({assignUsers: this.state.selectedMentors});
      }
      this.handleMultipleProgramModal(true);
  }

  handleFacilitatorModal(value){
    this.setState({showFacilitatorModal:value});
  }

  openProgramModal(id,type){
    KomunidadApi.getUser(id).then((res) => {

      if(res.success){
        let programsSelected = [];

        if(type === 'facilitator'){
          if(res.user.hasOwnProperty('facilitator_programs_permissions')){
            let _p = res.user.facilitator_programs_permissions;
            if(_p.length > 0){
              programsSelected = _p.map(program => program.program._id);
            }
          }
        }else if(type === 'partner'){
          if(res.user.hasOwnProperty('joined_programs')){
            let _p = res.user.joined_programs;
            if(_p.length > 0){
              programsSelected = _p.map(program => program.program);
            }
          }
        }else if(type === 'mentor'){
          if(res.user.hasOwnProperty('mentored_programs')){
            let _p = res.user.mentored_programs;
            if(_p.length > 0){
              programsSelected = _p.map(program => program.program);
            }
          }
        }

        this.setState(
          {
            userSelected:res.user,
            userType:type,
            programsSelected:programsSelected
          }, () => this.handleProgramModal(true)
        );
      }
    });
  }

  onClickMakePublic(is_public) {
    let selected_ids;
    if (is_public) {
      selected_ids = this.state.selectedCompanies.map(u => u).join('|');
    } else {
      selected_ids = this.state.selectedCatalog.map(u => u).join('|');
    }
    KomunidadApi.makePublic(selected_ids, is_public).then((res) => {
      if (res.success && is_public) {
        Swal.fire({
          title: 'Compañías agregadas al directorio público',
          html: 'Se han agregado las compañías al directorio con éxito',
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then((result) => {
          this.getCatalog('')
          this.setState({ 
            selectedCompanies : [],
          });
        })
      } else if(res.success && !is_public){
        Swal.fire({
          title: 'Compañías removidas del directorio público',
          html: 'Se han quitado las compañías del directorio con éxito',
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then((result) => {
          this.getCatalog('')
          this.setState({ 
            selectedCatalog : [],
          });
        })
      } else {
        let error = 'No pudimos agregar agregar las compañías al directorio. Intenta de nuevo mas tarde.';
        if(res.hasOwnProperty("message_es")){
          error = res.message_es;
        }
        Swal.fire({
          title: 'Error',
          html: error,
          type: 'warning',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        })
      }
    });
  }

  openUserProfile(id,activeKey){
    this.setState({userSelectedId:id,userProfileActiveKey:activeKey},() => this.handleSidebarProfile(true));
  }

  isProfileComplete(cell,row,rowIndex,formatExtraData){
    let color = cell ? '#707cd2' : 'red';
    return(
      <i className={"mdi " + formatExtraData[cell]} style={{color: color}}></i>
    )
  }

  clearSelect(users) {
    if(users === 'partners') {
      this.setState({
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedManagers: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    } else if(users === 'mentors') {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedManagers: [],
        selectedPreusers: [],
      })
    } else if(users === 'facilitators') {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedManagers: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    } else if(users === 'managers') {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    } else if(users === 'projects') {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedManagers: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    } else if(users === 'catalog') {
      this.setState({
        selectedPartners: [],
        selectedFacilitators: [],
        selectedCompanies: [],
        selectedManagers: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    }else if(users === 'preusers') {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedManagers: [],
        selectedMentors: []
      })
    } else {
      this.setState({
        selectedPartners: [],
        selectedCompanies: [],
        selectedFacilitators: [],
        selectedManagers: [],
        selectedMentors: [],
        selectedPreusers: [],
      })
    }
  }
  goToEditProfile(userId){
    this.props.history.push();
  }
  renderListUsers(){
    const users = this.state.users;
    const columns = [
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        headerStyle:(column,colIndex) => {
          return {width:'80px'}
        },
        formatter: (cellContent,row) => {
          return(
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
            </div>
          )
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '142px' },
        text: 'Nombre(s)',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
      {
        dataField: 'last_name_father',
        headerStyle: { minWidth: '180px' },
        text: 'A. Paterno',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
      {
        dataField: 'last_name_mother',
        headerStyle: { minWidth: '180px' },
        text: 'A. Materno',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
      {
        dataField: 'roles',
        sort: true,
        text: 'Roles',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          const roles = cellContent.map(function(rol){
            if(rol === 'partner'){
              return 'Emprendedor'
            }else if(rol === 'mentor'){
              return 'Aliado'
            }else if(rol === 'manager'){
              return 'Gestor'
            }else if(rol === 'facilitator'){
              return 'Director'
            }else{
              return 'No especificado'
            }
          });

          return(
            <span>{ roles.map((rol) =>
              <span>{rol}<br></br></span>
              )}
            </span>
          )
        }
      },
        {
          dataField: 'social_linkedin',
          text: 'Datos',
          headerStyle: {padding: '16px 12px' },
          style: {
            verticalAlign:'middle'
          },
          formatter: (cell,row,rowIndex,formatExtraData) =>{
            let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
            let colorProfile   = row.isProfileComplete ? "#707cd2" : 'red';

            let iconLi      = "mdi-linkedin-box";
            let colorLi     = '#007bff';
            let opacityLi   = isFilled(row.social_linkedin) ? 1.0 : 0.0;

            let iconId = "mdi-account-card-details";
            let colorId     = '#6c757d';
            let opacityId   = isFilled(row.id_scan) ? 1.0 : 0.0;


              return(
                <div className="flex space-between" style={{width:"65px"}}>
                  <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
                  <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
                  <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
                </div>
              )
          }
        },
      {
        dataField: '_id',
        text: '',
        formatter:(cell) => {
          return (<div className="btn btn-link" onClick={() => this.openUserProfile(cell,'companies')}>Ver empresas</div>)
        },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          let roles = row.roles;
          if( roles.includes('partner') || roles.includes('mentor') ){
            return (<div className="btn btn-link" onClick={() => this.openProgramModal(row._id,'partner')}>Modificar programas</div>)
          }else{
            return ''
          }
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return (<a className="btn btn-link"  href={`/profile/${row._id}/edit`}>Modificar perfil</a>);
        }
      },
    ];
    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectUsers,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div></div>
      ),
      selected:this.state.selectedUsers.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
        <div className="card-body">
          <div className='row'>
            <div className="col-md-6">
              <FormInput
              ref={ref => this.fi_query = ref}
              id="buscar"
              label="Buscar "
              model="query"
              value={this.state.query}
              showCounter={false}
              initialValue={''}
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Escribe el nombre o correo del usuario"
              onEnterPressed={() => {this.onClickFindUser()}}
              />
            </div>
            <div className="col-md-2">
              <Button style={{marginTop:'32px'}} className="btn btn-save" onClick={this.onClickFindUser}>Buscar</Button>
            </div>
          </div>

          <div className='row'>
            <div className="col-md-6">
              <b className='m-t-10 m-b-10'>{this.state.search_msg}</b>
            </div>
          </div>
          <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
            <div style={{marginLeft:"20px"}}>
              <KTableSmartSelect
                ref={ref => this.kTableSmartSelect = ref}
                onOptionSelected={(option) => this.onOptionSelected(option)}
                onCbClick={this.onCbClick}
                options={this.smartSelectOptions}>
              </KTableSmartSelect>
            </div>
            {this.renderActionButtons()}
          </div>
          <BootstrapTable
            bootstrap4
            striped
            keyField='_id'
            loading={this.state.loading}
            data={ users }
            columns={ columns }
            selectRow={ selectRow }
            noDataIndication={this.state.table_msg}
            wrapperClasses="m-t-20 table-responsive"
            classes="react-bootstrap-table"
            overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(203, 255, 255, 0.5)'}) } })}
          />
        </div>
    )
  }

  renderPartnersMessage() {
    if((this.state.selectedPartners.length === this.state.partners.length) && this.state.partners.length !== 0 && this.state.partnersQuery === '') {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todos</b> los emprendedores
        </div>
      )
    } else if(this.state.selectedPartners.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedPartners.length}</b> emprendedor
        </div>
      )
    } else if(this.state.selectedPartners.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedPartners.length}</b> emprendedores
        </div>
      )
    } else return null;
  }

  renderListPartners() {
    const partners = this.state.partners;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        headerStyle: (column, colIndex) => {
          return { width: '80px' }
        },
        formatter: (cellContent, row) => {
          return (
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id, 'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image" />
            </div>
          )
        }
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '125px', maxWidth: '130px' },
        style: { minWidth: '125px', maxWidth: '130px' },
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      /*{
        dataField: 'programs',
        isDummyField: true,
        text: 'Programas',
        headerStyle: {padding: '16px 12px' },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          console.log(row)
          const programs = cellContent.filter(program => program.access === 'allow');

          return(
            <span>{ programs.map((program) =>
              <span>{program.program.name}<br></br></span>
              )}
            </span>
          )
        }
      },*/
      {
        dataField: 'social_linkedin',
        text: 'Datos',
        headerStyle: { minWidth: '60px', padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
          let colorProfile = row.isProfileComplete ? "#707cd2" : 'red';

          let iconLi = "mdi-linkedin-box";
          let colorLi = '#007bff';
          let opacityLi = isFilled(row.social_linkedin) ? 1.0 : 0.0;

          let iconId = "mdi-account-card-details";
          let colorId = '#6c757d';
          let opacityId = isFilled(row.id_scan) ? 1.0 : 0.0;


          return (
            <div className="flex space-between" style={{ width: "65px" }}>
              <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
              <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
              <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
            </div>
          )
        }
      },
      {
        dataField: 'created_at',
        headerStyle: { minWidth: '200px' },
        text: 'Fecha de registro',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter:this.formatDate
      },
      {
        dataField: 'dummy',
        style: { maxWidth: '120px' },
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell, row) => {
          let roles = row.roles;
          if (roles.includes('partner') || roles.includes('mentor')) {
            return (<div className="btn btn-link" onClick={() => this.openProgramModal(row._id, 'partner')}>Modificar programas</div>)
          } else {
            return ''
          }
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return (<a className="btn btn-link"  href={`/profile/${row._id}/edit`}>Modificar perfil</a>);
        }
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({partnersPage: this.state.partnersPage + 1})
        } else if(page === '<') {
          this.setState({partnersPage: this.state.partnersPage - 1})
        } else {
          this.setState({partnersPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: partners.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.partnersPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectPartners,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle: { paddingLeft: '0.5rem', width: '60px' },
      selectColumnStyle: { padding: '0.5rem', width: '60px' },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectPartners = ref}
              onOptionSelected={(option) => this.onOptionSelectedPartners(option)}
              onCbClick={this.onCbPartnersClick}
              cbState={this.state.cb_state_partners}
              options={this.smartSelectPartnersOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected: this.state.selectedPartners.map(u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={mode} {...rest} />
          <label></label>
        </label>
      )
    };

    return (
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              id="buscar"
              label="Buscar "
              model="partnersQuery"
              value={this.state.partnersQuery}
              showCounter={false}
              initialValue={''}
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Escribe el nombre o correo del usuario"
              onEnterPressed={() => { this.onClickFindPartner() }}
            />
          </div>
          <div className="col-md-2">
            <Button style={{ marginTop: '32px' }} className="btn btn-save" onClick={this.onClickFindPartner}>Buscar</Button>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_partners_msg}</b>
          </div>
        </div>
        {this.renderPartnersMessage()}
        <div style={{ display: "flex", alignItems: "end", flexDirection: "row-reverse" }}>
          <div style={{ marginLeft: "20px" }}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectPartners = ref}
              onOptionSelected={(option) => this.onOptionSelectedPartners(option)}
              onCbClick={this.onCbPartnersClick}
              options={this.smartSelectPartnersOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsPartners()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField='_id'
                    loading={this.state.loading}
                    data={partners}
                    columns={columns}
                    selectRow={selectRow}
                    noDataIndication={this.state.table_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
      </div>
    )
  }

  renderMentorsMessage() {
    if((this.state.selectedMentors.length === this.state.mentors.length) && this.state.mentors.length !== 0 && this.state.mentorsQuery === '') {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todos</b> los aliados
        </div>
      )
    } else if(this.state.selectedMentors.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedMentors.length}</b> aliado
        </div>
      )
    } else if(this.state.selectedMentors.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedMentors.length}</b> aliados
        </div>
      )
    } else return null;
  }

  renderListMentors() {
    const mentors = this.state.mentors;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        headerStyle: (column, colIndex) => {
          return { width: '80px' }
        },
        formatter: (cellContent, row) => {
          return (
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id, 'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image" />
            </div>
          )
        }
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '125px', maxWidth: '130px' },
        style: { minWidth: '125px', maxWidth: '130px' },
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'social_linkedin',
        text: 'Datos',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let iconProfile = row.isProfileComplete ? 'mdi-check' : 'mdi-close';
          let colorProfile = row.isProfileComplete ? "#707cd2" : 'red';

          let iconLi = "mdi-linkedin-box";
          let colorLi = '#007bff';
          let opacityLi = isFilled(row.social_linkedin) ? 1.0 : 0.0;

          let iconId = "mdi-account-card-details";
          let colorId = '#6c757d';
          let opacityId = isFilled(row.id_scan) ? 1.0 : 0.0;


          return (
            <div className="flex space-between" style={{ width: "65px" }}>
              <i className={"mdi " + iconProfile} style={{color: colorProfile}}></i>
              <i className={"mdi " + iconLi} style={{color: colorLi, opacity: opacityLi}}></i>
              <i className={"mdi " + iconId} style={{color: colorId, opacity: opacityId}}></i>
            </div>
          )
        }
      },
      {
        dataField: 'created_at',
        headerStyle: { minWidth: '200px' },
        text: 'Fecha de registro',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter:this.formatDate
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        style: { maxWidth: '120px' },
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell, row) => {
          let roles = row.roles;
          if (roles.includes('partner') || roles.includes('mentor')) {
            return (<div className="btn btn-link" onClick={() => this.openProgramModal(row._id, 'mentor')}>Modificar programas</div>)
          } else {
            return ''
          }
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return (<a className="btn btn-link"  href={`/profile/${row._id}/edit`}>Modificar perfil</a>);
        }
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({mentorsPage: this.state.mentorsPage + 1})
        } else if(page === '<') {
          this.setState({mentorsPage: this.state.mentorsPage - 1})
        } else {
          this.setState({mentorsPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: mentors.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.mentorsPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectMentors,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle: { paddingLeft: '0.5rem', width: '60px' },
      selectColumnStyle: { padding: '0.5rem', width: '60px' },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectMentors = ref}
              onOptionSelected={(option) => this.onOptionSelectedMentors(option)}
              onCbClick={this.onCbMentorsClick}
              cbState={this.state.cb_state_mentors}
              options={this.smartSelectPartnersOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected: this.state.selectedMentors.map(u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={mode} {...rest} />
          <label></label>
        </label>
      )
    };

    return (
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              id="buscar"
              label="Buscar "
              model="mentorsQuery"
              value={this.state.mentorsQuery}
              showCounter={false}
              initialValue={''}
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Escribe el nombre o correo del usuario"
              onEnterPressed={() => { this.onClickFindMentor() }}
            />
          </div>
          <div className="col-md-2">
            <Button style={{ marginTop: '32px' }} className="btn btn-save" onClick={this.onClickFindMentor}>Buscar</Button>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_mentors_msg}</b>
          </div>
        </div>
        {this.renderMentorsMessage()}
        <div style={{ display: "flex", alignItems: "end", flexDirection: "row-reverse" }}>
          <div style={{ marginLeft: "20px" }}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectMentors = ref}
              onOptionSelected={(option) => this.onOptionSelectedMentors(option)}
              onCbClick={this.onCbMentorsClick}
              options={this.smartSelectPartnersOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsMentors()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField='_id'
                    loading={this.state.loading}
                    data={mentors}
                    columns={columns}
                    selectRow={selectRow}
                    noDataIndication={this.state.table_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
      </div>
    )
  }

  renderProjectsMessage() {
    if((this.state.selectedCompanies.length === this.state.companies.length) && this.state.companies.length !== 0 && this.state.companyQuery === '') {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todas</b> las empresas
        </div>
      )
    } else if(this.state.selectedCompanies.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedCompanies.length}</b> empresa
        </div>
      )
    } else if(this.state.selectedCompanies.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedCompanies.length}</b> empresas
        </div>
      )
    } else return null;
  }

  renderCatalogMessage() {
    if((this.state.selectedCatalog.length === this.state.catalog_companies.length) && this.state.catalog_companies.length !== 0 && this.state.catalogQuery=== '') {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todas</b> las empresas
        </div>
      )
    } else if(this.state.selectedCatalog.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedCatalog.length}</b> empresa
        </div>
      )
    } else if(this.state.selectedCatalog.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedCatalog.length}</b> empresas
        </div>
      )
    } else return null;
  }

  renderCompanyList() {
    const companies = this.state.companies;

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({projectsPage: this.state.projectsPage + 1})
        } else if(page === '<') {
          this.setState({projectsPage: this.state.projectsPage - 1})
        } else {
          this.setState({projectsPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: companies.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.projectsPage,
      withFirstAndLast: false
    };


    let selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectCompanies,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle: { paddingLeft: '0.5rem', width: '60px' },
      selectColumnStyle: { padding: '0.5rem', width: '60px' },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectCompanies = ref}
              onOptionSelected={(option) => this.onOptionSelectedCompanies(option)}
              onCbClick={this.onCbCompaniesClick}
              cbState={this.state.cb_state_companies}
              options={this.smartSelectCompaniesOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected: this.state.selectedCompanies,
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={mode} {...rest} />
          <label></label>
        </label>
      )
    };
    return (
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              ref={ref => this.fi_companies_query = ref}
              id="buscar"
              label="Buscar "
              model="companyQuery"
              value={this.state.companyQuery}
              showCounter={false}
              initialValue={''}
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Escribe el nombre de la empresa"
              onEnterPressed={() => this.onClickFindCompany()}
            />
          </div>
          <div className="col-md-2">
            <Button style={{ marginTop: '32px' }} className="btn btn-save" onClick={this.onClickFindCompany}>Buscar</Button>
          </div>
          <div className="col-md-4 col-xs-6 col-pull-right wrapper-btns-manager">

          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_cmpy_msg}</b>
          </div>
        </div>
        {this.renderProjectsMessage()}
        <div style={{ display: "flex", alignItems: "end", flexDirection: "row-reverse" }}>
          <div style={{ marginLeft: "20px" }}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectCompanies = ref}
              onOptionSelected={(option) => this.onOptionSelectedCompanies(option)}
              onCbClick={this.onCbCompaniesClick}
              options={this.smartSelectCompaniesOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsCompanies()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField={'_id'}
                    loading={this.state.loading}
                    data={companies}
                    columns={this.columns}
                    selectRow={selectRow}
                    noDataIndication={this.state.table_cmpy_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
      </div>
    )
  }

  renderCatalogList() {
    const companies = this.state.catalog_companies;

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({catalogPage: this.state.catalogPage + 1})
        } else if(page === '<') {
          this.setState({catalogPage: this.state.catalogPage - 1})
        } else {
          this.setState({catalogPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: companies.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.catalogPage,
      withFirstAndLast: false
    };


    let selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectCatalog,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle: { paddingLeft: '0.5rem', width: '60px' },
      selectColumnStyle: { padding: '0.5rem', width: '60px' },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectCatalog = ref}
              onOptionSelected={(option) => this.onOptionSelectedCatalog(option)}
              onCbClick={this.onCbCatalogClick}
              cbState={this.state.cb_state_catalog_companies}
              options={this.smartSelectCatalogOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected: this.state.selectedCatalog,
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={mode} {...rest} />
          <label></label>
        </label>
      )
    };
    return (
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              ref={ref => this.fi_catalog_query = ref}
              id="buscar"
              label="Buscar "
              model="catalogQuery"
              value={this.state.catalogQuery}
              showCounter={false}
              initialValue={''}
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Escribe el nombre de la empresa"
              onEnterPressed={() => this.onClickFindCatalog()}
            />
          </div>
          <div className="col-md-2">
            <Button style={{ marginTop: '32px' }} className="btn btn-save" onClick={this.onClickFindCatalog}>Buscar</Button>
          </div>
          <div className="col-md-4 col-xs-6 col-pull-right wrapper-btns-manager">

          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_cmpy_msg}</b>
          </div>
        </div>
        {this.renderCatalogMessage()}
        <div style={{ display: "flex", alignItems: "end", flexDirection: "row-reverse" }}>
          <div style={{ marginLeft: "20px" }}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectCompanies = ref}
              onOptionSelected={(option) => this.onOptionSelectedCompanies(option)}
              onCbClick={this.onCbCompaniesClick}
              options={this.smartSelectCompaniesOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsCatalog()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField={'_id'}
                    loading={this.state.loading}
                    data={companies}
                    columns={this.columns}
                    selectRow={selectRow}
                    noDataIndication={this.state.table_cmpy_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
      </div>
    )
  }

  renderPreUsersMessage() {
    if((this.state.selectedPreusers.length === this.state.preUsers.length) && this.state.preUsers.length !== 0 && this.state.preUserQuery === '') {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todos</b> los usuarios no confirmados
        </div>
      )
    } else if(this.state.selectedPreusers.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedPreusers.length}</b> usuario no confirmado
        </div>
      )
    } else if(this.state.selectedPreusers.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedPreusers.length}</b> usuarios no confirmados
        </div>
      )
    } else return null;
  }

  renderListPreUsers(){
    const preusers = this.state.preUsers;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        headerStyle:(column,colIndex) => {
          return {width:'80px'}
        },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          return(
            <KImage src={cellContent} type='user' alt="user_image"/>
          )
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'roles',
        sort: true,
        text: 'Roles',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          const roles = cellContent.map(function(rol){
            if(rol === 'partner'){
              return 'Emprendedor'
            }else if(rol === 'mentor'){
              return 'Aliado'
            }else if(rol === 'manager'){
              return 'Gestor'
            }else if(rol === 'facilitator'){
              return 'Director'
            }else{
              return 'No especificado'
            }
          });

          return(
            <span>{ roles.map((rol) =>
              <span>{rol}<br></br></span>
              )}
            </span>
          )
        }
      },
      {
        dataField: 'created_at',
        headerStyle: { minWidth: '221px' },
        text: 'Fecha de invitación',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter:this.formatDate
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page)*/
        if(page === '>') {
          this.setState({preUsersPage: this.state.preUsersPage + 1})
        } else if(page === '<') {
          this.setState({preUsersPage: this.state.preUsersPage - 1})
        } else {
          this.setState({preUsersPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: preusers.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.preUsersPage,
      withFirstAndLast: false
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectPreusers,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectPreusers = ref}
              onOptionSelected={(option) => this.onOptionSelectedPreusers(option)}
              onCbClick={this.onCbPreusersClick}
              cbState={this.state.cb_state_preusers}
              options={this.smartSelectPreusersOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedPreusers.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };
    return(
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
            ref={ref => this.fi_pre_user_query = ref}
            id="buscar"
            label="Buscar "
            model="preUserQuery"
            value={this.state.preUserQuery}
            showCounter={false}
            initialValue={''}
            onChange={ change => { this.onChangeForm(change) } }
            placeholder="Escribe el correo del usuario"
            onEnterPressed={() => {this.onClickFindPreUser()}}
            />
          </div>
          <div className="col-md-2">
            <Button style={{marginTop:'32px'}} className="btn btn-save" onClick={this.onClickFindPreUser}>Buscar</Button>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_pre_msg}</b>
          </div>
        </div>
        {this.renderPreUsersMessage()}
        <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
          <div style={{marginLeft:"20px"}}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectPreusers = ref}
              onOptionSelected={(option) => this.onOptionSelectedPreusers(option)}
              onCbClick={this.onCbPreusersClick}
              options={this.smartSelectPreusersOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsPreusers()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                  <BootstrapTable
                    bootstrap4
                    striped
                    caption={<TableCaption />}
                    keyField='_id'
                    loading={this.state.loading}
                    data={ preusers }
                    columns={ columns }
                    selectRow={ selectRow }
                    noDataIndication={this.state.table_pre_msg}
                    wrapperClasses="m-t-20 table-responsive"
                    classes="react-bootstrap-table"
                    { ...paginationTableProps }
                  />
                  <PaginationListStandalone
                    { ...paginationProps }
                  />
                </div>
                )
          }
        </PaginationProvider>
      </div>
    )
  }
  formatDate(date){
    if(date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }
  disableFormatter(cell,row,rowIndex,formatExtraData){
    let color = cell ? '#ff5050' : '#707cd2';
    return(
      <i className={"mdi " + formatExtraData[cell]} style={{color: color}}></i>
    )
  }

  renderFacilitatorsMessage() {
    if((this.state.selectedFacilitators.length === this.state.facilitators.length) && this.state.facilitators.length !== 0) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todos</b> los directores
        </div>
      )
    } else if(this.state.selectedFacilitators.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedFacilitators.length}</b> director
        </div>
      )
    } else if(this.state.selectedFacilitators.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedFacilitators.length}</b> directores
        </div>
      )
    } else return null;
  }

  renderFacilitatorsList(){
    const users = this.state.facilitators;
    const columns = [
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        headerStyle:(column,colIndex) => {
          return {width:'80px'}
        },
        formatter: (cellContent,row) => {
          return(
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
            </div>
          )
        }
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '142px' },
        text: 'Nombre',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';
          if(cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'facilitator_programs_permissions',
        text: 'Programas',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent) => {
          const programs = cellContent.filter(program => program.access === 'allow');

          return(
            <span>{ programs.map((program) =>
              {
                const p = program.program || {};
                return (<span>{p.name}<br></br></span>);
              }


              )}
            </span>
          )
        }
      },
      {
          dataField: 'is_disabled',
          text: 'Habilitado',
          headerStyle: {padding: '16px 12px' },
          style: {
            verticalAlign:'middle'
          },
          formatter: this.disableFormatter,
          formatExtraData: {
            true: 'mdi-close',
            false: 'mdi-check',
            undefined:'mdi-close',
          },
        },
        {
          dataField: '_id',
          style: {
            verticalAlign:'middle'
          },
          text: '',
          formatter:(cell,row) => {
            return (<div className="btn btn-link" onClick={() => this.openProgramModal(cell,'facilitator')}>Modificar programas</div>)
          }
        },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        /*onPageChange(page);*/
        if(page === '>') {
          this.setState({facilitatorsPage: this.state.facilitatorsPage + 1})
        } else if(page === '<') {
          this.setState({facilitatorsPage: this.state.facilitatorsPage - 1})
        } else {
          this.setState({facilitatorsPage: page})
        }
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: users.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: this.state.facilitatorsPage
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectFacilitators,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectFacilitators = ref}
              onOptionSelected={(option) => this.onOptionSelectedFacilitators(option)}
              onCbClick={this.onCbFacilitatorsClick}
              cbState={this.state.cb_state_facilitators}
              options={this.smartSelectFacilitatorsOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedFacilitators.map( u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };

    return(
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              id="buscar"
              label="Buscar "
              model="fQuery"
              value={this.state.fQuery}
              showCounter={false}
              initialValue={''}
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Escribe el nombre o correo del usuario"
              onEnterPressed={() => {this.onClickFindFacilitators()}}
            />
          </div>
          <div className="col-md-3">
            <Button style={{marginTop:'32px'}} className="btn btn-save" onClick={this.onClickFindFacilitators}>Buscar</Button>
          </div>
          <div className="col-md-3">
            <Button style={{marginTop:'32px'}} className="btn btn-save float-right" onClick={() => this.handleFacilitatorModal(true)}>Invitar director</Button>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_facilitators_msg}</b>
          </div>
        </div>
        {this.renderFacilitatorsMessage()}
        <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
          <div style={{marginLeft:"20px"}}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectFacilitators = ref}
              onOptionSelected={(option) => this.onOptionSelectedFacilitators(option)}
              onCbClick={this.onCbFacilitatorsClick}
              options={this.smartSelectFacilitatorsOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsFacilitators()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
              <div>
                <PaginationListStandalone
                  { ...paginationProps }
                />
                <BootstrapTable
                  bootstrap4
                  striped
                  caption={<TableCaption />}
                  keyField='_id'
                  loading={this.state.loading}
                  data={ users }
                  columns={ columns }
                  selectRow={ selectRow }
                  noDataIndication={this.state.table_fa_msg}
                  wrapperClasses="m-t-20 table-responsive"
                  classes="react-bootstrap-table"
                  overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(203, 255, 255, 0.5)'}) } })}
                  { ...paginationTableProps }
                />
                <PaginationListStandalone
                  { ...paginationProps }
                />
              </div>
              )
        }
       </PaginationProvider>
      </div>
    )
  }

  renderManagersMessage() {
    if((this.state.selectedManagers.length === this.state.managers.length) && this.state.managers.length !== 0) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>todos</b> los gestores
        </div>
      )
    } else if(this.state.selectedManagers.length === 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se ha seleccionado <b>{this.state.selectedManagers.length}</b> gestor
        </div>
      )
    } else if(this.state.selectedManagers.length > 1) {
      return(
        <div className="p-3 mb-2 bg-light text-dark text-center">
          Se han seleccionado <b>{this.state.selectedManagers.length}</b> gestores
        </div>
      )
    } else return null;
  }

  renderManagersList() {
    const managers = this.state.managers;
    const columns = [
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        headerStyle: (column, colIndex) => {
          return { width: '80px' }
        },
        formatter: (cellContent, row) => {
          return (
            <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id, 'profile')}>
              <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image" />
            </div>
          )
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {padding: '16px 12px' },
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'name',
        headerStyle: { minWidth: '142px' },
        text: 'Nombre(s)',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
      {
        dataField: 'last_name_father',
        headerStyle: { minWidth: '180px' },
        text: 'A. Paterno',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
      {
        dataField: 'last_name_mother',
        headerStyle: { minWidth: '180px' },
        text: 'A. Materno',
        style: {
          verticalAlign:'middle'
        },
        sort: true
      },
    ];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
          <button 
            className={ `my-3 mr-1 btn ${active ? 'btn-save' : 'btn-secondary'}` }
            onClick={ handleClick }
          >
            { page }
          </button>
      );
    };

    const options = {
      custom: true,
      totalSize: managers.length,
      pageButtonRenderer,
      sizePerPage: 25,
      page: 1
    };

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleOnSelectManagers,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle: { paddingLeft: '0.5rem', width: '60px' },
      selectColumnStyle: { padding: '0.5rem', width: '60px' },
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
              ref={ref => this.kTableSmartSelectManagers = ref}
              onOptionSelected={(option) => this.onOptionSelectedManagers(option)}
              onCbClick={this.onCbManagersClick}
              cbState={this.state.cb_state_managers}
              options={this.smartSelectManagersOptions}>
            </KTableSmartSelect>
        </div>
      ),
      selected: this.state.selectedManagers.map(u => u._id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={mode} {...rest} />
          <label></label>
        </label>
      )
    };

    return (
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <FormInput
              id="buscar"
              label="Buscar "
              model="managersQuery"
              value={this.state.managersQuery}
              showCounter={false}
              initialValue={''}
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Escribe el nombre o correo del usuario"
              onEnterPressed={() => { this.onClickFindManager() }}
            />
          </div>
          <div className="col-md-3">
            <Button style={{ marginTop: '32px' }} className="btn btn-save" onClick={this.onClickFindManager}>Buscar</Button>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <b className='m-t-10 m-b-10'>{this.state.search_managers_msg}</b>
          </div>
        </div>
        {this.renderManagersMessage()}
        <div style={{ display: "flex", alignItems: "end", flexDirection: "row-reverse" }}>
          <div style={{ marginLeft: "20px" }}>
            {/* <KTableSmartSelect
              ref={ref => this.kTableSmartSelectManagers = ref}
              onOptionSelected={(option) => this.onOptionSelectedManagers(option)}
              onCbClick={this.onCbManagersClick}
              options={this.smartSelectManagersOptions}>
            </KTableSmartSelect> */}
          </div>
          {this.renderActionButtonsManagers()}
        </div>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
              <div>
                <PaginationListStandalone
                  { ...paginationProps }
                />
                <BootstrapTable
                  bootstrap4
                  striped
                  caption={<TableCaption />}
                  keyField='_id'
                  loading={this.state.loading}
                  data={managers}
                  columns={columns}
                  selectRow={selectRow}
                  noDataIndication={this.state.table_msg}
                  wrapperClasses="m-t-20 table-responsive"
                  classes="react-bootstrap-table"
                  overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(203, 255, 255, 0.5)' }) } })}
                  { ...paginationTableProps }
                />
                <PaginationListStandalone
                  { ...paginationProps }
                />
              </div>
              )
        }
        </PaginationProvider>
      </div>
    )
  }
  renderTabs(){
    if(this.state.me.roles.includes("manager")){
      return(
        <div className="card">
          <Tab.Container
            mountOnEnter={ true }
            unmountOnExit={ true }
            defaultActiveKey="add_users">
            <Nav className="nav" bsPrefix='tabCustom' >
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="add_users" id="add_users" onClick={() => this.clearSelect('add')}>Agregar</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_users" id='list_users'>Usuarios</Nav.Link>
              </Nav.Item> */}
              <Nav.Item className="nav-item">
              <Nav.Link className="nav-link" eventKey="list_partners" id='list_partners' onClick={() => this.clearSelect('partners')}>Emprendedores</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
              <Nav.Link className="nav-link" eventKey="list_mentors" id='list_mentors' onClick={() => this.clearSelect('mentors')}>Aliados</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_facilitators" id='list_facilitators' onClick={() => this.clearSelect('facilitators')}>Directores</Nav.Link>
              </Nav.Item>
              {/*<Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_managers" id='list_managers' onClick={() => this.clearSelect('managers')}>Gestores</Nav.Link>
              </Nav.Item>*/}
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_companies" id='list_companies' onClick={() => this.clearSelect('projects')}>Empresas</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_catalog" id='list_catalog' onClick={() => this.clearSelect('catalog')}>Directorio de empresas</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_pre_users" id='list_pre_users' onClick={() => this.clearSelect('preusers')}>No confirmados</Nav.Link>
              </Nav.Item>
            </Nav>
  
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="add_users">
                {this.renderAddUserForm()}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="list_users">
                {this.renderListUsers()}
              </Tab.Pane> */}
              <Tab.Pane eventKey="list_partners">
                {this.renderListPartners()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_mentors">
                {this.renderListMentors()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_facilitators">
                {this.renderFacilitatorsList()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_managers">
                {this.renderManagersList()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_companies">
                {this.renderCompanyList()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_catalog">
                {this.renderCatalogList()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_pre_users">
                {this.renderListPreUsers()}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )
    }else{
      return(
        <div className="card">
          <Tab.Container
            mountOnEnter={ true }
            unmountOnExit={ true }
            defaultActiveKey="add_users">
            <Nav className="nav" bsPrefix='tabCustom' >
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="add_users" id="add_users" onClick={() => this.clearSelect('add')}>Agregar</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_partners" id='list_partners' onClick={() => this.clearSelect('partners')}>Emprendedores</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_mentors" id='list_mentors' onClick={() => this.clearSelect('mentors')}>Aliados</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link" eventKey="list_companies" id='list_companies' onClick={() => this.clearSelect('projects')}>Empresas</Nav.Link>
              </Nav.Item>
            </Nav>
  
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="add_users">
                {this.renderAddUserForm()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_partners">
                {this.renderListPartners()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_mentors">
                {this.renderListMentors()}
              </Tab.Pane>
              <Tab.Pane eventKey="list_companies">
                {this.renderCompanyList()}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )
    } 
  }

  render () {
    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Usuarios y empresas</h3>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    {this.renderTabs()}
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}
            <KAssignMultipleProgramModal showModal={this.state.showMultipleProgramModal}
                                         users={this.state.assignUsers}
                                         closeModal={this.handleMultipleProgramModal}
                                         onAddProgram={this.onAssignedMultiplePrograms}>
            </KAssignMultipleProgramModal>
            <KAssignProgramModal showModal={this.state.showProgramModal}
                                 user={this.state.userSelected}
                                 programsSelected={this.state.programsSelected}
                                 onUserAssign={this.getUsers}
                                 closeModal={this.handleProgramModal}
                                 onAddProgram={this.getUsers}
                                 userType={this.state.userType}>
            </KAssignProgramModal>
            {/*refresh after you add a program to the user*/}
            {/*is the user to be assign is a mentor, partner or facilitator*/}

           <KAddFacilitatorModal showModal={this.state.showFacilitatorModal}
                                 closeModal={this.handleFacilitatorModal}></KAddFacilitatorModal>
           <KProfileSidebar
             showSidebar={this.state.isSidebarProfileOpen}
             closeSidebar={(show) => this.handleSidebarProfile(show)}
             user_id={this.state.userSelectedId}
             activeKey={this.state.userProfileActiveKey}
             ></KProfileSidebar>
          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default ManagerUserView;
