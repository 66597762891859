/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx> & Israel Arellano <israel@startuplab.mx>, May 2021
 */

// Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

// Custom styling
import '../scss/pages/_style.css';
import './../scss/pages/topbar.scss';


class TopSideBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentPath: '',
    };
    this.createCompany = this.createCompany.bind(this);
  }

  componentDidMount(){
    this.setState({currentPath: this.props.history.location.pathname})
  }

  //If the user doesn't have his data complete, he can't create a business.
  createCompany(){
    this.props.history.push('/create_company')
  }

  renderAddCompanyButton(){
    let user = this.props.user;
    if(!user.hasOwnProperty("roles")){
      return (null);
    }
    if(this.props.user.roles.includes("partner") || this.props.user.roles.includes("mentor")){
      if(!this.props.visible) {
        return(
          <li className="nav-item d-none d-md-block">
            <div className="sidebar-btn" style={{textAlign: 'center'}}>
              <button type="button" className="btn btn-success btn-save" onClick={this.createCompany}>
                Agregar empresa
              </button>
            </div>
          </li>
        )
      } else {
        return(
          <li className="nav-item d-none d-md-block">
            <div className="sidebar-btn" style={{textAlign: 'center'}}>
              <button type="button" className="btn btn-success btn-save" onClick={this.createCompany}>
                <i className="mdi mdi-plus"></i>
              </button>
            </div>
          </li>
        )
      }
    }else{
      return(
        null
      )
    }
  }

  renderMenu(){
    const user = this.props.user;
    //If the user is not identified, remove all
    if(!user.hasOwnProperty("roles")){ 
      return(
        <ul></ul>
      )
    }
    else if(user.roles.includes('facilitator') || user.roles.includes('manager')){
      return(
        <ul className="navbar-nav me-auto mr-auto">
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath === '/manager' ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager"}>
              <i className="mdi mdi-account"></i> Panel de control
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/users') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/users"}>
              <i className="mdi mdi-account-multiple"></i> Usuarios
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/programs') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/programs"}>
              <i className="mdi mdi-book"></i> Programas
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/stats') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/stats"}>
              <i className="mdi mdi-folder-multiple"></i> Datos
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/certificate_editor') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/certificate_editor"}>
              <i className="mdi mdi-certificate"></i> Certificados
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/academy') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/academy"}>
              <i className="mdi mdi-school"></i> Academia
            </Link>
          </li>
        </ul>
      )
    }else{
      return(
        <ul className="navbar-nav me-auto mr-auto">
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/companies') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/companies"}>
              <i className="mdi mdi-lightbulb-on"></i> Inicio
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/mentors') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/mentors"}>
              <i className="mdi mdi-account-star-variant"></i> Aliados
            </Link>
          </li>
          <li className="nav-item d-none d-md-block">
            {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/services"}>
              <i className="mdi mdi-briefcase"></i> Servicios
            </Link> */}
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/progress_report') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/progress_report"}>
              <i className="mdi mdi-format-list-numbers"></i> Avances
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/resources') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/resources"}>
              <i className="mdi mdi-folder"></i> Recursos
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/courses') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/courses"}>
              <i className="mdi mdi-school"></i> Academia
            </Link>
          </li>
        </ul>
      )
    }
  }

  renderCreoMenu(){
    const user = this.props.user;
    //If the user is not identified, remove all
    if(!user.hasOwnProperty("roles")){ 
      return(
        <ul></ul>
      )
    }
    else if(user.roles.includes('facilitator') || user.roles.includes('manager')){
      return(
        <ul className="navbar-nav me-auto mr-auto">
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath === '/manager' ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager"}>
              <i className="mdi mdi-account"></i> Panel de control
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/users') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/users"}>
              <i className="mdi mdi-account-multiple"></i> Usuarios
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/programs') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/programs"}>
              <i className="mdi mdi-book"></i> Programas
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/stats') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/stats"}>
              <i className="mdi mdi-folder-multiple"></i> Datos
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/certificate_editor') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/certificate_editor"}>
              <i className="mdi mdi-certificate"></i> Certificados
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/manager/academy') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/manager/academy"}>
              <i className="mdi mdi-school"></i> Academia
            </Link>
          </li>
        </ul>
      )
    }else{
      return(
        <ul className="navbar-nav me-auto mr-auto">
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/agenda') ? 'selected-tab':'')}>
            {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/agenda"}>
              <i className="mdi mdi-book"></i> Inicio
            </Link> */}
          </li>
          <li className="nav-item d-none d-md-block">
            {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/services"}>
              <i className="mdi mdi-briefcase"></i> Servicios
            </Link> */}
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/live') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/live"}>
              <i className="mdi mdi-play-circle"></i> Inicio
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/progress_report') ? 'selected-tab':'')}>
            {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/progress_report"}>
              <i className="mdi mdi-format-list-numbers"></i> Avances
            </Link> */}
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/sponsors') ? 'selected-tab':'')}>
            {/*<Link className="nav-link sidebartoggler waves-effect waves-light" to={"/sponsors"}>
              <i className="mdi mdi-human-greeting"></i> Aliados
            </Link>*/}
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/companies') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/companies"}>
              <i className="mdi mdi-lightbulb-on"></i> Tu empresa
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/mentors') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/mentors"}>
              <i className="mdi mdi-account-star-variant"></i> Aliados
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/resources') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/resources"}>
              <i className="mdi mdi-folder"></i> Recursos
            </Link>
          </li>
          <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/courses') ? 'selected-tab':'')}>
            <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/courses"}>
              <i className="mdi mdi-school"></i> Academia
            </Link>
          </li>
          {/*<li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/financing') ? 'selected-tab':'')}>*/}
          {/*  <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/financing"}>*/}
          {/*    <i className="mdi mdi-cash"></i> Feria financiera*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>
      )
    }
  }

  renderFreeMenu(){
    return(
      <ul className="navbar-nav me-auto mr-auto">
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/agenda_creo') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/agenda_creo"}>
            <i className="mdi mdi-book"></i> Inicio
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath === '/live_free' ||this.state.currentPath === '/' ? 'selected-tab':'')}>
          <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/live_free"}>
            <i className="mdi mdi-play-circle"></i> Inicio
          </Link>
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/sponsors') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/sponsors"}>
            <i className="mdi mdi-human-greeting"></i> Aliados
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/sign-up') ? 'selected-tab':'')}>
          <div className="nav-link sidebartoggler waves-effect waves-light"  onClick={() => this.props.onClickSidebar()}>
            <i className="mdi mdi-lock"></i> Desbloquea todo el contenido de CREO MX
          </div>
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/companies') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/companies"}>
            <i className="mdi mdi-lightbulb-on"></i> Tu empresa
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/mentors') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/mentors"}>
            <i className="mdi mdi-account-star-variant"></i> Consultorio
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/resources') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/resources"}>
            <i className="mdi mdi-folder"></i> Recursos
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/courses') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/courses"}>
            <i className="mdi mdi-school"></i> Academia
          </Link> */}
        </li>
        <li className={"nav-item d-none d-md-block " + (this.state.currentPath.includes( '/financing') ? 'selected-tab':'')}>
          {/* <Link className="nav-link sidebartoggler waves-effect waves-light" to={"/financing"}>
            <i className="mdi mdi-cash"></i> Academia
          </Link> */}
        </li>
      </ul>
    )
}

  render() {
    if(this.props.hideTopBar){
      return null
    }else{
      if(this.props.freeMode){
        return (
          <header className="topbar topsidebar topsidebar-dark">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
              <div className="navbar-collapse">
                  {this.renderFreeMenu()}
                  {/* {this.renderAddCompanyButton()} */}
              </div>
            </nav>
          </header>
        );
      }else{
        return (
          <header className="topbar topsidebar topsidebar-dark">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
              <div className="navbar-collapse">
                  {this.renderCreoMenu()}
                  {/* {this.renderAddCompanyButton()} */}
              </div>
            </nav>
          </header>
        );
      }
    }
  }
}

TopSideBar.propTypes = {
  hideTopBar: PropTypes.bool,
  freeMode: PropTypes.bool,
  user: PropTypes.object,
  onClickSidebar: PropTypes.func,
};

TopSideBar.defaultProps = {
  hideTopBar : false,
  freeMode : false,
  user:{
      _id                     :null,
      name                    :null,
      email                   :null,
      created_at              :null,
      updatedAt               :null,
      source                  :null,
      uid                     :null,
      profileUrlThumbnail     :null,
      goverment_id_curp       :null,
      social_linkedin         :null,
      social_facebook         :null,
      social_twitter          :null,
      social_other            :null,
      goverment_id_rfc        :null,
      dateOfBirth             :null,
      phone                   :null,
      gender                  :null,
      roles                   :null,
  },
  onClickSidebar: () => {},
};
export default withRouter(TopSideBar);
