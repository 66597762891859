/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import { Tab, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom"

// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';

//Components
import Header from '../../components/Header';
import TopSideBar from '../../components/TopSideBar';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import KCompanyList from '../../components/KCompanyList';
import KSendContactEmail from '../../components/KSendContactEmail';
import { isUserProfileComplete } from '../../utils/Utils.js';
import MentoredProgramsPicker from '../../components/MentoredProgramsPicker.js';
import Swal from 'sweetalert2';

//Other imports
import { orderBy } from 'lodash';

class CompaniesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      me: {},
      partner_invitations: [],
      mentored_invitations: [],
      companies: [],
      companiesRatings: [],
      mentored_companies: [],
      display_mentored_companies: [],
      suggested_companies: [],
      selectedId: [0],
      programName: [0],
      selectedIdRating: [0],
      programNameRating: [0],
      partner_invitationsLoadState: "idle",
      mentored_invitationsLoadState: "idle",
      companiesLoadState: "idle",
      mentoredLoadState: "idle",
      companiesRatingsLoadState: "idle",
      companiesSuggestLoadState: "idle",
      keyCompaniesTabs: "my_companies",
      emailModal: false,
    };
    this.handleResizeBar         = this.handleResizeBar.bind(this);
    this.handleHeaderResize      = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile   = this.redirectToEditProfile.bind(this);
    this.onProgramSelected       = this.onProgramSelected.bind(this);
    this.onProgramSelectedRating = this.onProgramSelectedRating.bind(this);
    this.onSuggestionSelected    = this.onSuggestionSelected.bind(this);
    this.handleSelectedTab       = this.handleSelectedTab.bind(this);
    this.getCompaniesByProgram   = this.getCompaniesByProgram.bind(this);
    this.onRatingSelected        = this.onRatingSelected.bind(this);
    this.openEmailModal          = this.openEmailModal.bind(this);
  }
  redirectToEditProfile() {
    this.props.history.push("/profile/edit");
  }

  componentDidMount() {
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        if (!isUserProfileComplete(res.user)) {
          this.redirectToEditProfile();
        } else {
          this.setState({
            me: res.user,
            authorized: true,
          });
        }
        //get companies
        KomunidadApi.getCompanies().then((res) => {
          if (res.success) {
            if (res.hasOwnProperty("companies")) {

              let invitations = res.companies.filter(company => company.invitation_status === 'invited');
              let companies = res.companies.filter(company => company.invitation_status !== 'invited');

              this.setState({
                companies: this.sortProjectsEnabled(companies, false),
                companiesLoadState: 'success',
                partner_invitations: this.sortProjectsEnabled(invitations, false),
                partner_invitationsLoadState: 'success'
              });
            }
            if (this.state.companies.length === 0 && this.state?.me?.roles?.includes('mentor')) {
              this.setState({ keyCompaniesTabs: 'mentored_companies' });
            }
          } else {
            this.setState({
              companies: [],
              companiesLoadState: 'failed',
              partner_invitations: [],
              partner_invitationsLoadState: 'failed'
            });
          }
        });
        //get mentored companies
        KomunidadApi.getMentoredCompanies().then((res) => {
          if (res.success) {
            if (res.hasOwnProperty("companies")) {

              let invitations = res.companies.filter(company => company.invitation_status === 'invited');
              let companies = res.companies.filter(company => company.invitation_status !== 'invited');

              this.setState({
                mentored_companies: this.sortProjectsEnabled(companies, true),
                mentoredLoadState: 'success',
                mentored_invitations: this.sortProjectsEnabled(invitations, true),
                mentored_invitationsLoadState: 'success'
              }, () => {
                this.getCompaniesByProgram(this.state.selectedId[0]);
              });
            }
          } else {
            this.setState({
              mentored_companies: [],
              mentoredLoadState: 'failed',
              mentored_invitations: [],
              mentored_invitationsLoadState: 'failed'
            });
          }
        });
      } else {
        //redirect profile
        this.props.history.push("/profile");
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  handleSelectedTab(key) {
    if (this.state.keyCompaniesTabs === "rated_companies") {
      this.getAllCompaniesByProgram(this.state.selectedIdRating);
    }
    this.setState({ keyCompaniesTabs: key });
  }

  sortProjectsEnabled(projects, isMentor) {

    projects = orderBy(projects, ['created_at'], ['desc']);

    if (!isMentor) {
      projects = projects.filter((project) => project.is_disabled === false);
    }

    return projects;

  }
  cleanSuggestions(projects, mentored_projects) {
    // Array of metored projects_ids
    mentored_projects = mentored_projects.map((c) => c._id);
    // Remove my companies
    projects = projects.filter((project) => !project.partners.map((u) => u.user).includes(this.state.me._id));
    // Remove my mentored companies
    projects = projects.filter((project) => !mentored_projects.includes(project._id));

    return projects;
  }

  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true,
    });
  }
  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if (method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if (windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }
  renderCompanies() {
    const titles = [
      "Logo",
      "Nombre",
      "Fecha"
    ]
    if (
      this.state.me.roles.includes("partner") ||
      this.state.me.roles.includes("mentor")
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <KCompanyList
                    companies={this.state.companies}
                    titles={titles}
                    user={this.state.me}
                    viewLabel={"Ver / Editar"}
                    requestStatus={this.state.companiesLoadState}
                    emptyListTitle={"¡Te damos la bienvenida a komunidad!"}
                    emptyListSubtitle={
                      " para comenzar, si tienes dudas puedes consultar:"
                    }
                    supportLink={"https://komunidad.freshdesk.com/support/home"}
                    buttonLink={"/create_company"}
                    buttonText={"Agrega una startup"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  onProgramSelected(id, name) {
    let programId = id[0];
    let programName = name;
    this.setState({
      selectedId: id,
      programName: programName,
    });
    return this.getCompaniesByProgram(programId);
  }
  onProgramSelectedRating(id, name) {
    let programId = id[0];
    let programName = name;
    this.setState({
      selectedIdRating: id,
      programNameRating: programName,
    });
    return this.getAllCompaniesByProgram(programId);
  }
  getAllCompaniesByProgram(_id) {
    KomunidadApi.getProgram(_id).then((res) => {
      if (res.success) {
        this.setState({
          companiesRatings: this.sortProjectsEnabled(res.companies, false),
          companiesRatingsLoadState: 'success'
        });
      }
      else {
        this.setState({
          companiesRatingsLoadState: 'failed'
        });
      }
    });
  }
  getCompaniesByProgram(programId) {
    let m_companies = this.state.mentored_companies.filter((c) => {
      if (c.programs.length !== 0) {
        for (let i = 0; i < c.programs.length; i++) {
          if (c.programs[i].program._id === programId) {
            return c.programs[i].program._id;
          }
        }
      }
    });
    this.setState({
      display_mentored_companies: m_companies
    }, () => {
      this.getSuggestedCompaniesByProgram(programId);
    });
  }

  getSuggestedCompaniesByProgram(programId) {
    let m_companies = this.state.display_mentored_companies;
    KomunidadApi.getProgram(programId).then((res) => {
      if (res.success) {
        let suggested_companies = this.sortProjectsEnabled(res.companies, false);
        suggested_companies = this.cleanSuggestions(suggested_companies, m_companies)
        this.setState({
          suggested_companies: suggested_companies,
          companiesSuggestLoadState: 'success'
        });
      }
      else {
        this.setState({
          companiesSuggestLoadState: 'failed'
        });
      }
    });
  }

  renderMentoredCompanies() {
    const titles = [
      "Logo",
      "Nombre",
      "Fecha"
    ]
    if (this.state.mentored_companies.length > 0 || this.state.me.roles.includes('mentor')) {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title font-bold"><span className="lstick"></span>Selecciona un programa para ver las startups en las que eres aliado</h5>
                  <div className="form-group">
                    <div className="">
                      <MentoredProgramsPicker
                        onSelect={this.onProgramSelected}
                        joinedPrograms={this.state.me.mentored_programs}
                        multipleProgramSelected={this.state.selectedId}
                      />
                    </div>
                  </div>
                  <KCompanyList
                    companies={this.state.display_mentored_companies}
                    titles={titles}
                    user={this.state.me}
                    viewLabel={"Comentar"}
                    requestStatus={this.state.mentoredLoadState}
                    emptyListTitle={
                      "Aun no eres aliado de ningúna startup en el programa"
                    }
                    isMentoring={true}
                  />
                  {this.state.suggested_companies !== [] &&
                    <div className="m-t-40">
                      <h5 className="card-title font-bold"><span className="lstick"></span>Startups del programa en los que puedes ser aliado</h5>
                      <KCompanyList
                        companies={this.state.suggested_companies}
                        titles={titles}
                        user={this.state.me}
                        viewLabel={"Agregar"}
                        requestStatus={this.state.companiesSuggestLoadState}
                        emptyListTitle={
                          "El programa todavía no tiene startups inscritas"
                        }
                        isMentoring={true}
                        isMentoringSuggestion={true}
                        onSuggestionSelected={this.onSuggestionSelected}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onRatingSelected(value) {
    KomunidadApi.createCompanyRating(value.rating, value.companyId, this.state.selectedIdRating[0]).then((res) => {
      if (res.success) {
        Swal.fire({
          type: 'success',
          title: '¡Calificación registrada!',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'Ups..',
          text: 'Hubo un error el subir tu calificación',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  onSuggestionSelected(company) {
    KomunidadApi.selfInviteMentor(this.state.selectedId, company._id).then((res) => {
      if (res.success) {
        Swal.fire({
          type: 'success',
          title: '¡Te uniste exitosamente a la startup!',
          text: 'Ahora puedes hacer comentarios en la startup',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
        this.props.history.push("/companies/" + company._id);
      } else {
        Swal.fire({
          type: "error",
          title: "Hubo un error al unirte a la startup:",
          text: res.error_es,
          customClass: {
            confirmButton: "swalBtnTeal",
            title: "swal-custom-title",
          },
          footer: "",
        });
      }
    });
  }

  renderRatedCompanies() {
    const titles = [
      "Logo",
      "Nombre",
      "Fecha",
      "Calificación"
    ]
    if (this.state.mentored_companies.length > 0 || this.state.me.roles.includes('mentor')) {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title font-bold"><span className="lstick"></span>Selecciona un programa para calificar las startups pertenecientes al programa</h5>
                  <div className="form-group">
                    <div className="">
                      <MentoredProgramsPicker
                        onSelect={this.onProgramSelectedRating}
                        joinedPrograms={this.state.me.mentored_programs}
                        multipleProgramSelected={this.state.selectedId}
                      />
                    </div>
                  </div>
                  <KCompanyList
                    companies={this.state.companiesRatings}
                    titles={titles}
                    user={this.state.me}
                    viewLabel={"Comentar"}
                    ratingsEnabled={true}
                    managerView={true}
                    onRatingSelected={this.onRatingSelected}
                    requestStatus={this.state.companiesRatingsLoadState}
                    emptyListTitle={
                      "Selecciona un programa para ver las startups en las que eres aliado"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMentoredCompaniesTab() {
    if (this.state.mentored_companies.length > 0 || this.state.me.roles.includes('mentor')) {
      return (
        <Nav.Item className="nav-item">
          <Nav.Link
            className="nav-link"
            eventKey="mentored_companies"
            id="mentored_companies"
          >
            Asesorar
          </Nav.Link>
        </Nav.Item>
      );
    } else return null;
  }

  renderRatedCompaniesTab() {
    if (this.state.mentored_companies.length > 0 || this.state.me.roles.includes('mentor')) {
      return (
        <Nav.Item className="nav-item">
          <Nav.Link
            className="nav-link"
            eventKey="rated_companies"
            id="rated_companies"
          >
            Calificar
          </Nav.Link>
        </Nav.Item>
      );
    } else return null;
  }

  renderInvitationsTab() {
    if (this.state.mentored_invitations.length > 0 || this.state.partner_invitations.length) {
      return (
        <Nav.Item className="nav-item">
          <Nav.Link
            className="nav-link"
            eventKey="invitations"
            id="invitations"
          >
            Invitaciones
          </Nav.Link>
        </Nav.Item>
      );
    } else return null;
  }

  renderMentorInvitations() {
    const titles = [
      "Logo",
      "Nombre",
      "Programa",
      "Fecha",
    ]
    if (this.state.mentored_invitations.length > 0) {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title font-bold"><span className="lstick"></span>Invitaciones como aliado:</h5>
                  <KCompanyList companies={this.state.mentored_invitations}
                    titles={titles}
                    user={this.state.me}
                    emptyListTitle={'No se encontraron invitaciones de aliado'}
                    requestStatus={this.state.mentored_invitationsLoadState}
                    invitationMode={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }

  renderPartnerInvitations() {
    const titles = [
      "Logo",
      "Nombre",
      "Programa",
      "Fecha",
    ]
    if (this.state.partner_invitations.length > 0) {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title font-bold"><span className="lstick"></span>Invitaciones como emprendedor:</h5>
                  <KCompanyList companies={this.state.partner_invitations}
                    titles={titles}
                    user={this.state.me}
                    emptyListTitle={'No se encontraron invitaciones de aliado'}
                    requestStatus={this.state.partner_invitationsLoadState}
                    invitationMode={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }

  renderInvitations() {
    return (
      <div>
        {this.renderPartnerInvitations()}
        {this.renderMentorInvitations()}
      </div>
    )
  }

  openEmailModal(value) {
    this.setState({ emailModal: value });
  }

  renderHelp() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white p-3">Da de alta tu negocio o proyecto</div>
            <div className="card-body">
              <div className="card-title">
                Agrega tu negocio o empresa. Si estas inscrito en algún programa registralo y visualiza el avance de tu emprendimiento.
                <a class="ml-1" href="https://www.youtube.com/watch?v=6ShFh398864" target="_blank">Ayuda</a>
              </div>
              <Link to="/create_company" className="btn btn-success btn-save">Agregar negocio</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Retroalimentación de aliados</div>
            <div className="card-body">
              <div className="card-title">
                Invita a mentores a que revisen tu empresa o proyecto y recibe retroalimentación puntual para el éxito de tu negocio.
                <a class="ml-1" href="https://www.youtube.com/watch?v=7sHtGzi9q-Y" target="_blank">Ayuda</a>
              </div>
              <Link to="/mentors" className="btn btn-success btn-save">Directorio de aliados</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Academia Komunidad</div>
            <div className="card-body">
              <div className="card-title">
                Inscríbete a los cursos de tu programa, revisa los entregables y conoce las mejores herramientas y técnicas para emprender o administrar un negocio.
                <a class="ml-1" href="https://www.youtube.com/watch?v=GxxNWlmRSOs" target="_blank">Ayuda</a>
              </div>
              <Link to="/courses" className="btn btn-success btn-save">Cursos y talleres</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Contacto</div>
            <div className="card-body">
              <div className="card-title">
                Si tienes dudas o preguntas, ponte en contacto con tu director de programa.
              </div>
              <a href="#" onClick={() => this.openEmailModal(true)} className="btn bbtn-success btn-save">Enviar un mensaje</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div
            id="main-wrapper"
            className={this.state.companies.length <= 4 ? "height100" : null}
          >
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar
              user={this.state.me}
              visible={this.state.sidebarStatus}
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open' : '')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-xs-12">
                    <div className="card">
                      <Tab.Container id="tab_companies_view" activeKey={this.state.keyCompaniesTabs} onSelect={this.handleSelectedTab}>
                        <Nav className="nav" bsPrefix="tabCustom">
                          <Nav.Item className="nav-item">
                            <Nav.Link
                              className="nav-link"
                              eventKey="my_companies"
                              id="my_companies"
                            >
                              Mis negocios
                            </Nav.Link>
                          </Nav.Item>
                          {this.renderMentoredCompaniesTab()}
                          {this.renderRatedCompaniesTab()}
                          {this.renderInvitationsTab()}
                        </Nav>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey="my_companies">
                            {this.renderCompanies()}
                          </Tab.Pane>
                          <Tab.Pane eventKey="rated_companies">
                            {this.renderRatedCompanies()}
                          </Tab.Pane>
                          <Tab.Pane eventKey="mentored_companies">
                            {this.renderMentoredCompanies()}
                          </Tab.Pane>
                          <Tab.Pane eventKey="invitations">
                            {this.renderInvitations()}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-xs-12">
                    {this.renderHelp()}
                    { /*<div className="card">
                       <Tab.Container id="tab_help" activeKey="help">
                        <Nav className="nav" bsPrefix="tabCustom">
                            <Nav.Item className="nav-item">
                              <Nav.Link
                                className="nav-link"
                                eventKey="help"
                                id="help"
                              >
                                Ayuda
                              </Nav.Link>
                            </Nav.Item>
                        </Nav>
                          <Tab.Content className="tab-content">
                            <Tab.Pane eventKey="help">
                              {this.renderHelp()}
                            </Tab.Pane>
                          </Tab.Content>
                      </Tab.Container> 
                    </div>*/}
                  </div>
                </div>
              </div>
              {/* End Container fluid  */}
              {/* footer */}
              <Footer />
            </div>
            {/* End Page wrapper  */}
            <KSendContactEmail showModal={this.state.emailModal}
              closeModal={this.openEmailModal}>
            </KSendContactEmail>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default CompaniesView;
