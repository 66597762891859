/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>, October 2020
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//components
import KSpeakerModal from './KSpeakerModal';
import KImage from '../components/KImage';
import parse from 'html-react-parser';
import { Tab, Nav, Button, Modal } from 'react-bootstrap';

/**
*   Display the information of the speaker used by the component [KMentorsGrid](/#kmentorsgrid).
*   Invite a speaker to a company.
*   View the speaker profile.
*/

class KSpeakerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      scheduleModal: false,
      speakerIndex: 0,
    };
    this.handleOpenModal       = this.handleOpenModal.bind(this);
    this.closeModal            = this.closeModal.bind(this);
  }

  handleOpenModal(value, index){
    this.setState({
      showModal: value,
      speakerIndex: index
    });
  }

  closeModal(value) {
    this.setState({
      scheduleModal: value
    })
  }

  getValueSafely(user,property){
    if(user !== null ){
      if(user.hasOwnProperty(property)){
        return user[property];
      }else{
        return "-";
      }
    }
  }

  renderMoreInfo(speaker) {
    if(!speaker.hasOwnProperty('start')){
      return (
        <Nav.Item className="nav-item">
          <Nav.Link className="nav-link p-3" eventKey="speaker" id="speaker">Más información</Nav.Link>
        </Nav.Item>
      )
    } else {
      return null;
    }
  }

  renderInfoTab(speaker) {
    if(!speaker.hasOwnProperty('start')){
      return (
        <Tab.Pane eventKey="speaker">
          {this.renderInfo(speaker,"speaker")}
        </Tab.Pane>
      )
    } else {
      return null;
    }
  }

  renderImages(images,names) {
    return(
        images.map((item,index) =>
          (
            <div className="cursor-pointer" onClick={() => this.handleOpenModal(true, index)}>
              <img src={item} className="rounded-circle me-n2 card-hover border border-2 border-white my-2" width={50} />
              <div className="d-inline-block mx-2 btn-link">{names[index]}</div>
            </div>
          )
        )
    )
  }

  renderNames(speakers) {
    return(
        speakers.map((item, index) => (
          <div className="d-flex align-items-center">
            <span className="ms-3 fw-normal">{item.name}</span>
          </div>
        )
      )
    )
  }

  render() {
    const speaker = this.props.speaker;
    return(
      <div className="col-lg-3 col-md-6">
        <div className="card">
          <div className="el-card-item">
            <div className="el-card-avatar el-overlay-1" style={{cursor: 'pointer'}} onClick={() => this.handleOpenModal(true)}> 
              <img src={this.getValueSafely(speaker,"profileUrlThumbnail")} alt={"user_img"}/>
              <div className="el-overlay">
                <p className="el-info">
                  Más información
                </p>
              </div>
            </div>
            <div className="el-card-content">
              <h3 className="box-title">{speaker.name}</h3> <small>{speaker.speciality}</small>
              <br /> </div>
          </div>
        </div>
        <KSpeakerModal showModal={this.state.showModal}
                       user={this.props.speaker}
                       closeModal={this.handleOpenModal}>
        </KSpeakerModal>
      </div>
    )
  }
}

/*<div className="col-lg-3 col-md-6">
  <div className="card">
    <div className="el-card-item">
      <div className="el-card-avatar el-overlay-1" style={{cursor: 'pointer'}} onClick={() => this.handleOpenModal(true)}> 
        <img src={this.getValueSafely(speaker,"profileUrlThumbnail")} alt={"user_img"}/>
        <div className="el-overlay">
          <p className="el-info">
            Más información
          </p>
        </div>
      </div>
      <div className="el-card-content">
        <h3 className="box-title">{speaker.name}</h3> <small>{parse(speaker.speciality)}</small>
        <br /> </div>
    </div>
  </div>
  <KSpeakerModal showModal={this.state.showModal}
                 user={this.props.speaker}
                 closeModal={this.handleOpenModal}>
  </KSpeakerModal>
</div>*/

KSpeakerRow.propTypes = {
  /** Mentor to be render. */
  speaker            : PropTypes.object
};

KSpeakerRow.defaultProps = {
  speaker             : {}
};

export default KSpeakerRow;
