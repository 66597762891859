/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx> && Juan Hernández <juan@startuplab.mx>, August 2020
 */

// Regular imports
import React, { Component } from "react";
import KomunidadApi from "../../api/api.js";
import { isUserProfileComplete } from "../../utils/Utils.js";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../scss/pages/_style.css";
import BounceLoader from "react-spinners/BounceLoader";
import { Line } from "react-chartjs-2";

// Components
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import Footer from '../../components/Footer';
import TopSideBar from '../../components/TopSideBar';
import KStatsManager from "../../components/KStatsManager";
import KCompanyList from '../../components/KCompanyList';
import KReviewList from '../../components/KReviewList';
import KNews from "../../components/KNews";

//Other imports
import { orderBy } from 'lodash';
import { itBelongsToMonth } from "../../utils/Utils.js";
import FormInput from "../../components/form/FormInput";
import {Spinner} from "react-bootstrap";
import KImage from "../../components/KImage";

class ManagerView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      me: {},
      users: [],
      authorized: false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      programs: [],
      companies: [],
      partners: [],
      mentors: [],
      isSidebarProfileOpen: false,
      isLoading: true,
      monthsCompaniesChart: [0,0,0,0,0,0,0,0,0,0,0,0],
      chartOptions:{
        legend:{
          display: false
        }
      },
      chartData: {
        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        datasets: [
          {
            label: "Usuarios registrados",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(112,124,210,0.4)",
            borderColor: "rgba(112,124,210,1)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgba(112,124,210,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(112,124,210,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      },
      opportunities:{
        opportunity1Title:'',
        opportunity1Text:'',
        opportunity1Link:'',
        opportunity1Image:'',
        opportunity2Title:'',
        opportunity2Text:'',
        opportunity2Link:'',
        opportunity2Image:'',
        opportunity3Title:'',
        opportunity3Text:'',
        opportunity3Link:'',
        opportunity3Image:'',
      },
      images:[],
      isEditingOpportunities:false,
      isLoadingOpportunities:true,
      isSavingOpportunities:false
    };
    this.handleResizeBar       = this.handleResizeBar.bind(this);
    this.redirectToEditProfile = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize    = this.handleHeaderResize.bind(this);
    this.getCompanies          = this.getCompanies.bind(this);
    this.getPartners           = this.getPartners.bind(this);
    this.getMentors            = this.getMentors.bind(this);
    this.formatCompaniesChart  = this.formatCompaniesChart.bind(this);
    this.formatUsersChart      = this.formatUsersChart.bind(this);
    this.redirectToCompany     = this.redirectToCompany.bind(this);
    this.getOpportunities      = this.getOpportunities.bind(this);
    this.handleFile            = this.handleFile.bind(this);
    this.openInput             = this.openInput.bind(this);

  }

  isAuthorized(user) {
    let program_id = this.props.match.params.id;
    let facilitator_programs_permissions = user.facilitator_programs_permissions || [];
    let programs = facilitator_programs_permissions.map((p) => p.program.toString());
    
    if (user.roles.includes("manager") || user.roles.includes("facilitator")) {
      return true;
    } else if (
      user.roles.includes("facilitator") &&
      programs.includes(program_id)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        let isAuthorized = this.isAuthorized(res.user);
        if (!isUserProfileComplete(res.user)) {
          this.redirectToEditProfile();
        } else {
          if (isAuthorized) {
            this.setState({
              authorized: isAuthorized,
              me: res.user,
            });
          } else {
            this.redirectToProfile();
          }

          this.getUsers();
          this.getPrograms();
          this.getAllReviews();
          this.getCompanies();
        }
      } else {
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar();
    this.getOpportunities();
  }

  redirectToProfile() {
    this.props.history.push("/profile");
  }

  getPrograms() {
    KomunidadApi.findPrograms().then( (res) => {
      if(res.success){
        if(res.programs.length > 0){
          this.setState({programs: this.sortPrograms(res.programs)});
        }else{
          this.setState({programs:[]});
        }
      }
      //TODO: swal error or msg in table with table_msg
    });
  }

  sortPrograms(programs) {

    for (var i = 0; i < programs.length; i++) {
      programs[i]["status"] = this.getStatus("",programs[i]);
    }

    programs = orderBy(programs, ['status', 'created_at'], ['asc', 'desc']);

    return programs;

  }

  getStatus(cell,row){
    let today = Date.now();
    let startDate = new Date(row.start_date);
    let endDate = new Date(row.end_date);

    endDate.setDate(endDate.getDate() + 1);

    let status = ((today >= startDate.getTime()) && (today <= endDate.getTime())) ?  "Activo" :  "Inactivo";

    return status;
  }

  getCompanies() {
    KomunidadApi.findCompanies('').then((res) => {
      if (res.success) {
        if (res.companies.length > 0) {
          this.setState({ companies: this.formatCompaniesNoProgram(res.companies), isLoading: false});
          // this.formatCompaniesChart(res.companies);
        } else {
          this.setState({ companies: [], companiesChartData:[], isLoading: false });
        }
      }
    });
  }

  getUsers(){
    KomunidadApi.getUsers().then((res) => {
      if(res.success){
        this.formatUsersChart(res.users);
        this.getPartners(res.users);
        this.getMentors(res.users);
      }
    });
  }

  getAllReviews() {
    KomunidadApi.getAllReviews().then((res) => {
      if (res.success) {
        if (res.reviews.length > 0) {
          this.setState({ reviews: res.reviews,  });
        } else {
          this.setState({ reviews: []});
        }
      }
    });
  }

  getPartners(users) {
    let partners = users.filter(user => user.roles.includes('partner'));

    partners = orderBy(partners, ['created_at'], ['desc']);

    this.setState({ partners: partners, search_partners_msg: '' });
  }

  getMentors(users) {
    let mentors = users.filter(user => user.roles.includes('mentor'));

    mentors = orderBy(mentors, ['created_at'], ['desc']);

    this.setState({ mentors: mentors, search_mentors_msg: '' });
  }

  formatCompaniesNoProgram(companies) {
    companies.forEach(function (element) {
      if (!element.hasOwnProperty('programs')) {
        element.programs = [];
      }
    });

    companies = orderBy(companies, ['created_at'], ['desc']);

    return companies
  }

  formatCompaniesChart(companies){
    let companiesCount = this.state.monthsCompaniesChart;
    var year = new Date();
    year = year.getUTCFullYear();
    companies.forEach(company => {
      if (itBelongsToMonth(year, "01", company.created_at)) {
        companiesCount[0] = companiesCount[0] + 1;
      } else if (itBelongsToMonth(year, "02", company.created_at)) {
        companiesCount[1] = companiesCount[1] + 1;
      } else if (itBelongsToMonth(year, "03", company.created_at)) {
        companiesCount[2] = companiesCount[2] + 1;
      } else if (itBelongsToMonth(year, "04", company.created_at)) {
        companiesCount[3] = companiesCount[3] + 1;
      } else if (itBelongsToMonth(year, "05", company.created_at)) {
        companiesCount[4] = companiesCount[4] + 1;
      } else if (itBelongsToMonth(year, "06", company.created_at)) {
        companiesCount[5] = companiesCount[5] + 1;
      } else if (itBelongsToMonth(year, "07", company.created_at)) {
        companiesCount[6] = companiesCount[6] + 1;
      } else if (itBelongsToMonth(year, "08", company.created_at)) {
        companiesCount[7] = companiesCount[7] + 1;
      } else if (itBelongsToMonth(year, "09", company.created_at)) {
        companiesCount[8] = companiesCount[8] + 1;
      } else if (itBelongsToMonth(year, "10", company.created_at)) {
        companiesCount[9] = companiesCount[9] + 1;
      } else if (itBelongsToMonth(year, "11", company.created_at)) {
        companiesCount[10] = companiesCount[10] + 1;
      } else if (itBelongsToMonth(year, "12", company.created_at)) {
        companiesCount[11] = companiesCount[11] + 1;
      }
    });
    this.setState((prevState) => {
      let chartData = Object.assign({}, prevState.chartData); // creating copy of state variable data
      chartData.datasets[0].data = companiesCount; // update the data property, assign a new value
      return { chartData }; // return new object data object
    });
  }

  formatUsersChart(users){
    let usersCount = this.state.monthsCompaniesChart;
    var year = new Date();
    year = year.getUTCFullYear();
    users.forEach(user => {
      if (itBelongsToMonth(year, "01", user.created_at)) {
        usersCount[0] = usersCount[0] + 1;
      } else if (itBelongsToMonth(year, "02", user.created_at)) {
        usersCount[1] = usersCount[1] + 1;
      } else if (itBelongsToMonth(year, "03", user.created_at)) {
        usersCount[2] = usersCount[2] + 1;
      } else if (itBelongsToMonth(year, "04", user.created_at)) {
        usersCount[3] = usersCount[3] + 1;
      } else if (itBelongsToMonth(year, "05", user.created_at)) {
        usersCount[4] = usersCount[4] + 1;
      } else if (itBelongsToMonth(year, "06", user.created_at)) {
        usersCount[5] = usersCount[5] + 1;
      } else if (itBelongsToMonth(year, "07", user.created_at)) {
        usersCount[6] = usersCount[6] + 1;
      } else if (itBelongsToMonth(year, "08", user.created_at)) {
        usersCount[7] = usersCount[7] + 1;
      } else if (itBelongsToMonth(year, "09", user.created_at)) {
        usersCount[8] = usersCount[8] + 1;
      } else if (itBelongsToMonth(year, "10", user.created_at)) {
        usersCount[9] = usersCount[9] + 1;
      } else if (itBelongsToMonth(year, "11", user.created_at)) {
        usersCount[10] = usersCount[10] + 1;
      } else if (itBelongsToMonth(year, "12", user.created_at)) {
        usersCount[11] = usersCount[11] + 1;
      }
    });
    this.setState((prevState) => {
      let chartData = Object.assign({}, prevState.chartData); // creating copy of state variable data
      chartData.datasets[0].data = usersCount; // update the data property, assign a new value
      return { chartData }; // return new object data object
    });
  }
  
  handleSidebarProfile(show) {
    this.setState({ isSidebarProfileOpen: show });
  }

  redirectHome() {
    this.props.history.push("/");
  }

  redirectToEditProfile() {
    this.props.history.push("/profile/edit");
  }

  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true,
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  formatDate(date) {
    if (date !== "") {
      var d = new Date(date.replace("Z", "")),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    } else {
      return "";
    }
  }

  renderBrief() {
    return (
      <div style={{ background: "#f4f6f9" }}>
        {this.renderStats()}
        {/* Projects chart */}
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="card manager-view-cards">
              <div className="card-body">
                <h4 className="card-title">
                  <span className="lstick" />
                  Registro anual de usuarios {new Date().getUTCFullYear()}
                </h4>
                <Line data={this.state.chartData} options={this.state.chartOptions}/>
              </div>
            </div>
          </div>
          {/* Last reviews widget*/}
          <div className="col-lg-4 col-md-12">
            <div className="card manager-view-cards">
              <div className="card-body">
                <h4 className="card-title">
                  <span className="lstick" />
                  Retroalimentación
                </h4>
                {this.renderReviews()}
              </div>
            </div>
          </div>
        </div>
        {/* Last companies created */}
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h4 className="card-title">
                      <span className="lstick" />
                      Ultimas empresas
                    </h4>
                  </div>
                  <div className="ml-auto">{/* action buttons */}</div>
                </div>
                {this.renderRecentCompanies()}
              </div>
            </div>
          </div>
          {/* Recent activity widget*/}
          {this.renderNews(2)}
        </div>
        {/* Oportunities */}
        {this.renderOpportunities()}
        </div>
    );
  }
  handleFile(e){
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const id = e.target.id;
      let t = this;
      reader.addEventListener("load", () =>{ // Read the file
        const images = [...this.state.images];
        images[0].Content = reader.result;
        const opportunities = {...this.state.opportunities};
        opportunities[id] = reader.result;
        opportunities[`${id}File`] = images[0];
        this.setState({image:reader.result,images:images,opportunities:opportunities});
      });
      const file = e.target.files[0];
      this.setState({images:[{Name:file.name,ContentType:file.type,Content:''}]})
      reader.readAsDataURL(e.target.files[0]);
    }

  }
  openInput(id){
    if(this.state.isEditingOpportunities){
      document.getElementById(id).click();
    }
  }
  renderOpportunities(){
    if(this.state.isLoadingOpportunities){
      return(<Spinner variant={'primary'} animation={'border'} size={'sm'}></Spinner>)
    }
    return(
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="">
                  <div>
                    <h4 className="card-title">
                      <span className="lstick" />
                      Oportunidades
                    </h4>
                  </div>

                  <div className="row">
                    <div className='form-group form-horizontal'>
                      <FormInput
                          ref={ref => this.opportunity1Title = ref}
                          id="Oportunidad (1)"
                          label="Oportunidad (1)"
                          model="opportunity1Title"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity1Title}
                          help="Nombre de la oportunidad (1)"
                          maxLength={140}
                          validationRules="minLen:3|maxLen:140"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Nombre de la oportunidad (1)"/>
                      <FormInput
                          ref={ref => this.opportunity1Text = ref}
                          id="Descripción (1)"
                          label="Descripción (1)"
                          model="opportunity1Text"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity1Text}
                          help="Descripción de la oportunidad (1)"
                          maxLength={3000}
                          validationRules="minLen:3|maxLen:3000"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Descripción de la oportunidad (1)"
                          inputType={'textarea'}
                      />
                      <FormInput
                          ref={ref => this.opportunity1Link = ref}
                          id="Enlace (1)"
                          label="Enlace (1)"
                          model="opportunity1Link"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity1Link}
                          help="Enlace (1)"
                          maxLength={1000}
                          validationRules="minLen:3|maxLen:500"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Enlace (1)"
                      />
                      <div className="m-t-20 m-b-20">
                        <h4><i className="ti-link" /> Imagen</h4>
                        <input id="opportunity1Image" type={'file'} hidden={true}
                               accept="image/jpeg,image/jpg,image/png"
                               onChange={(ev) => this.handleFile(ev)}/>
                        <KImage src={this.state.opportunities.opportunity1Image}
                                type='email_image'
                                className={'rounded img-fluid selectLogo'}
                                alt="Adjuntar imagen"
                                onClick={() => this.openInput('opportunity1Image')}
                        />
                      </div>
                      <FormInput
                          ref={ref => this.opportunity2Title = ref}
                          id="Oportunidad (2)"
                          label="Oportunidad (2)"
                          model="opportunity2Title"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity2Title}
                          help="Nombre de la oportunidad (2)"
                          maxLength={140}
                          validationRules="minLen:3|maxLen:140"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Nombre de la oportunidad (2)"/>
                      <FormInput
                          ref={ref => this.opportunity2Text = ref}
                          id="Descripción (2)"
                          label="Descripción (2)"
                          model="opportunity2Text"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity2Text}
                          help="Descripción de la oportunidad (2)"
                          maxLength={3000}
                          validationRules="minLen:3|maxLen:3000"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Descripción de la oportunidad (2)"
                          inputType={'textarea'}
                      />
                      <div className="m-t-20 m-b-20">
                        <h4><i className="ti-link" /> Imagen</h4>
                        <input id="opportunity2Image" type={'file'} hidden={true}
                               accept="image/jpeg,image/jpg,image/png"
                               onChange={(ev) => this.handleFile(ev)}/>
                        <KImage src={this.state.opportunities.opportunity2Image}
                                type='email_image'
                                className={'rounded img-fluid selectLogo'}
                                alt="Adjuntar imagen"
                                onClick={() => this.openInput('opportunity2Image')}
                        />
                      </div>
                      <FormInput
                          ref={ref => this.opportunity2Link = ref}
                          id="Enlace (2)"
                          label="Enlace (2)"
                          model="opportunity2Link"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity2Link}
                          help="Enlace (2)"
                          maxLength={1000}
                          validationRules="minLen:3|maxLen:500"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Enlace (2)"
                      />
                      <FormInput
                          ref={ref => this.opportunity3Title = ref}
                          id="Oportunidad (3)"
                          label="Oportunidad (3)"
                          model="opportunity3Title"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity3Title}
                          help="Nombre de la oportunidad (3)"
                          maxLength={140}
                          validationRules="minLen:3|maxLen:140"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Nombre de la oportunidad (3)"/>
                      <FormInput
                          ref={ref => this.opportunity3Text = ref}
                          id="Descripción (3)"
                          label="Descripción (3)"
                          model="opportunity3Text"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity3Text}
                          help="Descripción de la oportunidad (3)"
                          maxLength={3000}
                          validationRules="minLen:3|maxLen:3000"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Descripción de la oportunidad (3)"
                          inputType={'textarea'}
                      />
                      <FormInput
                          ref={ref => this.opportunity3Link = ref}
                          id="Enlace (3)"
                          label="Enlace (3)"
                          model="opportunity3Link"
                          showCounter={true}
                          editMode={this.state.isEditingOpportunities}
                          value={this.state.opportunities.opportunity3Link}
                          help="Enlace (3)"
                          maxLength={1000}
                          validationRules="minLen:3|maxLen:500"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Enlace (3)"
                      />
                      <div className="m-t-20 m-b-20">
                        <h4><i className="ti-link" /> Imagen</h4>
                        <input id="opportunity3Image" type={'file'} hidden={true}
                               accept="image/jpeg,image/jpg,image/png"
                               onChange={(ev) => this.handleFile(ev)}/>
                        <KImage src={this.state.opportunities.opportunity3Image}
                                type='email_image'
                                className={'rounded img-fluid selectLogo'}
                                alt="Adjuntar imagen"
                                onClick={() => this.openInput('opportunity3Image')}
                        />
                      </div>
                      <div className="ml-auto">
                        <button type="submit" className="btn btn-success btn-save" onClick={() => this.toggleEdit()} data-tut="edit-profile">
                          <i className={this.state.isEditingOpportunities ? "mdi mdi-close mr-1" : "mdi mdi-grease-pencil mr-1"  } style={{fontSize: '16px'}}></i>
                          {this.state.isEditingOpportunities ? 'Cancelar' : 'Editar'}
                        </button>
                        {
                          this.state.isEditingOpportunities ?
                              <button type="submit" className="btn btn-success btn-save m-r-10" onClick={() => this.onSaveClick()}>
                                {
                                  this.state.isSavingOpportunities ?
                                      <>
                                        <Spinner variant={'primary'} animation={'border'} size={'sm'}></Spinner>
                                        Guardando
                                      </>
                                      :
                                      <>
                                        <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i>
                                        Guardar
                                      </>

                                }

                              </button> : null
                        }
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
  onChangeForm(data){
    const opportunities = {...this.state.opportunities};
    opportunities[data.model] = data.value;
    this.setState({
        opportunities
    });
  }
  onSaveClick(){
    this.setState({isSavingOpportunities:true});
    KomunidadApi.saveOpportunities(this.state.opportunities).then((res) =>{
      this.toggleEdit();
    }).catch((ex) =>{
    }).finally(() =>{
      this.setState({isSavingOpportunities:false});
    });
  }
  getOpportunities(){
    this.setState({isLoadingOpportunities:true});
    KomunidadApi.getOpportunities().then((res) =>{
      this.setState({
        opportunities:{
          opportunity1Title: res?.opportunities?.opportunity1Title || '',
          opportunity1Text: res?.opportunities?.opportunity1Text || '',
          opportunity1Link: res?.opportunities?.opportunity1Link || '',
          opportunity1Image: res?.opportunities?.opportunity1Image || '',
          opportunity2Title: res?.opportunities?.opportunity2Title || '',
          opportunity2Text: res?.opportunities?.opportunity2Text || '',
          opportunity2Link: res?.opportunities?.opportunity2Link || '',
          opportunity2Image: res?.opportunities?.opportunity2Image || '',
          opportunity3Title: res?.opportunities?.opportunity3Title || '',
          opportunity3Text: res?.opportunities?.opportunity3Text || '',
          opportunity3Link: res?.opportunities?.opportunity3Link || '',
          opportunity3Image: res?.opportunities?.opportunity3Image || ''
        }
      })
    }).catch((res) =>{
    }).finally(() =>{
      this.setState({isLoadingOpportunities:false});
    });
  }
  toggleEdit(){
    if(this.state.isEditingOpportunities){
      // Re-load data
      this.getOpportunities();
    }
    this.setState({isEditingOpportunities:!this.state.isEditingOpportunities})
  }
  renderStats() {
    let data = {
      programs: this.state.programs,
      companies: this.state.companies,
      partners: this.state.partners,
      mentors: this.state.mentors
    }
    return <KStatsManager data={data}></KStatsManager>;
  }
  renderRecentCompanies() {
    let companies = this.state.companies.slice(0,8);
    if (companies.length > 0){
      return (
        <KCompanyList
          companies={companies}
          requestStatus={"success"}
          shortView={true}
          managerView={true}
          managerDashboardView={true}
          stripedStyle={false}
          borderlessStyle={false}
          viewLabel={"Ver"}
          onClickCompany={this.redirectToCompany}
          emptyListTitle={"No se encontraron empresas"}
          showCreator={true}
          style={{ marginBottom: 0, cursor: "pointer" }}
        />
      );
    } else {
      return null
    }
  }

  redirectToCompany(company) {
    this.props.history.push("/companies/" + company._id);
  }

  renderReviews () {
    if (this.state.reviews !== undefined){
      let reviews = this.state.reviews.slice(0, 4);
      return (
        <KReviewList
          reviews={reviews}
          requestStatus={"success"}
          managerViewReview={true}
          subtitleEmptyList={" "} />
      );
    }
  }

  renderNews(size) {

    let data = {
      programs: this.state.programs.slice(0, size),
      companies: this.state.companies.slice(0, size),
      partners: this.state.partners.slice(0, size),
      mentors: this.state.mentors.slice(0, size)
    }

    return <KNews data={data}></KNews>;
  }

  renderLoader() {
    return (
      <div className="card loader_wrapper">
        <BounceLoader
          sizeUnit={"px"}
          size={150}
          color={"#3cc"}
          loading={true}
        />
      </div>
    );
  }

  renderContent() {
    if (false) {
      return this.renderLoader();
    } else {
      return this.renderBrief();
    }
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                    <h3 className="text-themecolor">
                      Panel de control
                    </h3>
                  </div>
                  <div className="col-md-7 align-self-center d-none">
                    <button
                      className="btn btn-success btn-save float-right"
                      onClick={() => {
                        console.log('pending')
                      }}
                    >
                      <i className="mdi mdi-refresh" style={{fontSize: '18px'}}></i>
                    </button>
                  </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">{this.renderContent()}</div>
                  {/* End column */}
                </div>
                {/* End row */}
              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ManagerView;
