/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, October 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { renderFullNameOrEmail} from '../utils/Utils.js';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import KImage from '../components/KImage';
import KCompanyList from '../components/KCompanyList';


/**
*  Render the mentor profile as a modal.
*  The basic information of the mentor.
*/

class KMentorProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal                   =  this.hideModal.bind(this);
    this.handleCloseModal            =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose      =  this.executeAfterModalClose.bind(this);
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.props.closeModal(false);
  }

  renderSpeciality(mentor){
    let fullMentorSpecialitity = '';

    if(mentor.hasOwnProperty('mentor_speciality_sector')){
      if(mentor.mentor_speciality_sector !== null){
        for (const key in mentor.mentor_speciality_sector){
          fullMentorSpecialitity += mentor.mentor_speciality_sector[key] + ", ";
        }
      }if (mentor.mentor_speciality_sector_other !== null){
          if (mentor.mentor_speciality_sector_other !== "") {
            fullMentorSpecialitity += mentor.mentor_speciality_sector_other + ", ";
          }
      }if(fullMentorSpecialitity === ''){
        fullMentorSpecialitity = 'No especificado ';
      }
      fullMentorSpecialitity = fullMentorSpecialitity.replace(/..$/,".");
      return fullMentorSpecialitity;
    }else{
      return null
    }
  }

  renderBio(mentor){
    if(mentor.hasOwnProperty('mentor_biography')){
      if(mentor.mentor_biography !== null ){
        return (
          <div className="card-body">
            <div><hr/></div>
            {mentor.mentor_biography}
          </div>
        )
      }else{
        return null;
      }
    }else{
      return null
    }
  }

  renderCompanies(){
    if(this.props.managerView){
      const companies = this.props.companies;

      return(
        <div className="card-body">
          <h4><b>Empresas</b></h4>
          <KCompanyList companies={companies}
                        requestStatus={'success'}
                        shortView={true}
                        hoverStyle={false}
                        stripedStyle={false}
                        borderlessStyle={false}
                        managerView={this.props.managerView}
                        emptyListTitle={'El usuario no cuenta con empresas registradas'}
                        />
        </div>
      )
    }else{
      return null
    }
  }

  renderContent(){
    const user = this.props.user;
    return(
      <div className="card-body">
          <center>
            <KImage src={user.profileUrlThumbnail} className="img-circle" alt="user_img" type={"user_"+user.gender}/>
            <h4 className="card-title m-t-10 font-bold">{renderFullNameOrEmail(user)}</h4>
            <h6 className="card-subtitle">{this.renderSpeciality(user)}</h6>
          </center>

          {this.renderBio(user)}

          <div className="card-body">
            <div><hr/></div>

            <small className="text-muted">Email</small>
            <h6>{user.email}</h6>
            <small className="text-muted p-t-30 db">Telefono</small>
            <h6>{user.phone}</h6>
          </div>

          {this.renderCompanies(user)}
      </div>
    )
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Perfil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
      </Modal>
    );
  }
}

KMentorProfileModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,

  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,

  /** User object (mentor) to be display. */
  user             : PropTypes.object,

  /** Company list of the user. */
  companies        : PropTypes.array,

  /** TO DO:Check if this props is usefull.
      If the user is manager, show the companies. */
  managerView      : PropTypes.bool,

};

KMentorProfileModal.defaultProps = {
  closeModal      : () => {},
  showModal       : false,
  user            : {},
  companies       : [],
  managerView     : false,

};

export default KMentorProfileModal;
