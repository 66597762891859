/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, February 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import QrReader from 'react-qr-reader';
import { Button, Tab, Nav } from 'react-bootstrap';
import KUsersList from '../../components/KUsersList';
import Swal from 'sweetalert2';

const WHATSAPP_QR_REGEX = /whatsapp:\/\/chat\?code\=([A-Z])\w+/;
const WHATSAPP_LINK_REGEX = /https:\/\/chat.whatsapp.com\/([A-Z])\w+/;
class InviteWhatsappGroupView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      instructionsPlatform:'iphone', //iphone,android
      me:{},
      step:'instructions', //instructions,qr_code,confirm,done
      qr_error:'',
      isValidWhatsappGroupLink:false,
      isValidWhatsappGroupName:false,
      group_name:'',
      group_link:'',
      users:[]
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.handleScan                    = this.handleScan.bind(this);
    this.renderInstructions            = this.renderInstructions.bind(this);
    this.renderStep                    = this.renderStep.bind(this);
    this.goToStep                      = this.goToStep.bind(this);
    this.renderConfirm                 = this.renderConfirm.bind(this);
    this.sendInvitation                = this.sendInvitation.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(res.user.roles.includes("manager") || res.user.roles.includes("facilitator")){
            //ok
          }else{
            this.props.history.push('/profile');
          }
          this.setState({
            me:res.user,
            authorized:true
          });

          //get users
          let users = [];
          // let programId = null;
          try{
            users = this.props.location.state.users_ids; //.join("|")
            // programId = this.props.location.state.program_id;
          }catch(ex){
            this.props.history.push('/profile');
          }
          KomunidadApi.getUsersById(users).then( (res) =>{
            this.setState({
              users:res.users
            })
          });


        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }
  sendInvitation(){
    let users = this.props.location.state.users_ids.join("|");
    let program_id = this.props.location.state.program_id;
    KomunidadApi.sendWhatsappGroupInvitation(this.state.group_name,this.state.group_link,users,program_id).then( (res) =>{
      if(res.success){
        Swal.fire({
          title:'Invitación enviada',
          text: 'Se ha enviado la invitación a los usuarios seleccionados',
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          onClose: () =>{
            this.props.history.goBack();
          }
        })
      }else{
        Swal.fire({
          title:'Invitación no enviada',
          text: 'No se pudo enviar la invitación. Intenta mas tarde',
          type: 'error',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        })
      }
    });
  }
  goToStep(step){
    if(step === "confirm"){
      if(!this.group_link.validate()){
        //If the form is invalid, do nothing
        return null;
      }
    }

    this.setState({
      step:step
    })
  }

  renderInstructions(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
            <h3 className="text-themecolor">Instrucciones:</h3>
            <div style={{height:"100%"}} className="m-t-30">
              <Tab.Container defaultActiveKey='iphone'>
                <Nav className="nav" bsPrefix='tabCustom'>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="iphone" id="iphone"><i className="mdi mdi-apple"></i> iPhone</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="android" id="android"><i className="mdi mdi-android"></i> Android</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="tab-content m-t-10">
                  <Tab.Pane eventKey="iphone">
                   <div className='row'>
                      <div className='col-lg-4'>
                        <div style={{minHeight:40}}><b>1.-</b>&nbsp;Ve a tu teléfono y crea o abre un grupo</div>
                        <div style={{minHeight:40}}><b>2.-</b>&nbsp;Toca el nombre del grupo en la parte superior</div>
                        <div style={{minHeight:40}}><b>3.-</b>&nbsp;Toca <b>Enlace de invitación del grupo</b></div>
                        <div style={{minHeight:40}}><b>4.-</b>&nbsp;Toca <b>Código QR</b></div>
                        <div style={{minHeight:40}}><b>5.-</b>&nbsp;Da clic en siguiente</div>
                        <Button block className="btn btn-success btn-save m-t-30" onClick={() =>this.goToStep('qr_code')}>
                          Siguiente
                        </Button>
                      </div>
                      <div className='col-lg-8'>
                        <video width="100%" height="300" autoplay controls style={{backgroundColor:"black"}}>
                          <source src="https://komunidaddiag.blob.core.windows.net/komunidad-assets/Komunidad_whatsapp_iphone.mp4" type="video/mp4"/>
                        </video>
                      </div>
                   </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="android">
                   <div className='row'>
                      <div className='col-lg-4'>
                        <div style={{minHeight:40}}><b>1.-</b>&nbsp;Ve a tu teléfono y crea o abre un grupo</div>
                        <div style={{minHeight:40}}><b>2.-</b>&nbsp;Toca el menú <i className="mdi mdi-dots-vertical" style={{backgroundColor: "#f2f2f2" ,width: 24,height:24,padding:5,borderRadius:2}}color={"#818b90"}></i> y selecciona <b>Info. del grupo.</b></div>
                        <div style={{minHeight:40}}><b>3.-</b>&nbsp;Toca <b>Enlace de invitación del grupo</b></div>
                        <div style={{minHeight:40}}><b>4.-</b>&nbsp;Toca el menú <i className="mdi mdi-dots-vertical" style={{backgroundColor: "#f2f2f2" ,width: 24,height:24,padding:5,borderRadius:2}}color={"#818b90"}></i> y selecciona <b>Imprimir código QR del grupo</b></div>
                        <div style={{minHeight:40}}><b>5.-</b>&nbsp;Da clic en siguiente</div>
                        <Button block className="btn btn-success btn-save m-t-30" onClick={() =>this.goToStep('qr_code')}>
                          Siguiente
                        </Button>
                      </div>
                      <div className='col-lg-8'>
                        <video width="100%" height="300" autoplay controls style={{backgroundColor:"black"}}>
                          <source src="https://komunidaddiag.blob.core.windows.net/komunidad-assets/Komunidad_whatsapp_android.mp4" type="video/mp4"/>
                        </video>
                      </div>
                   </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>

            </div>
          </div>
          </div>
      </div>
    )
  }
  renderQRCode(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-4">
            <h3 className="text-themecolor">Instrucciones:</h3>

            <div style={{height:"100%"}} className="m-t-30">
              <div style={{minHeight:40}}><b>1.-</b>&nbsp;Da clic en permitir acceso a la cámara web</div>
              <div style={{minHeight:40}}><b>2.-</b>&nbsp;Escanea tu código enfrente de la cámara web</div>
              <div style={{minHeight:40}}><b>3.-</b>&nbsp;Opcionalmente puedes escribir el enlace de tu grupo en el siguiente campo de texto:</div>
              <div className='form-group'>
                <FormInput
                  ref={ref => this.group_link = ref}
                  id="Enlace del grupo"
                  label="Enlace del grupo"
                  model="group_link"
                  showCounter={false}
                  value={this.state.group_link}
                  initialValue={''}
                  required={true}
                  validationRules="required|isWhatsappGroupLink"
                  onChange={ change => { this.onChangeForm(change) } }
                  errorPosition="top"
                  />
              </div>
              <div style={{minHeight:40}}><b>4.-</b>Da clic en siguiente</div>
              <Button block disabled={!this.state.isValidWhatsappGroupLink}
                            className={this.state.isValidWhatsappGroupLink ? "btn btn-success btn-save m-t-30": "m-t-30"}
                            variant={this.state.isValidWhatsappGroupLink ? "": "link"}
                            onClick={() =>this.goToStep('confirm')}>
                Siguiente
              </Button>
            </div>
          </div>

          <div className="col-lg-8 d-flex justify-content-center align-items-center">
            <QrReader
                   delay={100}
                   onError={this.handleError}
                   onScan={this.handleScan}
                   style={{ width:350, height:350}}
             />
          </div>

        </div>
      </div>

    )
  }
  renderConfirm(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-4">
            <h3 className="text-themecolor">Instrucciones:</h3>

            <div style={{height:"100%"}} className="m-t-30">
              <div style={{minHeight:40}}><b>1.-</b>&nbsp;Escribe el nombre de tu grupo en el siguiente campo de texto:</div>
              <div className='form-group'>
                <FormInput
                  ref={ref => this.group_name = ref}
                  id="Nombre del grupo"
                  label="Nombre del grupo"
                  model="group_name"
                  showCounter={false}
                  value={this.state.group_name}
                  initialValue={''}
                  required={true}
                  validationRules="required"
                  onChange={ change => { this.onChangeForm(change) } }
                  errorPosition="top"
                  />
              </div>
              <div style={{minHeight:40}}><b>2.-</b>Confirma los usuarios a invitar en la lista de la derecha</div>
              <div style={{minHeight:40}}><b>3.-</b>Cuando estés listo da clic en "Enviar invitación". Los usuarios recibirán en su correo un enlace y un código QR para unirse a tu grupo.</div>
              <Button block disabled={!this.state.isValidWhatsappGroupName}
                            className={this.state.isValidWhatsappGroupName ? "btn btn-success btn-save m-t-30": "m-t-30"}
                            variant={this.state.isValidWhatsappGroupName ? "": "link"}
                            onClick={() =>this.sendInvitation()}>
                Enviar invitación
              </Button>
            </div>
          </div>

          <div className="col-lg-8">
            <div style={{maxHeight:300,overflowY:"auto",overflowX:"hidden"}}>
              <KUsersList users={this.state.users}/>
            </div>
          </div>

        </div>
      </div>
    )
  }
  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }
  
  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  onChangeForm(data){
    this.setState({
      [data.model]:data.value
    }, () =>{
      if(data.model === "group_link"){
        let isValidWhatsappGroupLink = WHATSAPP_LINK_REGEX.test(data.value);
        this.setState({
          isValidWhatsappGroupLink:isValidWhatsappGroupLink
        }, () =>{
          this.group_link.validate();
        })
      }
     if(data.model === "group_name"){
       let isValidWhatsappGroupName = data.value.length > 0;
       this.setState({
         isValidWhatsappGroupName:isValidWhatsappGroupName
       }, () =>{
         this.group_name.validate();
       })
     }
    });
  }
  handleScan(scan){
    if(scan !== null){
      if(WHATSAPP_QR_REGEX.test(scan)){
        let whatsapp_link = scan.replace('whatsapp://chat?code=','https://chat.whatsapp.com/');
        this.setState({
          group_link:whatsapp_link
        }, () =>{
          this.setState({
            isValidWhatsappGroupLink:true
          }, () =>{
            this.group_link.focus();
            this.group_link.clearError();
          })
        });
      }else{
        //do something
      }
    }
  }
  renderStep(){
    if(this.state.step === "instructions"){
      return this.renderInstructions();
    }else if(this.state.step === "qr_code"){
      return this.renderQRCode();
    }else if(this.state.step === "confirm"){
      return this.renderConfirm();
    }else{
      return (null);
    }
  }
  render(){
   if(this.state.authorized){
     return(
       <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
             <div className="container-fluid">
               <div className="row">
                 <div className="col-lg-12 col-sm-12">
                   <div className="card">
                        {this.renderStep()}
                   </div>
                 </div>
               </div>
             </div>
             <Footer/>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default InviteWhatsappGroupView;
