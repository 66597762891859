/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, February 2021
 */

import React from 'react';
import KomunidadApi from '../api/api.js';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { orderBy } from 'lodash';

/** Pick one or multiple facilitators.
*   Returns an array of ids or the id of the selected facilitator(s).
*/


class KFacilitatorsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facilitators:[],
      selectedFacilitator:'',
      multipleOptions:[],
      multipleFacilitatorSelected:[]
    }
    this.onChange               = this.onChange.bind(this);
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.multipleFacilitatorSelected !== null){
      if ((state.multipleFacilitatorSelected.length === 0) && (props.multipleFacilitatorSelected !== state.multipleFacilitatorSelected)){
        let _selected = [];
        if(state.multipleOptions.length > 0){

          //Filter the multiple option array to set the selected facilitators
          _selected = state.multipleOptions.filter(function(option){
            return props.multipleFacilitatorSelected.indexOf(option.value) !== -1;
          });

          // console.log(_selected);
        }
        return {
          multipleFacilitatorSelected: _selected
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount(){
    this.getFacilitators();
  }

  clear(){
    this.setState({
      facilitators:[],
      selectedFacilitator:'',
      multipleOptions:[],
      multipleFacilitatorSelected:[]
    });
  }

  getFacilitators(){
    KomunidadApi.getUsers().then((res) => {
      if(res.success){
        if(res.users.length > 0){
          let facilitators = res.users.filter( user => user.roles.includes('facilitator'));

          facilitators = orderBy(facilitators, ['created_at'], ['desc']);

          this.setState({facilitators:facilitators});
          this.setMultipleOptions(facilitators);
        }else{
          this.setState({facilitators:[]});
        }
      }
    });
  }

  setMultipleOptions(facilitators){
    let options = facilitators.map(facilitator => ({ value: facilitator._id, label: facilitator.email }));
    this.setState({multipleOptions:options});
  }

  onChange(p){
    let selectedFacilitatorId = p.target.value;
    let facilitator = this.state.facilitators.filter(p => p._id.toString() === selectedFacilitatorId);
    this.setState({selectedFacilitator:facilitator._id});
    this.props.onChange(facilitator[0]);
  }

  handleMultipleSelect(selectedOption){
    this.setState({multipleFacilitatorSelected:selectedOption});
    let sOption = [selectedOption] || [];
    let facilitatorsIds = sOption.length > 0 ? sOption.map( option => option.value ) : [];
    this.props.onChange(facilitatorsIds);
  }

  renderSelect(){
    return(
      <Select
        value={this.state.multipleFacilitatorSelected}
        onChange={this.handleMultipleSelect}
        options={this.state.multipleOptions}
        isMulti={false}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        noOptionsMessage={()=> "No se encontraron directores registrados con este correo"}
        placeholder={"Selecciona el director"}
      />
    )
  }

  renderLabel(){
    return(
      <label>Director</label>
    )
  }

  render(){
    return(
      <div className='form-group'>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    )
  }
}

KFacilitatorsPicker.propTypes = {
  /** Selected program. */
	selectedFacilitator        : PropTypes.string,
  /** Callback on program selected, returns the id or an array of ids. */
  onChange               : PropTypes.func,
  /** Selected facilitators separated by pipe '|'. */
  multipleFacilitatorSelected: PropTypes.array,
}
KFacilitatorsPicker.defaultProps = {
	selectedFacilitator         : '',
  onChange                    : (program) => {},
  multipleFacilitatorSelected : [],
}

export default KFacilitatorsPicker;
