/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx> , February 2021
 */

// Regular imports
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom'
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import { Tab, Nav, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Link } from "react-router-dom";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../scss/pages/_style.css';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import KTableSmartSelect from '../../components/KTableSmartSelect';
import KCreateAndEditCourse from '../../components/KCreateAndEditCourse';
import ProgramsPicker from '../../components/ProgramsPicker';

// Other
import { orderBy } from 'lodash';
const TableCaption = () => <h5 className="display-table-caption" style={{ textAlign: 'center', color: '#398bf7', padding: '0.5em' }}> Desliza a la derecha para ver más<i className="ml-1 mdi mdi-chevron-right"/> </h5>;

class ManagerAcademy extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // Regular vars
      me: {},
      authorized:false,
      userIsLoaded:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      // Custom vars
      courses:[],
      table_msg:'No hay cursos para mostrar.',
      loading:false,
      formIsHalfFilledOut: false,
      confirmedNavigation: false,

    }
    // Regular methods
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    // Custom methods
    this.getAllCourses                   = this.getAllCourses.bind(this);
    this.onChangeProgram                 = this.onChangeProgram.bind(this);
    this.formIsHalfFilledOut             = this.formIsHalfFilledOut.bind(this);
    this.handleBlockedNavigation         = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick    = this.handleConfirmNavigationClick.bind(this);
  }

  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator") 
    )
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:true,
              me:res.user,
              userIsLoaded:true,
              loading:true,
            });
            this.getAllCourses();
          }else{
            this.redirectHome();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  getAllCourses(){
    KomunidadApi.getAllCourses().then( (res) => {
      if(res.success){
        if(res.courses.length > 0){
          this.setState({courses: this.sortCourses(res.courses),loading:false});
        }else{
          this.setState({courses:[],loading:false});
        }
      }
    });
  }
  getCoursesByProgramSelected(programs){
    
    KomunidadApi.getCoursesByProgram(programs.join("|")).then( (res) => {
      if(res.success){
        if(res.courses.length > 0){
          console.log(res.courses)
          this.setState({courses: this.sortCourses(res.courses),loading:false});
        }else{
          this.setState({courses:[],loading:false});
        }
      }
    });
  }

  componentDidUpdate(){
    if(this.state.formIsHalfFilledOut) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleBlockedNavigation = (nextLocation) => {
    if(!this.state.confirmedNavigation){
      this.confirmLeave(nextLocation);
      return false;
    }
    return true
  }

  //Confirm leave
  confirmLeave(nextLocation){
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que ingresaste se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.handleConfirmNavigationClick(nextLocation);
      }
    })
  }

  //Navigate to the next location
  handleConfirmNavigationClick(nextLocation){
     this.setState({
        confirmedNavigation: true
     }, () => {
        // Navigate to the previous blocked location
        this.props.history.push(nextLocation.pathname);
     })
  }

  sortCourses(courses) {
    courses = orderBy(courses, ['created_at'], ['desc']);
    return courses;
  }

  //Set the state of start edit the forma
  formIsHalfFilledOut(value){
    if(!(this.state.formIsHalfFilledOut && value)){
      this.setState({formIsHalfFilledOut:value});
    }
  }

  onChangeProgram(programs){
    this.setState({selectedPrograms:programs});
    this.getCoursesByProgramSelected(programs); 
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }


  renderAddCourseForm(){
    return(
      <div className="card-body">
        <div className="row">
         <div className="col-lg-10 col-md-10">
            <KCreateAndEditCourse
                isCreateMode={true}
                editMode={false}
                facilitatorView={this.state.me.roles.includes('facilitator')}
                onUpdateProgram={this.getAllCourses}
                onEditingProgram={this.formIsHalfFilledOut}/>
         </div>
        </div>
      </div>
    )
  }

  formatDate(date){
    let d = new Date(date.replace('Z','')),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
  }

  disableFormatter(cell,row,rowIndex,formatExtraData){
    let color = cell ? '#707cd2' : '#ff5050';
    return(
      <i className={"mdi " + formatExtraData[cell]} style={{color: color}}></i>
    )
  }

  renderListCourses(){
    const courses = this.state.courses;
    const columns = [
      {
        dataField: '_id',
        text: 'Nombre del curso',
        headerStyle: { minWidth: '240px' },
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return(
            <Link to={`/manager/academy/${row._id}`}> {row.name} </Link>
            // <div>{row.name}</div>
          )
        }
      },
      {
        dataField: 'programs',
        text: 'Programas',
        headerStyle: { minWidth: '108px' },
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (programs) => {
          // const programs = cellContent;
          
          return(
            <span>{ programs.map((program) =>
              <span>{program.program.name}<br></br></span>
              )}
            </span>
          )
        }
      },
      {
        dataField: 'phase',
        headerStyle: { minWidth: '173px' },
        text: 'Fase',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'accepts_inscriptions',
        // headerStyle: { minWidth: '103px' },
        text: 'Inscripciones',
        style: {
          verticalAlign:'middle'
        },
        formatter: this.disableFormatter,
        formatExtraData: {
          true: 'mdi-check',
          false: 'mdi-close',
          undefined:'mdi-close',
        },
      },
      {
        dataField: 'is_public',
        headerStyle: { minWidth: '103px' },
        text: 'Tipo',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(!row.hasOwnProperty('is_public')){
            return 'Público'
          }else{
            if(row.is_public){
              return 'Público'
            }else{
              return 'Privado'
            }
          }
        }
      },
    ];
    return(
        <div className="card-body">
          <div className="col-md-8">
            <ProgramsPicker showInactivePrograms={true}
                          onChange={(program) => {this.onChangeProgram(program)}}
                          isMultiple={true}>
            </ProgramsPicker>
          </div>
          <BootstrapTable
            bootstrap4
            striped
            caption={<TableCaption />}
            keyField='_id'
            loading={this.state.loading}
            data={ courses }
            columns={ columns }
            noDataIndication={this.state.table_msg}
            wrapperClasses="m-t-20 table-responsive"
            classes="react-bootstrap-table"
            overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(203, 255, 255, 0.5)'}) } })}
          />
        </div>
    )
  }

  render () {
    //--------------------------------------------------------------------------
    //Indicates if the user can leave the page
      const formIsHalfFilledOut = this.state.formIsHalfFilledOut
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    if(this.state.authorized){
      return(
         <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <Prompt
              when={formIsHalfFilledOut} // <- function that returns boolean
              message={this.handleBlockedNavigation}
          />
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Academia</h3>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                        <Tab.Container
                          mountOnEnter={true}
                          unmountOnExit={true}
                          defaultActiveKey="list_courses">
                          <Nav className="nav" bsPrefix='tabCustom' >
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="add_courses" id="add_courses">Agregar Cursos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="list_courses" id='list_courses'>Lista de Cursos</Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <Tab.Content className="tab-content">
                            <Tab.Pane eventKey="add_courses">
                              {this.renderAddCourseForm()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="list_courses">
                              {this.renderListCourses()}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default ManagerAcademy;
