/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Junuary 2020
 */

//imports
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav} from 'react-bootstrap';
import SlidingPanel from 'react-sliding-side-panel';
import KomunidadApi from '../api/api.js';
import BounceLoader from 'react-spinners/BounceLoader';

import { renderFullNameOrEmail} from '../utils/Utils.js';
import {
         editTransformToPhoneNumber,
         transformToPhone,
         transformToUpperCase,
         transformAddHttps,
         transformToAnchor }
from '../components/form/transforms.js';

//style
import '../scss/components/_profile_sidebar.scss';

//components
import KImage from '../components/KImage';
import KCompanyList from '../components/KCompanyList';
import FormInput from '../components/form/FormInput';

/**
*   Display the partner/mentor profile information and his companies.
*   The component call the api to get the user info and companies.
*/

class KProfileSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      companies:[],
      loadState:'loading', //posible states:loading, loaded, failed
      size:30,
        phases:[
            {
                label:'Idea',
                value:'idea'
            },
            {
                label:'Prototipo',
                value:'prototype'
            },
            {
                label:'Startup',
                value:'startup'
            },
            {
                label:'MiPyME',
                value:'mipyme'
            },
        ],
    }
    this.handleCloseSidebar          =  this.handleCloseSidebar.bind(this);
    this.getUser                     =  this.getUser.bind(this);
    this.updateDimensions            =  this.updateDimensions.bind(this);

  }

  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.user_id !== prevProps.user_id) {
      this.getUser(this.props.user_id);
    } 
  }

  updateDimensions(){
    var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    if(width <= 576){
      this.setState({size:90});
    }else if(width >= 576 && width <= 768){
      this.setState({size:50});
    }else{
      this.setState({size:30});
    }
  }

  getUser(id){
    //get user information
    if(id !== ''){
      KomunidadApi.getUserProfile(id).then((res) => {
        if(res.success){
          this.setState({user:res.user,companies:res.user.companies,loadState:'loaded'});
        }else{
          this.setState({loadState:'failed'});
        }
      });
    }
  }

  handleCloseSidebar(show){
    this.props.closeSidebar(show);
  }

  getBday(user){
    let moment = require('moment');
    let dateOfBirth = '';

    if(user.hasOwnProperty('dateOfBirth')){
      if(user.dateOfBirth !== null){
        let d = user.dateOfBirth;
        let date = new Date(d.replace('Z',''));

        dateOfBirth = moment(date).format('DD MMMM YYYY');
      }
    }
    return dateOfBirth;
  }


  renderSpeciality(mentor){
    let fullMentorSpecialitity = '';

    if(mentor.hasOwnProperty('mentor_speciality_sector')){
      if(mentor.mentor_speciality_sector !== null){
        for (const [key] in mentor.mentor_speciality_sector){
          fullMentorSpecialitity += mentor.mentor_speciality_sector[key] + ", ";
        }
      }if (mentor.mentor_speciality_sector_other !== null){
          if (mentor.mentor_speciality_sector_other !== "") {
            fullMentorSpecialitity += mentor.mentor_speciality_sector_other + ", ";
          }
      }if(fullMentorSpecialitity === ''){
        fullMentorSpecialitity = '—';
      }else{
        fullMentorSpecialitity = fullMentorSpecialitity.replace(/..$/,".");
      }

      return fullMentorSpecialitity;
    }else{
      return '—'
    }
  }

  renderCompanies(){
      const companies = this.state.companies;

      return(
        <div className="card-body">
          <h5 className="card-title font-bold"><span className="lstick"></span>Empresas</h5>

          <KCompanyList companies={companies}
                        requestStatus={'success'}
                        shortView={true}
                        hoverStyle={false}
                        stripedStyle={false}
                        borderlessStyle={false}
                        managerView={true}
                        emptyListTitle={'El usuario no cuenta con empresas registradas'}
                        />
        </div>
      )
  }

  renderUserProfile(user){
    const dateOfBirth = this.getBday(user);

    return(
      <div className='card-body'>
        <h5 className="card-title font-bold"><span className="lstick"></span>Información personal</h5>

        <div className="form-group form-horizontal">
          <FormInput
            label="Fecha de Nacimiento"
            editMode={false}
            value={dateOfBirth}
            emptyValueLabel={'—'}
          />
        </div>

        {/*
        <div className='form-group form-horizontal'>
          <FormInput
            label="CURP"
            editMode={false}
            value={user.goverment_id_curp}
            transformMethod={ transformToUpperCase }
            emptyValueLabel={'—'}
          />
        </div> */}

        <div className='form-group form-horizontal'>
          <FormInput
            label="Teléfono"
            editMode={false}
            value={user.phone}
            transformDisplayMethod={transformToPhone}
            transformMethod={editTransformToPhoneNumber}
            emptyValueLabel={'—'}
           />
        </div>

        {/*
        <div className='form-group form-horizontal'>
          <FormInput
            label="LinkedIn"
            editMode={false}
            value={user.social_linkedin}
            transformMethod={transformAddHttps}
            transformDisplayMethod={ transformToAnchor }
            emptyValueLabel={'—'}
          />
        </div> */}

        {/* {this.renderId(user)} */}
      </div>
    )
  }

  renderCV(user){
    let pdfName;
    if(user.hasOwnProperty('mentor_cv_file')) {
      pdfName = ( user.mentor_cv_file === '' || user.mentor_cv_file === null) ? '—' : 'Descargar archivo';
    } else {
      pdfName = '—'
    }

    if(user.mentor_cv_file === '' || user.mentor_cv_file === null){
      return(
        <div className='form-group'>
          <label>CV</label>
          <p>{pdfName}</p>
        </div>
      )
    }else{

      return(
        <div className='form-group'>
          <label>CV</label>
          <a href={user.mentor_cv_file} >{pdfName}</a>
        </div>
      )
    }
  }

  renderId(user){
    let pdfName;
    if(user.hasOwnProperty('id_scan')) {
     pdfName = ( user.id_scan === null ) ? '—' : 'Descargar archivo';
    } else {
      pdfName = '—'
    }

    if(user.id_scan === null){
      return(
        <div className='form-group'>
          <label>Identificación con fotografía</label>
          <p>{pdfName}</p>
        </div>
      )
    }else{
      return(
        <div className='form-group'>
          <label>Identificación con fotografía</label>
          <a href={user.id_scan} >{pdfName}</a>
        </div>
      )
    }
  }
  renderUserMentorIsCompany(user) {
      if (user.mentorIsCompany) {
          return (
              <div className='card-body'>
                  <h5 className="card-title font-bold"><span className="lstick"></span>Información de la empresa</h5>
                  <div className="form-group form-horizontal">
                      <FormInput
                          label="Nombre"
                          editMode={false}
                          value={user.mentorCompanyName}
                          emptyValueLabel={'—'}
                      />
                  </div>
                  <div className="form-group form-horizontal">
                      <FormInput
                          label="Sitio web"
                          editMode={false}
                          value={user.mentorCompanyWebsite}
                          emptyValueLabel={'—'}
                      />
                  </div>
                  <div className="form-group form-horizontal">
                      <FormInput
                          label="Dirección"
                          editMode={false}
                          value={user.mentorCompanyAddress}
                          emptyValueLabel={'—'}
                      />
                  </div>
                  <div className="form-group form-horizontal">
                      <FormInput
                          label="Nombre de contacto"
                          editMode={false}
                          value={user.mentorCompanyContactName}
                          emptyValueLabel={'—'}
                      />
                  </div>

                  <div className="form-group form-horizontal">
                      <FormInput
                          label="Teléfono"
                          editMode={false}
                          value={user.mentorCompanyPhone}
                          emptyValueLabel={'—'}
                      />
                  </div>
                  <div className="form-group form-horizontal">
                      <FormInput
                          label="E-mail de la empresa"
                          editMode={false}
                          value={user.mentorCompanyEmail}
                          emptyValueLabel={'—'}
                      />
                  </div>
                  <div className="form-group form-horizontal">
                      {
                          user.mentorCompanyPrograms.map((program) =>{
                              return <li key={program}>{program}</li>
                          })
                      }
                  </div>
                  <div className="form-group form-horizontal">
                      {
                          user.mentorCompanyPhases.map((p) => this.state.phases.find((_p) => p === _p.value)).map((phase) =>{
                              return <li key={phase.key}>{phase.label}</li>
                          })
                      }
                  </div>

              </div>
          )
      }
  }
  renderUserMentor(user){
      if(user.mentorIsCompany){
          return null;
      }
    const specialities = this.renderSpeciality(user);

    if(user.hasOwnProperty('roles')){
      if(user.roles.includes('mentor')){
       return(
         <div className='card-body'>
           <h5 className="card-title font-bold"><span className="lstick"></span>Información profesional</h5>

           <div className="form-group form-horizontal">
             <FormInput
               label="Biografía"
               editMode={false}
               value={user.mentor_biography}
               emptyValueLabel={'—'}
             />
           </div>

           <div className="form-group form-horizontal">
             <FormInput
               label="Áreas de especialidad"
               editMode={false}
               value={specialities}
             />
           </div>

           {this.renderCV(user)}
         </div>
       )
     }else{
       return null
     }
    }else{
      return null
    }
  }

  renderProfile(){
    const user = this.state.user;

    return(
      <div className="card">
        <div className='close-btn' onClick={() => this.handleCloseSidebar(false)} style={{cursor: 'pointer'}}>
          <i className="mdi mdi-close" style={{fontSize: '20px', color: '#67757c'}}></i>
        </div>

        <div className="card-body">
          <center>
            <KImage src={user.profileUrlThumbnail} className="img-circle" alt="user_img" type={"user_"+user.gender}/>
            <h4 className="card-title m-t-10 font-bold">{renderFullNameOrEmail(user)}</h4>
            <h6 className="card-subtitle">{user.email}</h6>
          </center>
        </div>
        <div className="card-body">
          {/* Tab container start*/}
          <Tab.Container defaultActiveKey={this.props.activeKey}>
              <Nav className="nav" bsPrefix='tabCustom'>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="profile" id='profile'>Perfil</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link className="nav-link" eventKey="companies" id='companies'>Empresas</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="tab-content">
                <Tab.Pane eventKey="profile">
                  { this.renderUserProfile(user)}
                    <div className="form-group form-horizontal">
                        <div className='card-body'>
                            <FormInput
                                label="¿El mentor es empresa?"
                                editMode={false}
                                value={user.mentorIsCompany ? 'Si' : 'No'}
                                emptyValueLabel={'—'}
                            />
                        </div>
                    </div>
                    { this.renderUserMentor(user)}
                  { this.renderUserMentorIsCompany(user)}
                </Tab.Pane>
                <Tab.Pane eventKey="companies">
                  { this.renderCompanies()}
                </Tab.Pane>
              </Tab.Content>
          </Tab.Container>
          {/* Tab container end*/}
        </div>
      </div>
    )
  }

  renderLoader(){
    return(
      <div className="card sidebar_loader_wrapper">
        <BounceLoader
                 sizeUnit={"px"}
                 size={150}
                 color={'#3cc'}
                 loading={true}
               />
      </div>
    )
  }

  renderContent(){
    const loadState = this.state.loadState;

    switch (loadState) {
      case 'loading':
        return this.renderLoader();
      case 'loaded':
        return this.renderProfile();
      case 'failed':
        return (null)
      default:
        return (null)
    }
  }

  render() {
    return (
      <SlidingPanel
        type={'right'}
        isOpen={this.props.showSidebar}
        size={this.state.size}
        backdropClicked={() => this.handleCloseSidebar(false)}>
        <div className='panel-container'>
          {this.renderContent()}
        </div>
      </SlidingPanel>
    );
  }
}

KProfileSidebar.propTypes = {
  /** Close the sidebar. */
  closeSidebar       : PropTypes.func,

  /** When true the sidebar will show itself. */
  showSidebar        : PropTypes.bool,

  /** The user id. */
  user_id            : PropTypes.string,

  /** The active key of the tab. */
  activeKey          : PropTypes.string,

};

KProfileSidebar.defaultProps = {
  closeSidebar      : () => {},
  showSidebar       : false,
  user_id           : '',
  activeKey         : 'profile',
};

export default KProfileSidebar;
