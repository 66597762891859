/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, December 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { renderFullNameOrEmail } from '../utils/Utils.js';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import ProgramsPicker from '../components/ProgramsPicker';

/**
 *  Modal to join a partner, mentor.
 *  Or assign a facilitator to a program.
 */

class KAssignProgramModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProgramsIds:[],
      programsChanged:false
    }
    this.hideModal                     =  this.hideModal.bind(this);
    this.handleCloseModal              =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose        =  this.executeAfterModalClose.bind(this);
    this.onChangeProgram               =  this.onChangeProgram.bind(this);
    this.addProgramPermissions         =  this.addProgramPermissions.bind(this);
    this.confirmSwal                   =  this.confirmSwal.bind(this);
    this.onClickAssign                 =  this.onClickAssign.bind(this);
    this.joinPrograms                  =  this.joinPrograms.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.programsChanged){
      return {
        selectedProgramsIds: state.selectedProgramsIds
      };
    }else{
      return{
        selectedProgramsIds: props.programsSelected
      }
    }
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.setState(
      {
        selectedProgramsIds:[],
        programsChanged:false
      }, () => this.props.closeModal(false)
    );
  }

  onChangeProgram(programs){
    this.setState({selectedProgramsIds:programs,programsChanged:true});
  }

  onClickAssign(){
    let user_id = this.props.user._id;
    let programs_ids = this.state.selectedProgramsIds.join("|");

    if(programs_ids !== ''){
      //Check if the user type
      if(this.props.userType === 'facilitator'){
        return this.addProgramPermissions(user_id,programs_ids);
      }else if(this.props.userType === 'mentor'){
        return this.joinPrograms(user_id,programs_ids,true);
      }else if(this.props.userType === 'partner'){
        return this.joinPrograms(user_id,programs_ids,false);
      }
    }else{
      return this.confirmSwal(user_id,programs_ids);
    }
  }

  joinPrograms(user_id,programs_ids,join_as_mentor){
    KomunidadApi.joinPrograms(user_id,programs_ids,'',join_as_mentor).then((res) => {
      if(res.body.success){
        Swal.fire({
          title: 'Programa asignado',
          text: "El programa ha sido asignado.",
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          onClose: () => {
            this.setState({
              programsChanged:false
            });
            this.handleCloseModal(false);
            this.props.onAddProgram();
          }
        });
      }else{
        if(res.status === 403){
          Swal.fire({
            title: 'Inscripciones cerradas',
            text: res.body.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          });
        }else{
          Swal.fire({
            title: 'Error',
            text: "El programa no pudo ser asignado.",
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          });
        }
      }
    });
  }

  confirmSwal(user_id,programs_ids){
    let txt = '';
    if(this.props.userType === 'facilitator'){
      txt = 'El director no podrá acceder a ningún programa.';
    }else if(this.props.userType === 'partner'){
      txt = 'El emprendedor no será asignado a ningún programa.';
    }else if(this.props.userType === 'mentor'){
      txt = 'El aliado no será asignado a ningún programa.';
    }

    Swal.fire({
      title: '¿Estás seguro(a)?',
      text: txt,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        if(this.props.userType === 'facilitator'){
          return this.addProgramPermissions(user_id,programs_ids);
        }else if(this.props.userType === 'mentor'){
          return this.joinPrograms(user_id,programs_ids,true);
        }else if(this.props.userType === 'partner'){
          return this.joinPrograms(user_id,programs_ids,false);
        }
      }
    })
  }

  addProgramPermissions(user_id,programs_ids){
    KomunidadApi.addProgramPermissions(user_id,programs_ids).then((res) => {
      if(res.success){
        Swal.fire({
          title: 'Programa asignado',
          text: "El programa ha sido asignado.",
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          onClose: () => {
            this.setState({
              programsChanged:false
            });
            this.handleCloseModal(false);
            this.props.onAddProgram();
          }
        });
      }else{
        Swal.fire({
          title: 'Error',
          text: "El programa no pudo ser asignado.",
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'error',
        });
      }
    });
  }
  renderTitle(){
    if(this.props.userType === 'facilitator'){
      return "Asignar programas a director";
    }else if(this.props.userType === 'mentor'){
      return "Asignar programas a aliado";
    }else if(this.props.userType === 'partner'){
      return "Asignar programas a emprendedor";
    }
  }

  renderContent(){
    const user = this.props.user;

    return(
      <div className="card-body">
        <h5 className='m-b-20'>
          Asignar programas a <b>{renderFullNameOrEmail(user)}</b>
        </h5>
        <ProgramsPicker showInactivePrograms={true}
                        onChange={(program) => {this.onChangeProgram(program)}}
                        multipleProgramSelected={this.props.programsSelected}
                        isMultiple={true}>
        </ProgramsPicker>
      </div>
    )
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.renderTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          <div className="btn btn-primary btn-save" onClick={() => this.onClickAssign()}>Asignar</div>
        </Modal.Footer>
      </Modal>
    );
  }
}

KAssignProgramModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** User object*/
  user             : PropTypes.object,
  /** Ids of the programs the user has assing*/
  programsSelected : PropTypes.array,
  /** Type of user to be assign:
      'partner', 'mentor' or 'facilitator'*/
  userType         : PropTypes.string,
  /** Callback on user assigned*/
  onUserAssign     : PropTypes.func,
  /*Callback refresh facilitators*/
  onAddProgram     : PropTypes.func,
};

KAssignProgramModal.defaultProps = {
  closeModal        : () => {},
  showModal         : false,
  user              : {},
  programsSelected  : [],
  userType          : 'partner', //partner, mentor or facilitator
  onUserAssign      : () => {},
  onAddProgram      : () => {}
};

export default KAssignProgramModal;
