/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Octuber 2019
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import CompanyPhasesChecker from '../../utils/CompanyPhasesChecker.js';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import ReactMapGL,{ NavigationControl, Popup} from 'react-map-gl';
import BounceLoader from 'react-spinners/BounceLoader';

import '../../scss/pages/_style.css';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import ProgramsPicker from '../../components/ProgramsPicker';
// import StatePicker from '../../components/StatePicker';
import KMarker from '../../components/KMarker';
import KImage from '../../components/KImage';
import KPhasesPicker from '../../components/KPhasesPicker';
import KSectorsPicker from '../../components/KSectorsPicker';

const MAPBOX_TOKEN = 'pk.eyJ1IjoicGFuemVyIiwiYSI6ImNrN2RsZzcwMDIxMHAzZW9naWh1amhkejIifQ.2MkaNWU65XhWigJvQUm1_A';
//Your mapbox token here

class ManagerDataView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      users:[],
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      ownerGender:'both',
      mentorsGender:'Ambos',
      phase:null,
      sector: null,
      file:true,
      fileCSV:false,
      program:'',
      dateRegister:null,
      addressState:'Cualquiera',
      stateIsoCode:'',
      exportDataLink:'',
      companies:[],
      company:{},
      mapStatus:'preview',//preview, loading, loaded
      resultCompanies:0,
      viewport: {
       latitude: process.env.REACT_APP_LATITUDE,
       longitude: process.env.REACT_APP_LONGITUDE,
       zoom: 4,
      },
      installationCoordinates:{
        latitude:0,
        longitude:0
      },
      showPopup: false
    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.handleOptionChange              = this.handleOptionChange.bind(this);
    this.generateFile                    = this.generateFile.bind(this);
    this.exportAll                       = this.exportAll.bind(this);
    this.getAllCompanies                 = this.getAllCompanies.bind(this);
    this.handleSelectFile                = this.handleSelectFile.bind(this);
    this.generateExportDataLink          = this.generateExportDataLink.bind(this);
    this.getCompaniesFiltered            = this.getCompaniesFiltered.bind(this);
    this.getCompaniesWithLocation        = this.getCompaniesWithLocation.bind(this);
    this.onChangeState                   = this.onChangeState.bind(this);
    this.handlePhasesChange              = this.handlePhasesChange.bind(this);
    this.handleSectorsChange             = this.handleSectorsChange.bind(this);
    this.stateCollection = [
      "Aguascalientes","Baja California","Baja California Sur","Campeche","Coahuila de Zaragoza","Colima",
      "Chiapas","Chihuahua","Ciudad de México","Durango","Guanajuato","Guerrero","Hidalgo","Jalisco","México",
      "Michoacán de Ocampo","Morelos","Nayarit","Nuevo León","Oaxaca","Puebla","Querétaro","Quintana Roo",
      "San Luis Potosí","Sinaloa","Sonora","Tabasco","Tamaulipas","Tlaxcala","Veracruz de Ignacio de la Llave",
      "Yucatán","Zacatecas",
    ];
    this.isAuthorized                    = this.isAuthorized.bind(this);

  }

  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator")
    )
  }
  componentDidMount(){
    this.generateExportDataLink();
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
            });
          }else{
            this.redirectToProfile();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }
  redirectToProfile(){
    this.props.history.push('/profile');
  }
  generateExportDataLink(){
    let link   = `${KomunidadApi.API_URL}/programs/export/data?`;
    let params = {
      "export":"xlsx"
    };

    if(this.state.stateIsoCode !== ""){
      params["filter_states"] = this.state.stateIsoCode;
    }
    if(this.state.phase !== null){
      params["filter_phase"] = this.state.phase;
    }
    if(this.state.sector !== null){
      params["filter_sectors"] = this.state.sector;
    }
    if(this.state.dateRegister !== null){
      params["filter_registration_date"] = this.state.dateRegister;
    }
    if(this.state.ownerGender !== "both"){
      params["filter_owner_gender"] = this.state.ownerGender;
    }
    if(this.state.mentorsGender !== "Ambos"){
      params["filter_mentors_gender"] = this.state.mentorsGender;
    }
    params["programs_ids"] = this.state.program;
    let p = new URLSearchParams(params);
    link = `${link}${p.toString()}`;
    this.setState({
      exportDataLink:link
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.setState({[data.model]:value});
  }

  handleOptionChange(event) {
    this.setState({[event.target.name]:event.target.value}, () =>{
      this.generateExportDataLink();
    });
  }
  handleRadioState(field,value) {
    this.setState({[field]:value}, () =>{
      this.generateExportDataLink();
    });
  }
  handlePhasesChange(phases){
    if(phases !== null){
      phases = phases.map((p) => p.value).join("|");
    }
    if(phases === "all"){
      phases = null;
    }
    this.setState({phase:phases},() =>{
      this.generateExportDataLink();
    })
  }
  handleSectorsChange(sectors){
    if(sectors !== null){
      sectors = sectors.map((p) => p.value).join("|");
    }
    if(sectors === "all"){
      sectors = null;
    }
    this.setState({sector:sectors},() =>{
      this.generateExportDataLink();
    })
  }
  handleOptionState(event) {
    this.setState({[event.target.name]:event.target.value}, () =>{
      this.generateExportDataLink();
    });
  }

  handleSelectFile(event){
    if(event.target.name === "file"){
      this.setState({
        file:event.target.checked,
        fileCSV: !event.target.checked
      })
    }else{
      this.setState({
        fileCSV:event.target.checked,
        file: !event.target.checked
      })
    }
    // this.setState({[event.target.name]:event.target.checked});
  }

  generateFile(){
    window.open(this.state.exportDataLink, '_blank');
  }

  exportAll(){
    let link   = `${KomunidadApi.API_URL}/companies/manager/index?`;
    let params = {
      "export":"xlsx"
    };
    let p = new URLSearchParams(params);
    link = `${link}${p.toString()}`;

    window.open(link, '_blank');
  }

  getAllCompanies(){
    //get all the companies to show in the map
    //set the loader
    this.setState({mapStatus:'loading',showPopup:false});

    let params = {
      "export":"map"
    };

    this.getCompaniesWithLocation(params);
  }

  getCompaniesWithLocation(params){
    //get the companies with location
    KomunidadApi.getCompaniesWithLocation(params).then((res) => {
      //response success
      if(res.success){
        if(res.hasOwnProperty('companiesWithLocation')){
          let companies = res.companiesWithLocation;

          //filter companies with coordinates[0,0]
          let _c = companies.filter( company => {
            if((company.coordinates[0] !== 0) && (company.coordinates[1] !== 0)){
              return company
            }
          });

          this.setState({
            companies:_c,
            mapStatus:'loaded',
            resultCompanies:res.companies.length,
            viewport:{
              latitude: res.installationCoordinates[0],
              longitude: res.installationCoordinates[1],
              zoom: 4,
            },
            installationCoordinates:{
              latitude: res.installationCoordinates[0],
              longitude: res.installationCoordinates[1],
            }
          });
        }
      }else{
        this.setState({mapStatus:'preview',resultCompanies:0});
      }

    });
  }

  getCompaniesFiltered(){
    //set the loader
    this.setState({mapStatus:'loading',showPopup:false});
    //get the companies
    if(this.state.programs !== ''){

      let params = {
        "export":"map"
      };

      if(this.state.stateIsoCode !== ""){
        params["filter_states"] = this.state.stateIsoCode;
      }
      if(this.state.phase !== null){
        params["filter_phase"] = this.state.phase;
      }
      if(this.state.sector !== null){
        params["filter_sectors"] = this.state.sector;
      }
      if(this.state.dateRegister !== null){
        params["filter_registration_date"] = this.state.dateRegister;
      }
      if(this.state.ownerGender !== "both"){
        params["filter_owner_gender"] = this.state.ownerGender;
      }
      if(this.state.mentorsGender !== "Ambos"){
        params["filter_mentors_gender"] = this.state.mentorsGender;
      }
      params["programs_ids"] = this.state.program;

      //get the companies the have coordinates
      this.getCompaniesWithLocation(params);
    }
  }

  onChangeProgram(programs){
    let programsIds = '';

    if(programs.length > 0){
      programsIds = programs.join('|');
    }

    this.setState({program:programsIds}, () => {this.generateExportDataLink()});
  }

  onChangeState(isoCodeArray){
    let isoCode = '';

    if(isoCodeArray.length > 0){
      if(!isoCodeArray.includes('all')){
        isoCode = isoCodeArray.join('|');
      }
    }

    this.setState({stateIsoCode:isoCode}, () => {this.generateExportDataLink()});
  }

  stateToISO31662(state){
    let isoCodes = {
      "Aguascalientes":"MX-AGU",
      "Baja California":"MX-BCN",
      "Baja California Sur":"MX-BCS",
      "Campeche":"MX-CAM",
      "Coahuila de Zaragoza":"MX-COA",
      "Colima":	"MX-COL",
      "Chiapas":"MX-CHP",
      "Chihuahua":"MX-CHH",
      "Ciudad de México":"MX-CMX",
      "Durango":"MX-DUR",
      "Guanajuato":"MX-GUA",
      "Guerrero":"MX-GRO",
      "Hidalgo":"MX-HID",
      "Jalisco":"MX-JAL",
      "México":"MX-MEX",
      "Michoacán de Ocampo":"MX-MIC",
      "Morelos":"MX-MOR",
      "Nayarit":"MX-NAY",
      "Nuevo León":"MX-NLE",
      "Oaxaca":"MX-OAX",
      "Puebla":"MX-PUE",
      "Querétaro":"MX-QUE",
      "Quintana Roo":"MX-ROO",
      "San Luis Potosí":"MX-SLP",
      "Sinaloa":"MX-SIN",
      "Sonora":"MX-SON",
      "Tabasco":"MX-TAB",
      "Tamaulipas":"MX-TAM",
      "Tlaxcala":"MX-TLA",
      "Veracruz de Ignacio de la Llave":"MX-VER",
      "Yucatán":"MX-YUC",
      "Zacatecas":"MX-ZAC"
    }
    return isoCodes[state];
  }

  iso31662ToState(state){
    let isoCodes = {
      "MX-AGU":"Aguascalientes",
      "MX-BCN":"Baja California",
      "MX-BCS":"Baja California Sur",
      "MX-CAM":"Campeche",
      "MX-COA":"Coahuila de Zaragoza",
      "MX-COL":"Colima",
      "MX-CHP":"Chiapas",
      "MX-CHH":"Chihuahua",
      "MX-CMX":"Ciudad de México",
      "MX-DUR":"Durango",
      "MX-GUA":"Guanajuato",
      "MX-GRO":"Guerrero",
      "MX-HID":"Hidalgo",
      "MX-JAL":"Jalisco",
      "MX-MEX":"México",
      "MX-MIC":"Michoacán de Ocampo",
      "MX-MOR":"Morelos",
      "MX-NAY":"Nayarit",
      "MX-NLE":"Nuevo León",
      "MX-OAX":"Oaxaca",
      "MX-PUE":"Puebla",
      "MX-QUE":"Querétaro",
      "MX-ROO":"Quintana Roo",
      "MX-SLP":"San Luis Potosí",
      "MX-SIN":"Sinaloa",
      "MX-SON":"Sonora",
      "MX-TAB":"Tabasco",
      "MX-TAM":"Tamaulipas",
      "MX-TLA":"Tlaxcala",
      "MX-VER":"Veracruz de Ignacio de la Llave",
      "MX-YUC":"Yucatán",
      "MX-ZAC":"Zacatecas",
    }
    return isoCodes[state];
  }

  renderDownloadButtons() {
    if(this.state.me.roles.includes("manager")){
      return(
        <div className="flex" style={{justifyContent:"flex-end"}}>
           <Dropdown as={ButtonGroup} className="m-r-10">
           <Button className='btn btn-info btn-save d-inline-flex align-items-center'
                   onClick={this.generateFile}
                   style={{marginRight:0}}>
             <i className="mdi mdi-file-excel m-r-5" style={{fontSize: '18px'}}></i>
              Descargar
           </Button>

           <Dropdown.Toggle split className='btn btn-info btn-save' style={{color:"white"}}  />
           <Dropdown.Menu>
             <Dropdown.Item onClick={this.exportAll}>Descargar todos los datos de la sede</Dropdown.Item>
           </Dropdown.Menu>
           </Dropdown>
           <Dropdown as={ButtonGroup}>
           <Button className='btn btn-info btn-aux d-inline-flex align-items-center'
                   onClick={() => this.getCompaniesFiltered()}
                   style={{marginRight:0}}>
             <i className="mdi mdi-google-maps m-r-5" style={{fontSize: '18px'}}></i>
              Visualizar
           </Button>

           <Dropdown.Toggle split className='btn btn-info btn-aux' id="dropdown-split-basic"  />
           <Dropdown.Menu>
             <Dropdown.Item onClick={() => this.getAllCompanies()}>Visualizar todas las empresas</Dropdown.Item>
           </Dropdown.Menu>
           </Dropdown>
         </div>
      )
    } else {
      return(
        <div className="flex" style={{justifyContent:"flex-end"}}>
           <Button className='btn btn-info btn-save d-inline-flex align-items-center'
                   onClick={this.generateFile}
                   style={{marginRight:'15px'}}>
             <i className="mdi mdi-file-excel m-r-5" style={{fontSize: '18px'}}></i>
              Descargar
           </Button>
           <Button className='btn btn-info btn-aux d-inline-flex align-items-center'
                   onClick={() => this.getCompaniesFiltered()}
                   style={{marginRight:0}}>
             <i className="mdi mdi-google-maps m-r-5" style={{fontSize: '18px'}}></i>
              Visualizar
           </Button>
         </div>
      )
    }
  }

  renderContent(){
    return(
      // Elige los datos y el tipo de archivo a exportar:
      <div className="card-body">
         <div className='form-group'>
           <div className='text-muted m-b-20'>Todos los campos son opcionales.</div>
             <ProgramsPicker showInactivePrograms={true}
                             onChange={(program) => {this.onChangeProgram(program)}}
                             isMultiple={true}>
             </ProgramsPicker>
           <div className="form-group">
             <KPhasesPicker onChange={(phases) => this.handlePhasesChange(phases)}></KPhasesPicker>
           </div>
           <div className="form-group">
             <KSectorsPicker onChange={(sectors) => this.handleSectorsChange(sectors)}></KSectorsPicker>
           </div>
           <div className="form-group">
               <label>La empresa operaba antes del programa</label>
               <div>
                 <input
                    id="dateRegister_yes"
                    onClick={() => this.handleRadioState('dateRegister','lt_doe')}
                    checked={this.state.dateRegister === 'lt_doe'}
                    name="dateRegister_yes"
                    type="radio"
                    className="with-gap radio-col-purple"
                 />
                 <label
                    for="dateRegister_yes"
                    id="radio_dateRegister_yes">
                    Si
                  </label>&nbsp;
                  <input
                     id="dateRegister_no"
                     onClick={() => this.handleRadioState('dateRegister','gt_doe')}
                     checked={this.state.dateRegister === 'gt_doe'}
                     name="dateRegister_no"
                     type="radio"
                     className="with-gap radio-col-purple"
                  />
                  <label
                     for="dateRegister_no"
                     id="radio_dateRegister_no">
                     No
                   </label>&nbsp;
                   <input
                      id="dateRegister_any"
                      onClick={() => this.handleRadioState('dateRegister',null)}
                      checked={this.state.dateRegister === null}
                      name="dateRegister_any"
                      type="radio"
                      className="with-gap radio-col-purple"
                   />
                   <label
                      for="dateRegister_any"
                      id="radio_dateRegister_any">
                      No importa
                    </label>
               </div>
           </div>
         </div>
         <div className="form-group">
             <label>El fundador es</label>
             <div>
               <input
                  id="founder_gender_male"
                  onClick={() => this.handleRadioState('ownerGender','male')}
                  checked={this.state.ownerGender === 'male'}
                  name="group_founder_gender"
                  type="radio"
                  className="with-gap radio-col-purple"
               />
               <label
                  for="founder_gender_male"
                  id="radio_founder_gender_male">
                  Hombre
                </label>&nbsp;
               <input
                  id="founder_gender_female"
                  onClick={() => this.handleRadioState('ownerGender','female')}
                  checked={this.state.ownerGender === 'female'}
                  name="group_founder_gender"
                  type="radio"
                  className="with-gap radio-col-purple"
               />
               <label
                  for="founder_gender_female"
                  id="radio_founder_gender_female">
                  Mujer
               </label>&nbsp;
               <input
                  id="founder_gender_any"
                  onClick={() => this.handleRadioState('ownerGender','both')}
                  checked={this.state.ownerGender === 'both'}
                  name="group_founder_gender"
                  type="radio"
                  className="with-gap radio-col-purple"
               />
               <label
                  for="founder_gender_any"
                  id="radio_founder_gender_any"
                  onClick={this.handleOptionChange} >
                  Cualquiera
                </label>
             </div>
         </div>

         {this.renderDownloadButtons()}

      </div>
    )
  }
  // render () {
  //   if(this.state.authorized){
  //     return(
  //       <div className="fix-header card-no-border fix-sidebar height100">
  //         <div id="main-wrapper" className="height100">
  //           <Header
  //               user={this.state.me}
  //               onBurgerClicked={() => this.handleSidebarVisible()}
  //               onResize={(w) => this.handleHeaderResize(w)}/>
  //           <SideBar user={this.state.me} visible={this.state.sidebarVisible}/>
  //           {/* Page wrapper  */}
  //           <div className={this.state.sidebarVisible ? "page-wrapper color-gray sidebar-wrapper-open": "page-wrapper color-gray"}>
  //             {/* Container fluid  */}
  //             <div className="container-fluid">
  //               {/* Bread crumb*/}
  //               <div className="row page-titles">
  //                   <div className="col-md-5 align-self-center">
  //                       <h3 className="text-themecolor">Datos</h3>
  //                   </div>
  //               </div>
  //               {/* End bread crumb*/}
  //
  //               {/* Row */}
  //               <div className="row">
  //                 {/* Column */}
  //                 <div className="col-lg-4 col-xlg-3">
  //                   <div className="card">
  //                       <Tab.Container defaultActiveKey="stats">
  //                         <Nav className="nav" bsPrefix='tabCustom' >
  //                           <Nav.Item className="nav-item">
  //                             <Nav.Link className="nav-link" eventKey="stats" id="stats">Generar datos</Nav.Link>
  //                           </Nav.Item>
  //                         </Nav>
  //
  //                         <Tab.Content className="tab-content">
  //                           <Tab.Pane eventKey="stats">
  //                              {this.renderContent()}
  //                           </Tab.Pane>
  //                         </Tab.Content>
  //                       </Tab.Container>
  //                   </div>
  //                 </div>
  //                 <div className="col-lg-8 col-xlg-9">
  //                   <div className='card'>
  //                     <div className='card-body'>
  //                       <Button className='btn btn-save  m-b-20' onClick={this.generateFile}>Generar archivo de excel</Button>
  //
  //                       {/*<h3 className='m-t-20 m-b-20'>Exportar todos </h3>*/}
  //                       <Button className='btn btn-save m-b-20' onClick={this.exportAll}>Exportar todos los datos de la sede</Button>
  //
  //                       Mapa
  //                     </div>
  //
  //                   </div>
  //                 </div>
  //                 {/* End column */}
  //               </div>
  //               {/* End row */}
  //
  //             </div>
  //             {/* End container fluid  */}
  //           </div>
  //           {/* End page wrapper  */}
  //
  //         </div>
  //       </div>
  //     )
  //   }else{
  //     return null
  //   }
  // // }
  renderMapContent(){
    switch (this.state.mapStatus) {
      case 'preview': return this.renderMapBoxPreview();
      case 'loading': return this.renderMapBoxLoader();
      case 'loaded': return this.renderMapBox();
      default: return null;
    }
  }
  renderAddress(company){
    let state = ''
    if( company.address_state !== ''){
      state = this.iso31662ToState(company.address_state);
    }
    return(
      <div className=''>
        {company.address_street + ' ' + company.address_number_ext + ' ' + company.address_number_int }
        <br/>
        {company.address_neighborhood}
        <br/>
        {company.address_zip_code + ' ' + company.address_municipality+ ', '+ state }
      </div>
    )
  }
  renderCompanyInfo(company){
    return(
      <div className='popup-map  m-t-5'>
        <div className='popup-row'>
          <KImage src={company.logoThumbnail} type='company' className='popup-image' alt="company_image"/>

          <div className='popup-column m-l-5'>
            <div className='font-bold'>{company.name}</div>
            <div className='text-muted'>{CompanyPhasesChecker.getCurrentPhaseLabel(company)}</div>
          </div>
        </div>
        { this.renderAddress(company) }
      </div>
    )
  }
  renderMapBox(){
    const {showPopup} = this.state;
    const {companies} = this.state;
    const {company} = this.state;
    let companyCount = this.state.resultCompanies - companies.length;
    let labelTxt = `${companyCount} empresas sin ubicación`;
    if(companyCount === 1){
      labelTxt = `${companyCount} empresa sin ubicación`;
    }

    return(
      <div>
        <ReactMapGL
          {...this.state.viewport}
          width="100%"
          height="530px"
          mapStyle="mapbox://styles/panzer/ck7e07mrw1nxj1iqvac5593qg"
          onViewportChange={viewport => this.setState({viewport})}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <div style={{position: 'absolute', right: 0}}>
            <NavigationControl showCompass={false}/>
          </div>

          { companies.map((company,item) => (
            <KMarker latitude={company.coordinates[1]}
                     longitude={company.coordinates[0]}
                     draggable={false}
                     onClickMarker={() => {this.setState({showPopup: true,company:company})}}>
             </KMarker>

            )
          )}
          { showPopup && <Popup
            latitude={company.coordinates[1]}
            longitude={company.coordinates[0]}
            closeButton={true}
            closeOnClick={false}
            onClose={() => this.setState({showPopup: false})}
            offsetTop={-32}
            anchor="left" >
            { this.renderCompanyInfo(company) }
          </Popup>}
          <KMarker latitude={this.state.installationCoordinates.latitude}
                   longitude={this.state.installationCoordinates.longitude}
                   draggable={false}
                   color={'#707cd2'}
                   icon={'building'}
                   label={labelTxt}>
           </KMarker>
        </ReactMapGL>
        <div className="stats-bar">
          <div className="row text-center">
            <div className="col-lg-6 col-md-6">
              <div className="m-t-20">
                <h6 className="m-b-0">Empresas encontradas</h6>
                <h3 className="m-b-0">{this.state.resultCompanies}</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="m-t-20">
                <h6 className="m-b-0">Empresas con ubicación</h6>
                <h3 className="m-b-0">{companies.length}</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  renderMapBoxPreview(){
    return(
      <div className='map-preview'>
        <h3>Filtra las empresas que quieres visualizar en el mapa</h3>
      </div>
    )
  }

  renderMapBoxLoader(){
    return(
      <div className='map-preview'>
        <BounceLoader
                sizeUnit={"px"}
                size={150}
                color={'#3cc'}
                loading={true}
              />
      </div>
    )
  }

  render () {
    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                      <h3 className="text-themecolor">Datos</h3>
                    </div>
                    <div className="col-md-7 align-self-center">

                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-4 col-xlg-3">
                    <div className="card">
                      {this.renderContent()}
                    </div>
                  </div>
                  <div className="col-lg-8 col-xlg-9">
                    <div className='card'>
                      <div className='card-body'>
                        {this.renderMapContent()}
                      </div>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default ManagerDataView;
