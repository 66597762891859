/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>
 * & Darien Miranda <darien@startuplab.mx>, November 2019
 */

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../scss/components/_ktablesmartselect.scss';
import PropTypes from 'prop-types';

class KTableSmartSelect extends React.Component {
  constructor(p){
    super(p);
    this.state = {
      cb_state: 'none' // 'none', 'all', 'mixed'
    }
    this.renderDropDown   = this.renderDropDown.bind(this);
    this.onCbClick        = this.onCbClick.bind(this);
    this.onOptionSelected = this.onOptionSelected.bind(this);
    this.changeCbState    = this.changeCbState.bind(this);


  }
  static getDerivedStateFromProps(props, current_state){
    if(current_state.cb_state !== props.cbState){
      return{
        cb_state: props.cbState,
      };
    }
  }
  changeCbState(cbState){
    this.setState({
      cb_state:cbState
    })
  }
  onCbClick(){
    let currentState = this.state.cb_state;
    let nextState    = '';
    if(currentState === "mixed" || currentState === "all"){
      nextState = "none";
    }
    if(currentState === "none"){
      nextState = "all";
    }
    this.setState({
      cb_state:nextState
    })
    this.props.onCbClick(nextState);
  }
  renderCheckbox(){
    switch (this.state.cb_state) {
      case 'none':
        return (
          <FontAwesomeIcon icon={['far','square']} size="lg"/>
        )
      case 'all':
        return (
          <FontAwesomeIcon icon={'check-square'} size="lg"/>
        )
      case 'mixed':
        return (
          <FontAwesomeIcon icon={'minus-square'} size="lg"/>
        )
      default: 
        return (
          <FontAwesomeIcon icon={['far','square']} size="lg"/>
        )
    }
  }
  onOptionSelected(option,index){
    this.setState({
      cb_state:option.actionType
    })
    this.props.onOptionSelected(option);
  }
  renderDropDown(){
    return(
      <Dropdown style={{display:"flex"}}>
        <div onClick={() => this.onCbClick()} className="kts_cb">{this.renderCheckbox()}</div>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            this.props.options.map((option,index) =>
              (
                <Dropdown.Item onClick={() => this.onOptionSelected(option,index)}>{option.label}</Dropdown.Item>
              )
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  render(){
    return (
      <div style={{width:"60px"}}>
        {this.renderDropDown()}
      </div>
    );

  }
}

KTableSmartSelect.propTypes = {
  cbState           : PropTypes.string,
  onCbClick         : PropTypes.func,
  onOptionSelected  : PropTypes.func,
  options           : PropTypes.array
};

KTableSmartSelect.defaultProps = {
  cbState               : 'none',
  onCbClick             : ()=>{},
  onOptionSelected      : ()=>{},
  options               : []
};

export default KTableSmartSelect;
