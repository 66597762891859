/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
//custom style
import '../scss/components/_resource_card.scss';


class KResourcesRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFolderOpen: false,
      isMouseInside: false
    }

    this.toogleFolderState = this.toogleFolderState.bind(this);
    this.toogleMouseState  = this.toogleMouseState.bind(this);

  }

  toogleFolderState() {
    this.setState({
      isFolderOpen: !this.state.isFolderOpen
    })
  }

  toogleMouseState() {
    this.setState({
      isMouseInside: !this.state.isMouseInside
    })
  }
  renderDate(date){
    let moment = require('moment');
    let _date = new Date(date.replace('Z',''));

    _date = moment(_date).format('LL');

    return _date;
  }

  render() {
    const resource = this.props.resource;
    return(
      <div className="col-md-4 col-lg-4 col-xlg-4">
        <div className="card card-body" style={{padding: '0.75rem'}}>
          <div className="resource-information">
            <Tooltip content={ resource.name } useDefaultStyles className="tooltipText">
              <a target="_blank"
                rel="noopener noreferrer"
                href={ resource.url }
                className="text-muted"
                onMouseDown={ this.toogleFolderState }
                onMouseUp={ this.toogleFolderState }
                onMouseEnter={ this.toogleMouseState }
                onMouseLeave={ this.toogleMouseState }
              >
                <i className={"mdi mdi-folder " + (this.state.isMouseInside ? "resource-link" : "")} style={{fontSize: '35px', cursor: 'pointer'}}></i>
              </a>
            </Tooltip>
          <i className="mdi mdi-link-variant resource-link ml-1" style={{fontSize: '15px'}}/>
          </div>
          <h5 className="card-title resource-text"><b>{ resource.name }</b></h5>
          <h5 className="card-title resource-text">{ resource.description }</h5>
          <small>{this.renderDate(resource.created_at)}</small>
        </div>
      </div>
    )
  }

}

KResourcesRow.propTypes = {
  resource            : PropTypes.object,
  onClickResource     : PropTypes.func,
};

KResourcesRow.defaultProps = {
  resource             : {},
  onClickResource      : ()=>{},
};

export default KResourcesRow;
