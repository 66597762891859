/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano && Juan Hernandez <israel@startuplab.mx> && <juan@startuplab.mx>
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';
import KomunidadApi from '../api/api.js';
import { withRouter } from 'react-router';
import ImagePicker from '../components/ImagePicker.js';
import KImage from '../components/KImage';

// Style
import '../scss/components/_create-course.scss';

//components
import '../scss/components/css-chart.css';
import Swal from 'sweetalert2';

/**
*   Display the information of the course used by the component [KMentorsGrid](/#kmentorsgrid).
*   
*   View the course.
*/

class KCoursesGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    
    this.goToCourse        =      this.goToCourse.bind(this)

  }

  joinCourse(courseId) {
    KomunidadApi.joinCourse(courseId).then( (res) => {
      if(res.success){
        Swal.fire({
          type: 'success',
          title: 'Curso agregado',
          text: 'Ahora serás redirigido al curso',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then(result => {
          this.props.history.push(`/courses/${courseId}`)
        })
      } else {
        Swal.fire({
          type: 'info',
          title: 'Ya te encuentras registrado',
          text: 'Serás redirigido al material del curso',
          customClass: {
            confirmButton: "swalBtnTeal",
            title: "swal-custom-title",
          },
        }).then(result => {
          this.props.history.push(`/courses/${courseId}`)
        });
      }
    });
  }

  goToCourse(path) {
    this.props.history.push(`/courses/${path}`)
  }

  redirectSignup() {
    this.props.history.push('/sign-up');
  }
  
  onClickCourse(courseId) {
    this.props.onClickCourse(courseId);
  }

  renderCourseButton(courseId){
    if(this.props.isMyCourse || this.props.course.is_mine){
      return(
        <button className='btn btn-info btn-save text-white'
                style={{marginLeft: 0, marginRight: ".50rem"}}
                onClick={() => this.goToCourse(courseId)}>Ver curso</button>
      )
    }else{
      if(!this.props.freeMode){
        return(
          <button className='btn btn-info btn-save text-white'
                  style={{marginLeft: 0, marginRight: ".50rem"}}
                  onClick={() => this.joinCourse(courseId)}>Inscribirse</button>
        );
      }else{
        return(
          <button className='btn btn-info btn-save text-white'
                  style={{marginLeft: 0, marginRight: ".50rem"}}
                  onClick={() => this.onClickCourse(courseId)}>Desbloquear</button>
        );
      }
    }
  }
  
  renderCourseName(course){
    if(this.props.isMyCourse || this.props.course.is_mine){
      return(
        <h3 onClick={() => this.goToCourse(course._id)} style={{cursor: 'pointer'}}>
          <p className="box-title m-b-0" style={{fontSize: '21px'}}>{course.name}</p>
        </h3>
      )
    }else{
      return(
        <h3 onClick={() => this.joinCourse(course._id)} style={{cursor: 'pointer'}}>
          <p className="box-title m-b-0" style={{fontSize: '21px'}}>{course.name}</p>
        </h3>
      );
    }
  }
  
  renderProgress(course){

    let percentage = Math.ceil(((course.progress/course.chapters)*100)/5)*5;

    if(this.props.isMyCourse || this.props.course.is_mine) {
      return (
        <div className="col align-self-center">
          <div data-label={percentage+'%'} className={'css-bar m-b-0 css-bar-success css-bar-'+percentage} style={{zIndex: 0}}></div>
        </div>
      )
    } else {
      return (null)
    }
  }

  renderChaptersSize(course) {
    if(this.props.isMyCourse || this.props.course.is_mine) {
      return (
        <small className="text-muted" >Módulos: {course}</small>
      )
    } else {
      return (
        <small className="text-muted" >Módulos: {course.length}</small>
      )
    }
  }

  render() {
    const course = this.props.course;
    const coursePrograms = (this.props.course.programs.length > 0) ? "Programa(s): "+this.props.course.programs.join() : "Curso gratis";
    return(
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div className="card course-card">
          <KImage src={course.bannerUrlThumbnail} className="card-img-top img-responsive" alt="course_img" type={"course"}/>
          {/*<img className="card-img-top img-responsive" src={Banner} style={{maxWidth: '370px'}}/>*/}
          <div className="row card-body">
            <div className="col-md-12 col-lg-12">
              {this.renderCourseName(course)}
              {/* <h3><p className="box-title m-b-0" style={{fontSize: '21px'}}>{course.name}</p></h3> */}
              <div className="row">
                <div className="col-8">
                  {this.renderChaptersSize(course.chapters)}<br/>
                  Etapa: {course.phase}<br/>
                  <small className="text-muted">{coursePrograms}</small>
                  <br/>
                  <div className='m-t-20 mb-2' style={{fontSize:'14px'}}>
                    {this.renderCourseButton(course._id)}
                  </div>
                </div>
                <div className="col-4 text-center">
                  {this.renderProgress(course)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

KCoursesGridRow.propTypes = {
  /** Mentor to be render. */
  course            : PropTypes.object,
  /** Callback when the course is clicked */
  onClickCourse     : PropTypes.func,
  programId         : PropTypes.string,
  isMyCourse        : PropTypes.bool,
  freeMode          : PropTypes.bool,
};

KCoursesGridRow.defaultProps = {
  course             : {},
  onClickCourse      : ()=>{},
  programId          : '',
  isMyCourse         : true,
  freeMode           : false,
};

export default withRouter(KCoursesGridRow);
