/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Octuber 2019
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';


//components
import KReviewRow from './KReviewRow';

/**
*  Show the list of reviews using the row: [kreviewrow](/#kreviewrow).
*
*/

class KReviewList extends React.Component {

  renderFailed(){
    return(
      <div className="text-center m-b-20">
        <h3>Hubo un problema al cargar los comentarios</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderEmptyList(){
    return(
      <div className="text-center m-b-20">
        <h3>Aún no hay comentarios</h3>
        <i className="mdi mdi-account-edit m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>{this.props.subtitleEmptyList}</h5>
      </div>
    )
  }

  render() {
    const reviews = this.props.reviews;

    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(reviews.length > 0){
        return(
          <div className={this.props.managerViewReview ? "card-body px-0" : "card-body" }>
            {reviews.map((item,index) =>
              (
                <KReviewRow   key={index}
                              review={item}
                              showFullReview={this.props.showFullReview}
                              managerViewReview={this.props.managerViewReview}
                              showAll={this.props.showAll}
                />
              )
            )}
          </div>
        )
      }else{
        // empty list
        return this.renderEmptyList();
      }
    }else{
      return this.renderFailed();
    }
  }
}

KReviewList.propTypes = {
  /** Review list. */
  reviews              : PropTypes.array,
  /** Status of the loading data: idle, fetching, success and failed. */
  requestStatus        : PropTypes.string,
  /** Message (subtitle) if the list is empty. */
  subtitleEmptyList    : PropTypes.string,
  /** When is true show the full review. */
  showFullReview       : PropTypes.bool,
  /** When is true show the ManagerView review. */
  managerViewReview    : PropTypes.bool,
  /** Whren this prop is true, pass a prop to show all reviews in one modal*/
  showAll              : PropTypes.bool

};

KReviewList.defaultProps = {
  reviews              : [],
  requestStatus        : 'idle',
  subtitleEmptyList    : '¡Agrega una!',
  showFullReview       : false,
  managerViewReview    : false,
  showAll              : false
};

export default KReviewList;
