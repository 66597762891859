/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juan Hernandez <juan@startuplab.mx>, July 2021
 */

// Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from 'react-tooltip-lite';
import { Button } from 'react-bootstrap';

// Components
import FormInput from './form/FormInput';


class KSocialNetworksField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialNetworks: [],
      resourceName: '',
      networkUrl: '',
    }
  }

  //Updates the state according to the props
  static getDerivedStateFromProps(props, current_state){
    return{
      socialNetworks:props.socialNetworks
    };
  }

  extractDomain(url) {
    var hostname;
    //remove protocol get hostname
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //remove port
    hostname = hostname.split(':')[0];
    //remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  extractDomainName(domain){
    switch (domain) {
      case 'www.facebook.com':
        return 'Facebook'
      case 'www.instagram.com':
        return 'Instagram'
      case 'www.linkedin.com':
        return 'Linkedin'
      case 'www.youtube.com':
        return 'YouTube'
      case 'www.twitter.com':
        return 'Twitter'
      case 'twitter.com':
        return 'Twitter'
      case 'wa.me':
        return 'Whatsapp'
      case 'api.whatsapp.com':
        return 'Whatsapp'
      default:
        return ''
    }
  }

  renderServiceIcon(domain_name){
    switch (domain_name) {
      case 'Facebook':
        return 'mdi-facebook'
      case 'Linkedin':
        return 'mdi-linkedin-box'
      case 'Instagram':
        return 'mdi-instagram'
      case 'YouTube':
        return 'mdi-youtube-play'
      case 'Twitter':
        return 'mdi-twitter'
      case 'Whatsapp':
        return 'mdi-whatsapp'
      default:
        return 'mdi-link-variant'
    }
  }

  onChangeForm(data) {
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.setState({ [data.model]: value });
  }

  renderSocialIcon(socialNetwork){ 
    return (
      <a href={socialNetwork.url} target="_blank" rel="noopener noreferrer">
        <div className='btn btn-secondary'>
          <i className={"mdi " + this.renderServiceIcon(socialNetwork.domain_name)} style={{fontSize: '18px'}}></i>
        </div>
      </a>
    )
  }

  renderSocialIconEdit(socialNetwork){
    return(
      <div>
        <div className="item radius4 d-flex actual-resource-card">
          <div style={{display:'block'}}>
            <div><b>{socialNetwork.domain_name}</b></div>
            <div className="actual-resources-link">{socialNetwork.url}</div>
          </div>
          <div className="ml-auto">
            <div className='btn btn-secondary'>
              <i className="mdi mdi-pencil" style={{fontSize: '18px'}}></i>
            </div>
            <div className='btn btn-danger'>
              <i className="mdi mdi-delete" style={{fontSize: '18px'}}></i>
            </div>
          </div>
        </div>
      </div>
    )
  }

  addSocialNetwork(){
    let url = this.state.networkUrl;
    let newSocialNetwork = {
      url: '',
      domain: '',
      domain_name: '',
    };
    
    newSocialNetwork.url = url;
    newSocialNetwork.domain = this.extractDomain(url);
    newSocialNetwork.domain_name = this.extractDomainName(newSocialNetwork.domain);
    
    let socialNetworks = this.state.socialNetworks.push(newSocialNetwork);
    this.setState({socialNetworks: socialNetworks});
    this.state.networkUrl = '';

    this.props.onChange({
      model: this.props.model,
      value: this.state.socialNetworks
    });
  }

  deleteSocialNetwork(item){
    let socialNetworks = this.state.socialNetworks.splice(item, 1);
    this.setState({socialNetworks: socialNetworks});

    this.props.onChange({
      model: this.props.model,
      value: this.state.socialNetworks
    });
  }

  render(){
    if (this.props.editMode) {
      return (
        <div className='form-group'>
          <label>{this.props.title}</label><br />

          <div style={{ display: 'flex', marginTop: '10px' }}>
            <FormInput
              ref={ref => this.fi_url = ref}
              id="social url"
              label="Link de red social o tienda en línea"
              model="networkUrl"
              showCounter={false}
              value={
                this.state.networkUrl
              }
              validationRules="url"
              onChange={change => { this.onChangeForm(change) }}
              placeholder="Link de red social o tienda en línea" />
              <Button className='btn btn-aux' style={{ marginLeft: '10px', marginTop: '24px', marginBottom: '24px', heigth: '30px' }} disabled={this.state.networkUrl === ''} onClick={() => { this.addSocialNetwork() }}>+</Button>
          </div>

          <div className="d-block">
            { this.state.socialNetworks.map((social,item) => (
              <div className="m-r-10">
                <div>
                  <div className="item radius4 d-flex actual-resource-card">
                    <div style={{display:'block'}}>
                      <div><b>{social.domain_name}</b></div>
                      <div className="actual-resources-link">{social.url}</div>
                    </div>
                    <div className="ml-auto">
                      <div className='btn btn-danger' onClick={() => this.deleteSocialNetwork(item)}>
                        <i className="mdi mdi-delete" style={{fontSize: '18px'}}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            )}
          </div>

          <div className="d-inline">
            <small className="form-text text-muted">{this.props.subtitle}
              <Tooltip content={<div className="more-help-tooltip">{this.props.moreHelp}</div>} useDefaultStyles className="tooltipText tooltipIcon">
                <i className="mdi mdi-help-circle"></i>
              </Tooltip>
            </small>
          </div>

        </div>
      )
    }else{
      if (this.state.socialNetworks.length !== 0) {
        return (
          <div className='form-group'>
            <label>{this.props.title}</label>
            <div className="d-flex">
              { this.state.socialNetworks.map((social,item) => (
                <div className="m-r-10">
                  {this.renderSocialIcon(social)}
                </div>
                )
              )}
            </div>
          </div>
        )
      } else {
        return (
          <div className='form-group'>
            <label>{this.props.title}</label>
          </div>
        )
      }
    }
  }

}
KSocialNetworksField.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  model: PropTypes.string,
  socialNetworks: PropTypes.array,
  editMode: PropTypes.bool,
};

KSocialNetworksField.defaultProps = {
  title: '',
  subtitle: '',
  model: '',
  socialNetworks: [],
  editMode: true,
};

export default KSocialNetworksField;
