/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * America Mendoza <america@startuplab.mx>, October 2019 &
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import KTabIdeaContent from './ktabs/KTabIdeaContent';
import KTabPrototypeContent from './ktabs/KTabPrototypeContent';
import KTabStartupContent from './ktabs/KTabStartupContent';
import KTabPymeContent from './ktabs/KTabPymeContent';
import KTabScaleUpContent from './ktabs/KTabScaleUpContent';
import KTabPartnersContent from './ktabs/KTabPartnersContent';
import KTabMentorsContent from './ktabs/KTabMentorsContent';
import KMentorReview from './KMentorReview';


/**
*   Render the company view as a list and non editable fields.
*   Show the list of [reviews](/#kmentorreview) and the input to add a [review](/#kmentorreview).
*/

class KMentorCompanyView extends Component{

  renderMentorTab(){
    const user = this.props.user;

    if( user.roles.includes('manager') || user.roles.includes("facilitator") ){
      return (
        <div>
          <h3 className="card-title font-bold"><span className="lstick"></span>Aliados</h3>
          <KTabMentorsContent company={this.props.company} nonEditable={true}/>
        </div>
      )
    }else{
      return null
    }
  }

  render(){
    return (
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title font-bold"><span className="lstick"></span>Socios</h3>
              <KTabPartnersContent company={this.props.company} nonEditable={true} showExtraContactButtons={true} className={"btn-info btn-save"}/>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h3 className="card-title font-bold"><span className="lstick"></span>Idea</h3>
              <KTabIdeaContent company={this.props.company} nonEditable={true}/>

              <h3 className="card-title font-bold"><span className="lstick"></span>Prototipo</h3>
              <KTabPrototypeContent company={this.props.company} nonEditable={true}/>

              <h3 className="card-title font-bold"><span className="lstick"></span>Startup</h3>
              <KTabStartupContent company={this.props.company} nonEditable={true}/>

              <h3 className="card-title font-bold"><span className="lstick"></span>Pyme</h3>
              <KTabPymeContent  company={this.props.company} nonEditable={true}/>

              <h3 className="card-title font-bold"><span className="lstick"></span>Scaleup</h3>
              <KTabScaleUpContent company={this.props.company} nonEditable={true}/>



              {this.renderMentorTab()}
            </div>
          </div>
        </div>
        <KMentorReview companyId={this.props.company._id}
                       user={this.props.user}
                       allowReviews={this.props.allowReviews}
                       subtitleEmptyList={this.props.subtitleEmptyList}/>
      </div>
    )
  }
}

KMentorCompanyView.propTypes = {
  /** The company object */
  company           : PropTypes.object,
  /** The user object */
  user              : PropTypes.object,
  /** When is true you can add a review
      Prop of the component (KMentorReview)[/#kmentorreview]*/
  allowReviews      : PropTypes.bool,
  /** Message if the list is empty
      Prop of the component (KMentorReview)[/#kmentorreview]*/
  subtitleEmptyList : PropTypes.string,
};

KMentorCompanyView.defaultProps = {
  company           : {},
  user              : {},
  allowReviews      : true,
  subtitleEmptyList : '¡Agrega una!',

};

export default KMentorCompanyView;
