/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez && Israel Arellano <juan@startuplab.mx> && <israel@startuplab.mx>, October 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';
import '../scss/pages/user-card.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import KSpeakerList from '../components/KSpeakerList';

import financing from './financing.json'

//Other imports
import { orderBy } from 'lodash';
import { isUserProfileComplete } from '../utils/Utils.js';

class FinancingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      sidebarVisible: true,
      me: {},
    };
    this.handleResizeBar        = this.handleResizeBar.bind(this);
    this.handleSidebarVisible   = this.handleSidebarVisible.bind(this);
    this.handleHeaderResize     = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile  = this.redirectToEditProfile.bind(this);
  }

  componentDidMount() {
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        if (!isUserProfileComplete(res.user)) {
          this.redirectToEditProfile();
        } else {
          this.setState({
            me: res.user,
            authorized: true,
          });
        }
      } else {
        //redirect profile
        this.props.history.push("/profile");
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile() {
    this.props.history.push("/profile/edit");
  }

  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true,
    });
  }

  handleSidebarVisible() {
    this.setState({
      sidebarVisible: !this.state.sidebarVisible,
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  renderContent() {

    let sortedFinancing = financing.financing;

    sortedFinancing = orderBy(sortedFinancing, ['name']);

    return (
      <KSpeakerList speakers={sortedFinancing}/>
    )
  }

  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
         <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
           {/* Page wrapper  */}
           <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">
                  <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                      <h3 className="text-themecolor">Feria financiera</h3>
                    </div>
                  </div>
                  { this.renderContent() }
               </div>
           </div>
           {/* End Page wrapper  */}
         </div>

        </div>
      );
    } else {
      return null;
    }
  }
}
export default FinancingView;
