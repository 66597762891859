/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Darien Miranda <darien@startuplab.mx, December 2019
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from 'react-tooltip-lite';

// Components
import Swal from 'sweetalert2';
class KFileField extends Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null
    }
    this.onFileSelected = this.onFileSelected.bind(this);
  }
  onFileSelected(file){
    if(file.target.files[0].size > this.props.maxUploadSize){
			Swal.fire({
				title: 'Archivo demasiado grande',
				text: "El archivo debe de ser menor a 5MB.",
				type: 'error',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
			});
			this.pdfInput.value = '';
		}else{
      this.props.onFileSelected({
        field:this.props.field,
        file:file
      });
    }
  }
  render(){
    if(this.props.editMode){
      return(
        <div className='form-group'>
          <label>{this.props.title}</label><br/>
          <input type="file" ref={ref=> this.pdfInput = ref} onChange={this.onFileSelected} accept={this.props.accept}/>
          <div className="d-inline">

          <small className="form-text text-muted">{this.props.subtitle}
            <Tooltip content={<div className="more-help-tooltip">{this.props.moreHelp}</div>} useDefaultStyles className="tooltipText tooltipIcon">
              <i className="mdi mdi-help-circle"></i>
            </Tooltip>
          </small>
          </div>

        </div>
      )
    }else{
      let pdfName = (this.props.uploadedFileUrl === "") ? this.props.fileNotUploadedTitle : this.props.downloadFileTitle;
      if(this.props.uploadedFileUrl === ''){
        return(
          <div className='form-group'>
            <label>{this.props.title}</label>
            <p>{pdfName}</p>
          </div>
        )
      }else{
        return(
          <div className='form-group'>
            <label>{this.props.title}</label>
            <a href={this.props.uploadedFileUrl} target="_blank" rel="noopener noreferrer">{pdfName}</a>
          </div>
        )
      }

    }
  }
}
KFileField.propTypes = {
  field                : PropTypes.string,
  title                : PropTypes.string,
  subtitle             : PropTypes.string,
  fileNotUploadedTitle : PropTypes.string,
  downloadFileTitle    : PropTypes.string,
  uploadedFileUrl      : PropTypes.string,
  maxUploadSize        : PropTypes.number,
  accept               : PropTypes.string,
  editMode             : PropTypes.bool,
  onFileSelected       : PropTypes.func
};

KFileField.defaultProps = {
  field                : null,
  title                : '',
  subtitle             : '',
  fileNotUploadedTitle : '',
  downloadFileTitle    : '',
  uploadedFileUrl      : '',
  maxUploadSize        : 5 * 1024 * 1024, //5MB
  accept               : "application/pdf",
  editMode             : true,
  onFileSelected       : (file) => {},
};

export default KFileField;
