/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, May 2021
 */

// Regular imports
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom';
// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';

//version
import pkg from '../../package.json'


/** Navigation sidebar.
*   Display the navigation option by user type (partner or mentor and facilitator or manager).
*/

class SideBarCatalog extends React.Component {
  constructor(p){
    super(p);

    this.state = {
      filters: [],
      showPhaseFilter: false,
      ideaFilter: false,
      prototypeFilter: false,
      startupFilter: false,
      pymeFilter: false,
      scaleUpFilter:false,
      slpFilter: false,
      zacFilter:false
    }

    this.addPhaseFilter           =  this.addPhaseFilter.bind(this);
    this.changeFilterStatus       =  this.changeFilterStatus.bind(this);

  }

  addPhaseFilter(filter) {
    if(this.state.filters.includes(filter)) {
      let index = this.state.filters.indexOf(filter);
      this.state.filters.splice(index, 1)
      this.setState({
        filters: this.state.filters
      }, () => {
        let selectedFilters = this.state.filters.map(filter => filter).join("|");
        this.props.searchBy(selectedFilters);
      })
    } else {
      this.setState({
        filters: this.state.filters.concat(filter)
      }, () => {
        let selectedFilters = this.state.filters.map(filter => filter).join("|");
        this.props.searchBy(selectedFilters);
      })
    }

  }

  changeFilterStatus(event) {
    let filterName = event.target.name; 
    this.setState({
      [filterName]: !this.state[filterName]
    })
  }

  renderMenu(){
    return(
      <div>
        <li>
          <Link className="has-arrow waves-effect waves-dark" name="showPhaseFilter" aria-expanded={this.state.showPhaseFilter} onClick={(status) => this.changeFilterStatus(status)}>
            <i className="mdi mdi-rocket" style={{fontSize: '20px'}}></i>
            <span className="hide-menu"> Etapas
            </span>
          </Link>
          <ul className={"collapse in " + (this.state.showPhaseFilter ? '' : 'd-none')}>
            <li>
              <input
                type="checkbox"
                id="idea"
                className="filled-in chk-col-light-blue"
                value={this.state.ideaFilter}
                onChange={(status) => { this.addPhaseFilter('idea'); this.changeFilterStatus(status); }}
                checked={this.state.ideaFilter}
                name='ideaFilter'/>
                <label htmlFor="idea">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Idea
                  </label>
                </label>
            </li>
            <li>
              <input
                type="checkbox"
                id="prototype"
                className="filled-in chk-col-light-blue"
                value={this.state.prototypeFilter}
                onChange={(status) => { this.addPhaseFilter('prototype'); this.changeFilterStatus(status); }}
                checked={this.state.prototypeFilter}
                name='prototypeFilter'/>
                <label htmlFor="prototype">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Prototipo
                  </label>
                </label>
            </li>
            <li>
              <input
                type="checkbox"
                id="startup"
                className="filled-in chk-col-light-blue"
                value={this.state.startupFilter}
                onChange={(status) => { this.addPhaseFilter('startup'); this.changeFilterStatus(status); }}
                checked={this.state.startupFilter}
                name='startupFilter'/>
                <label htmlFor="startup">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Startup
                  </label>
                </label>
            </li>
            <li>
              <input
                type="checkbox"
                id="pyme"
                className="filled-in chk-col-light-blue"
                value={this.state.pymeFilter}
                onChange={(status) => { this.addPhaseFilter('pyme'); this.changeFilterStatus(status); }}
                checked={this.state.pymeFilter}
                name='pymeFilter'/>
                <label htmlFor="pyme">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Pyme
                  </label>
                </label>
            </li>
            <li>
              <input
                type="checkbox"
                id="scaleup"
                className="filled-in chk-col-light-blue"
                value={this.state.scaleUpFilter}
                onChange={(status) => { this.addPhaseFilter('scaleup'); this.changeFilterStatus(status); }}
                checked={this.state.scaleUpFilter}
                name='scaleUpFilter'/>
                <label htmlFor="scaleup">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Scale Up
                  </label>
                </label>
            </li>
          </ul>
        </li>
        {/* <li>
          <Link className="has-arrow waves-effect waves-dark">
            <i className="mdi mdi-google-maps" style={{fontSize: '20px'}}></i>
            <span className="hide-menu"> Ubicación
            </span>
          </Link>
          <ul className="collapse in">
            <li>
              <input
                type="checkbox"
                id="slpfilter"
                className="filled-in chk-col-light-blue"
                value={this.state.slpFilter}
                onChange={(status) => { this.addPhaseFilter('MX-SLP'); this.changeFilterStatus(status); }}
                checked={this.state.slpFilter}
                name='slpFilter'/>
                <label htmlFor="slpfilter">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    San Luis Potosí
                  </label>
                </label>
            </li>
            <li>
              <input
                type="checkbox"
                id="zacfilter"
                className="filled-in chk-col-light-blue"
                value={this.state.zacFilter}
                onChange={(status) => { this.addPhaseFilter('MX-ZAC'); this.changeFilterStatus(status); }}
                checked={this.state.zacFilter}
                name='zacFilter'/>
                <label htmlFor="zacfilter">
                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                    Zacatecas
                  </label>
                </label>
            </li>
          </ul>
        </li> */}
      </div>
    )
  }
  render(){
    return(
      <aside className="left-sidebar">
          {/* Sidebar scroll*/}
          <div className="scroll-sidebar">
              {/* Sidebar navigation*/}
              <nav className="sidebar-nav">
                  <ul id="sidebarnav">
                      {this.renderMenu()}
                  </ul>
              </nav>
              {/* End Sidebar navigation */}
              {/* Start btn to create business */}
              {/* End btn to create business */}
          </div>
          {/* End Sidebar scroll*/}
          <footer className={"sidebar-nav " + (this.props.visible ? "sidebar-footer-k" : "")} style={{marginLeft: '15px'}}>
            <ul id="sidebarnav" style={{display: 'inline-flex', fontSize: '12px'}}>
              <li><a className="waves-effect waves-dark" style={{padding: 0, fontSize: '12px'}} rel="noopener noreferrer" href="https://creomx.com/edomex/Aviso.pdf" target="_blank">Politica de Privacidad</a></li> |
              <li><a className="waves-effect waves-dark" style={{padding: 0, fontSize: '12px'}} rel="noopener noreferrer" href="https://www.komunidad.io/terminosycondiciones" target="_blank">Términos</a></li>
            </ul>
            <p style={{fontSize: '12px'}}>Komunidad IO © 2024 - v{pkg.version}</p>
          </footer>
      </aside>
    );
  }

}
SideBarCatalog.propTypes = {
  /** User object */
  user                 : PropTypes.object,
  /** When is true the sidebar is showed. */
  visible              : PropTypes.bool,

  searchBy             : PropTypes.func,
};

SideBarCatalog.defaultProps = {
  user:{
      _id                     :null,
      name                    :null,
      email                   :null,
      created_at              :null,
      updatedAt               :null,
      source                  :null,
      uid                     :null,
      profileUrlThumbnail     :null,
      goverment_id_curp       :null,
      social_linkedin         :null,
      social_facebook         :null,
      social_twitter          :null,
      social_other            :null,
      goverment_id_rfc        :null,
      dateOfBirth             :null,
      phone                   :null,
      gender                  :null,
      roles                   :[]
  },
  visible                     :true,
  searchBy                    : (filters) => {}
};
export default withRouter(SideBarCatalog);