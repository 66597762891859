/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * America Mendoza <america@startuplab.mx>, May 2020
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import KomunidadApi from '../api/api.js';
import { Button } from 'react-bootstrap';

// Components
import Swal from 'sweetalert2';

/**
*   Enable/disable the program service requests.
*   Saves the service request email list.
*/

class KProgramServicesComponent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      accepts_service_requests:false,
      send_service_requests_to:'default', //default, email_list
      service_requests_email_list: [],
      values_changed:false,
      program_loaded:false,
    }
    this.onCbChange           = this.onCbChange.bind(this);
    this.cancel               = this.cancel.bind(this);
  }
  //----------------------------------------------------------------------
  static getDerivedStateFromProps(props, current_state){
    let program = props.program;
    let accepts_service_requests = true;
    let send_service_requests_to = 'default';
    let service_requests_email_list = [];
    if(program.hasOwnProperty("accepts_service_requests")){
      accepts_service_requests = program.accepts_service_requests;
    }
    if(program.hasOwnProperty("send_service_requests_to")){
      send_service_requests_to = program.send_service_requests_to;
    }
    if(program.hasOwnProperty("service_requests_email_list")){
      service_requests_email_list = program.service_requests_email_list;
    }

    if(!current_state.program_loaded){
      return{
        accepts_service_requests    : accepts_service_requests,
        send_service_requests_to    : send_service_requests_to,
        service_requests_email_list : service_requests_email_list,
        program_loaded              : true
      };
    }


  }

  //----------------------------------------------------------------------
  onCbChange(e){
    let checked = e.target.checked;
    this.setState({[e.target.name]:checked,values_changed:true})
  }
  handleRadioBtn(e){
    this.setState({[e.target.name]:e.target.value,values_changed:true})
  }
  renderEditAndSaveBtns(){
    if(this.state.values_changed){
      return(
        <div style={{marginTop:'32px'}}>
          <Button className='btn btn-save'    onClick={() => this.save()}>Guardar</Button>
          <Button className='btn btn-danger'  onClick={() => this.cancel()}>Cancelar</Button>
        </div>
      )
    }else{
      return(
        <div style={{marginTop:'32px'}}>

        </div>
      )
    }
  }
  render(){
    return (
      <div className='form-group'>
        <div className="switch">
          <label style={{fontSize:"1rem",fontWeight:'bold'}}>Ofrecer servicios
            <input type="checkbox"
                   name="accepts_service_requests"
                   onChange={(e) => this.onCbChange(e)}
                   checked={this.state.accepts_service_requests}/>
            <span className="lever"></span>
          </label>
          <p>{this.state.accepts_service_requests? "Los emprendedores puden solicitar servicios en este programa":"Los emprendedores no pueden solicitar servicios en este programa"}</p>
        </div>
        {this.renderEditAndSaveBtns()}
      </div>
    )
  }

  cancel(){
    let accepts_service_requests = false;
    if(this.props.program.hasOwnProperty("accepts_service_requests")){
      accepts_service_requests = this.props.program.accepts_service_requests;
    }
    this.setState({
      accepts_service_requests:accepts_service_requests,
      values_changed:false
    });
  }
  save(){
    let checked = this.state.accepts_service_requests;
    KomunidadApi.updateProgramAcceptServiceRequests(
      this.props.program._id,checked
    ).then((res) => {
      if(res.success){
        this.setState({
          values_changed : false
        }, () =>{
          this.props.onUpdateProgram();
          if (this.state.accepts_service_requests){
            Swal.fire({
              type: 'success',
              title: 'Servicios habilitados',
              text: 'Los emprendedores puden solicitar servicios',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }else{
            Swal.fire({
              type: 'success',
              title: 'Servicios deshabilitados',
              text: 'Los emprendedores no puden solicitar servicios',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }
        });
      }else{
        Swal.fire({
          type: 'error',
          title: 'Hubo un problema al tratar de guardar el programa',
          customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
        });
      }
    });

  }

}

KProgramServicesComponent.propTypes = {
  /** Program object. */
  program            : PropTypes.object,

  /** True when the program is being edited. */
  editMode           : true,

  /** Change the state editMode*/
  toggleEditing      : PropTypes.func,

  /** Callback when program is updated. */
  onUpdateProgram    : PropTypes.func
};

KProgramServicesComponent.defaultProps = {
  program            : {},
  editMode           : true,
  toggleEditing      : () => {},
  onUpdateProgram    : () => {}
};

export default KProgramServicesComponent;
