/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juan Hernandez <juan@startuplab.mx>, February 2021
 */

 // Regular imports
 import PropTypes from 'prop-types';
 import React, { Component } from 'react'
 import KomunidadApi from '../api/api.js';
 import { Button } from 'react-bootstrap';
 
 // Style
 import '../scss/components/_create-course.scss';

 // Components
 import Swal from 'sweetalert2';
 import FormInput from '../components/form/FormInput';
 import ProgramsPicker from '../components/ProgramsPicker';
 import KPhasesPicker from '../components/KPhasesPicker';
 import KChaptersPicker from '../components/KChaptersPicker';
 import RLDD from 'react-list-drag-and-drop/lib/RLDD';
 import KImage from '../components/KImage';
 import ImagePicker from '../components/ImagePicker.js';
 const moment = require('moment');
 
 
 /**
 *   Create and edit program
 */
 
 class KCreateAndEditCourse extends Component{
   constructor(props) {
     super(props);
 
     this.state = {
       selectedPrograms: [],
       selectedChapter: [],
       chapters: [],
       courseId:'',
       courseName:'',
       phase:'',
       resourceName:'',
       resourceUrl:'',
       resourceDescription:'',
       is_public:'false',
       accepts_inscriptions:true,
       selectedFacilitator:"",
       file:null,
       fileName:null,
     }
 
     this.clear                         = this.clear.bind(this);
     this.toggleEditMode                = this.toggleEditMode.bind(this);
     this.openCancelSwal                = this.openCancelSwal.bind(this);
     this.openLoadingSwal               = this.openLoadingSwal.bind(this);
     this.setInitialValues              = this.setInitialValues.bind(this);
     this.handleRadioBtn                = this.handleRadioBtn.bind(this);
     this.handlePhasesChange            = this.handlePhasesChange.bind(this);
     this.handleChapterChange           = this.handleChapterChange.bind(this);
     this.handleRLDDChange              = this.handleRLDDChange.bind(this);
     this.handleRLDDChangeActual        = this.handleRLDDChangeActual.bind(this);
     this.onChangeForm                  = this.onChangeForm.bind(this);
     this.onChangeProgram               = this.onChangeProgram.bind(this);
     this.onCbChange                    = this.onCbChange.bind(this);
     this.onCourseImageMouseEnter       = this.onCourseImageMouseEnter.bind(this);
     this.onCourseImageMouseLeave       = this.onCourseImageMouseLeave.bind(this);
     this.getImage                      = this.getImage.bind(this);
     this.sendCourse                    = this.sendCourse.bind(this);
     this.getPublicValue                = this.getPublicValue.bind(this);
     this.addChapter                   = this.addChapter.bind(this);
   }
 
 
   componentDidMount(){
     this.setInitialValues(this.props.course);
   }
 
   setInitialValues(course) {
     let courseId               = this.props.isCreateMode ? ''      : course._id;
     let courseName             = this.props.isCreateMode ? ''      : course.name;
     let phase                  = this.props.isCreateMode ? ''      : course.phase;
     let fileName               = this.props.isCreateMode ? null    : course.bannerUrlThumbnail;
     let selectedPrograms       = this.props.isCreateMode ? []      : course.programs.map(p => p.program);
     let chapters               = this.props.isCreateMode ? []      : course.chapters.map((c,index) => ({ ...c, id: index })).sort(function (a, b) { return a.priority - b.priority;});
     let is_public              = this.props.isCreateMode ? 'false' : this.getPublicValue(course);
     let accepts_inscriptions   = this.props.isCreateMode ? false : course.accepts_inscriptions;
    
     this.setState({
       courseId: courseId,
       courseName: courseName,
       accepts_inscriptions: accepts_inscriptions,
       fileName: fileName,
       chapters: chapters,
       selectedPrograms: selectedPrograms,
       phase: phase,
       is_public: is_public
     });
   }

   getPublicValue(program){
     if(program.hasOwnProperty('is_public')){
       return program.is_public.toString();
     }else{
       return 'true'
     }
   }
 
   openLoadingSwal() {
     Swal.fire({
       title: 'Tus datos están siendo guardados',
       customClass: {
         title: 'swal-custom-title',
       },
       onBeforeOpen: () => {
         Swal.showLoading()
       }
     })
   }
 
   //Save the data of the forminput
   onChangeForm(data){
     let value = (data.value === null) ? '' : data.value; //sets null data to empty string
     this.setState({[data.model]:value});
   }

   onChangeProgram(programs){
      this.setState({selectedPrograms:programs});
    }

    onCbChange(e){
      let checked = e.target.checked;
      this.setState({[e.target.name]:checked,values_changed:true})
    }
  
   handleRadioBtn(e){
     this.setState({[e.target.name]:e.target.value})
   }

   handlePhasesChange(phases){
     if(phases !== null){
       phases = phases.map((p) => p.label).join("|");
      }
      if(phases === "all"){
        phases = null;
      }
      this.setState({phase:phases});
    }

   handleChapterChange(chapter){
      this.setState({
        selectedChapter: chapter,
        resourceName: chapter.label,
        resourceDescription: chapter.description,
        resourceUrl: chapter.url 
      });
    }

    handleRLDDChange(newChapters) {
      this.setState({ chapters: newChapters });
    }
    handleRLDDChangeActual(newChapters) {
      // do nothing
    }
    renderUpdateImageArea(){
      let renderTxt = this.props.editMode? 'Actualizar imagen':'Agregar imagen';
      if(this.state.showUpdateImageArea){
        return(
          <div className="img-course-overlay" onClick={() => this.imagePicker.openPicker()}>
            <div>
              {renderTxt}
            </div>
          </div>
        )
      }else{
        return null;
      }
    }

   validateCourseForm() {
     if(this.state.is_public === 'false' && this.state.selectedPrograms.length===0 && this.props.isCreateMode) {
      Swal.fire({
        type: 'error',
        title: 'Selecciona algun programa',
        text: 'El curso debe tener por lo menos un programa',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
     if(this.state.courseName.trim().length === 0) {
       Swal.fire({
         type: 'error',
         title: 'Revisa el nombre del curso',
         text: 'El nombre del curso no pude estar vacio',
         customClass: {
           confirmButton: 'swalBtnTeal',
           title: 'swal-custom-title',
         },
       });
       return false
     }
     if(this.state.phase.trim().length === 0) {
      Swal.fire({
        type: 'error',
        title: 'Revisa la etapa del curso',
        text: 'La etapa del curso no pude estar vacia',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
    if(this.state.chapters.length === 0 && this.props.isCreateMode) {
      Swal.fire({
        type: 'error',
        title: 'Revisa los módulos del curso',
        text: 'El curso debe tener por lo menos un módulo',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
     return true;
   }

   onCourseImageMouseEnter(){
    this.setState({
      showUpdateImageArea:true
    })
  }
  onCourseImageMouseLeave(){
    this.setState({
      showUpdateImageArea:false
    })
  }

   sendCourse(){
     let chapters = JSON.stringify(this.state.chapters);
     if(this.state.is_public === "true"){
      this.setState({selectedPrograms: []})
      }
     let programs = this.state.selectedPrograms.join("|");
     if(this.validateCourseForm()){
        KomunidadApi.addCourse(
          this.state.courseName, this.state.phase, programs, this.state.is_public, chapters, this.state.file
        ).then((res) => {
          if(res.success){
            Swal.fire({
              type: 'success',
              title: '¡Curso registrado!',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
            this.setState({
              courseName: '',
              phase:'',
              selectedPrograms: [],
              chapters:[],
              file:null,
              fileName:null,
              is_public:'false'
            });
            this.clear();
            this.props.onUpdateCourse();
          }else{
            Swal.fire({
              type: 'error',
              title: 'No fue posible crear el curso',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            });
          }
        }).catch((err) => {
          return err;
        });
     }
   }

   updateCourse(){
     let chapters = this.state.chapters;
     chapters.forEach(function(c){ 
       if(c.hasOwnProperty('priority')){
          delete c.priority 
       } 
      });
     chapters = JSON.stringify(chapters.map((c,index) =>({priority:index, ...c})));
     
    if(this.state.is_public === "true"){
      this.setState({selectedPrograms: []})
    }
    let programs = this.state.selectedPrograms.join("|");
    if(this.validateCourseForm()){
      KomunidadApi.editCourse(
        this.state.courseId, this.state.courseName, programs, this.state.is_public,  this.state.accepts_inscriptions, chapters, this.state.file
      ).then((res) => {
        if(res.success){
          Swal.fire({
            type: 'success',
            title: '¡Curso actualizado!',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            // if valid reload page
            if (result) {
              document.location.reload(true)
            }
          })

          this.props.onUpdateCourse();
        }else{
          Swal.fire({
            type: 'error',
            title: 'No fue posible actualizar el curso',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      }).catch((err) => {
        return err;
      });
    }
   }

   renderBtnsChapters(){
     if(this.props.isCreateMode){
       return(
        <Button className='btn btn-aux' disabled={this.state.resourceName==='' || this.state.resourceUrl===''} onClick={() => {this.addChapter()} }>Agregar módulo</Button>
        )
      }else{
        return(
         <Button className='btn btn-aux' disabled={this.state.resourceName==='' || this.state.resourceUrl===''} onClick={() => {this.addChapter()} }>Agregar módulo</Button>
         // Enable if need to edit
         //  <Button className='btn btn-aux' style={{marginLeft: 'auto', marginTop:'24px', marginBottom:'24px'}}  disabled={this.state.resourceName==='' || this.state.resourceUrl===''} onClick={() => {this.editChapter()} }>Modificar</Button>
       )
     }
   }

   renderBtns(){
     if(this.props.isCreateMode){
       return(
         <Button className='btn btn-save' style={{marginTop:'32px'}} onClick={this.sendCourse}>Crear Curso</Button>
       )
     }else{
       return this.renderEditAndSaveBtns();
     }
   }
 
   renderEditAndSaveBtns(){
     if(this.props.editMode){
       return(
         <div style={{marginTop:'32px'}}>
           {(this.state.chapters.length === 0) && <small className="form-text text-danger">No se puede guardar un curso sin capitulos</small>}
           <Button className='btn btn-save' disabled={this.state.chapters.length === 0} onClick={() => this.updateCourse()}>Actualizar curso</Button>
         </div>
       )
     }else{
       return(
         <div style={{marginTop:'32px'}}>
           <Button className='btn btn-info btn-save'  onClick={() => this.toggleEditMode(true)}>Editar</Button>
         </div>
       )
     }
   }
 
   toggleEditMode(value) {
     this.props.toggleEditing(value);
   }
   openCancelSwal() {
     Swal.fire({
       title: '¿Deseas cancelar?',
       text: "Los datos que no han sido guardados se perderán",
       type: 'warning',
       showCancelButton: true,
       confirmButtonText: 'SI',
       cancelButtonText: 'NO',
       customClass: {
         confirmButton: 'swalBtnRedOrange',
         cancelButton: 'swalBtnTeal',
         title: 'swal-custom-title',
       },
     }).then(res => {
       if (res.value){
         this.toggleEditMode(false);
         this.clear();
       }
     })
   }
 
  clear () {
    this.fi_name.cancel();
    this.fi_chapter.cancel();
    this.fi_url.cancel();
    this.fi_phase.cancel();
    if(this.fi_programs){
      this.fi_programs.cancel();
    }
  }

   getImage(file){
    if(file !== null){
      let name = window.URL.createObjectURL(file);
      this.setState({
        file: file,
        fileName: name
      });
    }
  }
 
   removeChapter(index){
    var newChapters = this.state.chapters;
    newChapters.splice(index,1);
    this.setState({ chapters: newChapters });
   }
   addChapter(){
    if(this.state.resourceName !== '' && this.state.resourceUrl !== ''){
      var newChapters = this.state.chapters;
      var newChapter = {
          id: 0,
          name: "",
          description: "",
          url:""
      }
      newChapter.id = newChapters.length+1;
      newChapter.name = this.state.resourceName;
      newChapter.description = this.state.resourceDescription;
      newChapter.url = this.state.resourceUrl;
      newChapters.push(newChapter);
      this.setState({ 
          chapters:newChapters,
          resourceName: '',
          resourceDescription: '',
          resourceUrl: '' 
       });
    }
   }
   editChapter(){
    if(this.state.resourceName !== '' && this.state.resourceUrl !== ''){
      var newChapters = this.state.chapters;
      newChapters[this.state.selectedChapter.value].name = this.state.resourceName;
      newChapters[this.state.selectedChapter.value].description = this.state.resourceDescription;
      newChapters[this.state.selectedChapter.value].url = this.state.resourceUrl;
      this.setState({ 
          chapters:newChapters,
          resourceName: '',
          resourceDescription: '',
          resourceUrl: '' 
       });
    }
   }
   renderPrivacyLabel(){
     if(this.state.is_public === "true"){
       return ("En los cursos para toda la instalación los emprendedores pueden ver el contenido en la sección de Academia.");
      }else{
        return ("En los cursos pertenecientes a un programa solo los emprendedores inscritos en el programa pueden ver el contenido.");
      }
    }
    renderProgramPicker(){
      if(this.state.is_public === "false"){
        return(
          <ProgramsPicker 
            showInactivePrograms={true}
            onChange={(program) => {this.onChangeProgram(program)}}
            multipleProgramSelected={this.state.selectedPrograms}
            ref={ref => this.fi_programs = ref}
            isMultiple={true}>
          </ProgramsPicker>
        );
      }else{
        return null
      }
    }
    renderPhasePicker(){
      if(this.props.editMode){
        return(
          <div className="m-b-20">
            <label>Etapa:</label>
            <div>{this.state.phase}</div>
          </div>
        )
      }else{
        return(
          <KPhasesPicker 
            onChange={(phases) => this.handlePhasesChange(phases)}
            ref={ref => this.fi_phase = ref}
            isMultiple={false}></KPhasesPicker>
        )
      }
    }
    renderChapterPicker(){
      if(this.props.editMode){
        return(
          <KChaptersPicker 
            chapters={this.state.chapters}
            onChange={(chapter) => this.handleChapterChange(chapter)}
            ref={ref => this.fi_phase = ref}>
          </KChaptersPicker>
        )
      }else{
        return null
      }
    }
    renderActualResources(){
      if(this.props.editMode){           
        return(
          <RLDD
            items={this.state.chapters}
            itemRenderer={(item, index) => {
                return (            
                  <div className="item radius4 d-flex actual-resource-card">
                      <div style={{display:'block'}}>
                        <div><b>{item.name}</b></div>
                        <div className="actual-resources-link">{item.description}</div>
                        <small className="text-muted">{item.url}</small>
                      </div>
                      <div className="ml-auto">
                        <div className='btn btn-danger' onClick={() => {this.removeChapter(index)} }>
                          <i className="mdi mdi-delete" style={{fontSize: '18px'}}></i>
                        </div>
                      </div>
                  </div>
                );
            }}
            onChange={this.handleRLDDChange}
          />
        )
      } else {
        return null
      }
    }
   renderResources(){
    if(this.state.chapters.length > 0 && this.props.isCreateMode){           
      return(
        <RLDD
          items={this.state.chapters}
          itemRenderer={(item, index) => {
              return (            
                <div className="item radius4 d-flex resource-card">
                    <div className="icon-container">
                      <i className="mdi mdi-reorder-horizontal"></i>
                    </div>
                    <div style={{display:'block'}}>
                      <div><b>{item.name}</b></div>
                      <div className="resources-link">{item.description}</div>
                      <small className="text-muted">{item.url}</small>
                    </div>
                    <div className="ml-auto">
                      <div className='btn btn-danger' onClick={() => {this.removeChapter(index)} }>
                        <i className="mdi mdi-delete" style={{fontSize: '18px'}}></i>
                      </div>
                    </div>
                </div>
              );
          }}
          onChange={this.handleRLDDChange}
        />
      )
    } else {
      return null
    }
   }
   renderDisableCourse(){
    if(this.props.editMode){
      return (
        <div className='form-group'>
          <div className="switch">
            <label style={{fontSize:"1rem",fontWeight:'bold'}}>Inscripciones
              <input type="checkbox"
                     name="accepts_inscriptions"
                     onChange={(e) => this.onCbChange(e)}
                     checked={this.state.accepts_inscriptions}/>
              <span className="lever"></span>
            </label>
            <p>{this.state.accepts_inscriptions? "Los emprendedores puden inscribirse en este curso":"Los emprendedores no puden inscribirse en este curso"}</p>
          </div>
        </div>
      )
    }else{
      return null
    }
   }
   renderIsPublic(){
     if(this.props.facilitatorView){
      return null
     }else{
       return(
         <div>
           <label>Agregar curso a toda la instalación</label>
              <input
                  id="rb_is_public"
                  onChange={(e) => this.handleRadioBtn(e)}
                  checked={this.state.is_public === 'true'}
                  name="is_public"
                  type="radio"
                  value="true"
                  className="with-gap radio-col-purple"
              />
              <label for="rb_is_public">
                  Sí
              </label>&nbsp;
              <input
                  id="rb_is_private"
                  checked={this.state.is_public === 'false'}
                  onChange={(e) => this.handleRadioBtn(e)}
                  name="is_public"
                  type="radio"
                  value="false"
                  className="with-gap radio-col-purple m-l-10"
              />
              <label for="rb_is_private">
                  No
              </label>
              <div>{this.renderPrivacyLabel()}</div>
         </div>
       )
     }
   }
   render(){
     return (
      <div className='form-group form-horizontal'>
          <div className="form-group">
          {this.renderDisableCourse()}
          <label>{this.props.editMode? "Imagen del curso":"Agregar imagen del curso"}</label>
          <ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage} minWidthProp={370} minHeightProp={100}/>
          <div className={'cursor-pointer noselect'} onMouseEnter={this.onCourseImageMouseEnter} onMouseLeave={this.onCourseImageMouseLeave} data-tut="edit-image">
              {this.renderUpdateImageArea()}
              <KImage src={this.state.fileName} className="img-course" alt="course_img" type={"course"}/>
          </div>
          <div className="card-body">
          </div>
          {this.renderIsPublic()}
          </div>
          <FormInput
             ref={ref => this.fi_name = ref}
             id="nombre del curso"
             label="Nombre del curso"
             model="courseName"
             showCounter={true}
             value={
               this.state.courseName
             }
             initialValue={
               this.props.isCreateMode ? '' : this.props.course.name
             }
             required={true}
             validationRules="required"
             maxLength={35}
             onChange={ change => { this.onChangeForm(change) } }
             placeholder="Nombre"/>

          {this.renderProgramPicker()}
          
          {this.renderPhasePicker()}
          
             {/* Enable if need to edit */}
            {/* {this.renderChapterPicker()} */}
            <div><hr/></div>
            <h3 className='m-b-20'>Agregar módulos</h3>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                <FormInput
                ref={ref => this.fi_chapter = ref}
                id="nombre del módulo"
                label="Nombre"
                model="resourceName"
                showCounter={false}
                value={
                this.state.resourceName
                }
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Nombre"/>
              </div>
              <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12'>
                  <FormInput
                  ref={ref => this.fi_url = ref}
                  id="nombre del web"
                  label="Dirección Web"
                  model="resourceUrl"
                  showCounter={false}
                  value={
                    this.state.resourceUrl
                  }
                  validationRules="url"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Dirección Web"/>
              </div>
              <div className='col-md-9 col-12'>
                <FormInput
                  ref={ref => this.fi_chapter = ref}
                  id="descripcion del módulo"
                  label="Descripción"
                  model="resourceDescription"
                  showCounter={false}
                  value={
                    this.state.resourceDescription
                  }
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Agrega una descripción del contenido o instrucciones"/>
              </div>
              <div className='col-md-3 col-12 my-auto'>
                {this.renderBtnsChapters()}
              </div>
            </div>
          {this.renderActualResources()}
          {this.renderResources()}
          <div><hr/></div>
          {this.renderBtns()}
       </div>
     )
   }
 }
 
 KCreateAndEditCourse.propTypes = {
   /** Course object. */
   program            : PropTypes.object,
   
   /** Course object. */
   course             : PropTypes.object,
 
   /** Callback when program is created or updated. */
   onUpdateCourse    : PropTypes.func,
   
   /** When is true don´t allow to select program facilitator. */
   facilitatorView    : PropTypes.bool,
 
   /** When is true create a new program. */
   isCreateMode       : PropTypes.bool,
 
   /** True when the program is being edited. */
   editMode           : PropTypes.bool,
 
   /** Change the state editMode*/
   toggleEditing      : PropTypes.func,
 
 };
 
 KCreateAndEditCourse.defaultProps = {
   program            : {},
   course             : {},
   onUpdateCourse    : () => {},
   facilitatorView    : false,
   isCreateMode       : false,
   editMode           : false,
   toggleEditing      : () => {}
 };
 
 export default KCreateAndEditCourse;
 