/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx> & Juan Hernandez <juan@startuplab.mx>, October 2019
 */

// Regular imports
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom'
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import { Tab, Nav, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Link } from "react-router-dom";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../scss/pages/_style.css';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import KTableSmartSelect from '../../components/KTableSmartSelect';
import KCreateAndEditProgram from '../../components/KCreateAndEditProgram';

// Other
import { orderBy } from 'lodash';
const TableCaption = () => <h5 className="display-table-caption" style={{ textAlign: 'center', color: '#398bf7', padding: '0.5em' }}> Desliza a la derecha para ver más<i className="ml-1 mdi mdi-chevron-right"/> </h5>;

class CreateProgramView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      me: {},
      programs:[],
      authorized:false,
      userIsLoaded:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      programName:'',
      iframe:'',
      schedule:'',
      query:'',
      table_msg:'Ingresa el nombre del programa a buscar.',
      hideCheckBox:true,
      startDate: null,
      endDate: null,
      loading:false,
      search_msg:'',
      cb_state:'none',
      selectedRows:[],
      exportUrl:'',
      is_public:'true',
      price:'',
      formIsHalfFilledOut: false,
      confirmedNavigation: false,

    }
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.formIsHalfFilledOut             = this.formIsHalfFilledOut.bind(this);
    this.formatDate                      = this.formatDate.bind(this);
    this.findPrograms                    = this.findPrograms.bind(this);
    this.onClickFindProgram              = this.onClickFindProgram.bind(this);
    this.onOptionSelected                = this.onOptionSelected.bind(this);
    this.onCbClick                       = this.onCbClick.bind(this);
    this.handleOnSelect                  = this.handleOnSelect.bind(this);
    this.handleBlockedNavigation         = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick    = this.handleConfirmNavigationClick.bind(this);
    this.handleResizeBar                 = this.handleResizeBar.bind(this);

    this.smartSelectOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
      {
        label         : "Activos",
        actionType    : "mixed",
        actionName    : "active",
      },
      {
        label         : "Inactivos",
        actionType    : "mixed",
        actionName    : "disabled",
      }
    ];

  }
   onOptionSelected(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.programs.map(p => p._id);
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    if(option.actionName === "active"){
      selectedRows = this.state.programs.filter(p=> this.getStatus("", p)==="Activo").map(p => p._id);
    }
    if(option.actionName === "disabled"){
      selectedRows = this.state.programs.filter(p=> this.getStatus("", p)==="Inactivo").map(p => p._id);
    }
    this.setState({
      cb_state:option.actionType,
      selectedRows:selectedRows
    },() => {
      this.convertProgramsToIds();
    });
  }
  onCbClick(state){
    if(state === "all"){
      this.onOptionSelected(this.smartSelectOptions[0]);
    }else{
      this.onOptionSelected(this.smartSelectOptions[1]);
    }
  }
  convertProgramsToIds(){
    let url = `${KomunidadApi.API_URL}/programs?export=xlsx`;
    let ids = this.state.selectedRows.join("|");
    if(this.state.selectedRows.length === this.state.programs.length){
        //do nothing
    }else{
      url = `${KomunidadApi.API_URL}/programs?export=xlsx&ids=${ids}`;
    }
    this.setState({
      exportUrl:url
    })
  }
  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator") 
    )
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:true,
              me:res.user,
              userIsLoaded:true,
              loading:true,
            });
            this.findPrograms('');
          }else{
            this.redirectHome();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  componentDidUpdate(){
    if(this.state.formIsHalfFilledOut) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  redirectHome(){
    this.props.history.push('/profile');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleBlockedNavigation = (nextLocation) => {
    if(!this.state.confirmedNavigation){
      this.confirmLeave(nextLocation);
      return false;
    }
    return true
  }

  //Confirm leave
  confirmLeave(nextLocation){
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que ingresaste se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.handleConfirmNavigationClick(nextLocation);
      }
    })
  }

  //Navigate to the next location
  handleConfirmNavigationClick(nextLocation){
     this.setState({
        confirmedNavigation: true
     }, () => {
        // Navigate to the previous blocked location
        this.props.history.push(nextLocation.pathname);
     })
  }

  findPrograms(query){
    KomunidadApi.findPrograms(query).then( (res) => {
      if(res.success){
        let _length = res.programs.length;
        let _msg = '';

        //set msg for search results if the query is not empty
        if(query !== ''){
          let _r = (_length === 1) ? 'resultado' : 'resultados';

          _msg = `${_length} ${_r} para "${query}" `;
        }

        if(res.programs.length > 0){
          this.setState({programs: this.sortPrograms(res.programs) ,loading:false,search_msg:_msg});
        }else{
          this.setState({programs:[],table_msg:'No hay programas registrados.',loading:false,search_msg:_msg});
        }
      }
      //TODO: swal error or msg in table with table_msg
    });
  }

  sortPrograms(programs) {

    for (var i = 0; i < programs.length; i++) {
      programs[i]["status"] = this.getStatus("",programs[i]);
    }

    programs = orderBy(programs, ['status', 'created_at'], ['asc', 'desc']);

    return programs;

  }

  onClickFindProgram(){
    let query = this.state.query;
    if(this.fi_query.validate()){
      this.findPrograms(query);
    }
  }

  //Set the state of start edit the forma
  formIsHalfFilledOut(value){
    if(!(this.state.formIsHalfFilledOut && value)){
      this.setState({formIsHalfFilledOut:value});
    }
  }

  //Save the data of the forminput
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    // this.formIsHalfFilledOut(true);
    this.setState({[data.model]:value});
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }


  renderAddProgramForm(){
    return(
      <div className="card-body">
        <KCreateAndEditProgram
            user={this.state.me}
            isCreateMode={true}
            editMode={true}
            onUpdateProgram={this.findPrograms}
            onEditingProgram={this.formIsHalfFilledOut}/>
      </div>
    )
  }

  formatDate(date){
    let d = new Date(date.replace('Z','')),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
  }

  getStatus(cell,row){
    let today = Date.now();
    let startDate = new Date(row.start_date);
    let endDate = new Date(row.end_date);

    endDate.setDate(endDate.getDate() + 1);

    let status = ((today >= startDate.getTime()) && (today <= endDate.getTime())) ?  "Activo" :  "Inactivo";

    return status;
  }

  handleOnSelect(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedRows = [...state.selectedRows, row._id];
        return {
          selectedRows,
        };
      }, () => {
        this.convertProgramsToIds();
        if(this.state.selectedRows.length === this.state.programs.length){
          this.setState({ cb_state: "all" });
          this.kTableSmartSelect.changeCbState("all");
        }else{
          this.setState({ cb_state: "mixed" });
          this.kTableSmartSelect.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedRows: this.state.selectedRows.filter(x => x !== row._id),
      }), () => {
        this.convertProgramsToIds();
        if(this.state.selectedRows.length === 0){
          this.setState({ cb_state: "none" });
          this.kTableSmartSelect.changeCbState("none");
        }else{
          this.setState({ cb_state: "mixed" });
          this.kTableSmartSelect.changeCbState("mixed");
        }
      });
    }

  }
  renderActionButtons(){
    if(this.state.selectedRows.length === 0){
      return (null);
    }else{
      return(
        <a className="m-l-10" href={this.state.exportUrl} rel="noopener noreferrer" target={'_blank'}>
          <div disabled className='btn btn-info btn-save'>Exportar a Excel</div>
        </a>
      )
    }
  }

  renderListPrograms(){
    const programs = this.state.programs;
    const columns = [
      {
        dataField: 'dummy',
        text: '',
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div style={{'display':'flex'}}>
                {rowIndex+1}
            </div>
          )
        }
      },
      {
        dataField: '_id',
        text: 'Nombre del programa',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cell,row) => {
          return(
            <Link to={`/manager/programs/${row._id}`}> {row.name} </Link>
          )
        }
      },
      {
        dataField: 'start_date',
        text: 'Inicio',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter:this.formatDate
      },
      {
        dataField: 'end_date',
        text: 'Fin',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter:this.formatDate
      },
      {
        dataField: 'companies_count',
        text: 'Empresas',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'users_count',
        text: 'Usuarios',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'status',
        text: 'Estatus',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
      },
      {
        dataField: 'is_public',
        text: 'Privacidad',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(!row.hasOwnProperty('is_public')){
            return 'Público'
          }else{
            if(row.is_public){
              return 'Público'
            }else{
              return 'Privado'
            }
          }
        }
      },
      {
        dataField: 'is_free',
        text: 'Tipo',
        sort: true,
        style: {
          verticalAlign:'middle'
        },
        formatter: (cellContent,row) => {
          if(!row.hasOwnProperty('is_free')){
            return 'Premium'
          }else{
            if(row.is_free){
              return 'Básico'
            }else{
              return 'Premium'
            }
          }
        }
      },
    ];
    let selectRow = {
      mode: 'checkbox',
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
            <KTableSmartSelect
                ref={ref => this.kTableSmartSelect = ref}
                onOptionSelected={(option) => this.onOptionSelected(option)}
                onCbClick={this.onCbClick}
                cbState={this.state.cb_state}
                options={this.smartSelectOptions}>
              </KTableSmartSelect>
        </div>
      ),
      onSelect:this.handleOnSelect,
      selected:this.state.selectedRows,
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };
    return(
        <div className="card-body">
          <div className='row'>
            <div className="col-md-6">
              <FormInput
              ref={ref => this.fi_query = ref}
              id="buscar"
              label="Buscar "
              model="query"
              value={this.state.query}
              showCounter={false}
              initialValue={''}
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Escribe el nombre del programa"
              onEnterPressed={() => this.onClickFindProgram()}/>
            </div>
            <div className="col-md-3">
              <Button style={{marginTop:'32px'}} className="btn btn-save" onClick={this.onClickFindProgram}>Buscar</Button>
            </div>
          </div>

          <div className='row'>
            <div className="col-md-6">
              <b className='m-t-10 m-b-10'>{this.state.search_msg}</b>
            </div>
          </div>

          <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
            <div style={{marginLeft:"20px"}}>
              {/* <KTableSmartSelect
                ref={ref => this.kTableSmartSelect = ref}
                onOptionSelected={(option) => this.onOptionSelected(option)}
                onCbClick={this.onCbClick}
                options={this.smartSelectOptions}>
              </KTableSmartSelect> */}
            </div>
            {this.renderActionButtons()}
          </div>
          <BootstrapTable
            bootstrap4
            striped
            caption={<TableCaption />}
            keyField='_id'
            loading={this.state.loading}
            data={ programs }
            columns={ columns }
            selectRow={ selectRow }
            noDataIndication={this.state.table_msg}
            wrapperClasses="m-t-20 table-responsive"
            classes="react-bootstrap-table"
            overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(203, 255, 255, 0.5)'}) } })}
          />
        </div>
    )
  }

  render () {
    //--------------------------------------------------------------------------
    //Indicates if the user can leave the page
      const formIsHalfFilledOut = this.state.formIsHalfFilledOut
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    if(this.state.authorized){
      return(
         <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <Prompt
              when={formIsHalfFilledOut} // <- function that returns boolean
              message={this.handleBlockedNavigation}
          />
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Programas</h3>
                    </div>
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                        <Tab.Container
                          mountOnEnter={true}
                          unmountOnExit={true}
                          defaultActiveKey="list_programs">
                          <Nav className="nav" bsPrefix='tabCustom' >
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="add_programs" id="add_programs">Agregar programas</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="list_programs" id='list_programs'>Lista de Programas</Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <Tab.Content className="tab-content">
                            <Tab.Pane eventKey="add_programs">
                              {this.renderAddProgramForm()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="list_programs">
                              {this.renderListPrograms()}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default CreateProgramView;
