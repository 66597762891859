import React from 'react';
import PropTypes from 'prop-types';

// Custom SCSS
import '../scss/components/_k_search_input.scss';


/**
*    A styled icon/input search.
*    On click the search icon renders a input.
*    On press enter returns the value typed.
*/

class KSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput:false,
      query:'',
    }
     this.handleShowInput      = this.handleShowInput.bind(this);
     this.onChangeInput        = this.onChangeInput.bind(this);
     this.onEnter              = this.onEnter.bind(this);
     this.onBlur               = this.onBlur.bind(this);
  }

  handleShowInput(show){
    this.setState({showInput:show});
  }

  onChangeInput(data){
    this.setState({query:data.target.value});
  }

  onEnter(){
    this.props.onEnter(this.state.query);
  }

  onBlur(){
    this.handleShowInput(false);
    this.setState({query:''});
  }

  renderInput(){
    if(this.state.showInput){
      return(
        <input  autoFocus
               type='text'
               className="form-control m-r-10"
               placeholder={'Buscar...'}
               value={this.state.query}
               onChange={(value) => this.onChangeInput(value)}
               onKeyPress={event => {
                            if (event.key === 'Enter') {
                              this.onEnter();
                            }
                          }}
               onBlur={() => this.onBlur()}
              />
      )
    }else{
      return null
    }
  }

  render(){
    return(
      <div className="wrapper_search_input">
        {this.renderInput()}
        <i className="mdi mdi-account-search" style={{fontSize: '24px', color:this.state.showInput ? '#007bff' : "#c2c2c2" }} onClick={() => this.handleShowInput(true)}></i>
      </div>
    )
  }
}

KSearchInput.propTypes = {
  /** Callback on pressed the enter key */
  onEnter         : PropTypes.func,
  isMultiple      : PropTypes.bool,
}
KSearchInput.defaultProps = {
  onEnter         : () => {},
  isMultiple      : false
}

export default KSearchInput;
