/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */
//regular imports
import React from 'react';
import './KUsersList.css';
import PropTypes from 'prop-types';
//components
import KImage from '../components/KImage';


/**
 *  Render the user row, for the component [KUsersList](/#kuserslist)
 */


class KUserRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClickUser    = this.onClickUser.bind(this);
  }

  onClickUser(user){
    this.props.onClickUser(user);
  }

  renderNameorEmail(user){
    if(user.name === null){
      if(user.hasOwnProperty("not_found")){
        return user.email + " (invitar a komunidad.io)";
      }
      return user.email;
    }else{
      return user.name + ' ' + user.last_name_father + ' ' + user.last_name_mother;
    }
  }


  render() {
    const user = this.props.user;
    return(
      <div className="kuRow" onClick={() => this.onClickUser(user)}>
        <KImage src={user.profileUrlThumbnail} className={"kuImage"} alt={"user_img"}/>
        <div className="kuWrapper">
          <div>{this.renderNameorEmail(user)}</div>
          <div className="kuEmail text-muted">{user.email}</div>
        </div>
      </div>
    )
  }
}

KUserRow.propTypes = {
  /** The user object with the required fields:
      id, email */
  user            : PropTypes.object,
  /** Callback on user clicked. */
  onClickUser     : PropTypes.func,
};

KUserRow.defaultProps = {
  user             : {},
  onClickUser      : ()=>{}
};

export default KUserRow;
