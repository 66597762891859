/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx> && Israel Arellano <Isra_darrell@startuplab.mx>, March 2021
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import { Tab, Nav } from 'react-bootstrap';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../scss/pages/_style.css';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import KCreateAndEditCourse from '../../components/KCreateAndEditCourse';


class AcademyView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // Regular vars
      authorized:false,
      sidebarVisible:true,
      sidebarStatus: false,
			showTopbar: true,
      me: {},
      // Custom vars
      courseId: '',
      course: {},
      chapters: [],
      chapter_number: 0,
      chapter_name: '',
      chapter_description: '',
      chapter_link: '',
      chapter_service: '',
      
    }

    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.renderEditCourseTab          = this.renderEditCourseTab.bind(this);
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
  }

  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator") 
    )
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
            });
          }else{
            this.redirectToProfile();
          }
          this.setState({courseId: this.props.match.params.id})
          this.getCourse();
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  getCourse(){
    KomunidadApi.getCourse(this.state.courseId).then((res) => {
      if(res.success){
        this.setState({
          course: res.course,
          chapters: res.course.chapters.sort(function (a, b) { return a.priority - b.priority;}),
          chapter_name: res.course.chapters[0].name,
          chapter_description: res.course.chapters[0].description,
          chapter_link: res.course.chapters[0].url,
          chapter_service: res.course.chapters[0].service,
          chapter_number: 0,
        })
      }
    }).then(res => {
      // SOMETHING ELSE
    });
  }


  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  redirectHome(){
    this.props.history.push('/');
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
    });
  }


  renderEditCourseForm(){
    return(
      <div className="card-body">
        <div className="row">
         <div className="col-lg-10 col-md-10">
            <KCreateAndEditCourse
                isCreateMode={false}
                editMode={true}
                course={this.state.course}
                facilitatorView={this.state.me.roles.includes('facilitator')}
                onUpdateCourse={this.getCourse}/>
         </div>
        </div>
      </div>
    )
  }

  onSelectChapter(chapter) {
    this.setState({
      chapter_name: chapter.name,
      chapter_description: chapter.description,
      chapter_link: chapter.url,
      chapter_service: chapter.service,
      chapter_number: chapter.priority
    })
  }

  renderChapters() {
    let chapters = this.state.chapters;
    if(chapters) {
      return(
          chapters.map((chapter,index) =>
          (
            <li>
              <label htmlFor={index}>
                <label style={{fontWeight: 'normal', fontSize: '14px', cursor: 'pointer'}} onClick={() => this.onSelectChapter(chapter)}>
                  {/*{chapter.priority + 1}.-*/}{chapter.name}
                </label>
              </label>
            </li>
          )
        )
      )
    } else {
      return (null)
    }
  }

  changeChapter(status) {
    let change = this.state.chapter_number;

    if(status === 'next') {
      if(this.state.chapter_number + 1 < this.state.chapters.length) {
        change = this.state.chapter_number + 1;
      }
    } else {
      if(this.state.chapter_number > 0) {
        change = this.state.chapter_number - 1;
      }
    }

    let chapter = this.state.chapters.filter(chapter => chapter.priority === change);
    this.onSelectChapter(chapter[0]);
  }

  renderIframe() {
    if(this.state.chapter_service === 'youtube.com'){
      return(
        <iframe 
          width="100%" 
          height="100%" 
          src={this.state.chapter_link}
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      )
    } else {
      return(
        <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="100%"
          src={this.state.chapter_link}
          frameBorder="0">
        </iframe>
      )
    }
  }

  renderChapterDescription(){
    if(this.state.chapter_description !== ''){
      return(
        <div className="d-flex">
          <div className="align-self-center">
            <p className="card-title m-t-10">{this.state.chapter_description}</p>
          </div>
        </div>
      )
    }else{
      return null
    }
  }

  renderCourseContent(){
    return (
      <div className="card">
        <div className="row">
          <div className="col-lg-3 col-md-12 bg-light-part">
            <div className="row">
              <div className="col-lg-12 col-md-3 col-sm-12">
                {/* {this.renderProgress()} */}
              </div>
              <div className="col-lg-12 col-md-9 col-sm-12">
                <ul className="list-style-none mx-3">
                  <li className="box-label"><a>Módulos: {this.state.chapters.length}</a></li>
                  <li className="divider"></li>
                  {this.renderChapters()}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="right-page-header my-2">
              <div className="row">
                  <div className="col-8">
                    <h4 className="card-title m-t-10">{this.state.chapter_name}</h4>
                  </div>
                  <div className="col-4">
                    <div className="btn btn-info py-1 btn-aux float-right" onClick={() => this.changeChapter('next')}>
                      <i className="mdi mdi-chevron-right" style={{fontSize: '18px'}}></i>
                    </div>
                    <div className="btn btn-info py-1 btn-aux float-right mr-2" onClick={() => this.changeChapter('prev')}>
                      <i className="mdi mdi-chevron-left" style={{fontSize: '18px'}}></i>
                    </div>
                </div>
              </div>
              {this.renderChapterDescription()}
            </div>
            <div style={{height: '70vh'}}>
              {this.renderIframe()}
            </div>
          </div>
        </div>             
      </div>
    );
  }

  renderEditCourseTab(){
    return(
      <Nav.Item className="nav-item">
        <Nav.Link className="nav-link" eventKey="add_programs" id="add_programs">Editar curso</Nav.Link>
      </Nav.Item>
    )
  }
  render () {
    if(this.state.authorized){
      return(
         <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                  <div className="col-md-5 align-self-center">
                    <h3 className="text-themecolor">{this.state.course.name}</h3>
                  </div>
                  {/* <div className="col-md-7 align-self-center">
                    <div className="btn btn-info btn-aux float-right" onClick={() => this.props.history.push('/manager/academy')}>
                      Regresar a academia
                    </div>
                  </div> */}
                </div>
                {/* End bread crumb*/}

                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-12">
                    <div className="card">
                        <Tab.Container
                          mountOnEnter={true}
                          unmountOnExit={true}
                          defaultActiveKey="list_programs">
                          <Nav className="nav" bsPrefix='tabCustom'>
                            <Nav.Item className="nav-item">
                              <Nav.Link className="nav-link" eventKey="list_programs" id='list_programs'>Contenido</Nav.Link>
                            </Nav.Item>
                            {this.renderEditCourseTab()}
                          </Nav>

                          <Tab.Content className="tab-content">
                            <Tab.Pane eventKey="list_programs">
                              {this.renderCourseContent()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="add_programs">
                              {this.renderEditCourseForm()}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                    </div>
                  </div>
                  {/* End column */}
                </div>
                {/* End row */}

              </div>
              {/* End container fluid  */}
              <Footer/>
            </div>
            {/* End page wrapper  */}

          </div>
        </div>
      )
    }else{
      return null
    }
  }
}

export default AcademyView;
