/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

//regular imports
import React from 'react';
import './KUsersList.css';
import PropTypes from 'prop-types';

//components
import KUserRow from './KUserRow';


/**
 *  Render a list of users, using as row the component [KUserRow](/#kuserrow)
 */


class KUsersList extends React.Component {
  render() {
    const users = this.props.users;

    if(this.props.failedLoad){
      return (
        <div className="kuMainWrapper">
          Hubo un problema al buscar los usuarios.
        </div>
      )
    }
    if(users.length > 0){
      return (
        <div  className="kuMainWrapper">
          <ul className="kuList">
            {users.map((item,index) =>
              (
                <KUserRow key={index}
                          user={item}
                          onClickUser={(user) => this.props.onClickUser(user)}
                />
              )
            )}
          </ul>
        </div>
      );
    }else{
      if(this.props.showEmptyListLabel){
        return(
          <div className="kuEmptyLabel">
            No se encontró ningún usuario.
          </div>
        )
      }else{
        return null
      }
    }
  }
}

KUsersList.propTypes = {
  /** An array of user object. */
  users              : PropTypes.array,
  /** Callback on user clicked. */
  onClickUser        : PropTypes.func,
  /** Show a label if load fail.
  The default label is: 'Hubo un problema al buscar los usuarios.' */
  failedLoad         : PropTypes.bool,
  /** Show a label if the list is empty.
  The default label is: 'No se encontró ningún usuario.' */
  showEmptyListLabel : PropTypes.bool,
};

KUsersList.defaultProps = {
  users              : [],
  onClickUser        : ()=>{},
  failedLoad         : false,
  showEmptyListLabel : false,
};

export default KUsersList;
