/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';

//Components
import KMentorsRow from './KMentorsRow';
import KMentorsCompleteRow from './KMentorsCompleteRow';

/**
*   Render a list of mentors using the row [KMentorsRow](/#kmentorsrow)
*   or [KMentorsCompleteRow](/#kmentorscompleterow).
*/

class KMentorsList extends React.Component {

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al cargar los aliados</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderRow(mentors){

    if(this.props.showCompleteRow){
      return(
        <tbody>
          {mentors.map((item,index) =>
            (
              <KMentorsCompleteRow
                key={index}
                mentor={item}
                programId={this.props.programId}
                isMyMentor={this.props.isMyMentor}
              />
            )
          )}
        </tbody>
      )
    }else{
      return(
        <tbody>
          {mentors.map((item,index) =>
            (
              <KMentorsRow  key={index}
                            mentor={item}
                            onClickDeleteMentors={this.props.onClickDeleteMentor}
                            isUserOwner={this.props.isUserOwner}
              />
            )
          )}
        </tbody>
      )
    }
  }

  render() {
    const mentors = this.props.mentors;

    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(mentors.length > 0){
        return(
          // tableBorderB
          <Table responsive
                 striped={this.props.stripedStyle}
                 borderless={this.props.borderlessStyle}
                 className='tableVMiddle'>
            {this.renderRow(mentors)}
          </Table>
        )
      }else{
        // empty list
        return null
      }
    }else{
      return this.renderFailed()
    }
  }
}

KMentorsList.propTypes = {
  /** Mentor list. */
  mentors              : PropTypes.array,

  /** Callback on mentor deleted. */
  onClickDeleteMentor  : PropTypes.func,

  /** Status of the loading data: idle, fetching, success and failed. */
  requestStatus        : PropTypes.string,

  /** True when the user is owner. */
  isUserOwner          : PropTypes.bool,

  /** When is true show the component [KMentorsCompleteRow](/#kmentorscompleterow).
      by default the row is [KMentorsRow](/#kmentorsrow).*/
  showCompleteRow      : PropTypes.bool,

  /** When is true the style list is striped. */
  stripedStyle         : PropTypes.bool,

  /** When is true the list is borderless. */
  borderlessStyle      : PropTypes.bool,

  /* When is needed to use program id for the invitations */
  programId            : PropTypes.string,

  /** True when the user is watching his mentors */
  isMyMentor           : PropTypes.bool
};

KMentorsList.defaultProps = {
  mentors              : [],
  onClickDeleteMentor  : ()=>{},
  requestStatus        : 'idle',
  isUserOwner          : false,
  showCompleteRow      : false,
  stripedStyle         : false,
  borderlessStyle      : false,
  programId            : '',
  isMyMentor           : true
};

export default KMentorsList;
