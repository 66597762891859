import KomunidadApi from '../api/api.js';
import * as _ from 'lodash'
/*
 * Checks if the given email is valid
 *
 * @params String string
 *
 * @returns Boolean
*/
export function validateEmail (email) {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
  return re.test(String(email).toLowerCase().trim())
}

/*
 * Checks if the given string has a given length
 *
 * @params String string
 * @params Integer length
 *
 * @returns Boolean
*/
export function validateStringLength (string, length) {
  return string.length >= length
}

/*
 * Checks if the given string has a valid phone format
 *
 * @params string value
 * @returns bool
 */
export function validatePhone (value) {
  let re = /^\d{10}$/
  return re.test(value)
}

/*
 * Checks if the given value is not null, [],
 * {}, undefined or ''
 *
 * @params mixed value
 * @returns bool
 */
export function required (value) {
  return !_.isNil(value) && !_.isNull(value) && !_.isEmpty(value) && _.isString(value)
}

/*
 * Validates a string length
 *
 * @params String string
 * @params Integer min_length = false
 * @params Integer max_length = false
 *
 * @returns Boolean
*/
export function validateMinMaxLength (string, min_length = false, max_length = false) {
  let valid = true

  if (min_length) valid = string.length >= min_length
  if (max_length) valid = string.length <= max_length

   return valid
}
var isFilled = function(v){
  if(v === null || v === undefined){
    return false;
  }else{
    if( v.toString().trim() === ""){
      return false;
    }else{
      return true;
    }
  }
}
/*
 * Validates the completition of a user's profile
 *
 * @params Object user
 *
 * @returns Boolean
*/
//Temporal fix:
export function isUserProfileComplete(user){
  let roles = user.roles || [];
  if(roles.includes('mentor') && user?.mentorIsCompany){
    const isComplete = isFilled(user?.mentorCompanyName) &&
    isFilled(user?.mentorCompanyWebsite) &&
    isFilled(user?.mentorCompanyAddress) &&
    isFilled(user?.mentorCompanyContactName) &&
    isFilled(user?.mentorCompanyEmail) &&
    isFilled(user?.mentorCompanyPhone);
    isFilled(user?.mentorCompanyPhase);
    return isComplete;
  }
  if(!isFilled(user.name)){
    return false;
  }
  if(!isFilled(user.last_name_father)){
    return false;
  }
  if(!isFilled(user.last_name_mother)){
    return false;
  }
  // if(!user.hasOwnProperty("gender")){
  //   return false
  // }else{
  //   if(user.gender === null){return false};
  // }
  // if(!user.hasOwnProperty("dateOfBirth")){
  //   return false
  // }else{
  //   if(user.dateOfBirth === null){return false};
  // }
  if(!user.hasOwnProperty("phone")){
    return false
  }else{
    if(user.phone === null){return false};
  }
  //check for mentors data;
  if(roles.includes("mentor")){
    if(!isFilled(user.mentor_biography)){
      return false;
    }
    let specialities_sectors = user.mentor_speciality_sector || [];
    if( specialities_sectors.length > 0 || isFilled(user.mentor_speciality_sector_other)){
      // return false;
    }else{
      return false;
    }
  }
  if(process.env.REACT_APP_REQUIRED_ID === "true") {
    if(roles.includes("mentor") || roles.includes("partner")) {
      if(!isFilled(user.id_scan)){
        return false;
      }
    }
  }
  return true
}
export function getAfterLoginRedirectPath(user,companies){
  let roles = user.roles;
  if(!isUserProfileComplete(user)){
    return '/profile/edit';
  }
  else if(roles.includes("manager")){
    return '/manager';
  }
  else if(roles.includes('facilitator')){
    return '/manager';
  }else{
    let invitations = companies.filter(
      company => company.invitation_status === "invited"
    );
    if(invitations.length > 0){
      return `/companies/${invitations[0]._id}`;
    }else{
      return '/live/';
      // return '/companies/';
    }
  }
}
export async function getProfileStatus(user){
  function getCompaniesWithPromise(){
    return new Promise((resolve, reject) => {
      KomunidadApi.getCompanies().then((res) => {
        if(res.success){
          resolve(res.companies);
        }else{
          reject(null);
        }
      });
    });
  }
  //returns null if error
  //returns boolean
  return new Promise(async function(resolve,reject){
    //If user user a manager or facilitator, let him in.
    let companies = await getCompaniesWithPromise();
    if(user.roles.includes("facilitator") || user.roles.includes("manager")){
      let redirectPath = getAfterLoginRedirectPath(user,companies);
      let statusObj = {
        'redirectPath':redirectPath
      }
      resolve(statusObj);
    }else{
      // wait for companies to be fetched
      if( companies !== null ){
        let companiesWithProgramLength = companies.filter( c =>{
          if(c.hasOwnProperty("programs")){
            return c.programs.length > 0;
          }else{
            return false;
          }
        } ).length;

        // if user is partner || mentor, redirect
        let redirectPath = getAfterLoginRedirectPath(user,companies);
        let statusObj = {
          'redirectPath':redirectPath
        }
        resolve(statusObj);
      }else{
        //in case of error, return null
        reject(null);
      }
    }
  }); //end of promise
}
/*
 * Return the full name of the user, if the user  doesn't have any fiels return the email.
 *
 * @params Object user
 *
 * @returns string
*/
export function renderFullNameOrEmail(user){
  if(!user){
    return ("Usuario no encontrado");
  }
  if(user?.mentorIsCompany){
    return `${user.mentorCompanyName}`;
  }
  if(!user?.name){
    return user.email;
  }else{
    let fullName = user.name;
    fullName = [fullName, user.last_name_mother, user.last_name_father].filter((s) => s).join(' ');
    return fullName;
  }
}
/*
 * Validates a date if belongs to a month
 *
 */
export function itBelongsToMonth(year,month,date){
  let re = new RegExp(year+"-"+month+"-(.*)");
  return re.exec(date);

}
