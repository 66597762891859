/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, Febreary 2020
 */

//Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import 'moment/locale/es';

//componentes
import FormInput from '../components/form/FormInput';

import { editDateMask } from '../components/form/transforms.js';

class KDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen:false,
      selectedDate:'',
      form_input_value:'',
      inputChanged:false
    }
    this.onFormInputChange   = this.onFormInputChange.bind(this);
    this.formattedDate       = this.formattedDate.bind(this);
    this.onDateSelected      = this.onDateSelected.bind(this);
    this.cancel              = this.cancel.bind(this);

  }
  static getDerivedStateFromProps(props, current_state){
    // let v = props.value;
    // let cv = v;
    if(!current_state.inputChanged){
      return{
        form_input_value:props.value,
      };
    }
  }

  clear(){
    this.setState({
      form_input_value: '',
      selectedDate:'',
      inputChanged:false
    });
  }
  onFormInputChange(data){
    let _this = this;
    this.datePicker.setOpen(false);
    this.setState({
        "form_input_value":data.value,inputChanged:true
      },() =>{
      if(data.hasOwnProperty("cleanValue")){
        if(data.value.length === 10){
          let date = data.value.split("-")
          let new_date = new Date(date[2],date[1] - 1,date[0]);
          if(new_date > this.props.maxDate){
            new_date = this.props.maxDate;
            this.setState({
              form_input_value: this.formattedDate(new_date).formattedDate
            });
          }
          if(new_date < this.props.minDate){
            new_date = this.props.minDate;
            this.setState({
              form_input_value: this.formattedDate(new_date).formattedDate
            });
          }
          let d = this.formattedDate(new_date);
          _this.props.onDateChanged(d);
          this.setState({
            selectedDate:new_date
          }, () =>{
          });
        }else{
          _this.props.onDateChanged(null);
        }
      }

    })
  }
  formattedDate(date){
    let day = date.getDate().toString();
    let month = date.getMonth() + 1;
        month = month.toString();
    let year = date.getFullYear().toString();

    day   = day.padStart(2,0);
    month = month.padStart(2,0);

    return {
      day:day,
      month:month,
      year:year,
      formattedDate: `${day}-${month}-${year}`,
      date:date
    }
  }
  onDateSelected(date) {
    let d = this.formattedDate(date);
    this.setState({
      selectedDate:date,
      form_input_value:d.formattedDate,
      inputChanged:true
    }, () =>{
      this.fi_date.clearError();
      this.props.onDateChanged(d);
    });
  }
  cancel(){
    this.fi_date.cancel();
  }
  render(){
    const CustomDateInput = ({value, onClick, onChange,  ...props }) => (
      <button  className={this.props.editMode ? 'btnCalendar' : 'date-picker-non-display'} onClick={onClick}>
        <i className="mdi mdi-calendar" style={{fontSize: '20px', color: '#67757c'}}></i>

      </button>
    );

    return(
      <div  className="form-group form-horizontal wrapperDateCalendar m-b-0" style={{overflow: 'visible'}}>
        <div className="widthInputCalendar">
          <FormInput
            ref={ref => this.fi_date = ref}
            id={this.props.id}
            label={this.props.label}
            model={this.props.model}
            showCounter={false}
            editMode={this.props.editMode}
            value={this.state.form_input_value}
            initialValue={this.props.initialValue}
            help={this.props.help}
            required={this.props.required}
            transformMethod={editDateMask}
            transformDisplayMethod={editDateMask}
            validationRules={this.props.validationRules}
            onChange={ change => { this.onFormInputChange(change)} }
            onClick={() => {this.setState({isCalendarOpen:true})}}
            placeholder={this.props.placeholder}
            />
        </div>
        <div style={{display:'flex',alignItems:'center',marginTop:-31}}>
          <DatePicker
            ref={ref => this.datePicker = ref}
            selected={this.state.selectedDate}
            onSelect={this.onDateSelected}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="es"
            onChange={this.onDateSelected}
            dateFormat="dd MMMM yyyy"
            disabled={!this.props.editMode}
            className={this.props.editMode ? 'form-control form-control-line' : 'date-picker-non-editable'}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            placeholderText="Selecciona tu fecha"
            customInput={<CustomDateInput style={{backgroundColor:'red'}} />}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "-25px, -10px"
              },
            }}/>
        </div>

      </div>
    )
  }
}

KDatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  model: PropTypes.string,
  editMode: PropTypes.bool,
  value: PropTypes.string,
  initialValue:PropTypes.string,
  help:PropTypes.string,
  onDateChanged:PropTypes.func,
  required:PropTypes.bool,
  validationRules:PropTypes.string,
  minDate:PropTypes.instanceOf(Date),
  maxDate:PropTypes.instanceOf(Date)

};

KDatePicker.defaultProps = {
  id: '',
  label: 'Add a label',
  model: '',
  editMode: true,
  value: '',
  initialValue:'',
  help:'',
  onDateChanged:()=>{},
  required:true,
  placeholder:'Add a placeholder',
  validationRules:"required",
  minDate:new Date(),
  maxDate:addDays(new Date(), 365)
};

export default KDatePicker;
