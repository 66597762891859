/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, November 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import KomunidadApi from './../../api/api.js';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

/**
 *  Display and edit the company information (Settings content).
 */

class KTabSettingsContent extends React.Component{
  constructor(props) {
    super(props)
    this.disableCompany    = this.disableCompany.bind(this);
    this.completeDisable   = this.completeDisable.bind(this);
  }


  disableCompany(disabled) {

    if(!disabled) {
      (async () => {
        const { value: password } = await Swal.fire({
          title: '¿Deseas deshabiliar tu empresa?',
          text: "Una vez deshabilitado, no podrás volverlo a habilitar",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'swalBtnRedOrange',
            cancelButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          input: 'password',
          inputPlaceholder: 'Introduce tu contraseña para deshabilitar tu empresa.',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('La contraseña no puede ser vacía');
              } else {
                resolve();
              }
            })
          },
        })

        if (password) {
          this.completeDisable(password);
        }

      })()
    }
  }

  completeDisable(password) {
    KomunidadApi.changeStatus(this.props.company._id, password).then((res) => {
     if(res.success){
       Swal.fire({
         type: 'success',
         title: 'empresa deshabilitada',
         customClass: {
           confirmButton: 'swalBtnTeal',
           title: 'swal-custom-title',
         },
       });
       this.props.refreshCompany();
     }else{
       Swal.fire({
         type: 'error',
         title: res.error_es,
         customClass: {
           confirmButton: 'swalBtnTeal',
           title: 'swal-custom-title',
         },
       });
     }
    });
  }

  render(){
    let isCompanyDisabled = false;
    if(this.props.company.hasOwnProperty('is_disabled')){
      isCompanyDisabled = this.props.company.is_disabled;
    }

    if(isCompanyDisabled){
      return(
        <div className="card-body">
          <div className='text-danger m-t-10'>Tu empresa está deshabilitado.</div>
        </div>
      )
    }else{
      let disabled = false;
      if(this.props.company.hasOwnProperty('user_is_owner')){
        disabled = !this.props.company.user_is_owner;
      }

      return(
        <div className="card-body">
          <Button className='btn btn-danger m-t-10'
                  onClick={() => this.disableCompany(disabled)}
                  disabled={disabled}>Deshabilitar empresa</Button>

          <div className='m-t-10'>Una vez deshabilitado el empresa no se podra editar.</div>
        </div>
      )
    }
  }

}

KTabSettingsContent.propTypes = {
  /** Company object */
  company               : PropTypes.object,
  /** Callback to update the company data*/
  refreshCompany        : PropTypes.func,
};

KTabSettingsContent.defaultProps = {
  company               : {},
  refreshCompany        : () => {},
};

export default KTabSettingsContent;
