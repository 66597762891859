/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, October 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import {Modal,Button} from 'react-bootstrap';

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isUserProfileComplete } from '../utils/Utils.js';
import KSpeakerList from '../components/KSpeakerList';
import speakers from './agendaCreoEdoMex.json'

// Images
import {getCommingSoon, getCommingSoonSLP} from "../utils/Vendor.js";
const CommingSoon = getCommingSoon();
const CommingSoonSLP = getCommingSoonSLP();

class ScheduleView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized:false,
            sidebarVisible: true,
            sidebarStatus: false,
			showTopbar: true,
            me:{},
            resources:[],
            resourcesLoadState:'default',
            selectedId:[0],
            programName:[0],
            showWelcomeModal:true,
            loading: false
        }
        this.handleResizeBar               = this.handleResizeBar.bind(this);
        this.handleHeaderResize            = this.handleHeaderResize.bind(this);
        this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
        this.hideModal                     = this.hideModal.bind(this);
        this.handleOpenModal               = this.handleOpenModal.bind(this);
    }

    componentDidMount(){
        KomunidadApi.getMe().then( (res) => {
        if(res.valid_token){
            if(!isUserProfileComplete(res.user)){
                this.redirectToEditProfile();
            }else{
                this.setState({
                    me:res.user,
                    authorized:true,
                    loading: true
                });
                if(localStorage.getItem('welcomeModal') === 'showed'){
                    this.setState({showWelcomeModal: false})
                }
            }
        }else{
            //redirect profile
            this.props.history.push('/profile');
        }
        });
        window.addEventListener("resize", this.handleResizeBar);
        this.handleResizeBar()
    }

    redirectToEditProfile(){
        this.props.history.push('/profile/edit');
    }


    handleHeaderResize(width){
        this.setState({
            sidebarVisible:width <= 500 ? false : true
        });
    }

    handleResizeBar(method) {
        const windowSize = window.innerWidth;
        if(method === 'byClick') {
          this.setState({
            sidebarStatus: !this.state.sidebarStatus,
          })
        } else {
          if(windowSize <= 994) {
            this.setState({
              sidebarStatus: true,
              showTopbar: false
            })
          } else {
            this.setState({
              sidebarStatus: false,
              showTopbar: true
            })
          }
        }
      }

    hideModal(){
        this.handleCloseModal(false);
    }
    handleOpenModal(value){
        this.setState({showWelcomeModal: value});
    }
    onExitModal(){
        localStorage.setItem('welcomeModal', 'showed');
    }

    renderWelcomeModal() {
        return (
            <Modal
                show={this.state.showWelcomeModal}
                onHide={() => this.handleOpenModal(false)}
                onExited={this.onExitModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg">
                <Modal.Header>
                <Modal.Title>
                    <img src={ CommingSoonSLP } alt="Home" width="100%" className="" />
                    Bienvenido a CREO MX
                </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="mt-1 text-left">
                        <p>¿Estás listo para vivir la experiencia CREO MX?</p>
                        <p><b>1.</b> En la sección “AGENDA” elige las ponencias, talleres, paneles de tu interés y conoce más sobre nuestros aliados y sus conferencias.</p>
                        <p><b>2.</b> Conoce más sobre las instituciones que hacen posible #CREOMX en la sección de “ALIADOS” y conéctate con ellas. </p>
                        <p><b>3.</b> Queremos conocerte mejor para apoyar tu camino emprendedor, ingresa la información de tu empresa o proyecto en la sección “MI EMPRESA” y obtén tu one pager gratis.</p>
                        <p><b>4.</b> Hemos preparado material de interés para tu emprendimiento y/o PyME, consúltalo en la sección de “MATERIAL DE APOYO”.</p>
                    </div>
                    <div className="float-right">
                        <Button
                            className={"btn btn-save"}
                            onClick={() => this.handleOpenModal(false)}
                        >
                            Cerrar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    renderAgenda(){
        let agendaViewport = document.getElementById("agenda-viewport");
        if (agendaViewport){
            agendaViewport = agendaViewport.offsetHeight;
            return (
                <div id="scheed-agenda">
                    <iframe
                    src="https://tockify.com/creomx/agenda"
                    title="Creo MX 2020"
                    // m-top = 70, m-bottom = 60, ui = 82 ... 212px
                    height={agendaViewport-212+"px"}
                    width="100%"
                    frameBorder="0"
                    ></iframe>
                </div>
            );
        } else {
            return null
        }
    }
    renderCommingSoon(){
        return (
            <div className="container text-center">
                {/* <script data-cfasync="true" data-tockify-script="embed" src="https://public.tockify.com/browser/embed.js"></script>
                <div data-tockify-component="calendar" data-tockify-calendar="creomx"></div> */}
                <h1 className="mb-1">¡Próximamente CREO MX!</h1>
                <h3>26 de Agosto</h3>
                <img src={ CommingSoon } alt="Home" width="50%" className="" />

            </div>
        )
    }

    renderScheduleGrid() {
/*        let sortedSpeakers = speakers.speakers;

        sortedSpeakers = orderBy(sortedSpeakers, ['name']);
*/
        return (
          <KSpeakerList speakers={speakers.speakers}/>
        )
    }

  render(){
   if(this.state.authorized){
     return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
         <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
           {/* Page wrapper  */}
           <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
               <div className="container-fluid">

                {/*<div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                     <h3 className="text-themecolor">Agenda CREO MX 2021</h3>
                    </div>
                </div>*/}
                {/* Start Page Content */}
                {/*this.renderWelcomeModal()*/}
                {/* {this.renderAgenda()} */}
                {this.renderScheduleGrid()}
                {/*{this.renderCommingSoon()}*/}
                {/* End PAge Content */}
               </div>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default ScheduleView;
