/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, March 2020
 */

//regular imports
import React from 'react';
import PropTypes from 'prop-types';


//components
import EBEvent from './EBEvent';

/**
*  Shows a list of events using the component: [EBEvent](/#EBEvent).
*
*/

class EBListEvents extends React.Component {

  renderFailed(){
    return(
      <div className="text-center m-b-20">
        <h3>Hubo un problema al cargar los eventos</h3>
        <i className="mdi mdi-exclamation m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderEmptyList(){
    return(
      <div className="text-center m-b-20">
        <h3>No hay eventos que seleccionar</h3>
        <h5>{this.props.subtitleEmptyList}</h5>
      </div>
    )
  }

  render() {
    const events = this.props.events;

    if(this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching'){
      return null
    }else if(this.props.requestStatus === 'success'){
      if(events.length > 0){
        return(
          <div className="card-body">
            {events.map((item,index) =>
              (
                <EBEvent key={index} event={item} onClickEvent={(event) => this.props.onClickEvent(event)}/>
              )
            )}
          </div>
        )
      }else{
        // empty list
        return this.renderEmptyList();
      }
    }else{
      return this.renderFailed();
    }
  }
}

EBListEvents.propTypes = {
  /** Events list. */
  events              : PropTypes.array,
  /** Status of the loading data: idle, fetching, success and failed. */
  requestStatus        : PropTypes.string,
  /** Message (subtitle) if the list is empty. */
  subtitleEmptyList    : PropTypes.string,
  /** Callback on click event*/
  onClickEvent         : PropTypes.func,

};

EBListEvents.defaultProps = {
  events              : [],
  requestStatus        : 'idle',
  subtitleEmptyList    : '',
  onClickEvent      : ()=>{}

};

export default EBListEvents;
