/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>,
 * Darien Miranda <darien@startuplab.mx> March 2020
 * América Mendoza <america@startuplab.mx> March 2020
 */

// Regular imports
import React, { Component } from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';
import {  Button, Dropdown, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';
import BounceLoader from 'react-spinners/BounceLoader';
import Dropzone from 'react-dropzone';

//Style
import '../../scss/pages/_style.css';
import '../../scss/pages/dashboard1.scss';
import '../../scss/CertEditor.scss';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormTextArea from '../../components/form/FormTextArea';
import FormInput from '../../components/form/FormInput';
import ProgramsPicker from '../../components/ProgramsPicker';

//Data
import TCert1 from '../../data/cert1.json';

var fileDownload = require('js-file-download');

class CertificateEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      me: {},
      authorized:false,
      // openText:true,
      // openImages:false,
      // openSignature:false,
      showLoader:false,
      originalWidth:1650,
      originalHeight:1275,
      svgWidth:0,
      svgHeight:0,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      scale:0.6,
      description:'',
      name:'',
      cert_name:'DIPLOMA',
      place_and_date:'Ciudad, Estado. a dias de Mes de Año',
      mainLogo:null,
      numberOfSignatures:1,
      signatureWidth:0,
      signature_name_1:'Escriba un nombre aquí',
      signature_position_1:'Escriba un puesto aquí',
      signature_img_1:null,
      signature_name_2:'Escriba un nombre aquí',
      signature_position_2:'Escriba un puesto aquí',
      sponsors:null,
      template:{
          "name":"",
          "author":"",
          "preview":null,
          "elements":{
            "frames":{
              "frame_main":{
                "x":0,
                "y":0,
                "w":0,
                "h":0,
                "style":{
                  "strokeWidth":0,
                  "stroke":""
                }
              },
              "background":{
                "src":null
              }
            },
            "main_data":{
              "text_issues":{
                "x":0,
                "y":0,
                "fontSize":0,
                "fontFamily":"",
                "text":"",
                "fill":"",
                "textAnchor":"center"
              },
              "text_certificate":{
                "x":0,
                "y":0,
                "fontSize":0,
                "fontFamily":"",
                "text":"",
                "fill":"",
                "textAnchor":"center"
              },
              "text_name":{
                "x":0,
                "y":0,
                "fontSize":0,
                "fontFamily":"",
                "text":"",
                "fill":"",
                "textAnchor":"center"
              },
              "text_description":{
                "x":0,
                "y":0,
                "fontSize":0,
                "fontFamily":"",
                "text":"",
                "fill":"",
                "textAnchor":"center"
              },
              "text_place_and_date":{
                "x":0,
                "y":0,
                "fontSize":0,
                "fontFamily":"",
                "text":"",
                "fill":"",
                "textAnchor":"center"
              },
              "image_main_logo":{
                "x":0,
                "y":0,
                "height":0,
                "src":null,
                "class":"svg_image_center"
              }
            },
            "signatures":{
              "signatures_height":0,
              "signatures_width":0,
              "marginLeft":0,
              "spaceMode":"center",
              "amount":0,
              "signatureLine":{
                "stroke":"black"
              },
              "text_signature_1_name":{
                "fill":0,
                "text":0,
                "fontSize":0,
                "fontFamily":"",
                "textAnchor":"center"
              },
              "text_signature_1_position":{
                "fill":0,
                "text":0,
                "fontSize":0,
                "fontFamily":"",
                "textAnchor":"center"
              },
              "image_signature_1":{
                "h":0,
                "src":null
              },
              "text_signature_2_name":{
                "fill":0,
                "text":0,
                "fontSize":0,
                "fontFamily":"",
                "textAnchor":"center"
              },
              "text_signature_2_position":{
                "fill":0,
                "text":0,
                "fontSize":0,
                "fontFamily":"",
                "textAnchor":"center"
              },
              "image_signature_2":{
                "h":0,
                "src":null
              }
            },
            "sponsors":{
              "image_sponsors":{
                "x":0,
                "y":0,
                "h":0,
                "src":null,
                "class":"svg_image_center"
              }
            }
          }
        },
      programId:null,

    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.redirectToProfile               = this.redirectToProfile.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.updateDimensions                = this.updateDimensions.bind(this);
    this.onZoomChange                    = this.onZoomChange.bind(this);
    this.readDroppedImage                = this.readDroppedImage.bind(this);
    this.fileUpload                      = this.fileUpload.bind(this);
    this.centerSVGImages                 = this.centerSVGImages.bind(this);
    this.changeNumberOfSignatures        = this.changeNumberOfSignatures.bind(this);
    this.saveTemplate                    = this.saveTemplate.bind(this);
    this.createJSONTemplate              = this.createJSONTemplate.bind(this);
    this.downloadSVG                     = this.downloadSVG.bind(this);
    // MAXIMUM UPLOAD SIZE
    this.MAXIMUM_UPLOAD_SIZE = 5 * 1024 * 1024; //5MB
  }
  isAuthorized(user){
    return (
      user.roles.includes("manager") || user.roles.includes("facilitator") 
    )
  }
  componentDidMount(){
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
    //get users
    let template = null;
    try{
      // template = this.props.location.state.template;
      template = TCert1;
    }catch(ex){
      this.props.history.push('/profile');
    }
    let name = template.elements.main_data.text_name.text;
    if(name === "%__NAME__%"){
      name ="NOMBRE DEL PARTICIPANTE";
    }
    //update values from template
    this.setState({
      template:template,
      name:name,
      cert_name:template.elements.main_data.text_certificate.text,
      place_and_date:template.elements.main_data.text_place_and_date.text,
      description: "Por haber participado exitosamente en...",
      signature_name_1:template.elements.signatures.text_signature_1_name.text,
      signature_position_1:template.elements.signatures.text_signature_1_position.text,
      signature_name_2:template.elements.signatures.text_signature_2_name.text,
      signature_position_2:template.elements.signatures.text_signature_2_position.text,
      mainLogo:template.elements.main_data.image_main_logo.src,
      signature_img_1:template.elements.signatures.image_signature_1.src,
      signature_img_2:template.elements.signatures.image_signature_2.src,
      sponsors:template.elements.sponsors.image_sponsors.src
    });

    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        let isAuthorized = this.isAuthorized(res.user);

        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(isAuthorized){
            this.setState({
              authorized:isAuthorized,
              me:res.user,
              loading:true
            });
          }else{
            this.redirectToProfile();
          }
        }
      }else{
        this.redirectHome();
      }
    });
    
  }
  componentDidUpdate(){
    // this.fitName();
    this.centerSVGImages();
    // this.fitSignatures();
  }
  redirectToProfile(){
    this.props.history.push('/profile');
  }
  redirectHome(){
    this.props.history.push('/');
  }
  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleHeaderResize(width){
    // this.setState({
    //     sidebarVisible:width <= 500 ? false : true
    // });
  }
  // fitName(){
  //   let maxWidth   = this.state.svgWidth -  this.calculateScaleX(300);
  //   let name       = document.getElementById("certificate_name");
  //   let name_width = name.getBBox().width;
  //   let currentFontSize = parseFloat(name.getAttribute("font-size"));
  //   while(name_width > maxWidth){
  //     //reduce font
  //     currentFontSize  = currentFontSize - 1;
  //     name.setAttribute('font-size', currentFontSize);
  //     name_width = name.getBBox().width;
  //   }
  // }
  centerSVGImages(){
    let images = document.getElementsByClassName("svg_image_center");
    for (var i = 0; i < images.length; i++) {
      let image = images[i];
      let image_width = image.getBBox().width;
      // let image_height = image.getBBox().height;
      image.setAttribute('transform', `translate(-${image_width/2},0)`);
    }
  }
  fitSignatures(){
    let signatures = document.getElementsByClassName("signature");
    for (var i = 0; i < signatures.length; i++) {
      let signature = signatures[i];
      let signature_width = signature.getBBox().width;
      let signature_height = signature.getBBox().height;
      signature.setAttribute('transform', `translate(-${signature_width/2},-${signature_height})`);

    }

  }
  updateDimensions(){
    let width = 0;
    let editor_area = document.getElementById('editor_area');//.offsetWidth;
    if(editor_area !== null){
      width = editor_area.offsetWidth;
    }
    // let maxHeight = document.documentElement.clientHeight - 200;
    width = this.state.originalWidth * this.state.scale;
    // width = maxHeight * 1.2941;
    this.setState({
      svgWidth:width,
      svgHeight:width / 1.2941
    }, () =>{
      this.fitSignatures();
    })
    this.handleResizeBar();
  }
  calculateScaleX(v){
    return  v / this.state.originalWidth * this.state.svgWidth;
  }
  calculateScaleY(v){
    return  v / this.state.originalHeight * this.state.svgHeight;
  }
  renderSignatures(){
    let template = this.state.template;
    let signaturesHeight = this.calculateScaleY(template.elements.signatures.signatures_height);
    let signatureStyle = {
      strokeWidth:2
    }
    let w  = this.calculateScaleX(template.elements.signatures.signatures_width); //width of the signatures area
    let ml = this.calculateScaleX(template.elements.signatures.marginLeft); //marginLeft
    let spaceMode = template.elements.signatures.spaceMode;
    if(this.state.numberOfSignatures === 1){
      let sw = w / 3;
      let sp = 0;
      let pos_text = 0;
      if(spaceMode === "start"){
        sp = ml; //position (x) of the signature
        pos_text = ml;
      }else{
        sp = ml + sw; //position (x) of the signature
        pos_text = sp + ( sw / 2 );
      }
      return (
        <g>
          {/*line_signature_1*/}
          <line x1={sp}
                y1={signaturesHeight}
                x2={sp+sw}
                y2={signaturesHeight}
                stroke={template.elements.signatures.signatureLine.stroke}
                style={signatureStyle}
                />
          {/*text_signature_1_name */}
          <text x={pos_text}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_1_name.fontSize) }
                textAnchor={template.elements.signatures.text_signature_1_name.textAnchor}
                fontFamily={template.elements.signatures.text_signature_1_name.fontFamily}
                class="signature_fit"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_1_name.fontSize)}
                fill={template.elements.signatures.text_signature_1_name.fill}>{this.state.signature_name_1}</text>
          {/*text_signature_1_position */}
          <text x={pos_text}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_1_position.fontSize * 2)  + this.calculateScaleY(5)}
                textAnchor={template.elements.signatures.text_signature_1_position.textAnchor}
                fontFamily={template.elements.signatures.text_signature_1_position.fontFamily}
                class="signature_fit"
                id="signature_a_position"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_1_position.fontSize)}
                fill={template.elements.signatures.text_signature_1_position.fill}>{this.state.signature_position_1}</text>
          {/*image_signature_1*/}
          <image x={sp + ( sw / 2 )}
                 y={signaturesHeight }
                 height={this.calculateScaleY(template.elements.signatures.image_signature_1.h)}
                 class="signature"
                 onLoad={() => this.fitSignatures()}
                 xlinkHref={this.state.signature_img_1}/>
        </g>
      )
    }else{
      let sw = w / 4;
      let sp0 = 0;
      let sp1 = 0;
      let pos_text_1 = 0;
      let pos_text_2 = 0;
      if(spaceMode === "start"){
        sp0 = ml;
        sp1 = ml + (w * .75) - (sw / 2);
        pos_text_1 = ml;
        pos_text_2 = sp1;
      }else{
        sp0 = ml + (w / 4) - (sw / 2);
        sp1 = ml + (w * .75) - (sw / 2);
        pos_text_1 = sp0 + ( sw / 2 );
        pos_text_2 = sp1 + ( sw / 2 );
      }
      return (
        <g>
          {/*line_signature_1*/}
          <line x1={sp0}
                y1={signaturesHeight}
                x2={sp0+sw}
                y2={signaturesHeight}
                stroke={template.elements.signatures.signatureLine.stroke}
                style={signatureStyle}
                />
          {/*text_signature_1_name */}
          <text x={pos_text_1}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_1_name.fontSize) }
                textAnchor={template.elements.signatures.text_signature_1_name.textAnchor}
                fontFamily={template.elements.signatures.text_signature_1_name.fontFamily}
                class="signature_fit"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_1_name.fontSize)}
                fill={template.elements.signatures.text_signature_1_name.fill}>{this.state.signature_name_1}</text>
          {/*text_signature_1_position */}
          <text x={pos_text_1}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_1_position.fontSize * 2)  + this.calculateScaleY(5)}
                textAnchor={template.elements.signatures.text_signature_1_position.textAnchor}
                fontFamily={template.elements.signatures.text_signature_1_position.fontFamily}
                class="signature_fit"
                id="signature_a_position"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_1_position.fontSize)}
                fill={template.elements.signatures.text_signature_1_position.fill}>{this.state.signature_position_1}</text>
          {/*image_signature_1*/}
          <image x={sp0 + ( sw / 2 )}
                 y={signaturesHeight }
                 height={this.calculateScaleY(template.elements.signatures.image_signature_1.h)}
                 class="signature"
                 onLoad={() => this.fitSignatures()}
                 xlinkHref={this.state.signature_img_1}/>
           {/*line_signature_2*/}
            <line x1={sp1}
                y1={signaturesHeight}
                x2={sp1+sw}
                y2={signaturesHeight}
                stroke={template.elements.signatures.signatureLine.stroke}
                style={signatureStyle}
                />
          {/*text_signature_2_name */}
          <text x={pos_text_2}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_2_name.fontSize) }
                textAnchor={template.elements.signatures.text_signature_2_name.textAnchor}
                fontFamily={template.elements.signatures.text_signature_2_name.fontFamily}
                class="signature_fit"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_2_name.fontSize)}
                fill={template.elements.signatures.text_signature_2_name.fill}>{this.state.signature_name_2}</text>
          {/*text_signature_2_position */}
          <text x={pos_text_2}
                y={signaturesHeight + this.calculateScaleY(template.elements.signatures.text_signature_2_position.fontSize * 2) + this.calculateScaleY(5) }
                textAnchor={template.elements.signatures.text_signature_2_position.textAnchor}
                fontFamily={template.elements.signatures.text_signature_2_position.fontFamily}
                class="signature_fit"
                id="signature_a_position"
                fontSize={this.calculateScaleY(template.elements.signatures.text_signature_2_position.fontSize)}
                fill={template.elements.signatures.text_signature_2_position.fill}>{this.state.signature_position_2}</text>
          {/*image_signature_2*/}
          <image x={sp1 + ( sw / 2 )}
                 y={signaturesHeight }
                 height={this.calculateScaleY(template.elements.signatures.image_signature_2.h)}
                 class="signature"
                 onLoad={() => this.fitSignatures()}
                 xlinkHref={this.state.signature_img_2}/>
        </g>
      );
    }

  }
  calculateUnit(value,origininal){
    if(value.toString().includes("%")){
      let percentage = parseFloat(value.replace("%","")) / 100
      return origininal * percentage;
    }else{
      return value;
    }
  }
  calculateValueX(value){
    let unitValue = this.calculateUnit(value,this.state.originalWidth);

    return this.calculateScaleX(unitValue);
  }
  renderSVG(){
    let template = this.state.template;
    //originalSize: 1650x1275
    let rectangleStyle = {
      strokeWidth:this.calculateScaleX(template.elements.frames.frame_main.style.strokeWidth),
      stroke:template.elements.frames.frame_main.style.stroke
    }
    //90,72
    return (
      <svg
          x="0"
          y="0"
          id="certificate_svg"
          width={this.state.svgWidth}
          height={this.state.svgHeight}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="white">
        <defs>
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@700&display=swap');
          </style>
        </defs>
        {/*frame_outer*/}
        <rect width="100%" height="100%" />
        {/*frame_main*/}
        <rect x={this.calculateScaleX(template.elements.frames.frame_main.x)}
              y={this.calculateScaleY(template.elements.frames.frame_main.y)}
              width={this.state.svgWidth - this.calculateScaleX(template.elements.frames.frame_main.w)}
              height={this.state.svgHeight - this.calculateScaleY(template.elements.frames.frame_main.h)}
              style={rectangleStyle}
        />
        <image x={0}
               y={0}
               height={"100%"}
               width={"100%"}
               xlinkHref={template.elements.frames.background.src}/>
        {/*text_issues*/}
        <text x={this.calculateValueX(template.elements.main_data.text_issues.x)}
              y={this.calculateScaleY(template.elements.main_data.text_issues.y)}
              textAnchor={template.elements.main_data.text_issues.textAnchor}
              fontFamily={template.elements.main_data.text_issues.fontFamily}
              fontSize={this.calculateScaleY(template.elements.main_data.text_issues.fontSize)}
              fill={template.elements.main_data.text_issues.fill}>otorga el presente</text>
        {/*text_certificate*/}
        <text x={this.calculateValueX(template.elements.main_data.text_certificate.x)}
              y={this.calculateScaleY(template.elements.main_data.text_certificate.y)}
              textAnchor={template.elements.main_data.text_certificate.textAnchor}
              fontFamily={template.elements.main_data.text_certificate.fontFamily}
              fontSize={this.calculateScaleY(template.elements.main_data.text_certificate.fontSize)}
              fill={template.elements.main_data.text_certificate.fill}>{this.state.cert_name}</text>
        {/*text_name*/}
        <text x={this.calculateValueX(template.elements.main_data.text_name.x)}
              y={this.calculateScaleY(template.elements.main_data.text_name.y)}
              textAnchor={template.elements.main_data.text_name.textAnchor}
              fontFamily={template.elements.main_data.text_name.fontFamily}
              id="certificate_name"
              fontSize={this.calculateScaleY(template.elements.main_data.text_name.fontSize)}
              fill={template.elements.main_data.text_name.fill}>{this.state.name}</text>
        {/*name_underline*/}
        {this.renderDescription(template)}
        {/*text_place_and_date*/}
        <text x={this.calculateValueX(template.elements.main_data.text_place_and_date.x)}
              y={this.calculateScaleY(template.elements.main_data.text_place_and_date.y)}
              textAnchor={template.elements.main_data.text_place_and_date.textAnchor}
              fontFamily={template.elements.main_data.text_place_and_date.fontFamily}
              id="place_and_date"
              fontSize={this.calculateScaleY(template.elements.main_data.text_place_and_date.fontSize)}
              fill={template.elements.main_data.text_place_and_date.fill}>{this.state.place_and_date}</text>
        {/*image_mainLogo*/}
        <image x={this.calculateValueX(template.elements.main_data.image_main_logo.x)}
               y={this.calculateScaleY(template.elements.main_data.image_main_logo.y)}
               height={this.calculateScaleY(template.elements.main_data.image_main_logo.height)}
               id="main_logo"
               class={template.elements.main_data.image_main_logo.class}
               onLoad={() => this.centerSVGImages()}
               xlinkHref={this.state.mainLogo}
         />
         {/*SIGNATURES SECTION*/}
         {this.renderSignatures()}
         {/*image_sponsors*/}
         <image x={this.calculateValueX(template.elements.sponsors.image_sponsors.x)}
                y={this.calculateScaleY(template.elements.sponsors.image_sponsors.y)}
                height={this.calculateScaleY(92)}
                id="main_logo"
                class={template.elements.sponsors.image_sponsors.class}
                onLoad={() => this.centerSVGImages()}
                xlinkHref={this.state.sponsors}/>
      </svg>
    )
  }
  renderDescription(template){
    // let maxWordsPerLine = 12;
    let descriptionLines = this.state.description.split("\n").slice(0,3);

    return(
        descriptionLines.map((line,i) =>
          /*text_description */
          <text x={this.calculateValueX(template.elements.main_data.text_description.x)}
                y={this.calculateScaleY(template.elements.main_data.text_description.y + i * this.calculateScaleY(42))}
                textAnchor={template.elements.main_data.text_description.textAnchor}
                fontFamily="Zilla Slab"
                id="certificate_name"
                fontSize={this.calculateScaleY(21)}
                fill={template.elements.main_data.text_description.fill}>{line}</text>
        )
      )
  }
  onZoomChange(e){
    this.setState({
      scale:e.target.value
    }, () =>{
      this.updateDimensions();
    })
  }
  createJSONTemplate(){
    let template = JSON.parse(JSON.stringify(this.state.template)); //deep copy the template
    //then assign the values
    template.elements.main_data.text_certificate.text                  = this.state.cert_name;
    template.elements.main_data.text_place_and_date.text        = this.state.place_and_date;
    template.elements.main_data.text_description.text           = this.state.description;
    template.elements.signatures.text_signature_1_name.text     = this.state.signature_name_1;
    template.elements.signatures.text_signature_1_position.text = this.state.signature_position_1;
    template.elements.signatures.text_signature_2_name.text     = this.state.signature_name_2;
    template.elements.signatures.text_signature_2_position.text = this.state.signature_position_2;
    template.elements.main_data.image_main_logo.src             = this.state.mainLogo;
    template.elements.signatures.image_signature_1.src          = this.state.signature_img_1;
    template.elements.signatures.image_signature_2.src          = this.state.signature_img_2;
    template.elements.sponsors.image_sponsors.src               = this.state.sponsors;
    template.elements.main_data.text_name.text                  = "%__NAME__%";

    return template;
  }
  saveTemplate(){
    this.setState({
      scale:1
    }, () =>{
      this.updateDimensions();
    });
    (async () => {
      const { value: cert_name } = await Swal.fire({
          title: 'Guardar certificado',
          type: 'info',
          input:'text',
          inputPlaceholder:'Ingresa un nombre para guardar tu certificado',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.trim() === '') {
                resolve('El nombre del certificado no puede estar vacío');
              }else if(value.trim().length <= 3){
                resolve('El nombre del certificado debe de tener al menos tres caracteres');
              } else {
                resolve();
              }
            })
          },
          showCancelButton: true,
          confirmButtonText: 'Guardar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          customClass: {
            content: 'swal-content-class',
            confirmButton: 'swalBtnTeal',
            cancelButton: 'swalBtnRedOrange',
            title: 'swal-custom-title',
          },
      })
      if (cert_name) {
        //------show loader--------------
        this.setState({showLoader:true});
        //-------------------------------
        let svg = document.getElementById("certificate_svg").outerHTML;
        svg = svg.replace("NOMBRE DEL PARTICIPANTE","%__NAME__%");
        // let t = this.createJSONTemplate();
        let template = JSON.stringify(this.createJSONTemplate());
        KomunidadApi.createTemplate(this.state.programId,cert_name,template,svg).then((res) => {
          //-----remove loader--------------
          this.setState({showLoader:false});
          //-------------------------------

         if(res.success){
            Swal.fire({
              type: 'success',
              title: 'Plantilla guardada',
              html: '1. Dirígete a la sección de Programas<br>2. Selecciona el programa al cual quieres emitir o enviar el certificado<br>3. Selecciona el ícono de certificado para ver las opciones<br>4. Puedes descargarlos o enviarlos por correo a los participantes del programa<br>',
              customClass: {
                confirmButton: 'swalBtnTeal',
                title: 'swal-custom-title',
              },
            }).then((result) => {
              this.props.history.goBack();
            })
         }else{
           Swal.fire({
             type: 'error',
             title: res.error_es,
             customClass: {
               confirmButton: 'swalBtnTeal',
               title: 'swal-custom-title',
             },
           });
         }
        });
      }
    })()


  }
  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.setState({[data.model]:value});
    if(data.model === "signature_name_1"){
      // this.fitSignatures();
    }
  }
  readDroppedImage(images,model){
    let _this = this;
    var reader = new FileReader();
    reader.readAsDataURL(images[0]);
    reader.onloadend = function() {
      // console.log(reader);

       var base64data = reader.result;
      //  console.log(base64data);
       _this.setState({
         [model]:base64data
       })
    }

  }
  fileUpload(file,model) {
    if(file.target.files[0].size > this.MAXIMUM_UPLOAD_SIZE){
			Swal.fire({
				title: 'Archivo demasiado grande',
				text: "El archivo debe de ser menor a 5MB.",
				type: 'error',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
			});
		}else{
      let _this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onloadend = function() {
        // console.log(reader);

        var base64data = reader.result;
        // console.log(base64data);
        _this.setState({
          [model]:base64data
        })
      }
    }
  }

  changeNumberOfSignatures(n){
    this.setState({
      numberOfSignatures:n
    })
  }
  downloadSVG(){
    let svg = document.getElementById("certificate_svg").outerHTML;
    fileDownload(svg, 'certificado.svg');
  }
  onProgramSelected(programs){
    if(programs){
      this.setState({programId:programs._id});
    }
  }
  renderSecondSignature(){
    if(this.state.numberOfSignatures !== 2){
      return (null);
    }
    return (
      <div className='m-t-20'>
        <FormInput
            ref={ref => this.fi_signature_name_2 = ref}
            id="Nombre de quien firma #2"
            label="Nombre de quien firma #2"
            model="signature_name_2"
            showCounter={true}
            maxLength={60}
            value={this.state.signature_name_2}
            initialValue={''}
            onChange={ change => { this.onChangeForm(change) } }
            placeholder="Nombre de quien firma #2"
            errorPosition="bottom"
          />
          <FormInput
              ref={ref => this.fi_signature_position_2 = ref}
              id="Puesto de quien firma #2"
              label="Puesto de quien firma #2"
              model="signature_position_2"
              showCounter={true}
              maxLength={60}
              value={this.state.signature_position_2}
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Puesto de quien firma #2"
              errorPosition="bottom"
            />
            <div className='form-group m-t-10'>
              <label>Firma #2</label>
              <input type="file" onChange={file => this.fileUpload(file,'signature_img_2')} accept="image/jpeg, image/png"/>
              {/* <Dropzone onDrop={acceptedFiles => this.readDroppedImage(acceptedFiles,'signature_img_2')}>
                {({getRootProps, getInputProps}) => (
                  <section className='dropzone'>
                    <div {...getRootProps()} style={{backgroundImage: `url(${this.state.signature_img_2})` }}>
                      <input {...getInputProps()} />
                      <p className={this.state.signature_img_2 !== null ? 'dropzone-gradient' : 'text-info'}>Arrastra la imagen o da click aquí</p>
                    </div>
                  </section>
                )}
              </Dropzone> */}
            </div>
        </div>
    )
  }

  renderLoader(){
    if(this.state.showLoader){
      return(
        <div className='wrapper-loader'>
          <BounceLoader
                  sizeUnit={"px"}
                  size={180}
                  color={'#3cc'}
                  loading={true}
                />
          <h1>Guardando</h1>
          <h5>Este proceso puede tardar unos minutos.</h5>
        </div>
      )
    }else{
      return null
    }
  }

  renderSavedCertificates(){
    return (
      <div className='card-body'>
        <h5>Instrucciones para emitir certificado con plantillas creadas</h5>

        {/* <div className='row m-t-20'>
          {
            this.state.savedCertificates.map( cert => {
                return(
                  <div className='col-lg-3 col-md-6 m-b-20'>
                    <img
                      className='img-responsive img-template'
                      src={cert.thumbnail}
                      alt='template'
                      onClick={() => this.openSavedTemplate(cert)}
                      />
                      <div>{cert.name}</div>
                  </div>
                )
            })
          }
        </div> */}

        <div className='m-t-20'>
          1. Dirígete a la sección de Programas<br/>
          2. Selecciona el programa al cual quieres emitir o enviar el certificado<br/>
          3. Selecciona el ícono de certificado para ver las opciones<br/>
          4. Puedes descargarlos o enviarlos por correo a los participantes del programa<br/>
        </div>

      </div>
    )
  }

  render(){
    return (
      <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
            {/* Container fluid  */}
            <div className="container-fluid">
              {/* Bread crumb*/}
              <div className="row page-titles">
                  <div className="col-md-5 d-flex align-self-center align-items-center">
                      <i className="mdi mdi-arrow-left" onClick={() => this.props.history.goBack()} style={{cursor:'pointer', fontSize: '20px'}}></i>
                      <h3 className="text-themecolor m-l-10">Crea tu certificado</h3>
                  </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-12 col-xlg-12">
                  <div className='card'>
                    {this.renderSavedCertificates()}
                  </div>
                </div>
              </div>
              {/* Bread crumb*/}
              <div className="row">
                <div className="col-md-8">
                  <div className="card" id="editor_area">
                    <div>
                      <div id='range'>
                        <label>+</label>
                        <input  type="range" min={0.4} max={1}
                                      onChange={ (e) => {this.onZoomChange(e)}}
                                      step={0.1}
                                      value={this.state.scale}
                                      />
                        <label>-</label>
                        </div>
                      {this.renderSVG()}
                    </div>
                  </div>
                </div>

              {/* <div className='right-side-panel'> */}
              <div className='col-md-4 '>
                <div id="editor_controls" className='row'>
                  <div className="col-md-12">
                    <ProgramsPicker selectedProgram={this.state.programId}
                                    onChange={(program) => {this.onProgramSelected(program)}}>
                    </ProgramsPicker>
                    <hr/>
                  </div>

                  <div className="col-md-12">
                    <div id="opentext">
                      <FormInput
                        ref={ref => this.fi_cert_name = ref}
                        id="Tipo de certificado"
                        label="Tipo de certificado"
                        model="cert_name"
                        showCounter={true}
                        maxLength={18}
                        value={this.state.cert_name}
                        initialValue={'DIPLOMA'}
                        onChange={ change => { this.onChangeForm(change) } }
                        placeholder="Certificado, Diploma, Reconocimiento"
                        errorPosition="bottom"
                      />
                      <FormTextArea
                          ref={ref => this.fi_description = ref}
                          id="Descripción"
                          label="Descripción"
                          model="description"
                          showCounter={false}
                          value={this.state.description}
                          initialValue={''}
                          validationRules="required"
                          onChange={ change => { this.onChangeForm(change) } }
                          placeholder="Descripción de tu diploma"
                          errorPosition="bottom"
                        />
                        <FormTextArea
                            ref={ref => this.fi_place_and_date = ref}
                            id="Lugar y Fecha"
                            label="Lugar y Fecha"
                            model="place_and_date"
                            showCounter={false}
                            value={this.state.place_and_date}
                            initialValue={''}
                            validationRules="required"
                            onChange={ change => { this.onChangeForm(change) } }
                            placeholder="Lugar y Fecha"
                            errorPosition="bottom"
                          />
                    </div>
                    <hr/>
                  </div>

                  <div className="col-md-12 mb-0">
                    <div id="openImages" className="row">
                      <div className='col-md-6 form-group m-t-5'>
                        <label>Imagen de programa</label>
                        <input type="file" style={{maxWidth: '172px'}} onChange={file => this.fileUpload(file,'mainLogo')} accept="image/jpeg, image/png"/>
                        {/* <Dropzone onDrop={acceptedFiles => this.readDroppedImage(acceptedFiles,'mainLogo')}>
                          {({getRootProps, getInputProps}) => (
                            <section className='dropzone'>
                              <div {...getRootProps()} style={{backgroundImage: `url(${this.state.mainLogo})` }}>
                                <input {...getInputProps()} />
                                <p className={this.state.mainLogo !== null ? 'dropzone-gradient' : 'text-info mt-3'}>Arrastra la imagen o da click aquí</p>
                              </div>
                            </section>
                          )}
                        </Dropzone> */}
                        {/* <small>*Verifica que las imágenes no tengan márgenes</small> */}
                      </div>

                      <div className='col-md-6 form-group m-t-5'>
                        <label>Patrocinadores</label>
                        <input type="file" style={{maxWidth: '172px'}} onChange={file => this.fileUpload(file,'sponsors')} accept="image/jpeg, image/png"/>
                        {/* <Dropzone onDrop={acceptedFiles => this.readDroppedImage(acceptedFiles,'sponsors')}>
                          {({getRootProps, getInputProps}) => (
                            <section className='dropzone'>
                              <div {...getRootProps()} style={{backgroundImage: `url(${this.state.sponsors})` }}>
                                <input {...getInputProps()} />
                                <p className={this.state.sponsors !== null ? 'dropzone-gradient' : 'text-info mt-3'}>Arrastra la imagen o da click aquí</p>
                              </div>
                            </section>
                          )}
                        </Dropzone> */}
                      </div>
                      <small style={{paddingLeft: "15px"}}>*Verifica que las imágenes no tengan márgenes</small>

                    </div>
                    <hr/>
                  </div>

                  <div className="col-md-12  m-t-5">
                      <div id="openSignature">
                          <Dropdown className='m-b-10'>
                            <Dropdown.Toggle variant='info' id="dropdown-basic" data-toggle="dropdown">
                              Número de firmas {this.state.numberOfSignatures}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => this.changeNumberOfSignatures(1)}>1</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item onClick={() => this.changeNumberOfSignatures(2)}>2</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <FormInput
                            ref={ref => this.fi_signature_name_1 = ref}
                            id="Nombre de quien firma #1"
                            label="Nombre de quien firma #1"
                            model="signature_name_1"
                            showCounter={true}
                            maxLength={60}
                            value={this.state.signature_name_1}
                            initialValue={'Darien Alberto Miranda Bojorquez'}
                            onChange={ change => { this.onChangeForm(change) } }
                            placeholder="Nombre de quien firma #1"
                            errorPosition="bottom"
                          />
                          <FormInput
                              ref={ref => this.fi_signature_position_1 = ref}
                              id="Puesto de quien firma #1"
                              label="Puesto de quien firma #1"
                              model="signature_position_1"
                              showCounter={true}
                              maxLength={60}
                              value={this.state.signature_position_1}
                              initialValue={'Darien Alberto Miranda Bojorquez'}
                              onChange={ change => { this.onChangeForm(change) } }
                              placeholder="Puesto de quien firma #1"
                              errorPosition="bottom"
                            />
                            <div className='form-group m-t-10'>
                              <label>Firma #1</label>
                              <input type="file" onChange={file => this.fileUpload(file,'signature_img_1')} accept="image/jpeg, image/png"/>
                              {/* <Dropzone onDrop={acceptedFiles => this.readDroppedImage(acceptedFiles,'signature_img_1')}>
                                {({getRootProps, getInputProps}) => (
                                  <section className='dropzone'>
                                    <div {...getRootProps()} style={{backgroundImage: `url(${this.state.signature_img_1})` }}>
                                      <input {...getInputProps()} />
                                      <p className={this.state.signature_img_1 !== null ? 'dropzone-gradient' : 'text-info mt-4'}>Arrastra la imagen o da click aquí</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone> */}
                            </div>

                            {this.renderSecondSignature()}
                      </div>
                  </div>

                  <div className="col-md-12  m-t-20">
                    <Button className="btn-save float-right" onClick={() => this.saveTemplate()}>Guardar</Button>
                  </div>

                </div>

              </div>
            </div>
            
            </div>
            {/* End container fluid  */}
            <Footer/>
          </div>
          {/* End page wrapper  */}
          {this.renderLoader()}

        </div>
      </div>
    )

  }
}

export default CertificateEditor;
