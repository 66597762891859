/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, June 2019
 */

import React from 'react';
import currency from '../../utils/CurrencyFormatter.js';

/*
 * Transforms the given value to money format
 *
 * @param String value
 * @param String currencyVal
 *
 * @returns Number
 */
export function transformToMoneyFormat (x) {
  let originalValue = x ;
  let y = "";
  if(x === ""){
    return "";
  }
  x = x.toString().replace(/,/g,"");
  let splitted_val = x.split(".");
  x = splitted_val[0];
  if(splitted_val.length > 0){
    y = splitted_val[1];
    if(y !== undefined){
      if(y.toString().length > 2){
        y = y.substring(0,4);
      }
    }
  }
  if(x.length > 3){
    x = parseFloat(x);
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if(originalValue.toString().includes(".")){
    return `$${x}.${y} MXN`
  }else{
    return `$${x}.00 MXN`;
  }
}

/*
 * Transforms the given String to Uppercase
 *
 * @param String value
 *
 * @returns String
 */
export function transformToUpperCase (value) {
  let upper = ( value === null ) ? value = '' : value;
  return upper.toString().toUpperCase();
}

/*
 * Transforms the given value to a trimmed string
 *
 * @param String value
 *
 * @returns String
 */
export function transformToTrimmed (value) {
  let trimCons = ( value === null ) ? value = '' : value;
  return trimCons.trim();
}

/*
 * Transforms the given value to a formatted phone number
 *
 * @param String value
 *
 * @returns String
 */
export function transformToPhone (value) {
  const phone  = ( value === null ) ? value = '' : value;
  let area_code = null;
  let phone_number_a = null;
  let phone_number_b = null;
  if(phone.length === 10){
    area_code = phone.substring(0, 3);
    phone_number_a = phone.substring(3,6);
    phone_number_b = phone.substring(6);
  }else{
    area_code = "---";
    phone_number_a = "---";
    phone_number_b = "----";
  }


  let phoneData = (value === '') ? value : `(${area_code}) ${phone_number_a} ${phone_number_b}`;
  return phoneData
}

/*
 * Transforms the given value to a html anchor tag
 *
 * @param String value
 *
 * @returns String
 */
export function transformToAnchor (value) {
  let url = ( value === null ) ? value = '' : value;
  if(url === ""){
    return "";
  }
  let has_protocol = url.includes('https://') || url.includes('http://');

  if(!has_protocol){
    let has_www = url.includes('www.');
    if(!has_www){
      url = `http://www.${url}`;
    }else{
      url = `http://${url}`;
    }
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>{url}</a>
  )
}

/*
 * Transforms the given value to a html label tag
 *
 * @param String value
 *
 * @returns String
 */
export function transformToLabel(value) {
  const text = ( value === null ) ? value = '' : value;

  return (
    <div className="selected-items">{ text }</div>
  )
}
function stripeExtraDots( str ) {
    return str.toString().replace( /^([^.]*\.)(.*)$/, function ( a, b, c ) {
        return b + c.replace( /\./g, '' );
    });
}
export function transformToFloat(value){
  value     = value.replace(/[^0-9.]/g,''); //remove all non digits first
  value     = stripeExtraDots(value);
  return value;
}
export function transformToMoney(value){
  value              = (value === null) ? "" : value;
  let cleanValue     = stripeExtraDots(value);
  cleanValue         = cleanValue.replace(/[^0-9.]/g,'');
  value              = currency.numberWithCommas4Decimals(cleanValue,"MXN");

  let response = {
    "cleanValue":cleanValue,
    "formattedValue":value
  }
  return response;
}
export function editTransformToPhoneNumber(value){
  let phone = ( value === null ) ? value = '' : value;
  phone     = phone.replace(/[^0-9]/g,''); //remove all non digits first
  let area_code = null;
  let phone_number_a = null;
  let phone_number_b = null;
  area_code = phone.substring(0, 3);
  phone_number_a = phone.substring(3,6);
  phone_number_b = phone.substring(6);

  let formattedValue = ``;
  if(phone.length < 1){
    formattedValue = ``;
  }else if (phone.length < 4){
    formattedValue = `(${area_code}`;
  }else if(phone.length < 7){
    formattedValue = `(${area_code}) ${phone_number_a}`;
  }else{
    formattedValue = `(${area_code}) ${phone_number_a} ${phone_number_b}`;
  }
  let cleanValue     = formattedValue.replace(/[^0-9]/g,'');
  let response = {
    "cleanValue" : cleanValue,
    "formattedValue" : formattedValue
  }
  return response;
}
export function transformToInt(value){
  value     = value.replace(/[^0-9]/g,''); //remove all non digits first
  value     = stripeExtraDots(value);
  return value;
}
export function transformAddHttps(...[link]){
  let reW = /^[a-z]{2,3}\.linkedin\.com\/.*$/;
  let nLink = link;
  if(reW.test(link)){
    nLink = "https://" + link;
  }
  let reH = /^http:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  if(reH.test(link)){
    nLink = link.replace("http:","https:");
  }
  return nLink;
}
  export function editDateMask(value){
    let date = ( value === null ) ? value = '' : value;
    let formattedValue = '';
    date     = date.replace(/[^0-9-]/g,''); //remove all non digits first

    if(date.length === 0){
      formattedValue = '';
    }else if(date.length === 1){
      if(date === "-"){
        formattedValue = '';
      }else{
        formattedValue = date;
      }
    }else if(date.length === 2){
      if(date[1] === "-"){
        date     = date.replace(/[^0-9]/g,'');
        formattedValue = `0${date}-`;
      }else{
        formattedValue = `${date}`;
      }
    }else if(date.length === 3){
      if(date[2] === "-"){
        date     = date.replace(/[^0-9]/g,'');
        formattedValue = `${date}-`;
      }else{
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-${month}`;
      }
    }else if(date.length === 4){
      if(date[3] === "-"){
        date     = date.replace(/[^0-9]/g,'');
        formattedValue = `${date}-`;
      }else{
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-${month}`;
      }
    }else if(date.length === 5){
      if(date[4] === "-"){
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-0${month}-`;
      }else{
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-${month}`;
      }
    }else if(date.length === 6){
      if(date[5] === "-"){
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-${month}-`;
      }else{
        date      = date.replace(/[^0-9]/g,'');
        let day   = date.substring(0,2);
        let month = date.substring(2);
        formattedValue = `${day}-${month}-`;
      }
    }else if(date.length >= 7){
      date      = date.replace(/[^0-9]/g,'');
      let day   = date.substring(0,2);
      let month = date.substring(2,4);
      let year  = date.substring(4,8);
      if(parseInt(day) > 31){
        day     = 31;
      }
      if(parseInt(month) > 12){
        month   = 12;
      }
      formattedValue = `${day}-${month}-${year}`;
    }


    let cleanValue = date;
    let response = {
      "cleanValue" : cleanValue,
      "formattedValue" : formattedValue
    }
    return response;
}
