/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>, September 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Components
import KSpeakerRow from './KSpeakerRow';

//Styles
import '../scss/pages/user-card.scss';

/**
*   Render a list of speakers using the row [KSpeakersRow](/#kspeakerssrow).
*/

class KSpeakersList extends React.Component {

  renderFailed(){
    return(
      <div className="text-center">
        <h3>Hubo un problema al cargar los speakers</h3>
        <FontAwesomeIcon className="m-t-20 m-b-20" color="#c2c2c2" icon="exclamation-triangle" size='4x'/>
        <h5>Inténtalo nuevamente</h5>
      </div>
    )
  }

  renderRow(speakers){
    return(
        speakers.map((item,index) =>
          (
            <KSpeakerRow
              key={index}
              speaker={item}
            />
          )
        )
    )
  }

  render() {
    const speakers = this.props.speakers;
                 
    if(speakers.length > 0){
      return(
        // tableBorderB
        <div className="row el-element-overlay">
          {this.renderRow(speakers)}
        </div>
      )
    }else{
      // empty list
      return this.renderFailed()
    }
  }
}

KSpeakersList.propTypes = {
  /** Speakers list. */
  speakers              : PropTypes.array,

  /** Status of the loading data: idle, fetching, success and failed. */
  requestStatus        : PropTypes.string,

  /** True when the user is owner. */
  isUserOwner          : PropTypes.bool,

  /** When is true show the component [KMentorsCompleteRow](/#kmentorscompleterow).
      by default the row is [KMentorsRow](/#kmentorsrow).*/
  showCompleteRow      : PropTypes.bool,

  /** When is true the style list is striped. */
  stripedStyle         : PropTypes.bool,

  /** When is true the list is borderless. */
  borderlessStyle      : PropTypes.bool,
};

KSpeakersList.defaultProps = {
  speakers              : [],
  requestStatus        : 'idle',
  isUserOwner          : false,
  showCompleteRow      : false,
  stripedStyle         : false,
  borderlessStyle      : false,
};

export default KSpeakersList;
