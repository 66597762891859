// import Logo from "../assets/images/app_logox.png";
// import Logo from "../assets/images/app_logo.png";

export function getAppLogo(){
  try{
    return require("../vendor/images/login-logo.png");
  }catch(err){
    return require("../assets/images/login-logo.png");
  }
}
export function getHelpLogo(){
  try{
    return require("../vendor/images/creo_registro_baner.png");
  }catch(err){
    return require("../assets/images/creo_registro_baner.png");
  }
}
export function getSIVILogo(){
  try{
    return require("../vendor/images/sivi_logo.png");
  }catch(err){
    return require("../assets/images/sivi_logo.png");
  }
}
export function getCommingSoon(){
  try{
    return require("../vendor/images/comming_soon.png");
  }catch(err){
    return require("../assets/images/comming_soon.jpg");
  }
}
export function getCommingSoonTv(){
  try{
    return require("../vendor/images/comming_soon_tv.gif");
  }catch(err){
    return require("../assets/images/comming_soon_tv.gif");
  }
}
export function getAsem(){
  try{
    return require("../vendor/images/logo_asem.png");
  }catch(err){
    return require("../assets/images/logo_asem.png");
  }
}
export function getPosible(){
  try{
    return require("../vendor/images/logo_posible.png");
  }catch(err){
    return require("../assets/images/logo_posible.png");
  }
}
export function getConcamin(){
  try{
    return require("../vendor/images/logo_concamin.png");
  }catch(err){
    return require("../assets/images/logo_concamin.png");
  }
}
export function getEshow(){
  try{
    return require("../vendor/images/logo_eshowmx.png");
  }catch(err){
    return require("../assets/images/logo_eshowmx.png");
  }
}

export function getBrive(){
  try{
    return require("../vendor/images/logo_brive.png");
  }catch(err){
    return require("../assets/images/logo_brive.png");
  }
}

export function getMexicoPuede(){
  try{
    return require("../vendor/images/logo_mexico_puede.png");
  }catch(err){
    return require("../assets/images/logo_mexico_puede.png");
  }
}

export function getGentera(){
  try{
    return require("../vendor/images/logo_gentera.png");
  }catch(err){
    return require("../assets/images/logo_gentera.png");
  }
}

export function getApolo(){
  try{
    return require("../vendor/images/logo_apolo.png");
  }catch(err){
    return require("../assets/images/logo_apolo.png");
  }
}

export function getStartuplab(){
  try{
    return require("../vendor/images/logo_startuplab.png");
  }catch(err){
    return require("../assets/images/logo_startuplab.png");
  }
}

export function getCoppel(){
  try{
    return require("../vendor/images/logo_coppel.png");
  }catch(err){
    return require("../assets/images/logo_coppel.png");
  }
}

export function getSolve(){
  try{
    return require("../vendor/images/logo_solve.png");
  }catch(err){
    return require("../assets/images/logo_solve.png");
  }
}

export function getCCMX(){
  try{
    return require("../vendor/images/logo_ccmx.png");
  }catch(err){
    return require("../assets/images/logo_ccmx.png");
  }
}

export function getCMN(){
  try{
    return require("../vendor/images/logo_cmn.png");
  }catch(err){
    return require("../assets/images/logo_cmn.png");
  }
}
export function getEDOMEX(){
  try{
    return require("../vendor/images/logo_edomex.png");
  }catch(err){
    return require("../assets/images/logo_edomx.png");
  }
}
export function getPartnersLogos(){
  try{
    return require("../vendor/images/logo_partners.PNG");
  }catch(err){
    return require("../assets/images/logo_partners.PNG");
  }
}

export function getConsejo(){
  try{
    return require("../vendor/images/logo_consejo.png");
  }catch(err){
    return require("../assets/images/logo_consejo.png");
  }
}

export function getWadhwani(){
  try{
    return require("../vendor/images/logo_wadhwani.png");
  }catch(err){
    return require("../assets/images/logo_wadhwani.png");
  }
}

export function getUs(){
  try{
    return require("../vendor/images/logo_usmf.png");
  }catch(err){
    return require("../assets/images/logo_usmf.png");
  }
}

export function getADO(){
  try{
    return require("../vendor/images/logo_ado.png");
  }catch(err){
    return require("../assets/images/logo_ado.png");
  }
}

export function getCofece(){
  try{
    return require("../vendor/images/logo_cofece.png");
  }catch(err){
    return require("../assets/images/logo_cofece.png");
  }
}
// CREO MX SLP
export function getCommingSoonSLP(){
  try{
    return require("../vendor/images/comming_soon_slp.png");
  }catch(err){
    return require("../assets/images/comming_soon_slp.jpg");
  }
}
export function getSedeco(){
  try{
    return require("../vendor/images/logo_sedeco.png");
  }catch(err){
    return require("../assets/images/logo_sedeco.png");
  }
}
export function getAyuntamiento(){
  try{
    return require("../vendor/images/logo_ayuntamiento.png");
  }catch(err){
    return require("../assets/images/logo_ayuntamiento.png");
  }
}
export function getCanacintra(){
  try{
    return require("../vendor/images/logo_canacintra.png");
  }catch(err){
    return require("../assets/images/logo_canacintra.png");
  }
}
export function getUASLP(){
  try{
    return require("../vendor/images/uaslp.png");
  }catch(err){
    return require("../assets/images/uaslp.png");
  }
}
