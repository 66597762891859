/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darién Miranda & América Mendoza <darien@startuplab.mx & america@startuplab.mx>, July 2019
 */

// Regular imports
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom'
import {Tab, Nav, FormCheck} from 'react-bootstrap';
import KomunidadApi from '../../api/api.js';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';

import 'moment/locale/es';
import Swal from 'sweetalert2';
import steps from './tour/ProfileSteps.js';
import Tour from 'reactour';
import Select from 'react-select';


// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker.js';
import KEditableInput from '../../components/KEditableInput';
import KCompanyList from '../../components/KCompanyList';
import KImage from '../../components/KImage';
import FormInput from '../../components/form/FormInput';
import FormTextArea from '../../components/form/FormTextArea';
import {components} from 'react-select';
import {
         editDateMask,
         editTransformToPhoneNumber,
         transformToPhone,
         transformToUpperCase,
         transformAddHttps,
         transformToAnchor }
from '../../components/form/transforms.js'
import { isUserProfileComplete } from '../../utils/Utils.js';
import ProgramsPicker from "../../components/ProgramsPicker";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

const options = [
  { value:'Branding', label:'Branding'},
  { value:'Comunicación', label:'Comunicación'},
  { value:'Contabilidad', label:'Contabilidad'},
  { value:'Desarrollo de Software', label:'Desarrollo de Software'},
  { value:'Descubrimiento de Clientes', label:'Descubrimiento de Clientes'},
  { value:'Diseño Gráfico', label:'Diseño Gráfico'},
  { value:'Distribución', label:'Distribución'},
  { value:'Financiamiento', label:'Financiamiento'},
  { value:'Finanzas', label:'Finanzas'},
  { value:'Innovación', label:'Innovación'},
  { value:'Legal', label:'Legal'},
  { value:'Marketing Digital', label:'Marketing Digital'},
  { value:'Modelo de Negocio', label:'Modelo de Negocio'},
  { value:'Pitch', label:'Pitch'},
  { value:'Productividad', label:'Productividad'},
  { value:'Recursos Humanos', label:'Recursos Humanos'},
  { value:'Sustentabilidad', label:'Sustentabilidad'},
  { value:'UX / UI', label:'UX / UI'},
  { value:'Validación de Mercado', label:'Validación de Mercado'},
  { value:'Valuación', label:'Valuación'},
  { value:'Ventas', label:'Ventas'},
];
class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode:false,
      me:{
        _id:null,
        name:"",
        email:"",
        created_at:null,
        updatedAt:null,
        source:null,
        uid:null,
        profileUrlThumbnail:null,
        goverment_id_curp:null,
        social_linkedin:null,
        social_facebook:null,
        social_twitter:null,
        social_other:null,
        goverment_id_rfc:null,
        dateOfBirth:null,
        phone:null,
        gender:null,
      },
      formData: {
        name:"",
        last_name_father:"",
        last_name_mother:"",
        email:"",
        dateOfBirth:null,
        gender:null,
        goverment_id_curp:null,
        phone:null,
        social_linkedin:null,
        bdaypicker:'',
        pdf_file:'',
        calendly_username: '',
        id_scan: '',
        mentorPromotedProgramName1:'',
        mentorPromotedProgramDescription1:'',
        mentorPromotedProgramURL1:'',
        mentorPromotedProgramName2:'',
        mentorPromotedProgramDescription2:'',
        mentorPromotedProgramURL2:''
      },
      dateOfBirthInitialValue:null,
      companies:[],
      companiesLoadState:'idle',
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      isUploadingProfileImage:false,
      formIsHalfFilledOut:false,
      confirmedNavigation: false,
      isCalendarOpen:false,
      specialitiesChanged:false,
      isTourOpen: false,
      disCompanies:[],
      disCompaniesLoadState:'success',
      showDisCompanies: false,
      mentoredCompanies:[],
      invitations:[],
      mentoredLoadState:'idle',
      invitationsLoadState:'idle',
      phases:[
        {
          label:'Idea',
          value:'idea'
        },
        {
          label:'Prototipo',
          value:'prototype'
        },
        {
          label:'Startup',
          value:'startup'
        },
        {
          label:'MiPyME',
          value:'mipyme'
        },
      ],
      creatableSelectText:''
    }
    this.handleResizeBar                 = this.handleResizeBar.bind(this);
    this.editProfile                     = this.editProfile.bind(this);
    this.cancelForm                      = this.cancelForm.bind(this);
    this.isFormValid                     = this.isFormValid.bind(this);
    this.saveForm                        = this.saveForm.bind(this);
    this.onProfileImageMouseEnter        = this.onProfileImageMouseEnter.bind(this);
    this.onProfileImageMouseLeave        = this.onProfileImageMouseLeave.bind(this);
    this.getImage                        = this.getImage.bind(this);
    this.redirectToEditProfile           = this.redirectToEditProfile.bind(this);
    this.onDateChange                    = this.onDateChange.bind(this);
    this.onDateSelected                  = this.onDateSelected.bind(this);
    this.handleOptionChange              = this.handleOptionChange.bind(this);
    this.getGender                       = this.getGender.bind(this);
    this.getBday                         = this.getBday.bind(this);
    this.handleHeaderResize              = this.handleHeaderResize.bind(this);
    this.onChangeForm                    = this.onChangeForm.bind(this);
    this.onChangeFormCalendly            = this.onChangeFormCalendly.bind(this);
    this.cancelEdit                      = this.cancelEdit.bind(this);
    this.handleBlockedNavigation         = this.handleBlockedNavigation.bind(this);
    this.handleConfirmNavigationClick    = this.handleConfirmNavigationClick.bind(this);
    this.mentor_speciality_sector_change = this.mentor_speciality_sector_change.bind(this);
    this.fileUpload                      = this.fileUpload.bind(this);
    this.idUpload                        = this.idUpload.bind(this);
    this.getDisabledCompanies            = this.getDisabledCompanies.bind(this);
    this.getCompanies                    = this.getCompanies.bind(this);
    this.toggleDisCompanies              = this.toggleDisCompanies.bind(this);
    this.handlePhaseChange               = this.handlePhaseChange.bind(this);
    this.handlePhaseSelectorChange       = this.handlePhaseSelectorChange.bind(this);
  }

  specialitiesListToObj(specialities){
    let obj = [];
    for (var i = 0; i < specialities.length; i++) {
      let o = {}
      o["value"] = specialities[i];
      o["label"] = specialities[i];

      obj.push(o);
    }
    return obj;
  }
  handleChange = selectedOption => {
      let selectedSpecialities = selectedOption || [];
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData["mentor_speciality_sector"] = selectedSpecialities;
        return { formData };
      });
      this.setState({specialitiesChanged:true});
    };
  mentor_speciality_sector_change(data){
      let dataValue = data.target.value;
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.mentor_speciality_sector = dataValue;
        return { formData };
      });
  }
  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            authorized:true,
            me:res.user,
            formData:{
              name: res.user.name,
              last_name_father: res.user.last_name_father,
              last_name_mother: res.user.last_name_mother,
              email: res.user.email,
              dayOfBirth:null,
              gender: res.user.gender,
              goverment_id_curp: res.user.goverment_id_curp || '',
              phone: res.user.phone,
              social_linkedin: res.user.social_linkedin,
              mentor_biography: res.user.mentor_biography,
              mentor_speciality_sector: this.specialitiesListToObj(res.user.mentor_speciality_sector),
              mentor_speciality_sector_other: res.user.mentor_speciality_sector_other,
              calendly_username: res.user.calendly_username,
              pdf_file:res.user.mentor_cv_file || '',
              id_scan: res.user.id_scan || '',
              mentorIsCompany: res.user.mentorIsCompany || false,
              mentorCompanyName: res.user.mentorCompanyName || '',
              mentorCompanyWebsite: res.user.mentorCompanyWebsite || '',
              mentorCompanyAddress: res.user.mentorCompanyAddress || '',
              mentorCompanyContactName: res.user.mentorCompanyContactName || '',
              mentorCompanyEmail: res.user.mentorCompanyEmail || '',
              mentorCompanyPhone: res.user.mentorCompanyPhone || '',
              mentorCompanyPrograms: res.user?.mentorCompanyPrograms || [],
              mentorCompanyPhases: (res.user.mentorCompanyPhases || []).map((v) => this.state.phases.find((p) => p.value === v)),
              mentorPromotedProgramName1:  res.user.mentorPromotedProgramName1 ||'',
              mentorPromotedProgramDescription1: res.user.mentorPromotedProgramDescription1 ||'',
              mentorPromotedProgramURL1: res.user.mentorPromotedProgramURL1 ||'',
              mentorPromotedProgramName2: res.user.mentorPromotedProgramName2 ||'',
              mentorPromotedProgramDescription2: res.user.mentorPromotedProgramDescription2 ||'',
              mentorPromotedProgramURL2: res.user.mentorPromotedProgramURL2 ||''
            },
            userIsLoaded:true,
          });
          this.getGender(res.user);
          this.getBday(res.user);
          this.getCompanies();
          this.getDisabledCompanies();
          this.getMentoredCompanies();
        }
      }else{
        this.redirectHome();
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  componentDidUpdate(){
    if(this.state.formIsHalfFilledOut) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }
  }

  handlePhaseChange(event) {
    this.formIsHalfFilledOut(true);

    this.setState({
      formData:{
        ...this.state.formData,
        mentorCompanyPhase: event.target.value
      }
    });
  }
  getCompanies(){
    KomunidadApi.getCompanies().then((res) => {
       if(res.success){
         if(res.hasOwnProperty('companies')){
           this.setState({companies:res.companies.filter((company) => company.is_disabled === false ),companiesLoadState:'success'});
         }
       }else{
         this.setState({companies:[],companiesLoadState:'failed'})
       }
    });
  }

  getDisabledCompanies(){
    KomunidadApi.getDisabledCompanies().then((res) => {
       if(res.success){
         if(res.hasOwnProperty('companies')){
           this.setState({disCompanies:res.companies,disCompaniesLoadState:'success'});
         }
       }else{
         this.setState({disCompanies:[],disCompaniesLoadState:'failed'})
       }
    });
  }

  getMentoredCompanies(){
    //get mentored companies and invitations
    KomunidadApi.getMentoredCompanies().then((res) => {
       if(res.success){
         if(res.hasOwnProperty('companies')){
           let companies = res.companies;

           //get invitation and companies
           let invitations = companies.filter(company => company.invitation_status === 'invited');
           let mentored = companies.filter(company => company.invitation_status === 'accepted');

           this.setState({invitations:invitations,invitationsLoadState:'success'});
           this.setState({mentoredCompanies:mentored,mentoredLoadState:'success'})
         }
       }else{
         this.setState({invitations:[],invitationsLoadState:'failed'});
         this.setState({mentoredCompanies:[],mentoredLoadState:'failed'})
       }
    });
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  renderSpecialityAreaError(){
    if(this.state.formData.mentor_speciality_sector.length === 0 && this.state.specialitiesChanged){
      return (<div style={{color:'red',fontSize:"13px"}}>Selecciona al menos un área de especialidad</div>)
    }
  }
  fileUpload(file) {
    let pdf_file = file.target.files[0];
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.pdf_file = pdf_file;
      return { formData };
    });
  }
  renderPdfLabel(){
    if(this.state.editMode){
      return(
        <div className='form-group'>
          <label>Tu CV (Máximo 5MB)</label><br/>
          <input type="file" onChange={this.fileUpload} accept="application/pdf"/>
          <small className="form-text text-muted">Sube tu Curriculum Vitae en formato PDF.</small>
        </div>
      )
    }else{
      let pdfName = ( this.state.formData.pdf_file === '' ) ? 'No has agregado un CV' : 'Descargar archivo';
      if(this.state.formData.pdf_file === ''){
        return(
          <div className='form-group'>
            <label>Tu CV (Máximo 5MB)</label>
            <p>{pdfName}</p>
          </div>
        )
      }else{
        return(
          <div className='form-group'>
            <label>Tu CV (Máximo 5MB)</label>
            <a href={this.state.formData.pdf_file} >{pdfName}</a>
          </div>
        )
      }

    }
  }
  onChangeProgram(programs){
    const formData = {...this.state.formData};
    formData.mentorCompanyPrograms = programs;
    this.setState({formData:formData});
  }
  renderCompanyForm(){
    return (
        <div className='form-group form-horizontal'>
          <FormInput
              ref={ref => this.mentorCompanyName = ref}
              id="Nombre de la empresa"
              label="Nombre de la empresa"
              model="mentorCompanyName"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyName}
              initialValue={this.state.me.mentorCompanyName}
              help="Ingresa el nombre de la empresa (Mínimo 2 caracteres)"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de la empresa"/>
          <FormInput
              ref={ref => this.mentorCompanyWebsite = ref}
              id="Página web de la empresa"
              label="Página web de la empresa"
              model="mentorCompanyWebsite"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyWebsite}
              initialValue={this.state.me.mentorCompanyWebsite}
              help="Ingresa la página web de la empresa"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de la página web de la empresa"/>
          <FormInput
              ref={ref => this.mentorCompanyAddress = ref}
              id="Ubicación o Dirección"
              label="Ubicación o Dirección"
              model="mentorCompanyAddress"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyAddress}
              initialValue={this.state.me.mentorCompanyAddress}
              help="Ubicación o Dirección"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Ubicación o Dirección"/>
          <FormInput
              ref={ref => this.mentorCompanyContactName = ref}
              id="Nombre de contacto"
              label="Nombre de contacto"
              model="mentorCompanyContactName"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyContactName}
              initialValue={this.state.me.mentorCompanyContactName}
              help="Nombre de contacto"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Nombre de contacto"/>
          <FormInput
              ref={ref => this.mentorCompanyPhone = ref}
              id="Teléfono"
              label="Teléfono"
              model="mentorCompanyPhone"
              showCounter={false}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyPhone}
              initialValue={this.state.me.mentorCompanyPhone}
              help="Ingresa tu número de teléfono a 10 dígitos (Mínimo 10 caracteres)"
              maxLength={14}
              required={true}
              transformDisplayMethod={transformToPhone}
              transformMethod={editTransformToPhoneNumber}
              validationRules="minLen:10|maxLen:10|phone|required"
              onChange={change => {
                this.onChangeForm(change)
              }}
              placeholder="Teléfono"/>
          <FormInput
              ref={ref => this.mentorCompanyEmail = ref}
              id="mentorCompanyEmail"
              label="Correo electrónico"
              model="mentorCompanyEmail"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentorCompanyEmail}
              initialValue={this.state.me.mentorCompanyEmail}
              help="Correo electrónico"
              maxLength={140}
              required={true}
              validationRules="minLen:2|maxLen:140|required:1"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Correo electrónico"/>
          <div className='form-group form-horizontal'>
            <label>Tus programas para 2024</label>
            {
              this.state.editMode ?
                  <CreatableSelect
                      isClearable
                      isMulti
                      onChange={(program) => {this.handleMultipleSelect(program)}}
                      onInputChange={((v) => this.setState({creatableSelectText:v}))}
                      noOptionsMessage={() => 'Escribe tu programa'}
                      formatCreateLabel={() => {
                        return `Agregar '${this.state.creatableSelectText}'`;
                      }}
                      value={this.generateOptions()}
                  />
                  :
                  <div>
                    {
                      this.state.formData.mentorCompanyPrograms.map((program) =>{
                        return <li key={program}>{program}</li>
                      })
                    }
                  </div>
            }

          </div>
          <div className="form-group form-horizontal">
            <label>Me interesan proyectos que se encuentran en las siguientes etapas:</label>
            <div>
              {this.renderPhasesSelector()}
            </div>
          </div>

        </div>
    )
  }
  generateOptions() {
    return this.state.formData.mentorCompanyPrograms.map((p) => {
      if(typeof(p) === 'string'){
        return {
          value:p,
          label:p
        }
      }else{
        return p;
      }
    })
  }
  handleMultipleSelect(val){
    const safeVal = val || [];
    // Add appended options to state
    const formData = {...this.state.formData};
    formData.mentorCompanyPrograms = safeVal;
    this.setState({
      formData: formData,
    })
  }
  onInputChange(val){
    this.setState({
      mentorCompanyProgramWriting:val
    })
  }
  handlePhaseSelectorChange(phases){
    const formData = {...this.state.formData};
    formData.mentorCompanyPhases = phases;
    this.setState({
      formData:formData
    })
  }
  renderPhasesSelector(){
    if(!this.state.editMode){
      return (
          <>
            {this.state.formData?.mentorCompanyPhases.map((phase) =>{
              return <li key={phase.value}>{phase.label}</li>
            })}
          </>
      )
    }else{
      return(
          <Select
              value={this.state.formData.mentorCompanyPhases}
              onChange={this.handlePhaseSelectorChange}
              options={this.state.phases}
              menuPosition={'fixed'}
              isMulti={true}
              placeholder={"Selecciona una o más etapas de proyecto que te interesen"}
          />
      )
    }

  }
  renderMentorFields(){
    const isValidNewOption = (inputValue, selectValue) =>
      inputValue.length > 0 && selectValue.length < 10;
      const Menu = props => {
        const optionSelectedLength = props.getValue().length || 0;
        return (
          <components.Menu {...props}>
            {optionSelectedLength < 10 ? (
              props.children
            ) : (
              <div>Has alcanzado el límite máximo de especialidades</div>
            )}
          </components.Menu>
        );
      };
    let roles = this.state.me.roles || [];
    if(roles.includes("mentor")){
      return (
        <div>
          <div className='form-group form-horizontal'>
            <FormInput
              ref={ref => this.fi_calendly_username = ref}
              id="Calendly"
              label="Calendly"
              model="calendly_username"
              editMode={this.state.editMode}
              value={this.state.formData.calendly_username}
              initialValue={this.state.me.calendly_username}
              help="Ingresa tu nombre de usuario de Calendly"
              validationRules="maxLen:50"
              maxLength={50}
              onChange={ change => { this.onChangeFormCalendly(change) } }
              placeholder="Calendly"/>
          </div>
          <div className='form-group form-horizontal'>
            <FormTextArea
              ref={ref => this.fi_mentor_biography = ref}
              id="Biografía"
              label="Biografía"
              model="mentor_biography"
              showCounter={true}
              editMode={this.state.editMode}
              value={this.state.formData.mentor_biography}
              initialValue={this.state.me.mentor_biography}
              help="Escribe tu biografía."
              maxLength={500}
              required={true}
              validationRules="minLen:50|maxLen:500|required"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Biografía"/>
          </div>
          <div className='form-group form-horizontal'>
            <label>Áreas de Especialidad  <span>*</span></label>
              <Select
                components={{ Menu }}
                value={this.state.formData.mentor_speciality_sector}
                onChange={this.handleChange}
                options={options}
                isMulti={true}
                isDisabled={!this.state.editMode}
                isValidNewOption={isValidNewOption}
                closeMenuOnSelect={false}
                placeholder={"Selecciona una o más especialidades"}
              />
              {this.state.editMode?<small className="form-text text-muted">Seleciona una o mas especialidades.</small>:''}
              {this.renderSpecialityAreaError()}
          </div>
          <div className='form-group form-horizontal'>
            <FormInput
              ref={ref => this.mentor_speciality_sector_other = ref}
              id="Otra Área de Especialidad"
              label="Otra Área de Especialidad"
              model="mentor_speciality_sector_other"
              showCounter={true}
              editMode={this.state.editMode}
              initialValue={this.state.me.mentor_speciality_sector_other}
              value={this.state.formData.mentor_speciality_sector_other}
              help="Si tu área no se encuentra en la lista, escríbela aquí."
              maxLength={50}
              validationRules="minLen:3|maxLen:50|required"
              onChange={ change => { this.onChangeForm(change) } }
              placeholder="Otra Área de Especialidad"/>
          </div>
          {this.renderPdfLabel()}
        </div>
      )
    }else{
      return (null);
    }
  }
  idUpload(file) {
    let id_scan = file.target.files[0];
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.id_scan = id_scan;
      return { formData };
    });
  }
  renderIdField(){
    let roles = this.state.me.roles
    if(process.env.REACT_APP_ID_SCAN === "true") {
      if( roles.includes("mentor") || roles.includes("partner") ) {
        if(this.state.editMode){
          return(
            <div className='form-group'>
              <label>Tu Identificación</label><br/>
              <input type="file" accept="application/pdf" onChange={this.idUpload} />
              <small className="form-text text-muted">Sube tu identificación en formato PDF.</small>
            </div>
          )
        } else {
          let idFile = ( this.state.formData.id_scan === '' ) ? 'No has agregado una identificación' : 'Descargar archivo';
          if(this.state.formData.id_scan === ''){
            return(
              <div className='form-group'>
                <label>Tu identificación</label>
                <p>{idFile}</p>
              </div>
            )
          } else {
            return(
              <div className='form-group'>
                <label>Tu identificación </label>
                <a href={this.state.formData.id_scan} >{idFile}</a>
              </div>
            )
          }
        }
      } else {
        return (null);
      }
    }
  }
  getGender(user){
    let gender = '';
    if(user.hasOwnProperty('gender')){
      gender = user.gender;
    }
    this.setState({
      formData:{
        ...this.state.formData,
        gender: gender
      }
    });
  }
  openLoadingSwal(){
		Swal.fire({
				 title: 'Tus datos están siendo guardados',
         allowOutsideClick: false,
         customClass: {
           title: 'swal-custom-title',
         },
				 onBeforeOpen: () => {
						Swal.showLoading()
				 }
		});
	}
  closeLoadingSwal(){
		Swal.close();
	}
  getBday(user){
    if(user.hasOwnProperty('dateOfBirth')){
      let d = user.dateOfBirth;
      let date = new Date(d.replace('Z',''));
      let formInputdate = this.returnDateFormatToFormInput(date);

      this.setState({dateOfBirthInitialValue: formInputdate.day + formInputdate.month + formInputdate.year});

      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.dateOfBirth = date;
        formData.bdaypicker = formInputdate.day + formInputdate.month + formInputdate.year;
        return { formData };
      });
    }
  }

  handleBlockedNavigation = (nextLocation) => {
    if(!this.state.confirmedNavigation){
      this.confirmLeave(nextLocation);
      return false;
    }

    return true
  }

  //Confirm leave
  confirmLeave(nextLocation){
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que ingresaste se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.handleConfirmNavigationClick(nextLocation);
      }
    })
  }

  //Navigate to the next location
  handleConfirmNavigationClick(nextLocation){
     this.setState({
        confirmedNavigation: true
     }, () => {
        // Navigate to the previous blocked location
        this.props.history.push(nextLocation.pathname);
     })
  }

  //Set the state of start edit the forma
  formIsHalfFilledOut(value){
    if(!(this.state.formIsHalfFilledOut && value)){
      this.setState({formIsHalfFilledOut:value});
    }
  }

  handleOptionChange(event) {
    this.formIsHalfFilledOut(true);
    this.setState({
      formData:{
        ...this.state.formData,
        gender: event.target.value
      }
    });
  }

  editProfile(){
    this.setState({
      editMode:true,
    });
  }
  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }
  redirectHome(){
    this.props.history.push('/');
  }
  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }
  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }
  renderUpdateImageArea(){
    if(this.state.isUploadingProfileImage){
      return(
        <div className="img-circle-overlay" >
          <div className="spinner-border" role="status">
            <span className="sr-only">Subiendo...</span>
          </div>
        </div>
      )
    }else if(this.state.showUpdateImageArea){
      return(
        <div className="img-circle-overlay" onClick={() => this.imagePicker.openPicker()}>
          <div>
            Actualizar
          </div>
        </div>
      )
    }else{
      return null;
    }
  }
  onProfileImageMouseEnter(){
    this.setState({
      showUpdateImageArea:true
    })
  }
  onProfileImageMouseLeave(){
    this.setState({
      showUpdateImageArea:false
    })
  }
  //Save the data of the forminput and formtextarea
	onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.formIsHalfFilledOut(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
	}

  //Save the data of the forminput on calendly field
	onChangeFormCalendly(data){
    this.formIsHalfFilledOut(true);
    let userName = this.extractUsername(data.value);
    if(userName !== null){
      data.value = userName
    }

		this.setState({
    	formData: {
    		...this.state.formData,
    		[data.model]: data.value
    	}
    });
	}

  extractUsername(url) {
    var hostname;
    //remove protocol get hostname
    console.log(url.split('/'))
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //remove port
    hostname = hostname.split(':')[0];
    //remove "?"
    hostname = hostname.split('?')[0];

    let calendly = ["calendly.com","www.calendly.com"];
    if(calendly.includes(hostname)){
      return url.split('/')[3];
    }

    return null;
  }


  onChangeWrapperDate(data){
    if(this.state.isCalendarOpen){
      this.dp_bday.setOpen(false);
    }

    if( data.value?.length === 10 ){
      let day = data.value.substring(0,2);
      let month = parseInt(data.value.substring(3,5));
      let year = data.value.substring(6,10);
      let date = new Date(year,month-1,day);
      this.dp_bday.selected = date;
      this.onDateChange(date);
    }

    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.formIsHalfFilledOut(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }
  getImage(file){
    if(file != null){
      this.setState({isUploadingProfileImage:true});
      KomunidadApi.uploadUserImage(file).then((res) => {
        this.setState({isUploadingProfileImage:false});
        if(res.success){
          if(res.hasOwnProperty('user')){
            let user = res.user;
            if(user.hasOwnProperty('profileUrlThumbnail')){
                this.setState(prevState => {
                  let me  = Object.assign({}, prevState.me);    // creating copy of state variable jasper
                  me.profileUrlThumbnail = user.profileUrlThumbnail;           // update the name property, assign a new value
                  return { me };                                // return new object jasper object
                })
            }
          }
        }else{
          // TODO: DO SOMETHING
        }
      });
    }else{
      // TODO: DO SOMETHING
    }
  }

  //setState for DatePicker
  onDateChange(date) {
    this.formIsHalfFilledOut(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.dateOfBirth = date;
      return { formData };
    });
  }
  //setState for DatePicker
  onDateSelected(date) {
    this.formIsHalfFilledOut(true);

    let d = this.returnDateFormatToFormInput(date);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.bdaypicker = d.day + d.month + d.year;
      return { formData };
    });
  }

  returnDateFormatToFormInput(date){
    let day = date.getDate().toString();
    let month = date.getMonth() + 1;
        month = month.toString();
    let year = date.getFullYear().toString();

    if(day.length === 1){
      day = '0'+day;
    }
    if(month.length === 1){
      month = '0'+month;
    }

    return {day:day,month:month,year:year}
  }

  renderNavMentorProfile() {
    if(this.state.me.roles.includes('mentor')) {
      return(
        <Nav.Item className="nav-item">
          <Nav.Link className="nav-link" eventKey="first_b" id='first_b'>Empresas para asesorar</Nav.Link>
        </Nav.Item>
      )
    } else {
      return null;
    }
  }

  renderNavCompanies() {
    if(this.state.me.roles.includes('manager') || this.state.me.roles.includes('facilitator')) {
      return null;
    } else {
      return(
        <Nav.Item className="nav-item">
          <Nav.Link className="nav-link" eventKey="second" id='second'>Mis empresas</Nav.Link>
        </Nav.Item>
      )
    }
  }

  renderTabMentorProfile(){
    if(this.state.me.roles.includes('mentor')) {
      return(
        <div className="card-body">
         <div className='col-sm-12 col-xs-12'>
           <h5 className="card-title font-bold"><span className="lstick"></span>Invitaciones pendientes:</h5>
             <KCompanyList companies={this.state.invitations}
                           user={this.state.me}
                           emptyListTitle={'No se encontraron invitaciones'}
                           requestStatus={this.state.invitationsLoadState}
                           invitationMode={true}
             />
           <hr></hr>
           <h5 className="card-title font-bold"><span className="lstick"></span>Invitaciones aceptadas:</h5>
             <KCompanyList companies={this.state.mentoredCompanies}
                           user={this.state.me}
                           requestStatus={this.state.mentoredLoadState}
                           viewLabel={"Comentar"}
                           emptyListTitle={'No cuentas con empresas para asesorar'}
                           isMentoring={true}

             />
           <hr></hr>
         </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderTabCompanies() {
    if(this.state.me.roles.includes('manager') || this.state.me.roles.includes('facilitator')) {
      return null;
    } else {
      return(
        <div className="card-body">
          <KCompanyList companies={this.state.companies}
                        user={this.state.me}
                        requestStatus={this.state.companiesLoadState}
                        emptyListSubtitle={'Da clic en el botón de agregar empresa'}
          />
        </div>
      );
    }
  }

  renderDisabledCompanies(){
    if(this.state.disCompanies.length > 0){
      return (
        <div>
          <div className="col-md-5 align-self-center d-inline-flex" onClick={this.toggleDisCompanies} style={{cursor: 'pointer'}}>
              <h3 className="text-themecolor">Empresas Deshabilitados </h3>
              <p className="text-themecolor pl-3"><i className={"mdi " + (this.state.showDisCompanies ? 'mdi-chevron-up' : 'mdi-chevron-down')} style={{fontSize: '20px'}}></i></p>
          </div>
         <div className={this.state.showDisCompanies ? "row": "d-none"}>
          <div className="col-12">
             <div className="card">
                 <div className="card-body">
                  <KCompanyList companies={this.state.disCompanies}
                                user={this.state.me}
                                viewLabel={"Ver / Editar"}
                                requestStatus={this.state.disCompaniesLoadState}
                                emptyListSubtitle={'Da clic en el botón de agregar empresa'}
                                />
                 </div>
             </div>
           </div>
         </div>
        </div>
      )
    }else{
      return (null);
    }
  }

  toggleDisCompanies(){
    this.setState({
      showDisCompanies:!this.state.showDisCompanies
    });
  }
  onCbChange(e) {
    let checked = e.target.checked;
    const newValue = !this.state.formData.mentorIsCompany;
    const newFormData = {
      ...this.state.formData,
      mentorIsCompany: newValue
    }
    this.setState({
      formData:newFormData
    })
  }
  handlePhaseChange(event) {
    this.formIsHalfFilledOut(true);

    this.setState({
      formData:{
        ...this.state.formData,
        mentorCompanyPhase: event.target.value
      }
    });
  }
  render(){
    let moment = require('moment');
    const dateOfBirth = moment(this.state.formData.dateOfBirth).format('DD MMMM YYYY');
    const formIsHalfFilledOut = this.state.formIsHalfFilledOut;

    const CustomDateInput = ({value, onClick, onChange,  ...props }) => (
      <button className={this.state.editMode ? 'btnCalendar' : 'date-picker-non-display'} onClick={onClick}>
        <i className="mdi mdi-calendar" style={{fontSize: '20px', color: '#67757c'}}></i>
      </button>
    );


    if(this.state.authorized){
      return(
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <Prompt
              when={formIsHalfFilledOut} // <- function that returns boolean
              message={this.handleBlockedNavigation}
          />
          <Tour
            steps={steps}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.closeTour}
          />
          <div id="main-wrapper" className="height100">
            <ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage}/>
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              {/* Container fluid  */}
              <div className="container-fluid">
                {/* Bread crumb*/}
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">Perfil</h3>
                    </div>
                    <div className="col-md-7 align-self-center">
                      <div className="company-actions">
                        <div className="m-b-5 btn btn-success btn-save float-right" onClick={() => this.openTour()}>Ayuda</div>
                      </div>
                    </div>
                </div>
                {/* End Bread crumb */}
                {/* Start Page Content */}
                {/* Row */}
                <div className="row">
                  {/* Column */}
                  <div className="col-lg-4 col-xlg-3 col-md-5">
                      <div className="card">
                          <div className="card-body">
                              <center className="m-t-30 cursor-pointer noselect" onMouseEnter={this.onProfileImageMouseEnter} onMouseLeave={this.onProfileImageMouseLeave} data-tut="edit-image">
                                  {this.renderUpdateImageArea()}
                                  <KImage src={this.state.me.profileUrlThumbnail} className="img-circle" alt="user_img" type={"user_"+this.state.me.gender}/>
                                  <h4 className="card-title m-t-10">{this.state.me.name}</h4>
                                  <h6 className="card-subtitle"> </h6>
                              </center>
                          </div>
                          <div>
                              <hr></hr>
                          </div>
                          <div className="card-body">
                              <small className="text-muted">E-mail</small>
                              <h6>{this.state.me.email}</h6>
                            {!this.state.me?.mentorIsCompany ?
                                <>
                                  <small className="text-muted p-t-30 db">Fecha de nacimiento</small>
                                  <h6>{dateOfBirth}</h6>
                                  <small className="text-muted p-t-30 db">CURP</small>
                                  <h6>{this.state.me.goverment_id_curp}</h6>
                                </>
                                : null
                            }
                              <small className="text-muted p-t-30 db">Redes sociales</small>
                              <a href={this.state.me.social_linkedin} rel="noopener noreferrer" target="_blank"><i className="mdi mdi-linkedin-box" style={{fontSize: '26px'}}></i></a>
                          </div>
                      </div>
                  </div>
                  {/* Column */}
                  {/* Column */}
                  <div className="col-lg-8 col-xlg-9 col-md-7">
                      <div className="card">
                        {/* Tab container start*/}
                        <Tab.Container defaultActiveKey="first">
                            {/* Tabs. The style is in the file _style.css*/}
                            <Nav className="nav" bsPrefix='tabCustom'>
                              <Nav.Item className="nav-item">
                                <Nav.Link className="nav-link" eventKey="first" id='first'>Perfil</Nav.Link>
                              </Nav.Item>
                              {this.renderNavCompanies()}
                              {this.renderNavMentorProfile()}
                            </Nav>
                            {/* Tabs End*/}
                            {/* Tabs Content Start*/}
                            <Tab.Content className="tab-content">
                              {/* First tab content */}
                              <Tab.Pane eventKey="first">

                                <div className='card-body'>
                                  <div className='col-sm-12 col-xs-12'>
                                    {
                                      this.state.me.roles.includes('mentor') ?
                                          <div className='form-group'>
                                            <div className="switch">
                                              <label style={{fontSize: "1rem", fontWeight: 'bold'}}>Soy empresa
                                                <input type="checkbox"
                                                       name="accepts_service_requests"
                                                       onChange={(e) => {
                                                         this.onCbChange(e)
                                                       }}
                                                       disabled={!this.state.editMode}
                                                       checked={this.state.formData.mentorIsCompany}/>
                                                <span className="lever"></span>
                                              </label>
                                              <p>Activa esta casilla si eres una empresa</p>
                                            </div>
                                          </div>
                                          : null
                                    }

                                    {
                                      this.state.formData.mentorIsCompany ? this.renderCompanyForm() :

                                    <div>

                                      <div className='form-group form-horizontal'>
                                        <FormInput
                                            ref={ref => this.fi_name = ref}
                                            id="Nombre(s)"
                                            label="Nombre(s)"
                                            model="name"
                                            showCounter={true}
                                            editMode={this.state.editMode}
                                            value={this.state.formData.name}
                                            initialValue={this.state.me.name}
                                            help="Ingresa tu nombre (Mínimo 2 caracteres)"
                                            maxLength={140}
                                            required={true}
                                            validationRules="minLen:2|maxLen:140|required"
                                            onChange={change => {
                                              this.onChangeForm(change)
                                            }}
                                            placeholder="Nombre(s)"/>
                                      </div>
                                      <div className='form-group form-horizontal'>
                                        <FormInput
                                            ref={ref => this.fi_last_name_father = ref}
                                            id="Apellido Materno"
                                            label="Apellido Paterno"
                                            model="last_name_father"
                                            editMode={this.state.editMode}
                                            value={this.state.formData.last_name_father}
                                            initialValue={this.state.me.last_name_father}
                                            help="Ingresa tu apellido paterno (Mínimo 3 caracteres)"
                                            maxLength={140}
                                            required={true}
                                            validationRules="minLen:3|maxLen:140|required"
                                            onChange={change => {
                                              this.onChangeForm(change)
                                            }}
                                            placeholder="Apellido Paterno"/>
                                      </div>
                                      <div className='form-group form-horizontal'>
                                        <FormInput
                                            ref={ref => this.fi_last_name_mother = ref}
                                            id="Apellido Materno"
                                            label="Apellido Materno"
                                            model="last_name_mother"
                                            editMode={this.state.editMode}
                                            value={this.state.formData.last_name_mother}
                                            initialValue={this.state.me.last_name_mother}
                                            help="Ingresa tu apellido materno (Mínimo 3 caracteres)"
                                            maxLength={140}
                                            required={true}
                                            validationRules="minLen:3|maxLen:140|required"
                                            onChange={change => {
                                              this.onChangeForm(change)
                                            }}
                                            placeholder="Apellido Materno"/>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="example-email">Email</label>
                                        <div className="">
                                          <KEditableInput editMode={false} editable={false}
                                                          value={this.state.me.email}/>
                                        </div>
                                      </div>
                                      <div className="form-group form-horizontal wrapperDateCalendar m-b-0"
                                           style={{overflow: 'visible'}}>
                                        <div className="widthInputCalendar">
                                          <FormInput
                                              ref={ref => this.fi_bday = ref}
                                              id="Fecha de Nacimiento"
                                              label="Fecha de Nacimiento"
                                              model="bdaypicker"
                                              showCounter={false}
                                              editMode={this.state.editMode}
                                              value={this.state.formData.bdaypicker}
                                              initialValue={this.state.dateOfBirthInitialValue}
                                              help="Ingresa tu fecha de nacimiento (dd-mm-aaaa)"
                                              transformMethod={editDateMask}
                                              transformDisplayMethod={editDateMask}
                                              onChange={change => {
                                                this.onChangeWrapperDate(change)
                                              }}
                                              onClick={() => {
                                                this.dp_bday.setOpen(true);
                                                this.setState({isCalendarOpen: true})
                                              }}
                                              placeholder="Fecha de Nacimiento"
                                          />
                                        </div>
                                        <DatePicker
                                            ref={ref => this.dp_bday = ref}
                                            selected={this.state.formData.dateOfBirth}
                                            onSelect={this.onDateSelected}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            locale="es"
                                            onChange={this.onDateChange}
                                            dateFormat="dd MMMM yyyy"
                                            disabled={!this.state.editMode}
                                            className={this.state.editMode ? 'form-control form-control-line' : 'date-picker-non-editable'}
                                            maxDate={addDays(new Date(), 0)}
                                            placeholderText="Selecciona tu fecha"
                                            customInput={<CustomDateInput/>}
                                            popperModifiers={{
                                              offset: {
                                                enabled: true,
                                                offset: "-25px, -10px"
                                              },
                                            }}
                                        />
                                      </div>
                                      <div className="form-group form-horizontal">
                                        <label>Sexo</label>
                                        <div>
                                          <select
                                              className={this.state.editMode ? 'form-control custom-select' : 'select-non-editable'}
                                              value={this.state.formData.gender}
                                              onChange={this.handleOptionChange}
                                              disabled={!this.state.editMode}
                                              placeholder="Selecciona tu sexo">
                                            <option
                                                value={''}>{this.state.editMode ? 'Selecciona tu sexo' : ''}</option>
                                            <option name="female" value="female">Femenino</option>
                                            <option name="male" value="male">Masculino</option>
                                            <option name="no_answer" value="no_answer">Prefiero no decir</option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className='form-group form-horizontal'>
                                        <FormInput
                                            ref={ref => this.fi_phone = ref}
                                            id="Teléfono"
                                            label="Teléfono"
                                            model="phone"
                                            showCounter={false}
                                            editMode={this.state.editMode}
                                            value={this.state.formData.phone}
                                            initialValue={this.state.me.phone}
                                            help="Ingresa tu número de teléfono a 10 dígitos (Mínimo 10 caracteres)"
                                            maxLength={14}
                                            required={true}
                                            transformDisplayMethod={transformToPhone}
                                            transformMethod={editTransformToPhoneNumber}
                                            validationRules="minLen:10|maxLen:10|phone|required"
                                            onChange={change => {
                                              this.onChangeForm(change)
                                            }}
                                            placeholder="Teléfono"/>
                                      </div>
                                      {this.renderMentorFields()}
                                    </div>
                                    }
                                    {this.renderPromotedPrograms()}
                                    {this.renderFormButtons()}
                                  </div>

                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="first_b">
                                {this.renderTabMentorProfile()}
                              </Tab.Pane>
                              {/* First tab content end*/}
                              <Tab.Pane eventKey="second">
                                {this.renderTabCompanies()}
                                {this.renderDisabledCompanies()}
                              </Tab.Pane>
                            </Tab.Content>
                            {/* Tabs Content End*/}
                        </Tab.Container>
                        {/* Tab container end*/}
                      </div>
                  </div>
                  {/* Column */}
                </div>
                {/* Row */}
                {/* End PAge Content */}
              </div>
              {/* End Container fluid  */}
              <Footer/>
            </div>
          </div>
        </div>

      )
    }else{
      return (null);
    }

  }
  renderPromotedPrograms(){
    if(!this.state.me.roles.includes('mentor')){
      return null;
    }
    return (
        <div id={'promoted_programs'}>
          <hr></hr>
          <b>A cotinuación puedes listar programas para ser promocionados en tu perfil de komunidad. Puedes promocionar
            hasta 2 programas.</b>
          <br/>
          <br/>

          <div className="form-group form-horizontal">
            <div>
              <FormInput
                  ref={ref => this.mentorPromotedProgramName1 = ref}
                  id="Nombre del programa"
                  label="Nombre del programa"
                  model="mentorPromotedProgramName1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramName1}
                  initialValue={this.state.me.mentorPromotedProgramName1}
                  help="Nombre del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Nombre del programa"/>
              <FormInput
                  ref={ref => this.mentorPromotedProgramName1 = ref}
                  id="Descripción del programa"
                  label="Descripción del programa"
                  model="mentorPromotedProgramDescription1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramDescription1}
                  initialValue={this.state.me.mentorPromotedProgramDescription1}
                  help="Descripción del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Descripción del programa"/>
              <FormInput
                  ref={ref => this.mentorPromotedProgramURL1 = ref}
                  id="Link del registro del programa"
                  label="Link del registro del programa"
                  model="mentorPromotedProgramURL1"
                  showCounter={true}
                  editMode={this.state.editMode}
                  value={this.state.formData.mentorPromotedProgramURL1}
                  initialValue={this.state.me.mentorPromotedProgramURL1}
                  help="Link del registro del programa"
                  maxLength={140}
                  required={false}
                  validationRules="minLen:2|maxLen:140|required:1"
                  onChange={ change => { this.onChangeForm(change) } }
                  placeholder="Link del registro del programa"/>
            </div>
            <FormInput
                ref={ref => this.mentorPromotedProgramName2 = ref}
                id="Nombre del programa (2)"
                label="Nombre del programa (2)"
                model="mentorPromotedProgramName2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramName2}
                initialValue={this.state.me.mentorPromotedProgramName2}
                help="Nombre del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Nombre del programa (2)"/>
            <FormInput
                ref={ref => this.mentorPromotedProgramDescription2 = ref}
                id="Descripción del programa (2)"
                label="Descripción del programa (2)"
                model="mentorPromotedProgramDescription2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramDescription2}
                initialValue={this.state.me.mentorPromotedProgramDescription2}
                help="Descripción del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Descripción del programa"/>
            <FormInput
                ref={ref => this.mentorPromotedProgramURL2 = ref}
                id="Link del registro del programa (2)"
                label="Link del registro del programa (2)"
                model="mentorPromotedProgramURL2"
                showCounter={true}
                editMode={this.state.editMode}
                value={this.state.formData.mentorPromotedProgramURL2}
                initialValue={this.state.me.mentorPromotedProgramURL2}
                help="Link del registro del programa (2)"
                maxLength={140}
                required={false}
                validationRules="minLen:2|maxLen:140|required:1"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder="Link del registro del programa (2)"/>
          </div>
        </div>
    )
  }
  renderFormButtons(){
    if(!this.state.editMode){
      return(
        <div className="form-actions">
          <button type="submit" className="btn btn-success btn-save" onClick={() => this.editProfile()} data-tut="edit-profile">
            <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
              Editar
          </button>
        </div>
        )
    }else{
      return(
        <div className="form-actions">
          <button type="submit" className="btn btn-success btn-save m-r-10" onClick={() => this.onSaveClick()}>
            <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
          </button>
          <button type="button" className="btn btn-danger" onClick={this.cancelEdit}>
            <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                Cancelar
          </button>
        </div>
        )
    }
  }
  isFormValid(){
    //call all validations
    let roles = this.state.me.roles || [];
    if(roles.includes("mentor")){
      if(this.state.formData.mentorIsCompany){
        return (
            this.mentorCompanyName.validate() &&
            this.mentorCompanyWebsite.validate() &&
            this.mentorCompanyAddress.validate() &&
            this.mentorCompanyContactName.validate() &&
            this.mentorCompanyPhone.validate() &&
            this.mentorCompanyEmail.validate()
        );
      }else{
        return (
            this.fi_name.validate() &&
            this.fi_last_name_father.validate() &&
            this.fi_last_name_father.validate() &&
            this.fi_phone.validate() &&
            this.state.formData.dateOfBirth !== null &&
            this.state.formData.gender !== null &&
            this.fi_mentor_biography.validate() &&
            this.fi_calendly_username.validate() &&
            (
                this.state.formData.mentor_speciality_sector.length > 0 ||
                (
                    this.state.formData.mentor_speciality_sector_other !== '' &&
                    this.mentor_speciality_sector_other.validate()
                )
            )

        );
      }

    }else{
      return (
        this.fi_name.validate() &&
        this.fi_last_name_father.validate() &&
        this.fi_last_name_father.validate() &&
        this.fi_phone.validate() &&
        this.state.formData.dateOfBirth !== null &&
        this.state.formData.gender !== null
      );
    }
  }
  onSaveClick(){
    if(this.state.formData.mentor_speciality_sector.length === 0 || this.state.formData.mentor_speciality_sector_other.trim() === '' ){
      this.setState({specialitiesChanged:true}); //this will trigger the specialities error
    }
    if(this.isFormValid()){
      //update all
      this.saveForm();
    }
  }
  saveForm(){
    this.openLoadingSwal();
    let params = this.state.formData;
    let mentor_specialities = params.mentor_speciality_sector || [];
    try{
      params.phone = this.fi_phone.getCleanValue();
      params.mentor_speciality_sector = mentor_specialities.map(function(ss){return ss["value"]}).join("|");
    }catch(ex){

    }
    try {
      params.mentorCompanyPhone = this.mentorCompanyPhone.getCleanValue();
    }catch(ex){

    }
    if(params.social_linkedin === null){
      params.social_linkedin = '';
    }
    if(this.state.formData.mentorIsCompany){
      params.mentorCompanyPhases = this.state.formData.mentorCompanyPhases.map((v) => v.value);
      params.mentorCompanyPrograms = this.state.formData.mentorCompanyPrograms.map((v) => v.value || v);
    }
    let t = this;
    KomunidadApi.updateUser(params).then((res) => {
      /*--------Close loading swal------------------------------------------- */
      t.closeLoadingSwal();
      if(res.success){
        this.formIsHalfFilledOut(false);

        if(res.hasOwnProperty('user')){
          let user = res.user;
          const formData = {...this.state.formData};
          formData.mentorCompanyPhases = (res.user.mentorCompanyPhases || []).map((v) => this.state.phases.find((p) => p.value === v));
          this.setState({
            editMode:false,
            me:user,
            formData:formData
          })
          this.setState(prevState => {
            let formData = Object.assign({}, prevState.formData);
            formData["mentor_speciality_sector"] = this.specialitiesListToObj(res.user.mentor_speciality_sector);
            formData["pdf_file"] = res.user.mentor_cv_file;
            formData["id_scan"] = res.user.id_scan;
            return { formData };
          });
          this.getGender(user);
          this.getBday(user);
        }
      }else{
        Swal.fire({
          type: 'error',
          title: 'No pudimos guardar tus datos',
          text: 'Hubo un error al guardar tus datos',
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }
  getFormatDate(date){
    let moment = require('moment');
    let dateFormat = moment(date).format('MM-DD-YYYY');
    return dateFormat
  }
  cancelEdit(){
    this.cancelForm();
    this.setState({
      editMode:false,
    });
  }
  cancelForm(){
    let roles = this.state.me.roles || [];
    if(!this.state.formData.mentorIsCompany){
        this.fi_name.cancel();
        this.fi_last_name_father.cancel();
        this.fi_last_name_mother.cancel();
        /*this.fi_goverment_id_curp.cancel();*/
        this.fi_phone.cancel();
        // this.fi_social_linkedin.cancel();
    }

    if(roles.includes("mentor")){
      if(!this.state.formData.mentorIsCompany) {
        this.fi_calendly_username.cancel();
        this.fi_bday.cancel();
        this.mentor_speciality_sector_other.cancel();
        this.fi_mentor_biography.cancel();
      }else{
        this.mentorCompanyName.cancel();
        this.mentorCompanyWebsite.cancel();
        this.mentorCompanyAddress.cancel();
        this.mentorCompanyContactName.cancel();
        this.mentorCompanyEmail.cancel();
        this.mentorCompanyPhone.cancel();
        const newValue = {
          ...this.state.formData,
          mentorCompanyPhase: this.state.me.mentorCompanyPhase
        }
        this.setState({
          formData: newValue
        })
      }
      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData["mentor_speciality_sector"] = this.specialitiesListToObj(this.state.me.mentor_speciality_sector);
        return { formData };
      });
    }

  }
}

export default ProfileView
