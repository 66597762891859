/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, September 2019
 */

//Regular imports
import React from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import SkyLight from 'react-skylight';
import FormInput from '../form/FormInput';
import KomunidadApi from '../../api/api.js';

//Style import
import '../../scss/pages/_landing.scss';


class DemoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      full_name: '',
      email: '',
      phone: '',
      business_name: '',
      modalStyle: {}
    }

    this.executeAfterModalClose = this.executeAfterModalClose.bind(this);
    this.executeBeforeModalOpen = this.executeBeforeModalOpen.bind(this);
    this.onChangeForm           = this.onChangeForm.bind(this);
    this.validateData           = this.validateData.bind(this);
    this.sendData               = this.sendData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isModalOpen) {
      this.demoModal.show();
    }else{
      this.demoModal.hide();
    }
  }

  executeBeforeModalOpen() {
    if(window.innerWidth <= 560){
      this.setState(prevState => {
        let modalStyle = Object.assign({}, prevState.modalStyle);
        modalStyle.marginLeft = 0;
        modalStyle.marginTop = undefined;
        modalStyle.width = '100%';
        modalStyle.left = 0;
        modalStyle.top = '10%';
        modalStyle.minHeight = undefined;
        modalStyle.maxHeight = '80%'
        modalStyle.overflow = 'auto'; 
        modalStyle.zIndex = 200;
        return { modalStyle };
      })
    } else {
      this.setState(prevState => {
        let modalStyle = Object.assign({}, prevState.modalStyle);
        modalStyle.marginLeft = '-25%';
        modalStyle.marginTop = undefined;
        modalStyle.width = '50%';
        modalStyle.left = '50%';
        modalStyle.top = '15%';
        modalStyle.minHeight = '400px';
        modalStyle.maxHeight = '80%';
        modalStyle.overflow = 'auto'; 
        modalStyle.zIndex = 200;
        return { modalStyle };
      })
    }
  }

  executeAfterModalClose(){
    this.props.closeModal();
    this.setState({
      full_name: '',
      email: '',
      phone: '',
      business_name: '',
    })
  }

  //Save the data of the forminput
  onChangeForm(data){
    this.setState({
      [data.model]: data.value
    });
  }

  validateData(){
    return this.full_name.validate() &&
           this.fi_email.validate() &&
           this.fi_phone.validate() &&
           this.fi_business_name.validate();
  }

  //Method to company registration
  sendData() {

    let params = {};
    if(this.validateData()) {
      params = {
        full_name: this.state.full_name,
        email: this.state.email,
        phone: this.state.phone,
        business_name: this.state.business_name,
      }
      KomunidadApi.requestDemo(params).then((res) => {
        if(res.success){
          this.requestSuccess()
        }else{
          this.requestFail()
        }
      });
    }
  }

  requestSuccess() {
    Swal.fire({
      type: 'success',
      title: '¡Se ha enviado tu solicitud!',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then((result) => {
      this.executeAfterModalClose()
    })
  }

  requestFail() {
    Swal.fire({
      type: 'error',
      title: 'No pudimos enviar tus datos',
      text: 'Hubo un error al enviar tus datos',
      footer: '',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    });
  }

  render() {
  	return(
  		<SkyLight
        dialogStyles={this.state.modalStyle}
        title={[
        	<div className="row mx-0 justify-content-md-center" key="tittlekey" style={{display: 'block'}}>
            <center>
              <div className="pt-5">Solicitar una demo</div>
            </center>
          </div>
        ]}
        ref={ref => this.demoModal = ref}
        beforeOpen={this.executeBeforeModalOpen}
        afterClose={this.executeAfterModalClose}
        hideOnOverlayClicked
      	>
        <div className="row mx-0 p-30 pt-0 form-horizontal" key="contentkey" style={{textAlign: 'left'}}>
          <div className="card-body p-30">
            <FormInput 
              label="Nombre y Apellido"
              ref={ref => this.full_name = ref}
              id="nombre"
              model="full_name"
              cssClass="custom-class"
              value={this.state.full_name}
              help="Escribe tu nombre completo."
              maxLength={140}
              validationRules="minLen:2|maxLen:100"
              required={true}
              onChange={ change => { this.onChangeForm(change) } }
            />
            <FormInput 
              label="Correo"
              ref={ref => this.fi_email = ref}
              id="correo"
              model="email"
              cssClass="custom-class"
              showCounter={false}
              value={this.state.email}
              help="Escribe tu correo electronico."
              validationRules="email"
              required={true}
              onChange={ change => { this.onChangeForm(change) } }
            />
            <FormInput
              ref={ref => this.fi_phone = ref}
              id="teléfono"
              model="phone"
              label="Teléfono"
              cssClass="custom-class"
              showCounter={true}
              value={this.state.phone}
              help="Escribe el teléfono de tu empresa,sin espacios ni comas."
              maxLength={10}
              validationRules="phone|minLen:10|maxLen:10"
              required={true}
              onChange= {change => { this.onChangeForm(change) }}
            />
            <FormInput 
              label="Nombre de empresa u organización"
              ref={ref => this.fi_business_name = ref}
              id="nombre de tu organización"
              model="business_name"
              cssClass="custom-class"
              value={this.state.business_name}
              help="Escribe el nombre de tu organización."
              maxLength={140}
              validationRules="required|minLen:3|maxLen:140"
              required={true}
              onChange={ change => { this.onChangeForm(change) } }
            />
            <div className="button-group">
              <button 
                type="button" 
                className="btn waves-effect waves-light btn-info btn-save pull-right float-right"
                onClick={this.sendData}>Enviar</button>
            </div>
          </div>
        </div>
      </SkyLight>
  	)
  }

}

DemoModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

DemoModal.defaultProps = {
  isModalOpen: false,
  closeModal: () => {},
};

export default DemoModal;