/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal,Button} from 'react-bootstrap';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import KCompanyList from '../components/KCompanyList';
import KImage from '../components/KImage';
import { compose } from 'underscore';

/** TODO:CHECK THE NAME.
*   Modal to invite a selected mentor to a company (as mentor).
*   If the user is a partner in the selected company, can't be a mentor.
*/

class KCompanyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageIsVisible: true,
            message: null,
            isCompanySelected: false,
            companySelected: {},
            isButtonDisable: false,
            showBtnBack: false,
            companies: [],
            companiesLoadState: "idle",
        };
        this.setMsg = this.setMsg.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.validatorEmail = this.validatorEmail.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.executeAfterModalClose = this.executeAfterModalClose.bind(this);
        this.onCompanySelected = this.onCompanySelected.bind(this);
        this.backNavigation = this.backNavigation.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.clear = this.clear.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
    }
  filterPrograms(company, programId) {
    var companyBelongsProgram = false;
    company.programs.forEach((program) => {
      if (program.program._id === programId) {
        companyBelongsProgram = true;
      }
    });
    return companyBelongsProgram;
  }

  // componentWillMount is deprecated...
  componentDidMount() {
    this.getCompanies();
  }

  componentDidUpdate(prevProps) {
    if (this.props.programId !== prevProps.programId) {
      this.getCompanies();
    }
  }

  getCompanies() {
    //get companies
    KomunidadApi.getCompanies().then((res) => {
      if (res.success) {
        if (res.hasOwnProperty("companies")) {
          let companies = res.companies;
          companies = companies.filter(
            (company) => company.invitation_status === "accepted"
          );
          if (this.props.programId !== "") {
            let programId = this.props.programId;
            companies = companies.filter((company) => {
              return this.filterPrograms(company, programId);
            });
          }
          this.setState({
            companies: companies,
            companiesLoadState: "success",
          });
        }
      } else {
        this.setState({ companies: [], companiesLoadState: "failed" });
      }
    });
  }

  handleCloseModal(show) {
    this.props.closeModal(show);
  }

  validatorEmail(email) {
    let messages = {
      invalid_email: "El email es inválido",
      invalid_len: "El email no puede estar vacío",
    };
    let validatorResponse = {
      result: false,
      reason: null,
    };

    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

    if (email.toString().trim() === "") {
      validatorResponse.reason = messages.invalid_len;
      validatorResponse.result = false;
    } else if (re.test(String(email).toLowerCase())) {
      validatorResponse.reason = null;
      validatorResponse.result = true;
    } else {
      validatorResponse.reason = messages.invalid_email;
      validatorResponse.result = false;
    }
    return validatorResponse;
  }

  inviteMentor(company) {
    let messages = {
      not_sent: "Hubo un error al enviar tus datos",
      invalid_id: "Hubo un error al enviar tus datos",
      sent: "Tu invitación ha sido enviada",
    };

    if (company._id !== null) {
      KomunidadApi.inviteRegisteredMentor(
        this.props.user._id,
        company._id
      ).then((res) => {
        if (res.success) {
          this.hideModal();
          this.props.onMentorsAdded();
        } else {
          Swal.fire({
            type: "error",
            title: "Error al enviar invitación:",
            text: res.error_es,
            customClass: {
              confirmButton: "swalBtnTeal",
              title: "swal-custom-title",
            },
            footer: "",
          });
        }
      });
    } else {
      this.setMsg(true, messages["invalid_id"]);
    }
  }

  hideModal() {
    this.handleCloseModal(false);
  }

  onClickSend() {
    //get the value of the company selected
    let companySelected = this.state.companySelected;

    this.inviteMentor(companySelected);
  }

  setMsg(messageIsVisible, msg, type) {
    this.setState({
      messageIsVisible: messageIsVisible,
      message: msg,
    });
  }

  clearMessage() {
    this.setMsg(true, "");
  }

  renderMessage() {
    if (this.state.messageIsVisible) {
      return this.state.message;
    }
  }

  executeAfterModalClose() {
    this.clear();
    this.clearMessage();
    this.props.closeModal(false);
  }

  onCompanySelected(company) {
    this.setState({
      showBtnBack: true,
      companySelected: company,
      isCompanySelected: true,
    });
  }

  renderNameOrEmail(user) {
    if (user.name === null) {
      return user.email;
    } else {
      return (
        user.name + " " + user.last_name_father + " " + user.last_name_mother
      );
    }
  }

  renderModalContent() {
    const user = this.props.user;

    if (this.state.isCompanySelected) {
      return (
        <div className="KAPMWrapper">
          <div className="KAPMUser">
            <h4 className="m-b-20">
              Estás por invitar a <b>{this.renderNameOrEmail(user)}</b> como tu
              nuevo aliado(a) a tu startup{" "}
              <b>{this.state.companySelected.name}</b>.
            </h4>
            <div className="invitation_logos m-t-20">
              <KImage
                src={this.state.companySelected.logoThumbnail}
                alt="company_img"
                className="invitation-company-logo"
                type={"company"}
              />
              <KImage
                src={user.profileUrlThumbnail}
                className="invitation-company-logo"
                alt="user_img"
                type={"user_" + user.gender}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrapper__kCompany__list">
          <p>Selecciona una empresa</p>
          <KCompanyList
            companies={this.state.companies}
            requestStatus={this.state.companiesLoadState}
            shortView={true}
            hoverStyle={true}
            stripedStyle={false}
            borderlessStyle={false}
            emptyListTitle={
              "No cuentas con empresas registradas en este programa"
            }
            onClickCompany={this.onCompanySelected}
            showDate={false}
          />
        </div>
      );
    }
  }

  renderBtnBack() {
    if (this.state.showBtnBack) {
      return (
        <div className="modal__btn__back" onClick={this.backNavigation}>
          <i className="mdi mdi-chevron-left" style={{fontSize: '30px', color: "#c2c2c2"}}></i>
        </div>
      );
    } else {
      return null;
    }
  }

  backNavigation() {
    this.clear();
  }

  renderInvitationBtn() {
    if (this.state.isCompanySelected) {
      return (
        <Button
          className="float-right btn btn-info btn-save"
          disabled={this.state.isButtonDisable}
          onClick={this.onClickSend}
        >
          Enviar invitación
        </Button>
      );
    } else {
      return null;
    }
  }
  clear() {
    this.setState({
      isCompanySelected: false,
      companySelected: {},
      isButtonDisable: false,
      showBtnBack: false,
    });
  }

  onShow() {
    this.clear();
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onShow={() => this.onShow()}
        onHide={() => this.handleCloseModal(false)}
        onExit={this.executeAfterModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {this.renderBtnBack()}
          <Modal.Title id="contained-modal-title-vcenter">
            Invitar aliados
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"modal__body__height"}>
          {this.renderModalContent()}
          <div className="KAPMMessage">
            <b>{this.renderMessage()}</b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={"btn btn-danger btn-cancel"}
            onClick={() => this.handleCloseModal(false)}
          >
            Cerrar
          </Button>
          {this.renderInvitationBtn()}
        </Modal.Footer>
      </Modal>
    );
  }
}

KCompanyModal.propTypes = {
  /** Company  Id. */
  companyId        : PropTypes.string,
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** Callback when the mentor is invited*/
  onMentorsAdded   : PropTypes.func,
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** The user to be invited as mentor*/ 
  user             : PropTypes.object,
  /* The program id */
  programId        : PropTypes.string
};

KCompanyModal.defaultProps = {
  companyId       : null,
  closeModal      : () => {},
  onMentorsAdded  : () => {},
  showModal       : false,
  user            : {},
  programId       : ''
};

export default KCompanyModal;
