/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, June 2019
 */

// Regular imports
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom';
// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';

//version
import pkg from '../../package.json'


/** Navigation sidebar.
*   Display the navigation option by user type (partner or mentor and facilitator or manager).
*/

class SideBar extends React.Component {
  constructor(p){
    super(p);
    this.state = {
      currentPath: ''
    }
    this.createCompany = this.createCompany.bind(this);
  }

  componentDidMount(){
    this.setState({currentPath: this.props.history.location.pathname})
  }

  //If the user doesn't have his data complete, he can't create a business.
  createCompany(){
    this.props.history.push('/create_company')
  }
  renderAddCompanyButton(){
    let user = this.props.user;
    if(!user.hasOwnProperty("roles")){
      return (null);
    }
    if(this.props.user.roles.includes("partner") || this.props.user.roles.includes("mentor")){
      return ( <div className="sidebar-btn" style={{textAlign: 'center'}}>
           <button type="button" className="btn btn-success btn-save" onClick={this.createCompany}>
            +
           </button>
         </div>)
    }else{
      return(
        null
      )
    }
  }

  refreshSchedule() {
    if(window.location.pathname === '/agenda') {
      window.location.href="/agenda";
    } else {
      this.props.history.push('/agenda')
    }
  }

  renderMenu(){
    const user = this.props.user;
    if(!user.hasOwnProperty("roles")){ //If the user is not identified, remove all
      return(
        <div>
        </div>
      )
    }
    else if(user.roles.includes('facilitator') || user.roles.includes('manager')){
      return(
        <div>
          <li className={this.state.currentPath ==='/manager' ? 'selected-tab':''}>
            <Link
              className="has-arrow waves-effect waves-dark"
              to={"/manager"}
            >
              <i className="mdi mdi-account" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Panel de control</span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/manager/users') ? 'selected-tab':''}>
            <Link
              className="has-arrow waves-effect waves-dark"
              to={"/manager/users"}
            >
              <i className="mdi mdi-account-multiple" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Usuarios</span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/manager/programs') ? 'selected-tab':''}>
            <Link className="has-arrow waves-effect waves-dark" to={'/manager/programs'}>
              <i className="mdi mdi-book" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Programas
              </span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/manager/stats') ? 'selected-tab':''}>
            <Link
              className="has-arrow waves-effect waves-dark"
              to={"/manager/stats"}
            >
              <i className="mdi mdi-folder-multiple" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Datos</span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/certificate_editor') ? 'selected-tab':''}>
            <Link
              className="has-arrow waves-effect waves-dark"
              to={"/certificate_editor"}
            >
              <i className="mdi mdi-certificate" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Certificados</span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/manager/academy') ? 'selected-tab':''}>
            <Link
              className="has-arrow waves-effect waves-dark"
              to={"/manager/academy"}
            >
              <i className="mdi mdi-school" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Academia</span>
            </Link>
          </li>
        </div>
      )
    }else{
        return(
          <div>
            {/* <li className={this.state.currentPath.includes('/agenda') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/agenda'} onClick={() => this.refreshSchedule()}>
              <i className="mdi mdi-book" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Inicio
                </span>
              </Link>
            </li> */}
            {/* <li>
              <Link className="has-arrow waves-effect waves-dark" to={'/speakers'}>
                <i className="mdi mdi-microphone-variant" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Ponentes
                </span>
              </Link>
            </li> */}
            <li className={this.state.currentPath.includes('/live') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/live'}>
                <i className="mdi mdi-play-circle" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Inicio
                </span>
              </Link>
            </li>
            {/*<li className={this.state.currentPath.includes('/sponsors') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/sponsors'}>
                <i className="mdi mdi-human-greeting" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Aliados
                </span>
              </Link>
            </li>*/}
            <li className={this.state.currentPath.includes('/companies') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/companies'}>
                <i className="mdi mdi-lightbulb-on" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Tu empresa
                </span>
              </Link>
            </li>
            <li className={this.state.currentPath.includes('/mentors') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/mentors'}>
                <i className="mdi mdi-account-star-variant" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Aliados
                </span>
              </Link>
            </li>
            {/* <li>
              <Link className="has-arrow waves-effect waves-dark" to={'/services'}>
                <i className="mdi mdi-briefcase" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Servicios
                </span>
              </Link>
            </li>
            <li className={this.state.currentPath.includes('/progress_report') ? 'selected-tab':''}>
              <Link className="has-arrow waves-effect waves-dark" to={'/progress_report'}>
                <i className="mdi mdi-format-list-numbers" style={{fontSize: '20px'}}></i>
                <span className="hide-menu"> Avances
                </span>
              </Link>
            </li> */}
          {/* <li>
            <Link className="has-arrow waves-effect waves-dark" to={'/financing'}>
              <i className="mdi mdi-bank" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Feria financiera
              </span>
            </Link>
          </li> */}
          <li className={this.state.currentPath.includes('/resources') ? 'selected-tab':''}>
            <Link className="has-arrow waves-effect waves-dark" to={'/resources'}>
              <i className="mdi mdi-folder" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Recursos
              </span>
            </Link>
          </li>
          <li className={this.state.currentPath.includes('/courses') ? 'selected-tab':''}>
            <Link className="has-arrow waves-effect waves-dark" to={'/courses'}>
              <i className="mdi mdi-school" style={{fontSize: '20px'}}></i>
              <span className="hide-menu"> Academia
              </span>
            </Link>
          </li>
          {/*<li className={this.state.currentPath.includes('/financing') ? 'selected-tab':''}>*/}
          {/*  <Link className="has-arrow waves-effect waves-dark" to={'/financing'}>*/}
          {/*    <i className="mdi mdi-cash" style={{fontSize: '20px'}}></i>*/}
          {/*    <span className="hide-menu"> Feria financiera*/}
          {/*    </span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </div>
      )
    }
  }
  renderFreeMenu(){
    return(
      <div>
        <li className={this.state.currentPath === '/live_free'||this.state.currentPath === '/' ? 'selected-tab':''}>
          <Link className="has-arrow waves-effect waves-dark" to={'/live_free'}>
            <i className="mdi mdi-play-circle" style={{fontSize: '20px'}}></i>
            <span className="hide-menu"> En Vivo
            </span> 
          </Link>
        </li>
        {/* <li className={this.state.currentPath.includes('/sponsors') ? 'selected-tab':''}>
          <Link className="has-arrow waves-effect waves-dark" to={'/sponsors'}>
            <i className="mdi mdi-human-greeting" style={{fontSize: '20px'}}></i>
            <span className="hide-menu"> Aliados
            </span>
          </Link>
        </li> */}
        <li className={this.state.currentPath.includes('/sign-up') ? 'selected-tab':''}>
          <div className="has-arrow waves-effect waves-dark"  onClick={() => this.props.onClickSidebar()}>
            <i className="mdi mdi-lock" style={{fontSize: '20px'}}></i>
            <span className="hide-menu"> Desbloquea todo el contenido de CREO MX
            </span> 
          </div>
        </li>

      </div>
  )
  }

  switchMenu(){
    if(this.props.freeMode){
      return this.renderFreeMenu();
    }else{
      return this.renderMenu();
    }
  }
  render(){
    if(this.props.hideSideBar){
      return null
    }else{
      return(
        <aside className="left-sidebar">
            {/* Sidebar scroll*/}
            <div className="scroll-sidebar">
                {/* Sidebar navigation*/}
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <li className="user-profile">
                          {/*<Link to={'/profile'} className="has-arrow waves-effect waves-dark" aria-expanded="false">
                            <KImage src={this.props.user.profileUrlThumbnail}
                                    className={"sideBarProfileImage"}
                                    alt={"user_img"}
                                    type={"user_"+this.props.user.gender}
                            />
                            <span className="hide-menu">{this.props.user.name} </span>
                          </Link>*/}
                            <ul aria-expanded="false" className="collapse">
                                <li><Link to={'/profile'}>Mi Perfil </Link ></li>
                                <li><Link  to={"/"}>Salir</Link></li>
                            </ul>
                        </li>
                        {/*<li className="nav-devider"></li>*/}
                        {this.switchMenu()}
                        {/* {this.renderAddCompanyButton()} */}
                    </ul>
                </nav>
                {/* End Sidebar navigation */}
                {/* Start btn to create business */}
                {/* End btn to create business */}
            </div>
            {/* End Sidebar scroll*/}
            <footer className={"sidebar-nav " + (this.props.visible ? "sidebar-footer-k" : "")} style={{marginLeft: '15px'}}>
              <ul id="sidebarnav" style={{display: 'inline-flex', fontSize: '12px'}}>
                <li><a className="waves-effect waves-dark" style={{padding: 0, fontSize: '12px'}} rel="noopener noreferrer" href="https://creomx.com/edomex/Aviso.pdf" target="_blank">Politica de Privacidad</a></li> |
                <li><a className="waves-effect waves-dark" style={{padding: 0, fontSize: '12px'}} rel="noopener noreferrer" href="https://www.komunidad.io/terminosycondiciones" target="_blank">Términos</a></li>
              </ul>
              <p style={{fontSize: '12px'}}>Komunidad IO © 2024 - v{pkg.version}</p>
            </footer>
        </aside>
      );
    }
  }

}
SideBar.propTypes = {
  /** User object */
  user                 : PropTypes.object,
  /** When is true the sidebar is showed. */
  visible              : PropTypes.bool,
  /** When is true the sidebar is not rendered. */
  hideSideBar          : PropTypes.bool,
  /** When is true the sidebar render free mode. */
  freeMode             : PropTypes.bool,
  /** When is true the sidebar render free mode. */
  onClickSidebar       : PropTypes.bool,
};

SideBar.defaultProps = {
  user:{
      _id                     :null,
      name                    :null,
      email                   :null,
      created_at              :null,
      updatedAt               :null,
      source                  :null,
      uid                     :null,
      profileUrlThumbnail     :null,
      goverment_id_curp       :null,
      social_linkedin         :null,
      social_facebook         :null,
      social_twitter          :null,
      social_other            :null,
      goverment_id_rfc        :null,
      dateOfBirth             :null,
      phone                   :null,
      gender                  :null,
      roles                   :[]
  },
  visible                     :true,
  hideSideBar                 :false,
  freeMode                    :false,
  onClickSidebar              : () => {},
};
export default withRouter(SideBar);
