/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx>, August 2019
 */

// Regular imports
import React from 'react'
import Swal from 'sweetalert2';
import KomunidadApi from '../../api/api.js';

// Custom styling
import '../../scss/pages/login-register-lock.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/pages.scss';
import '../../scss/pages/_login.scss';

// Images
import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();
class ResetPasswordView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      email: '',
      pr_code: '',
      password: '',
      password_confirmation: '',
      error_message: '',
      show_success: false,
      resetEnabled: false,
      pwdEquals:false
    }

    this.passwordCheck = {
      isNumber: false,
      isLetter: false,
      isUpperLetter: false,
      sixCharacters: false,
      isEqual: false
    }

    this.handleSubmit         = this.handleSubmit.bind(this);
    this.validateForm         = this.validateForm.bind(this);
    this.handleChanges        = this.handleChanges.bind(this);
    this.getPasswordResetCode = this.getPasswordResetCode.bind(this);
    this.cleanErrorFields     = this.cleanErrorFields.bind(this);
  }

  componentDidMount () {
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){ //redirect to profile if user is logged in
        this.redirectToCompanies();
      }
    });
    this.getPasswordResetCode();
  }

  redirectToProfile(){
    this.props.history.push('/profile');
  }

  redirectToCompanies(){
    this.props.history.push(`/companies`);
  }

  getPasswordResetCode () {
    let password_reset_code = this.props.match.params.pr_code

    KomunidadApi.getPrCode(password_reset_code).then(res => {
      if (res.hasOwnProperty('user')) {
        this.setState({
          email: res.user.email,
          pr_code: res.pr_code,
          resetEnabled: true
        })
      } else { // Abort, no code matches
        this.setState({ error_message: 'El código de cambio de contraseña no es válido.' })
      }
    }).catch(err => {
      this.setState({ error_message: 'Servicio no disponible. Intenta de nuevo más tarde.' })
    })
  }

  setPassword(password) {
    if(password.target.value.match(/\d+/)) {
      this.passwordCheck.isNumber = true;
    } else {
      this.passwordCheck.isNumber = false;
    }
    if(password.target.value.match(/[A-Z]+/)) {
      this.passwordCheck.isUpperLetter = true;
    } else {
      this.passwordCheck.isUpperLetter = false;
    }
    if(password.target.value.match(/[a-z]+/)) {
      this.passwordCheck.isLetter = true;
    } else {
      this.passwordCheck.isLetter = false;
    }
    if(password.target.value.match(/\w{6,12}/)) {
      this.passwordCheck.sixCharacters = true;
    } else {
      this.passwordCheck.sixCharacters = false;
    }
  }

  isPasswordEqual() {
		let pwd_eq = (this.state.password === this.state.password_confirmation );
	  this.passwordCheck.isEqual = pwd_eq;
		this.setState({"pwdEquals":pwd_eq});
  }

  handleChanges(event) {
		this.setState({
			[event.target.id]:event.target.value,
			errorMsg:""
		}, () => {
			this.isPasswordEqual();
		});
	}

  handleSubmit (event) {
    event.preventDefault();
    this.setState({ error_message : '' });

    if (this.validateForm()) {
      KomunidadApi.createNewPwd(this.state.pr_code, this.state.password).then(res => {
        this.setState({ show_success: true });
        this.passwordChanged()
      }).catch(err => {
        this.setState({ error_message: 'Servicio no disponible. Intenta de nuevo más tarde.' });
      })
    } else {
    	this.setState({ error_message: 'Verifica que tu contraseña cumpla con las condiciones.' });
    }
  }

  validateForm () {

    for(const el in this.passwordCheck) {
      if(!this.passwordCheck[el]) {
        return false;
      }
    }

		return true;

  }

  cleanErrorFields() {
    this.passwordCheck.isLetter = false;
    this.passwordCheck.isUpperLetter = false;
    this.passwordCheck.isNumber = false;
    this.passwordCheck.sixCharacters = false;
    this.passwordCheck.isEqual = false;
    this.setState({
      password: '',
      password_confirmation: '',
      error_message: ''
    });
  }

  passwordChanged() {
    Swal.fire({
      type: 'success',
      title: '¡Genial!',
      text: '¡Tu contraseña se ha reestablecido. Ya puedes iniciar sesión!',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then((result) => {
      this.setState({ show_success: false });
      KomunidadApi.login(this.state.email, this.state.password).then(res => {
        this.props.history.push('/profile');
      })
    })
  }
  renderIsNumberHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"40px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isNumber){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
								Al menos un número
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
								Al menos un número
							</div>
				</div>
			);
		}
	}
	renderIsLetterHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"40px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isLetter){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra minúscula
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra minúscula
							</div>
				</div>
			);
		}
	}
	renderIsUpperLetterHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"40px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.isUpperLetter){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra mayúscula
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
								Al menos una letra mayúscula
							</div>
				</div>
			);
		}
	}

	renderSixCharsHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"40px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.passwordCheck.sixCharacters){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
								Al menos 6 caracteres
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
								Al menos 6 caracteres
							</div>
				</div>
			);
		}
	}
	renderPwdEqualsHint(){
		let divStyle = {
			display: "flex",
			alignItems: "center",
			marginLeft:"8px",
			height:"40px"
		}
		let iconStyle = {
			color:"#3ccccc",
			width:"20px",
			marginRight:"5px"
		}
		let textStyle = {
			cursor:'default',
			fontSize:"13px"
		}
		if(this.state.pwdEquals){
			return (
				<div style={divStyle}>
							<i className="mdi mdi-check" style={iconStyle}></i>
							<div className={this.state.pwdEquals ? "": "text-muted"} style={textStyle}>
								Contraseñas coinciden
							</div>
				</div>
			);
		}else{
			return (
				<div style={divStyle}>
							<div style={iconStyle}/>
							<div className={this.state.pwdEquals ? "": "text-muted"} style={textStyle}>
								Contraseñas coinciden
							</div>
				</div>
			);
		}
	}
  renderForm() {
    if(this.state.resetEnabled) {
      return(
        <div>
          <div className="form-group mb-3">
            <div className="col-xs-12" style={{fontSize: '14px'}}>
              <label>Correo electrónico:</label> {this.state.email}
              <input className="d-none" id="email" value={this.state.email} onChange={this.handleChanges} type="email" disabled placeholder="Correo electrónico" />
            </div>
          </div>

          <div className="form-group mb-3">
            <div className="col-xs-12">
              <input tabindex="1" className="form-control" id="password" value={this.state.password} onChange={ change => {this.handleChanges(change); this.setPassword(change); this.isPasswordEqual(change);}} type="password"  placeholder="Nueva contraseña" />
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="col-xs-12">
              <input tabindex="2" className="form-control" id="password_confirmation" value={this.state.password_confirmation} onChange={ change => {this.handleChanges(change); this.isPasswordEqual(change); }} type="password" placeholder="Confirma tu contraseña" />
            </div>
          </div>
          <label className="font-weight-bold" style={{fontSize: '14px'}}>Tu contraseña debe contener:</label>
            {this.renderIsNumberHint()}
            {this.renderIsLetterHint()}
            {this.renderIsUpperLetterHint()}
            {this.renderSixCharsHint()}
            {this.renderPwdEqualsHint()}

          <p className="text-error my-2">{this.state.error_message}</p>

          <div className="form-group my-3">
            <div className="col-md-12">
            	<div className="float-right">
              	<button className="btn btn-info btn-block btn-rounded" style={{borderRadius: '.25rem',padding: '5px'}} type="submit">Restablecer contraseña</button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div className="d-flex justify-content-center">
          <p className="text-error">{this.state.error_message}</p>
        </div>
      )
    }
  }

  render () {
    return (
    	<section id="wrapper">
    	  <div className="d-flex no-block justify-content-center align-items-center" style={{ backgroundColor: '#F2F4F5', position: 'relative', minHeight: '100vh'}}>
    	  	<div className='row justify-content-center'>
						<div className="col-12 mb-5">
							<a className="text-center db" href="https://www.komunidad.io/">
								<img src={ Logo } alt="Home" className="AppLogo" />
							</a>
						</div>
						<div className="col-md-10">
							<div className="card-container card border-0" style={{overflow: 'auto'}}>
								<div className="card-body">
									<form className="form-horizontal" id="loginform" onSubmit={this.handleSubmit}>

									{this.renderForm()}

									</form>
								</div>
							</div>
						</div>
					</div>
	      </div>
	    </section>
    )
  }
}

export default ResetPasswordView;
