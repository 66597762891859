/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, July 2020
 */

// Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KomunidadApi from '../api/api.js';

// Components
import KImage from './KImage';
import Swal from 'sweetalert2';
import {Modal} from 'react-bootstrap';
import RichTextEditor from 'react-rte';

/**
*  Show the list of [reviews](/#kreviewlist)
*  and if the user is a mentor can add review.
*/

class KCreateReview extends Component{
  constructor(props) {
    super(props)
    this.state = {
      review:'',
      reviewEditor: RichTextEditor.createEmptyValue(),
      reviewIsEmpty:true
    }
    this.getValueSafely         = this.getValueSafely.bind(this);
    this.onChangeForm           = this.onChangeForm.bind(this);
    this.onChangeReviewHtml     = this.onChangeReviewHtml.bind(this);
    this.sendReview             = this.sendReview.bind(this);
  }

  getValueSafely(user,property){
    if(user !== null ){
      if(user.hasOwnProperty(property)){
        return user[property];
      }else{
        return "-";
      }
    }
  }

  onChangeForm(data){
    this.setState({review:data.value});
  }

  onChangeReviewHtml(msg){
    this.setState({reviewEditor:msg});

    let msgIsEmpty =  !msg.getEditorState().getCurrentContent().hasText();
    this.setState({
      reviewIsEmpty:msgIsEmpty
    });
  }

  cleanState(){
    this.setState({
      reviewEditor: RichTextEditor.createEmptyValue(),
      reviewIsEmpty:true
    });
  }

  sendReview(){
    let review = this.state.reviewEditor.toString('html');;

    if(this.state.reviewIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Revisa el contenido de tu retroalimentación',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
    } else {
      KomunidadApi.createCompanyReview(review,this.props.companyId).then((res) => {
        if(res.success){
          /*this.fta_review.cancel();*/
          this.cleanState()
          this.props.getReviews();
          Swal.fire({
            type: 'success',
            title: 'Retroalimentación registrada!',
            text: 'Se te enviará un correo de confirmación.',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
  				this.props.closeReviewModal(false)
        }else{
          let error_res = 'Hubo un error el crear tu retroalimentación'
          if(review.length < 50) {
            error_res = 'Verifica que tu retroalimentación tenga al menos 50 caracteres';
          } else if(review.length > 2000) {
            error_res = 'Tu retroalimentación debe de tener menos de 2000 caracteres';
          }
          Swal.fire({
            type: 'error',
            title: 'Ups..',
            text: error_res,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          });
        }
      });
    }

  }


  render(){
    if(this.props.allowReviews){
      const user = this.props.user;

      const toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
          {label: 'Italic', style: 'ITALIC'},
          {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Heading Large', style: 'header-one'},
          {label: 'Heading Medium', style: 'header-two'},
          {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };

      return(
      	<Modal show={this.props.showModalReview}
              onHide={() => this.props.closeReviewModal(false)}
              onExit={() => this.props.closeReviewModal(false)}
              size="lg">
        <Modal.Header closeButton>
		      <div className="row w-100 card-body py-0">
            <div className="col-md-auto">
              <KImage src={this.getValueSafely(user,"profileUrlThumbnail")}
                    className={"kuImage mr-2 mt-2"}
                    alt={"user_img"}
                    type={"user_"+user.gender}/>
            </div>
            <div className="col m-auto">
              <Modal.Title>
                Redacta tu comentario
              </Modal.Title>
            </div>
          </div>
        </Modal.Header>
          <Modal.Body>
		        <div className="card-body">
		          <div className="activity-item">
		            <div className="review-input">
		              {/*<FormTextArea
		                ref={ref => this.fta_review = ref}
		                id="revisión"
		                model="review"
		                maxLength={2000}
		                value={this.state.review}
		                cssClass='resizable-text'
		                initialValue={''}
		                validationRules="minLen:50|maxLen:2000"
		                onChange={ change => { this.onChangeForm(change) } }
		                placeholder={'Agrega tu revisión'}
		              />*/}
                  <RichTextEditor
                    value={this.state.reviewEditor}
                    onChange={this.onChangeReviewHtml}
                    editorClassName="resizable-text"
                    toolbarConfig={toolbarConfig}
                  />
                  <div className="float-right mt-3">
                    <button className="btn btn-danger" onClick={() => this.props.closeReviewModal(false)}>Cancelar</button>
  		              <button className="btn btn-success btn-save" onClick={() => this.sendReview()}> Enviar </button>
		              </div>
                </div>
		          </div>
		        </div>
		      </Modal.Body>
		    </Modal>
      )
    }else{
      return null
    }
  }
}

KCreateReview.propTypes = {
  /** Id of the company to be review. */
  companyId         : PropTypes.string,

  /** Mentor object. */
  user              : PropTypes.object,

  /** When is true show the input to add the review. */
  allowReviews      : PropTypes.bool,

  /** Function to close modal*/
  handleReviewModal : PropTypes.func,

  /** When is true this show the modal*/
  showModalReview   : PropTypes.bool,

  /** Update reviews*/
  getReviews				: PropTypes.func
};

KCreateReview.defaultProps = {
  companyId         : '',
  user              : {},
  allowReviews      : true,
  handleReviewModal : () => {},
  showModalReview   : false,
  getReviews				: () => {}
};

export default KCreateReview;
