/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>, September 2021
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import { Tab, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom"

// Custom styling
import '../scss/pages/_style.css';
import '../scss/_sidebar.scss';
import '../scss/pages/pages.scss';
import '../scss/colors/default-dark.scss';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import TopSideBar from '../components/TopSideBar';
import Footer from '../components/Footer';

import { isUserProfileComplete } from '../utils/Utils.js';
import ProgramsResourcesPicker from '../components/ProgramsResourcesPicker';

class ProgramsLiveView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      me: {},
      iframe: "",
      iframe_2: "",
      schedule: "",
      partnership: "",
      liveViewLoadState: 'default',
      selectedId: [0],
      programName: [0],
      showWelcomeModal: true,
      loading: false,
      creoVideos: [],
      currentCreoVideo:{

      },
      opportunities:[],
      opportunitiesLoaded:false
    }

    this.handleResizeBar = this.handleResizeBar.bind(this);
    this.handleHeaderResize = this.handleHeaderResize.bind(this);
    this.handleSelectedTab = this.handleSelectedTab.bind(this);
    this.redirectToEditProfile = this.redirectToEditProfile.bind(this);
    this.onProgramSelected = this.onProgramSelected.bind(this);
    this.getLiveViewByProgram = this.getLiveViewByProgram.bind(this);
    this.getVideos = this.getVideos.bind(this);

  }

  componentDidMount() {
    KomunidadApi.getMe().then((res) => {
      if (res.valid_token) {
        if (!isUserProfileComplete(res.user)) {
          this.redirectToEditProfile();
        } else {
          this.setState({
            me: res.user,
            authorized: true,
            loading: true
          });
          if (localStorage.getItem('welcomeModal') === 'showed') {
            this.setState({ showWelcomeModal: false })
          }
        }
      } else {
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    this.setState({opportunitiesLoaded:false});
    KomunidadApi.getOpportunities().then((res) =>{
      const opportunities ={
        opportunity1Title: res?.opportunities?.opportunity1Title || '',
        opportunity1Text: res?.opportunities?.opportunity1Text || '',
        opportunity1Link: res?.opportunities?.opportunity1Link || '',
        opportunity1Image: res?.opportunities?.opportunity1Image || '',
        opportunity2Title: res?.opportunities?.opportunity2Title || '',
        opportunity2Text: res?.opportunities?.opportunity2Text || '',
        opportunity2Link: res?.opportunities?.opportunity2Link || '',
        opportunity2Image: res?.opportunities?.opportunity2Image || '',
        opportunity3Title: res?.opportunities?.opportunity3Title || '',
        opportunity3Text: res?.opportunities?.opportunity3Text || '',
        opportunity3Link: res?.opportunities?.opportunity3Link || '',
        opportunity3Image: res?.opportunities?.opportunity3Image || '',
      }
      const opportunitiesArray = [
          {
            title:opportunities.opportunity1Title,
            text:opportunities.opportunity1Text,
            link:opportunities.opportunity1Link,
            image:opportunities.opportunity1Image
          },
          {
            title:opportunities.opportunity2Title,
            text:opportunities.opportunity2Text,
            link:opportunities.opportunity2Link,
            image:opportunities.opportunity2Image
          },
          { title:opportunities.opportunity3Title,
            text:opportunities.opportunity2Text,
            link:opportunities.opportunity3Link,
            image:opportunities.opportunity3Image
          },
      ];
      this.setState({
        opportunities: opportunitiesArray.filter((o) => o.title && o.text && o.link)
      })
    }).catch((ex) =>{

    }).finally(() =>{
      this.setState({opportunitiesLoaded:true});
    })
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar();
    this.getVideos();
  }
  getVideos(){
    KomunidadApi.getCreoVideos().then((res) => {
      console.log(res);
      const data = res?.value?.items ? res?.value?.items : [];
      this.setState({creoVideos:data,currentCreoVideo:data[0]});
    }).catch((ex) =>{

    });
  }
  onProgramSelected(id, name) {
    let programId = id[0];
    let programName = name;
    this.setState({
      selectedId: id,
      programName: programName
    })
    // this.handleSelectedTab('schedule_tab')
    return this.getLiveViewByProgram(programId);
  }

  getLiveViewByProgram(programId) {
    KomunidadApi.getLiveViewByProgram(programId).then((res) => {
      if (res.success) {
        this.setState({ iframe: res.iframe, iframe_2: res.iframe_2, schedule: res.schedule, partnership: res.partnership, liveViewLoadState: 'success', loading: false });
      } else {
        this.setState({ iframe: "", iframe_2: "", schedule: "", partnership: "", liveViewLoadState: 'failed', loading: false })
      }
    });
  }


  redirectToEditProfile() {
    this.props.history.push('/profile/edit');
  }


  handleHeaderResize(width) {
    this.setState({
      sidebarVisible: width <= 500 ? false : true
    });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if (method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if (windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  handleSelectedTab(tab) {
    // if(tab === "event_tab"){
    //   window.location.reload();
    // }
    this.setState({
      keyEventTabs: tab
    });
  }

  renderSelectedTab(tab) {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="card mb-0">
              <div className="card-body">
                {
                  {
                    'event_tab': this.renderLiveIframe(),
                    'event_tab_2': this.renderLiveIframe_2(),
                    'schedule_tab': this.renderScheduleIframe(),
                    'partnership_tab': this.renderPartnershipIframe()
                  }[tab]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIframe() {
    return (
      <div className="container text-center pt-3">
        {/* <iframe className="live-frame" src="https://www.youtube.com/embed/0I_g2PR6j4k" title="YouTube video player" 
                 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                 allowfullscreen></iframe> */}
        <div className="container live-frame">
          {/* <div style={{padding:'40.25% 0 0 0', position:'relative'}}><iframe src="https://player.vimeo.com/video/591686665?h=74d08b57de" frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}}></iframe>
                    </div> */}
          {/* <iframe src="https://vimeo.com/live-chat/591686665/" width="100%" height="100%" frameborder="0"></iframe> */}
          <iframe src="https://player.vimeo.com/video/592943128?h=20e9370bd0" className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          {/*<iframe src="https://vimeo.com/live-chat/591686665/" className="chat-frame" frameborder="0" ></iframe>*/}
          {/* <div className="col-md-9">
                  </div>
                  <div className="col-md-3">
                  </div> */}
        </div>
      </div>
    )
  }
  renderNotAvailable() {
    return (
      <div className="text-center m-t-30">
        <h3>Este evento aún no tiene transmisión en vivo</h3>
        <i className="mdi mdi-play m-t-20 m-b-20" style={{ fontSize: '50px', color: "#c2c2c2" }}></i>
      </div>
    )
  }

  renderLiveIframe() {
    const iframe = this.state.iframe;
    if (this.state.loading === false && iframe !== "") {
      return (
        <div className="container text-center pt-3">
          <div className="live-frame">
            <iframe src={iframe} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      )
    } else {
      return this.renderNotAvailable()
    }
  }
  renderLiveIframe_2() {
    const iframe_2 = this.state.iframe_2;
    if (this.state.loading === false && iframe_2 !== "") {
      return (
        <div className="container text-center pt-3">
          <div className="live-frame">
            <iframe src={iframe_2} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      )
    } else {
      return this.renderNotAvailable()
    }
  }
  renderScheduleIframe() {
    const schedule = this.state.schedule;
    if (this.state.loading === false && schedule !== "") {
      if (this.state.selectedId[0] === "6165b4afd7024e217389f618") {
        return (
          <div className="container text-center pt-3">
            <div className="container" style={{ height: "4000px" }}>
              <iframe src={schedule} className="" style={{ height: "100%", width: "100%" }} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
          </div>
        )
      } else {
        return (
          <div className="container text-center pt-3">
            <div className="live-frame">
              <iframe src={schedule} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
          </div>
        )
      }
    } else {
      return this.renderNotAvailable()
    }
  }
  renderPartnershipIframe() {
    const partnership = this.state.partnership;
    if (this.state.loading === false && partnership !== "") {
      return (
        <div className="container text-center pt-3">
          <div className="live-frame">
            <iframe src={partnership} className="stream-frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      )
    } else {
      return this.renderNotAvailable()
    }
  }

  renderHelp() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white p-3">Da de alta tu negocio o proyecto</div>
            <div className="card-body">
              <div className="card-title">
                Agrega tu negocio o empresa. Si estas inscrito en algún programa registralo y visualiza el avance de tu emprendimiento.
                <a class="ml-1" href="https://www.youtube.com/watch?v=6ShFh398864" target="_blank">Ayuda</a>
              </div>
              <Link to="/create_company" className="btn btn-success btn-save">Agregar negocio</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Retroalimentación de aliados</div>
            <div className="card-body">
              <div className="card-title">
                Invita a aliados a que revisen tu empresa o proyecto y recibe retroalimentación puntual para el éxito de tu negocio.
                <a class="ml-1" href="https://www.youtube.com/watch?v=7sHtGzi9q-Y" target="_blank">Ayuda</a>
              </div>
              <Link to="/mentors" className="btn btn-success btn-save">Directorio de aliados</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Academia Komunidad</div>
            <div className="card-body">
              <div className="card-title">
                Inscríbete a los cursos de tu programa, revisa los entregables y conoce las mejores herramientas y técnicas para emprender o administrar un negocio.
                <a class="ml-1" href="https://www.youtube.com/watch?v=GxxNWlmRSOs" target="_blank">Ayuda</a>
              </div>
              <Link to="/courses" className="btn btn-success btn-save">Cursos y talleres</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-6 col-xs-12">
          <div className="card w-100">
            <div className="card-header bg-white">Contacto</div>
            <div className="card-body">
              <div className="card-title">
                Si tienes dudas o preguntas, ponte en contacto con tu director de programa.
              </div>
              <a href="#" onClick={() => this.openEmailModal(true)} className="btn bbtn-success btn-save">Enviar un mensaje</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderCreoVideos(){
      return(
          <div className={"flex"}>
            <div>
              <div className="card-header bg-white p-3">
                Revive los CREO
              </div>
              <div className={"yt-selector pt-3"}>
                {
                  this.state.creoVideos.map((video) =>{
                    const videoThumbnail =  video?.snippet?.thumbnails?.high?.url;
                    const isSelected = this.state.currentCreoVideo?.id === video?.id;
                    return (
                        <div className={`yt-video ${isSelected? 'yt-selected': ''}`} onClick={() => this.setState({currentCreoVideo:video})}>
                          <div className={`yt-thumbnail`} style={{backgroundImage:`url(${videoThumbnail})`}}></div>
                          <div className={"yt-title"}>
                            {isSelected ? '▶️ ':''}
                            {video?.snippet?.title}
                          </div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
            <div>
              <div className="card-header bg-white p-3">
                &nbsp;
              </div>
              <div className={'p-3'}>
                {
                  this.state.currentCreoVideo?.contentDetails?.videoId?
                      <iframe width="600" height="400" src={`https://www.youtube.com/embed/${this.state.currentCreoVideo?.contentDetails?.videoId}`}
                              title={this.state.currentCreoVideo?.snippet?.title} frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                      : null
                }
              </div>


            </div>


          </div>
      )
  }
  renderOpportunities(){
    return(
        <div className={"flex w-100"}>
          <div className={"w-100"}>
            <div className="card-header bg-white p-3">
              Oportunidades
            </div>
            {
              this.state.opportunities.length === 0 && this.state.opportunitiesLoaded ?
                  <div className={'p-4'}>
                    Por el momento, no hay oportunidades disponibles.
                  </div> : null
            }

            {
              this.state.opportunities.map((opportunity) =>{
                return (
                    <div className={'p-4'}>
                      <div><h5><b>{opportunity.title}</b></h5></div>
                      {
                        opportunity.image  ?
                            <div className={'opportunity_img'} style={{backgroundImage:`url(${opportunity.image})`}}>
                            </div> : null
                      }
                      <div><p>{opportunity.text}</p></div>
                      <div>Enlace:&nbsp;<a href={opportunity.link}>{opportunity.link}</a></div>
                    </div>
                )
              })
            }
          </div>
        </div>
    )
  }
  render() {
    if (this.state.authorized) {
      return (
        <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar
              user={this.state.me}
              visible={this.state.sidebarStatus}
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open' : '')}>
              <div className="container-fluid">
                {/* <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                            <h3 className="text-themecolor">INICIO</h3>
                        </div>
                    </div> */}
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-xs-12">
                    {/*<div className="row page-titles">*/}
                    {/*  <div className="col-md-6 align-self-center">*/}
                    {/*    <p>Selecciona el evento que quieres ver</p>*/}
                    {/*    <ProgramsResourcesPicker onSelect={this.onProgramSelected}*/}
                    {/*      multipleProgramSelected={this.state.selectedId} />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/* {this.renderLiveIframe()} */}
                    <div className="card">
                      {this.renderCreoVideos()}
                    </div>
                    <div className={"card"}>
                      {this.renderOpportunities()}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-xs-12">
                    {this.renderHelp()}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            {/* End Page wrapper  */}
          </div>

        </div>
      )
    } else {
      return (null)
    }
  }
}
export default ProgramsLiveView;
