/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx>, July 2020
 */

// Regular imports
import React from 'react';
import {Link} from "react-router-dom";
import KomunidadApi from '../../api/api.js';
import {validateEmail, validatePhone, validateStringLength, required} from '../../utils/Utils.js';
import Swal from 'sweetalert2';
import AdBlockComponent from '../../components/adblock/AdBlockComponent';
import {getProfileStatus} from '../../utils/Utils.js';

//version
import pkg from '../../../package.json'
// Custom styling
import '../../scss/pages/login-register-lock.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/pages.scss';
import '../../scss/pages/_login.scss';

// Images
import {getAppLogo} from "../../utils/Vendor.js";
import Background from "../../assets/images/background/login-register.jpg"


const Logo = getAppLogo();


class SignUpView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      register_name: '',
      register_email: '',
      register_last_name_f: '',
      register_last_name_m: '',
      register_phone: '',
      error_message: '',
      register_error_message: '',
      acceptedTerms: false,
      password: '',
      pwdConfirm: '',
      program: null,
      isValidDate: false,
      hasProgram:false,
      programId:'',
      userIsLoggedIn:false
    }

    this.handleChanges = this.handleChanges.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.hideErrorMessage = this.hideErrorMessage.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
    this.joinProgram = this.joinProgram.bind(this);
    this.passwordCheck = {
      isNumber: false,
      isLetter: false,
      isUpperLetter: false,
      sixCharacters: false,
      isEqual: false
    }
  }
  loadData(){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    KomunidadApi.getProgramName(programIdQuery).then((res) => {
      this.setState({
        program: res.program,
        isValidDate: this.isValidDate(res.program),
        hasProgram: true,
        programId:programIdQuery
      })
      KomunidadApi.getMe().then((res) => {
        if (res.valid_token) {
          this.setState({
            userIsLoggedIn:true,
            me:res.user
          })
          getProfileStatus(res.user).then(statusObj => {
            if(!this.state.hasProgram){
              this.props.history.push(statusObj.redirectPath);
            } // Only redirect if the user is not waiting to be joined to a program
          }).catch(err => {
            this.setState({error_message: 'Servicio no disponible. Intenta nuevamente más tarde.'});
          });
        }
      });
    }).catch((ex) =>{
    });
  }
  componentDidMount() {
    this.loadData();
  }
  isValidDate(program) {
    let today = Date.now();
    let endDate = new Date(program.end_date);

    endDate.setDate(endDate.getDate());

    let status = (endDate.getTime() > today) ? true : false;

    return status;

  }
  handleChanges(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  hideErrorMessage() {
    setTimeout(() => {
      this.setState({
        register_error_message: '',
        error_message: ''
      });
    }, 3000);
  }

  acceptTerms() {
    this.setState({
      acceptedTerms: !this.state.acceptedTerms
    });
  }

  swalSuccess(email, name,pathname = '/live') {
    Swal.fire({
      title: 'Registro exitoso',
      html: `¡Bienvenido(a) a Komunidad!`,
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => {
          Swal.hideLoading()
        }, 5000)
      },
    }).then(() => {
      // redirect with email
      this.props.history.push({
        pathname: pathname,
        // search: `?email=${email}&name=${name}`
      });
    })
  }

  swalWarning(email) {
    Swal.fire({
      title: 'El correo ya se encuentra registrado',
      html: 'Serás redirigido al inicio de sesión',
      type: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
      onOpen: () => {
        Swal.showLoading()
        setTimeout(() => {
          Swal.hideLoading()
        }, 3000)
      },
    }).then(() => {
      // redirect with email
      this.props.history.push({
        pathname: '/',
        search: `?email=${email}`
      });
    })
  }

  validatePwd(pwd, pwd2) {
    let messages = {
      invalid_pwd: 'La contraseña no es válida.',
      invalid_empty: 'La contraseña no puede ser vacía.',
      invalid_len_min: 'La contraseña debe tener al menos 6 caracteres.',
      invalid_len_max: 'La contraseña debe tener máximo 12 caracteres.',
      password_does_not_match: 'La contraseñas no coinciden'
    }
    // let re = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{5,12})\S$/;
    if (pwd.trim() === '') {
      return {
        isValid: false,
        reason: messages.invalid_empty
      }
    } else if (pwd.length < 6) {
      return {
        isValid: false,
        reason: messages.invalid_len_min
      }
    } else if (pwd.length > 12) {
      return {
        isValid: false,
        reason: messages.invalid_len_max
      }
    } else if (pwd !== pwd2) {
      return {
        isValid: false,
        reason: messages.password_does_not_match
      }
    }
    return {
      isValid: true,
      reason: null
    }
  }

  isValidForm() {
    if (!this.state.acceptedTerms) {
      return {
        isValidForm: false,
        error: 'Debes aceptar términos y condiciones para registrarte.'
      }
    }
    if (!required(this.state.register_name)) {
      return {
        isValidForm: false,
        error: 'Debes de escribir tu nombre'
      }
    }
    if (!required(this.state.register_last_name_f)) {
      return {
        isValidForm: false,
        error: 'Debes de escribir tu nombre paterno'
      }
    }
    if (!required(this.state.register_last_name_m)) {
      return {
        isValidForm: false,
        error: 'Debes de escribir tu nombre materno'
      }
    }

    if (!validateStringLength(this.state.register_name, 3)) {
      return {
        isValidForm: false,
        error: 'Tu nombre debe contener al menos 3 letras'
      }
    }
    if (!validateStringLength(this.state.register_last_name_f, 3)) {
      return {
        isValidForm: false,
        error: 'Tu apellido paterno debe contener al menos 3 letras'
      }
    }
    if (!validateStringLength(this.state.register_last_name_m, 3)) {
      return {
        isValidForm: false,
        error: 'Tu apellido materno debe contener al menos 3 letras'
      }
    }
    if (!validateEmail(this.state.register_email)) {
      return {
        isValidForm: false,
        error: 'El correo electrónico no es válido'
      }
    }
    if (!validatePhone(this.state.register_phone)) {
      return {
        isValidForm: false,
        error: 'El teléfono no es válido'
      }
    }
    const v=this.validatePwd(this.state.password, this.state.pwdConfirm);
    if (!this.validatePwd(this.state.password, this.state.pwdConfirm).isValid) {

      return {
        isValidForm: false,
        error: this.validatePwd(this.state.password, this.state.pwdConfirm).reason
      }
    }
    return {
      isValidForm: true,
      error: ''
    }
  }

  registerSubmit(event) {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    event.preventDefault();
    this.setState({register_error_message: ''})
    const {isValidForm, error} = this.isValidForm();
    if (!isValidForm) {
      this.setState({register_error_message: error})
      this.hideErrorMessage();
    }
    KomunidadApi.userRegisterNew(
        this.state.register_email,
        this.state.register_name,
        this.state.register_last_name_f,
        this.state.register_last_name_m,
        this.state.register_phone,
        this.state.password).then(res => {
      if (res.body.success) {
        if(this.state.hasProgram){
          this.loadData(); // Reload and show join
        }else{
          this.swalSuccess(this.state.register_email, this.state.register_name);
        }
        this.setState({
          register_name: '',
          register_email: '',
          register_last_name_f: '',
          register_last_name_m: '',
          register_phone: '',
        });
        this.acceptTerms();
        this.loadData();
      } else {
        if (res.status === 400) {
          this.swalWarning(this.state.register_email);
        }
        this.setState({
          register_name: '',
          register_email: '',
          register_last_name_f: '',
          register_last_name_m: '',
          register_phone: '',
          register_error_message: res.body.message_es
        });
        this.hideErrorMessage();
      }
    }).catch(err => {
      this.setState({register_error_message: 'Servicio no disponible. Intente de nuevo más tarde.'})
      this.hideErrorMessage();
    });


  }

  renderAdBlockDetect() {
    return (
        <AdBlockComponent/>
    );
  }
  renderIsNumberHint(){
    let divStyle = {
      display: "flex",
      alignItems: "center",
      marginLeft:"8px",
      height:"20px"
    }
    let iconStyle = {
      color:"#3ccccc",
      fontSize:"20px",
      marginRight:"5px",
      width:"20px"
    }
    let textStyle = {
      cursor:'default',
      fontSize:"13px"
    }
    if(this.passwordCheck.isNumber){
      return (
          <div style={divStyle}>
            <i className="mdi mdi-check" style={iconStyle}></i>
            <div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
              Al menos un número
            </div>
          </div>
      );
    }else{
      return (
          <div style={divStyle}>
            <div style={iconStyle}/>
            <div className={this.passwordCheck.isNumber ? "": "text-muted"} style={textStyle}>
              Al menos un número
            </div>
          </div>
      );
    }
  }
  renderIsLetterHint(){
    let divStyle = {
      display: "flex",
      alignItems: "center",
      marginLeft:"8px",
      height:"20px"
    }
    let iconStyle = {
      color:"#3ccccc",
      width:"20px",
      marginRight:"5px"
    }
    let textStyle = {
      cursor:'default',
      fontSize:"13px"
    }
    if(this.passwordCheck.isLetter){
      return (
          <div style={divStyle}>
            <i className="mdi mdi-check" style={iconStyle}></i>
            <div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
              Al menos una letra minúscula
            </div>
          </div>
      );
    }else{
      return (
          <div style={divStyle}>
            <div style={iconStyle}/>
            <div className={this.passwordCheck.isLetter ? "": "text-muted"} style={textStyle}>
              Al menos una letra minúscula
            </div>
          </div>
      );
    }
  }
  renderIsUpperLetterHint(){
    let divStyle = {
      display: "flex",
      alignItems: "center",
      marginLeft:"8px",
      height:"20px"
    }
    let iconStyle = {
      color:"#3ccccc",
      width:"20px",
      marginRight:"5px"
    }
    let textStyle = {
      cursor:'default',
      fontSize:"13px"
    }
    if(this.passwordCheck.isUpperLetter){
      return (
          <div style={divStyle}>
            <i className="mdi mdi-check" style={iconStyle}></i>
            <div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
              Al menos una letra mayúscula
            </div>
          </div>
      );
    }else{
      return (
          <div style={divStyle}>
            <div style={iconStyle}/>
            <div className={this.passwordCheck.isUpperLetter ? "": "text-muted"} style={textStyle}>
              Al menos una letra mayúscula
            </div>
          </div>
      );
    }
  }
  renderSixCharsHint(){
    let divStyle = {
      display: "flex",
      alignItems: "center",
      marginLeft:"8px",
      height:"20px"
    }
    let iconStyle = {
      color:"#3ccccc",
      width:"20px",
      marginRight:"5px"
    }
    let textStyle = {
      cursor:'default',
      fontSize:"13px"
    }
    if(this.passwordCheck.sixCharacters){
      return (
          <div style={divStyle}>
            <i className="mdi mdi-check" style={iconStyle}></i>
            <div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
              Al menos 6 caracteres
            </div>
          </div>
      );
    }else{
      return (
          <div style={divStyle}>
            <div style={iconStyle}/>
            <div className={this.passwordCheck.sixCharacters ? "": "text-muted"} style={textStyle}>
              Al menos 6 caracteres
            </div>
          </div>
      );
    }
  }
  renderPwdEqualsHint(){
    let divStyle = {
      display: "flex",
      alignItems: "center",
      marginLeft:"8px",
      height:"20px"
    }
    let iconStyle = {
      color:"#3ccccc",
      width:"20px",
      marginRight:"5px"
    }
    let textStyle = {
      cursor:'default',
      fontSize:"13px"
    }
    if(this.state.pwdConfirm){
      return (
          <div style={divStyle}>
            <i className="mdi mdi-check" style={iconStyle}></i>
            <div className={this.state.password === this.state.pwdConfirm ? "": "text-muted"} style={textStyle}>
              Contraseñas coinciden
            </div>
          </div>
      );
    }else{
      return (
          <div style={divStyle}>
            <div style={iconStyle}/>
            <div className={this.state.password === this.state.pwdConfirm ? "": "text-muted"} style={textStyle}>
              Contraseñas coinciden
            </div>
          </div>
      );
    }
  }
  setPassword(password) {
    if(password.target.value.match(/\d+/)) {
      this.passwordCheck.isNumber = true;
    } else {
      this.passwordCheck.isNumber = false;
    }
    if(password.target.value.match(/[A-Z]+/)) {
      this.passwordCheck.isUpperLetter = true;
    } else {
      this.passwordCheck.isUpperLetter = false;
    }
    if(password.target.value.match(/[a-z]+/)) {
      this.passwordCheck.isLetter = true;
    } else {
      this.passwordCheck.isLetter = false;
    }
    if(password.target.value.match(/\w{6,12}/)) {
      this.passwordCheck.sixCharacters = true;
    } else {
      this.passwordCheck.sixCharacters = false;
    }
  }
  renderClosedProgram(){
    const program = this.state.program;
    return (
        <div className="landingView" >
          <div className="page-wrapper" style={{ minHeight: '584px', padding: 0 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card" style={{ margin: 0 }}>
                    <div className="card-body">
                      <div className="error-box">
                        <div className="error-body text-center">
                          <h3 className="text-uppercase error-subtitle">Las inscripciones al programa {program?.name} han cerrado</h3>
                          <p className="mt-4 mb-4">Te invitamos a conocer otros programas disponibles
                            <Link className="mx-1" style={{ fontSize: '14px', paddingTop: '5px' }} to={'/'}>
                              Inicia sesión
                            </Link>
                          </p>
                          <div className="mb-4">¿No tienes cuenta?
                            <Link className="mx-1" style={{ fontSize: '14px', paddingTop: '5px' }} to={'/sign-up'}>
                              Registrate
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
  formatLinkPrograms(idProgram, joined_programs) {
    let programs = joined_programs;
    let programsIds = programs.map(program => {
      return program.program._id;
    })

    if (!programsIds.includes(idProgram)) {
      programsIds.push(idProgram);
    }

    return programsIds.join("|");
  }
  joinProgram() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let programIdQuery = params.get('programid');
    let userId = this.state.me._id;
    let joinedPrograms = this.state.me.roles.includes("mentor") ? this.state.me.mentored_programs : this.state.me.joined_programs;
    let programsArray = this.formatLinkPrograms(programIdQuery, joinedPrograms)
    let join_as_mentor = this.state.me.roles.includes("mentor") ? "true" : "false";

    KomunidadApi.joinPrograms(userId, programsArray, programIdQuery, join_as_mentor).then((res) => {
      if (res.body.success) {
        Swal.fire({
          title: join_as_mentor === "true" ? 'Te has inscrito correctamante como aliado del programa' : 'Te has inscrito con éxito',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'success',
          timer: 1250,
          showConfirmButton: false
        }).then(() => {
          if (join_as_mentor === "true") {
            this.props.history.push({
              pathname: '/companies',
            })

          } else {
            this.props.history.push({
              pathname: '/live'
            })
          }
        });
      } else {
        if (res.status === 403) {
          Swal.fire({
            title: 'Inscripciones cerradas',
            text: res.body.error_es,
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          }).then(() => {
            this.redirectHome();
          });
        } else {
          Swal.fire({
            title: 'Ocurrió un error',
            text: "Inténtalo más tarde",
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
            type: 'error',
          }).then(() => {
            this.redirectHome();
          });
        }
      }
    });
  }
  render() {
    let program = this.state?.program;
    if(this.state.hasProgram && !this.state.isValidDate){
      this.renderClosedProgram();
    }
    return (
        <div id={'wrapper'} className="d-flex no-block justify-content-center align-items-center"
             style={{
               position: 'relative',
               minHeight: '100vh',
               backgroundSize:'cover',
               backgroundImage: `url(${program?.bannerUrlThumbnail ? program?.bannerUrlThumbnail : Background})` }}>
          <div className="d-flex no-block justify-content-center align-items-center"
               style={{ position: 'relative', minHeight: '100vh'}}>

            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                <a className="text-center db" href="https://www.komunidad.io/">
                  <img src={Logo} alt="Home" className="AppLogo"/>
                </a>
              </div>
              <div className="col-md-8">
                <div className="card container border-0">
                  {/*Join a program*/}
                  {
                    this.state.userIsLoggedIn  && this.state.hasProgram ?
                        <div className="card-body mb-3">
                          <h2 className="mb-3">¡Bienvenido a <span className="font-bold text-dark">{program?.name}</span>!</h2>
                          <p>{program?.message}</p>
                          <div className="form-group row justify-content-start mb-3">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                              <button className="btn btn-info btn-block btn-rounded" style={{ borderRadius: '.25rem', minWidth: '150px' }} onClick={this.joinProgram}>Inscribirse</button>
                            </div>
                          </div>
                        </div>
                        :null
                  }

                  {/*Register*/}
                  {
                    !this.state.userIsLoggedIn ?
                        <div className="card-body">
                          <h2 className="m-0"><b>¡Regístrate aquí!</b></h2>
                          <div className="form-group row my-3">
                            <div className="col-md-12">
                              <div className="float-left" style={{color: '#67757c', fontSize: '14px'}}>
                                Si ya tienes una cuenta, da clic &nbsp;
                                <a href={this.state.hasProgram ? `/login?programid=${this.state.programId}` : '/'}>
                                  aquí.
                                </a>
                              </div>
                            </div>
                          </div>
                          <form className="form-horizontal" id="loginform" onSubmit={this.registerSubmit}>
                            <div className="form-group row mb-3">
                              <div className="col-md-4 mb-3">
                                {/* <label>Nombre</label> */}
                                <input className="form-control" id="register_name"
                                       value={this.state.register_name} onChange={this.handleChanges}
                                       placeholder="Nombre" type="text"/>
                              </div>
                              <div className="col-md-4 mb-3">
                                {/* <label>Correo electrónico</label> */}
                                <input className="form-control" id="register_last_name_f"
                                       value={this.state.register_last_name_f}
                                       onChange={this.handleChanges} placeholder="Apellido paterno"
                                       type="text"/>
                              </div>
                              <div className="col-md-4 mb-3">
                                {/*<label>Correo electrónico</label>*/}
                                <input className="form-control" id="register_last_name_m"
                                       value={this.state.register_last_name_m}
                                       onChange={this.handleChanges} placeholder="Apellido materno"
                                       type="text"/>
                              </div>
                              <div className="col-md-8 mb-3">
                                {/* <label>Correo electrónico</label> */}
                                <input className="form-control" id="register_email"
                                       value={this.state.register_email} onChange={this.handleChanges}
                                       placeholder="tucorreo@tucorreo.com" type="email"/>
                              </div>
                              <div className="col-md-4">
                                {/* <label>Correo electrónico</label> */}
                                <input className="form-control" id="register_phone"
                                       value={this.state.register_phone} onChange={this.handleChanges}
                                       placeholder="Teléfono" type="text"/>
                              </div>
                              <div className={'separator'}>

                              </div>
                              <div className={"col-md-8 mt-2"}>
                                <input type="password"
                                       id="password"
                                       ref={(input) => {
                                         this.password = input;
                                       }}
                                       placeholder="Escribe una contraseña"
                                       value={this.state.password}
                                       onChange={change => {
                                         this.handleChanges(change);
                                         this.setPassword(change);
                                       }}
                                       className="form-control"
                                       onKeyPress={
                                         event => {
                                           if (event.key === "Enter") {
                                             // this.pwdConfirmRef.current.focus();
                                           }
                                         }
                                       }
                                       onKeyDown={
                                         event => {
                                           if (event.key === "Tab") {

                                           }
                                         }
                                       }
                                />
                                <input type="password"
                                       id="pwdConfirm"
                                       ref={this.pwdConfirmRef}
                                       placeholder="Confirma tu contraseña"
                                       value={this.state.pwdConfirm}
                                       onChange={change => {
                                         this.handleChanges(change);
                                       }}
                                       className="form-control m-t-20"
                                       onKeyPress={
                                         event => {
                                           if (event.key === "Enter") {
                                             // this.checkConfirmPwd()
                                           }
                                         }
                                       }
                                       onKeyDown={
                                         event => {
                                           if (event.key === "Tab") {
                                             // this.checkConfirmPwd()
                                           }
                                         }
                                       }
                                />
                              </div>
                              <div className={"col-md-4 mt-2"}>
                                <label className="font-weight-bold">Tu contraseña debe contener:</label>
                                {this.renderIsNumberHint()}
                                {this.renderIsLetterHint()}
                                {this.renderIsUpperLetterHint()}
                                {this.renderSixCharsHint()}
                                {this.renderPwdEqualsHint()}
                              </div>

                            </div>
                            <p className="text-error my-1">{this.state.register_error_message}</p>

                            {/*<div className="form-group m-0">
                  <div className="col-xs-12">
                    {this.renderAdBlockDetect()}
                  </div>
                </div>*/}

                            <div className="form-group">
                              <div className="my-2">
                                <input
                                    type="checkbox"
                                    id="md_checkbox"
                                    className="filled-in chk-col-light-blue"
                                    value={this.state.acceptedTerms}
                                    onChange={this.acceptTerms}
                                    checked={this.state.acceptedTerms}
                                    name='terms_and_conditions'/>
                                <label htmlFor="md_checkbox">
                                  <label style={{fontWeight: 'normal', fontSize: '14px'}}>
                                    Estoy de acuerdo con los <a
                                      href="https://komunidad.io/aviso.html" rel="noopener noreferrer"
                                      target="_blank">términos de uso</a> y la
                                    <a href="https://komunidad.io/aviso.html"
                                       rel="noopener noreferrer" target="_blank"> política de
                                      privacidad.</a>
                                  </label>
                                </label>
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <div className="col-md-12">
                                <Link className="float-left"
                                      style={{fontSize: '14px', paddingTop: '5px'}} to={{
                                  pathname: '/forgot-password',
                                  params: {email: this.state.email}
                                }}>
                                  ¿Olvidaste tu contraseña?
                                </Link>
                                <div className="float-right">
                                  <button
                                      className={`btn ${this.state.acceptedTerms ? 'btn-info' : 'btn-aux'} btn-block btn-rounded`}
                                      disabled={false}
                                      style={{borderRadius: '.25rem', padding: '5px'}}
                                      type="submit">Regístrate
                                  </button>
                                </div>
                              </div>
                            </div>

                          </form>

                          <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
                            <div className="container text-muted text-center mb-2" style={{fontSize: '14px'}}>
                              <p style={{color: '#67757c', display: 'inline'}}>Komunidad IO © 2024 -
                                v{pkg.version}</p>
                            </div>
                            <div className="text-center row justify-content-md-center">
                              <div className="col col-lg-8" style={{bottom: '12px', fontSize: '14px'}}>
                                <a rel="noopener noreferrer" href="https://www.komunidad.io"
                                   target="_blank">Nosotros</a> |
                                <a rel="noopener noreferrer" href="https://komunidad.freshdesk.com/support/home"
                                   target="_blank"> Soporte</a> |
                                <a rel="noopener noreferrer" href="https://komunidad.io/aviso.html"
                                   target="_blank"> Aviso de Privacidad</a> |
                                <a rel="noopener noreferrer" href="https://komunidad.io/aviso.html"
                                   target="_blank"> Términos y Condiciones</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default SignUpView;
