/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano && Juan Hernandez <Isra_darrell@startuplab.mx> && <juan@startuplab.mx>, Febrero 2020
 */
import React from 'react';
import KomunidadApi from '../api/api.js';
import PropTypes from 'prop-types';
import Select from 'react-select';

//Other imports
import { orderBy } from 'lodash';

class ProgramCoursesPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programs:[],
      multipleOptions:[],
      multipleProgramSelected:[]
    }
    this.setMultipleOptions     = this.setMultipleOptions.bind(this);
    this.handleMultipleSelect   = this.handleMultipleSelect.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.multipleProgramSelected !== null){
      if ((state.multipleProgramSelected.length === 0) && (props.multipleProgramSelected !== state.multipleProgramSelected)){
        let _selected = [];
        if(state.multipleOptions.length > 0){

          //Filter the multiple option array to set the selected programs
          _selected = state.multipleOptions.filter(function(option){
            return props.multipleProgramSelected.indexOf(option.value) !== -1;
          });

        }
        return {
          multipleProgramSelected: _selected
        };
      }
    }
    // Return null if the state hasn't changed
    return null;
  }

  sortProgramsByDate(programs){
    if(programs.length > 0){
      programs = orderBy(programs, ['created_at'], ['desc']);
      return programs;
    }else{
      return [];
    }
  }

  componentDidMount(){
    if(!this.props.freeMode){
      KomunidadApi.getMyPrograms().then( (res) => {
        if(res.success){
         let sortedPrograms = this.sortProgramsByDate(res.programs)
          this.setState({
            programs:sortedPrograms,
          });
          if (sortedPrograms.length > 0) {
            sortedPrograms.unshift({_id:'public', name:'Cursos gratis en Komunidad'});
            if(this.props.showMyCourses){
              sortedPrograms.unshift({_id:'mine', name:'Mis cursos'});
            }
            let selectedProgram = {};
            if (sortedPrograms.length > 1) {
              selectedProgram = {
                value: sortedPrograms[1]._id,
                label: sortedPrograms[1].name,
              };
            }else{
              selectedProgram = {
                value: sortedPrograms[0]._id,
                label: sortedPrograms[0].name,
              };
            }
            this.handleMultipleSelect(selectedProgram);
          }
          this.setMultipleOptions(sortedPrograms);
        }
      });
    }else{
      let programs = [];
      programs.push({_id:'public', name:'Cursos gratis en Komunidad'});
      let firstProgram = {
        value: programs[0]._id,
        label: programs[0].name,
      };
      this.setState({
        programs:programs,
      });
      this.handleMultipleSelect(firstProgram);
      this.setMultipleOptions(programs);
    }
  }

  setMultipleOptions(programs){
    let options = programs.map(program => ({ value: program._id, label: program.name }));
    this.setState({multipleOptions:options});
  }

  handleMultipleSelect(selectedOption){
    this.setState({multipleProgramSelected:selectedOption});
    let sOption = [selectedOption] || [];
    let programsIds = sOption.length > 0 ? sOption.map( option => option.value ) : [];

    this.props.onSelect(programsIds,selectedOption.label);
  }

  render(){
    return(
        <Select
          value={this.state.multipleProgramSelected}
          onChange={this.handleMultipleSelect}
          options={this.state.multipleOptions}
          placeholder={'Selecciona un programa'}
        />
    )
  }
}

ProgramCoursesPicker.propTypes = {
  onSelect                : PropTypes.func,
  showMyCourses           : PropTypes.bool,
  multipleProgramSelected : PropTypes.array,
  freeMode                : PropTypes.bool,
}
ProgramCoursesPicker.defaultProps = {
  onSelect                : (program) => {},
  showMyCourses           : true,
  multipleProgramSelected : [],
  freeMode                : false,
}

export default ProgramCoursesPicker;
