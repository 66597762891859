/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, September 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import { renderFullNameOrEmail } from '../utils/Utils.js';
//components
import KImage from '../components/KImage';
import KMentorSidebar from './KMentorSidebar';

/**
*   Display the information of the mentor used by the component [KMentorsList](/#kmentorslist).
*   Also display the button to delete a mentor.
*/

class KMentorsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      showProfileModal:false,
    };
    this.onClickDeleteMentors = this.onClickDeleteMentors.bind(this);
    this.handleOpenModal       = this.handleOpenModal.bind(this);
    this.handleProfileModal    = this.handleProfileModal.bind(this);
  }

  onClickDeleteMentors(mentor){
    this.props.onClickDeleteMentors(mentor);
  }

  handleOpenModal(value){
    this.setState({showModal: value});
  }

  handleProfileModal(value){
    this.setState({showProfileModal: value});
  }

  renderLinkein(mentor){
    if(mentor.social_linkedin !== null && mentor.social_linkedin !== ''){
      return(
        <a href={mentor.social_linkedin} target="_blank" rel="noopener noreferrer">
          <div className='btn btn-secondary'>
            <i className="mdi mdi-linkedin-box" style={{fontSize: '22px'}}></i>
          </div>
        </a>
      )
    }else{
      return null
    }
  }

  renderInvitationStatus(status){
    switch (status) {
      case "invited_by_email":
        return "  Invitado, pendiende de crear cuenta";
      case "invited":
        return "  Invitado, pendiente de confirmación";
      default:
      return null
    }
  }

  renderDeleteButton(mentor){
    if(this.props.isUserOwner && mentor.status !== "accepted"){
      if(mentor.is_owner){
        return (
          <div></div>
        );
      }else{
        return (
          <div className="btn btn-danger" onClick={() => this.onClickDeleteMentors(mentor)}>Borrar aliado</div>
        );
      }
    }else{
      return (
        <div></div>
      );
    }
  }

  renderEmail(mentor){
    return ( mentor.name === null ? '' : '(' + mentor.email + ")");
  }

  render() {

    let emptyUser = {
      name:'Usuario no encontrado',
      email:'Usuario no encontrado',
      gender:'',
      last_name_father:null,
      last_name_mother:null,
      social_linkedin:null,
    };

    if(this.props.mentor.status === "invited_by_email"){
      emptyUser.name = this.props.mentor.invited_email;
    }

    const mentor = this.props.mentor;
    const user   = this.props.mentor.user !== null ? this.props.mentor.user : emptyUser;
    return(
      <tr>
          <td style={{width:50}}>
            <span className="round roundTransparent">
              <KImage src={user.profileUrlThumbnail} 
              className={"kuImage  cursor-pointer"} 
              type={'user_'+user.gender} 
              alt={"user_img"}
              onClick={() => this.handleProfileModal(true)}
            />
            </span>
          </td>
          <td>
            <div class="row">
              <div class="col-auto px-1">
                <h6><b>{renderFullNameOrEmail(user)} </b></h6>
              </div>
              <div class="col-md px-1">
                <h6>{this.renderEmail(user)}</h6>
              </div>
            </div>
            <div class="row">
                <small className="text-muted">{this.renderInvitationStatus(mentor.status)}</small>
            </div>
          </td>
          <td>
            {this.renderLinkein(user)}
          </td>
          <td>
            {this.renderDeleteButton(mentor)}
          </td>
          <KMentorSidebar
             showSidebar={this.state.showProfileModal}
             closeSidebar={this.handleProfileModal}
             isMyMentor={this.props.isMyMentor}
             mentor={this.props.mentor.user}
             >
          </KMentorSidebar>
      </tr>
    )
  }
}

KMentorsRow.propTypes = {
  /** Mentor to be render */
  mentor               : PropTypes.object,
  /** Callback on click the button delete mentor*/
  onClickDeleteMentors : PropTypes.func,
  /** When is true the user is owner*/
  isUserOwner          : PropTypes.bool
};

KMentorsRow.defaultProps = {
  mentor               : {},
  onClickDeleteMentors : ()=>{},
  isUserOwner          : false
};

export default KMentorsRow;
