/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, November 2020
 */

// Regular imports
import React from 'react';
import ReactModal from 'react-modal';
import { Link as RouteLink} from "react-router-dom";

// Custom styling
import '../../scss/pages/_landing.scss';
import '../../scss/pages/_landing-modal.scss';
import '../../scss/components/_navbar.scss';
import '../../scss/pages/_landing-carousel.scss';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import '../../scss/colors/icons.scss';

import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();

class EventView extends React.Component {

  render() {
    return (
      <div id="main-wrapper">
        {/* Landing page Header */}
        <div className="col-md navbar-header">
          <img src={Logo} className="light-logo AppLogoHeader" alt="Komunidad"/>
        </div>

        {/* Main page wrapper */}
        <div id="agenda-viewport" className="container-fluid pt-0 h-100">
          {/* Start Page Content */}
          <div id="scheed-agenda" style={{height: "80vh"}}>
              <iframe
              src="https://tockify.com/creomx/agenda"
              title="Creo MX 2020"
              // m-top = 70, m-bottom = 60, ui = 82 ... 212px
              height="100%"
              width="100%"
              frameBorder="0"
              ></iframe>
          </div>
          {/* End PAge Content */}
       </div>
      </div>
    )
  }
}

export default EventView;
