/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernández <juan@startuplab.mx>, July 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../api/api.js';
import RichTextEditor from 'react-rte';
import Swal from 'sweetalert2';

import { isUserProfileComplete } from '../utils/Utils.js';

//Components
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import CompanyPicker from '../components/CompanyPicker';
import ProgramOfCompanyPicker from '../components/ProgramOfCompanyPicker';
import Select from 'react-select';


import FormInput from '../components/form/FormInput';



class ProgressReportViewOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: true,
      me:{},
      company:null,
      program:null,
      phase:'',
      startDate:null,
      endDate:null,
      activitiesIsEmpty:true,
      problemsIsEmpty:true,
      goalsIsEmpty:true,
      describeActivities:RichTextEditor.createEmptyValue(),
      describeProblems:RichTextEditor.createEmptyValue(),
      nextPhaseGoals:RichTextEditor.createEmptyValue(),
      pdf_file:'',
      sendCopy:true,
      phaseOptions: [
        { value: 'idea', label: 'Idea' },
        { value: 'prototype', label: 'Prototipo' },
        { value: 'startup', label: 'Startup' },
        { value: 'pyme', label: 'PyME' },
        { value: 'scaleup', label: 'Scale up' },
      ]
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.onCompanySelected             = this.onCompanySelected.bind(this);
    this.onClickSend                   = this.onClickSend.bind(this);
    this.onChangeInput                 = this.onChangeInput.bind(this);
    this.onChangeForm                  = this.onChangeForm.bind(this);
    this.onChangePhaseSelect           = this.onChangePhaseSelect.bind(this);
    this.onChangeActivitiesHtml        = this.onChangeActivitiesHtml.bind(this);
    this.onChangeProblemsHtml          = this.onChangeProblemsHtml.bind(this);
    this.onChangeGoalsHtml             = this.onChangeGoalsHtml.bind(this);
    this.fileUpload                    = this.fileUpload.bind(this);
    this.cleanState                    = this.cleanState.bind(this);
    this.onProgramSelected             = this.onProgramSelected.bind(this);

    // MAXIMUM UPLOAD SIZE
		this.MAXIMUM_UPLOAD_SIZE = 5 * 1024 * 1024; //5MB
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          this.setState({
            me:res.user,
            authorized:true
          });
          //get programs
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true
        })
      } else {
        this.setState({
          sidebarStatus: false
        })
      }
    }
  }
  sendProgressEmail(company_id,phase,startDate,endDate,describeActivities,describeProblems,nextPhaseGoals,cc_me){
    KomunidadApi.progressReport(phase,startDate,endDate,describeActivities,describeProblems,nextPhaseGoals,company_id,this.state.program._id,this.state.pdf_file,cc_me).then((res) => {
      if(res.success){
        this.cleanState();
        Swal.fire({
          type: 'success',
          title: 'Tu avance fue enviado',
          text: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });

      }else{
        Swal.fire({
          type: 'error',
          title: 'Error al enviar tu avance',
          text: 'Hubo un error al enviar tu mensaje. Intenta de nuevo más tarde.',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  onCompanySelected(companies){
    let company = companies.company;
    let program = null;
    this.setState({program:[]});

    if(company !== null){
      if(company.hasOwnProperty("programs")){
        if(company.programs.length === 1){
          //auto select the first program if there is only one
          program = company.programs[0].program;
        }
      }
    }
    this.setState({
      company:companies.company,
      program:program
    });
  }
  onProgramSelected(p){
    let program = p.program;
    this.setState({
      program:program
    });
  }
  onClickSend(){
    let company             = this.state.company;
    let phase               = this.state.phase;
    let startDate           = this.state.startDate;
    let endDate             = this.state.endDate;
    let describeActivities  = this.state.describeActivities.toString('html');
    let describeProblems    = this.state.describeProblems.toString('html');
    let nextPhaseGoals      = this.state.nextPhaseGoals.toString('html');
    let sendCopy = this.state.sendCopy ? 'true' : 'false';

    if(company === null){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Selecciona una empresa',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false
    }
    if(phase.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'La fase no puede estar vacío',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(startDate.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'La fecha inicial no puede estar vacía',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(endDate.trim() === ""){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'La fecha final no puede estar vacía',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.activitiesIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Describe las actividades realizadas',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.problemsIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Describe los problemas que tuviste',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }
    if(this.state.goalsIsEmpty){
      Swal.fire({
        type: 'error',
        title: 'Datos incompletos',
        text: 'Escribe cuales son tus metas para la siguiente fase',
        customClass: {
          confirmButton: 'swalBtnTeal',
          title: 'swal-custom-title',
        },
      });
      return false;
    }

    return this.sendProgressEmail(company._id,phase,startDate,endDate,describeActivities,describeProblems,nextPhaseGoals,sendCopy);
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }

  onChangeForm(data){
    this.setState({
      [data.model]:data.value
    });
  }

  onChangePhaseSelect(data){
    this.setState({ phase: data.label });
  }

  onChangeActivitiesHtml(msg){
    this.setState({describeActivities:msg});

    let msgIsEmpty =  !msg.getEditorState().getCurrentContent().hasText();
    this.setState({
      activitiesIsEmpty:msgIsEmpty
    });
  }

  onChangeProblemsHtml(msg){
    this.setState({describeProblems:msg});

    let msgIsEmpty =  !msg.getEditorState().getCurrentContent().hasText();
    this.setState({
      problemsIsEmpty:msgIsEmpty
    });
  }

  onChangeGoalsHtml(msg){
    this.setState({nextPhaseGoals:msg});

    let msgIsEmpty =  !msg.getEditorState().getCurrentContent().hasText();
    this.setState({
      goalsIsEmpty:msgIsEmpty
    });
  }

  //setState for file upload
	fileUpload(file) {
		if (file.target.files[0].size > this.MAXIMUM_UPLOAD_SIZE) {
			Swal.fire({
				title: 'Archivo demasiado grande',
				text: "El archivo debe de ser menor a 5MB.",
				type: 'error',
				customClass: {
					confirmButton: 'swalBtnTeal',
					title: 'swal-custom-title',
				},
			});
			this.pdfInput.value = '';
		} else {
			this.setState({
        pdf_file: file.target.files[0],
			});
		}

	}

  cleanState(){
    this.picker.removeSelections();

    this.setState({
      company:null,
      program:null,
      phase:'',
      startDate:'',
      endDate:'',
      activitiesIsEmpty:true,
      problemsIsEmpty:true,
      goalsIsEmpty:true,
      describeActivities:RichTextEditor.createEmptyValue(),
      describeProblems:RichTextEditor.createEmptyValue(),
      nextPhaseGoals:RichTextEditor.createEmptyValue(),
      pdf_file:'',
      sendCopy:true
    });
  }
  renderProgram(){
    let programs = [];
    let showPicker = false;
    if(this.state.company !== null){
      if(this.state.company.hasOwnProperty("programs")){
        programs = this.state.company.programs.map( p=> p.program);
        if(this.state.company.programs.length > 1){
          showPicker = true;
        }
      }
    }
    if(showPicker){
      return(
        <ProgramOfCompanyPicker  ref={ref => this.programOfCompanyPicker = ref}
                        programs={programs}
                        onSelect={(company) => {this.onProgramSelected(company)}}
                        companySelected={this.state.company}
                        onProgramSelected={this.state.program}>

        </ProgramOfCompanyPicker>
      )
    }else{
      return(
        null
      )
    }

  }
  renderControls(){
    //possible states:
    // a)No company is selected, return null
    // a)The company is not in a program. Show error
    //
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ],
      BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
      ]
    };
    const customStyles = {
      container: (provided) => ({
          ...provided,
          zIndex: 100,
        }),
    }
    let companyHasPrograms = false;
    if(this.state.company !== null){
      if(this.state.company.hasOwnProperty("programs")){
        if(this.state.company.programs.length > 0){
          companyHasPrograms = true;
        }
      }
    }
    if(this.state.company === null){
      return (null);
    }
    if(!companyHasPrograms){
      return(
        <div>Tu empresa no tiene programas</div>
      )
    }

    //If the company has programs, but the selected program has finished
    if (this.state.program !== null) {
      var today = Date.now();
      var startDate = new Date(this.state.program.start_date);
      var endDate = new Date(this.state.program.end_date);
      endDate.setDate(endDate.getDate() + 1);
      if ((today >= startDate.getTime()) && (today <= endDate.getTime())) {
        //ok
      } else {
        return (
          <div>El programa {this.state.program.name} no acepta reportes de avance porque ya ha finalizado.</div>
        )
      }
    }
    //}

    //}
    if(this.state.program === null){ //If the company is selected but not the program
      return (
        null
      )
    }
    return (
      <div>

        <div className="form-group">
          <label>Etapa en la que se encuentran</label>
          <Select
            value={this.state.phase.value}
            onChange={ change => {this.onChangePhaseSelect(change) } }
            options={this.state.phaseOptions}
            placeholder={"Selecciona la etapa en que estuviste trabajando"}
            // styles={customStyles}
          />
        </div>

        <div className="form-group">
          <label>Fechas que comprende este avance</label>
          <div className="row">
            <div className="col-md">
              <FormInput
                id="fechaInicio"
                model="startDate"
                inputType="date"
                value={this.state.startDate}
                maxLength={250}
                showCounter={false}
                validationRules="maxLen:250"
                onChange={ change => {this.onChangeForm(change) } }
                />
            </div>
            <div className="col-md">
              <FormInput
                id="fechaFin"
                model="endDate"
                inputType="date"
                value={this.state.endDate}
                maxLength={250}
                showCounter={false}
                validationRules="maxLen:250"
                onChange={ change => {this.onChangeForm(change) } }
                />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Describe las principales actividades que realizaron</label>
        </div>
          <RichTextEditor
            value={this.state.describeActivities}
            onChange={this.onChangeActivitiesHtml}
            className="email-editor"
            toolbarConfig={toolbarConfig}
          />

        <div className="form-group m-y-10 m-t-20">
          <label>¿Cuál fue el mayor reto que tuvieron en este tiempo?</label>
        </div>
          <RichTextEditor
            value={this.state.describeProblems}
            onChange={this.onChangeProblemsHtml}
            className="email-editor"
            toolbarConfig={toolbarConfig}
          />

        <div className="form-group m-y-10 m-t-20">
          <label>¿Cuáles son las metas u objetivos a lograr para el siguiente avance?</label>
        </div>
          <RichTextEditor
            value={this.state.nextPhaseGoals}
            onChange={this.onChangeGoalsHtml}
            className="email-editor"
            toolbarConfig={toolbarConfig}
          />

        <div className="form-group m-y-10 m-t-20">
          <label>En caso de ser necesario adjunta un archivo con tus evidencias y/o entregables</label>
        </div>
          <input type="file" ref={ref=> this.pdfInput = ref} onChange={this.fileUpload} accept="application/pdf"/>
					<small className="form-text text-muted">Sube tu archivo enformato PDF. (Máximo 5MB).</small>

        <label className="m-t-20">
          <input
            type="checkbox"
            id="sendCopy"
            className='filled-in chk-col-light-blue'
            name='sendCopy'
            value={this.state.sendCopy}
            checked={this.state.sendCopy}
            onChange={this.onChangeInput} />
          <label htmlFor="sendCopy" className={this.state.sendCopy ? "" : "text-muted"}>Enviarme copia del correo</label>
        </label>

        <br/>

        <button className="btn btn-info btn-save m-t-20 d-inline-flex align-self-center" onClick={() => this.onClickSend()}>
          <i className="mdi mdi-email mr-2"></i>
          Enviar
        </button>
      </div>

    )
  }
  render() {
    if (this.state.authorized) {
      return (
        <div className={"color-gray"}>
            <div className="container-fluid">

            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                <h3 className="text-themecolor">Reporte de avances</h3>
                </div>
            </div>
            <div className="row view-subtittle">
                <div className="col-md-9">
                <p>En esta sección te invitamos a subir tus avances de tu empresa lo cual te ayudará a tu equipo y a nosotros a medir el avance que vas teniendo en el programa. Si tienes más de un socio inscrito solo debe de llenarse uno por empresa.</p>
                <p>Llenar este avance no debe de tomarte más de 15 o 20 minutos.</p>
                </div>
            </div>
            {/* Start Page Content */}
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                <div className="card">
                    <div className='card-body'>
                    <CompanyPicker ref={ref => this.picker = ref}
                        onSelect={(company) => { this.onCompanySelected(company) }}>

                    </CompanyPicker>
                    {this.renderProgram()}
                    {this.renderControls()}
                    </div>
                </div>
                </div>
            </div>
            {/* End PAge Content */}
            </div>
        </div>
      )
    } else {
      return (null)
    }
  }
}
export default ProgressReportViewOnly;
