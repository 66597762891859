/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, July 2019 &
 * America Mendoza <america@startuplab.mx>, August 2019 &
 * Darien Miranda <darien@startuplab.mx, August 2019
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import KomunidadApi from './../../api/api.js';
import { transformToMoneyFormat, transformToMoney } from './../form/transforms.js';

// Components
import Swal from 'sweetalert2';
import FormInput from './../form/FormInput';
import FormTextArea from './../form/FormTextArea';


/**
 *  Display and edit the company information (Scaleup phase content).
 */

class KTabScaleUpContent extends Component{
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      company:{},
      isFormEditing:false,
      formData: {
        pre_capital_value:'',
        seeked_capital:'',
        has_participated_program:false,
        participated_program_name:''
      },
    }

    this.updateData           = this.updateData.bind(this);
    this.cancelEdit           = this.cancelEdit.bind(this);
    this.toggleEditMode       = this.toggleEditMode.bind(this);
    this.openCancelSwal       = this.openCancelSwal.bind(this);
    this.openLoadingSwal      = this.openLoadingSwal.bind(this);
    this.renderActionButton   = this.renderActionButton.bind(this);
    this.setInitialValues     = this.setInitialValues.bind(this);
    this.renderAddInfoButton  = this.renderAddInfoButton.bind(this);
    this.validateForm         = this.validateForm.bind(this);
    this.formIsEditing        = this.formIsEditing.bind(this);
  }
  componentDidMount(){
    this.setInitialValues(this.props.company);
  }

  setInitialValues(company) {
    this.setState({
      formData: {
        ...this.state.formData,
        pre_capital_value : company.pre_capital_value || "",
        seeked_capital : company.seeked_capital || "",
        has_participated_program:company.has_participated_program || false,
        participated_program_name:company.participated_program_name || ""

      }
    })
  }
  formIsEditing(editing){
    if(!(this.state.isFormEditing && editing)){
      this.setState({isFormEditing:editing});
      this.props.isTabEditing(editing);
    }
  }

  cancelEdit () {
    this.cancelForm();
    this.formIsEditing(false);
    this.setState({
      editMode: false,
    })
  }

  toggleEditMode () {
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }))
  }

  renderAddInfoButton() {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div className="m-b-20">
            <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
            </button>
          </div>
        )
      } else {
        return (
          <div className="m-b-20">
            <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
              <i className="mdi mdi-plus mr-1" style={{fontSize: '16px'}}></i> 
              Agregar información
            </button>
          </div>
        )
      }
    }else{
      return null
    }
  }

  renderActionButton () {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div>
             <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
             </button>

             <button className="btn btn-danger btn-cancel" onClick={ this.openCancelSwal }>
               <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                Cancelar
             </button>
          </div>
        )
      } else {
        return (
          <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
            <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
              Editar
          </button>
        )
      }
    }else{
      return null
    }
  }

  updateData () {
    if(this.validateForm()){
      this.openLoadingSwal();
      let formData               = this.state.formData;
      formData.pre_capital_value = this.fi_pre_capital_value.getCleanValue();
      formData.seeked_capital    = this.fi_seeked_capital.getCleanValue();
      KomunidadApi.updateCompany(this.props.company._id, formData).then(res => {
        this.formIsEditing(false);
        Swal.close();

        if(!res.success){
          Swal.fire({
						type: 'error',
						title: 'No pudimos guardar tus datos',
						text: 'Hubo un error al guardar tus datos',
						footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
					});
        }else{
          this.props.refreshCompany();
          this.toggleEditMode();
        }
      })
    }
  }

  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.formIsEditing(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }

  validateForm(){
    if (this.state.formData.has_participated_program) {
      return (
        this.fi_pre_capital_value.validate() &&
        this.fi_seeked_capital.validate() &&
        this.ft_participated_program_name.validate()
      );
    }else{
      return (
        this.fi_pre_capital_value.validate() &&
        this.fi_seeked_capital.validate()
      );
    }
  }

  cancelForm(){
    this.fi_pre_capital_value.cancel();
    this.fi_seeked_capital.cancel();
    if(this.state.has_participated_program){
      this.ft_participated_program_name.cancel();
    }
    this.setInitialValues(this.props.company);

  }

  render() {
    return (
      <div className="card-body">
        <div className="row view-subtittle">
          <div className="col-md-9">
            <p>Esta sección es solo por si tu startup se encuentra ya vendiendo y buscas levantar recursos para poder escalar el crecimiento de tu empresa. Te recomendamos llenar esta información con tu aliado. </p>
          </div>
        </div>
        <div className="form-horizontal">
          {this.renderAddInfoButton()}
          <FormInput
            ref={ref => this.fi_pre_capital_value = ref}
            id="valor pre-capital"
            model="pre_capital_value"
            label="Valuación esperada de tu empresa"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.pre_capital_value}
            initialValue={this.props.company.pre_capital_value}
            help="En caso de estar buscando capital ingresa tu valuación estimada de tu empresa (pre-capital) en pesos MXN."
            maxLength={20}
            validationRules="isNumber"
            transformMethod={transformToMoney}
            transformDisplayMethod={transformToMoneyFormat}
            onChange={change => { this.onChangeForm(change) }}
          />
          <FormInput
            ref={ref => this.fi_seeked_capital = ref}
            id="capital buscado"
            model="seeked_capital"
            label="Capital esperado"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.seeked_capital}
            initialValue={this.props.company.seeked_capital}
            help="Ingresa el monto sin espacios o comas del capital esperado que requieres para tu empresa en pesos MXN."
            maxLength={20}
            validationRules="isNumber"
            transformMethod={transformToMoney}
            transformDisplayMethod={transformToMoneyFormat}
            onChange={change => { this.onChangeForm(change) }}
          />
          <div className='form-group' >
            <label>Programas de incubación o aceleración realizados</label>
            {this.state.editMode ? <small className="form-text text-muted">Marca la casilla si alguno de los socios del empresa han participado en algún programa de incubación o aceleración previamente. Escribe el año y nombre del programa en el cual participaron.</small> : null}
          </div>
          <div className='form-group' >
            <label>
              <input
                type="checkbox"
                id="cb_has_participated_program"
                className="filled-in chk-col-light-blue"
                name='has_participated_program'
                disabled={!this.state.editMode}
                checked={this.state.formData.has_participated_program}
                initialValue={this.props.company.has_participated_program}
                onChange={(e) => { this.handleCheckbox("scaleup", e) }}
              />
              <label htmlFor="cb_has_participated_program">Si. He participado en algún programa.</label>
            </label>
            <div className='form-group m-l-40'>
              {this.renderHasParticipatedProgram()}
            </div>
          </div>
          {this.renderActionButton()}
        </div>
      </div>
    )
  }
  handleCheckbox(phase,data){
    let name = data.target.name;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[name] = !formData[name];
      return { formData };
    });
	}
  renderHasParticipatedProgram(){
		if(this.state.formData.has_participated_program){
			return (
				<FormTextArea
					ref={ref =>this.ft_participated_program_name = ref}
					id="programas en lo que has participado"
					label="¿En Cuáles?"
					model="participated_program_name"
					cssClass="custom-class"
          editMode={this.state.editMode}
					value={this.state.formData.participated_program_name}
					help="Escribe en cuales programas has participado (Mínimo 5 caracteres)."
					maxLength={300}
					validationRules="minLen:5|maxLen:300"
					onChange={ change => { this.onChangeForm(change) } }
					/>
			)
		}else{
			return (null);
		}
	}
  openLoadingSwal() {
    Swal.fire({
      title: 'Tus datos están siendo guardados',
      customClass: {
        title: 'swal-custom-title',
      },
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  openCancelSwal() {
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then(res => {
      if (res.value) this.cancelEdit()
    })
  }

}

KTabScaleUpContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
  /** True while the company is being edited */
  isTabEditing   : PropTypes.func,
  /** Callback to update the company data*/
  refreshCompany : PropTypes.func,
  /** True if the information can't be edited*/
  nonEditable    : PropTypes.bool,
};

KTabScaleUpContent.defaultProps = {
  company        : {},
  isTabEditing   : () => {},
  refreshCompany : () => {},
  nonEditable    : false,
};

export default KTabScaleUpContent;
