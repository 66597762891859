/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <Isra_darrell@startuplab.mx>, November 2019
 */
import React from "react";

const companySteps = [
  {
    selector: '[data-tut="welcome"]',
    content: `Hola, para comenzar con el registro de tu empresa debes de seguir los siguientes pasos:`
  },
  {
    selector: '[data-tut="program-step"]',
    content: `Aquí selecciona el programa para el que deseas registrar tu empresa.`
  },
  {
    selector: '[data-tut="project-name"]',
    content: () => (
      <div>
        Ahora da un nombre a tu empresa.
        Si el campo tiene un <span style={{color: 'red'}}>*</span> ,el campo es obligatorio, de lo contrario lo puedes dejar vacío.
      </div>
    ),
    action: node => node.focus()
  },
  {
    selector: '[data-tut="continue"]',
    content: `El mismo proceso aplica con los siguientes campos.`
  },
  {
    selector: '[data-tut="cancel-button"]',
    content: `Si desesas cancelar el registro de la empresa, puedes dar clic aqui`,
  },
  {
    selector: '[data-tut="save-and-continue-button"]',
    content: `Si desesas guardar y continuar con el registro, puedes dar clic aqui`,
  },
  {
    selector: '[data-tut="save-and-close-button"]',
    content: `Si desesas guardar y cerrar el registro, puedes dar clic aqui`,
  },
];

export default companySteps;