/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx> June 2021
 * & Juan Hernandez <juan@startuplab.mx>
 */

// Regular imports
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom'
import KomunidadApi from '../../api/api.js';

import 'moment/locale/es';
import Swal from 'sweetalert2';
import { isUserProfileComplete } from '../../utils/Utils.js';

// Custom styling
import '../../scss/pages/_style.css';
import '../../scss/_sidebar.scss';
import '../../scss/pages/pages.scss';
import '../../scss/colors/default-dark.scss';

//Components
import HeaderCatalog from '../../components/HeaderCatalog';
import TopSideBar from '../../components/TopSideBar';
import ImagePicker from '../../components/ImagePicker.js';
import KImage from '../../components/KImage';

//Map components
import ReactMapGL,{ NavigationControl, Popup} from 'react-map-gl';
import KMarker from '../../components/KMarker';

// Vars
var CompanyPhasesChecker = require("../../utils/CompanyPhasesChecker");
const MAPBOX_TOKEN = 'pk.eyJ1IjoicGFuemVyIiwiYSI6ImNrN2RsZzcwMDIxMHAzZW9naWh1amhkejIifQ.2MkaNWU65XhWigJvQUm1_A';

class PublicCompanyView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      me: {},
      company: {},
      company_id: null,
      embed: false,
      confirmedNavigation: false,
      isOnePagerAvailable:false,
      isCompanyloaded: false,
      viewport: {
        latitude: Number(process.env.REACT_APP_LATITUDE),
        longitude: Number(process.env.REACT_APP_LONGITUDE),
        zoom: 13.9,
      },
      installationCoordinates:{
        latitude:0,
        longitude:0
      },
    }

    this.extractQueryParams = this.extractQueryParams.bind(this);
    this.getCompany         = this.getCompany.bind(this);
  }

  componentDidMount () {
    let company_id = this.props.match.params.id
    KomunidadApi.getMe().then(res => {
      if (res.valid_token) {
        if (isUserProfileComplete(res.user)) {
          this.setState({ me: res.user })
        } 
      }
    });
    this.setState({ 
      company_id: company_id 
    }, () => {
      this.extractQueryParams();
      this.getCompany();
    })
  }

  extractQueryParams(){
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let embed  = params.get('embed') !== null && params.get('embed') === 'true' ? true : false;
    this.setState({
      embed: embed,
    });
  }

  getCompany () {
    KomunidadApi.getPublicCompany(this.state.company_id).then((res) => {
      if(res.success && res.hasOwnProperty('company')) {
        let companyPhases = CompanyPhasesChecker.check(res.company);
        this.setState({company:res.company,isOnePagerAvailable:companyPhases.isIdea, isCompanyLoaded: true});
        if(res.company.coordinates !== []){
          this.setState({
            viewport:{
              latitude: res.company.coordinates[1],
              longitude: res.company.coordinates[0],
              zoom: 13.9,
            },
          });
        }
      }else if(!res.success){
        this.props.history.push('/directorio');
      }
    })
  }

  formatDate(date){
    if(date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }

  
extractHostname(url) {
  var hostname;
  //remove protocol get hostname
  if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
  } else {
      hostname = url.split('/')[0];
  }

  //remove port
  hostname = hostname.split(':')[0];
  //remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

isFromYoutube(hostName) {
  if(hostName === 'www.youtube.com') {
    return true;
  } else if(hostName === 'youtube.com') {
    return true;
  } else if(hostName === 'youtu.be') {
    return true;
  } else {
    return false;
  }
}

isFromGoogle(hostName) {
  if(hostName === 'www.docs.google.com') {
    return true;
  } else if(hostName === 'docs.google.com') {
    return true;
  } else {
    return false;
  }
}

isFromDrive(hostName) {
  if(hostName === 'www.drive.google.com') {
    return true;
  } else if(hostName === 'drive.google.com') {
    return true;
  } else {
    return false;
  }
}

parseUrl(url) {
  if(this.isFromYoutube(this.extractHostname(url))) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return 'https://www.youtube.com/embed/'.concat(
      (match && match[2].length === 11)
      ? match[2]
      : null
    );
  } else if(this.isFromGoogle(this.extractHostname(url))){
    const regExp = /\edit.*$/;
    let parsedURl = url.replace(regExp, 'preview')

    return parsedURl
  } else if(this.isFromDrive(this.extractHostname(url))){
    const regExp = /view.*$/;
    let parsedURl = url.replace(regExp, 'preview')

    return parsedURl
  } else {
    return url;
  }
}



  renderPartners(partners){
    return(
      <div className="row">
        {partners.map((item,index) =>
          (
            <div className="col-lg-auto col-md-auto mb-3"><img src={item.user.profileUrlThumbnail} className="img-fluid img-circle" style={{height: '80px', width: '80px'}}/></div>
          )
        )}
      </div>
    )
  }

  renderPrograms(programs) {
    if(programs.length !== 0) {   
      return(
        <div className="sl-item d-flex align-items-start mb-2">
          <div className="sl-right">
            <div>
              <h6 className="font-weight-bold">Programas en los que ha participado</h6>
              <div>
                {programs.map((item,index) =>
                  (
                    <span className="mb-1 badge font-weight-bold bg-light-primary text-primary mr-2 ">
                      {item.program.name}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else return null;
  }

  renderCompanyInfo(company){
    return(
      <div className='popup-map  m-t-5'>
        <div className='popup-row'>
          <KImage src={company.logoThumbnail} type='company' className='popup-image' alt="company_image"/>

          <div className='popup-column m-l-5'>
            <div className='font-bold'>{company.name}</div>
            <div className='text-muted'>{CompanyPhasesChecker.getCurrentPhaseLabel(company)}</div>
          </div>
        </div>
        { this.renderAddress(company) }
      </div>
    )
  }
  renderAddress(company){
    let state = ''
    if( company.address_state !== ''){
      state = this.iso31662ToState(company.address_state);
    }
    return(
      <div className=''>
        {company.address_street + ' ' + company.address_number_ext + ' ' + company.address_number_int }
        <br/>
        {company.address_neighborhood}
        <br/>
        {company.address_zip_code + ' ' + company.address_municipality+ ', '+ state }
      </div>
    )
  }
  iso31662ToState(state){
    let isoCodes = {
      "MX-AGU":"Aguascalientes",
      "MX-BCN":"Baja California",
      "MX-BCS":"Baja California Sur",
      "MX-CAM":"Campeche",
      "MX-COA":"Coahuila de Zaragoza",
      "MX-COL":"Colima",
      "MX-CHP":"Chiapas",
      "MX-CHH":"Chihuahua",
      "MX-CMX":"Ciudad de México",
      "MX-DUR":"Durango",
      "MX-GUA":"Guanajuato",
      "MX-GRO":"Guerrero",
      "MX-HID":"Hidalgo",
      "MX-JAL":"Jalisco",
      "MX-MEX":"México",
      "MX-MIC":"Michoacán de Ocampo",
      "MX-MOR":"Morelos",
      "MX-NAY":"Nayarit",
      "MX-NLE":"Nuevo León",
      "MX-OAX":"Oaxaca",
      "MX-PUE":"Puebla",
      "MX-QUE":"Querétaro",
      "MX-ROO":"Quintana Roo",
      "MX-SLP":"San Luis Potosí",
      "MX-SIN":"Sinaloa",
      "MX-SON":"Sonora",
      "MX-TAB":"Tabasco",
      "MX-TAM":"Tamaulipas",
      "MX-TLA":"Tlaxcala",
      "MX-VER":"Veracruz de Ignacio de la Llave",
      "MX-YUC":"Yucatán",
      "MX-ZAC":"Zacatecas",
    }
    return isoCodes[state];
  }

  renderWebSite(website) {
    if(website !== '' && website !== null) {
      return(
        <div>
          <small className="text-muted">Sitio web</small>
          <h6 className="font-weight-bold"><a href={website} target="_blank">{website}</a></h6>
        </div>
      );
    } else return null;
  }

  renderPdf(pdf) {
    if(pdf !== null) {
      return(
        <div>
          <small className="text-muted">Ficha técnica</small>
          <h6 className="font-weight-bold"><a href={pdf} target="_blank">Descargar</a></h6>
        </div>
      );
    } else return null;
  }

  renderSector(sector) {
    if(sector !== '' && sector !== null) {
      return(
        <div>
          <small className="text-muted">Sector</small>
          <h6 className="font-weight-bold">{sector}</h6>
        </div>
      );
    } else return null;
  }

  renderDate(date) {
    if(date !== '01-01-1970' && date !== null && date !== '') {
      return(
        <div>
          <small className="text-muted">Inicio de operaciones</small>
          <h6 className="font-weight-bold">{date}</h6>
        </div>
      );
    } else return null;
  }

  renderSector(sector) {
    if(sector !== '' && sector !== null) {
      return(
        <div>
          <small className="text-muted">Sector</small>
          <h6 className="font-weight-bold">{sector}</h6>
        </div>
      );
    } else return null;
  }

  renderMapBox(){
    const {showPopup} = this.state;
    const {company} = this.state;

    if(this.state.company.coordinates[0] !== 0 && this.state.company.coordinates[1] !== 0) {
      return(
        <div className="map-box">
          <small className="text-muted">Ubicación</small>
          <ReactMapGL
            {...this.state.viewport}
            width="100%"
            height="350px"
            mapStyle="mapbox://styles/panzer/ck7e07mrw1nxj1iqvac5593qg"
            onViewportChange={viewport => this.setState({viewport})}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            <div style={{position: 'absolute', right: 0}}>
              <NavigationControl showCompass={false}/>
            </div>

            <KMarker latitude={this.state.company.coordinates[1]}
                    longitude={this.state.company.coordinates[0]}
                    draggable={false}
                    onClickMarker={() => {this.setState({showPopup: true,company:company})}}>
            </KMarker>
            { showPopup && <Popup
              latitude={company.coordinates[1]}
              longitude={company.coordinates[0]}
              closeButton={true}
              closeOnClick={false}
              onClose={() => this.setState({showPopup: false})}
              offsetTop={-32}
              anchor="left" >
              { this.renderCompanyInfo(company) }
            </Popup>}
          </ReactMapGL>
        </div>
      )
    } else return null;

  }

  renderHistory(history) {
    if(history !== '' && history !== null) {
      return(
        <div>
          <div className="sl-item d-flex align-items-start mb-2">
            <div className="sl-right">
              <div>
                <h6 className="font-weight-bold">Historia</h6>
                <p>{history}</p>
              </div>
            </div>
          </div>
          <hr className="mt-0" />
        </div>
      )
    } else return null;
  }

  renderBusinessModel(model) {
    if(model !== '' && model !== null) {
      return(
        <div>
          <div className="sl-item d-flex align-items-start mb-2">
            <div className="sl-right">
              <div>
                <h6 className="font-weight-bold">Modelo de negocio</h6>
                <p>{model}</p>
              </div>
            </div>
          </div>
          <hr className="mt-0" />
        </div>
      )
    } else return null;
  }

  renderServiceIcon(domain_name){
    switch (domain_name) {
      case 'Facebook':
        return 'mdi-facebook btn-facebook'
      case 'Linkedin':
        return 'mdi-linkedin-box btn-linkedin'
      case 'Instagram':
        return 'mdi-instagram btn-dribbble'
      case 'YouTube':
        return 'mdi-youtube-play btn-youtube'
      case 'Twitter':
        return 'mdi-twitter btn-twitter'
      case 'Whatsapp':
        return 'mdi-whatsapp btn-success'
      default:
        return 'mdi-link-variant btn-dark'
    }
  }

  renderSocial(socialNetworks) {
    if(socialNetworks !== null && socialNetworks !== undefined && socialNetworks.length !== 0) {
      return(
        <div className="sl-item d-flex align-items-start mb-2">
          <div className="sl-right">
            <div>
              <h6 className="font-weight-bold">Contacto, redes sociales y ventas</h6>
              <div>
                {socialNetworks.map((item,index) =>
                  (
                    <a href={item.url} target="_blank" rel="noopener noreferrer" className="mr-2">
                      <i className={"btn py-0 px-2 mdi " + this.renderServiceIcon(item.domain_name)} style={{fontSize: '28px'}}></i>
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else return null;
  }

  renderVideo(video) {
    if(video !== '' && video !== null) {
      return(
        <div className="col-md">
          <h3 className="text-themecolor">Video</h3>
          <iframe 
            width="100%" 
            height="400px" 
            src={this.parseUrl(video)}
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>
      )
    } else return null;
  }

  render(){
    if(this.state.isCompanyLoaded){

        let company = this.state.company;
        const dateOfEstablishment = this.formatDate(this.state.company.dateOfEstablishment ? this.state.company.dateOfEstablishment:'');

        return(
          <div className="fix-header card-no-border fix-sidebar height100">
            <div id="main-wrapper" className="height100">
            {this.state.embed === false && <HeaderCatalog
              user={this.state.me}
              hideBurger={true}
            />}
              
              {/* Page wrapper  */}
              <div className={"page-wrapper color-gray " + (this.state.embed === true ? "pt-0":"")} style={{paddingTop: '55px'}}>
                {/* Container fluid  */}
                <div className="container-fluid">
                  <div className="row page-titles">
                    <div className="col align-self-end">
                      <div className="btn btn-info btn-aux float-right" onClick={() => this.props.history.push('/directorio'+ (this.state.embed? "?embed=true":""))}>
                        Regresar al directorio
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Column */}
                    <div className="col-lg-4 col-xlg-3 col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <center className="m-t-30 noselect" data-tut="edit-image">
                                    <KImage src={company.logoThumbnail} className="catalog-company-logo" alt="user_img" type={"company"}/>
                                    <h4 className="card-title m-t-10">{company.name}</h4>
                                    <h6 className="card-subtitle">{company.quote}</h6>
                                </center>
                            </div>
                            <div>
                                <hr></hr>
                            </div>
                            <div className="card-body">
                              {this.renderWebSite(company.website)}
                              {this.renderPdf(company.pdf_file)}
                              {this.renderSector(company.industry_sector)}
                              {this.renderDate(dateOfEstablishment)}
                              <small className="text-muted">Personas en el equipo</small>
                              <h6 className="font-weight-bold">{company.partners}</h6>
                              {this.renderMapBox()}
                            </div>
                        </div>
                    </div>
                    {/* Column */}
                    {/* Column */}
                    <div className="col-lg-8 col-xlg-9 col-md-7">
                        <div className="card">
                          <div className="card-body">
                            <div className="profiletimeline mt-0">
                              {this.renderHistory(company.achievements)}
                              {this.renderBusinessModel(company.business_model)}
                              {this.renderPrograms(company.programs)}
                              {this.renderSocial(company.social_networks)}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/*<div className="col-md">
                            <h3 className="text-themecolor">Ficha técnica</h3>
                            <iframe id="inlineFrameExample"
                              title="Company PDF"
                              width="100%"
                              height="400px"
                              src={'https://docs.google.com/viewer?embedded=true&url='+company.pdf_file}
                              frameBorder="0">
                            </iframe>
                          </div>*/}
                          {this.renderVideo(company.video_url)}
                        </div>

                    </div>
                    {/* Column */}
                  </div>
                  {/* Row */}
                  {/* End PAge Content */}
                </div>
                {/* End Container fluid  */}
              </div>
            </div>
          </div>

        )
    } else {
      return null;
    }
  }
}

export default PublicCompanyView;
