/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * America Mendoza <america@startuplab.mx>, October 2019 &
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KomunidadApi from '../api/api.js';

// Components
import KReviewList from './KReviewList';
import KModalReviewList from './KModalReviewList';
import KCreateReview from './KCreateReview';
import Swal from 'sweetalert2';

// Custom SCSS
import '../scss/components/_input.scss';

/**
*  Show the list of [reviews](/#kreviewlist)
*  and if the user is a mentor can add review.
*/

class KMentorReview extends Component{
  constructor(props) {
    super(props)
    this.state = {
      review:'',
      reviews:[],
      requestStatusReviewList:'idle',
      showModal:false,
      showModalReview: false,
    }
    this.onChangeForm         = this.onChangeForm.bind(this);
    this.sendReview           = this.sendReview.bind(this);
    this.getReviews           = this.getReviews.bind(this);
    this.handleCloseModal     = this.handleCloseModal.bind(this);
    this.handleReviewModal    = this.handleReviewModal.bind(this);
  }

  componentDidMount(){
    this.getReviews();
  }

  onChangeForm(data){
    this.setState({review:data.value});
  }

  handleReviewModal(value){
    this.setState({showModalReview: value});
  }

  sendReview(){
    let review = this.state.review;

    KomunidadApi.createCompanyReview(review,this.props.companyId).then((res) => {
      if(res.success){
        this.fta_review.cancel();
        this.getReviews();
        Swal.fire({
          type: 'success',
          title: '¡Retroalimentación registrada!',
          text: 'Se te enviará un correo de confirmación.',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }else{
        Swal.fire({
          type: 'error',
          title: 'Ups..',
          text: 'Hubo un error el crear tu retroalimentación',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }

  getReviews(){
    KomunidadApi.getReviews(this.props.companyId).then((res) => {
      if(res.success){
        this.setState({reviews:res.reviews,requestStatusReviewList:'success'});
      }else{
        this.setState({requestStatusReviewList:'failed'});
      }
    });
  }

  /*renderAddReview(){
    if(this.props.allowReviews){
      const user = this.props.user;

      return(
        <div className="card-body">
          <div className="activity-item">
            <div className="m-r-10">
              <KImage src={user.profileUrlThumbnail} className={"kuImage"} alt={"user_img"} type={"user_"+user.gender}/>
            </div>
            <div className="review-input">
              <FormTextArea
                ref={ref => this.fta_review = ref}
                id="revisión"
                model="review"
                maxLength={2000}
                value={this.state.review}
                cssClass='resizable-text'
                initialValue={''}
                validationRules="minLen:50|maxLen:2000"
                onChange={ change => { this.onChangeForm(change) } }
                placeholder={'Agrega tu revisión'}
              />

              <button className="btn btn-success btn-save float-right" onClick={() => this.sendReview()}> Enviar </button>
            </div>
          </div>
        </div>
      )
    }else{
      return null
    }
  }*/

  renderShowReviewBtn(reviews){
    if(reviews.length >= 8){
        return(
          <div className="text-center m-b-30 ">
            <div className="btn waves-effect waves-light btn-outline-secondary"
                 onClick={() => this.handleCloseModal(true)}>Ver todo ({reviews.length})</div>
          </div>
        )
    }else{
      return null;
    }
  }

  renderCreateReview() {
    if(this.props.allowReviews){
      return(
        <div className="float-right">
          <button className="btn btn-info btn-block btn-rounded" style={{borderRadius: '.25rem'}} onClick={() => this.handleReviewModal(true)}>Añadir</button>
        </div>
      )
    } else return null;
  }

  handleCloseModal(value){
    this.setState({showModal:value});
  }

  render(){
    const reviews = this.state.reviews;
    return (
      <div className="col-lg-4 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="float-left">
              <h3 className="card-title">Retroalimentación</h3>
            </div>
            {this.renderCreateReview()}
          </div>

          <div className="activity-box">
            {/*this.renderAddReview()*/}
            <KCreateReview
              showModalReview={this.state.showModalReview}
              closeReviewModal={this.handleReviewModal}
              companyId={this.props.companyId}
              user={this.props.user}
              allowReviews={this.props.allowReviews}
              getReviews={this.getReviews}
            />
            <div className="date-devider"><span>Últimas entradas</span></div>

            <KReviewList
                reviews={reviews.slice(0,8)}
                requestStatus={this.state.requestStatusReviewList}
                subtitleEmptyList={this.props.subtitleEmptyList}/>

            {this.renderShowReviewBtn(reviews)}
          </div>

        </div>
        <KModalReviewList showModal={this.state.showModal}
                          reviews={this.state.reviews}
                          requestStatus={this.state.requestStatusReviewList}
                          closeModal={this.handleCloseModal}>
        </KModalReviewList>
      </div>
    )
  }
}

KMentorReview.propTypes = {
  /** Id of the company to be review. */
  companyId         : PropTypes.string,

  /** Mentor object. */
  user              : PropTypes.object,

  /** When is true show the input to add the review. */
  allowReviews      : PropTypes.bool,

  /** Message if the list is empty.
      Prop of the component (KReviewList)[/#kreviewlist]*/
  subtitleEmptyList : PropTypes.string,
};

KMentorReview.defaultProps = {
  companyId         : '',
  user              : {},
  allowReviews      : true,
  subtitleEmptyList : '¡Agrega una!',
};

export default KMentorReview;
