/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Hernandez <juan@startuplab.mx>, December 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import KomunidadApi from '../api/api.js';
import Swal from 'sweetalert2';

import '../scss/components/_add-partner-modal.scss';
import '../scss/pages/form-pages.scss';

import ProgramsPicker from '../components/ProgramsPicker';

/**
 *  Modal to join a partner, mentor.
 *  Or assign a facilitator to a program.
 */

class KAssignMultipleProgramModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProgramsIds:[],
      passwordStep: false,
      password:'',
      programsChanged:false
    }
    this.hideModal                     =  this.hideModal.bind(this);
    this.handleChanges                 =  this.handleChanges.bind(this);
    this.handleCloseModal              =  this.handleCloseModal.bind(this);
    this.executeAfterModalClose        =  this.executeAfterModalClose.bind(this);
    this.onChangeProgram               =  this.onChangeProgram.bind(this);
    this.onClickProgramSelected        =  this.onClickProgramSelected.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(state.programsChanged){
      return {
        selectedProgramsIds: state.selectedProgramsIds
      };
    }else{
      return{
        selectedProgramsIds: props.programsSelected
      }
    }
  }

  handleChanges (event) {
    this.setState({ [event.target.id] : event.target.value });
  }

  handleCloseModal(show){
    this.props.closeModal(show);
  }

  hideModal(){
    this.handleCloseModal(false);
  }

  executeAfterModalClose(){
    this.setState(
      {
        selectedProgramsIds:[],
        passwordStep:false,
        password:'',
        programsChanged:false
      }, () => this.props.closeModal(false)
    );
  }

  onChangeProgram(programs){
    this.setState({selectedProgramsIds:programs,programsChanged:true});
  }

  onClickProgramSelected(){
    let program_id = this.state.selectedProgramsIds._id;
    if (program_id){
        this.setState({
          passwordStep: true
        });
    }else{
        Swal.fire({
          title: 'Faltan campos',
          text: "Selecciona un programa.",
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'warning',
        });
    }
  }
  assignUsersToProgram(){
      let program_id = this.state.selectedProgramsIds._id;
      let users_ids = this.props.users.map(u => u._id).join("|");
      let pwd = this.state.password;
      KomunidadApi.addMultipleUsers(program_id,users_ids,pwd).then((res) => {
      if(res.success){
        Swal.fire({
          title: 'Usuarios asignados al programa',
          text: "Los usuarios fueron asignados correctamente al programa.",
          type: 'success',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          onClose: () => {
            this.setState({
              programsChanged:false,
              password:'',
              passwordStep:false
            });
            this.handleCloseModal(false);
            this.props.onAddProgram();
          }
        });
      }else{
        Swal.fire({
          title: 'Error',
          text: "Hubo un error al asignar a los usuarios.",
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
          type: 'error',
        });
        this.setState({
            programsChanged:false,
            password:'',
            passwordStep:false
        });
      }
    });
  }

  renderContent(){
      if(!this.state.passwordStep){
          return(
            <div className="card-body">
              <h5 className='m-b-20'>
                Asignar usuarios seleccionados a:
              </h5>
              <ProgramsPicker showInactivePrograms={true}
                              onChange={(program) => {this.onChangeProgram(program)}}
                              multipleProgramSelected={this.props.programsSelected}
                              isMultiple={false}>
              </ProgramsPicker>
            </div>
          )
      } else {
          return(
            <div className="card-body">
              <h5 className='m-b-20'>
                Ingresa tu contraseña para continuar:
              </h5>
              <div className="form-group mb-3">
                  <div className="col-xs-12">
                    {/*<label>Contraseña</label>*/}
                    <input className="form-control" id="password" value={this.state.password} onChange={this.handleChanges} placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" type="password"/>
                  </div>
                </div>
            </div>
          )
      }
  }
  renderActionButton(){
      if(!this.state.passwordStep){
        return(<div className="btn btn-primary btn-save" onClick={() => this.onClickProgramSelected()}>Siguiente</div>)
      } else {
        return(<div className="btn btn-primary btn-save" onClick={() => this.assignUsersToProgram()}>Asignar</div>)
      }
  }

  render() {
    return (
      <Modal  show={this.props.showModal}
              onHide={() => this.handleCloseModal(false)}
              onExit={this.executeAfterModalClose}
              >
        <Modal.Header closeButton>
          <Modal.Title>
            Asignar usuarios a programa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderActionButton()}
        </Modal.Footer>
      </Modal>
    );
  }
}

KAssignMultipleProgramModal.propTypes = {
  /** Close the modal. */
  closeModal       : PropTypes.func,
  /** When true the modal will show itself. */
  showModal        : PropTypes.bool,
  /** Users object*/
  users            : PropTypes.object,
  /** Ids of the programs the user has assing*/
  programsSelected : PropTypes.array,
  /*Callback refresh facilitators*/
  onAddProgram     : PropTypes.func,
};

KAssignMultipleProgramModal.defaultProps = {
  closeModal        : () => {},
  showModal         : false,
  users             : [],
  programsSelected  : [],
  onAddProgram      : () => {}
};

export default KAssignMultipleProgramModal;
