/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Kevin Avila <kevin@startuplab.mx>, July 2019 &
 * America Mendoza <america@startuplab.mx>, August 2019 &
 * Darien Miranda <darien@startuplab.mx, August 2019
 */

 // Regular imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KomunidadApi from './../../api/api.js';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';

// Components
import Swal from 'sweetalert2';
import FormInput from './../form/FormInput';
import {transformToMoney, transformToMoneyFormat, editDateMask} from './../form/transforms.js'
import FormTextArea from './../form/FormTextArea';
import ImagePicker from './../ImagePicker.js';
import KImage from './../KImage';
import KFileField from "./../KFileField";
import KSocialNetworksField from "./../KSocialNetworksField";


/**
 *  Display and edit the company information (Startup phase content).
 */


class KTabStartupContent extends Component{
  constructor(props) {
    super(props)

    this.state = {
      editMode: false,
      company:{},
      dateOfEstablishment:'',
      dateOfEstablishmentInitialValue:'',
      showHover: false,
      logoName:'',
      isFormEditing:false,
      isCalendarOpen:false,
      formData: {
        logo: null,
        dateOfEstablishment: '',
        market: '',
        business_model: '',
        stats_is_seeking_funding: false,
        stats_funding_amount_seeked: '',
        stats_funding_seeking_reason: '',
        stats_is_seeking_mentoring: false,
        stats_is_seeking_team: false,
        stats_team_seeking_reason: '',
        achievements: '',
        active_users: '',
        currency: 'MXN',
        stats_is_seeking_mentoring_marketing:false,
        stats_is_seeking_mentoring_sales: false,
        stats_is_seeking_mentoring_legal: false,
        stats_is_seeking_mentoring_fiscal: false,
        stats_is_seeking_mentoring_financing: false,
        stats_is_seeking_mentoring_accounting: false,
        stats_is_seeking_mentoring_hr: false,
        stats_is_seeking_mentoring_business_plan: false,
        stats_is_seeking_mentoring_trademark: false,
        stats_is_seeking_mentoring_patent: false,
        stats_is_seeking_mentoring_institutional_image: false,
        stats_is_seeking_mentoring_processes: false,
        pitch_file:'',
        pitch_link:'',
        social_networks:[]

      },
      selectedFile:null,
    }

    this.updateData           = this.updateData.bind(this);
    this.cancelEdit           = this.cancelEdit.bind(this);
    this.toggleEditMode       = this.toggleEditMode.bind(this);
    this.openCancelSwal       = this.openCancelSwal.bind(this);
    this.openLoadingSwal      = this.openLoadingSwal.bind(this);
    this.renderActionButton   = this.renderActionButton.bind(this);
    this.setInitialValues     = this.setInitialValues.bind(this);
    this.renderAddInfoButton  = this.renderAddInfoButton.bind(this);
    this.validateForm         = this.validateForm.bind(this);
    this.searchOfStatus       = this.searchOfStatus.bind(this);
    this.onChangeForm				  = this.onChangeForm.bind(this);
    this.onDateChange         = this.onDateChange.bind(this);
    this.onDateSelected       = this.onDateSelected.bind(this);
    this.onChangeWrapperDate  = this.onChangeWrapperDate.bind(this);
    this.getDate              = this.getDate.bind(this);
    this.hoverLogo						= this.hoverLogo.bind(this);
    this.leaveLogo						= this.leaveLogo.bind(this);
    this.getImage             = this.getImage.bind(this);
    this.openPicker           = this.openPicker.bind(this);
    this.formIsEditing        = this.formIsEditing.bind(this);
    this.onFileSelected       = this.onFileSelected.bind(this);
  }

  componentDidMount(){
    this.setInitialValues(this.props.company);
    this.getDate(this.props.company);
    this.setState({logoName:this.props.company.logo});

  }
  componentDidUpdate (prevProps) {
    if (this.props.company._id !== prevProps.company._id) {
      this.setInitialValues(this.props.company);
    }
    if (this.props.company.dateOfEstablishment !== prevProps.company.dateOfEstablishment) {
      this.getDate(this.props.company);
    }
    if (this.props.company.logo !== prevProps.company.logo) {
      this.setState({logoName:this.props.company.logo});
    }
  }

  setInitialValues(company) {
    this.setState({
      formData: {
        ...this.state.formData,
        logo: company.logo || '',
        dateOfEstablishment: company.dateOfEstablishment || '',
        market: company.market || '',
        business_model: company.business_model || '',
        stats_is_seeking_funding: company.stats_is_seeking_funding || false,
        stats_funding_amount_seeked: company.stats_funding_amount_seeked || '',
        stats_funding_seeking_reason: company.stats_funding_seeking_reason || '',
        stats_is_seeking_mentoring: company.stats_is_seeking_mentoring || false,
        stats_is_seeking_team: company.stats_is_seeking_team || false,
        stats_team_seeking_reason: company.stats_team_seeking_reason || '',
        active_users: company.active_users || '',
        currency: company.currency || 'MXN',
        achievements: company.achievements || '',
        stats_is_seeking_mentoring_marketing : company.stats_is_seeking_mentoring_marketing || false,
        stats_is_seeking_mentoring_sales : company.stats_is_seeking_mentoring_sales || false,
        stats_is_seeking_mentoring_legal : company.stats_is_seeking_mentoring_legal || false,
        stats_is_seeking_mentoring_fiscal : company.stats_is_seeking_mentoring_fiscal || false,
        stats_is_seeking_mentoring_financing : company.stats_is_seeking_mentoring_financing || false,
        stats_is_seeking_mentoring_accounting : company.stats_is_seeking_mentoring_accounting || false,
        stats_is_seeking_mentoring_hr : company.stats_is_seeking_mentoring_hr || false,
        stats_is_seeking_mentoring_business_plan : company.stats_is_seeking_mentoring_business_plan || false,
        stats_is_seeking_mentoring_trademark : company.stats_is_seeking_mentoring_trademark || false,
        stats_is_seeking_mentoring_patent : company.stats_is_seeking_mentoring_patent || false,
        stats_is_seeking_mentoring_institutional_image : company.stats_is_seeking_mentoring_institutional_image || false,
        stats_is_seeking_mentoring_processes : company.stats_is_seeking_mentoring_processes || false,
        pitch_file : company.pitch_file || '',
        social_networks : company.social_networks || [],

      }
    });
  }

  getDate(company){
    if(company.hasOwnProperty('dateOfEstablishment')){
      let d = company.dateOfEstablishment;
      let date = null;

      if( d !== null){
        date = new Date(d.replace('Z',''));
      }

      let formInputdate = this.returnDateFormatToFormInput(date);

      this.setState({
        dateOfEstablishment: formInputdate.day + formInputdate.month + formInputdate.year,
        dateOfEstablishmentInitialValue: formInputdate.day + formInputdate.month + formInputdate.year
      });

      this.setState(prevState => {
        let formData = Object.assign({}, prevState.formData);
        formData.dateOfEstablishment = date;
        return { formData };
      });
    }
  }

  formIsEditing(editing){
    if(!(this.state.isFormEditing && editing)){
      this.setState({isFormEditing:editing});
      this.props.isTabEditing(editing);
    }
  }

  cancelEdit () {
    this.cancelForm();
    this.formIsEditing(false);
    this.setState({
      editMode: false,
    })
  }

  toggleEditMode () {
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }))
  }

  renderAddInfoButton() {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div className="m-b-20">
            <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
            </button>
          </div>
        )
      } else {
        return (
          <div className="m-b-20">
            <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
              <i className="mdi mdi-plus mr-1" style={{fontSize: '16px'}}></i> 
              Agregar información
            </button>
          </div>
        )
      }
    }else{
      return null
    }
  }

  renderActionButton () {
    if(this.props.nonEditable){
      return (null);
    }
    if(this.props.company.is_disabled === false){
      if (this.state.editMode) {
        return (
          <div>
             <button className="btn btn-success btn-save" onClick={ this.updateData }>
              <i className="mdi mdi-content-save mr-1" style={{fontSize: '16px'}}></i> 
                Guardar
             </button>

             <button className="btn btn-danger btn-cancel" onClick={ this.openCancelSwal }>
               <i className="mdi mdi-close mr-1" style={{fontSize: '16px'}}></i> 
                Cancelar
             </button>
          </div>
        )
      } else {
        return (
          <button className="btn btn-info btn-aux" onClick={ this.toggleEditMode }>
            <i className="mdi mdi-grease-pencil mr-1" style={{fontSize: '16px'}}></i> 
              Editar
          </button>
        )
      }
    }else{
      return null
    }
  }

  updateData () {
    if(this.validateForm()){
      this.openLoadingSwal();
      let formData = this.state.formData;
      formData.stats_funding_amount_seeked = this.fi_stats_funding_amount_seeked.getCleanValue();
      formData.pitch_file = this.state.selectedFile;
      KomunidadApi.updateCompany(this.props.company._id, formData).then(res => {
        this.formIsEditing(false);
        Swal.close();

        if(!res.success){
          Swal.fire({
						type: 'error',
						title: 'No pudimos guardar tus datos',
						text: 'Hubo un error al guardar tus datos',
						footer: '',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
					});
        }else{
          this.props.refreshCompany();
          this.toggleEditMode();
          //TODO: Use getDerivedStateFromProp instead
          this.setState(prevState => {
            let formData = Object.assign({}, prevState.formData);
            formData.pitch_file = res.company.pitch_file;
            return { formData };
          });
        }
      })
    }
  }

  onChangeForm(data){
    let value = (data.value === null) ? '' : data.value; //sets null data to empty string
    this.formIsEditing(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }

  onChangeSocialNetworks(data){
    let value = (data.value === null) ? [] : data.value; //sets null data to empty string
    this.formIsEditing(true)
    
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[data.model] = value;
      return { formData };
    });
  }

  validateForm(){
    return (  this.fi_market.validate() && this.fi_business_model.validate() &&
					    this.fi_stats_funding_amount_seeked.validate() &&
					    this.fi_stats_funding_seeking_reason.validate() &&
					    this.fi_stats_team_seeking_reason.validate() &&
              this.fi_achievements.validate()) && this.fi_pitch_link.validate()
  }

  cancelForm(){
    this.fi_date.cancel();
    this.fi_market.cancel();
    this.fi_business_model.cancel();
    this.fi_stats_funding_amount_seeked.cancel();
    this.fi_stats_funding_seeking_reason.cancel();
    this.fi_stats_team_seeking_reason.cancel();
    this.fi_achievements.cancel();

    //reset checkboxes
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.stats_is_seeking_funding = this.props.company.stats_is_seeking_funding || false;
      formData.stats_is_seeking_mentoring = this.props.company.stats_is_seeking_mentoring || false;
      formData.stats_is_seeking_team = this.props.company.stats_is_seeking_team || false;
      return { formData };
    });
    this.setState({logoName:this.props.company.logo});

  }

  getImage(file) {
    let name = window.URL.createObjectURL(file);
    this.formIsEditing(true);

    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.logo = file;
      return { formData };
    });

    this.setState({
      logoName: name
    });
  }

  //setState 'search of' data
  searchOfStatus(data) {
    this.formIsEditing(true);
    let checked = data.target.checked;
    let model   = data.target.name;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[model] = checked;
      return { formData };
    });

  }

  renderSmallLabelSeeking(){
    if(this.state.editMode){
      return(
        <small className="form-text text-muted">Selecciona la casilla si estas en busca de financiamiento, aliados o personas, igualmente te pedimos llenes los campos que se requieren.</small>
      )
    }else{
      return null
    }
  }

  renderSmallLabelDate(){
    if(this.state.editMode){
      return(
        <small className="form-text text-muted">Selecciona la fecha de inicio de operaciones de tu empresa.</small>
      )
    }else{
      return null
    }
  }

  //setState DatePicker
  onDateChange(date) {
    let data = { model:'dateOfEstablishment', value:date };

    //Save the date on the formData object.
    this.onChangeForm(data);
  }

  //setState for DatePicker
  onDateSelected(date) {
    this.formIsEditing(true);

    let d = this.returnDateFormatToFormInput(date);

    this.setState({dateOfEstablishment:d.day + d.month + d.year})
  }

  onChangeWrapperDate(data){
    if(this.state.isCalendarOpen){
      this.dp_dateOfEstablishment.setOpen(false);
    }

    if( data.value.length === 10 ){
      let day = data.value.substring(0,2);
      let month = parseInt(data.value.substring(3,5));
      let year = data.value.substring(6,10);
      let date = new Date(year,month-1,day);
      this.dp_dateOfEstablishment.selected = date;
      this.onDateChange(date);
    }

    let value = (data.value === null) ? '' : data.value; //sets null data to empty string

    this.formIsEditing(true);

    this.setState({dateOfEstablishment:value})
  }

  returnDateFormatToFormInput(date){
    let day = date.getDate().toString();
    let month = date.getMonth() + 1;
        month = month.toString();
    let year = date.getFullYear().toString();

    if(day.length === 1){
      day = '0'+day;
    }
    if(month.length === 1){
      month = '0'+month;
    }

    return {day:day,month:month,year:year}
  }

  //Show hover on logo div
  hoverLogo() {
    this.setState({
      showHover: true
    });
  }

  //Hide hover on logo div
  leaveLogo() {
    this.setState({
      showHover: false
    });
  }

  //Render the text in the logo container
  renderLogoMessage() {
    if(this.state.showHover && this.state.editMode) {
      return(
        <div className='img-company-overlay' onClick={() => this.openPicker()}>
          Actualizar
        </div>
      );
    } else {
      return null;
    }
  }
  openPicker(){
    if(this.state.editMode){
      this.imagePicker.openPicker();
    }
  }
  render(){
    //const date picker custom btn
    const CustomDateInput = ({value, onClick, onChange,  ...props }) => (
      <button className={this.state.editMode ? 'btnCalendar' : 'date-picker-non-display'} onClick={onClick}>
        <i className="mdi mdi-calendar" style={{fontSize: '20px', color: '#67757c'}}></i>
      </button>
    );

    return (
      <div className="card-body">
        <div className="row view-subtittle">
          <div className="col-md-9">
            <p>Una startup es una organización temporal que se encuentra en busca de un modelo de negocio escalable y repetible.</p>
          </div>
        </div>
        <div className="form-horizontal">
          { this.renderAddInfoButton() }

          <div className='form-group'>
            <label>Logo</label>
            <ImagePicker ref={ref => this.imagePicker = ref} getCroppedImage={this.getImage}/>
            <div className={'rounded selectLogo noselect'} onMouseEnter={this.hoverLogo} onMouseLeave={this.leaveLogo}>
              {this.renderLogoMessage()}
              <KImage src={this.state.logoName} type='company' className={'rounded img-fluid selectLogo'} alt="company_image"/>
            </div>
            {this.state.editMode ? <small className="text-muted">Te recomendamos que este sea de al menos 500px por 500px (cuadrado)</small> : null}
          </div>

          <div className='form-group form-horizontal wrapperDateCalendar m-b-0p' style={{overflow: 'visible'}}>
            <div className="widthInputCalendar">
              <FormInput
                ref={ref => this.fi_date = ref}
                id="Inicio de operaciones"
                label="Inicio de operaciones"
                model="dateOfEstablishment"
                showCounter={false}
                editMode={this.state.editMode}
                value={this.state.dateOfEstablishment}
                initialValue={this.state.dateOfEstablishmentInitialValue}
                help="Pon la fecha aproximada de cuándo hiciste la primera venta. En caso de que no hayas tenido ventas todavía, puedes poner la fecha de hoy (dd-mm-aaaa)"
                transformMethod={editDateMask}
                transformDisplayMethod={editDateMask}
                onChange={ change => { this.onChangeWrapperDate(change)} }
                onClick={() => {this.dp_dateOfEstablishment.setOpen(true); this.setState({isCalendarOpen:true})}}
                placeholder="Inicio de operaciones"
              />
            </div>
            <DatePicker
              ref={ref => this.dp_dateOfEstablishment = ref}
              selected={this.state.formData.dateOfEstablishment}
              onSelect={this.onDateSelected}
              onChange={this.onDateChange}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale="es"
              dateFormat="dd MMMM yyyy"
              disabled={!this.state.editMode}
              className={this.state.editMode ? 'form-control form-control-line' : 'date-picker-non-editable'}
              maxDate={addDays(new Date(), 0)}
              placeholderText="Selecciona la fecha de inicio de operaciones"
              customInput={<CustomDateInput />}
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "-25px, -10px"
                },
              }}
            />
          </div>

          <FormTextArea
            ref={ref => this.fi_market = ref}
            id="mercado"
            label="Mercado meta"
            model="market"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.market}
            initialValue={this.props.company.market}
            help="Identifica quién es tu mercado meta (usuarios y clientes), su definición puede incluir edad, ubicación, sexo, estudios, hobbies, estado civil, ingresos, etc (Mínimo 5 caracteres)."
            moreHelp="Puedes analizar a tu competencia y buscar ellos como definen su mercado, debes de ser lo más especifico posible. El poner “personas adultas de clase socioeconómica b” no sirve de nada, ademas de que las campañas de marketing te costarán una fortuna. En tus entrevistas de validación identifica prospectos que te comenten cosas como: “esto es perfecto, me encanta, ¿donde dices que lo venden?”"
            maxLength={500}
            validationRules="minLen:5|maxLen:500"
            onChange={ change => { this.onChangeForm(change) } }
            />

          <FormTextArea
            ref={ref => this.fi_business_model = ref}
            id="modelo"
            label="Modelo de negocios"
            model="business_model"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.business_model}
            initialValue={this.props.company.business_model}
            help="Un modelo de negocios define tres cosas 1) ¿Qué vendes?, 2) ¿A quién se lo vendes? Y 3) ¿Cómo y cuánto le cobras?, lo cual debes de explicar utilizando cifras de ser posible (Mínimo 3 caracteres)."
            moreHelp="Igualmente puedes explicar el proceso de validación que utilizaste para llegar a este modelo de negocio, ¿cómo hiciste las entrevistas?, ¿a quién se las hiciste?, ¿qué encontraste y cómo adaptaste tu producto?. Lo más importante es recolectar evidencia, es lo único que bajará el riesgo al iniciar un negocio."
            maxLength={500}
            validationRules="minLen:3|maxLen:500"
            onChange={ change => { this.onChangeForm(change) } }
            />

          <div className='form-group' style={{margin: '0 0 10px 0'}}>
            <label>Necesidades de tu empresa</label>
            {this.renderSmallLabelSeeking()}
          </div>

          <div className="form-group" data-toggle="buttons" style={{margin: '0px'}}>
            <label>
              <input
                type="checkbox"
                id="md_checkbox_21"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_funding'
                checked={this.state.formData.stats_is_seeking_funding}
                value={this.state.formData.stats_is_seeking_funding}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}/>
              <label htmlFor="md_checkbox_21"
                className={this.state.formData.stats_is_seeking_funding ? "" : "text-muted"}>
                Financiamiento
              </label>
            </label>
            <div className={this.state.formData.stats_is_seeking_funding ? "form-group m-l-40": "showButton"}>
              <FormInput
                ref={ref => this.fi_stats_funding_amount_seeked = ref}
                id="financiamiento"
                label="Monto requerido"
                model="stats_funding_amount_seeked"
                cssClass="custom-class"
                editMode={this.state.editMode}
                value={this.state.formData.stats_funding_amount_seeked}
                initialValue={this.props.company.stats_funding_amount_seeked}
                help="Escribe la cantidad de capital que necesitas en pesos MXN (sin comas o decimales)."
                maxLength={20}
                validationRules="isNumber"
                transformMethod={transformToMoney}
                transformDisplayMethod={ transformToMoneyFormat }
                onChange={ change => { this.onChangeForm(change) } }
                />
            </div>
            <div className={this.state.formData.stats_is_seeking_funding ? "form-group m-l-40": "showButton"}>
              <FormInput
                ref={ref => this.fi_stats_funding_seeking_reason = ref}
                id="razón de financiamiento"
                label="Uso del recurso"
                model="stats_funding_seeking_reason"
                cssClass="custom-class"
                editMode={this.state.editMode}
                value={this.state.formData.stats_funding_seeking_reason}
                initialValue={this.props.company.stats_funding_seeking_reason}
                help="Detalla el uso que le darás a este recurso (capital de trabajo, marketing, contratación de personal, etc). Se lo más especifico que puedas."
                maxLength={300}
                validationRules="minLen:5|maxLen:300"
                onChange={ change => { this.onChangeForm(change) } }
                />
            </div>
          </div>

          <div className="form-group" data-toggle="buttons" style={{margin: '0 0 15px 0'}}>
            <label>
              <input
                type="checkbox"
                id="md_checkbox_22"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring'
                checked={this.state.formData.stats_is_seeking_mentoring}
                value={this.state.formData.stats_is_seeking_mentoring}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}/>
              <label htmlFor="md_checkbox_22"
                className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>
                Consultoría (capacitación)
              </label>
            </label>
            <div className={this.state.formData.stats_is_seeking_mentoring ? "form-group m-l-40": "showButton"}>
              <div className="demo-radio-button">
              <input
                type="checkbox"
                id="cb_marketing"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_marketing'
                value={this.state.formData.stats_is_seeking_mentoring_marketing}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_marketing}

              />
              <label htmlFor="cb_marketing" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Marketing</label>
              <input
                type="checkbox"
                id="cb_sales"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_sales'
                value={this.state.formData.stats_is_seeking_mentoring_sales}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_sales}
              />
              <label htmlFor="cb_sales" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Ventas</label>
              <input
                type="checkbox"
                id="cb_legal"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_legal'
                value={this.state.formData.stats_is_seeking_mentoring_legal}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_legal}
              />
              <label htmlFor="cb_legal" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Legal</label>
              <input
                type="checkbox"
                id="cb_fiscal"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_fiscal'
                value={this.state.formData.stats_is_seeking_mentoring_fiscal}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_fiscal}
              />
              <label htmlFor="cb_fiscal" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Fiscal</label>
              <input
                type="checkbox"
                id="cb_financing"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_financing'
                value={this.state.formData.stats_is_seeking_mentoring_financing}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_financing}
              />
              <label htmlFor="cb_financing" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Financiamiento</label>
              <input
                type="checkbox"
                id="cb_accounting"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_accounting'
                value={this.state.formData.stats_is_seeking_mentoring_accounting}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_accounting}
              />
              <label htmlFor="cb_accounting" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Contabilidad</label>
              <input
                type="checkbox"
                id="cb_hr"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_hr'
                value={this.state.formData.stats_is_seeking_mentoring_hr}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_hr}
              />
              <label htmlFor="cb_hr" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Recursos Humanos</label>
              <input
                type="checkbox"
                id="cb_business_plan"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_business_plan'
                value={this.state.formData.stats_is_seeking_mentoring_business_plan}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_business_plan}
              />
              <label htmlFor="cb_business_plan" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Plan de negocios</label>
              <input
                type="checkbox"
                id="cb_trademark"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_trademark'
                value={this.state.formData.stats_is_seeking_mentoring_trademark}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_trademark}
              />
              <label htmlFor="cb_trademark" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Registro de Marca</label>
              <input
                type="checkbox"
                id="cb_patent"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_patent'
                value={this.state.formData.stats_is_seeking_mentoring_patent}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_patent}
              />
              <label htmlFor="cb_patent" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Patente</label>
              <input
                type="checkbox"
                id="cb_institutional_image"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_institutional_image'
                value={this.state.formData.stats_is_seeking_mentoring_institutional_image}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_institutional_image}
              />
              <label htmlFor="cb_institutional_image" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Imagen Institucional</label>
              <input
                type="checkbox"
                id="cb_processes"
                className="filled-in chk-col-light-blue"
                name='stats_is_seeking_mentoring_processes'
                value={this.state.formData.stats_is_seeking_mentoring_processes}
                onChange={this.searchOfStatus}
                disabled={!this.state.editMode}
                checked={this.state.formData.stats_is_seeking_mentoring_processes}
              />
              <label htmlFor="cb_processes" className={this.state.formData.stats_is_seeking_mentoring ? "": "text-muted"}>Procesos</label>
            </div>
            </div>
            <div className="form-group" data-toggle="buttons" style={{ margin: '0 0 15px 0' }}>
              <label>
                <input
                  type="checkbox"
                  id="md_checkbox_23"
                  className="filled-in chk-col-light-blue blue-cb"
                  name='stats_is_seeking_team'
                  checked={this.state.formData.stats_is_seeking_team}
                  value={this.state.formData.stats_is_seeking_team}
                  onChange={this.searchOfStatus}
                  disabled={!this.state.editMode} />
                <label htmlFor="md_checkbox_23" className={this.state.formData.stats_is_seeking_team ? "" : "text-muted"}>Equipo (socios, colaboradores, etc)</label>
              </label>
              <div className={this.state.formData.stats_is_seeking_team ? "form-group m-l-40" : "showButton"}>
                <FormInput
                  ref={ref => this.fi_stats_team_seeking_reason = ref}
                  id="tipo de equipo"
                  label="Equipo"
                  model="stats_team_seeking_reason"
                  cssClass="custom-class"
                  editMode={this.state.editMode}
                  value={this.state.formData.stats_team_seeking_reason}
                  initialValue={this.props.company.stats_team_seeking_reason}
                  help="Describe si estas buscando socios, desarrolladores, vendedores, etc. Se lo más especifico que puedas (Mínimo 5 caracteres)."
                  maxLength={300}
                  validationRules="minLen:5|maxLen:300"
                  onChange={change => { this.onChangeForm(change) }}
                />
              </div>
            </div>
          </div>

          <FormTextArea
            ref={ref => this.fi_achievements = ref}
            id="logros"
            label="Historia de tu empresa"
            model="achievements"
            cssClass="custom-class"
            editMode={this.state.editMode}
            value={this.state.formData.achievements}
            initialValue={this.props.company.achievements}
            help="Para los jueces e inversionistas es muy importante entender el contexto de cómo inicio tu empresa. De igual manera puedes mencionar algún logro que tu y tu equipo hayan tenido trabajando en esta empresa (Mínimo 5 caracteres)."
            maxLength={300}
            validationRules="minLen:5|maxLen:300"
            onChange={ change => { this.onChangeForm(change) } }
            />
            <KFileField
              editMode={this.state.editMode}
              title={"Presentación de tu startup (Pitch deck)"}
              subtitle={"Sube un archivo PDF con el pitch de tu startup"}
              moreHelp={"Te recomendamos que esta presentación no sea mayor a 12 slides. Teniendo el siguiente orden: 1) Portada, 2) Misión y visión de la empresa, 3) Equipo, 4) Problema, 5) Solución, 6) Ventaja competitiva, 7) Mercado meta, 8) Ventas, 9) Competencia, 10) Logros, 11) Conclusión, 12) Necesidades de financiamiento. En caso de que tu presentación sea mayor a 5mb, puedes agregar una url (Dropbox, Google Drive, One Drive, etc). No olvides dar los permisos necesarios para que tu aliado o juez pueda visualizarla correctamente."}
              fileNotUploadedTitle={"No has subido un pitch de tu startup"}
              downloadFileTitle={"Descargar archivo"}
              uploadedFileUrl={this.state.formData.pitch_file}
              onFileSelected={this.onFileSelected}
              >
            </KFileField>
            {this.renderPitchURL()}
            <KSocialNetworksField
              editMode={this.state.editMode}
              model="social_networks"
              title={"Redes sociales de tu empresa"}
              subtitle={"Sube los links de las redes sociales de tu empresa"}
              moreHelp={"Si aun no cuentas con las redes sociales de tu empresa te recomendamos que las generes para darle presencia a tu proyecto."}
              socialNetworks={this.state.formData.social_networks}
              onChange={ change => { this.onChangeSocialNetworks(change) } }
            />
          { this.renderActionButton() }
        </div>
      </div>
    )
  }
  getPitchURL(){
    if(this.props.company.pitch_file.contains("https://komunidaddiag.blob.core.windows.net/komunidad-files")){
      return null;
    }else{
      return this.props.company.pitch_file;
    }
  }
  renderPitchURL(){
    if(this.state.editMode){
      return (
        <FormTextArea
          ref={ref => this.fi_pitch_link = ref}
          id="Url de tu pitch"
          label="O agrega la URL de tu pitch"
          model="pitch_link"
          cssClass="custom-class"
          editMode={this.state.editMode}
          value={this.state.formData.pitch_link}
          initialValue={this.props.company.pitch_link}
          help="También puedes agregar una URL a tu pitch aquí"
          maxLength={300}
          validationRules="url"
          onChange={ change => { this.onChangeForm(change) } }
          />
      )
    }else{
      return (null);
    }

  }
  onFileSelected(selectedFile){
    this.formIsEditing(true);
    let pitch_file = selectedFile.file.target.files[0];
    this.setState({selectedFile:pitch_file});
  }
  openLoadingSwal() {
    Swal.fire({
      title: 'Tus datos están siendo guardados',
      customClass: {
        title: 'swal-custom-title',
      },
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  openCancelSwal() {
    Swal.fire({
      title: '¿Deseas cancelar?',
      text: "Los datos que no han sido guardados se perderán",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then(res => {
      if (res.value) this.cancelEdit();
    })
  }

}

KTabStartupContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
  /** True while the company is being edited */
  isTabEditing   : PropTypes.func,
  /** Callback to update the company data*/
  refreshCompany : PropTypes.func,
  /** True if the information can't be edited*/
  nonEditable    : PropTypes.bool,
};

KTabStartupContent.defaultProps = {
  company        : {},
  isTabEditing   : () => {},
  refreshCompany : () => {},
  nonEditable    : false,
};

export default KTabStartupContent;
