/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, February 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import { isUserProfileComplete } from '../../utils/Utils.js';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import FormInput from '../../components/form/FormInput';
import { Button, Tab, Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';

class CreateResourceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      instructionsPlatform:'iphone', //iphone,android
      me:{},
      step:'instructions', //instructions,qr_code,confirm,done
      qr_error:'',
      isValidWhatsappGroupLink:false,
      isValidWhatsappGroupName:false,
      group_name:'',
      group_link:'',
      resourceName:'',
      resourceLink:'',
      resourceDescription:'',
      users:[]
    }
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.renderInstructions            = this.renderInstructions.bind(this);
    this.renderStep                    = this.renderStep.bind(this);
    this.goToStep                      = this.goToStep.bind(this);
    this.renderConfirm                 = this.renderConfirm.bind(this);
    this.isFormValid                   = this.isFormValid.bind(this);
    this.confirmResource               = this.confirmResource.bind(this);
    this.onChangeForm                  = this.onChangeForm.bind(this);
    this.handleResizeBar               = this.handleResizeBar.bind(this);
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(res.user.roles.includes("manager") || res.user.roles.includes("facilitator")){
            //ok
          }else{
            this.props.history.push('/profile');
          }
          this.setState({
            me:res.user,
            authorized:true
          });
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
    });
    window.addEventListener("resize", this.handleResizeBar);
    this.handleResizeBar()
  }
  goToStep(step){
    this.setState({
      step:step
    })
  }
  onChangeForm(data) {
    this.setState({
      [data.model]: data.value
    })
  }
  isFormValid() {
    return this.resource_name.validate() && this.resource_link.validate();
  }
  confirmResource() {
    if(this.isFormValid()){
      KomunidadApi.createResource(this.state.resourceName, this.state.resourceLink, this.state.resourceDescription, this.props.location.state.program_id).then( (res) =>{
        if(res.success){
          Swal.fire({
            title:'Recurso registrado',
            text: 'Se ha registrado el recurso para el programa',
            type: 'success',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          }).then((result) => {
            this.props.history.push('/manager/programs/' + this.props.location.state.program_id);
          })
        }else{
          Swal.fire({
            title:'Recurso no registrado',
            text: 'No se pudo registrar el recurso. Intenta mas tarde',
            type: 'error',
            customClass: {
              confirmButton: 'swalBtnTeal',
              title: 'swal-custom-title',
            },
          })
        }
      });
    } else {
      //TODO: Hint
    }
  }

  renderInstructions() {
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
            <h3 className="text-themecolor">Instrucciones:</h3>
            <div style={{height:"100%"}} className="m-t-30">
              <Tab.Container defaultActiveKey='gdrive'>
                <Nav className="nav" bsPrefix='tabCustom'>
                  <Nav.Item className="nav-item">
                    <Nav.Link className="nav-link" eventKey="gdrive" id="iphone">Añade un recurso</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="tab-content m-t-10">
                  <Tab.Pane eventKey="gdrive">
                   <div className='row'>
                      <div className='col-lg-6'>
                        <div style={{minHeight:40}}><b>1.-</b>&nbsp;Abre tu cuenta de <b>Google Drive, Dropbox, One Drive, etc.</b></div>
                        <div style={{minHeight:40}}><b>2.-</b>&nbsp;<b>Identifica la carpeta o archivo</b> que quieres compartir</div>
                        <div style={{minHeight:40}}><b>3.-</b>&nbsp;Genera el <b> enlace para compartir</b> el recurso</div>
                        <div style={{minHeight:40}}><b>4.-</b>&nbsp;<b>Copia el link</b> que se genera</div>
                        <div style={{minHeight:40}}><b>5.-</b>&nbsp;<b>Agrega una descripción</b> del contenido del recurso.</div>
                        <div style={{minHeight:40}}><b>6.-</b>&nbsp;Da clic en <b>Siguiente</b></div>
                        <Button block className="btn btn-success btn-save m-t-30 col-lg-4" onClick={() =>this.goToStep('confirm')}>
                          Siguiente
                        </Button>
                      </div>
                      <div className='col-lg-6'>
                        {/*<video width="100%" height="300" autoplay controls style={{backgroundColor:"black"}}>
                          <source src="https://komunidaddiag.blob.core.windows.net/komunidad-assets/Komunidad_resources_drive.mp4" type="video/mp4"/>
                        </video>*/}

                      </div>
                   </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderConfirm(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-8">
            <h3 className="text-themecolor">Instrucciones:</h3>

            <div style={{height:"100%"}} className="m-t-30">
              <div style={{minHeight:40}}><b>1.-</b>&nbsp;Escribe el nombre del recurso en el siguiente campo de texto:</div>
              <div className='form-group'>
                <FormInput
                  ref={ref => this.resource_name = ref}
                  id="Nombre del recurso"
                  label="Nombre del recurso"
                  model="resourceName"
                  showCounter={false}
                  value={this.state.resourceName}
                  initialValue={''}
                  required={true}
                  validationRules="required"
                  onChange={ change => { this.onChangeForm(change) } }
                  errorPosition="top"
                />
              </div>
              <div style={{minHeight:40}}><b>2.-</b>Coloca el link del recurso en el siguiente campo de texto:</div>
              <div className='form-group'>
                <FormInput
                  ref={ref => this.resource_link = ref}
                  id="Link del recurso"
                  label="Link del recurso"
                  model="resourceLink"
                  showCounter={false}
                  value={this.state.resourceLink}
                  initialValue={''}
                  required={true}
                  validationRules="required|url"
                  onChange={ change => { this.onChangeForm(change) } }
                  errorPosition="top"
                />
              </div>
              <div style={{minHeight:40}}><b>3.-</b>Coloca una descripción del recurso en el siguiente campo de texto:</div>
              <div className='form-group'>
                <FormInput
                  ref={ref => this.resource_description = ref}
                  id="Descripción del recurso"
                  label="Descripción del recurso"
                  model="resourceDescription"
                  showCounter={false}
                  value={this.state.resourceDescription}
                  initialValue={''}
                  required={false}
                  onChange={ change => { this.onChangeForm(change) } }
                  errorPosition="top"
                />
              </div>
              <div style={{minHeight:40}}><b>4.-</b>Cuando estés listo da clic en "Registrar recurso". Los emprendedores ahora podrán ver la carpeta compartida en la vista de Recursos.</div>
              <div className="col-lg-4 p-0">
                <Button block className="btn btn-success btn-save m-t-30" onClick={() =>this.confirmResource()}>
                  Registrar recurso
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  renderStep() {
    if(this.state.step === "instructions"){
      return this.renderInstructions();
    }else if(this.state.step === "confirm"){
      return this.renderConfirm();
    }else{
      return (null);
    }
  }
  render(){
   if(this.state.authorized){
     return(
      <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
        <div id="main-wrapper" className="height100">
          <Header
            user={this.state.me}
            onBurgerClicked={() => this.handleResizeBar('byClick')}
            onResize={(w) => this.handleHeaderResize(w)}
            hideBurguer={this.state.showTopbar}
          />
          <TopSideBar
            user={this.state.me}
            hideTopBar={!this.state.showTopbar}
          />
          <SideBar 
            user={this.state.me} 
            visible={this.state.sidebarStatus} 
            hideSideBar={this.state.showTopbar}
          />
          {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
             <div className="container-fluid">
               <div className="row">
                 <div className="col-lg-12 col-sm-12">
                   <div className="card">
                        {this.renderStep()}
                   </div>
                 </div>
               </div>
             </div>
            <Footer/>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default CreateResourceView;
