/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Darien Miranda <darien@startuplab.mx>, March 2020
 */

// Regular imports
import React from 'react';
import KomunidadApi from '../../api/api.js';
import EventBriteApi from '../../api/EventBriteApi.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import { isUserProfileComplete } from '../../utils/Utils.js';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

//Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import TopSideBar from '../../components/TopSideBar';
import Footer from '../../components/Footer';
import EBListEvents from '../../components/eventbrite/EBListEvents';
import EBListOrganizations from '../../components/eventbrite/EBListOrganizations';
import KTableSmartSelect from '../../components/KTableSmartSelect';
import BounceLoader from 'react-spinners/BounceLoader';
import ProgramsPicker from '../../components/ProgramsPicker';


class EventbriteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized:false,
      sidebarVisible: true,
      sidebarStatus: false,
      showTopbar: true,
      me:{},
      eventbriteToken:null,
      organizations:[],
      events:[],
      attendees:[],
      selectedAttendees:[],
      selectedPrograms:[],
      pickedEvent:null,
      error:null,
      pickedOrganization:{
        _type:null,
        name:"",
        vertical:"",
        parent_id:null,
        locale:null,
        image_id:null,
        id:null
      },
      currentStep:"loading", //pickOrganization, pickEvent, pickUsers, response, loading, error, auth
      attendeesInvitationsResults:[]
    }
    this.handleResizeBar               = this.handleResizeBar.bind(this);
    this.handleHeaderResize            = this.handleHeaderResize.bind(this);
    this.redirectToEditProfile         = this.redirectToEditProfile.bind(this);
    this.renderContent                 = this.renderContent.bind(this);
    this.renderEvents                  = this.renderEvents.bind(this);
    this.renderAttendees               = this.renderAttendees.bind(this);
    this.onCbAttendeesClick            = this.onCbAttendeesClick.bind(this);
    this.handleSelectAttendee          = this.handleSelectAttendee.bind(this);
    this.inviteAttendees               = this.inviteAttendees.bind(this);
    this.goToStep                      = this.goToStep.bind(this);
    this.renderResponse                = this.renderResponse.bind(this);
    this.goToPrograms                  = this.goToPrograms.bind(this);
    this.onChangeProgram               = this.onChangeProgram.bind(this);
    this.smartSelectAttendeesOptions = [
      {
        label         : "Todos",
        actionType    : "all",
        actionName    : "all",
      },
      {
        label         : "Ninguno",
        actionType    : "none",
        actionName    : "none",
      },
    ];
  }

  componentDidMount(){
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){
        if(!isUserProfileComplete(res.user)){
          this.redirectToEditProfile();
        }else{
          if(res.user.roles.includes("manager") || res.user.roles.includes("facilitator")){
            //ok
          }else{
            this.props.history.push('/profile');
          }
          this.setState({
            me:res.user,
            authorized:true
          });
        }
      }else{
        //redirect profile
        this.props.history.push('/profile');
      }
      window.addEventListener("resize", this.handleResizeBar);
      this.handleResizeBar()
    });


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');
    this.setState({
      eventbriteToken:token
    });
    EventBriteApi.getOrganizations(token).then((res) => {
      if(res.hasOwnProperty("organizations")){
        let totalOrganizations = res.pagination.object_count;
        if(totalOrganizations === 1){
          this.setState({
            pickedOrganization:res.organizations[0],
            organizations:res.organizations,
            currentStep:'pickEvent'
          }, () => {
            this.getEvents();
          });
        }else{
          this.setState({
            organizations:res.organizations,
            currentStep:'pickOrganization'

          });
        }
      }else{
      }
    });
  }
  async getAttendees(event){
    this.setState({
      currentStep:"loading"
    })
    let has_more_items = true;
    let attendees = [];
    let continuation = null;
    let event_id = event.id;
    while(has_more_items){
      await EventBriteApi.getAttendees(event_id,this.state.eventbriteToken,continuation).then((res) => {
          if(res.hasOwnProperty("attendees")){
            attendees = attendees.concat(res.attendees);
            if(res.pagination.hasOwnProperty("continuation")){
              continuation = res.pagination.continuation;
            }
            has_more_items = res.pagination.has_more_items;
          }else{
            has_more_items = false;
          }
      });
    }
    this.setState({
      attendees:attendees,
      pickedEvent:event,
      currentStep:'pickUsers'
    })
  }
  async getEvents(){
    let has_more_items = true;
    let events = [];
    let continuation = null;
    while(has_more_items){
      await EventBriteApi.getEvents(this.state.pickedOrganization.id,this.state.eventbriteToken,continuation).then((res) => {
          if(res.hasOwnProperty("events")){
            events = events.concat(res.events);
            if(res.pagination.hasOwnProperty("continuation")){
              continuation = res.pagination.continuation;
            }
            has_more_items = res.pagination.has_more_items;
          }else{
            has_more_items = false;
          }
      });
    }
    this.setState({
      events:events,
      currentStep:'pickEvent'
    })
  }

  onChangeProgram(programs){
    this.setState({selectedPrograms:programs});
  }

  renderLoader(){
    return(
      <div className="eb-loader-container">
        <center>
          <div className='m-b-20'>.</div>
          <BounceLoader
             sizeUnit={"px"}
             size={150}
             color={'#3cc'}
             loading={this.props.loading}
          />
        </center>
      </div>

    )
  }

  redirectToEditProfile(){
    this.props.history.push('/profile/edit');
  }

  handleHeaderResize(width){
    this.setState({
        sidebarVisible:width <= 500 ? false : true
      });
  }

  handleResizeBar(method) {
    const windowSize = window.innerWidth;
    if(method === 'byClick') {
      this.setState({
        sidebarStatus: !this.state.sidebarStatus,
      })
    } else {
      if(windowSize <= 994) {
        this.setState({
          sidebarStatus: true,
          showTopbar: false
        })
      } else {
        this.setState({
          sidebarStatus: false,
          showTopbar: true
        })
      }
    }
  }

  onChangeInput(data) {
    this.setState({[data.target.name]:data.target.checked});
  }
  onClickOrganization(organization){
    this.setState({
      pickedOrganization:organization,
      currentStep:'loading'
    }, () =>{
      this.getEvents();
    });
  }
  renderOrganizations(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
          <h3 className="text-themecolor">Selecciona una organización:</h3>
            <EBListOrganizations organizations={this.state.organizations}
                                 requestStatus='success'
                                 onClickOrganization={(organization) => {this.onClickOrganization(organization)}}>
            </EBListOrganizations>
          </div>
        </div>
      </div>
    )
  }
  renderEvents(){
    return (
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
            <div className="d-inline-flex">
              <div className="eb-back" onClick={() => this.goToStep('pickOrganization')}>
                <FontAwesomeIcon className="mt-2 m-b-10" icon="arrow-left" />
              </div>
              <h3 className="text-themecolor">Selecciona un evento:</h3>
            </div>
            <EBListEvents events={this.state.events} requestStatus='success' onClickEvent={(event) =>{this.getAttendees(event)}}> </EBListEvents>
          </div>
        </div>
      </div>
    )
  }
  handleSelectAttendee(row, isSelect){
    if (isSelect) {
      this.setState(state => {
        const selectedAttendees = [...state.selectedAttendees, row];
        return {
          selectedAttendees,
        };
      }, () => {
        if(this.state.selectedAttendees.length === this.state.attendees.length){
          this.setState({cb_state_partners: "all"});
          this.kTableSmartSelectAttendees.changeCbState("all");
        }else{
          this.setState({cb_state_partners: "mixed"});
          this.kTableSmartSelectAttendees.changeCbState("mixed");
        }
      });
    } else{
      this.setState(() => ({
        selectedAttendees: this.state.selectedAttendees.filter(x => x !== row),
      }), () => {
        if(this.state.selectedAttendees.length === 0){
          this.setState({cb_state_partners: "none"});
          this.kTableSmartSelectAttendees.changeCbState("none");
        }else{
          this.setState({cb_state_partners: "mixed"});
          this.kTableSmartSelectAttendees.changeCbState("mixed");
        }
      });
    }
  }
  onOptionSelectedAttendees(option){
    let selectedRows = [];
    if(option.actionType === "all"){
      selectedRows = this.state.attendees;
    }
    if(option.actionType === "none"){
      selectedRows = [];
    }
    this.setState({
      cb_state_partners: option.actionType,
      selectedAttendees:selectedRows
    });
  }
  onCbAttendeesClick(state){
    if(state === "all"){
      this.onOptionSelectedAttendees(this.smartSelectAttendeesOptions[0]);
    }else{
      this.onOptionSelectedAttendees(this.smartSelectAttendeesOptions[1]);
    }
  }
  inviteAttendees(){
    let users = this.state.selectedAttendees.map( a => {
      //--TRY TO PARSE BIRTH OF DATE--------------------------------------------
      let birth_date = null;
      if(a.profile.hasOwnProperty("birth_date")){
        if(a.profile.birth_date.length === 10){
          try{
            birth_date = new Date(a.profile.birth_date);
            if(isNaN(birth_date.getTime())){
              //If the date is invalid, then fallback to null
              birth_date = null;
            }
          }catch(e){
            //Couldn't parse bday. Bday is null
            birth_date = null;
          }
        }
      }
      //--TRY TO PARSE GENDER---------------------------------------------------
      let gender = null;
      if(a.profile.hasOwnProperty("gender")){
        switch (a.profile.gender) {
          case "male":
            gender = "male";
            break;
          case "hombre":
            gender = "male";
            break;
          case "female":
            gender = "female";
            break;
          case "mujer":
            gender = "female";
            break;
          default:
            gender = null;
        }
      }
      //--TRY TO PARSE PHONE NUMBER---------------------------------------------
      let phone = null;
      if(a.profile.hasOwnProperty("home_phone")){
        try{
          let home_phone = a.profile.home_phone;
          home_phone = home_phone.replace(/[^0-9.]/g,"")
          if(home_phone.length === 10){
            phone = home_phone;
          }
        }catch(e){

        }
      }
      if(a.profile.hasOwnProperty("cell_phone")){
        try{
          let cell_phone = a.profile.cell_phone;
          cell_phone = cell_phone.replace(/[^0-9.]/g,"")
          if(cell_phone.length === 10){
            phone = cell_phone;
          }
        }catch(e){

        }
      }

      return {
        "email":a.profile.email,
        "name":a.profile.first_name,
        "last_name_father":a.profile.last_name,
        "dateOfBirth":birth_date,
        "gender":gender,
        "phone":phone,
        "valid":true
      }

    });
    let roles = ["partner"].join("|");
    let programs_ids = this.state.selectedPrograms.join("|");
    let eventName = this.state.pickedEvent.name.text;
    users = {users:users};
    KomunidadApi.inviteUsersMassive(JSON.stringify(users),roles,programs_ids,eventName).then((res) => {
      if(res.success){
        this.setState({attendeesInvitationsResults:res.results,currentStep:'response'});
      }else{
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al enviar las invitaciones',
          type: 'error',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
    });
  }
  renderResponse(){
    const columns = [
      {
        dataField: 'email',
        text: 'Email',
      },
      {
        dataField: 'last_name_father',
        text: 'Apellido(s)',
      },
      {
        dataField: 'name',
        text: 'Nombre',
      },
      {
        dataField: 'status',
        text: 'Estado',
        formatter: (cellContent) => {
          const status = {
            user_exists:'Usuario ya registrado',
            invitation_updated:'Invitación enviada',
            errored:'Error al registrar',
            invited:'Invitación enviada'
          }
          const className={
            user_exists:'text-danger',
            invitation_updated:'text-success',
            errored:'text-danger',
            invited:'text-success'
          }

          return <span className={className[cellContent]}>{status[cellContent]}</span>
        }
      },
    ];
    return(
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
          <h3 className="text-themecolor">Resultado de invitaciones</h3>
            <div style={{display:"flex",alignItems:"center",flexDirection:"row-reverse"}}>
              <div style={{marginLeft:"20px"}}>
              </div>
              <div className="eb-back" onClick={() => this.goToStep('pickEvent')}>
                <FontAwesomeIcon className="m-t-20 m-b-20" icon="arrow-left" />
              </div>

            </div>
            <div>
              <BootstrapTable
                bootstrap4
                striped
                keyField='id'
                data={ this.state.attendeesInvitationsResults }
                columns={ columns }
                wrapperClasses="table-responsive"
                classes="react-bootstrap-table"
              />
            </div>


          </div>
        </div>
        <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
          <div style={{marginRight:"20px"}}>
            <Button variant="link"
                    onClick={() => this.goToStep('pickEvent')}>
                    Seguir invitando
            </Button>
            <Button className="btn btn-primary btn-save"
                    onClick={() => this.goToPrograms()}>
                    Finalizar
            </Button>
          </div>
        </div>
      </div>
    )
  }
  goToStep(step){
    this.setState({
      currentStep:step
    })
  }
  goToPrograms(){
    this.props.history.push('/manager/programs');
  }
  renderAttendees(){
    const columns = [
      {
        dataField: 'profile.email',
        text: 'Email',
      },
      {
        dataField: 'profile.last_name',
        text: 'Apellido(s)',
      },
      {
        dataField: 'profile.first_name',
        text: 'Nombre',
      }
    ];
    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleSelectAttendee,
      selectColumnPosition: 'right',
      style: { background: '#e6fbff' },
      headerColumnStyle:{paddingLeft:'0.5rem',width:'60px'},
      selectColumnStyle:{padding:'0.5rem',width:'60px'},
      selectionHeaderRenderer: ({ mode, ...rest }) => (
        <div>
          <KTableSmartSelect
            ref={ref => this.kTableSmartSelectAttendees = ref}
            onOptionSelected={(option) => this.onOptionSelectedAttendees(option)}
            onCbClick={this.onCbAttendeesClick}
            cbState={this.state.cb_state_partners}
            options={this.smartSelectAttendeesOptions}>
          </KTableSmartSelect>
        </div>
      ),
      selected:this.state.selectedAttendees.map( u => u.id),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="custom-checkbox">
          <input className="filled-in chk-col-purple" type={ mode } { ...rest } />
          <label></label>
        </label>
      )
    };
    return(
      <div className="card-body">
        <div className="row p-l-20 p-r-20">
          <div className="col-lg-12">
          <div className="d-inline-flex">
            <div className="eb-back" onClick={() => this.goToStep('pickEvent')}>
              <FontAwesomeIcon className="mt-2 m-b-10" icon="arrow-left" />
            </div>
            <h3 className="text-themecolor">Selecciona a los asistentes a invitar a Komunidad.io:</h3>
          </div>
          <div className="col-md-6">
           <div className='form-group m-t-30'>
              <label>Selecciona el programa al que los deseas invitar</label>
              <ProgramsPicker
                ref={ref => this.fi_programs = ref}
                showLabel={false}
                onChange={(program) => {this.onChangeProgram(program)}}
                showInactivePrograms={true}
                isMultiple={true}
              ></ProgramsPicker>
           </div>
         </div>
            <BootstrapTable
              bootstrap4
              striped
              keyField='id'
              data={ this.state.attendees }
              columns={ columns }
              selectRow={ selectRow }
              wrapperClasses="table-responsive"
              classes="react-bootstrap-table"
            />
          </div>
        </div>
        <div style={{display:"flex",alignItems:"end",flexDirection:"row-reverse"}}>
          <div style={{marginRight:"20px"}}>
            <Button className="btn btn-primary btn-save"
                    disabled={this.state.selectedAttendees.length === 0}
                    onClick={this.inviteAttendees}>
                    Invitar a los seleccionados
            </Button>
          </div>
        </div>
      </div>
    )
  }
  renderContent(){
    if(this.state.currentStep === "loading"){
      return this.renderLoader();
    }
    else if(this.state.currentStep === "pickOrganization"){
      return this.renderOrganizations();
    }else if(this.state.currentStep === "pickEvent"){
      return this.renderEvents();
    }else if(this.state.currentStep === "pickUsers"){
      return this.renderAttendees();
    }else if(this.state.currentStep === "response"){
      return this.renderResponse();
    }
  }
  render(){
   if(this.state.authorized){
     return(
       <div className={"fix-header card-no-border fix-sidebar height100 " + (this.state.sidebarStatus ? "mini-sidebar" : "")} >
          <div id="main-wrapper" className="height100">
            <Header
              user={this.state.me}
              onBurgerClicked={() => this.handleResizeBar('byClick')}
              onResize={(w) => this.handleHeaderResize(w)}
              hideBurguer={this.state.showTopbar}
            />
            <TopSideBar
              user={this.state.me}
              hideTopBar={!this.state.showTopbar}
            />
            <SideBar 
              user={this.state.me} 
              visible={this.state.sidebarStatus} 
              hideSideBar={this.state.showTopbar}
            />
            {/* Page wrapper  */}
            <div className={"page-wrapper color-gray " + (this.state.showTopbar ? 'topbar-wrapper-open':'')}>
              <div className="container-fluid">
               <div className="row">
                 <div className="col-lg-12 col-sm-12">
                   <div className="card">
                    {this.renderContent()}
                   </div>
                 </div>
               </div>
              </div>
            <Footer/>
           </div>
           {/* End Page wrapper  */}
         </div>

       </div>
     )
   }else{
     return(null)
   }
  }
}
export default EventbriteView;
