/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, July 2019
 */

 // Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import KomunidadApi from '../../api/api.js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Components
import KPartnersList from './../KPartnersList';

//const
const MySwal = withReactContent(Swal);


/**
 *  Display and edit the company information (Partners content).
 */

class KTabPartnersContent extends React.Component{
  constructor(props) {
    super(props);
    this.onClickDeletePartner = this.onClickDeletePartner.bind(this)
    this.onClickAddPartners   = this.onClickAddPartners.bind(this);
  }



  onClickAddPartners(open){
    this.props.onClickAddPartners(open);
  }

  onClickDeletePartner(partner){
    let partnerId = partner._id;
    let companyId = this.props.company._id;

    //Only the owner can delete partners
    //confirm if the btn is clicked
    this.confirmAlert(partnerId,companyId);
  }

  confirmAlert(partnerId,companyId){
    MySwal.fire({
      title: '¿Estás seguro(a)?',
      text: "Tu socio(a) no podrá acceder a esta empresa. Puedes volver a agregarlo después.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swalBtnRedOrange',
        cancelButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.value) {
        this.deletePartner(partnerId,companyId);
      }
    })
  }

  deletePartner(deletePartner,companyId){
    KomunidadApi.deletePartner(deletePartner,companyId).then((res) => {
      if(!res.success){
        Swal.fire({
          type: 'error',
          title: 'No pudimos remover a este usuario',
          text: res.error_es,
          footer: '',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        });
      }
      this.props.onPartnersDeleted(companyId);
    });
  }

  renderAddPartnersBtn(){
    let is_disabled = false; //if the company doesn't have the property set as false.
    const className = "btn " + this.props.className;

    //remove the btns
    if(this.props.nonEditable){
      return (null);
    }

    //check if the company is disabled
    if(this.props.company.hasOwnProperty('is_disabled')){
      is_disabled = this.props.company.is_disabled;
    }

    if(!is_disabled){
      return(
        <div className="form-actions">
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-offset-3 col-md-9">
                            <button type="submit"
                                    className={className}
                                    onClick={() => this.onClickAddPartners(true)}>
                                    <i className="mdi mdi-account" style={{fontSize: '16px'}}></i> Agregar socios
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )
    }else{
      return null
    }
  }

  render(){
    const partners = this.props.company.partners;

    return(
      <div className="card-body">
        {/* form-horizontal */}
        <div className="form-horizontal">
          <div className="row">
              <div className="col-md-12">
                <KPartnersList partners={partners}
                               isUserOwner={this.props.company.user_is_owner}
                               showExtraContactButtons={this.props.showExtraContactButtons}
                               onClickDeletePartner={this.onClickDeletePartner}/>
              </div>
          </div>
          {this.renderAddPartnersBtn()}
        </div>
        {/* form-horizontal end*/}
      </div>
    )
  }
}

KTabPartnersContent.propTypes = {
  /** Company object */
  company                 : PropTypes.object,
  /** Callback on add a partner */
  onClickAddPartners      : PropTypes.func,
  /** Callback on delete a partner */
  onPartnersDeleted       : PropTypes.func,
  /** Class for the add partner btn */
  className               : PropTypes.string,
  /** True if the information can't be edited*/
  nonEditable             : PropTypes.bool,
  /** Shows or hide extra contact buttons **/
  showExtraContactButtons : PropTypes.bool
};

KTabPartnersContent.defaultProps = {
  company                 : {},
  onClickAddPartners      : () => {},
  onPartnersDeleted       : () => {},
  className               : 'btn-info',
  nonEditable             : false,
  showExtraContactButtons : false
};

export default KTabPartnersContent;
