/* Copyright (C) Startuplab - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Juan Hernandez <juan@startuplab.mx>, April 2021
*/

// Regular imports
import React from 'react';
import PropTypes from 'prop-types';
import KomunidadApi from './../../api/api.js';
import { withRouter, Link } from "react-router-dom";
import KImage from '../KImage';

import Swal from 'sweetalert2';
import '../../scss/components/css-chart.css';

/**
*  Display my courses and available courses.
*/

class KTabCourseContent extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      companyProgramsIds:[],
      availableCourses:[],
      myCourses:[],
      arrayMyCourses:[],
      isCoursesFilter:false,
      showModal:false,
      requestStatusReviewList:'idle',
    }
    this.getCompanyCourses = this.getCompanyCourses.bind(this);
    this.getMyCourses      = this.getMyCourses.bind(this);
  }

  componentDidMount(){
    let programsIds = this.props.company.programs.map(p => p.program._id );
    this.setState({companyProgramsIds: programsIds});
    this.getMyCourses();
  }

  getMyCourses() {
    KomunidadApi.getMyCourses().then( (res) => {
      if(res.success){
        if(res.courses.length > 0){
          let filteredCourses = [];
          this.setState({arrayMyCourses: res.courses.map(c => c._id)});
          for(var c in res.courses){

            let arrayCoursesPrograms = res.courses[c].programs.map(p => p.program);
            let intersection = arrayCoursesPrograms.filter(p => this.state.companyProgramsIds.includes(p));

            if(intersection.length !== 0){
              filteredCourses = filteredCourses.concat(res.courses[c])
            }
            }
            this.setState({myCourses: filteredCourses});
          }else{
            this.setState({myCourses:[]});
          }
          this.getCompanyCourses();
      }else{    
        this.setState({myCourses: []});
      }
    });
  }

  async getCompanyCourses(){
    var programsIds = this.state.companyProgramsIds;
    let newCourses = [];

    if (programsIds.length !== 0){
      await programsIds.forEach(function(programId){
        KomunidadApi.getUserCourses(programId).then( (res) => {
          if(res.success && res.courses.length > 0){
            newCourses = newCourses.concat(res.courses)
            // Remove my courses
            newCourses = newCourses.filter(c => !this.state.arrayMyCourses.includes(c._id));
            // Filter duplicates
            newCourses = newCourses.filter((course, index, self) =>
                index === self.findIndex((c) => (
                c._id === course._id
              ))
            )
            this.setState({availableCourses: newCourses});
          }
        });     
      },this)
    }

  }

  eliminaDuplicados = (arr) => {
    return arr.filter((valor, indice) => {
      return arr.indexOf(valor) === indice;
    });
  }

  joinCourse(courseId) {
    KomunidadApi.joinCourse(courseId).then( (res) => {
      if(res.success){
        Swal.fire({
          type: 'success',
          title: 'Curso agregado',
          text: 'Ahora serás redirigido al curso',
          customClass: {
            confirmButton: 'swalBtnTeal',
            title: 'swal-custom-title',
          },
        }).then(result => {
          this.props.history.push(`/courses/${courseId}`)
        })
      } else {
        Swal.fire({
          type: 'info',
          title: 'Ya te encuentras registrado',
          text: 'Serás redirigido al material del curso',
          customClass: {
            confirmButton: "swalBtnTeal",
            title: "swal-custom-title",
          },
        }).then(result => {
          this.props.history.push(`/courses/${courseId}`)
        });
      }
    });
  }

  renderMyCourses(){
    if(this.state.myCourses.length !== 0){
      return(
        <div>
          <h5><b>Mis cursos</b></h5>
          {
            this.state.myCourses.map((val, index) => {
              let chapCount = val.chapters;
              let chapDone = val.progress;
              let percentage = Math.ceil(((chapDone/chapCount)*100)/5)*5;
              return(
                <div>
                <div key={index+"-background"} className="d-flex m-t-30">
                  <KImage src={val.bannerUrlThumbnail} className="card-img-top img-responsive" alt="course_img" type={"course"}/>
                </div>
                <div key={index} className="activity-item">
                  <div className="m-r-10">
                  <div className="m-3">
                    <div className="row h-100 justify-content-center align-items-center">
                      <div data-label={percentage+'%'} className={'css-bar css-bar-md m-b-0 css-bar-success css-bar-'+percentage} style={{zIndex: 0}}></div>
                    </div>
                  </div>
                  </div>
                  <div style={{width:'60%',marginTop:'2rem'}} className="m-t-2">
                    <h5 className="m-b-5 font-medium">
                      {val.name}
                    </h5>
                    {val.phase}
                  </div>
                  <div className="p-0 ml-auto" style={{marginTop:'2rem'}}>
                    <Link to={'/courses/'+val._id}>
                      <div className="btn btn-info btn-save">
                          Ver curso
                      </div>
                    </Link>
                  </div>
                </div>
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  renderAvailableCourses(){
    if(this.state.availableCourses.length !== 0){
      return(
        <div>
          <h5><b>Cursos disponibles</b></h5>
          {
            this.state.availableCourses.map((val, index) => {
              return(
                <div>
                <div key={index+"-background"} className="d-flex m-t-30">
                  <KImage src={val.bannerUrlThumbnail} className="card-img-top img-responsive" alt="course_img" type={"course"}/>
                </div>
                <div key={index} className="activity-item">
                  <div style={{width:'60%',marginTop:'2rem'}} className="m-t-2">
                    <h5 className="m-b-5 font-medium">
                      {val.name}
                    </h5>
                    {val.phase}
                  </div>
                  <div className="p-0 ml-auto" style={{marginTop:'2rem'}}>
                    <div className="btn btn-info btn-save" onClick={() => this.joinCourse(val._id)}>Inscribirse</div>
                  </div>
                </div>
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  renderPlaceholder(){
    if(this.state.availableCourses.length === 0 && this.state.myCourses.length === 0){
      return(
        <div className="text-center m-b-20">
          <h3>Aún no hay cursos disponibles</h3>
          <i className="mdi mdi-school m-t-20 m-b-20" style={{fontSize: '50px', color: "#c2c2c2"}}></i>
        </div>
      )
    }
  }

  render(){
    return(
      <div className="card-body">
        <div className="activity-box">
          {this.renderMyCourses()}
          {this.renderAvailableCourses()}
          {this.renderPlaceholder()}
        </div>
      </div>
    )
  }

}

KTabCourseContent.propTypes = {
  /** Company object */
  company        : PropTypes.object,
};

KTabCourseContent.defaultProps = {
  company               : {},
};

export default withRouter(KTabCourseContent);
