/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, February 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";

import KProfileSidebar from './KProfileSidebar';
import KImage from './KImage';
import CompanyPhasesChecker from '../utils/CompanyPhasesChecker.js';
import { renderFullNameOrEmail } from '../utils/Utils.js';

import company from '../assets/images/program-icon.svg';
import review from '../assets/images/mentoring-project.svg';
import mentor from '../assets/images/mentor.svg';
import partner from '../assets/images/enterpreneur.svg';


/**
 *  Render the stats of a program.
 */

class KStatsProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse:false,
      renderStats:'',//companies, reviews, mentors, partners
      isSidebarProfileOpen:false,
      userSelectedId:'',
      userProfileActiveKey:'profile'
    }
    this.handleOpenCollapse              = this.handleOpenCollapse.bind(this);
    this.handleSidebarProfile            = this.handleSidebarProfile.bind(this);
    this.openUserProfile                 = this.openUserProfile.bind(this);
    this.getUserList                     = this.getUserList.bind(this);
    this.formatDate                      = this.formatDate.bind(this);

  }

  handleOpenCollapse(open){
    this.setState({openCollapse:open});
  }

  renderStats(){
    switch (this.state.renderStats) {
      case 'companies': return this.renderCompanies(this.formatCompaniesPhase(this.props.program.registered_companies));
      case 'companies_mentored': return this.renderCompanies(this.formatCompaniesPhase(this.props.program.companies_mentored));
      case 'mentors': return this.renderPartners( 'mentors' );
      case 'partners': return this.renderPartners('partners');
      default: return null;
    }
  }

  formatDate(date){
    if(date !== ''){
      var d = new Date(date.replace('Z','')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day, month, year].join('-');
    }else{
      return '';
    }
  }

  formatCompaniesPhase(companies) {
    let formatedCompanies = companies.map(company => {
      let phaseNumber = Object.values(company.phases).filter((v) => v).length;
      company.phases.phaseNumber = phaseNumber;
      return {
        ...company
      };
    })
    return formatedCompanies;
  }

  renderCompanies(_companies){
    const companies = _companies;

    const columns = [
      {
        dataField: 'logoThumbnail',
        text: 'Logo',
        formatter: (cellContent,row) => {
          return(
            <Link to={`/companies/${row._id}`}>
              <KImage src={cellContent} type='company' className='companyImage' alt="company_image"/>
            </Link>
          )
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        formatter: (cellContent,row) => {
          return(
            <Link to={`/companies/${row._id}`}>{cellContent}</Link>
          )
        }
      },
      {
        dataField: 'dummy',
        isDummyField: true,
        text: 'Fecha de inscripción',
        sort: true,
        formatter: (cell,row) => {
          let program = row.programs.find((program) => program.program === this.props.program._id);
          //
          return this.formatDate(program.joined_date);
        }
      },
      {
        dataField: 'phases',
        text: 'Etapa',
        sort: true,
        formatter:(cellContent) => {
          return CompanyPhasesChecker.getPhaseLabelFromObject(cellContent);
        },
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === 'desc') {
            if (a.phaseNumber === b.phaseNumber){
              return a.phaseNumber - b.phaseNumber;
            }
            return a.phaseNumber - b.phaseNumber;
          }
          if (a.phaseNumber === b.phaseNumber){
            return b.phaseNumber - a.phaseNumber; // asc
          }
          return b.phaseNumber - a.phaseNumber; // asc
        },
      },
      {
        dataField: 'industry_sector',
        text: 'Sector',
        sort: true,
      },
    ];

    return(
      <div className="card-body">
        <BootstrapTable
          bootstrap4
          striped
          keyField='_id'
          data={ companies }
          columns={ columns }
          noDataIndication={'No se encontraron empresas para este programa.'}
          wrapperClasses="table-responsive"
          classes="react-bootstrap-table"
        />
      </div>
    )
  }

  openUserProfile(id,activeKey){
    this.setState({userSelectedId:id,userProfileActiveKey:activeKey},() => this.handleSidebarProfile(true));
  }

  handleSidebarProfile(show){
    this.setState({isSidebarProfileOpen:show});
  }

  getUserList(userType){
    if(userType === 'mentors'){
      const _m = this.props.program.mentors_participated;
      return  _m.map( m => m.user )
    }else{
      let users = this.props.program.partners.concat(this.props.program.users_without_company)
      return users;
    }
  }

  renderPartners(userType){
    const partners = this.getUserList(userType);
    const columns = [
      {
        dataField: 'profileUrlThumbnail',
        text: '',
        formatter: (cellContent,row) => {
          if(cellContent !== undefined){
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                <KImage src={cellContent} type={`user_${row.gender}`} alt="user_image"/>
              </div>
            )
          }else{
            return(
              <KImage src={cellContent} type='user' alt="user_image"/>
            )
          }
        }
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
        formatter: (cellContent,row) => {
          let name = 'Usuario no encontrado';

          if(cellContent !== null && cellContent !== undefined){
            name = renderFullNameOrEmail(row);
            return(
              <div className={'cursor-pointer'} onClick={() => this.openUserProfile(row._id,'profile')}>
                {name}
              </div>
            )
          }
          return name;
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: '_id',
        text: '',
        formatter:(cell,row) =>{
          if(row !== null){
            return (<div className="btn btn-link" onClick={() => this.openUserProfile(cell,'companies')}>Ver empresas</div>)
          }
        },
      },
    ];

    return(
        <div className="card-body">
          <BootstrapTable
            bootstrap4
            striped
            keyField='_id'
            data={ partners }
            columns={ columns }
            noDataIndication={'No se encontraron Emprendedores para este programa.'}
            wrapperClasses="table-responsive"
            classes="react-bootstrap-table"
          />
        </div>
    )
  }

  render(){
    return(
      <div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="flex-row" style={{display:'flex'}}>
               {/* aria-controls="example-collapse-text"
               aria-expanded={this.state.openCollapse}
               onClick={ () => { this.handleOpenCollapse(true); this.setState({renderStats:'companies'}) } }> */}
                <div className="p-10">
                  <button class="p-10 btn btn-circle btn-stats text-white btn-lg">
                    <i class="mdi mdi-lightbulb-on"></i>
                  </button>
                </div>
                <div className="align-self-center m-l-5">
                  <h4 class="fw-normal my-2">Empresas inscritas</h4>
                </div>
                <div className="align-self-center ml-auto">
                  <h3 className="my-2 p-r-20  f-32">{this.props.program.registered_companies.length}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="flex-row" style={{display:'flex'}}>
               {/* aria-controls="example-collapse-text"
               aria-expanded={this.state.openCollapse}>
               onClick={ () => { this.handleOpenCollapse(true); this.setState({renderStats:'companies_mentored'}) } }> */}
                <div className="p-10">
                  <button class="p-10 btn btn-circle btn-stats text-white btn-lg">
                    <i class="mdi mdi-library"></i>
                  </button>
                </div>
                <div className="align-self-center m-l-5">
                  <h4 class="fw-normal my-2">Empresas activas</h4>
                </div>
                <div className="align-self-center ml-auto">
                  <h3 className="my-2 p-r-20  f-32">{this.props.program.companies_mentored.length}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="flex-row" style={{display:'flex'}}>
               {/*aria-controls="example-collapse-text"
               aria-expanded={this.state.openCollapse}
               onClick={ () => { this.handleOpenCollapse(true); this.setState({renderStats:'mentors'}) } }> */}
                <div className="p-10">
                  <button class="p-10 btn btn-circle btn-stats text-white btn-lg">
                    <i class="mdi mdi-account-star-variant"></i>
                  </button>
                </div>
                <div className="align-self-center m-l-5">
                  <h4 class="fw-normal my-2">Aliados activos</h4>
                </div>
                <div className="align-self-center ml-auto">
                  <h3 className="my-2 p-r-20  f-32">{this.props.program.mentors_participated.length}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="flex-row" style={{display:'flex'}}>
              {/* aria-controls="example-collapse-text"
              aria-expanded={this.state.openCollapse}>
              onClick={ () => { this.handleOpenCollapse(true); this.setState({renderStats:'partners'}) } }> */}
                <div className="p-10">
                  <button class="p-10 btn btn-circle btn-stats text-white btn-lg">
                    <i class="mdi mdi-account-multiple"></i>
                  </button>
                </div>
                <div className="align-self-center m-l-5">
                  <h4 class="fw-normal my-2">Emprendedores</h4>
                </div>
                <div className="align-self-center ml-auto">
                  <h3 className="my-2 p-r-20  f-32">{this.props.program.partners_signed?.length}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Collapse in={this.state.openCollapse}>
          <div id="example-collapse-text" className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {this.renderStats()}
                </div>
              </div>
            </div>
          </div>
        </Collapse>

        <KProfileSidebar showSidebar={this.state.isSidebarProfileOpen}
                         closeSidebar={(show) => this.handleSidebarProfile(show)}
                         user_id={this.state.userSelectedId}
                         activeKey={this.state.userProfileActiveKey}>
       </KProfileSidebar>

      </div>
    )
  }
}

KStatsProgram.propTypes = {
  /** Program object */
  program                : PropTypes.object,
}

KStatsProgram.defaultProps = {
  program                : { registered_companies:[], companies_mentored:[], mentors_participated:[], partners:[]  },
}

export default KStatsProgram;
