/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <isra_darrell@startuplab.mx>, August 2019
 */

// Regular imports
import React from 'react';
import { Link } from "react-router-dom";
import KomunidadApi from '../../api/api.js';
import { validateEmail } from '../../utils/Utils.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//version
import pkg from '../../../package.json'

// Custom styling
import '../../scss/pages/login-register-lock.scss';
import '../../scss/colors/default-dark.scss';
import '../../scss/pages/pages.scss';
import '../../scss/pages/_login.scss';


import {getAppLogo} from "../../utils/Vendor.js";
const Logo = getAppLogo();
//const
const MySwal = withReactContent(Swal);

class ForgotPasswordView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      error_message: '',
    }
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  componentDidMount() {
    KomunidadApi.getMe().then( (res) => {
      if(res.valid_token){ //redirect to profile if user is logged in
        this.redirectToCompanies();
      }
    });

    if(this.props.location.hasOwnProperty('params')){
      this.setState({
        email: this.props.location.params.email
      });
    }
  }

  redirectToCompanies(){
    this.props.history.push(`/companies`);
  }

  redirectToProfile(){
    this.props.history.push('/profile');
  }

  handleChanges (event) {
    this.setState({ [event.target.id] : event.target.value });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.setState({ error_message : '' })

    if (validateEmail(this.state.email)) {
      KomunidadApi.requestPwdReset(this.state.email).then(res => {
        this.renderSwal();
      }).catch(err => {
        this.setState({ error_message: 'Servicio no disponible. Intente de nuevo más tarde.' });
      })
    } else {
      this.setState({ error_message: 'El correo electrónico no es válido' });
    }
  }

  renderSwal(){
    MySwal.fire({
      type:"success",
      // title:"¡Genial!",
      text:"Se ha enviado un correo con instrucciones para restablecer tu contraseña. (Recuerda revisar tu bandeja de correo no deseado)",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'swalBtnTeal',
        title: 'swal-custom-title',
      },
    }).then((result) => {
      if (result.value) {
        this.props.history.push('/');
      }
    })
  }

  render () {
    return (
      <section id="wrapper">
        <div className="d-flex no-block justify-content-center align-items-center" style={{ backgroundColor: '#F2F4F5', position: 'relative', minHeight: '100vh'}}>
          <div style={{position: 'absolute', bottom: 0, width:'100%'}}>
            <div className="text-center row justify-content-md-center">
              <div className="col col-lg-8" style={{bottom: '12px', fontSize: '14px'}}>
                <a rel="noopener noreferrer" href="https://komunidad.freshdesk.com/support/home" target="_blank"> Soporte</a> |
                <a rel="noopener noreferrer" href="https://creomx.com/edomex/Aviso.pdf" target="_blank"> Aviso de Privacidad</a> |
                <a rel="noopener noreferrer" href="https://www.komunidad.io/terminosycondiciones" target="_blank"> Términos y Condiciones</a>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className="col-12 mb-5">
              <a className="text-center db" href="https://www.komunidad.io/">
                <img src={ Logo } alt="Home" className="AppLogo" />
              </a>
            </div>
            <div className="col-md-10">
              <div className="card-container card border-0">
                <div className="card-body">
                  <h2 className="m-0"><b>¿Olvidaste tu contraseña?</b></h2>
                  <div className="form-group row my-3">
                    <div className="col-md-12">
                      <div className="float-left" style={{color: '#67757c',fontSize: '14px'}}>
                        ¿Ya tienes una cuenta? da clic <Link to={{ pathname: '/'}}>aquí.</Link>
                      </div>
                    </div>
                  </div>
                  <form className="form-horizontal" id="loginform" onSubmit={this.handleSubmit}>
                    <div className="form-group mb-3">
                      <div className="col-xs-12">
                        {/*<label>Correo electrónico</label>*/}
                        <input className="form-control" id="email" value={this.state.email} onChange={this.handleChanges} placeholder="tucorreo@tucorreo.com" type="email"/>
                      </div>
                    </div>

                    <p className="text-error my-2">{this.state.error_message}</p>

                    {/*<div className="form-group m-0">
                      <div className="col-xs-12">
                        {this.renderAdBlockDetect()}
                      </div>
                    </div>*/}

                    <div className="form-group row mb-3">
                      <div className="col-md-12">                    
                        <div className="float-right">
                          <button className="btn btn-info btn-block btn-rounded" style={{borderRadius: '.25rem',padding: '5px'}} type="submit">Restablecer contraseña</button>
                        </div>
                      </div>
                    </div>

                  </form>
                  <div className="container text-muted text-center" style={{fontSize: '14px'}}>
                    <p style={{color: '#67757c', display: 'inline'}}>Komunidad IO © 2024 - v{pkg.version}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ForgotPasswordView;
